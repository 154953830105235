import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';
import { TimeUtil } from '../../../../../shared/utils/time-util';

const ARNETA_VEHICLE_SOURCE_URL = "http://arneta.com.tr";
const ASIS_VEHICLE_SOURCE_URL = "https://asis-services.com/";
@Component({
  selector: 'vehicle-dialog-template',
  templateUrl: './vehicle-dialog-template.component.html',
  styleUrls: ['./vehicle-dialog-template.component.scss']
})
export class VehicleDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  // Context variables
  entity: any;
  positionEntity: any;
  address: any;
  vehiclePlateNumber: string;
  vehicleType: any;
  vehicleCategory: any;
  vehicleModel: any;
  vehicleBrand: any;
  speed: any;
  location: any;
  date: any;
  vehicleId: any;
  vehicleTrimmed: any;
  dotSeparatedDate: string;
  mileageFromOdometer: string;
  dailyMileageFromOdometer: string;
  latitude: any;
  longitude: any;
  linecode: any;
  drivername: any;
  direction: any;
  totalnumberofpassengers: any;
  totalpassengerincome: any;
  isArnetaVehicle = undefined;
  isAsisVehicle = undefined;
  title = undefined;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected mapContextData(context: any): void {
    this.entity = context.entity.entity;
    this.positionEntity = context.positionEntity.entity;
    // get source
    this.isArnetaVehicle = super.getValue(this.entity,'source') === ARNETA_VEHICLE_SOURCE_URL;
    this.isAsisVehicle = super.getValue(this.entity,'source') === ASIS_VEHICLE_SOURCE_URL;
    if (this.isArnetaVehicle) {
      this.title = "Araç";
    } else if (this.isAsisVehicle) {
      this.title = "Asis Aracı";
    } else {
      this.title = "İtfaiye Aracı";
    }
    // get the values to be displayed on UI
    this.vehiclePlateNumber = super.getValue(this.entity, 'vehiclePlateNumber');
    this.vehicleType = super.getValue(this.entity, 'vehicleType');
    this.vehicleCategory = super.getValue(this.entity, 'category');
    this.vehicleModel = super.getValue(this.entity, 'vehicleModel');
    this.vehicleBrand = super.getValue(this.entity, 'vehicleBrand');
    this.mileageFromOdometer = super.getValue(this.positionEntity, 'mileageFromOdometer');
    this.dailyMileageFromOdometer = super.getValue(this.positionEntity, 'dailyMileageFromOdometer');
    this.speed = super.getValue(this.positionEntity, 'speed');
    this.location = super.getValue(this.positionEntity, 'location').coordinates.join(', ');
    const date = new Date(super.getValue(this.positionEntity, 'speed', 'modifiedAt'));
    this.dotSeparatedDate = TimeUtil.serializeReadableDotSeparatedDate(date);
    this.date = TimeUtil.serializeCustomDatetime(date);
    this.vehicleId = this.entity.id;
    this.vehicleTrimmed = this.vehicleId.substring(this.vehicleId.lastIndexOf(':') + 1);
    const address = super.getValue(this.positionEntity, 'address');
    this.address = `(${address.addressRegion}/${address.addressLocality})`;
    const array = this.location.split(',');
    this.longitude = this.floatParse(array[0], 5);
    this.latitude = this.floatParse(array[1], 5);
    this.location = this.longitude + ' , ' + this.latitude;
    this.linecode = super.getValue(this.positionEntity, 'linecode');
    this.direction = super.getValue(this.positionEntity, 'direction');
    this.drivername = super.getValue(this.positionEntity, 'driverName');
    this.totalnumberofpassengers = super.getValue(this.positionEntity, 'totalnumberofpassengers');
    this.totalpassengerincome = super.getValue(this.positionEntity, 'totalpassengerincome');
  }

  floatParse(str, val) {
    str = str.toString();
    str = str.slice(0, (str.indexOf('.')) + val + 1);
    return Number(str);
  }
}
