<!-- Do not display footer, which presents the buttons to confirm/suppress the alert, if the user has no right to
update alert state-->
<closeable-dialog class="alert-detail-dialog" [displayFooter]="canUpdateAlertState">
  <div dialog-card-header>
    <label *ngIf="alerts?.length" class="alert-title">
      {{ alerts[0]?.getSingleElementValue('description') }}
    </label>
    <i class="alert-icon fa fa-exclamation-circle"></i>
  </div>

  <div class="w-100 h-100" dialog-card-body>

    <div *ngIf="alerts?.length" class="alert-info">
      <div class="row">
        <div class="col-7">
          <span class="alert-info-title">{{'ID' | translate}}:</span>
          <span class="alert-info-text">{{alerts[0].entity.id | translate}}</span>
        </div>

        <div class="col-5">
          <span class="alert-info-title">{{'İşlemi Yapan Kişi' | translate}}:</span>
          <span class="alert-info-text">{{_AlertUtil.getUserName(alerts[0])}}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-7">
          <span class="alert-info-title">{{'Priority' | translate}}:</span>
          <span class="alert-info-text"
                [ngClass]="'severity-' + alerts[0].getSingleElementValue('severity')">{{_AlertUtil.getPriorityText(alerts[0].getSingleElementValue('severity')) | translate}}</span>
        </div>
        <div class="col-5 alert-state-value">
          <span class="alert-info-title">{{'Status' | translate}}:</span>
          <div *ngIf="alerts[0].entity.state" class="alert-info-text alert-badge alert-state-badge" [ngClass]="'state-' + alerts[0].getSingleElementValue('state')">
            {{ _AlertUtil.getStateText(alerts[0].getSingleElementValue('state')) | translate }}
          </div>
        </div>
      </div>

      <div class="row topless">
        <div class="col-7">
          <div class="inner-row">
            <span class="alert-info-title">{{'Category' | translate}}:</span>
            <span class="alert-info-text">{{alerts[0].getSingleElementValue('event')}}</span>
          </div>

          <div class="inner-row">
            <span class="alert-info-title">{{'Subcategory' | translate}}:</span>
            <span class="alert-info-text">{{alerts[0].getSingleElementValue('eventCode')}}</span>
          </div>

          <div class="inner-row">
            <span class="alert-info-title">{{'Source' | translate}}:</span>
            <span class="alert-info-text">{{alerts[0].getSingleElementValue('source')}}</span>
          </div>

          <div class="inner-row">
            <span class="alert-info-title">{{'Associated Entity' | translate}}:</span>
            <span class="alert-info-text">{{alerts[0].getSingleElementValue('alertSource')}}</span>
          </div>

          <div class="inner-row">
            <span class="alert-info-title">{{'Alert_Location' | translate}}:</span>
            <span *ngIf="alerts[0].entity.address"
              class="alert-info-text">{{alerts[0].getAddress()}}</span>
            <div class="alert-location-icon flex-centered" (click)="showAlertLocation()">
              <uruk-svg [width]="'70%'"
                        [height]="'70%'"
                        [viewbox]="'0 0 15 15'"
                        [paths]="['M10.5883 9.11816C8.57126 9.11816 7.61352 10.8472 7.38117 11.3407C7.34234 11.4231 7.34234 11.5186 7.38117 11.6009C7.61352 12.0948 8.57126 13.824 10.5883 13.824C12.5983 13.824 13.5567 12.1073 13.7932 11.6067C13.8338 11.5205 13.8338 11.4217 13.7932 11.3355C13.5567 10.8349 12.5983 9.11816 10.5883 9.11816ZM10.5883 10.0005C11.4004 10.0005 12.0589 10.659 12.0589 11.4711C12.0589 12.2832 11.4004 12.9417 10.5883 12.9417C9.77626 12.9417 9.11773 12.2832 9.11773 11.4711C9.11773 10.659 9.77626 10.0005 10.5883 10.0005ZM10.5883 10.5888C10.3543 10.5888 10.1299 10.6817 9.9644 10.8472C9.79892 11.0127 9.70596 11.2371 9.70596 11.4711C9.70596 11.7051 9.79892 11.9295 9.9644 12.095C10.1299 12.2605 10.3543 12.3535 10.5883 12.3535C10.8223 12.3535 11.0468 12.2605 11.2122 12.095C11.3777 11.9295 11.4707 11.7051 11.4707 11.4711C11.4707 11.2371 11.3777 11.0127 11.2122 10.8472C11.0468 10.6817 10.8223 10.5888 10.5883 10.5888Z',
                                      'M7.04059 1.10841L8.97471 0.329279C9.74765 0.0176251 10.5882 0.591163 10.5882 1.42955V8.44769C10.5882 8.47507 10.5873 8.5023 10.5855 8.52934C8.71492 8.53052 7.57367 9.72143 6.99107 10.5872C6.88224 10.5825 6.77377 10.5627 6.66882 10.5274L3.54823 9.47982L1.61353 10.259C0.840588 10.57 0 9.99707 0 9.15869V2.14055C0 1.65588 0.292941 1.2204 0.739412 1.04028L3.11118 0.0845774C3.36941 -0.0191097 3.65588 -0.0279972 3.92 0.0608775L7.04059 1.10841ZM6.28647 9.13973C6.66706 9.26593 7.05882 8.98034 7.05882 8.57685V2.79229C7.05882 2.53633 6.89588 2.30941 6.65471 2.22942L4.30176 1.44851C3.92118 1.32231 3.52941 1.60789 3.52941 2.01138V7.79594C3.52941 8.0519 3.69235 8.27883 3.93353 8.35881L6.28647 9.13973Z']"
                        [svgContainerStyle]="'fill: white'">
              </uruk-svg>
            </div>
          </div>

          <div *ngIf="alerts[0].entity.data" class="inner-row">
            <div class="col-6 no-pad">
              <span class="alert-info-title">{{'Creation Time' | translate}}:</span>
              <span
                class="alert-info-text">{{_AlertUtil.getReadableStartDate(alerts[0]) + '-' + _AlertUtil.getReadableStartTime(alerts[0])}}</span>
            </div>
            <div class="col-6">
              <span class="alert-info-title">{{'Value at Occurrence' | translate}}:</span>
              <span class="alert-info-text">{{alerts[0].getFirstTemporalValue('data')?.value}}</span>
            </div>
          </div>

          <div *ngIf="alerts[0].entity.state" class="inner-row">
            <div class="col-6 no-pad">
              <span class="alert-info-title">{{'End Time' | translate}}:</span>
              <span
                class="alert-info-text">{{_AlertUtil.getReadableEndDate(alerts[0]) + '-' + _AlertUtil.getReadableEndTime(alerts[0])}}</span>
            </div>
            <div class="col-6">
              <span class="alert-info-title">{{'Value at the End' | translate}}:</span>
              <span class="alert-info-text">{{alerts[0].getLastTemporalValue('data')?.value}}</span>
            </div>
          </div>
        </div>
        <div class="col-5 alert-description">
          <div class="alert-info-title w-100">{{'Alert_Description' | translate}}:</div>
          <textarea nbInput fullWidth placeholder="Textarea"> </textarea>
        </div>
      </div>

      <div *ngIf="alertValues" class="row">
        <div class="col-12">
          <div class="alert-timeline">
            <div class="info-container flex-centered"> {{'Değer Güncelleme Geçmişi' | translate}}:</div>
            <div class="value-timeline flex-centered">
              <div class="timeline-bar"></div>
              <div class="timeline-items">
                <div class="timeline-item" *ngFor="let alertValue of alertValues">
                  <div class="value-time">
                    <span class="text-item">{{_TimeUtil.serializeReadableDate(alertValue.observedAt)}}</span>
                    <span class="text-item">{{_TimeUtil.serializeHour(alertValue.observedAt)}}</span>
                  </div>
                  <div class="timeline-dot"></div>
                  <div class="value text-item">
                    {{alertValue.value}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div dialog-card-footer class="footer">
    <div class="alert-footer-button cancel-button flex-centered" [ngClass]="{'disabled':disabled}"
         (click)="suppress()"> {{ 'Suppress the Alert' | translate }}</div>
    <div class="alert-footer-button confirm-button flex-centered" [ngClass]="{'disabled':disabled}"
         (click)="confirm()">{{ 'Confirm the Alert' | translate }}</div>
  </div>
</closeable-dialog>
