<div class="confirmation-dialog">

  <div class="header">
    <div class="header-title">
      {{ 'Kullanıcı sil' | translate }}
    </div>
    <div class="header-close">
      <i class="fa fa-times"></i>
    </div>
  </div>
  <div class="body">
    <div class="user-details">
      <div class="user-detail">
        <label class="label-title">{{ 'Role' | translate }}:</label>
        <label class="label-info">{{ user.role }}</label>
      </div>
      <div class="user-detail">
        <label class="label-title">{{ 'Realm' | translate }}:</label>
        <label class="label-info">{{ 'testrealm' }}</label>
      </div>
      <div class="user-detail two-columns">
        <div class="column">
          <label class="label-title">{{ 'Status' | translate }}:</label>
          <label class="label-info">{{ user.status | translate }}</label>
        </div>
        <div class="column right">
          <i class="fa fa-calendar-day"></i>
          <label class="label-info">{{ user.creationTime }}</label>
        </div>
      </div>
    </div>
    <div class="confirmation-text"> {{ 'Are you sure you want to delete user?' | translate : {name: user.fullname } }}</div>
  </div>
  <div class="footer">
    <div class="flex-centered button left-button"  (click)="cancel()">{{'Cancel'|translate}}</div>
    <div class="flex-centered button right-button" (click)="remove()">{{'Delete'|translate}}</div>
  </div>
</div>
