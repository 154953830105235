import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  templateUrl: './generic-confirmation-dialog.component.html',
  styleUrls: ['./generic-confirmation-dialog.component.scss']
})
export class GenericConfirmationDialogComponent {
  @Input() body: string;
  @Input() title: string;
  @Input() id: number;

  constructor(protected ref: NbDialogRef<GenericConfirmationDialogComponent>) {
  }

  cancel() {
    this.ref.close(false);
  }

  remove() {
    this.ref.close(true);
  }

}
