import {Context} from './context.model';
import {BaseMetaModel} from './base/base-meta-model.model';

/**
 * External system providing data
 */
export class ContextSource extends BaseMetaModel {
  /**
   * Name of the external source
   */
  name: string;

  /**
   * Description of the external source
   */
  description?: string;

  /**
   * Information about entities that are related to the source
   */
  contexts: Context[];

  /**
   * Whether the context-source source is in use or not
   * */
  inUse: boolean;

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.name = data.name;
    this.description = data.description;
    this.inUse = data.inUse;

    if (data.contexts) {
      this.contexts = data.contexts.map(context => new Context(context));
    }
  }
}
