import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule, NbPopoverModule, NbRadioModule,
  NbSelectModule,
  NbTooltipModule
} from '@nebular/theme';
import {TranslateModule} from '@ngx-translate/core';
import {MapComponent} from './components/map/map.component';
import {LeafletMapComponent} from './components/map/leaflet-map.component';
import {MapraysMapComponent} from './components/map/maprays-map.component';
import {CloseableDialogComponent} from './components/dialog/closeable-dialog.component';
import {PageHostDirective} from './directives/page-host.directive';
import {SearchTagInputComponent} from './components/input/search-tag-input.component';
import {DateSelectionComponent} from './components/date-selection/date-selection.component';
import {UrukSvgComponent} from './components/uruk-svg.component';
import {KpiSearchComponent} from './components/kpi/kpi-search.component';
import {FormsModule} from '@angular/forms';
import {WordForWordTranslatePipe} from './pipes/word-for-word-translate.pipe';
import {IconSelectionComponent} from './components/icon/icon-selection.component';
import {LayerSelectionComponent} from './components/layer/layer-selection.component';
import {OptionInputComponent} from './components/form/option-input.component';
import {MultiValueInputComponent} from './components/form/multi-value-input.component';
import {LocationSelectionDialogComponent} from './components/dialog/location-selection-dialog.component';
import {DrawingToolboxComponent} from './components/map/drawing-toolbox.component';
import {NgxMapLibreGLModule} from 'ngx-maplibre-gl';
import {MaplibreMapComponent} from './components/map/maplibre-map.component';
import {SelectWidthDirective} from './directives/select-width.directive';
import {DateTimeSelectionComponent} from './components/date-selection/date-time-selection.component';
import {QueryExpressionComponent} from './components/query-expression/query-expression.component';
import {QueryExpressionFilterComponent} from './components/query-expression/query-expression-filter.component';
import {LegendComponent} from './components/legend/legend.component';
import {ListComponent} from './components/list/list.component';
import {ReleaseNotesDialogComponent} from './components/dialog/release-notes-dialog.component';
import {MetadataPipe} from './pipes/metadata.pipe';
import {NotificationsTooltipComponent} from './components/notification/notifications-tooltip.component';
import { PaginationComponent } from './components/list/pagination.component';
import {SuccessDialogComponent} from './components/dialog/success-dialog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CheckboxGroupInputComponent } from './components/input/checkbox-group-input.component';
import {AnalyticsSearchComponent} from './components/analytics/analytics-search.component';
import {PathSelectionComponent} from './components/icon/path-selection.component';
import { FileSelectorComponent } from './components/form/file-selector.component';
import { ValueListComponent } from './components/form/value-list.component';
import { DefibrillatorDialogComponent} from '../modules/page/templates/dialogs/defibrillator-dialog/dialog/defibrillator-dialog.component';
import { DefibrillatorEkgDialogComponent} from '../modules/page/templates/dialogs/defibrillator-dialog/dialog/defibrillator-ekgdialog.component';
import { DefibrillatorInfoDialogComponent} from '../modules/page/templates/dialogs/defibrillator-dialog/dialog/info/defibrillator-infodialog.component';
import { JkgysTakBitResultdialogComponent} from '../modules/page/templates/dialogs/jkgysTak-dialog/bitResults/jkgysTak-bitResultdialog.component';
import {LottieModule} from "ngx-lottie";

@NgModule({
  imports: [
    CommonModule,
    LeafletModule,
    LeafletDrawModule,
    NgxMapLibreGLModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbPopoverModule,
    NbSelectModule,
    TranslateModule,
    FormsModule,
    NbTooltipModule,
    NbCheckboxModule,
    NbRadioModule,
    LottieModule
  ],
  declarations: [
    AnalyticsSearchComponent,
    DateTimeSelectionComponent,
    DrawingToolboxComponent,
    MultiValueInputComponent,
    LocationSelectionDialogComponent,
    OptionInputComponent,
    ValueListComponent,
    FileSelectorComponent,
    PageHostDirective,
    SelectWidthDirective,
    MapComponent,
    ListComponent,
    PaginationComponent,
    LeafletMapComponent,
    MaplibreMapComponent,
    MapraysMapComponent,
    CloseableDialogComponent,
    BreadcrumbComponent,
    SearchTagInputComponent,
    CheckboxGroupInputComponent,
    LegendComponent,
    DateSelectionComponent,
    UrukSvgComponent,
    KpiSearchComponent,
    IconSelectionComponent,
    LayerSelectionComponent,
    WordForWordTranslatePipe,
    MetadataPipe,
    QueryExpressionFilterComponent,
    QueryExpressionComponent,
    ReleaseNotesDialogComponent,
    NotificationsTooltipComponent,
    SuccessDialogComponent,
    PathSelectionComponent,
    DefibrillatorDialogComponent,
    DefibrillatorEkgDialogComponent,
    DefibrillatorInfoDialogComponent,
    JkgysTakBitResultdialogComponent
  ],
    exports: [
        AnalyticsSearchComponent,
        DrawingToolboxComponent,
        MultiValueInputComponent,
        LocationSelectionDialogComponent,
        BreadcrumbComponent,
        OptionInputComponent,
        ValueListComponent,
        FileSelectorComponent,
        PageHostDirective,
        SelectWidthDirective,
        MapComponent,
        ListComponent,
        PaginationComponent,
        LeafletMapComponent,
        MaplibreMapComponent,
        MapraysMapComponent,
        CloseableDialogComponent,
        SearchTagInputComponent,
        CheckboxGroupInputComponent,
        LegendComponent,
        DateSelectionComponent,
        UrukSvgComponent,
        KpiSearchComponent,
        IconSelectionComponent,
        LayerSelectionComponent,
        DateTimeSelectionComponent,
        QueryExpressionFilterComponent,
        QueryExpressionComponent,
        ReleaseNotesDialogComponent,
        NotificationsTooltipComponent,
        WordForWordTranslatePipe,
        PathSelectionComponent,
        DefibrillatorDialogComponent,
        DefibrillatorEkgDialogComponent,
        DefibrillatorInfoDialogComponent,
        JkgysTakBitResultdialogComponent
    ],
})
export class SharedModule {
}
