<div class="pagination d-flex">
  <div>
    <span class="currentPage">{{currentPage}}</span>-{{totalPages}} {{'page'|translate}}
  </div>
  <div *ngIf="pages.length" class="d-flex pages">
    <uruk-svg
      [paths]="['M9.04678 6.35215C9.22353 6.25473 9.3335 6.06455 9.3335 5.85542L9.3335 0.562113C9.3335 0.352983 9.22353 0.16281 9.04678 0.0653911C8.87819 -0.0273614 8.68014 -0.0209445 8.51739 0.0823084L5.8334 1.78364L5.8334 0.562113C5.8334 0.352983 5.72344 0.162811 5.54668 0.0653914C5.3781 -0.027361 5.18005 -0.0209442 5.01729 0.0823087L1.39992 2.37529L1.39992 1.80867C1.39992 1.42206 1.08651 1.10865 0.6999 1.10865C0.31329 1.10865 -0.000118692 1.42206 -0.000118659 1.80867L-0.000118414 4.60875C-0.00011838 4.99536 0.31329 5.30877 0.699901 5.30877C1.08651 5.30877 1.39992 4.99536 1.39992 4.60875L1.39992 4.04272L5.017 6.33552C5.18005 6.43848 5.3781 6.4449 5.54668 6.35215C5.72344 6.25473 5.8334 6.06456 5.8334 5.85542L5.8334 4.63438L8.5171 6.33552C8.68014 6.43848 8.87819 6.4449 9.04678 6.35215Z']"
      [svgContainerClass]="'uruk-icon'" [svgContainerStyle]="'width: 0.5vw'" [viewbox]="'0 0 10 8'" class="action-icon"
      (click)="onGoTo(1)">
    </uruk-svg>
    <uruk-svg
      [paths]="['M7.92391 9.49047C8.17641 9.35131 8.3335 9.07964 8.3335 8.78089L8.3335 1.21922C8.3335 0.920473 8.17641 0.648807 7.92391 0.50964C7.68308 0.37714 7.40016 0.386307 7.16766 0.533807L1.20308 4.31464C0.971413 4.46089 0.833496 4.71714 0.833496 5.00006C0.833496 5.28297 0.971413 5.53922 1.20266 5.68589L7.16725 9.46672C7.40016 9.61381 7.68308 9.62297 7.92391 9.49047Z']"
      [svgContainerClass]="'uruk-icon'" [svgContainerStyle]="'width: 0.5vw'" [viewbox]="'0 0 9 10'" class="action-icon"
      (click)="onPrevious()">
    </uruk-svg>
    <div *ngFor="let page of pages;let last=last">
      <span [ngClass]="{'active':page === currentPage}" (click)="onGoTo(page)">{{page === -1 ? '...' : page}}</span>
      <ng-container *ngIf="!last">-</ng-container>
    </div>
    <uruk-svg
      [paths]="['M1.07633 0.509527C0.823831 0.648693 0.666748 0.920361 0.666748 1.21911L0.666748 8.78078C0.666748 9.07953 0.823831 9.35119 1.07633 9.49036C1.31716 9.62286 1.60008 9.61369 1.83258 9.46619L7.79716 5.68536C8.02883 5.53911 8.16675 5.28286 8.16675 4.99994C8.16675 4.71703 8.02883 4.46078 7.79758 4.31411L1.833 0.533277C1.60008 0.386194 1.31716 0.377027 1.07633 0.509527Z']"
      [svgContainerClass]="'uruk-icon'" [svgContainerStyle]="'width: 0.5vw'" [viewbox]="'0 0 9 10'" class="action-icon"
      (click)="onNext()">
    </uruk-svg>
    <uruk-svg
      [paths]="['M0.286716 0.856594C0.109961 0.954013 0 1.14419 0 1.35332V6.64663C0 6.85576 0.109962 7.04593 0.286716 7.14335C0.455304 7.2361 0.653351 7.22968 0.816106 7.12643L3.5001 5.4251V6.64663C3.5001 6.85576 3.61006 7.04593 3.78681 7.14335C3.9554 7.2361 4.15345 7.22968 4.3162 7.12643L7.93358 4.83345V5.40007C7.93358 5.78668 8.24699 6.10009 8.6336 6.10009C9.02021 6.10009 9.33361 5.78668 9.33361 5.40007V2.59999C9.33361 2.21338 9.02021 1.89997 8.6336 1.89997C8.24699 1.89997 7.93358 2.21338 7.93358 2.59999V3.16602L4.31649 0.87322C4.15345 0.770258 3.9554 0.763841 3.78681 0.856594C3.61006 0.954013 3.5001 1.14419 3.5001 1.35332V2.57436L0.816397 0.87322C0.653351 0.770258 0.455304 0.763841 0.286716 0.856594Z']"
      [svgContainerClass]="'uruk-icon'" [svgContainerStyle]="'width: 0.5vw'" [viewbox]="'0 0 10 8'" class="action-icon"
      (click)="onGoTo(totalPages)">
    </uruk-svg>
  </div>
  <div class="page-size-indicator">
    {{'paginationPageCount.title'|translate}}
    <nb-select [selected]="pageSize" (selectedChange)="handlePageSizeChange($event)" [size]="'tiny'">
      <nb-option [value]="10"> 10 </nb-option>
      <nb-option [value]="20"> 20 </nb-option>
      <nb-option [value]="30"> 30 </nb-option>
    </nb-select>
    {{('paginationPageCount.status.'+type) | translate}}
  </div>
</div>