import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

/**
 * Dialog to cancel an operation such as KPI creation.
 */
@Component({
    templateUrl: './cancel-operation-dialog.component.html',
    styleUrls: ['./cancel-operation-dialog.component.scss']
})
export class CancelOperationDialogComponent {
    @Input() body: string;

    constructor(protected ref: NbDialogRef<CancelOperationDialogComponent>) {
    }

    cancel() {
        this.ref.close(true);
    }

    continue() {
        this.ref.close(false);
    }

}
