<div class="d-flex">
    <!-- input to type a value to be added to the list -->
    <input *ngIf="isAdding" [fieldSize]="inputFieldSize" type="text" nbInput [(ngModel)]="valueToBeAdded" class="value-input" [placeholder]="inputPlaceholder | translate" [disabled]="disabled">
    <!-- select component to displayed values in the list -->
    <nb-select *ngIf="!isAdding && valueList?.length" class="value-input" [multiple]="true"
        [placeholder]="valueList.length == 1 ? valueList[0] : valueList[0] + ', +' + (valueList.length - 1) + ' ' + ('other(s)' | translate)">
        <nb-option *ngFor="let value of valueList" (click)="removeValue(value)"
            [title]="disabled ? '' :'Click to remove' | translate">{{value}}</nb-option>
    </nb-select>
    <!-- button to add a value to list -->
    <div *ngIf="!disabled" class="action-button flex-centered" (click)="addValue()"
        [title]="addButtonLabel | translate">
        <i *ngIf="!isAdding" class="fa fa-plus"></i>
        <i *ngIf="isAdding" class="fa fa-check"></i>
    </div>
</div>