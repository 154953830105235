<div class="date-selection-dialog">
  <!-- show only day selection tab -->
  <div *ngIf="onlyDayTab" class="selection-type">
    <nb-calendar [(date)]="startDate" (dateChange)="onDateChange($event)" size="large"
                 startView="date"></nb-calendar>
    <button (click)="onTodayClicked()" class="today-button" fullWidth nbButton
            status="primary">{{ 'Today' | translate }}</button>
  </div>
  <!-- show available data selection tabs as a tabset -->
  <nb-tabset *ngIf="!onlyDayTab" (changeTab)="onTabChange($event)">
    <!-- Day selection -->
    <nb-tab [active]="activeTab === '1'" [tabId]="'1'" tabTitle="{{ 'Day' | translate }}">
      <div class="selection-type">
        <nb-calendar [(date)]="startDate" (dateChange)="onDateChange($event)" size="large"
                     startView="date"></nb-calendar>
        <button (click)="onTodayClicked()" class="today-button" fullWidth nbButton
                status="primary">{{ 'Today' | translate }}</button>
      </div>
    </nb-tab>
    <!-- Month selection -->
    <nb-tab [active]="activeTab === '2'" [tabId]="'2'" tabTitle="{{ 'Month' | translate }}">
      <div class="selection-type">

        <div class="title-bar">
          {{ interval }}
          <button (click)="decreaseInterval()" ghost nbButton>
            <nb-icon icon="chevron-left-outline" pack="eva"></nb-icon>
          </button>

          <button (click)="increaseInterval()" ghost nbButton>
            <nb-icon icon="chevron-right-outline" pack="eva"></nb-icon>
          </button>
        </div>

        <nb-calendar-month-picker [(month)]="month" [date]="month" (monthChange)="onMonthChange($event)"></nb-calendar-month-picker>
      </div>
    </nb-tab>
    <!-- Year selection -->
    <nb-tab [active]="activeTab === '3'" [tabId]="'3'" tabTitle="{{ 'Year' | translate }}">
      <div class="selection-type">
        <div class="title-bar">
          {{ getYearInterval() }}
          <button (click)="decreaseInterval()" ghost nbButton>
            <nb-icon icon="chevron-left-outline" pack="eva"></nb-icon>
          </button>

          <button (click)="increaseInterval()" ghost nbButton>
            <nb-icon icon="chevron-right-outline" pack="eva"></nb-icon>
          </button>
        </div>

        <nb-calendar-year-picker [(year)]="year" [date]="year" (yearChange)="onYearChange($event)"></nb-calendar-year-picker>
      </div>
    </nb-tab>
    <!-- Custom date selection -->
    <nb-tab [active]="activeTab === '4'" [tabId]="'4'" tabTitle="{{ 'DATE' | translate }}">
      <div class="horizontal-flex justify-content-center">
        <nb-calendar-range [(range)]="range" (rangeChange)="onCustomRangeChange($event)"></nb-calendar-range>

        <div *ngIf="displayCustomDateButtons" class="button-container">
          <button (click)="onTodayClicked()" class="custom-button" nbButton
                  status="primary">{{ 'Today' | translate }}</button>
          <button (click)="onLast24HoursClicked()" class="custom-button" nbButton
                  status="primary">{{ 'Last 24 Hours' | translate }}</button>
          <button (click)="onLastWeekClicked()" class="custom-button" nbButton
                  status="primary">{{ 'Last Week' | translate }}</button>
          <button (click)="onLastMonthClicked()" class="custom-button" nbButton
                  status="primary">{{ 'Last Month' | translate }}</button>
          <button (click)="onLast3MonthsClicked()" class="custom-button" nbButton
                  status="primary">{{ 'Last 3 Months' | translate }}</button>
          <button (click)="onLastYearClicked()" class="custom-button" nbButton
                  status="primary">{{ 'Last Year' | translate }}</button>
          <button (click)="onLast3YearsClicked()" class="custom-button" nbButton
                  status="primary">{{ 'Last 3 Years' | translate }}</button>
        </div>
      </div>
    </nb-tab>
    <!-- Period selection -->
    <nb-tab *ngIf="showPeriodTab" [active]="activeTab === '5'" [tabId]="'5'" tabTitle="{{ 'Period' | translate }}">
      <div class="horizontal-flex justify-content-center">
        <input class="period-input" nbInput placeholder="{{ 'Last'| translate }}" readonly status="basic" type="text">
        <input [defaultValue]="periodValue" (change)="onPeriodValueChange($event.target.value)" class="period-input" nbInput
               status="basic" type="number" min="1">
        <nb-select [selected]="periodUnit" (selectedChange)="onPeriodUnitChange($event)" class="period-input" selectWidth [options]="TimeIntervalKeys" [formatter]="timeIntervalFormatter">
          <nb-option *ngFor="let interval of TimeInterval | keyvalue" [value]="interval.value">{{('timeInterval_'+interval.value) | translate}}</nb-option>
        </nb-select>
      </div>
    </nb-tab>
  </nb-tabset>
</div>
