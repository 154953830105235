<!-- Shows all models -->
<ng-container *ngIf="step === 0">
  <uruk-list class="h-100" (onDeleteItem)="deleteModel($event)" [type]="'MLModel'" [list]="paginatedModels"
             [displayCount]="false" [displaySearch]="true"  [displayActionButton]="true" [displayDeleteButton]="displayDeleteButton"
             [displayEditButton]="false" (onActionItem)="selectModel($event)"
             [columnMetadata]="columnMetadata" [statusMetadata]="statusMetadata" [searchPlaceholder]="'Search in models...'"
             [paginationCallback]="paginationCallback" (onSortAscending)="sortAscending($event)" (onSortDescending)="sortDescending($event)"
             (onTextSearch)="searchModels($event)"></uruk-list>
</ng-container>

<!-- Shows all trained model files for the selected model -->
<ng-container *ngIf="step === 1">
  <div class="trained-models">
    <div class="uruk-items-header">
      <div class="col-6">{{ 'Model' | translate }}: {{ selectedModel.name }}</div>
    </div>

    <div class="uruk-items">
      <nb-radio-group [(value)]="selectedTrainedModel" (valueChange)="onTrainedModelSelect()">
        <nb-radio class="uruk-items-row" *ngFor="let model of selectedModel.models" [value]="model"> {{ model }} </nb-radio>
      </nb-radio-group>
    </div>
  </div>
</ng-container>
