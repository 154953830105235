/**
 * The LayerType is used to match the following types with the Layer name so that a corresponding LayerController can
 * be initiated in the LayerFactory service
 */
export enum LayerType {
  Alert = 'Alert',
  Junctions = 'Junctions',
  OffStreetParking = 'OffStreetParking',
  OnStreetParking = 'OnStreetParking',
  Vehicles = 'Vehicles',
  FireEngines = 'FireEngines',
  WeatherPollutionCSB = 'Weather Pollution CSB',
  WeatherPollutionCopercinus = 'Weather Pollution Copernicus',
  Trains = 'Trains',
  ElectricBuses = 'ElectricBuses',
  MGUBCastle = 'MGUBCastle',
  AsisVehicles = 'AsisVehicles',
  Tak = 'Tak',
  SolarPole = 'SolarPole',
  DEFIBRILLATOR = 'Defibrillator',
  AnnounceSensors = 'AnnounceSensors',
  JKGYS = 'Jkgys',
  JKGYSTak = 'JkgysTak',
  UrukRoute = 'UrukRoute',
  TcddTrains = 'TcddTrains',
  Drone = 'Drone'
}
