import {Component, Injector, Input, OnInit} from '@angular/core';
import {ChartComponent} from '../chart.component';
import {ChartType} from '../../../enums/chart-type.enum';
import {NGSIResult} from '../../../models/ngsi/ngsi-result';
import {VisualizationMode} from '../../../enums/visualization-mode.enum';
import {ChartOptionsUtil} from '../../../utils/chart-options-util';

@Component({
  selector: 'uruk-bar-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['../chart.component.scss']
})
export class BarChartComponent extends ChartComponent implements OnInit {

  // labels for the dimension axis
  @Input() labels: string[];

  // labels for multi/single series data
  @Input() seriesLabels: string[];

  // list of colors for all the bars; if more than one color are provided, then a gradient will be created
  @Input() barColors: string[];

  // list of color expressions for individual coloring of the bars. Evaluates to the first available expression
  @Input() colorExpressions: any[];

  // opacity settings for individual opacity setting of the bars.
  @Input() opacitySettings: any;

  // list of available tresholds to display on the chart
  @Input() tresholds: any[];

  // Orientation of the bar the chart. Either 'vbar' or 'hbar'. Default is 'vbar'.
  @Input() type: 'vbar' | 'hbar' = 'vbar';

  // Determines the visibility of split lines parallel to axes
  @Input() displaySplitLines: boolean = true;

  // responsiveness parameters
  private baseMarginPercentage = 7; // maximum possible margin for the minimum-dimension container
  private fontScaleFactor = 40; // a scale factor to determine the responsive font size depending on the dimension
  private marginScaleFactor = 300; // a scale factor to determine the responsive font size depending on the dimension
  private tooltipFontScaleFactor = 1.25; // a scale factor to determine the responsive font size for tooltips

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Returns the default options that won't change based on the chart container dimensions
   */
  defaultChartOptions() {
    let defaultOptions: any = {
      xAxis: {
        type: this.type === ChartType.VerticalBar ? 'category' : 'value',
        z: 10
      },
      yAxis: {
        type: this.type === ChartType.VerticalBar ? 'value' : 'category',
        z: 10
      },
      grid: {
        containLabel: true
      },
      tooltip: {
        trigger: 'item',
        formatter: this.tooltipFormatter.bind(this)
      }
    };

    // handle "vertical" bar chart axis properties
    if (this.type === ChartType.VerticalBar) {
      defaultOptions.xAxis.axisLabel = {
        inside: true,
        textStyle: {
          color: '#fff'
        },
        rotate: 90,
        overflow: 'truncate',
        fontWeight: 'normal'
      };
      defaultOptions.xAxis.axisTick = {alignWithLabel: true};
      defaultOptions.yAxis.splitLine = {show: this.displaySplitLines};
      defaultOptions.yAxis.axisLabel = {
        fontWeight: 'bold',
        formatter: this.valueAxisFormatter.bind(this)
      };
    }
    // handle "horizontal" bar chart axis properties
    else {
      defaultOptions.yAxis.axisLabel = {
        inside: true,
        textStyle: {
          color: '#fff'
        },
        overflow: 'truncate',
        fontWeight: 'normal'
      };
      defaultOptions.yAxis.axisTick = {alignWithLabel: true};
      defaultOptions.xAxis.splitLine = {show: this.displaySplitLines};
      defaultOptions.xAxis.axisLabel = {
        fontWeight: 'bold',
        formatter: this.valueAxisFormatter.bind(this)
      };
    }

    // set the data if the chart is initialized with some data
    if (this.data || this.seriesLabels) {
      defaultOptions.series = this.createDataSeries(this.data, this.seriesLabels);
    }

    // set the x axis labels if provided
    if (this.labels) {
      if (this.type === ChartType.VerticalBar) {
        defaultOptions.xAxis.data = this.labels;
        defaultOptions.xAxis.show = this.labels.length <= this.maxNumberOfItemToDisplayLabels;
      } else {
        defaultOptions.yAxis.data = this.labels;
        defaultOptions.yAxis.show = this.labels.length <= this.maxNumberOfItemToDisplayLabels;
      }
    }

    // set color palette for the chart
    if (this.colors) {
      defaultOptions.color = this.colors;
    }

    // set legend options
    if (this.showLegend) {
      defaultOptions.legend = {
        data: this.seriesLabels,
        textStyle: {
          color: 'white'
        }
      }
    }

    if (this.chartOptions) {
      defaultOptions = ChartOptionsUtil.mergeChartOptions(defaultOptions, this.chartOptions);
    }

    return defaultOptions;
  }

  /**
   * Returns the chart options with responsive parameters
   */
  responsiveChartOptions() {
    let viewportMin;

    switch (this.visualizationMode) {
      case VisualizationMode.DOM: // Determine the min dimension for the chart container
        viewportMin = Math.min(this.echartsContainer.nativeElement.offsetWidth, this.echartsContainer.nativeElement.offsetHeight);
        break;
      case VisualizationMode.Dialog: // Container size cannot be determined when charts are displayed on dialogs; use provided viewportMin
        viewportMin = this.dialogViewportMin;
        break;
    }

    const responsiveFontSize = this.baseFontSize + (viewportMin - this.baseDimension) / this.fontScaleFactor;
    const responsiveMargin = (this.baseMarginPercentage - (viewportMin - this.baseDimension) / this.marginScaleFactor).toString() + '%';

    const responsiveOptions: any = {
      xAxis: {
        axisLabel: {
          fontSize: responsiveFontSize,
        }
      },
      yAxis: {
        axisLabel: {
          fontSize: responsiveFontSize,
        }
      },
      grid: {
        top: responsiveMargin,
        bottom: responsiveMargin,
        right: responsiveMargin,
        left: responsiveMargin
      },
      tooltip: {
        textStyle: {
          fontSize: responsiveFontSize * this.tooltipFontScaleFactor
        }
      }
    };

    if (this.showLegend) {
      responsiveOptions.legend = {
        textStyle: {
          fontSize: responsiveFontSize
        }
      };
    }

    return responsiveOptions;
  }

  createDataSeries(seriesData, seriesLabels) {
    const series = [];

    for (let i = 0; i < seriesData.length; i++) {
      const data = seriesData[i];
      const seriesLabel = seriesLabels[i];

      const serie: any = {
        type: 'bar',
        name: seriesLabel || this.defaultSeriesName,
        barMaxWidth: '50vw',
        itemStyle: {
          borderRadius: this.type === ChartType.VerticalBar ? [50, 50, 0, 0] : [0, 50, 50, 0],
        }
      };

      if (this.barColors && this.barColors.length > 0) {
        serie.itemStyle.color = this.barColors.length > 1 ? this.createGradient(this.barColors) : this.barColors[0];
      }

      // set the data if the chart is initialized with some data
      if (data) {
        // get the dimension data which is used to evaluate opacity expressions if the corresponding settings are available
        let dimensionDataForOpacity = null;
        if (this.opacitySettings?.expressions) {
          dimensionDataForOpacity = this.rawData.find(d => d.dimension && d.chartAxisSetting.index === this.opacitySettings.dimensionIndex);
        }

        serie.data = data.map((value, index) => {
          const mappedData: any = {
            value: value
          };

          // set color expressions if provided in panel context
          if (this.colorExpressions && this.colorExpressions.length > 0) {
            mappedData.itemStyle = {
              color: this.evaluateExpression(value, this.colorExpressions, 'color')
            };
          }

          if (dimensionDataForOpacity) {
            mappedData.itemStyle = {
              ...mappedData.itemStyle,
              opacity: this.evaluateExpression(dimensionDataForOpacity.data[index], this.opacitySettings.expressions, 'opacity')
            };
          }

          return mappedData;
        });

        // if there is no bar colors provided but color expressions are provided, set the overall serie color.
        //    this is used for example for displaying overall chart color in legends
        // if there is no barColors and no colorExpressions in chartSettings.settings for the panel, then
        //    check whether there is any user defined color (there exists colorMap in chartSettings.settings),
        //    if yes, set colorBy in charts to "data", so that each data will have a different color in the bar-chart
        //    since default value for colorBy in echarts is "series" for bar-charts, same color is applied to all data
        if (!this.barColors || this.barColors.length === 0) {
          if (this.colorExpressions && this.colorExpressions.length)
            serie.itemStyle.color = serie.data[0].itemStyle.color;
          else if (this.colors)
            serie.colorBy = "data";
        }

        // set tresholds if provided
        if (this.tresholds) {
          serie.markLine = {
            symbol: 'none',
            data: this.tresholds.map(treshold => {
              const lineData: any = {
                name: treshold.label,
                label: {
                  color: 'white'
                },
                lineStyle: {
                  normal: {
                    color: treshold.color,
                    width: 2
                  }
                }
              };
              this.type === ChartType.VerticalBar ? lineData.yAxis = treshold.value : lineData.xAxis = treshold.value;
              return lineData;
            })
          };
        }
      }
      series.push(serie);
    }

    return series;
  }

  setData(data: NGSIResult[]) {
    [this.rawData, this.colors] = this.dataExtractionUtil.extractData(this.kpi, this.chartSettings, data);

    let dimensionData;
    const seriesData = [];

    this.rawData.forEach(axis => {
      if (axis.dimension) {
        dimensionData = axis;
      } else if (axis.series) {
        seriesData.push(axis);
      }
    });

    // extract chart essentials
    this.data = seriesData.map(seriesItem => seriesItem.data);
    this.seriesLabels = seriesData.map(seriesItem => seriesItem.chartAxisSetting.label);
    this.labels = dimensionData.data;

    // update chart data
    const series = this.createDataSeries(this.data, this.seriesLabels);
    this.setMultiSeriesData(series);

    this.type === ChartType.VerticalBar ? this.setXAxisLabels(this.labels) : this.setYAxisLabels(this.labels);
  }
}
