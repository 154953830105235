import {ComponentFactoryResolver, Directive, ViewContainerRef} from '@angular/core';
import {BasePageComponent} from '../components/base-page.component';
import {Page} from '../models/page.model';

/**
 * Directive to load different type of pages to the element annotated with this directive
 */
@Directive({
  selector: '[pageHost]',
})
export class PageHostDirective {
  constructor(public viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) { }

  /**
   * Loads given page to the host element. Type of the page is specified in page.componentType.
   * @param page
   */
  public loadPage(page: Page): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(page.componentType);

    const viewContainerRef = this.viewContainerRef;
    viewContainerRef.clear();

    // we wrap the loading in a setTimeout in order not to get ExpressionChangedAfterItHasBeenCheckedError for association of page
    setTimeout(() => {
      const componentRef = viewContainerRef.createComponent<BasePageComponent>(componentFactory);
      componentRef.instance.page = page;
    });
  }
}
