import {Component, Injector, Input, OnInit} from '@angular/core';
import {ChartComponent} from '../chart.component';

import * as echarts from 'echarts';
import {NGSIResult} from '../../../models/ngsi/ngsi-result';

declare const ecStat: any;

@Component({
  selector: 'uruk-scatter-chart',
  templateUrl: '../chart.component.html',
  styleUrls: ['../chart.component.scss']
})
export class ScatterChartComponent extends ChartComponent implements OnInit  {

  // The regression method if available
  @Input() regressionMethod: 'none' | 'linear' | 'exponential' | 'polynomial' = 'linear';

  // Determines the visibility of split lines parallel to axes
  @Input() displaySplitLines: boolean = true;

  // responsiveness parameters
  private baseSymbolSize = 5; // minimum size of the bubbles
  private baseMarginPercentage = 4; // maximum possible margin for the minimum-dimension container
  private fontScaleFactor = 25; // a scale factor to determine the responsive font size depending on the dimension
  private symbolSizeScaleFactor = 25; // a scale factor to determine the responsive font size depending on the dimension
  private marginScaleFactor = 200; // a scale factor to determine the responsive font size depending on the dimension

  constructor(injector: Injector) {
    super(injector);

    echarts.registerTransform(ecStat.transform.regression);

    // FIXME remove below
    this.data = [
      [10.0, 8.04],
      [8.07, 6.95],
      [13.0, 7.58],
      [9.05, 8.81],
      [11.0, 8.33],
      [14.0, 7.66],
      [13.4, 6.81],
      [10.0, 6.33],
      [14.0, 8.96],
      [12.5, 6.82],
      [9.15, 7.20],
      [11.5, 7.20],
      [3.03, 4.23],
      [12.2, 7.83],
      [2.02, 4.47],
      [1.05, 3.33],
      [4.05, 4.96],
      [6.03, 7.24],
      [12.0, 6.26],
      [12.0, 8.84],
      [7.08, 5.82],
      [5.02, 5.68]
    ];
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Returns the default options that won't change based on the chart container dimensions
   */
  defaultChartOptions() {
    const defaultOptions: any = {
      series: [{
        type: 'scatter',
        name: this.seriesName || this.defaultSeriesName,
        datasetIndex: 0
      }],
      dataset: [{}, {
        transform: {
          type: 'ecStat:regression',
          config: {
            method: this.regressionMethod,
            formulaOn: 'end'
          }
        }
      }],
      xAxis: {
        axisLabel: {
          fontWeight: 'bold'
        },
        splitLine: {
          show: this.displaySplitLines
        }
      },
      yAxis: {
        axisLabel: {
          fontWeight: 'bold'
        },
        splitLine: {
          show: this.displaySplitLines
        }
      },
      grid: {
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      }
    };

    // set the data if the chart is initialized with some data
    if (this.data) {
      defaultOptions.dataset[0].source = this.data;
    }

    // set the regression type if it is specified by the user
    if (this.regressionMethod !== 'none') {
      defaultOptions.series.push({
        type: 'line',
        name: 'Regression',
        smooth: true,
        datasetIndex: 1,
        symbolSize: 0.1,
        symbol: 'circle',
        label: {
          show: true,
          color: '#ffffff',
          fontSize: this.baseFontSize
        },
        labelLayout: {
          dx: -50
        },
        encode: {label: 2, tooltip: 1}
      });
    }

    return defaultOptions;
  }

  /**
   * Returns the chart options with responsive parameters
   */
  responsiveChartOptions() {
    const viewportMin = Math.min(this.echartsContainer.nativeElement.offsetWidth, this.echartsContainer.nativeElement.offsetHeight);
    const responsiveFontSize = this.baseFontSize + (viewportMin - this.baseDimension) / this.fontScaleFactor;
    const responsiveMargin = (this.baseMarginPercentage - (viewportMin - this.baseDimension) / this.marginScaleFactor).toString() + '%';
    const responsiveSymbolSize = this.baseSymbolSize + (viewportMin - this.baseDimension) / this.symbolSizeScaleFactor;

    const responsiveOptions: any = {
      series: [{
        symbolSize: responsiveSymbolSize
      }],
      xAxis: {
        axisLabel: {
          fontSize: responsiveFontSize,
        }
      },
      yAxis: {
        axisLabel: {
          fontSize: responsiveFontSize,
        }
      },
      grid: {
        top: responsiveMargin,
        bottom: responsiveMargin,
        right: responsiveMargin,
        left: responsiveMargin,
        containLabel: true
      },
      tooltip: {
        textStyle: {
          fontSize: responsiveFontSize
        }
      },
    };

    if (this.regressionMethod !== 'none') {
      responsiveOptions.series.push({
        label: {
          fontSize: responsiveFontSize
        }
      });
    }

    return responsiveOptions;
  }

  setData(data: NGSIResult[]) {
    // do some logic

    // update chart data
    this.setSeriesData(data);
  }
}
