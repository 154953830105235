import {Visualization} from './visualization.model';
import {VisualizationFactory} from '../factories/visualization.factory';

export class GenericVisualization extends Visualization{

  /**
   * Set of settings for different types of visualizations
   */
  visualizations: Visualization[];

  /**
   * Flag indicating the visibility of points (as icons) regardless of the actual visualization.
   * For instance, if the location of an entity corresponds to a polygon, an icon will also be
   * displayed for at the middles along with it.
   */
  shouldDisplayPoints: boolean;

  /**
   * Flag indicating the visibility of points while other visualization types are hidden when
   * a breakpoint zoom level is exceeded
   */
  shouldReduceToPoints: boolean;

  /**
   * A certain zoom level where different logic will be implemented when passing through this value
   */
  breakpointZoomLevel: number;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    if (data.visualizations) {
      this.visualizations = data.visualizations.map(v => VisualizationFactory.create(v));
    }
    this.shouldDisplayPoints = data.shouldDisplayPoints;
    this.shouldReduceToPoints = data.shouldReduceToPoints;
    this.breakpointZoomLevel = data.breakpointZoomLevel;

  }
}
