import {TrainDefinitionModel} from '../../../../../../shared/models/train-definition/train-definition.model';
import {TractionModel} from '../../../../../../shared/models/train-definition/traction.model';
import {BrakeModel} from '../../../../../../shared/models/train-definition/brake.model';
import {PowerVoltageModel} from '../../../../../../shared/models/train-definition/power-voltage.model';
import {AirConditionModel} from '../../../../../../shared/models/train-definition/air-condition.model';
import {DoorModel} from '../../../../../../shared/models/train-definition/door.model';
import {CommunicationModel} from '../../../../../../shared/models/train-definition/communication.model';
import {OtherModel} from '../../../../../../shared/models/train-definition/other.model';
import {SignalizationModel} from '../../../../../../shared/models/train-definition/signalization.model';
import {RandomUtil} from '../../../../../../shared/utils/random.util';

export class TrainDataUtil {
  randomUtil = new RandomUtil();

  constructor() {
  }

  public generateRandomTrainData(isError: boolean): TrainDefinitionModel {
    // Initialize traction variables
    const fDriveForceAx3 = (this.randomUtil.getRandomInt(0, 10000) / 1000).toFixed(2);
    const fDriveForceAx4 = (this.randomUtil.getRandomInt(0, 10000) / 1000).toFixed(2);
    const requiredTractionForce = (parseFloat(fDriveForceAx3) + parseFloat(fDriveForceAx4)).toFixed(2);
    const forceAppliedAx3 = (this.randomUtil.getRandomInt(0, 10000) / 1000).toFixed(2);
    const forceAppliedAx4 = (this.randomUtil.getRandomInt(0, 10000) / 1000).toFixed(2);
    const appliedTractionForce = (parseFloat(forceAppliedAx3) + parseFloat(forceAppliedAx4)).toFixed(2);

    // Initialize brake variables
    const diagBrDirectList = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const diagBrDirect = diagBrDirectList.some(value => value === 1) ? 1 : 0;
    const majorBrParkList = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const majorBrPark = majorBrParkList.some(value => value === 1) ? 1 : 0;

    // Initialize door variables
    const outerDoorStatus = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 2));
    const doorFaultPriorityAActive = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const doorFaultPriorityBActive = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const doorFaultPriorityCActive = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const stepFaultPriorityAActive = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const stepFaultPriorityBActive = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const stepFaultPriorityCActive = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const doorInsulationList = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));
    const innerDoorError = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError));

    const door1LevelFault =
      doorFaultPriorityAActive.some(value => value === 1) ||
      stepFaultPriorityAActive.some(value => value === 1) ? 1 : 0;

    const door2LevelFault =
      doorFaultPriorityBActive.some(value => value === 1) ||
      stepFaultPriorityBActive.some(value => value === 1) ||
      doorFaultPriorityCActive.some(value => value === 1) ||
      stepFaultPriorityCActive.some(value => value === 1) ? 1 : 0;

    const ickapi1SeviyeAriza = innerDoorError.some(value => value === 1) ? 1 : 0;

    const kapi1SeviyeAriza =
      doorFaultPriorityAActive.some(value => value === 1) ||
      stepFaultPriorityAActive.some(value => value === 1) ? 1 : 0;

    const prmAsansorKapi1Devrede = 0;
    const doorLoopOkLed =
      doorInsulationList.some(value => value === 1) ||
      doorFaultPriorityAActive.some(value => value === 1) ||
      stepFaultPriorityAActive.some(value => value === 1) ||
      doorFaultPriorityBActive.some(value => value === 1) ||
      stepFaultPriorityBActive.some(value => value === 1) ||
      doorFaultPriorityCActive.some(value => value === 1) ||
      stepFaultPriorityCActive.some(value => value === 1) ||
      innerDoorError.some(value => value === 1) ? 1 : 0;

    const slDoorControlByPass = 0;

    // Initialize other variables
    const contactorHalfLightList = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 1));
    const contactorEmergencyLightList = Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 1));
    const interiorLightingModeList = contactorHalfLightList.map((halfLight, index) =>
      this.determineInteriorLightingMode(halfLight, contactorEmergencyLightList[index])
    );

    // Traction Model
    const traction = new TractionModel({
      fDriveForceAx3: fDriveForceAx3,
      fDriveForceAx4: fDriveForceAx4,
      requiredTractionForce: requiredTractionForce,
      forceAppliedAx3: forceAppliedAx3,
      forceAppliedAx4: forceAppliedAx4,
      appliedTractionForce: appliedTractionForce,
      cutOffTraction: this.randomUtil.getRandomInt(1, 1, 0, 0, isError),
      cutOffByPass: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      tractionNull: this.randomUtil.getRandomInt(1, 1, 0, 0, isError),
      motorCooling2mvcbProtection: 0,
      tempTrcInv1: 0,
      tempTrcInv2: 0,
      tempTrcRec: 0,
      vTrcDclink: 0,
      tempMotorAx3: 0,
      tempMotorAx4: 0,
      forceAvmaxAx3: 0,
      forceAvmaxAx4: 0,
      velMotorAx3: 0,
      velMotorAx4: 0,
      edBar: 0,
      tractionBar: 0,
      tractionCutOffLed: 0,
      tractionDcLinkOk: 0,
    });

    // Brake Model
    const brake = new BrakeModel({
      bp: 0,
      mrp: 0,
      pressureGovernorPBReleased: this.randomUtil.getRandomInt(1, 1, 0, 0, isError),
      pressureGovernorDetectionEmergencyBrake: this.randomUtil.getRandomInt(1, 1, 0, 0, isError),
      pressureGovernorIndirectBrakeControl: this.randomUtil.getRandomInt(1, 1, 0, 0, isError),
      diagBrDirect: diagBrDirect,
      diagBrDirectList: diagBrDirectList,
      majorBrPark: majorBrPark,
      majorBrParkList: majorBrParkList,
      majorBrWsp: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      ebcu: Array.from({length: 5}, () => this.randomUtil.getRandomInt(1, 1, 0, 0, isError)),
      diagBrCPress: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      emergencyStatusBrakeLed: 0,
    });

    // Power Voltage Model
    const powerVoltage = new PowerVoltageModel({
      majorUnderVoltageError: 0,
      catenaryVoltage: 0,
      yuksekAkimKaynakliVakumHatasi: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      hvConfigError: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      pantoABar: 0,
      tumPanolarIzole: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      pantografDustu: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      diagPantoComp: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      pTrain: 0,
      majorMvError: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      vcbCloseSka1: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      vcbCloseSka2: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      pressurePs1SafeguardAddSka1: 1,
      pressurePs1SafeguardAddSka2: 0,
      hvDisconnectorCloseSKA1: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      hvDisconnectorCloseSKA2: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      hvDisconnectorCloseOA2: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      tractionTransformerOilFlow: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      tractionTransformerOilLevel: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      tractionTransformerOverPressure: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      measuringBatteryVoltage: Array.from({length: 5}, () => parseFloat((this.randomUtil.getRandomInt(6, 160, 0, 5, isError) * 15.71).toFixed(2))),
      tempBattery: Array.from({length: 5}, () => this.randomUtil.getRandomInt(-10, 40, 41, 127, isError)),
      aBatteryCharger: Array.from({length: 5}, () => this.randomUtil.getRandomInt(-128, 127)),
      tempApsRec: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      tempApsInv: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      vApsDclink: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 159, 160, 250, false) * 5),
      tractionTransformerTemp: Array.from({length: 5}, () => this.randomUtil.getRandomInt(-128, 110, 111, 127, isError)),
      voltPantoSka1: 0,
      mediumVoltageBackupEnabled2Vehicles: 0,
    });

    // Air Condition Model
    const airCondition = new AirConditionModel({
      driverAirConditioningTemperature: 0,
      passengerAirConditioningMode: this.randomUtil.getRandomInt(0, 3),
      hvacsInhibitionList: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 1)),
      driverMajorHvac: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      passengerMajorHvac1: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      passengerMajorHvac2: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      driverMinorHvac: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      passengerMinorHvac1: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
      passengerMinorHvac2: Array.from({length: 5}, () => this.randomUtil.getRandomInt(0, 0, 1, 1, isError)),
    });

    // Door Model
    const door = new DoorModel({
      door1LevelFault: door1LevelFault,
      door2LevelFault: door2LevelFault,
      ickapi1SeviyeAriza: ickapi1SeviyeAriza,
      kapi1SeviyeAriza: kapi1SeviyeAriza,
      prmAsansorKapi1Devrede: prmAsansorKapi1Devrede,
      doorLoopOkLed: doorLoopOkLed,
      slDoorControlByPass: slDoorControlByPass,
      innerDoorError: innerDoorError,
      outerDoorStatus: outerDoorStatus,
      doorFaultPriorityAActive: doorFaultPriorityAActive,
      doorFaultPriorityBActive: doorFaultPriorityBActive,
      doorFaultPriorityCActive: doorFaultPriorityCActive,
      stepFaultPriorityAActive: stepFaultPriorityAActive,
      stepFaultPriorityBActive: stepFaultPriorityBActive,
      stepFaultPriorityCActive: stepFaultPriorityCActive,
      doorInsulationList: doorInsulationList,
    });

    const other = new OtherModel({
      contactorHalfLightList: contactorHalfLightList,
      contactorEmergencyLightList: contactorEmergencyLightList,
      interiorLightingModeList: interiorLightingModeList,
      toiletIsNotActive: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      toiletWaterLevelLow: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      toiletBowlFull: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      toiletWastewaterTankFull: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      toiletSosSignal: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
    });

    const communication = new CommunicationModel({});

    const signalization = new SignalizationModel({
      etcsIsolationByPass: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      ertmsCutOff: this.randomUtil.getRandomInt(1, 1, 0, 0, isError),
      atsByPass: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
      ertmsServiceBrake: this.randomUtil.getRandomInt(0, 0, 1, 1, isError),
    });

    // Construct and return the TrainDefinitionModel
    return new TrainDefinitionModel({
      traction: traction,
      brake: brake,
      powerVoltage: powerVoltage,
      airCondition: airCondition,
      door: door,
      other: other,
      communication: communication,
      signalization: signalization,
      fDriveApplied: 0,
      hmiJourneyNo: 0,
      location: 0,
      speed: 0,
      distanceTraveled: 0,
      ascLimit: 0,
      externalTemperature: 0,
      predictiveMaintenanceCode: 0,
      ApsDcLinkOk: 0,
      lcuMaintenance1: 0
    });
  }

  private determineInteriorLightingMode(halfLight: number, emergencyLight: number): number {
    let mode: number;

    if (halfLight === 1 && emergencyLight === 1) {
      mode = 3;
    } else if (halfLight === 1 && emergencyLight === 0) {
      mode = 2;
    } else if (halfLight === 0 && emergencyLight === 1) {
      mode = 1;
    } else {
      mode = 0;
    }
    return mode;
  }
}
