import { Pipe, PipeTransform } from '@angular/core';

/**
 * Searches the given text in the metadata of EntityResults and returns the matching ones in the following format:
 *  {
 *    "id": <Entity id>
 *    "match": <Entity information matching the search text>
 *  }
 * */
@Pipe({
  name: 'entitySearch',
})
export class EntitySearchPipe implements PipeTransform {

  constructor() {

  }

  /**
   * 
   * @param metadataList the metadata list of EntityResults
   * @param searchText search text
   * @param maxResults the maximumum number of results
   * @return search results
   * */
  transform(metadataList: any[], searchText: string, maxResults = 5): any[] {
    const results = []
    if (metadataList?.length) {
      for (let metadata of metadataList) {
        const properties = Object.keys(metadata)
        for (let property of properties) {
          const value: string = metadata[property]
          if (value.match(new RegExp(`${searchText}`, 'i'))) {
            const count = results.push({
              "id": metadata.id,
              "match": value
            })
            if (count === maxResults) {
              return results;
            }
          }
        }
      }
    }

    return results;
  }
}
