<div class="uruk-list">
  <div class="uruk-list-status">
    <div [ngClass]="{'disabled':isMultipleActionsDisable}" *ngIf="displayMultipleActions" class="list-multi-actions">
      <div (click)="onUpdateItems.emit()" [nbTooltip]="'Update'| translate" class="list-action-button flex-centered">
        <div class="icon-text-container">
          <nb-icon [icon]="'sync-outline'" pack="eva"></nb-icon>
          <span>{{'Update'| translate}}</span>
        </div>
      </div>

      <div  (click)="onResetItems.emit()" [nbTooltip]="'Set Default Settings'| translate" class="list-action-button flex-centered">
        <div class="icon-text-container">
          <nb-icon [icon]="'refresh-outline'" pack="eva" ></nb-icon>
          <span>{{'Set Default Settings'| translate}}</span>
          </div>
      </div>

      <div (click)="onDeleteItems.emit()" [nbTooltip]="'Delete Devices'| translate" class="list-action-button flex-centered">
        <nb-icon [icon]="'trash-outline'" pack="eva"></nb-icon>
      </div>
    </div>

    <ng-container *ngFor="let status of statusMetadata">
      <span class="status-text {{status.class}}">{{status.title | translate}}
        <span *ngIf="status.count >= 0 && displayCount" class="status-count">{{status.count}}</span>
      <ng-container *ngIf="status.commaSeparated">,</ng-container></span>
    </ng-container>

    <div [ngClass]="{'search-bar-container-wrapper':displayFilter && displaySearch}">
      <div *ngIf="displaySearch" class="search-bar-container">
        <nb-form-field>
          <input type="text" [(ngModel)]="searchText" [placeholder]="searchPlaceholder | translate" (keyup.enter)="handleTextSearch()" nbInput>
          <button class="search-input-button" nbSuffix nbButton (click)="handleTextSearch()">
            <nb-icon [icon]="'search-outline'" pack="eva"></nb-icon>
          </button>
        </nb-form-field>

        <button *ngIf="displayFilter" nbButton class="filter-button" size="small" (click)="onFilterItems.emit()">
          <i class="fa fa-filter fa-xs"></i>
        </button>

        <!--
        <button nbButton class="filter-button" (click)="handleAlertFilterClick()">
          <nb-icon [icon]="'filter'" pack="uruk"> </nb-icon>
        </button>
        -->
      </div>
    </div>

  </div>
  <div class="uruk-list-view">
    <div class="list-view">
      <div class="list-header">
        <div *ngFor="let data of columnMetadata" [ngClass]="'col-' + data.columnWidth + (data.sortable ? ' sortable' : '')" class="list-header-column">
          <div *ngIf="displayCheckBox && data.field === 'id'" style="margin-left: 0; margin-right: 10px;">
            <input #selectAllCheckbox type="checkbox" name="" (change)="handleSelectAll()">
          </div>
          {{ data.label | translate }}
          <div *ngIf="data.sortable">
            <nb-icon icon="caret-up"   pack="fa" (click)="sortAscending(data.field)"></nb-icon>
            <nb-icon icon="caret-down" pack="fa" (click)="sortDescending(data.field)"></nb-icon>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
      <div *ngIf="!tableDataLoading" class="list-body">
        <div *ngFor="let item of list | metadata | async;let i=index" class="list-row"
        [ngClass]="{'ten-rows':pageSize === 10,'twenty-rows':pageSize === 20,'thirty-rows':pageSize === 30}">
          <div *ngFor="let data of columnMetadata" [ngClass]="'col-' + data.columnWidth" [title]="item[data.field + 'Tooltip'] || ''">
            <div class="vertical-container">
              <div *ngIf="displayCheckBox && data.field === 'id'">
                <input type="checkbox" class="list-actions actions-check-input" name="" (change)="onSelectItem.emit(item['id'])">
              </div>
              <span [innerHTML]="item[data.field] | translate"></span>
            </div>
          </div>
          <div class="col-1 list-actions">
            <div (click)="onActionItem.emit(item)" *ngIf="displayActionButton"
                 class="list-action-button flex-centered">
              <i [ngClass]="actionIconClass"></i>
            </div>

            <div (click)="onEditItem.emit(list[i])"  *ngIf="displayEditButton"
                 class="list-action-button flex-centered">
              <i [ngClass]="editIconClass"></i>
            </div>

            <div (click)="onDeleteItem.emit(item.id)" *ngIf="deleteButtonVisibilityForItem[i]"
                 class="list-action-button flex-centered">
              <i [ngClass]="deleteIconClass"></i>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="tableDataLoading">
        <div class="list-loader">
          <ng-lottie [options]="lottieOptions"></ng-lottie>
        </div>
      </ng-container>
      <div class="list-footer">
        <pagination *ngIf="statusMetadata.length" [total]="statusMetadata[0].count" (paginate)="paginate($event)"
         [type]="type"></pagination>
      </div>
    </div>
  </div>
</div>
