import {Injectable, Injector} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BaseHttpClientService} from './base-http-client.service';

/**
 * Service get map session info
 */
@Injectable({
  providedIn: 'root'
})
export class MapService extends BaseHttpClientService {

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, '');
  }

  getSessionOfMap(): Promise<any> {
    const url: string = `${this.endpoint}/createMapSessions`;

    return this.httpClient.post(url, {}).toPromise();
  }
}
