<closeable-dialog class="add-page-icon-dialog">
  <!-- Dialog header -->
  <div dialog-card-header>
    <label>
      {{ 'Page Icon' | translate }}
    </label>
  </div>
  <!-- Dialog body displaying a list of icons for the selection-->
  <div class="dialog-width" dialog-card-body>
    <div class="form-group">
      <div>
        <uruk-icon-selection (onIconSelected)="onIconSelected($event)"></uruk-icon-selection>
      </div>
    </div>
  </div>
  <!-- Dialog footer displaying action button to save selection-->
  <div dialog-card-footer>
    <button (click)="save()" class="float-right" nbButton status="primary" type="submit">
      {{ 'Save' | translate }}
    </button>
  </div>
</closeable-dialog>
