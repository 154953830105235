import {TimeUtil, TimeInterval} from '../../utils/time-util';
import {NGSITemporalQuery} from '../ngsi/ngsi-temporal-query.model';
import {Duration} from '../generic/duration.model';
import {TemporalQueryType} from '../../enums/temporal-query-type.enum';
import {NGSITemporalRelation} from '../../enums/ngsi-query.enum';
import {Persistable} from '../persistanble';

/**
 * A wrapper class to handle date selection and manage NGSI temporal queries
 */
export class UrukTemporalQuery extends NGSITemporalQuery implements Persistable {

  /**
   * Type of the temporal query
   */
  type: TemporalQueryType;

  /**
   * Period as string in the following format: n{@link TimeInterval}
   */
  period: string;

  /**
   * A flag that indicates whether start and end of the temporal query is equivalent today
   */
  isToday: boolean;

  /**
   * Display text for the temporal query
   */
  displayText: string;

  /**
   * Time expression for specifying panel/layer specific local NGSI context
   */
  timeExpression?: string;

  /**
  * Ending time expression for specifying panel/layer specific local NGSI context
  */
  endTimeExpression?: string;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    // initialize fields
    this.type = data.type;
    this.period = data.period;
    this.isToday = data.isToday;
    this.timeExpression = data.timeExpression;
    this.endTimeExpression = data.endTimeExpression;

    if (this.timeExpression && this.endTimeExpression) {
      this.time = TimeUtil.parseStringTime(this.timeExpression);
      this.endTime = TimeUtil.parseStringTime(this.endTimeExpression);

      // populate the type and period information which are missing when the query data is retrieved from the backend
      if (!this.type) {
        this.type = TemporalQueryType.PERIOD;
      }
      if (!this.period) {
        this.period = this.timeExpression.substring(this.endTimeExpression.length + 1);
      }
    }

    // set display text
    switch (this.type) {
      case TemporalQueryType.DATE:
        if (this.isToday) {
          this.displayText = 'Today';
        } else {
          this.displayText = TimeUtil.serializeReadableDate(this.time);
        }
        break;
      case TemporalQueryType.MONTH:
        this.displayText = TimeUtil.serializeReadableShortDate(this.time);
        break;
      case TemporalQueryType.YEAR:
        this.displayText = TimeUtil.serializeYear(this.time);
        break;
      case TemporalQueryType.PERIOD:
        this.displayText = TimeUtil.getDisplayNameOfPeriod(this.period);
        break;
      case TemporalQueryType.CUSTOM:
        this.displayText = TimeUtil.serializeReadableDate(this.time) + ' - ' + TimeUtil.serializeReadableDate(this.endTime);
        break;
    }
    if (!this.type) {
      this.displayText = TimeUtil.serializeReadableDate(this.time) + ' - ' + TimeUtil.serializeReadableDate(this.endTime);
    }
  }

  public static convertDateQueryToDuration(temporalQuery: UrukTemporalQuery): Duration {
    const duration: Duration = new Duration();
    switch (temporalQuery.type) {
      case TemporalQueryType.DATE:
      case TemporalQueryType.MONTH:
      case TemporalQueryType.YEAR:
        duration.startDate = temporalQuery.time;
        break;
      case TemporalQueryType.CUSTOM:
        duration.startDate = temporalQuery.time;
        duration.endDate = temporalQuery.endTime;
    }
    return duration;
  }

  /**
   * Parses the given string representation of UrukTemporalQuery and returns the corresponding UrukTemporalQuery. 
   * @param time the string representation of UrukTemporalQuery
   * @throws an exception if the given string representation is not handled
   * @returns the corresponding UrukTemporalQuery
   */
  public static parseTemporalQuery(time:string): UrukTemporalQuery{
    // check if the given representatin is a date
    const date = TimeUtil.parseDate(time)
    if(date.toString() != "Invalid Date"){
      return new UrukTemporalQuery({
        time: date,
        type: TemporalQueryType.DATE
      });
    } 
    // check if the given representatin is a datetime
    const dateTime = TimeUtil.parseDatetimeISO(time)
    if(dateTime.toString() != "Invalid Date"){
      return new UrukTemporalQuery({
        time: dateTime,
        type: TemporalQueryType.DATE
      });
    } 
    // check if the given representatin is an hour
    const hour = TimeUtil.parseHour(time)
    if(hour.toString() != "Invalid Date"){
      return new UrukTemporalQuery({
        time: hour,
        type: TemporalQueryType.DATE
      });
    } 
    // throw an exception for the unhandled UrukTemporalQuery types
    throw Error(`Parsing of ${time} is not handled !`)
  }

  public static createTemporalQuery(start: Date, end: Date, type: TemporalQueryType, isToday = false): UrukTemporalQuery {
    return new UrukTemporalQuery({
      timerel: NGSITemporalRelation.BETWEEN,
      time: start,
      endTime: end,
      type: type,
      isToday: isToday
    });
  }

  /**
   * Creates the temporal query for the given period.
   * @param period Period as string in the following format: n{@link TimeInterval}
   * @return the temporal query
   * */
  public static createTemporalQueryForPeriod(period: string): UrukTemporalQuery {
    return  new UrukTemporalQuery({
      timerel: NGSITemporalRelation.BETWEEN,
      period: period,
      type: TemporalQueryType.PERIOD,
      timeExpression: `now-${period}`,
      endTimeExpression: 'now'
    });
  }

  public static today() {
    const today = TimeUtil.today();
    const query = this.createTemporalQuery(TimeUtil.startOfDay(today), TimeUtil.endOfDay(today), TemporalQueryType.DATE, true);
    return query;
  }

  public static last24Hours() {
    return new UrukTemporalQuery({
      type: TemporalQueryType.CUSTOM,
      timeExpression: 'now-1d',
      endTimeExpression: 'now'
    });
  }

  public static lastWeek() {
    const today = TimeUtil.today();
    return this.createTemporalQuery(TimeUtil.addWeeks(TimeUtil.startOfDay(today), -1), TimeUtil.endOfDay(today), TemporalQueryType.CUSTOM);
  }

  public static lastMonth() {
    const today = TimeUtil.today();
    return this.createTemporalQuery(TimeUtil.addMonths(TimeUtil.startOfDay(today), -1), TimeUtil.endOfDay(today), TemporalQueryType.CUSTOM);
  }

  public static last3Months() {
    const today = TimeUtil.today();
    return this.createTemporalQuery(TimeUtil.addMonths(TimeUtil.startOfDay(today), -3), TimeUtil.endOfDay(today), TemporalQueryType.CUSTOM);
  }

  public static lastYear() {
    const today = TimeUtil.today();
    return this.createTemporalQuery(TimeUtil.addYears(TimeUtil.startOfDay(today), -1), TimeUtil.endOfDay(today), TemporalQueryType.CUSTOM);
  }

  public static last3Years() {
    const today = TimeUtil.today();
    return this.createTemporalQuery(TimeUtil.addYears(TimeUtil.startOfDay(today), -3), TimeUtil.endOfDay(today), TemporalQueryType.CUSTOM);
  }

  public reset() {
    this.time = null;
    this.endTime = null;
    this.type = null;
    this.displayText = null;
    this.period = null;
    this.isToday = false;
  }

  copy(that: UrukTemporalQuery): void {
    super.copy(that);
    this.type = that.type;
    this.displayText = that.displayText;
    this.period = that.period;
    this.isToday = that.isToday;
  }

  createNGSIParameters(): any {
    if (this.timeExpression && this.endTimeExpression) {
      this.time = TimeUtil.parseStringTime(this.timeExpression);
      this.endTime = TimeUtil.parseStringTime(this.endTimeExpression);
    }
    return super.createNGSIParameters();
  }

  createPersistableObject(): any {
    const persistableObject: any = JSON.parse(JSON.stringify(this));
    if (this.timeExpression && this.endTimeExpression) {
      persistableObject['time'] = null;
      persistableObject['endTime'] = null;
    }
    return persistableObject;
  }
}
