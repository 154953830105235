import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ContextSource} from '../../../shared/models/context-source.model';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {Context} from '../../../shared/models/context.model';
import { SearchQuery } from 'app/shared/models/query/search-query.model';
import { RestUtil } from 'app/shared/utils/rest-util';

/**
 * A service to handle context-source sources
 */
@Injectable()
export class ContextSourceService extends BaseHttpClientService {
  private readonly contextsPath = 'contexts';

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'contextsources');
  }

  /**
   * Creates a context-source source on the authenticated realm.
   * @param contextSource Context source to be created
   */
  createContextSource(contextSource: ContextSource): Observable<ContextSource> {
    return this.httpClient.post(this.endpoint, contextSource).pipe(map(response => new ContextSource(response)));
  }

  /**
   * Retrieves the context-source sources in the authenticated realm.
   */
  getAllContextSources(): Observable<ContextSource[]> {
    let url: string = this.endpoint;
    const parameters: string = RestUtil.createURLParameters(new SearchQuery());
    if (parameters) {
      url += '?' + parameters;
    }
    return this.httpClient.get<ContextSource[]>(url).pipe(map(response => response.map(item => new ContextSource(item))));
  }

  /**
   * Retrieves the given context-source source.
   * @param sourceId Identifier of context-source source to be retrieved
   */
  getContextSource(sourceId: string): Observable<ContextSource> {
    const url = `${this.endpoint}/${sourceId}`;
    return this.httpClient.get<ContextSource>(url).pipe(map(response => new ContextSource(response)));
  }

  /**
   * Deletes the given context-source source
   * @param sourceId Id of the context-source source
   */
  deleteContextSource(sourceId: string) {
    const url = `${this.endpoint}/${sourceId}`;
    return this.httpClient.delete(url);
  }

  /**
   * Enables the given context-source source within the authenticated realm.
   * @param sourceId Id of the context-source source
   */
  enableContextSource(sourceId: string): Observable<any> {
    const url = `${this.endpoint}/${sourceId}/status`;
    return this.httpClient.put(url, {});
  }

  /**
   * Disables the given context-source source within the authenticated realm.
   * @param sourceId Id of the context-source source
   */
  disableContextSource(sourceId: string): Observable<any> {
    const url = `${this.endpoint}/${sourceId}/status`;
    return this.httpClient.delete(url);
  }

  /**
   * Allows data provision for the given entity type.
   * @param sourceId Id of the context-source source
   * @param context Entity type that client will be allowed to send data for (StructureDefinition.id)
   */
  addDataProvisionRightsForContext(sourceId: string, context: Context): Observable<any> {
    const url = `${this.endpoint}/${sourceId}/${this.contextsPath}/${context.entityType}`;
    return this.httpClient.put(url, context);
  }

  /**
   * Removes the data provision rights for client for the given entity type
   * @param sourceId Id of the context-source source
   * @param entityType Entity type that client will be prevented from sending data for (StructureDefinition.id)
   */
  removeDataProvisionRightsForContext(sourceId: string, entityType: string) {
    const url = `${this.endpoint}/${sourceId}/${this.contextsPath}/${entityType}`;
    return this.httpClient.delete(url);
  }
}
