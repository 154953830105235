import {QueryOptions} from '../ngsi/query-options';
import {NGSIPath} from '../ngsi/ngsi-path.model';
import {Geometry} from '../../enums/ngsi-query.enum';
import {TemporalValue} from './entity/temporal/temporal-value';
import {PostalAddress} from './entity/postal-address.model';

export class EntityResult {
  constructor(public entity: any, public queryOptions: QueryOptions) {
    this.reverseCoordinates();
  }

  private reverseCoordinates(): void {
    if (this.entity.location) {
      const locationValue: any = this.getSingleElementValue('location');
        if (locationValue.type === Geometry.POINT) {
          locationValue.coordinates = locationValue.coordinates.reverse();
        } else if (locationValue.type === Geometry.LINE_STRING) {
          locationValue.coordinates = locationValue.coordinates.map(item => item.reverse());
        }
      }
    }

  /**
   * Gets the identifier of the entity
   */
  get id(): string {
    return this.entity.id;
  }

  /**
   * Gets the type of the entity
   */
  get type(): string {
    return this.entity.type;
  }

  /**
   * Gets the value of the specified element
   * @param elementName
   */
  getElementValue(elementName: string): any {
    if (this.entity[elementName]) {
      const path: NGSIPath = new NGSIPath({
        propOrRelPath: [
          elementName
        ]
      });
      return path.executeOnJson(this.entity, this.queryOptions);
    } else {
      return null;
    }
  }

  getElementValueByNgsiPath(ngsiPath: NGSIPath): any {
    return ngsiPath.executeOnJson(this.entity, this.queryOptions);
  }

  /**
   * Gets single element value for the specified element. For temporal representations returns the last value
   * @param elementName
   */
  getSingleElementValue(elementName: string): any {
    const elementValue = this.getElementValue(elementName);
    if (Array.isArray(elementValue) && this.queryOptions.isTemporal) {
      return elementValue[0];
    } else {
      return elementValue;
    }
  }

  getSingleElementValueByNgsiPath(ngsiPath: NGSIPath): any {
    const elementValue = this.getElementValueByNgsiPath(ngsiPath);
    if (Array.isArray(elementValue) && this.queryOptions.isTemporal) {
      return elementValue[0];
    } else {
      return elementValue;
    }
  }

  /**
   * Gets first temporal value for the specified element
   * @param elementName
   */
  getFirstTemporalValue(elementName: string): TemporalValue {
    // be sure that the element value is suitable for an array of TemporalValue. It could contain a primitive value if the entity was fetched with key-values option
    if (this.isElementTemporalArray(elementName)) {
      const values: any[] = this.entity[elementName] as any[];
      return new TemporalValue(values[values.length - 1]);
    } else {
      return null;
    }
  }

  /**
   * Gets last temporal value for the specified element
   * @param elementName
   */
  getLastTemporalValue(elementName: string): TemporalValue {
    if (this.isElementTemporalArray(elementName)) {
      const values: any[] = this.entity[elementName] as any[];
      return new TemporalValue(values[0]);
    } else {
      return null;
    }
  }

  /**
   * Gets the element value as a {@link TemporalValue} array
   * @param elementName
   */
  getAttributeAsTemporalValueArray(elementName: string): TemporalValue[] {
    if (this.isElementTemporalArray(elementName)) {
      return (this.entity[elementName] as any[]).map(value => new TemporalValue(value));
    } else {
      return [];
    }
  }

  /**
   * Checks whether the element value is an array of TemporalValue
   * @param elementName
   * @private
   */
  private isElementTemporalArray(elementName: string): boolean {
    const elementValue = this.entity[elementName];
    if (Array.isArray(elementValue) && elementValue.length) {
      const valueField: string = elementValue[0].type === 'Relationship' ? 'object' : 'value';
      return elementValue[0][valueField] !== undefined;

    } else {
      return false;
    }
  }

  getAddress(): string {
    if (this.entity.address) {
      const addressPath: NGSIPath = new NGSIPath({
        propOrRelPath: [
          'address'
        ]
      });
      // get the entity address to create PostalAddress
      let entityAddress = addressPath.executeOnJson(this.entity, this.queryOptions);
      if (Array.isArray(entityAddress)) {
        entityAddress = entityAddress[0];
      }
      const address: PostalAddress = new PostalAddress(entityAddress);
      return address.getDisplayText();

    } else {
      return '';
    }
  }
}

