<closeable-dialog class="create-page-dialog">
  <!-- Dialog title-->
  <div dialog-card-header>
    <label class="title">
      {{ 'Select Geographical Area' | translate }}
    </label>
  </div>
  <!-- Dialog body presenting the page form-->
  <div dialog-card-body>
    <div class="info">
      <i class="fa fa-info-circle"></i>
      {{'You can select a geographical region below map' | translate}}
    </div>
    <div class="leaflet-frame position-relative">
      <uruk-map #map [geoQuery]="geoQuery"></uruk-map>
      <drawing-toolbox class="overlay-container"></drawing-toolbox>
    </div>
  </div>
  <div dialog-card-footer>
    <div class="footer">
      <div *ngIf="realmGeoQuery"   class="btn btn-primary ml-auto" (click)="onAreaSelected(realmGeoQuery)">{{'Realm' | translate}}</div>
      <div *ngIf="countryGeoQuery" class="btn btn-primary ml-auto" (click)="onAreaSelected(countryGeoQuery)">{{'Turkey' | translate}}</div>
    </div>
  </div>
</closeable-dialog>

