import {Color} from '../enums/color';

export class TrainColorRules {
  protected rules: { [key: string]: any };

  constructor() {
    this.rules = {
      tractionSystemRules: {
        cutOffTraction: {0: Color.Red, default: Color.White},
        cutOffByPass: {1: Color.Blue, default: Color.White},
        tractionNull: {0: Color.Red, default: Color.White}
      },

      brakeSystemRules: {
        majorBrPark: {1: Color.Red, default: Color.White},
        pressureGovernorPBReleased: {0: Color.Red, default: Color.White},
        pressureGovernorDetectionEmergencyBrake: {0: Color.Red, default: Color.White},
        pressureGovernorIndirectBrakeControl: {0: Color.Red, default: Color.White},
        majorBrWsp: {1: Color.Red, default: Color.White},
        ebcu: {0: Color.Red, default: Color.White},
        diagBrDirect: {1: Color.Red, default: Color.White},
        diagBrCPress: {1: Color.Red, default: Color.White}
      },

      powerVoltageSystemRules: {
        yuksekAkimKaynakliVakumHatasi: {1: Color.Red, default: Color.White},
        hvConfigError: {1: Color.Red, default: Color.White},
        tumPanolarIzole: {1: Color.Red, default: Color.White},
        pantografDustu: {1: Color.Red, default: Color.White},
        diagPantoComp: {1: Color.Red, default: Color.White},
        majorMvError: {1: Color.Red, default: Color.White},
        vcbCloseSka: {1: Color.Blue, default: Color.White},
        pressurePs1SafeguardAddSka: {1: Color.Blue, default: Color.White},
        hvDisconnectorCloseSKA: {1: Color.Blue, default: Color.White},
        hvDisconnectorCloseOA: {1: Color.Blue, default: Color.White},
        tractionTransformerOilFlow: {1: Color.Red, default: Color.White},
        tractionTransformerOilLevel: {1: Color.Red, default: Color.White},
        tractionTransformerOverPressure: {1: Color.Red, default: Color.White},
        tractionTransformerTemp: (value: number) => {
          if (value < 55) return Color.White;
          if (value > 110) return Color.Red;
          return Color.Orange;
        },
        tempApsInv: {1: Color.Red, default: Color.White},
        tempApsRec: {1: Color.Red, default: Color.White},
        vApsDclink: (value: number) => (value < 800 ? Color.White : Color.Red),
        measuringBatteryVoltage: (value: number) => {
          if (value > 90) return Color.White;
          if (value < 80) return Color.Red;
          return Color.Orange;
        },
        tempBattery: (value: number) => (value >= -10 && value <= 40 ? Color.White : Color.Red)
      },

      airConditionSystemRules: {
        majorHvac: {1: Color.Red, default: Color.White},
        minorHvac: {1: Color.Orange, default: Color.White}
      },

      doorSystemRules: {
        door1LevelFault: {1: Color.Red, default: Color.White},
        door2LevelFault: {1: Color.Orange, default: Color.White},
        ickapi1SeviyeAriza: {1: Color.Red, default: Color.White},
        kapi1SeviyeAriza: {1: Color.Red, default: Color.White},
        prmAsansorKapi1Devrede: {1: Color.Red, default: Color.White},
        doorLoopOkLed: {1: Color.Red, default: Color.White},
        slDoorControlByPass: {1: Color.Blue, default: Color.White}
      },

      otherSystemRules: {
        toiletIsNotActive: {1: Color.Red, default: Color.White},
        toiletWaterLevelLow: {1: Color.Red, default: Color.White},
        toiletBowlFull: {1: Color.Red, default: Color.White},
        toiletWastewaterTankFull: {1: Color.Red, default: Color.White},
        toiletSosSignal: {1: Color.Red, default: Color.White}
      },

      signalizationSystemRules: {
        etcsIsolationByPass: {1: Color.Red, default: Color.White},
        ertmsCutOff: {0: Color.Red, default: Color.White},
        atsByPass: {1: Color.Red, default: Color.White},
        ertmsServiceBrake: {1: Color.Red, default: Color.White}
      }
    };
  }

  getRule(systemName: string) {
    return this.rules[systemName];
  }
}
