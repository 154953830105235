import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestUtil} from '../../../shared/utils/rest-util';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {SearchQuery} from '../../../shared/models/query/search-query.model';
import {PageTemplate} from '../../../shared/models/page-template.model';

/**
 * A service to handle page template related operations
 */
@Injectable()
export class PageTemplateService extends BaseHttpClientService {

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'pagetemplates');
  }

  /**
   * Creates a new page template
   * @param template Page template to be created
   */
  createPageTemplate(template: PageTemplate): Observable<PageTemplate> {
    const endpoint = `${this.endpoint}`;
    return this.httpClient.post(endpoint, template).pipe(map(response => new PageTemplate(response)));
  }

  /**
   * Retrieves the details of a single page template
   * @param id the identifier of the page template to be retrieved
   */
  getPageTemplate(id: string): Observable<PageTemplate> {
    const url = `${this.endpoint}/${id}`;
    return this.httpClient.get(url).pipe(map(response => new PageTemplate(response)));
  }

  /**
   * Retrieves the all page templates defined in the platform.
   */
   getAllPageTemplates(): Observable<PageTemplate[]> {
    return this.getPageTemplates(new SearchQuery());
  }

  /**
   * Retrieves the page templates which satisfies the given query.
   * @param query Search query
   */
  getPageTemplates(query?: SearchQuery): Observable<PageTemplate[]> {
    let url: string = `${this.endpoint}`;
    if (query) {
      const parameters: string = RestUtil.createURLParameters(query);
      if (parameters) {
        url += '?' + parameters;
      }
    }
    return this.httpClient.get<PageTemplate[]>(url).pipe(map(response => response.map(item => new PageTemplate(item))));
  }

  /**
   * Updates the specified page template with the given patch.
   * @param id the identifier of page template to be patched
   * @param patch the patch object
   */
  patchPageTemplate(id: string, patch: any): Observable<PageTemplate> {
    const url = `${this.endpoint}/${id}`;
    return this.httpClient.patch(url, patch).pipe(map(response => new PageTemplate(response)));
  }

  /**
   * Deletes the page template
   * @param id the identifier of the page template to be deleted
   */
  deletePageTemplate(id: string) {
    const url = `${this.endpoint}/${id}`;
    return this.httpClient.delete(url);
  }

}
