import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StructureDefinition} from '../../../shared/models/schema/structure-definition.model';
import {ElementDefinition} from '../../../shared/models/schema/element-definition.model';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {RestUtil} from '../../../shared/utils/rest-util';
import {StructureDefinitionQuery} from '../../../shared/models/query/structure-definition-query.model';
import {StructureDefinitionKind} from '../../../shared/enums/structure-definition-kind.enum';

/**
 * A service to handle structure definition related operations
 */
@Injectable()
export class StructureDefinitionService extends BaseHttpClientService {
  private readonly elementsPath = 'elements';

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'structuredefinitions');
  }

  /**
   * Retrieves the details of a single structure Definition
   * @param structureDefinitionId Id of the structure definition
   */
  getStructureDefinition(structureDefinitionId: String): Observable<StructureDefinition> {
    const url = this.endpoint + '/' + structureDefinitionId;
    return this.httpClient.get(url).pipe(map(response => new StructureDefinition(response)));
  }

  /**
   * Retrieves the details of structure Definitions.
   */
  getStructureDefinitions(query?: StructureDefinitionQuery): Observable<StructureDefinition[]> {
    let url: string = this.endpoint;
    if (query) {
      const parameters: string = RestUtil.createURLParameters(query);
      if (parameters) {
        url += '?' + parameters;
      }
    }
    return this.httpClient.get<StructureDefinition[]>(url)
      .pipe(
        map(response => response
          .map(item => new StructureDefinition(item))
          .sort((a: StructureDefinition, b: StructureDefinition) => {
            return (a.id > b.id) ? 1 : ((a.id < b.id) ? -1 : 0);
          })
        )
      );
  }

  /**
   * Creates a new structure definition on the platform
   * @param structureDefinition Structure definition to be created
   */
  createStructureDefinition(structureDefinition: StructureDefinition): Observable<StructureDefinition> {
    return this.httpClient.post(this.endpoint, structureDefinition).pipe(map(response => new StructureDefinition(response)));
  }

  /**
   * Create bulk structure definitions in one call
   * @param data Byte array from the compressed file containing all structure definition files
   */
  createMultipleStructureDefinitions(data: ArrayBuffer): Observable<StructureDefinition[]> {
    return this.httpClient.post<StructureDefinition[]>(this.endpoint, data).pipe(map(response => response.map(item => new StructureDefinition(item))));
  }

  /**
   * Updates the given structure definition
   * @param structureDefinition Structure definition to be updatedcons
   */
  updateStructureDefinition(structureDefinition: StructureDefinition): Observable<StructureDefinition> {
    const url = this.endpoint + '/' + structureDefinition.id;
    return this.httpClient.patch(url, structureDefinition).pipe(map(response => new StructureDefinition(response)));
  }

  /**
   * Update multiple structure definitions in one call
   * @param data Byte array from the compressed file containing all structure definition files
   */
  updateMultipleStructureDefinitions(data: ArrayBuffer): Observable<StructureDefinition[]> {
    return this.httpClient.patch<StructureDefinition[]>(this.endpoint, data).pipe(map(response => response.map(item => new StructureDefinition(item))));
  }

  /**
   * Enables the structure definition within Uruk platform.
   * @param id Id of the structure definition
   */
  enableStructureDefinition(id: string) {
    return this.httpClient.put(`${this.endpoint}/${id}/status`, {});
  }

  /**
   * Disables the structure definition within Uruk platform.
   * @param id Id of the structure definition
   */
  disableStructureDefinition(id: string) {
    return this.httpClient.delete(`${this.endpoint}/${id}/status`);
  }

  /**
   * Deletes the given structure definition
   * @param structureDefinitionId Id of the structure definition to be deleted
   */
  deleteStructureDefinition(structureDefinitionId: String) {
    const url = this.endpoint + '/' + structureDefinitionId;
    return this.httpClient.delete(url);
  }

  /**
   * Updates a specific element within a given structure definition
   * @param structureDefinitionId Id of the structure definition
   * @param elementPath Path of the element
   * @param elementDefinition Element definition to be updated
   */
  updateElement(structureDefinitionId: String, elementPath: String, elementDefinition: ElementDefinition) {
    const url = this.endpoint + '/' + structureDefinitionId + '/' + this.elementsPath + '/' + elementPath;
    return this.httpClient.patch(url, elementDefinition);
  }

  /**
   * Extends a schema with a new element
   * @param structureDefinitionId Id of the structure definition
   * @param elementPath Path of the element
   * @param elementDefinition Element definition to be added
   */
  extendStructureDefinition(structureDefinitionId: string, elementPath: string, elementDefinition: ElementDefinition) {
    const url = `${this.endpoint}/${structureDefinitionId}/${this.elementsPath}/${elementPath}`;
    return this.httpClient.put(url, elementDefinition);
  }

  /**
   * Retrieves identifiers of entities on which a KPI query can be defined e.g. Junction, OffStreetParking
   */
  getQueryableEntityIds(domains: string[] = []): Observable<string[]> {
    // construct the query
    const query: StructureDefinitionQuery = new StructureDefinitionQuery();
    query.isAbstract = false;
    query.kind = StructureDefinitionKind.RESOURCE;
    query.domains = domains;
    return this.getStructureDefinitions(query)
      .pipe(
        map(structureDefinitions =>
          structureDefinitions.map(definition => definition.id)
        )
      );
  }

  /**
   * Retrieves entity parameters that can be used in NGSI queries
   */
  getEntityElements(entityId: string, category?: string, dataType?: string, klass?: string[]): Observable<ElementDefinition[]> {
    let url = this.endpoint + '/' + entityId + '/elements';
    const parameters: string = RestUtil.createURLParameters(
      {
        category,
        dataType,
        class: klass
      }
    );
    if (parameters) {
      url += '?' + parameters;
    }

    return this.httpClient.get<ElementDefinition[]>(url)
      .pipe(
        map(response => {
          return response
            .map(element => new ElementDefinition(element))
            .sort((a: ElementDefinition, b: ElementDefinition) => {
              return (a.path > b.path) ? 1 : ((b.path > a.path) ? -1 : 0);
            });
        })
      );
  }
}
