import {PersistencySettings} from './persistency-settings.model';
import {NGSIPath} from '../ngsi/ngsi-path.model';

/**
 * Element definitions are used to model entity attributes like OffStreetParking.availableSpotNumber
 */
export class ElementDefinition {
  /**
   * Json path to the element defined e.g. availableSpotNumber or address.postalCode
   */
  path: string;

  /**
   * Human friendly definition of element
   */
  definition?: string;

  /**
   * NGSI-LD Context of this property or relationship. If it does not exist, the context of parent StructureDefinition is applied
   */
  context?: string;

  /**
   * Minimum cardinality of the element
   */
  min?: number = 0;

  /**
   * Maximum cardinality of the element
   */
  max: string = '1';

  /**
   * Class of the element.
   * If this element definition is included in an entity definition (e.g. OffStreetParking), the default value is Property
   * If this element definition is included in a data type definition (e.g. Address), the default value is Value.
   - Property: NGSI-LD property
   - GeoProperty: NGSI-LD Geo Property
   - Value: NGSI-LD value
   - Relationship: NGSI-LD relationship"
   */
  class?: string;

  /**
   * Data type of this element
   */
  type?: string;

  /**
   * If true, this is a temporal attribute and its value changes frequently in time
   */
  isTemporal: boolean;

  /**
   * Fixed value for the element. Applicable only for simple types
   */
  fixedValue: any;

  /**
   * Value set for coded elements i.e. elements having code type
   */
  valueSet?: string[];

  /**
   * For relationship elements, this field keeps possible target entity types for the relationship
   */
  targetRelationshipType?: string[];

  /**
   * Persistency settings about this attribute
   * */
  persistencySettings: PersistencySettings;

  /**
   * NGSIPath representation of this element
   */
  ngsiPath: NGSIPath;

  /**
   * Default unit of this element
   * */
  unit: string;

  /*
    UI-specific fields
   */

  /**
   * Whether the element definition is created for an existing structure definition. If it is true, there should be
   * a separate REST call to extend structure definition element list with this one.
   * */
  createdForExistingResource: boolean = false;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.path = data.path;
    this.definition = data.definition;
    this.context = data.context;
    this.min = data.min;
    this.max = data.max;
    if (data.class) {
      this.class = data.class;
    }
    if (data.type) {
      this.type = data.type;
    }
    this.isTemporal = data.isTemporal;
    this.fixedValue = data.fixedValue;
    this.valueSet = data.valueSet;
    this.targetRelationshipType = data.targetRelationshipType;
    if (data.persistencySettings) {
      this.persistencySettings = new PersistencySettings(data.persistencySettings);
    }
    if (data.ngsiPath) {
      this.ngsiPath = new NGSIPath(data.ngsiPath);
    }
    if (data.createdForExistingResource) {
      this.createdForExistingResource = data.createdForExistingResource;
    }
    this.unit = data.unit;
  }
}
