import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {PageService} from '../services/meta/page.service';
import {Page} from '../../shared/models/page.model';
import {UserService} from '../services/data/user.service';
import {UserPreference} from '../../shared/models/user-preference.model';
import {mergeMap, take} from 'rxjs/operators';

/**
 * A resolver that provides page data from the server during the navigation
 */
@Injectable({
  providedIn: 'root'
})
export class PageResolver implements Resolve<Page> {

  constructor(private pageService: PageService, private userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Page> {
    const id: string = route.paramMap.get('id');
    if (id) {
      return this.pageService.getPage(route.paramMap.get('id'));

      // no id is given, navigate to the default page
    } else {
      return this.userService.onUserPreferencesChanged().pipe(
        take(1), // this is required to get the latest value of the user preferences ReplaySubject
        mergeMap((userPreferences: UserPreference) => {
          const pageIds = userPreferences.getPageIds();
          // navigate to the default page if exists. Otherwise, use a random page from the user page list if any.
          const pageId = userPreferences.defaultPageId ? userPreferences.defaultPageId : (pageIds.length ? pageIds[0] : null);
          if (pageId) {
            return this.pageService.getPage(pageId);
          } else {
            return of(null);
          }
        })
      );
    }
  }
}
