<div class="uruk-panel">

  <ng-container *ngIf="loading">
    <div class="panel-loader">
      <ng-lottie [options]="lottieOptions"></ng-lottie>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <div class="panel-header">
      <div class="panel-title" *ngIf="displayTitle" [nbPopover]="popoverRef" nbPopoverTrigger="hint" nbPopoverPlacement="bottom">
        {{ panel.title }}
      </div>

      <div *ngIf="displayPanelSettings" class="panel-settings">

        <div *ngIf="!empty" class="icon-container uruk-icon image-export-icon" (click)="handleExportReportIconClick()" title="{{ 'Create PDF' | translate }}">
          <i class="fa fa-file-pdf"></i>
        </div>

        <div *ngIf="!empty"
             class="icon-container uruk-icon image-export-icon" (click)="handleExportImageIconClick()" title="{{ 'Export PNG' | translate }}">
          <i class="fa fa-file-image"></i>
        </div>

        <div *ngIf="!empty"
             class="icon-container uruk-icon image-export-icon" (click)="handleExportCsvIconClick()" title="{{ 'Export CSV' | translate }}">
          <i class="fa fa-file-excel"></i>
        </div>

        <!-- Icons for edit mode -->
        <ng-container *ngIf="editMode">
          <!-- Icon to edit panel -->
          <div class="icon-container" title="{{ 'Edit' | translate }}">
            <uruk-svg (click)="editPanel()"
                      [height]="'100%'"
                      [paths]="['M14.3789 1.41699C13.8361 1.41699 13.2933 1.61223 12.8789 2.00358L12 2.83366L15 5.66699L15.8789 4.83691C16.7069 4.05491 16.7069 2.78629 15.8789 2.00358C15.4645 1.61223 14.9217 1.41699 14.3789 1.41699ZM10.875 3.89616L2.25 12.042V14.8753H5.25L13.875 6.72949L10.875 3.89616Z']"
                      [svgContainerClass]="'uruk-icon'"
                      [viewbox]="'0 0 16 16'"
                      [width]="'100%'">
            </uruk-svg>
          </div>
          <!-- Icon to delete panel -->
          <div class="icon-container" title="{{ 'Delete' | translate }}">
            <uruk-svg (click)="deletePanel()"
                      [height]="'100%'"
                      [paths]="['M8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.424 16 16 12.424 16 8C16 3.576 12.424 0 8 0ZM11.7656 10.6344C12.0784 10.9472 12.0784 11.4528 11.7656 11.7656C11.6096 11.9216 11.4048 12 11.2 12C10.9952 12 10.7904 11.9216 10.6344 11.7656L8 9.1312L5.3656 11.7656C5.2096 11.9216 5.0048 12 4.8 12C4.5952 12 4.3904 11.9216 4.2344 11.7656C3.9216 11.4528 3.9216 10.9472 4.2344 10.6344L6.8688 8L4.2344 5.3656C3.9216 5.0528 3.9216 4.5472 4.2344 4.2344C4.5472 3.9216 5.0528 3.9216 5.3656 4.2344L8 6.8688L10.6344 4.2344C10.9472 3.9216 11.4528 3.9216 11.7656 4.2344C12.0784 4.5472 12.0784 5.0528 11.7656 5.3656L9.1312 8L11.7656 10.6344Z']"
                      [svgContainerClass]="'uruk-icon'"
                      [viewbox]="'0 0 16 16'"
                      [width]="'100%'">
            </uruk-svg>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="empty">
      <div class="panel-body flex-centered">
        <div class="panel-empty">
          {{ 'No data found for selected dates and/or displayed location.' | translate}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!empty">
      <div id="panel-body" class="panel-body">

        <ng-container *ngIf="isKpiPanel()">
          <uruk-metric #chart *ngIf="panel.chartSettings.type === ChartType.Metric"
                       [panelTitle]="panel.title"
                       [kpi]="this.kpi"
                       [chartSettings]="panel.chartSettings"
                       [visualizationMode]="visualizationMode"
                       [dialogViewportMin]="dialogViewportMin"></uruk-metric>

          <uruk-line-chart #chart *ngIf="panel.chartSettings.type === ChartType.Line"
                           [panelTitle]="panel.title"
                           [kpi]="this.kpi"
                           [chartSettings]="panel.chartSettings"
                           [lineColors]="colors"
                           [displaySplitLines]="false"
                           [displaySymbols]="true"
                           [visualizationMode]="visualizationMode"
                           [dialogViewportMin]="dialogViewportMin"></uruk-line-chart>

          <uruk-bar-chart #chart
                          *ngIf="panel.chartSettings.type === ChartType.VerticalBar || panel.chartSettings.type === ChartType.HorizontalBar"
                          [panelTitle]="panel.title"
                          [kpi]="this.kpi"
                          [chartSettings]="panel.chartSettings"
                          [type]="panel.chartSettings.type"
                          [barColors]="colors"
                          [displaySplitLines]="false"
                          [visualizationMode]="visualizationMode"
                          [dialogViewportMin]="dialogViewportMin"></uruk-bar-chart>

          <uruk-pie-chart #chart *ngIf="panel.chartSettings.type === ChartType.Pie"
                          [panelTitle]="panel.title"
                          [kpi]="this.kpi"
                          [chartSettings]="panel.chartSettings"
                          [type]="panel.chartSettings.type"
                          [visualizationMode]="visualizationMode"
                          [dialogViewportMin]="dialogViewportMin"></uruk-pie-chart>

          <uruk-pie-chart #chart *ngIf="panel.chartSettings.type === ChartType.Doughnut"
                          [panelTitle]="panel.title"
                          [kpi]="this.kpi"
                          [chartSettings]="panel.chartSettings"
                          [type]="panel.chartSettings.type"
                          [visualizationMode]="visualizationMode"
                          [dialogViewportMin]="dialogViewportMin"></uruk-pie-chart>

          <uruk-gauge #chart *ngIf="panel.chartSettings.type === ChartType.Gauge"
                      [panelTitle]="panel.title"
                      [kpi]="this.kpi"
                      [chartSettings]="panel.chartSettings"
                      [visualizationMode]="visualizationMode"
                      [dialogViewportMin]="dialogViewportMin"></uruk-gauge>

          <uruk-ring-chart #chart *ngIf="panel.chartSettings.type === ChartType.Ring"
                           [panelTitle]="panel.title"
                           [kpi]="this.kpi"
                           [chartSettings]="panel.chartSettings"
                           [visualizationMode]="visualizationMode"
                           [dialogViewportMin]="dialogViewportMin"></uruk-ring-chart>

          <uruk-scatter-chart #chart *ngIf="panel.chartSettings.type === ChartType.Scatter"
                              [panelTitle]="panel.title"
                              [kpi]="this.kpi"
                              [chartSettings]="panel.chartSettings"
                              [visualizationMode]="visualizationMode"
                              [dialogViewportMin]="dialogViewportMin"></uruk-scatter-chart>
        </ng-container>

        <ng-container *ngIf="isAnalyticsPanel()">
          <uruk-line-chart #chart *ngIf="panel.chartSettings.type === ChartType.Line"
                           [panelTitle]="panel.title"
                           [kpi]="kpi"
                           [chartSettings]="panel.chartSettings"
                           [lineColors]="colors"
                           [displaySplitLines]="false"
                           [displaySymbols]="true"
                           [visualizationMode]="visualizationMode"
                           [dialogViewportMin]="dialogViewportMin"></uruk-line-chart>
        </ng-container>
      </div>
    </ng-container>

    <!--
    <div class="panel-footer">

    </div>
    -->
  </ng-container>

  <ng-template #popoverRef>
    <div class="kpi-detail-popover">
      <div class="kpi-title">{{ kpi.name }}</div>
      <div class="kpi-description">{{ kpi.description }}</div>
    </div>
  </ng-template>

</div>
