import {Component, HostListener, Injector, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable, timer} from 'rxjs';
import {map, shareReplay, takeUntil} from 'rxjs/operators';
import {PageWithMapComponent} from '../components/page-with-map.component';
import {Panel} from '../../../shared/models/visualization/panel.model';
import {ChartType} from '../../../shared/enums/chart-type.enum';
import {environment} from '../../../../environments/environment';
import {EventService} from '../../../core/services/event.service';
import {GenericConfirmationDialogComponent} from '../../dialog/confirmation-dialog/generic-confirmation-dialog.component';

@Component({
  selector: 'uruk-videowall',
  templateUrl: './videowall.component.html',
  styleUrls: ['./videowall.component.scss'],
  animations: [
    trigger('idleChange', [
      state('active', style({
        opacity: 1,
      })),
      state('idle', style({
        opacity: 0
      })),
      transition('* => *', animate(300))
    ]),
  ]
})
export class VideowallComponent extends PageWithMapComponent implements OnInit, OnDestroy {

  // Reference to internal sources and Angular API
  ChartType = ChartType;

  // Panel Locations
  PANEL_LOCATION_LEFT = 'left';
  PANEL_LOCATION_TOP = 'top';
  PANEL_LOCATION_BOTTOM = 'bottom';

  largePanelCount = 3; // number of large panels within a column or row
  smallPanelCount = 9; // number of small panels within a row

  // DateTime to be displayed on the top-right corner
  time: Date;

  // User inactivity tracking
  isIdle = false;
  idleTimeout = environment.timeouts.mouseIdleTimeout;
  idleTimeoutReference;

  // Observable and subjects
  time$: Observable<Date>;

  // TODO: below will be removed
  liveVideos = [
    {
      'name': 'İl Özel İdare Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7913'
    },
    {
      'name': 'Hacı Cümbüş Camii Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7918'
    },
    {
      'name': 'İtfaiye Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7906'
    },
    {
      'name': 'Stadyum Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7901'
    },
    {
      'name': 'Adliye Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7903'
    },
    {
      'name': 'Şok Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7919'
    },
    {
      'name': 'Sebze Hali Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7908'
    },
    {
      'name': 'Kan Merkezi Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7911'
    },
    {
      'name': 'İsmar Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7910'
    },
    {
      'name': 'İl Özel İdare Kavşağı',
      'url': 'https://uruktest.geleceginsehri.com/Api/Cam/GetData?name=7913'
    }
    // {
    //   'name': 'Tekel Kavşağı',
    //   'url': 'https://uruktest.geleceginsehri.com/publicLive/132/mainStream'
    // },
    // {
    //   'name': 'Fidanlık Kavşağı',
    //   'url': 'https://uruktest.geleceginsehri.com/publicLive/145/mainStream'
    // },
    // {
    //   'name': 'Toki Kavşağı',
    //   'url': 'https://uruktest.geleceginsehri.com/publicLive/140/mainStream'
    // },
    // {
    //   'name': 'Kayabaş Camii Kavşağı',
    //   'url': 'https://uruktest.geleceginsehri.com/publicLive/122/mainStream'
    // }
  ];

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.menuService.setMenuVisibility(false);
    // process the page metadata
    // this.page = this.route.snapshot.data.page;

    // track mouse movements for "add-panel" visibility
    this.resetIdleTimeout();

    // create an observer at 1-second intervals to keep track of the time
    this.time$ = timer(0, 1000).pipe(
      map(tick => new Date()),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    // subscribe to time changes
    this.time$.subscribe(result => {
      this.time = result;
    });

    super.ngOnInit();

    this.subscribeToEvents();
  }

  ngOnDestroy(): void {
    this.menuService.setMenuVisibility(true);
    super.ngOnDestroy();
  }

  private subscribeToEvents(): void {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.PANEL_UPDATED: {
            this.saveUpdates();
            break;
          }
        }
      });
  }

  /******* PAGE & PANEL OPERATIONS *******/

  deletePanel(panel: Panel): void {
    // open the dialog
    const dialogRef = this.dialogService.open(GenericConfirmationDialogComponent, {
      context: {
        title: 'Delete Panel',
        body: this.translateService.instant('Are you sure you want to delete panel ?', {name: panel.title})
      }
    });

    // retrieve the confirmation result on close
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          super.deletePanel(panel);
          this.saveUpdates();
        }
      });
  }

  /******* CAMERA OPERATIONS *******/

  /**
   * TODO
   */
  collapseCameras() {

  }

  /******* IDLE TIMEOUT *******/

  /**
   * Resets the "idle" state every time the user moves the mouse
   */
  @HostListener('document:mousemove', ['$event'])
  resetIdleTimeout() {
    // whenever user moves the mouse, reset the idle timeout to the beginning
    clearTimeout(this.idleTimeoutReference);
    this.isIdle = false;

    // set a timeout and declare the user idle after that
    this.idleTimeoutReference = setTimeout(() => {
      this.isIdle = true;
    }, this.idleTimeout);
  }

  /**
   * Returns the state of the mouse movements for animations
   */
  mouseState() {
    return this.isIdle ? 'idle' : 'active';
  }

  /**
   * Persists the changes done on the page
   * @private
   */
  private saveUpdates(): void {
    // update page
    this.pageService.updatePage(this.page).subscribe(updatedPage => {
      // set page
      this.page = updatedPage;
    }, error => {
      console.error(error);
    });
  }
}
