import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';
import {LegendItem} from './legend.item';

/**
 * Displays a legend based on given settings
 * */
@Component({
  selector: 'uruk-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent extends BaseComponent implements OnInit {
  @Input() settings: LegendItem[];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {

  }
}
