import {Layer} from '../../shared/models/layer.model';
import {Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {NGSIResultUtil} from '../../shared/utils/ngsi-result-util';
import {EntityRepresentation} from '../../shared/models/visualization/representation.model';
import {Notification} from '../../shared/models/notification.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import { LayerController } from './layer.controller';
import { catchError, forkJoin, map, Observable, of, take } from 'rxjs';
import { NGSIQuery } from 'app/shared/models/ngsi/ngsi-query.model';
import { NGSIFilter } from 'app/shared/models/ngsi/ngsi-filter.model';
import {Marker} from '../../shared/models/map/marker.model';

export class ElectricBusLayerController extends LayerController {

  displaysTooltip = true;

  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    query.filter.id = [result.getEntityResult().getSingleElementValue('electricBusRef')];
    const electricBusRefQuery = this.cbrokerService.getEntity(query, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const electricBusQuery = this.getMarkerEntity(result.getEntityId(),false);
    const contextObservable: Observable<any> = forkJoin([electricBusRefQuery, electricBusQuery]).pipe(
      take(1),
      map(response => {
        return {
          entity: response[0]?.getEntityResult(),
          positionEntity: response[1]?.getEntityResult()
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }


  protected handleCreateEvent(representation: EntityRepresentation, notification: Notification): NGSIResult {
    const newEntityElectricBusRef: string = NGSIResultUtil.extractEntityAttributeValue(notification.eventPayload.content, 'electricBusRef', new QueryOptions());
    const representationEntities = this.entities.get(representation);
    const entityIndex = representationEntities ? representationEntities.findIndex(e => {
      return this.getElectricBusRefFromNGSIResult(e) === newEntityElectricBusRef;
    }) : -1;
    if (entityIndex !== -1) {
      this.entities.get(representation).splice(entityIndex, 1);
      this.markers.get(representation).splice(entityIndex, 1);
    }
    return super.handleCreateEvent(representation, notification);
  }

  /**
   * Filters out the duplicate results that are belong to the same electric bus
   * TODO non-duplicate results will be fetched from server one we have the "last" operator
   * @param results
   * @protected
   */
  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    const entitiesFound: Set<string> = new Set();
    const finalResults: NGSIResult[] = [];
    results.forEach(result => {
      const electricBusRef: string = this.getElectricBusRefFromNGSIResult(result);
      if (!entitiesFound.has(electricBusRef)) {
        finalResults.push(result);
        entitiesFound.add(electricBusRef);
      }
    })
    return finalResults;
  }

  private getElectricBusRefFromNGSIResult(ngsiResult: NGSIResult): string {
    return ngsiResult.getEntityResult().getSingleElementValue('electricBusRef');
  }

  createTooltip(marker: Marker): string {
    const vehicleSpeed =  marker.entity.entity.vehicleSpeed.value ?  marker.entity.entity.vehicleSpeed.value : marker.entity.entity.vehicleSpeed[0].value;
    const array = marker.entity.entity.electricBusRef.object ? marker.entity.entity.electricBusRef.object.split(':') : marker.entity.entity.electricBusRef[0].object.split(':');
    const busId = array[3];
    let html = '<div class="entity-tooltip">';
    html += `<div class="text"><span class="title">Elektrikli Otobüs ID: </span> ${busId} </div>`;
    html += `<div class="text"><span class="title">Hız: </span> ${vehicleSpeed} </div>`;
    html += '</div>';
    return html;
  }
}
