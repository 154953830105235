/**
 * Settings for entities or elements for persistence
 */
export class PersistencySettings {
  /**
   * Whether the value of element is to be indexed or not
   */
  isIndexed?: boolean;

  /**
   * Chunk time interval for time series (See Timescaledb) in terms of day
   */
  chunkTimeInterval?: number;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.isIndexed = data.isIndexed;
    this.chunkTimeInterval = data.chunkTimeInterval;
  }
}
