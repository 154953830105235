import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {UserService} from '../services/data/user.service';

// keeps the strategies of Auth Scope Guard which are used to evaluate 
export enum AuthScopeGuardStrategy {
  UNANIMOUS = 'Unanimous', // all policies must be satisfied
  AFFIRMATIVE = 'Affirmative' // at least one policy must be satisfied
}

/**
 * A service that permits only the user who has the specified permission for the given resource.
 */
@Injectable()
export class AuthScopeGuard implements CanActivate {
  constructor(public router: Router, private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const strategy = route.data['strategy'];
    const policies:any[] = route.data['policies'];

    let result = false;
    if(strategy === AuthScopeGuardStrategy.UNANIMOUS){
      result = policies.every(policy => this.userService.hasPermissionForResource(policy.resourceId, policy.permission))
    } else {
      result = policies.some(policy => this.userService.hasPermissionForResource(policy.resourceId, policy.permission))
    }

    if (result) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
