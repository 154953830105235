<div class="date-selection" [ngClass]="{'disabled': disabled}">
  <nb-form-field>
    <nb-icon *ngIf="showReset && temporalQuery?.displayText" (click)="onResetClicked()" icon="close-outline" nbPrefix
             pack="eva"></nb-icon>
    <input #dateSelectionInput
           (click)="onTimeSelectionClicked()"
           nbInput [placeholder]="(temporalQuery?.displayText ? temporalQuery.displayText : 'YYYY MM DD') | wordForWordTranslate"
           status="basic"
           type="text">
    <nb-icon (click)="onTimeSelectionClicked()" icon="calendar-outline" nbSuffix pack="uruk"></nb-icon>
  </nb-form-field>
</div>
