<closeable-dialog class="solarPole-dialog-container">
  <div class="dialog-header" dialog-card-header>
    <label class="column-info title" [ngStyle]="{'color':hasSolarPoleTabRedAlarm ? '#FF1F00' : hasSolarPoleTabYellowAlarm ? '#FFB800' : 'white'}">{{'solarPole' | translate}}</label>
  </div>

  <div dialog-card-body class="dialog-body full-height">
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab [tabIcon]="hasSolarPoleTabRedAlarm ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : hasSolarPoleTabYellowAlarm ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
              tabTitle="{{'SolarPole'}}" [active]="activeTab === 'solarPole'" [tabId]="'solarPole'">
        <div class="d-flex ">
          <div class="flex-80">
            <label class="column-info title"> {{'panelPower' | translate}} : </label>
            <label [ngClass]="'column-info content'+ panelPowerColor">{{panelPower}}</label>
          </div>
          <div [ngClass]="'flex-100'">
            <label class="column-info title"> {{'batteryVoltage' | translate}} : </label>
            <label [ngClass]="'column-info content'+ batteryVoltageColor">{{batteryVoltage}}V </label>
          </div>
          <div class="flex-100">
            <label class="column-info title"> {{'batteryCurrent' | translate}} :</label>
            <label class="column-info content">{{batteryCurrent}}A </label>
          </div>
        </div>
        <div  class="d-flex ">
          <div class="flex-80">
            <label class="column-info title"> {{'panelVoltage' | translate}} :</label>
            <label [ngClass]="'column-info content'+ panelVoltageColor">{{panelVoltage}}V </label>
          </div>
          <div class="flex-100">
            <label class="column-info title"> {{'loadCurrent' | translate}} :</label>
            <label [ngClass]="'column-info content'+ loadCurrentColor">{{loadCurrent}}A </label>
          </div>
          <div class="flex-100">
            <label class="column-info title"> {{'loadStatus' | translate}} :</label>
            <label [ngClass]="'column-info content'+ loadStatusColor">{{'loadStatus-' + loadStatus | translate}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-80">
            <label class="column-info title"> {{'temperature' | translate}} :</label>
            <label [ngClass]="'column-info content'+ temperatureColor">{{temperature}}&deg;C </label>
          </div>
          <div class="flex-100">
            <label class="column-info title"> {{'batteryPercentage' | translate}} :</label>
            <label [ngClass]="'column-info content'+ batteryPercentageColor">{{batteryPercentage}}</label>
          </div>
          <div class="flex-100">
            <label class="column-info title"> {{'loadPower' | translate}} :</label>
            <label [ngClass]="'column-info content'+ loadPowerColor"> {{loadPower}} W</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-100">
            <label class="column-info title">{{'doorStatus' | translate}} : </label>
            <label [ngClass]="'column-info content'+ doorStatusColor">{{'doorStatus-' + doorStatus | translate}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-100">
            <label class="column-info title">{{'chargeStatus' | translate}} : </label>
            <label [ngClass]="'column-info content'+ chargeStatusColor">{{'chargeStatus-'+ chargeStatus | translate }}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-100">
            <label class="column-info title"> {{'batteryType' | translate}} :</label>
            <label class="column-info content">{{'batteryType-' + batteryType | translate}}</label>
          </div>
        </div>
      </nb-tab>
      <nb-tab  [tabIcon]="hasRelayTabAlarm ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : {}" tabTitle="{{'relays' | translate}}" [active]="activeTab === 'relay'" [tabId]="'relay'">
        <div>
          <table id="relay-table">
            <tr [ngClass]="'header'">
              <th>{{'relayId' | translate}}</th>
              <th>{{'description' | translate}}</th>
              <th>{{'relayStatus' | translate}}</th>
            </tr>
            <tr *ngFor="let relay of relays">
              <td>
                <div [ngClass]= "{'redDiv':relay.relayStatus === 1 }">
                  {{relay.relayId}}
                </div>
              </td>
              <td>
                <div [ngClass]= "{'redDiv':relay.relayStatus === 1 }">
                  {{relay.description}}
                </div>
              </td>
              <td>
                <div [ngClass]= "{'redDiv':relay.relayStatus === 1 }">
                  {{relay.relayStatus === 0 ? 'Açık' : 'Pasif'}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info title">{{'coordinate' | translate}} : </label>
      <label class="column-info content">{{location}}</label>
    </div>
    <div dialog-card-footer class="footer-right">
      <label  style="color:red;" class="column-info content">{{this.passiveRelaysCount}} &nbsp; </label>
      <label class="column-info content"> / {{this.relays.length}}</label>
      <label class="column-info content">&nbsp; {{'relay' | translate}} </label>
    </div>
  </div>
</closeable-dialog>
