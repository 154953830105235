import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '../services/data/user.service';

/**
 * A service that permits only admins for the specific routes
 */
@Injectable()
export class AdminGuard implements CanActivate {
  constructor(public router: Router, private userService: UserService) {
  }

  canActivate(): boolean {
    if (this.userService.isAdmin()) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      // TODO: warn user
      return false;
    }
  }
}
