import {QueryOperatorParam} from '../query/query-operator-param.model';
import {QueryOperatorParamValue} from '../query/query-operator-param-value.model';
import {QueryOperatorExpressionFactory} from './query-operator-expression.factory';

/**
 * Factory class to handle different types of query operator parameters
 */
export class QueryOperatorParamFactory {

  /**
   * Factory method to create a specific query operator parameter
   * @param data
   */
  static create(data: any): QueryOperatorParam {
    if (typeof data === 'string' || typeof data === 'boolean' || typeof data === 'number') {
      return new QueryOperatorParamValue(data);
    } else {
      return QueryOperatorExpressionFactory.create(data);
    }
  }
}
