import {NGSIContext} from '../generic/ngsi-context.model';
import {Visualization} from './visualization.model';
import {VisualizationFactory} from '../factories/visualization.factory';
import {Persistable} from '../persistanble';

/**
 * Defines a visualization method for a specific entity type.
 */
export class EntityRepresentation implements Persistable {

  /**
   * Query to fetch the data items that will be displayed in this representation
   */
  entityQuery: NGSIContext;

  /**
   * Visualization configurations for this representation
   */
  visualization: Visualization;

  /**
   * Minimum level of zoom level to activate cluster-based queries
   */
  zoomLevels?: number[];

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.entityQuery = new NGSIContext(data.entityQuery);
    this.visualization = VisualizationFactory.create(data.visualization);
    this.zoomLevels = data.zoomLevels;
  }

  createPersistableObject(): any {
    const {entityQuery, visualization,  ...rest} = this;
    rest['entityQuery'] = entityQuery.createPersistableObject();
    rest['visualization'] = visualization.createPersistableObject();
    return rest;
  }
}
