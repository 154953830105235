import {MaxMinDistance} from '../generic/max-min-distance.model';
import {Coordinate} from '../generic/coordinate.model';
import {Geometry, NGSIGeoRelation, NGSIOperator, NGSISeparator} from '../../enums/ngsi-query.enum';
import {BaseQuery} from './base-query.model';

export class NGSIGeoQuery extends BaseQuery {
  /**
   * Target attribute of an entity containing the geographic data
   */
  geoproperty?: string = 'location';

  /**
   * Geographic relationship (i.e. operator) for the query e.g. (near, within, contains, overlaps, ...)
   */
  georel: NGSIGeoRelation;

  /**
   * Geometric nature of geographic data kept in the specified property
   */
  geometry: Geometry;

  /**
   * Coordinates as the operand for the specified geo relation
   */
  coordinates: any[];

  /**
   * Distance that can be used for the 'near' operator
   */
  maxMinDistance?: MaxMinDistance;

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.geoproperty = data.geoproperty;
    this.georel = data.georel;
    this.geometry = data.geometry;
    if (data.coordinates) {
      this.coordinates = data.coordinates
    }
    if (data.maxMinDistance) {
      this.maxMinDistance = new MaxMinDistance(data.maxMinDistance);
    }
  }

  /**
   * Creates polygon geoqueries
   */
  static createPolygonQuery(coordinates: Coordinate[]) {
    const geoquery = new NGSIGeoQuery();
    geoquery.georel = NGSIGeoRelation.INTERSECTS;
    geoquery.geometry = Geometry.POLYGON;
    geoquery.coordinates = [coordinates.map(c => c.toLongLatArray())];
    return geoquery;
  }

  /**
   * Create circular geoqueries
   */
  static createCircularQuery(center: Coordinate, radius: number, isMax: boolean) {
    const geoquery = new NGSIGeoQuery();
    geoquery.georel = NGSIGeoRelation.NEAR;
    geoquery.geometry = Geometry.POINT;
    geoquery.coordinates = center.toLongLatArray();
    geoquery.maxMinDistance = new MaxMinDistance({distance: radius, isMax: isMax});
    return geoquery;
  }

  /**
   * Creates an NGSI query based on the existing information
   */
  createNGSIParameters() {
    if (this.georel === NGSIGeoRelation.INTERSECTS) {
      return {
        georel: this.georel,
        geometry: this.geometry,
        coordinates: this.coordinates
      };
    } else if (this.georel === NGSIGeoRelation.NEAR) {
      return {
        georel: `${this.georel}${NGSISeparator.QUERY}${NGSIGeoRelation.MAX_DISTANCE}${NGSIOperator.EQUAL}${this.maxMinDistance.distance}`,
        geometry: this.geometry,
        coordinates: this.coordinates[0]
      };
    }
  }
}
