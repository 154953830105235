import {LayerController} from './layer.controller';
import {Layer} from '../../shared/models/layer.model';
import {Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

/**
 * Generic implementation of Layer Controller
 * */
export class GenericLayerController extends LayerController {

  constructor(layer: Layer, page: Page, injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const contextObservable: Observable<any> = this.getMarkerEntity(result.getEntityId(), false).pipe(
      take(1),
      map(entity => {
        return entity;
      })
    );

    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }
}
