import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../core/services/auth/auth.service';
import {MenuItem} from '../models/menu/menu-item';
import {MainMenuItemEnum} from '../enums/main-menu-item.enum';
import {ApplicationManagementMenuItemEnum} from '../enums/application-management-menu-item.enum';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsUtil {
  private readonly keycloakRealmId: string = '';

  constructor(private authService: AuthService) {
    this.keycloakRealmId = this.authService.getRealmId(true);
  }

  public getMenuItems(): MenuItem[] {
    return [
      new MenuItem({
        title: MainMenuItemEnum.DataTracking,
        icon: {
          icon: 'data-tracking',
          pack: 'uruk'
        },
        link: environment.routes.pages,
        children: []
      }),
      new MenuItem({
        title: MainMenuItemEnum.DataAnalytics,
        icon: {
          icon: 'web-analytics',
          pack: 'uruk'
        },
        link: environment.routes.analytics
      }),
      new MenuItem({
        title: MainMenuItemEnum.Reports,
        icon: {
          icon: 'download-graph-report',
          pack: 'uruk'
        },
        link: environment.routes.reports
      }),
      new MenuItem({
        title: MainMenuItemEnum.WorkflowManagement,
        icon: {
          icon: 'workflow',
          pack: 'uruk'
        },
        url: environment.workflow.url + this.keycloakRealmId + '/'
      }),
      new MenuItem({
        title: MainMenuItemEnum.Alerts,
        icon: {
          icon: 'alert-guard',
          pack: 'uruk'
        },
        link: environment.routes.alerts
      }),
      new MenuItem({
        title: MainMenuItemEnum.ApplicationManagement,
        link: environment.routes.platform.platform,
        children: [
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.Pages,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.pages}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.PageTemplates,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.pageTemplates}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.Realms,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.realms}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.Domains,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.subdomains}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.Layers,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.layers}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.Regions,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.regions}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.KPIs,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.kpis}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.StructureDefinitionResources,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.structureDefinitions}/resource`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.StructureDefinitionValues,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.structureDefinitions}/value`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.Patterns,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.patterns}`,
            dragDisabled: true
          }),
          new MenuItem({
            title: ApplicationManagementMenuItemEnum.ContextSources,
            link: `${environment.routes.platform.platform}/${environment.routes.platform.contextSources}`,
            dragDisabled: true
          })
        ]
      }),
      new MenuItem({
        title: 'Device Management',
        icon: {
          icon: 'device-management',
          pack: 'uruk'
        },
        link: `${environment.routes.platform.platform}/${environment.routes.platform.deviceManagement}`
      }),
      new MenuItem({
        title: MainMenuItemEnum.UserManagement,
        link: `${environment.routes.platform.platform}/${environment.routes.platform.userManagement}`
      }),
      new MenuItem({
        title: 'Logout',
        callbackFunction: () => this.authService.logout()
      })
    ];
  }
}
