import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export const PAGINATION_DEFAULT_PAGE_SIZE = 10;

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {

  // total element count
  @Input() total: number;
  // type of the entries to be paginated
  @Input() type: string = "";

  // event to be emitted when the entries are paginated
  @Output() paginate: EventEmitter<any> = new EventEmitter<any>()

  // the number of entries displayed in the page
  public pageSize: number = PAGINATION_DEFAULT_PAGE_SIZE;

  // the list of pages to be displayed in the pagination
  public pages: number[] = [];
  // the total number of pages
  public totalPages: number;
  // the current page
  public currentPage: number = 1;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.total && changes.total.currentValue !== changes.total.previousValue) {
      this.setTotalPages();
      this.getPages();
      if (this.currentPage > this.totalPages) {
        this.onGoTo(this.totalPages)
      }
    }
  }

  /**
   * Moves user to the selected page.
   * @param page the page
   * @param force whether the user should be moved to the selected page when it is the same as current one
   */
  public onGoTo(page: number, force = false): void {
    if (this.currentPage !== page || force) {
      this.paginate.emit({ pageSize: this.pageSize, page: page })
      this.currentPage = page;
      this.getPages();
    }
  }

  /**
   * Moves user to next page. 
   */
  public onNext(): void {
    if (this.currentPage !== this.totalPages) {
      this.paginate.emit({ pageSize: this.pageSize, page: this.currentPage + 1 })
      this.currentPage = this.currentPage + 1;
      this.getPages();
    }
  }

  /**
   * Moves user to previous page. 
   */
  public onPrevious(): void {
    if (this.currentPage !== 1) {
      this.paginate.emit({ pageSize: this.pageSize, page: this.currentPage - 1 });
      this.currentPage = this.currentPage - 1;
      this.getPages();
    }
  }

  /**
  * Handler for the change of page size.
  * @param pageSize new page size
  */
  public handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.setTotalPages();
    this.onGoTo(1, true);
  }

  /**
   * Sets {@link pages} variable which keeps the available pages for pagination.
  */
  private getPages() {
    if (this.totalPages <= 7) {
      this.pages = [...Array(this.totalPages).keys()].map(x => ++x);
      return;
    }

    if (this.currentPage > 5) {
      if (this.currentPage >= this.totalPages - 4) {
        this.pages = [1, -1, this.totalPages - 4, this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages];
        return
      } else {
        this.pages = [1, -1, this.currentPage - 1, this.currentPage, this.currentPage + 1, -1, this.totalPages];
        return;
      }
    }

    this.pages = [1, 2, 3, 4, 5, -1, this.totalPages];
  }

  /**
   * Sets {@link totalPages} variable based on the total number of entries and page size.
  */
  private setTotalPages() {
    this.totalPages = Math.ceil(this.total / this.pageSize);
  }
}
