export enum QueryType {
  RETRIEVE_ENTITY = 'retrieve',
  RETRIEVE_ENTITY_TEMPORAL = 'tretrieve',
  QUERY_ENTITIES =  'query',
  QUERY_ENTITIES_TEMPORAL = 'tquery',
  AGGREGATION = 'agg',
  TEMPORAL_AGGREGATION = 'tagg',
  CROSS_AGGREGATION = 'cross-agg',
  CROSS_TEMPORAL_AGGREGATION = 'cross-tagg',
  CROSS_QUERY = 'cross-query',
  CROSS_TEMPORAL_QUERY = 'cross-tquery'
}

export class QueryTypeUtil {
  public static queryTypes: [string, string][] = [
    ['retrieve', 'Retrieve Entity'],
    ['tretrieve', 'Retrieve Entity Temporal'],
    ['query', 'Query Entities'],
    ['tquery', 'Query Entities Temporal'],
    ['agg', 'Aggregation'],
    ['tagg', 'Temporal Aggregation'],
    ['cross-agg', 'Cross Aggregation'],
    ['cross-tagg', 'Cross Temporal Aggregation'],
    ['cross-query', 'Cross Query'],
    ['cross-tquery', 'Cross Temporal Query'],
  ];

  /**
   * Returns whether the given query type is an aggregation query
   * @param queryType
   */
  public static isAggregationQuery(queryType: QueryType): boolean {
    if (queryType === QueryType.AGGREGATION || queryType === QueryType.TEMPORAL_AGGREGATION || queryType === QueryType.CROSS_AGGREGATION || queryType === QueryType.CROSS_TEMPORAL_AGGREGATION) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns whether the given query type is a temporal query
   * @param queryType
   */
  public static isTemporalQuery(queryType: QueryType): boolean {
    if (queryType === QueryType.RETRIEVE_ENTITY_TEMPORAL || queryType === QueryType.QUERY_ENTITIES_TEMPORAL || queryType === QueryType.TEMPORAL_AGGREGATION || queryType === QueryType.CROSS_TEMPORAL_AGGREGATION) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns whether the given query type is an retrieve query
   * @param queryType
   */
  public static isRetrieveQuery(queryType: QueryType): boolean {
    if (queryType === QueryType.RETRIEVE_ENTITY || queryType === QueryType.RETRIEVE_ENTITY_TEMPORAL) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns the query types which are allowed to be used in a layer definition.
   * @returns a set of query types
   */
  public static layerQueryTypes(): Set<string> {
    return new Set([QueryType.RETRIEVE_ENTITY, QueryType.RETRIEVE_ENTITY_TEMPORAL, QueryType.QUERY_ENTITIES, QueryType.QUERY_ENTITIES_TEMPORAL, QueryType.CROSS_QUERY, QueryType.CROSS_TEMPORAL_QUERY]);
  }

  /**
   * Returns the human-readable name of query type.
   * @param queryType
   */
  public static getHumanReadableQueryTypeName(queryType: string): string {
    return QueryTypeUtil.queryTypes.find(type => type[0] === queryType)[1];
  }
}
