import {AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';

/**
 * A wrapper component to simplify using inline svg images
 */
@Component({
  selector: 'uruk-svg',
  templateUrl: './uruk-svg.component.html'
})
export class UrukSvgComponent implements AfterViewInit {

  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() viewbox: string;
  // paths to be rendered in the svg
  @Input() paths: string[];
  // css class to be injected to the wrapper div element of the svg
  @Input() svgContainerClass: string;
  // styles to be injected to the wrapper div element of the svg
  @Input() svgContainerStyle: string;
  @ViewChild('svgContainer') svgContainer: ElementRef;

  constructor(private renderer: Renderer2) {
  }

  /**
   * Creates an svg element using the given inputs. The element is created in the "after view init" phase as the element ref
   * becomes available in that phase.
   */
  ngAfterViewInit(): void {
    const svg = this.renderer.createElement('svg', 'http://www.w3.org/2000/svg');
    this.renderer.setAttribute(svg, 'width', this.width);
    this.renderer.setAttribute(svg, 'height', this.height);
    this.renderer.setAttribute(svg, 'viewBox', this.viewbox);
    // create path element for each path input
    this.paths.forEach(path => {
      const pathElement = this.renderer.createElement('path', 'http://www.w3.org/2000/svg');
      this.renderer.setAttribute(pathElement, 'd', path);
      // append the path to the new svg element
      svg.appendChild(pathElement);
    });
    // add the complete new svg element to the container element
    this.renderer.appendChild(this.svgContainer.nativeElement, svg);
  }
}

