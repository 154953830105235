import {UrukTemporalQuery} from '../query/uruk-temporal-query.model';

export class ModelConfig {

  /**
   * File name of the for the script used to train the ML model (Nifi needs this file name)
   */
  public script: string;

  /**
   * Content of the script. Normally maintained in Nifi.
   */
  public scriptContent: string;

  /**
   * Type of the entity that is related with the ML model
   */
  public entityType: string;

  /**
   * IIdentifier of the KPIs required by the script e.g. KPI to retrieve junction arms
   */
  public kpiIds: string[];

  /**
   * Temporal query to limit the period of the data to be used to train the model
   */
  public temporalQuery: UrukTemporalQuery;

  /**
   * Temporal field of the entity on which the model will be trained
   */
  public entityAttribute: string;

  constructor(data?: any) {
    this.script = data.script;
    this.scriptContent = data.scriptContent;
    this.entityType = data.entityType;
    this.kpiIds = data.kpiIds;
    this.entityAttribute = data.entityAttribute;

    if (data.temporalQuery) {
      this.temporalQuery = new UrukTemporalQuery(data.temporalQuery);
    }
  }
}
