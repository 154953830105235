import {Injector} from "@angular/core";
import {ContextService} from "../http/context.service";
import {EntityRepresentation} from "../../../shared/models/visualization/representation.model";
import {NGSIResult} from "../../../shared/models/ngsi/ngsi-result";
import {Layer} from "../../../shared/models/layer.model";
import {IconVisualization} from "../../../shared/models/visualization/icon-visualization.model";
import {DefibrillatorLayerController} from "../../controllers/defibrillator-layer.controller";
import {map, mergeMap} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";
import {NGSIContext} from "../../../shared/models/generic/ngsi-context.model";
import {KPI} from "../../../shared/models/kpi.model";
import {UserService} from "./user.service";


export enum DEFIBRILLATOR_COLORS {
    GREEN = 'GREEN',
    RED = 'RED',
    GRAY = 'GRAY',
    YELLOW = 'YELLOW',
    ORANGE = 'ORANGE',
    PURPLE = 'PURPLE',
    BURGUNDY = 'BURGUNDY'
}
export class DefibrillatorLayerService {

    private cBrokerService: ContextService;
    private userService: UserService;
    public entities: Map<DEFIBRILLATOR_COLORS, NGSIResult[]>;
    private subscribers: DefibrillatorLayerController[];
    static instance : DefibrillatorLayerService;
    public testMap : Map<String, NGSIResult>;
    private isUpdating = false;

    private constructor(private layer: Layer, private injector: Injector) {
        this.cBrokerService = injector.get(ContextService);
        this.userService = injector.get(UserService);
        this.subscribers = [];
        this.entities = new Map();
        this.testMap = new Map();
        this.getDefibrillatorWithKpi();
    }

    public addSubscriber(defibrillatorLayerController : DefibrillatorLayerController){
      this.subscribers.push(defibrillatorLayerController);
    }

    public unsubscribe(defibrillatorLayerController : DefibrillatorLayerController){
      const entityIndex = this.subscribers.findIndex(e => {
        return e === defibrillatorLayerController;
      });
      if(!entityIndex){
        return;
      }
      this.subscribers.splice(entityIndex, 1);
    }

    public notifySubscriber(){
        try {
            this.subscribers.map(controller => {
                controller.getDefibrillators();
            })
        }catch (error){
            //console.error('Error occurred while notifying layers:', error);
        }finally {
            this.isUpdating = false;
        }
    }

    static getInstance(layer: Layer, injector: Injector) {
        if(DefibrillatorLayerService.instance == null){
            DefibrillatorLayerService.instance = new DefibrillatorLayerService(layer, injector);
        }
        return DefibrillatorLayerService.instance
    }
    static getInstanceDefault(){
      return DefibrillatorLayerService.instance;
    }

    public getDefibrillatorWithKpi(){
        if(this.isUpdating){
            return;
        }
        let parameters: any = {};
        if(this.userService.isRegularUser()){
          parameters.companyName = this.userService.companyName.getValue();
        }else{
          parameters.companyName = '*';
        }
        this.isUpdating = true;
        const entitiesFound: Set<string> = new Set();
        this.testMap = new Map<String, NGSIResult>();
        let contextObservable: Observable<any> = null;
        const tempEntities: Map<DEFIBRILLATOR_COLORS, NGSIResult[]> = new Map();

        const defibrillatorTestRef= this.cBrokerService.executeKPI( this.kpiCreationForDefibrillatorTest(), new NGSIContext({
            temporalQuery: {
                timeProperty: "observedAt",
                timerel: "between",
                timeExpression: "now-1d",
                endTimeExpression: "now"
            }
        }), parameters);
        const defibrillatorPositionRef=  this.cBrokerService.executeKPI( this.kpiCreationForDefibrillatorPosition(), new NGSIContext({
            temporalQuery: {
                timeProperty: "observedAt",
                timerel: "between",
                timeExpression: "now-60d",
                endTimeExpression: "now"
            }
        }), parameters);

        contextObservable = forkJoin([defibrillatorTestRef, defibrillatorPositionRef]).pipe(
            map(response => {
                return {
                    results: response,
                };
            })
        );
        contextObservable.subscribe( response => {
            this.fillTestMap(response.results[0]);
            response.results[1].forEach((position: NGSIResult) => {
                let refId: string = position.getEntityResult().getElementValue('refId');
                if(!entitiesFound.has(refId)){
                    entitiesFound.add(refId);
                    if(this.testMap.get(refId) !== undefined){
                        this.checkResults(this.testMap.get(refId), position, tempEntities);
                    }else{
                        if(!tempEntities.has(DEFIBRILLATOR_COLORS.GRAY)){
                            tempEntities.set(DEFIBRILLATOR_COLORS.GRAY, [position]);
                        }else{
                            tempEntities.get(DEFIBRILLATOR_COLORS.GRAY).push(position);
                        }
                    }
                }
            });
            this.entities = tempEntities;
            this.notifySubscriber();
          }
        )
    }

    private fillTestMap(responseElement: any) {
        responseElement?.forEach((test: NGSIResult) => {
           let refId = test.getEntityResult().getElementValue('refId');
           if(this.testMap.get(refId) === undefined){
               this.testMap.set(refId, test);
           }
        });
    }

    public updatePositionColorWithPosition(defibrillatorPosition: NGSIResult) {
      setTimeout(() => {
        this.getDefibrillatorWithKpi();
      }, 2000);
      /*const tempEntities: Map<DEFIBRILLATOR_COLORS, NGSIResult[]> = new Map();
      let found : boolean = false;
      let entityId : string = defibrillatorPosition.getEntityId();
      this.entities.forEach((value, key) => {
        value.map(entity => {
          if(entity.getEntityId() === entityId){
            //console.log("defibrillatorPosition is changed with id=> "+defibrillatorPosition.getEntityId());
            found = true;
            return defibrillatorPosition;
          }else{
            return entity;
          }
        })
      })
      if(!found){
        //console.log("defibrillatorPosition is added with id=> "+defibrillatorPosition.getEntityId());
        this.entities.get(DEFIBRILLATOR_COLORS.GRAY).push(defibrillatorPosition);
      }
      this.entities = new Map(this.entities);
      this.notifySubscriber();*/
    }

    public updatePositionColorWithTest(defibrillatorTest: NGSIResult) {
      setTimeout(() => {
        this.getDefibrillatorWithKpi();
      }, 2000);
      ////console.log("defibrillatorTest => "+defibrillatorTest.getEntityId());
    }

    getDefibrillatorsForRepresentation(entityRep : EntityRepresentation){
      let color : String = (entityRep.visualization as IconVisualization).icon.split('_')[1]?.toUpperCase().split('.')[0];
      let colorValue = this.convertStringToEnum(color);
        if(!this.entities.has(colorValue)){
            return [] as NGSIResult[];
        }
        return this.entities.get(colorValue);
    }

    convertStringToEnum(color : String) : DEFIBRILLATOR_COLORS {
        const enumKeys = Object.keys(DEFIBRILLATOR_COLORS).filter(k => typeof DEFIBRILLATOR_COLORS[k as keyof typeof DEFIBRILLATOR_COLORS] === 'string') as (keyof typeof DEFIBRILLATOR_COLORS)[];

        for (const key of enumKeys) {
            if (DEFIBRILLATOR_COLORS[key] === color) {
                return DEFIBRILLATOR_COLORS[key];
            }
        }
        return DEFIBRILLATOR_COLORS.GREEN;
    }
    private checkResults(entityResponseForDefibrillatorTest: NGSIResult, entityResponseForPosition: NGSIResult, tempEntities: Map<DEFIBRILLATOR_COLORS, NGSIResult[]>) {
        if(entityResponseForPosition.getEntityResult().getElementValue('status') !== null && entityResponseForPosition.getEntityResult().getElementValue('status') === 2){
            if(!tempEntities.has(DEFIBRILLATOR_COLORS.PURPLE)){
                tempEntities.set(DEFIBRILLATOR_COLORS.PURPLE, [entityResponseForPosition]);
            }else{
                tempEntities.get(DEFIBRILLATOR_COLORS.PURPLE).push(entityResponseForPosition);
            }
            return;
        }
        if (entityResponseForDefibrillatorTest === undefined) {
            if(!tempEntities.has(DEFIBRILLATOR_COLORS.GRAY)){
                tempEntities.set(DEFIBRILLATOR_COLORS.GRAY, [entityResponseForPosition]);
            }else{
                tempEntities.get(DEFIBRILLATOR_COLORS.GRAY).push(entityResponseForPosition);
            }
            return;
        }
        let entityResult = entityResponseForDefibrillatorTest.getEntityResult();
        let AEDBatteryVoltageLevel: number = entityResult.getElementValue("AEDDeviceBatteryVoltageLevel") / 10;
        let IOTBatteryVoltageLevel: number = entityResult.getElementValue("IOTModuleBatteryVoltageLevel") / 10;
        let ADSTestResult: number = entityResult.getElementValue("adsTestResult");
        let ECGTestResult: number = entityResult.getElementValue("ecgTestResult");
        let cryptoTestResult: number = entityResult.getElementValue("cryptoTestResult");
        let audioTestResult: number = entityResult.getElementValue("audioTestResult") ;
        let chargeCircuit1TestResult: number = entityResult.getElementValue("chargeCircuit1TestResult") ;
        let chargeCircuit2TestResult: number = entityResult.getElementValue("chargeCircuit2TestResult") ;
        let capacitorVoltageTestResult: number = entityResult.getElementValue("capacitorVoltageTestResult") ;
        let hBridgeCircuit1TestResult: number = entityResult.getElementValue("hBridgeCircuit1TestResult") ;
        let hBridgeCircuit2TestResult: number = entityResult.getElementValue("hBridgeCircuit2TestResult") ;
        let onOffButtonTestResult: number = entityResult.getElementValue("onOffButtonTestResult") ;
        let shockButtonTestResult: number = entityResult.getElementValue("shockButtonTestResult") ;
        let iotAccelerometerTestResult: number = entityResult.getElementValue("iotAccelerometerTestResult") ;
        let iotInternalMemoryTestResult: number = entityResult.getElementValue("iotInternalMemoryTestResult") ;
        let iotAedConnectionTestResult: number = entityResult.getElementValue("iotAedConnectionTestResult") ;
        let padTestResult: number = entityResult.getElementValue("padTestResult") ;
        let ads4v6Result: number = entityResult.getElementValue("ads4v6Result")/50 ;
        let dacSetVoltage: number = entityResult.getElementValue("dacSetVoltage") ;
        let chargingVoltage: number = entityResult.getElementValue("chargingVoltage") ;
        let chargeTime: number = entityResult.getElementValue("chargeTime")/10 ;
        let hBridge1: number = entityResult.getElementValue("hBridge1") ;
        let hBridge2: number = entityResult.getElementValue("hBridge2") ;


        const lastTestDate = new Date();
        lastTestDate.setDate(entityResult.getElementValue("testDay") );
        lastTestDate.setMonth(entityResult.getElementValue("testMonth")  - 1);
        lastTestDate.setFullYear(entityResult.getElementValue("testYear")  + 2000);
        lastTestDate.setHours(entityResult.getElementValue("testHour") );
        lastTestDate.setMinutes(entityResult.getElementValue("testMinute"));
        const today = new Date();
        const validTestDate = new Date(today);
        validTestDate.setDate(today.getDate() - 1);

        if (lastTestDate > validTestDate) {
            if (this.layer.representations && this.layer.representations.length > 0) {
                if (AEDBatteryVoltageLevel < 9.5 || ADSTestResult === 0 ||
                    ECGTestResult === 0 || cryptoTestResult === 0 || audioTestResult === 0  || chargeCircuit1TestResult === 0 ||
                    chargeCircuit2TestResult === 0  || capacitorVoltageTestResult === 0 || hBridgeCircuit1TestResult === 0 ||
                    hBridgeCircuit2TestResult === 0 || onOffButtonTestResult === 0 || shockButtonTestResult === 0 ||
                    iotAccelerometerTestResult === 0 || iotInternalMemoryTestResult === 0 || iotAedConnectionTestResult === 0) {
                    if (!tempEntities.has(DEFIBRILLATOR_COLORS.RED)){
                        tempEntities.set(DEFIBRILLATOR_COLORS.RED, [entityResponseForPosition]);
                    } else {
                        tempEntities.get(DEFIBRILLATOR_COLORS.RED).push(entityResponseForPosition);
                    }

                } else if (this.userService.isAselsanUser() && (((chargingVoltage !== null &&  chargingVoltage !== 0 && (chargingVoltage >= 2300 )) ||
                  (chargeTime !== null && chargeTime !== 0 && (chargeTime >= 12 || chargeTime <= 4 )) ||
                  (ads4v6Result !== null && ads4v6Result !== 0 && (ads4v6Result >= 5.1 || ads4v6Result <= 4.1 )) ||
                  (dacSetVoltage !== null &&  dacSetVoltage !== 0 && (dacSetVoltage >= 2150)) ||
                  (hBridge1 !== null && hBridge1 !== 0 && (hBridge1 > 60 || hBridge1 < 50 )) ||
                  (hBridge2 !== null) && hBridge2 !== 0 && (hBridge2 > 50 || hBridge2 < 40)))) {
                        if (!tempEntities.has(DEFIBRILLATOR_COLORS.BURGUNDY)) {
                          tempEntities.set(DEFIBRILLATOR_COLORS.BURGUNDY, [entityResponseForPosition]);
                        } else {
                          tempEntities.get(DEFIBRILLATOR_COLORS.BURGUNDY).push(entityResponseForPosition);
                        }
                } else if ((AEDBatteryVoltageLevel >= 9.5 && AEDBatteryVoltageLevel < 10 ) || IOTBatteryVoltageLevel < 2.65) {
                    if (!tempEntities.has(DEFIBRILLATOR_COLORS.YELLOW)) {
                        tempEntities.set(DEFIBRILLATOR_COLORS.YELLOW, [entityResponseForPosition]);
                    } else {
                        tempEntities.get(DEFIBRILLATOR_COLORS.YELLOW).push(entityResponseForPosition);
                    }
                } else if (padTestResult === 0) {
                    if (!tempEntities.has(DEFIBRILLATOR_COLORS.ORANGE)) {
                        tempEntities.set(DEFIBRILLATOR_COLORS.ORANGE, [entityResponseForPosition]);
                    } else {
                        tempEntities.get(DEFIBRILLATOR_COLORS.ORANGE).push(entityResponseForPosition);
                    }
                } else if (AEDBatteryVoltageLevel >= 10 && IOTBatteryVoltageLevel >= 2.65 && ADSTestResult === 1 && ECGTestResult === 1 && cryptoTestResult === 1
                    && audioTestResult === 1 && chargeCircuit1TestResult === 1 && chargeCircuit2TestResult === 1
                    && capacitorVoltageTestResult === 1 && hBridgeCircuit1TestResult === 1 && hBridgeCircuit2TestResult === 1 && onOffButtonTestResult === 1
                    && shockButtonTestResult === 1 && iotAccelerometerTestResult === 1 && iotInternalMemoryTestResult === 1 && iotAedConnectionTestResult === 1 && padTestResult === 1) {
                    if (!tempEntities.has(DEFIBRILLATOR_COLORS.GREEN)) {
                        tempEntities.set(DEFIBRILLATOR_COLORS.GREEN, [entityResponseForPosition]);
                    } else {
                        tempEntities.get(DEFIBRILLATOR_COLORS.GREEN).push(entityResponseForPosition);
                    }
                }
            }
        } else {
            if(!tempEntities.has(DEFIBRILLATOR_COLORS.GRAY)){
                tempEntities.set(DEFIBRILLATOR_COLORS.GRAY, [entityResponseForPosition]);
            }else{
                tempEntities.get(DEFIBRILLATOR_COLORS.GRAY).push(entityResponseForPosition);
            }
        }
    }

    private kpiCreationForDefibrillatorPosition(){
        return new KPI({
            id: '326d8028-ce80-4f62-95b7-80770d24cdc0',
            name: 'Defibilatör Position KPI',
            description: 'Sistemde kayıtlı pozisyon verilerini döner',
            queryType: 'cross-query',
            query: {
                'jsonClass': 'NGSIQuery',
                'filter': {
                    'type': [
                        'DefibrillatorPosition'
                    ]
                },
                limit: 100000,
                cursorAfter: true,
                options: [],
                sortField: 'createdAt',
                sortDirection: 'desc',
                lastNDays: 365
            },
            dimensions: [
                {
                    'keys': {
                        'id': {
                            'dataType': 'URI'
                        }
                    }
                },
                {
                  'keys': {
                    'observedAt': {
                      'dataType': 'DateTime'
                        }
                    }
                }
            ],
            overrideGeoQuery: false,
            overrideTemporalQuery: false,
            overrideIdQuery: false
        });
    }

    private kpiCreationForDefibrillatorTest() {
        return new KPI({
            id: '95ab4b07-0884-4fd3-81e3-0dda8fa6538b',
            name: 'Defibilatör Position KPI',
            description: 'Sistemde kayıtlı test verilerini döner',
            queryType: 'cross-query',
            query: {
                'jsonClass': 'NGSIQuery',
                'filter': {
                    'type': [
                        'DefibrillatorTest'
                    ]
                },
                limit: 100000,
                cursorAfter: true,
                options: [],
                sortField: 'createdAt',
                sortDirection: 'desc',
                lastNDays: 365
            },
            dimensions: [
            ],
            overrideGeoQuery: false,
            overrideTemporalQuery: false,
            overrideIdQuery: false
        });
    }
}
