<closeable-dialog *ngIf="pages !== null" [displayFooter]="step>0" class="create-page-dialog">
  <!-- Dialog title-->
  <div dialog-card-header>
    <label>
      {{ 'Create Page' | translate }}
    </label>
  </div>
  <!-- Dialog body presenting the page form-->
  <div dialog-card-body>
    <!-- Buttons to move next step-->
    <div *ngIf="step === 0" class="navigation">
      <uruk-path-selection [leftText]="'Create Page' | translate" [rightText]="'Select Page' | translate"
                           [leftIcon]="'fa fa-file'" [rightIcon]="'fa fa-clone'"
                           (onLeftSelected)="onNextStep(1)" (onRightSelected)="onNextStep(2)"></uruk-path-selection>
    </div>

    <!-- Page creation form or page selection form -->
    <form *ngIf="step>0" [formGroup]="pageForm" autocomplete="off">
      <ng-container *ngIf="step === 1">
        <!-- Page Title -->
        <div class="form-group">
          <label [ngClass]="{'error-label': displayErrors && !pageForm.get('title').value}" class="label mandatory"
                 for="pageTitle">
            {{ 'Page Title:' | translate }}
          </label>
          <input [status]="displayErrors && !pageForm.get('title').value ? 'danger' : 'basic'" class="margin-left-small"
                 fieldSize="tiny" formControlName="title" id="pageTitle" name="pageTitle" nbInput
                 type="email">
        </div>
        <!-- Template Type -->
        <div class="form-group ">
          <label [ngClass]="{'error-label': displayErrors && !pageForm.get('templateType').value}"
                 class="label mandatory">
            {{ 'Template Type:' | translate }}
          </label>

          <div class="rounded-border">
            <div class="custom-horizontal-scrollbar template-type-container">
              <div (click)="onTemplateTypeClicked(template.id)"
                   *ngFor="let template of templates"
                   [ngClass]="{'selected': pageForm.get('templateType').value === template.id}"
                   class="template-type">
                <span>{{template.title | translate}}</span>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <!-- Page group and icon-->
      <div class="form-group clear-fix d-flex no-margin-bottom">
        <!-- Page group -->
        <div class="form-group page-group-container">
          <label class="label">
            {{ 'Page Group' | translate }}
          </label>
          <div *ngIf="userPreferences" class="d-flex">
            <!-- Page group selection -->
            <div class="rounded-border">
              <div #pageGroups class="custom-vertical-scrollbar">
                <div *ngFor="let pageGroup of userPreferences.pageGroups">
                  <input [value]="pageGroup.name" formControlName="selectedPageGroup" type="radio">
                  <span class="margin-left-small">{{pageGroup.name | translate}}</span>
                  <span (click)="deletePageGroup(pageGroup.name)" [ngClass]="{'disabled': disableActionButtons || (step === 1 && isModalPage())}">
                  <i class="control-icon fa fa-minus-circle" title="{{'deleteGroupPage' | translate}}"></i>
              </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Page Icon -->
        <div *ngIf="step === 1" class="form-group" class="icons-container">
          <label [ngClass]="{'error-label': displayErrors && !pageForm.get('icon').value}" class="label mandatory">
            {{ 'Page Icon:' | translate }}
          </label>

          <div class="rounded-border">
            <div class="custom-vertical-scrollbar">
              <uruk-icon-selection (onIconSelected)="onIconClicked($event)"></uruk-icon-selection>
            </div>
          </div>
        </div>

        <!-- Pages -->
        <div *ngIf="step === 2" class="form-group">
          <label [ngClass]="{'error-label': displayErrors && !selectedPages.length}" class="label mandatory">
            {{ 'Pages' | translate }}:
          </label>
          <div class="pages-container">
            <nb-checkbox (change)="onPageSelected(page.id)" *ngFor="let page of pages;let i=index"
                         [checked]="selectedPages.includes(page.id)">{{page.title}}</nb-checkbox>
          </div>
        </div>
      </div>
      <!-- Input to add new page group -->
      <div *ngIf="userPreferences" class="form-group new-page-group-container">
        <label class="label" for="pageTitle">
          {{ 'Add Page Group:' | translate }}
        </label>
        <input class="margin-left-small"
               fieldSize="tiny" formControlName="pageGroup" id="pageGroup" name="pageGroup" nbInput>
        <span (click)="addPageGroup()" [ngClass]="{'disabled': disableActionButtons || (step === 1 && isModalPage())}">
                  <i class="control-icon fa fa-plus"></i>
              </span>
      </div>
      <ng-container *ngIf="step === 1">
        <div class="form-group">
          <label [ngClass]="{'error-label': displayErrors && pageForm.get('tags').invalid}" class="label mandatory">
            {{ 'Domains' | translate }}:
          </label>
          <nb-select [formatter]="tagFormatter" [options]="domainTags" class="margin-left-small" formControlName="tags" multiple
                     selectWidth size="tiny">
            <nb-option *ngFor="let tag of domainTags" [value]="NAMESPACE_DOMAIN+tag.code">{{tag.name}}</nb-option>
          </nb-select>
        </div>
        <!-- Entities to be Tracked / Layers -->
        <div class="form-group">
          <label [ngClass]="{'error-label': displayErrors && !isAnyLayerSelected()}" class="label mandatory">
            {{ 'Entities to be Tracked' | translate }}
          </label>
          <br>
          <uruk-layer-selection (onLayerSelection)="setSelectedLayers($event)"
                                [domains]="pageForm.get('tags').value"></uruk-layer-selection>
          <br>
        </div>
      </ng-container>
    </form>
  </div>
  <!-- Dialog footer displaying the action buttons-->
  <div dialog-card-footer>
    <div class="d-flex justify-content-end">
      <!-- Step 1: Button to create a page -->
      <button (click)="createPage()" *ngIf="step === 1" [disabled]="disableActionButtons" nbButton status="primary"
              type="submit">{{ 'Create Page' | translate }}</button>
      <!-- Step 1: Button to select pages -->
      <button (click)="selectPages()" *ngIf="step === 2" [disabled]="disableActionButtons" nbButton status="primary"
              type="submit">{{ 'Select Pages' | translate }}</button>
    </div>
  </div>
</closeable-dialog>
