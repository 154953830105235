import {LayerController} from './layer.controller';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {catchError, forkJoin, Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {Marker} from '../../shared/models/map/marker.model';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {NGSIEntity} from '../../shared/utils/ngsi-result-util';


export class MgubLayerController extends  LayerController {
  displaysTooltip: boolean = true;
  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    const mgubId = result.getEntityResult().entity.id;
    let contextObservable: Observable<any> = null;

      const sensorQuery: NGSIQuery = new NGSIQuery({
        filter: {
          type: [NGSIEntity.SENSOR] ,
          q : {
            jsonClass: "NGSIQueryTerm",
            path: {
              jsonClass: "NGSIPath",
              propOrRelPath: [
                "refId"
              ],
              isTemporal: false,
              isPropOrRelArray: false
            },
            op: "==",
            value: [
              mgubId
            ]
          }
        },
        limit: 1000
      });

      const mgubRef =  this.cbrokerService.getEntities(sensorQuery, new QueryOptions(false)).pipe(catchError(() => of(null)));
      const mgubRefQuery = this.getMarkerEntity(result.getEntityId(), false);
      contextObservable = forkJoin([mgubRef, mgubRefQuery]).pipe(
        map(response => {
          return {
            entity: response,
          };
        })
      );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }

  createTooltip(marker: Marker): string {
    const description = marker.entity.getSingleElementValue('description');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text">${description}</div>`;
    html += '</div';

    return html;
  }

}
