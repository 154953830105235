import {EventEmitter, Injectable} from '@angular/core';
import {Event} from '../../shared/models/generic/event.model';
import {NGSIGeoQuery} from '../../shared/models/ngsi/ngsi-geo-query.model';
import {RequestTrackerService} from './http/request-tracker.service';
import {NGSITemporalQuery} from '../../shared/models/ngsi/ngsi-temporal-query.model';
import {ElementDefinition} from '../../shared/models/schema/element-definition.model';
import {Page} from '../../shared/models/page.model';
import {Panel} from '../../shared/models/visualization/panel.model';
import {Coordinate} from '../../shared/models/generic/coordinate.model';
import {PanelContextData} from '../../shared/models/report/panel-context-data.model';
import {UrukTemporalQuery} from '../../shared/models/query/uruk-temporal-query.model';
import {NGSIQueryExpression} from '../../shared/models/ngsi/ngsi-query-expression.model';
import {LayerController} from '../controllers/layer.controller';
import {MapTileType} from "../../shared/enums/map-framework.enum";

/**
 * Broadcasts events for app level changes
 */
@Injectable({
  providedIn: 'root'
})
export class EventService {

  /*********** CONTROL PANEL EVENTS ***********/
  public static SHOW_LAYERS = 103;
  public static HIDE_LAYERS = 104;
  public static ZOOM_IN = 105;
  public static ZOOM_OUT = 106;
  public static LAYER_VISIBILITY_TOGGLED = 107;
  /**
   * Event to be triggered when the zoom of the map changes
   */
  public static ZOOM_CHANGED = 107;
  /**
   * Event to be triggered when a specific coordinate should be zoomed
   */
  public static PAN_AND_ZOOM = 108;
  public static PAN = 109;
  public static DRAW_RECTANGLE = 110;
  public static DRAW_CIRCLE = 111;
  public static DRAW_POLYGON = 112;
  public static DRAW_REMOVE = 113;
  public static MAP_TYPE_CHANGED = 114;

  /*********** MAP CHANGE EVENTS ***********/
  public static MAP_INITIALIZED = 201;
  public static MAP_CHANGED = 202;
  public static SHAPE_CHANGED = 203;

  /*********** TIME CHANGE EVENTS ***********/
  public static TIME_CHANGED = 301;

  /*********** ENTITY CREATION EVENTS ***********/
  public static ENTITY_CREATION_ATTRIBUTE_CHANGED = 401;
  public static ENTITY_CREATION_LOCATION_MAP_OPENED = 402;
  public static ENTITY_CREATION_LOCATION_SELECTED = 403;
  public static ENTITY_CREATION_CITY_SELECTED = 404;
  public static ENTITY_CREATION_ENTITY_CREATED = 405;
  public static ENTITY_VISIBILITY_CHANGE: number = 406;

  /*********** CONNECTION EVENTS ***********/
  public static SOCKET_CONNECTED = 501;

  /*********** PAGE EVENTS ***********/
  public static PAGE_CREATED = 601;
  public static PAGE_DELETED = 602;
  public static PAGE_NAVIGATED = 603;
  public static PANEL_CREATED = 604;
  public static PANEL_UPDATED = 605;
  public static PANEL_DELETED = 606;
  public static PANEL_RESIZED = 610;
  public static PAGE_UPDATED = 611;
  /**
   * Event to be emitted when a page is reported so that active panels would send their internal data to the parent page to compile all the report data
   */
  public static PAGE_REPORT_GENERATED = 607;
  /**
   * Event to be emitted when a panel processes a PAGE_REPORT_GENERATED event. PanelContextData is sent via this event back to a Page
   */
  public static PANEL_REPORT_DATA_SENT = 608;

  /**
   * Event to be emitted when a layer within a page finished processing data
   */
  public static LAYER_PROCESSING_COMPLETED = 609;

  /*********** KPI EDITOR EVENTS ***********/
  /**
   * Event to be emitted when a filter is deleted
   */
  public static KPI_EDITOR_FILTER_DELETED = 801;
  /**
   * Event to be emitted when filter edit clicked so that the parent components including the filter component can handle the edit event
   */
  public static KPI_EDITOR_FILTER_EDIT_CLICKED = 802;

  /*********** NAVIGATION EVENTS ***********/
  public static NAVIGATE_TO_NO_PAGE_TEMPLATE = 901;

  /*********** REALM EVENTS ***********/
  public static REALM_LOGO_UPDATED = 1001;

  public eventEmitter: EventEmitter<Event>;

  constructor(private requestTrackerService: RequestTrackerService) {
    this.eventEmitter = new EventEmitter();
  }

  /*********** CONTROL PANEL EVENTS ***********/

  broadcastShowLayersEvent() {
    this.eventEmitter.emit(new Event(EventService.SHOW_LAYERS));
  }

  broadcastHideLayersEvent() {
    this.eventEmitter.emit(new Event(EventService.HIDE_LAYERS));
  }

  broadcastZoomInEvent() {
    this.eventEmitter.emit(new Event(EventService.ZOOM_IN));
  }

  broadcastZoomOutEvent() {
    this.eventEmitter.emit(new Event(EventService.ZOOM_OUT));
  }

  broadcastLayerVisibilityChangeEvent(layer: LayerController) {
    this.eventEmitter.emit(new Event(EventService.LAYER_VISIBILITY_TOGGLED, layer));
  }

  broadcastZoomChangedEvent(zoomLevel: number) {
    this.eventEmitter.emit(new Event(EventService.ZOOM_CHANGED, {zoomLevel: zoomLevel}));
  }

  broadcastPanAndZoomEvent(coordinate: Coordinate, zoomLevel: number, speed?: number) {
    this.eventEmitter.emit(new Event(EventService.PAN_AND_ZOOM, {coordinate: coordinate, zoomLevel: zoomLevel, speed: speed}));
  }

  broadcastPanEvent(coordinate: Coordinate) {
    this.eventEmitter.emit(new Event(EventService.PAN, coordinate));
  }

  broadcastDrawRectangleEvent() {
    this.eventEmitter.emit(new Event(EventService.DRAW_RECTANGLE));
  }

  broadcastDrawCircleEvent() {
    this.eventEmitter.emit(new Event(EventService.DRAW_CIRCLE));
  }

  broadcastMapTypeChangedEvent(mapType: MapTileType) {
    this.eventEmitter.emit(new Event(EventService.MAP_TYPE_CHANGED, mapType));
  }

  broadcastDrawPolygonEvent() {
    this.eventEmitter.emit(new Event(EventService.DRAW_POLYGON));
  }

  broadcastDrawRemoveEvent() {
    this.eventEmitter.emit(new Event(EventService.DRAW_REMOVE));
  }

  /*********** MAP CHANGE EVENTS ***********/

  broadcastMapInitializedEvent(geoQuery: NGSIGeoQuery) {
    this.eventEmitter.emit(new Event(EventService.MAP_INITIALIZED, geoQuery));
  }
  broadcastRouteVisibilityChangedEvent(entityId: any, visibility: any) {
    this.eventEmitter.emit(new Event(EventService.ENTITY_VISIBILITY_CHANGE, {
      entityId,
      visibility
    }));
  }
  broadcastMapChangedEvent(geoQuery: NGSIGeoQuery) {
    this.requestTrackerService.destroyRequests();
    this.eventEmitter.emit(new Event(EventService.MAP_CHANGED, geoQuery));
  }

  broadcastShapeChangedEvent(geoQuery: NGSIGeoQuery, drawnShapesCleared = false) {
    this.requestTrackerService.destroyRequests();
    this.eventEmitter.emit(new Event(
      EventService.SHAPE_CHANGED, {
        geoQuery,
        drawnShapesCleared
      }
    ));
  }

  /*********** TIME CHANGE EVENTS ***********/

  /**
   * Event to be emitted when the date is changed via the date selection dialog.
   * @param timeQuery Updated time query
   * @param dialogSource Angular-wide identifier of the component from which the date selection dialog is initiated.
   */
  broadcastTimeChangeEvent(timeQuery: UrukTemporalQuery, dialogSource: string) {
    this.requestTrackerService.destroyRequests();
    this.eventEmitter.emit(new Event(EventService.TIME_CHANGED, {timeQuery, dialogSource}));
  }

  /*********** ENTITY CREATION EVENTS ***********/

  /**
   * Event data contains the element definition related to the change event and value set for the attribute
   * @param eventData
   */
  broadcastAttributeChangeEvent(eventData: any) {
    this.eventEmitter.emit(new Event(EventService.ENTITY_CREATION_ATTRIBUTE_CHANGED, eventData));
  }

  /**
   * @param elements contains the definition of attribute being updated and the element definitions
   */
  broadcastLocationSelectionMapOpenedEvent(elementSelfAndParents: ElementDefinition[]) {
    this.eventEmitter.emit(new Event(EventService.ENTITY_CREATION_LOCATION_MAP_OPENED, elementSelfAndParents));
  }

  broadcastLocationSelectedEvent(coordinates: number[]) {
    this.eventEmitter.emit(new Event(EventService.ENTITY_CREATION_LOCATION_SELECTED, coordinates));
  }

  broadcastCitySelectedEvent(city: string) {
    this.eventEmitter.emit(new Event(EventService.ENTITY_CREATION_CITY_SELECTED, city));
  }

  broadcastEntityCreatedEvent(createdEntity: any) {
    this.eventEmitter.emit(new Event(EventService.ENTITY_CREATION_ENTITY_CREATED, createdEntity));
  }

  /*********** CONNECTION EVENTS  ***********/

  broadcastSocketConnectedEvent() {
    this.eventEmitter.emit(new Event(EventService.SOCKET_CONNECTED));
  }

  /*********** PAGE EVENTS  ***********/

  broadcastPageCreatedEvent(page: Page) {
    this.eventEmitter.emit(new Event(EventService.PAGE_CREATED, page));
  }

  broadcastPageUpdatedEvent(page: Page) {
    this.eventEmitter.emit(new Event(EventService.PAGE_UPDATED, page));
  }

  broadcastPageDeletedEvent(pageId: string) {
    this.eventEmitter.emit(new Event(EventService.PAGE_DELETED, pageId));
  }

  broadcastPageNavigatedEvent(page: Page) {
    this.eventEmitter.emit(new Event(EventService.PAGE_NAVIGATED, page));
  }

  broadcastPanelCreatedEvent(panel: Panel) {
    this.eventEmitter.emit(new Event(EventService.PANEL_CREATED, panel));
  }

  broadcastPanelUpdatedEvent(panel: Panel) {
    this.eventEmitter.emit(new Event(EventService.PANEL_UPDATED, panel));
  }

  broadcastPanelResizedEvent(panelLocation: string, pageId: string): void {
    this.eventEmitter.emit(new Event(EventService.PANEL_RESIZED, {
      panelLocation: panelLocation,
      pageId: pageId
    }));
  }

  broadcastPanelDeletedEvent(panel: Panel) {
    this.eventEmitter.emit(new Event(EventService.PANEL_DELETED, panel));
  }

  broadcastReportGeneratedEvent(): void {
    this.eventEmitter.emit(new Event(EventService.PAGE_REPORT_GENERATED));
  }

  broadcastPanelReportDataSentEvent(panelContextData: PanelContextData, singlePanelReport: boolean): void {
    this.eventEmitter.emit(new Event(EventService.PANEL_REPORT_DATA_SENT, {
      panelData: panelContextData,
      singlePanelReport: singlePanelReport
    }));
  }

  broadcastLayerProcessingFinishedEvent(layer: LayerController): void {
    this.eventEmitter.emit(new Event(EventService.LAYER_PROCESSING_COMPLETED, layer));
  }

  broadcastFilterDeletedEvent(deletedTerm: NGSIQueryExpression): void {
    this.eventEmitter.emit(new Event(EventService.KPI_EDITOR_FILTER_DELETED, deletedTerm));
  }

  broadcastFilterEditClickedEvent(editedTerm: NGSIQueryExpression): void {
    this.eventEmitter.emit(new Event(EventService.KPI_EDITOR_FILTER_EDIT_CLICKED, editedTerm));
  }

  /*********** NAVIGATION EVENTS ***********/
  broadcastNavigateToNoPageTemplateEvent(): void {
    this.eventEmitter.emit(new Event(EventService.NAVIGATE_TO_NO_PAGE_TEMPLATE));
  }

  /*********** REALM EVENTS ***********/
  broadcastRealmLogoUpdatedEvent(logo: string): void {
    this.eventEmitter.emit(new Event(EventService.REALM_LOGO_UPDATED, logo));
  }
}
