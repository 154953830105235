import {PollingSettings} from './poll-settings.model';
import {SubscriptionSettings} from './subscription-settings.model';
import {NGSIGeoQuery} from '../ngsi/ngsi-geo-query.model';
import {UrukTemporalQuery} from '../query/uruk-temporal-query.model';
import {Persistable} from '../persistanble';
import {MLModelConfig} from '../analytics/mlmodel-config.model';

/**
 * An NGSI context is used to define a query along with some query settings either by polling or by subscription. Polling and subscription
 * methods are disjoint i.e. one of them could be applicable for a particular NGSI Context object.
 */
export class NGSIContext implements Persistable {
  /**
   * Reference to the KPI that defines the query to be used in this context object
   */
  kpiId?: string;

  /**
   * Identifier of the subscription
   */
  subscriptionId?: string;

  /**
   * MLModel definition if this context is related to analytics predictions
   */
  mlModelConfig?: MLModelConfig;

  /**
   * Id query for this context
   */
  idQuery: string;

  /**
   * Company query for this context
   */
  companyQuery: string;

  /**
   * Geographical query for this context
   */
  geoQuery: NGSIGeoQuery;

  /**
   * Time query for this context
   */
  temporalQuery: UrukTemporalQuery;

  /**
   * Polling settings for the query
   */
  pollingSettings?: PollingSettings;

  constructor(data?) {
    if (!data) {
      return;
    }

    this.kpiId = data.kpiId;
    this.idQuery = data.idQuery;
    this.companyQuery = data.companyQuery;
    if (data.geoQuery) {
      this.geoQuery = new NGSIGeoQuery(data.geoQuery);
    }
    if (data.temporalQuery) {
      this.temporalQuery = new UrukTemporalQuery(data.temporalQuery);
    }
    if (data.pollingSettings) {
      this.pollingSettings = new PollingSettings(data.pollingSettings);
    }
    if (data.subscriptionId) {
      this.subscriptionId = data.subscriptionId;
    }
    if (data.mlModelConfig) {
      this.mlModelConfig = new MLModelConfig(data.mlModelConfig);
    }
  }

  createPersistableObject(): any {
    const {temporalQuery, ...rest} = this;
    if (temporalQuery) {
      rest['temporalQuery'] = temporalQuery.createPersistableObject();
    }
    return rest;
  }
}
