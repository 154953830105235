import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {BaseHttpClientService} from './base-http-client.service';
import {ReportConfig} from '../../shared/models/report/report-config.model';
import {ReportContextData} from '../../shared/models/report/report-context-data.model';
import {ReportFormat} from '../../shared/enums/report-format.enum';

/**
 * Service providing reporting functionalities
 */
@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseHttpClientService {

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'report');
  }

  /**
   * Returns all the {@link ReportConfig} objects defined on the platform
   * @param text Text search parameter
   */
  getReportConfigs(text?: string): Observable<ReportConfig[]> {
    const urlParameter = text ? '?text=' + text : '';
    const url: string = `${this.endpoint}/config${urlParameter}`;
    return this.httpClient.get<ReportConfig[]>(url)
      .pipe(
        map(response => {
          return response.map(item => new ReportConfig(item));
        })
      );
  }

  /**
   * Creates a {@link ReportConfig} object
   * @param data Data belonging to the {@link ReportConfig} object to be created
   */
  createReportConfig(data: ReportConfig) {
    const url: string = `${this.endpoint}/config`;
    return this.httpClient.post(url, data);
  }

  /**
   * Updates the given {@link ReportConfig} object
   * @param report {@link ReportConfig} object to be update
   */
  updateReportConfig(report: ReportConfig) {
    const url: string = `${this.endpoint}/config/${report.id}`;
    return this.httpClient.put(url, report);
  }

  /**
   * Deletes the given {@link ReportConfig} object
   * @param reportId Id of the {@link ReportConfig} object to be deleted
   */
  deleteReportConfig(reportId: string) {
    const url: string = `${this.endpoint}/config/${reportId}`;
    return this.httpClient.delete(url);
  }

  /**
   * Generates a report based on the {@link ReportConfig} objects
   * @param reportId Id of the {@link ReportConfig} to be generated
   */
  generateRemoteReport(reportId: string) {
    const url: string = `${this.endpoint}/config/${reportId}/report`;
    return this.httpClient.get(url, {responseType: 'blob'}).pipe(
      map((response: any) => this.downloadReport(response, 'report.pdf'))
    );
  }

  /**
   * Generates a report for entities on the platform based on a given criteria
   */
  generateEntityReport(format: string, entityType: string, entityIds?: string[], attributes?: string[], temporalQuery?: any, filters?: any) {
    // format and entityTypes are default parameters and must exist in the query parameters
    let urlParameter = '?format=' + format + '&entityType=' + entityType;

    // append entityIds to the request if selected by the user
    if (entityIds && entityIds.length > 0) {
      urlParameter += '&entityIds=' + entityIds.join(',');
    }

    // append selected attributes by the user
    if (attributes && attributes.length > 0) {
      urlParameter += '&attributes=' + attributes.join(',');
    }

    // append the temporal query information to the request if selected by the user
    if (temporalQuery) {
      urlParameter += '&timerel=' + temporalQuery.timerel + '&time=' + temporalQuery.time;

      if (temporalQuery.endTime) {
        urlParameter += '&endTime=' + temporalQuery.endTime;
      }
    }

    if (filters) {
      Object.keys(filters).forEach(key => {
        if (filters[key] instanceof Array ) {
          if (filters[key].length > 0) {
            urlParameter += '&' + key.toString() + '=' + filters[key].join(',');
          }
        } else if (filters[key] instanceof Object ) {
          if (filters[key] !== null && filters[key] !== undefined && Object.keys(filters[key]).length > 0) {
            let values = [];
            Object.entries(filters[key]).filter(entry => entry[1] === true).forEach(entry => values.push(entry[0]));
            if (values.length > 0) {
              urlParameter += '&' + key.toString() + '=' + values.join(',');
            }
          }
        } else if (typeof filters[key] === 'string') {
          urlParameter += '&' + key.toString() + '=' + filters[key];
        }
      });
    }

    const url: string = `${this.endpoint}/entity${urlParameter}`;
    return this.httpClient.get(url, {responseType: 'blob'}).pipe(
      map((response: any) => {
        const extension = response.type === 'application/pdf' ? '.pdf' : '.csv';
        this.downloadReport(response, 'report' + extension);
      })
    );
  }

  /**
   * Generates a report
   * @param data Data to be reported
   * @param reportType Type of the report to be generated
   */
  generateReport(data: ReportContextData, reportType: string = 'table'): Observable<void> {
    const url: string = `${this.endpoint}/page?reportType=table`;
    return this.httpClient.post(url, data, {responseType: 'blob'}).pipe(
      map((response: any) => this.downloadReport(response, data.pageName + '-report.pdf'))
    );
  }

  /**
   * Download the report to the user's operating system
   * @param data Report data
   * @param fileName Name of the downloaded file
   * @private
   */
  private downloadReport(data, fileName) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(data);
    a.download = fileName;
    // start download
    a.click();
    return;
  }
}
