import {NGSIQuery} from './ngsi/ngsi-query.model';
import {BaseMetaModel} from './base/base-meta-model.model';
import {QueryType} from '../enums/query-type';
import {DataDimension} from './visualization/data-dimension.model';
import {DataSeries} from './visualization/data-series.model';
import {UrukAggregationQuery} from './ngsi/uruk-aggregation-query.model';
import {Persistable} from './persistanble';
import {UrukCrossAggQuery} from './ngsi/uruk-cross-agg-query.model';

/**
 * KPIs are units of data retrieval. They include either an NGSI query or NGSI aggregation query and a corresponding dimension set that
 * describes the data as the response of the query.
 */
export class KPI extends BaseMetaModel implements Persistable {
  /**
   * Name of the KPI
   */
  name: string;

  /**
   * Description of the KPI
   */
  description; string;

  /**
   * Query type for the KPI. See {@link QueryType}
   */
  queryType: QueryType;

  /**
   * Query to retrieve the data
   */
  query: NGSIQuery | UrukAggregationQuery | UrukCrossAggQuery;

  /**
   * Whether the geo query inside the KPI will be overridden or not by external filters
   */
  overrideGeoQuery = false;

  /**
   * Whether the temporal query inside the KPI will be overridden or not by external filters
   */
  overrideTemporalQuery = false;

  /**
   * Whether the id inside the KPI will be overridden or not by external filters
   */
  overrideIdQuery = false;

  /**
   * Whether the id inside the KPI will be overridden or not by external filters
   */
  overrideCompanyQuery = false;

  /**
   * Data series generated as a result of the query defined for this KPI.
   */
  dimensions: DataDimension[];

  /**
   * Metadata of the data series included in the KPI response
   */
  series: DataSeries[];

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.name = data.name;
    this.description = data.description;
    this.queryType = data.queryType;
    if (data.query.jsonClass === 'NGSIQuery') {
      this.query = new NGSIQuery(data.query);
    } else if (data.query.jsonClass === 'UrukAggregationQuery') {
      this.query = new UrukAggregationQuery(data.query);
    } else if (data.query.jsonClass === 'UrukCrossAggQuery') {
      this.query = new UrukCrossAggQuery(data.query);
    }
    this.overrideGeoQuery = data.overrideGeoQuery;
    this.overrideTemporalQuery = data.overrideTemporalQuery;
    this.overrideCompanyQuery = data.overrideCompanyQuery;
    this.overrideIdQuery = data.overrideIdQuery;
    if (data.dimensions) {
      this.dimensions = data.dimensions.map(dimension => new DataDimension(dimension));
    }
    if (data.series) {
      this.series = data.series.map(series => new DataSeries(series));
    }
  }

  getQueryAsAggregationQuery(): UrukAggregationQuery {
    return this.query as UrukAggregationQuery;
  }

  getQueryAsCrossAggQuery(): UrukCrossAggQuery {
    return this.query as UrukCrossAggQuery;
  }

  getQueryAsNgsiQuery(): NGSIQuery {
    return this.query as NGSIQuery;
  }

  /**
   * Extracts entity id defined in the filter of the KPI. It is either extracted by the type filter or the id filter.
   */
  getQueryEntityType(): string {
    if (this.query.filter.type) {
      return this.query.filter.type[0];
    } else {
      if (this.id) {
        const idParts: string[] = this.id.split(':');
        if (idParts.length === 4) {
          return idParts[2];
        }
      }
    }
    return null;
  }

  createPersistableObject(): any {
    const {query, ...rest} = this;
    rest['query'] = query.createPersistableObject();
    return rest;
  }
}
