import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../base.component';
import {EventService} from '../../../core/services/event.service';
import {UrukMap} from '../../models/map/uruk-map.interface';
import {NGSIGeoQuery} from '../../models/ngsi/ngsi-geo-query.model';
import {Coordinate} from '../../models/generic/coordinate.model';
import {LayerController} from '../../../core/controllers/layer.controller';
import {MapSettings} from '../../models/visualization/map-settings.model';
import {MapFramework, MapTileType} from '../../enums/map-framework.enum';
import {Point} from 'app/shared/models/geometry/point.model';

@Component({
  selector: 'uruk-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends BaseComponent implements OnInit {
  mapFramework: string = environment.map.framework;
  mapType: string = environment.map.tileType;

  /**
   * Layers to be shown on the map
   */
  @Input() layers: LayerController[];

  /**
   * Geo query to be shown on the map
   */
  @Input() geoQuery: NGSIGeoQuery;

  /**
   * Map settings for the map framework
   * */
  @Input() mapSettings: MapSettings;

  /**
   * Output informing the parent component about the coordinate of the clicked point
   */
  @Output() onMapClicked: EventEmitter<Coordinate> = new EventEmitter<Coordinate>();

  @ViewChild('map') map: UrukMap;
  MapFramework = MapFramework;
  MapTileType = MapTileType;

  constructor(private injector: Injector) {
    super(injector);
    if(localStorage.getItem("mapType") && localStorage.getItem("mapFrameWork")){
      this.mapType = localStorage.getItem("mapType");
      this.mapFramework = localStorage.getItem("mapFrameWork");
      environment.map.tileType = localStorage.getItem("mapType");
      environment.map.framework =localStorage.getItem("mapFrameWork");
    }
  }

  ngOnInit(): void {
    // register for events broadcasted by EventService
    this.handleEvents();
    const realmId = this.authService.getRealmId();
    if(!this.mapSettings){
      // master realm does not have a realm definition, skip it
      if(realmId !== environment.auth.master_realm_id){
        this.realmService.getRealm(realmId).subscribe(realm => {
          this.regionService.getRegion(realm.regionId).subscribe(region => {
            this.mapSettings = new MapSettings({
              point: region.getCenter()
            })
          })
        })
      }
    }
  }

  /**
   * Registers handlers for the map events
   */
  handleEvents() {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.ZOOM_IN:
            this.onZoomIn();
            break;
          case EventService.ZOOM_OUT:
            this.onZoomOut();
            break;
          case EventService.PAN_AND_ZOOM:
            this.onPanAndZoom(event.data.coordinate, event.data.zoomLevel, event.data.speed);
            break;
          case EventService.PAN:
            this.onPan(event.data);
            break;
          case EventService.DRAW_CIRCLE:
            this.onDrawCircleInitiated();
            break;
          case EventService.DRAW_POLYGON:
            this.onDrawPolygonInitiated();
            break;
          case EventService.DRAW_RECTANGLE:
            this.onDrawRectangleInitiated();
            break;
          case EventService.DRAW_REMOVE:
            this.onDrawnShapeRemoved();
            break;
          case EventService.MAP_TYPE_CHANGED:
            this.onMapTypeChanged(event.data)
            break;
        }
      });
    this.layoutService.onMapAreaChanged().pipe(
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.onMapAreaChanged();
    });
  }

  /*
   * Event handlers for the map
   */

  onMapAreaChanged(): void {
    this.map.onMapAreaChanged();
  }

  public onMapInitialized(geoQuery: NGSIGeoQuery): void {
    this.eventService.broadcastMapInitializedEvent(geoQuery);
  }

  public onMapBoundriesChanged(geoQuery: NGSIGeoQuery): void {
    if (this.mapFramework === MapFramework.MAPLIBRE) {
      if(!this.map.drawnShapeExists()){
        this.eventService.broadcastMapChangedEvent(geoQuery);
      }
    }else{
      this.eventService.broadcastMapChangedEvent(geoQuery);
    }
  }

  public onShapeDrawn(geoQuery: NGSIGeoQuery) {
    this.eventService.broadcastShapeChangedEvent(geoQuery);
  }

  public onInternalMapClicked(coordinate: Coordinate): void {
    this.onMapClicked.emit(coordinate);
  }

  /*
   * Event handlers for subscribed app-wide events
   */

  private onZoomIn(): void {
    this.map.onZoomIn();
  }

  private onZoomOut(): void {
    this.map.onZoomOut();
  }

  private onPanAndZoom(coordinate: Coordinate, zoomLevel: number, speed: number): void {
    this.map.panAndZoom(coordinate, zoomLevel, speed);
  }

  onPan(coordinate: Coordinate): void {
    this.map.pan(coordinate);
  }

  private onDrawCircleInitiated(): void {
    this.map.onDrawCircleInitiated();
  }

  private onDrawPolygonInitiated(): void {
    this.map.onDrawPolygonInitiated();
  }

  private onDrawRectangleInitiated(): void {
    this.map.onDrawRectangleInitiated();
  }

  private onDrawnShapeRemoved(): void {
    this.map.onDrawnShapeRemoved();
    this.eventService.broadcastShapeChangedEvent(this.map.getGeoQuery(), true);
  }

  private onMapTypeChanged(mapType: MapTileType): void {
    if(this.mapType !== MapTileType.DEFAULT && mapType === MapTileType.DEFAULT){
      localStorage.setItem('mapType', MapTileType.DEFAULT);
      localStorage.setItem('mapFrameWork', MapFramework.MAPLIBRE);
      this.mapFramework = MapFramework.MAPLIBRE;
      this.mapType = MapTileType.DEFAULT;
      environment.map.framework = MapFramework.MAPLIBRE;
      environment.map.tileType = MapTileType.DEFAULT;
    }
    if(this.mapType !== MapTileType.ROADMAP && mapType === MapTileType.ROADMAP){
      localStorage.setItem('mapType', MapTileType.ROADMAP);
      localStorage.setItem('mapFrameWork', MapFramework.LEAFLET);
      this.mapFramework = MapFramework.LEAFLET;
      this.mapType = MapTileType.ROADMAP;
      environment.map.framework = MapFramework.LEAFLET;
      environment.map.tileType = MapTileType.ROADMAP;
    }
    if(this.mapType !== MapTileType.SATELLITE && mapType === MapTileType.SATELLITE){
      localStorage.setItem('mapType', MapTileType.SATELLITE);
      localStorage.setItem('mapFrameWork', MapFramework.LEAFLET);
      this.mapFramework = MapFramework.LEAFLET;
      this.mapType = MapTileType.SATELLITE;
      environment.map.framework = MapFramework.LEAFLET;
      environment.map.tileType = MapTileType.SATELLITE;
    }
    return;
  }
}
