import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable, of} from 'rxjs';
import {NGSIPath} from '../../../shared/models/ngsi/ngsi-path.model';
import {QueryOperator} from '../../../shared/models/query/query-operator.model';
import {QueryOperatorExpr} from '../../../shared/models/query/query-operator-expr.model';
import {NGSIOperator} from '../../../shared/models/ngsi/operator/ngsi-operator.model';
import {NGSIOperatorCategory} from '../../../shared/enums/ngsi-query.enum';
import {map} from 'rxjs/operators';
import {RestUtil} from '../../../shared/utils/rest-util';
import {ObjectUtil} from '../../../shared/utils/object-util';
import {BaseHttpClientService} from '../base-http-client.service';

/**
 * Service that obtains information about NGSI query elements that can be generated on the backend side e.g. parameters that can be used in a certain step of pipeline.
 */
@Injectable({
  providedIn: 'root'
})
export class OperatorDefinitionService extends BaseHttpClientService {
  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'operatordefinitions');
  }

  /**
   * Gets operators for the given categories
   * @param operatorCategories
   */
  getQueryOperators(operatorCategories: NGSIOperatorCategory[]): Observable<NGSIOperator[]> {
    let url: string = this.endpoint;
    const parameters: any = {
      category: operatorCategories
    };
    if (!ObjectUtil.isEmpty(parameters)) {
      const urlParameters = RestUtil.createURLParameters(parameters);
      url += '?' + urlParameters;
    }
    return this.httpClient.get<NGSIOperator[]>(url)
      .pipe(
        map(response => response.map(item => new NGSIOperator(item)))
      );
  }
}
