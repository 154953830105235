import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Panel} from '../../../../../shared/models/visualization/panel.model';
import {environment} from '../../../../../../environments/environment';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {EntityResult} from '../../../../../shared/models/schema/entity-result';

@Component({
  selector: 'junction-arm-dialog-template',
  templateUrl: './junction-arm-dialog-template.component.html',
  styleUrls: ['./junction-arm-dialog-template.component.scss']
})
export class JunctionArmDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  // Context variables
  title: string;
  topLabels: string[] = [];
  topValues: string[] = [];
  footer: string;
  entity: EntityResult;

  /**
   * Panels
   */
  PANEL_LOCATION_SECOND_ROW = 'second-row';
  PANEL_LOCATION_THIRD_ROW = 'third-row';
  secondRowPanel: Panel;
  thirdRowPanel: Panel;

  displayMode: number;
  displayModeInfo = 1;
  displayModeCamera = 2;

  env = environment;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.page.panels.forEach(panel => {
      panel.ngsiContext.idQuery = this.entity.id;
    });
    this.displayMode = this.displayModeInfo;
    this.checkMissingValues();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  displayCamera() {
    this.displayMode = this.displayModeCamera;
  }

  displayInfo() {
    this.displayMode = this.displayModeInfo;
  }

  shouldDisplayCamera() {
    return this.displayMode === this.displayModeCamera;
  }

  shouldDisplayInfo() {
    return this.displayMode === this.displayModeInfo;
  }

  deletePanel(panel: Panel): void {
  }

  mapPanels(): void {
    this.page.panels.forEach(panel => {
      switch (panel.panelLocation) {
        case this.PANEL_LOCATION_SECOND_ROW:
          this.secondRowPanel = panel;
          break;
        case this.PANEL_LOCATION_THIRD_ROW:
          this.thirdRowPanel = panel;
          break;
      }
    });
  }

  protected mapContextData(context: any): void {
    this.title = context.title;
    this.topLabels = context.topLabels;
    this.topValues = context.topValues;
    this.footer = context.footer;
    this.entity = context.entity;
  }

  /**
   * Puts a dash (-) for null or undefined values
   * @private
   */
  private checkMissingValues(): void {
    this.topValues = this.topValues.map(value => {
      if (value) {
        return value;
      } else {
        return '-';
      }
    });
  }
}
