import {EventEmitter} from '@angular/core';

export class SVGManagerUtil {
  public resultEmitter: EventEmitter<any> = new EventEmitter();
  private readonly svgContainer: HTMLElement;
  private readonly colorRules: { [key: string]: any };
  private readonly clickHandlers: { [key: string]: (id: string) => any };
  private readonly svgIndex: any = ''; // Maps specific group IDs to their corresponding index
  private readonly isModificationEnabled: boolean = false; // Indicates if SVG modification is enabled
  private readonly isClickEnabled: boolean = false; // Indicates if click handling is enabled
  private svgData: any = ''; // Data related to the SVG for conditional logic
  private activeTab: string = ''; // The currently active tab for applying specific rules
  private selectedResult: any = ''; // Holds the result after an SVG element is clicked

  constructor(
    svgContainer: HTMLElement,
    colorRules: { [key: string]: any },
    clickHandlers: { [key: string]: (id: string) => any },
    svgIndex: any = '',
    isModificationEnabled: boolean = false,
    isClickEnabled: boolean = false
  ) {
    this.svgContainer = svgContainer;
    this.colorRules = colorRules;
    this.clickHandlers = clickHandlers;
    this.svgIndex = svgIndex;
    this.isModificationEnabled = isModificationEnabled;
    this.isClickEnabled = isClickEnabled;
  }

  /**
   * Loads the SVG from a provided path and modifies it according to the current settings.
   * @param svgPath - Path to the SVG file.
   * @param svgData - Data related to the SVG that may influence modifications.
   * @param activeTab - The currently active tab used to apply specific color and click handlers.
   * @returns The selected result.
   */
  public loadAndModifySVG(svgPath: string, svgData: any = '', activeTab: string = '') {
    if (activeTab !== '') {
      this.activeTab = activeTab;
    }

    if (svgData !== '') {
      this.svgData = svgData;
    }

    fetch(svgPath)
      .then(response => response.text())
      .then(data => {
        this.handleSVGData(data);
      })
      .catch(error => {
        console.error('An error occurred while loading the SVG: ', error);
      });
  }

  /**
   * Handles the loaded SVG data and applies modifications based on the current configuration.
   * @param data - The raw SVG data to be inserted and processed.
   */
  private handleSVGData(data: string) {
    // If the svg container is found, load the SVG and modify it.
    if (this.svgContainer) {
      this.svgContainer.innerHTML = data;
      // Modify 'rect', 'path', and 'g' elements based on rules and handle clicks.
      this.setupElements('rect', this.handleModifyRect.bind(this), this.handleRectClick.bind(this)); // Modify 'rect' elements in the SVG
      this.setupElements('path', this.handleModifyPath.bind(this), this.handlePathClick.bind(this)); // Modify 'path' elements in the SVG
      this.setupElements('g', this.handleModifyGroup.bind(this), this.handleGroupClick.bind(this)); // Modify 'g' elements in the SVG
      this.setupText(); // Add dynamic text to certain elements based on rules.
    }
  }

  /**
   * Generic setup method for handling different types of SVG elements.
   * It applies modifications and attaches click handlers if enabled.
   * @param elementType - The SVG element type (e.g., 'rect', 'path', 'g').
   * @param modifyHandler - A function to modify the element (color, attributes, etc.).
   * @param clickHandler - A function to handle click events on the element.
   */
  private setupElements(
    elementType: 'rect' | 'path' | 'g',
    modifyHandler: (element: SVGGraphicsElement) => void,
    clickHandler: (element: SVGGraphicsElement) => any
  ) {
    const elements = this.svgContainer.querySelectorAll(elementType);

    elements.forEach(element => {
      // Only modify elements with the class 'unit'
      if (element.classList.contains('unit')) {
        if (this.isModificationEnabled) {
          modifyHandler(element); // Apply element-specific modifications.
        }

        if (this.isClickEnabled) {
          element.style.cursor = 'pointer'; // Change cursor to pointer if clickable.
          element.addEventListener('click', () => {
            this.selectedResult = clickHandler(element); // Execute the click handler.
            if (this.selectedResult) {
              this.resultEmitter.emit(this.selectedResult);
            }
          });
        }
      }
    });
  }

  /**
   * Modifies the fill color of rect elements according to the rules.
   * @param rect - The SVG rect element to modify.
   */
  private handleModifyRect(rect: SVGRectElement) {
    this.applyColorModification(rect, 'fill');
  }

  /**
   * Modifies the stroke color of path elements according to the rules.
   * @param path - The SVG path element to modify.
   */
  private handleModifyPath(path: SVGPathElement) {
    this.applyColorModification(path, 'stroke');
  }

  /**
   * Modifies the fill color of group elements ('g') according to the rules.
   * @param g - The SVG group element to modify.
   */
  private handleModifyGroup(g: SVGGElement) {
    this.applyColorModification(g, 'fill');
  }

  /**
   * Applies color modifications to a specific SVG element.
   * @param element - The SVG element (rect, path, g) to modify.
   * @param attribute - The attribute to modify (e.g., 'fill', 'stroke').
   */
  private applyColorModification(element: SVGGraphicsElement, attribute: string) {
    const rules = this.colorRules[this.activeTab];
    if (rules) {
      for (const rule of rules) {
        if (element.id.includes(rule.target)) {
          const isConditionMet = this.evaluateCondition(rule.condition, element);
          if (isConditionMet) {
            element.setAttribute(attribute, rule.color);
          }
        }
      }
    }
  }

  /**
   * Sets up dynamic text elements in the SVG, adding them next to specific elements
   * based on rules defined for the current active tab.
   */
  private setupText() {
    const elements = this.svgContainer.querySelectorAll('.text-pointer');
    const rules = this.colorRules[this.activeTab];

    if (!rules) {
      return;
    }

    elements.forEach((element) => {
      if (element instanceof SVGGraphicsElement) {
        for (const rule of rules) {
          if (rule.target.includes('text')) {

            const value = this.getRandomInt(rule.min, rule.max) + rule.unit;

            const isConditionMet = this.evaluateCondition(rule.condition, element);
            if (isConditionMet) {
              const bbox = element.getBBox();
              this.appendTextElement(element, value, bbox, 10, 0, rule.color);
            }
          }
        }
      }
    });
  }

  /**
   * Appends a text element next to a specific SVG element.
   * @param parentElement - The SVG element to append text next to.
   * @param textContent - The text content to display.
   * @param bbox - The bounding box of the parent element for positioning the text.
   * @param offsetX - Horizontal offset from the parent element.
   * @param offsetY - Vertical offset from the parent element.
   * @param color - The color of the text.
   */
  private appendTextElement(
    parentElement: Element,
    textContent: string,
    bbox: DOMRect,
    offsetX: number = 10,
    offsetY: number = 0,
    color: string
  ): void {
    const textElem = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    const x = bbox.x + bbox.width + offsetX; // Position the text to the right of the element.
    const y = bbox.y + bbox.height + offsetY; // Vertically align the text with the element.

    textElem.setAttribute('x', x.toString());
    textElem.setAttribute('y', y.toString());
    textElem.setAttribute('fill', color);
    textElem.setAttribute('font-size', '16');
    textElem.setAttribute('font-family', 'Arial');
    textElem.textContent = textContent;

    parentElement.after(textElem); // Insert the text element after the parent.
  }

  /**
   * Evaluates a condition to determine if the rule should be applied.
   * @param condition - The condition function or expression to evaluate.
   * @param element - The current SVG element being processed.
   * @returns True if the condition is met, false otherwise.
   */
  private evaluateCondition(condition: any, element: SVGGraphicsElement): boolean {
    if (typeof condition === 'function') {
      const parentGroupId = this.findParentGroupId(element);
      return condition(this.svgIndex[parentGroupId] || 0, this.svgData);
    }
    return false;
  }

  /**
   * Retrieves the g element ID for a specific SVG element by checking its parent 'g' element.
   * @param element - The current SVG element.
   * @returns The ID of the parent 'g' element or an empty string if none is found.
   */
  private findParentGroupId(element: SVGGraphicsElement): string {
    const parentG = element.parentElement?.closest('g');
    return parentG?.id || '';
  }

  /**
   * Handles clicks on rect elements by executing the relevant click handler.
   * @param rect - The clicked SVG rect element.
   * @returns The result of the click handler.
   */
  private handleRectClick(rect: SVGRectElement): any {
    return this.handleClick(rect);
  }

  /**
   * Handles clicks on path elements by executing the relevant click handler.
   * @param path - The clicked SVG path element.
   * @returns The result of the click handler.
   */
  private handlePathClick(path: SVGPathElement): any {
    return this.handleClick(path);
  }

  /**
   * Handles clicks on group ('g') elements by executing the relevant click handler.
   * @param g - The clicked SVG group element.
   * @returns The result of the click handler.
   */
  private handleGroupClick(g: SVGGElement): any {
    return this.handleClick(g);
  }

  /**
   * Executes the click handler for a given SVG element.
   * @param element - The SVG element that was clicked.
   * @returns The result of the click handler or an empty string if no handler exists.
   */
  private handleClick(element: SVGGraphicsElement): any {
    const handler = this.clickHandlers[this.activeTab];

    if (handler) {
      const result = handler(element.id);

      return {
        ...result,
        index: this.svgIndex[this.findParentGroupId(element)] || 0
      };
    }

    return '';
  }

  /**
   * Generates a random integer between the given min and max values.
   * @param min - The minimum value (inclusive).
   * @param max - The maximum value (inclusive).
   * @returns A random integer between min and max.
   */
  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private getStatus(): any {
    return {};
  }
}
