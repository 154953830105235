export abstract class GeoJson {

  /**
   * Type of GeoJson such as Point or Polygon
   * */
  type: string;


  constructor(data?) {
    if (!data) {
      return;
    }

    this.type = data.type;
  }

  /**
   * Returns the coordinate of center point for the given geoJSON.
   * @param geoJson GeoJSON
   * @return center of geoJSON as an array of numbers in [longitude, latitude] order
   */
  public static getCenter(geoJson: any): [number, number] {
    switch (geoJson.type) {
      case "Point":
        return geoJson.coordinates;
      case "LineString":
        const latitudeArray = geoJson.coordinates.map(coordinates => coordinates[0]);
        const midLatitude = latitudeArray.reduce((a, b) => a + b, 0) / latitudeArray.length;

        const longitudeArray = geoJson.coordinates.map(coordinates => coordinates[1]);
        const midLongitude = longitudeArray.reduce((a, b) => a + b, 0) / longitudeArray.length;
         
        return [midLongitude, midLatitude];
      case "Polygon":
        // assume that polygon does not have any interior rings or holes
        const polygonCoordinates = geoJson.coordinates[0];
        const polygonPointCount = polygonCoordinates.length;
        return [polygonCoordinates.reduce((sum, point) => sum + point[0], 0) / polygonPointCount, polygonCoordinates.reduce((sum, point) => sum + point[1], 0) / polygonPointCount];
      default:
        throw Error(`Method not implemented to find center of ${geoJson.type}`)
    }
  }
}
