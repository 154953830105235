import {NGSIContext} from '../generic/ngsi-context.model';
import {ChartSettings} from './chart-settings.model';
import {Persistable} from '../persistanble';

/**
 * Panel is a container that shows values obtained for a KPI via the NGSI context object. The values is displayed in different ways
 * according to the chart settings.
 */
export class Panel implements Persistable {

  /**
   * Title of the panel
   */
  title: string;

  /**
   * Location of the panel that determines the panel's position in the container page template
   */
  panelLocation: string;

  /**
   * Context object that is used to fetch data to be presented in this panel
   */
  ngsiContext: NGSIContext;

  /**
   * Settings for the chart to be shown in this panel
   */
  chartSettings: ChartSettings;

  constructor(data?) {
    if (!data) {
      return;
    }

    this.title = data.title;
    this.panelLocation = data.panelLocation;
    this.ngsiContext = new NGSIContext(data.ngsiContext);
    this.chartSettings = new ChartSettings(data.chartSettings);
  }

  createPersistableObject(): any {
    const {ngsiContext, chartSettings, ...rest} = this;
    rest['ngsiContext'] = ngsiContext.createPersistableObject();
    rest['chartSettings'] = chartSettings.createPersistableObject();
    return rest;
  }
}
