export enum ChartType {
  Metric = 'metric',
  Indicator = 'indicator',
  Table = 'table',
  Line = 'line',
  VerticalBar = 'vbar',
  HorizontalBar = 'hbar',
  Pie = 'pie',
  Doughnut = 'doughnut',
  Gauge = 'gauge',
  Ring = 'ring',
  Scatter = 'scatter',
  List = 'list',
}

/**
 * Object that keeps details about chart types
 */
export const chartTypes = {
  metric: {
    id: 'metric',
    name: 'Metric',
    paths: ['M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z'],
    series: [
      {
        label: 'Metrik'
      }
    ]
  },
  indicator: {
    series: [
      {
        label: 'Metrik',
      }
    ],
    disabledInPanels: true
  },
  // table: {
  //   series: [],
  //   dimensions: [
  //     {
  //       label: 'X Ekseni',
  //     }
  //   ]
  // },
  line: {
    id: 'line',
    name: 'Line',
    paths: ['M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z'],
    series: [
      {
        label: 'Y Ekseni',
      }
    ],
    dimensions: [
      {
        label: 'X Ekseni',
      }
    ]
  },
  vbar: {
    id: 'vbar',
    name: 'Bar',
    paths: ['M22,21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z'],
    series: [
      {
        label: 'Y Ekseni'
      }
    ],
    dimensions: [
      {
        label: 'X Ekseni'
      }
    ]
  },
  // hbar: {
  //   series: [
  //     {
  //       label: 'Y Ekseni',
  //     }
  //   ],
  //   dimensions: [
  //     {
  //       label: 'X Ekseni',
  //     }
  //   ]
  // },
  pie: {
    id: 'pie',
    name: 'Pie',
    paths: ['M21,11H13V3A8,8 0 0,1 21,11M19,13C19,15.78 17.58,18.23 15.43,19.67L11.58,13H19M11,21C8.22,21 5.77,19.58 4.33,17.43L10.82,13.68L14.56,20.17C13.5,20.7 12.28,21 11,21M3,13A8,8 0 0,1 11,5V12.42L3.83,16.56C3.3,15.5 3,14.28 3,13Z'],
    series: [
      {
        label: 'Metrik',
      }
    ],
    dimensions: [
      {
        label: 'Dilim',
      }
    ]
  },
  doughnut: {
    id: 'doughnut',
    name: 'Doughnut',
    paths: ['M16.18,19.6L14.17,16.12C15.15,15.4 15.83,14.28 15.97,13H20C19.83,15.76 18.35,18.16 16.18,19.6M13,7.03V3C17.3,3.26 20.74,6.7 21,11H16.97C16.74,8.91 15.09,7.26 13,7.03M7,12.5C7,13.14 7.13,13.75 7.38,14.3L3.9,16.31C3.32,15.16 3,13.87 3,12.5C3,7.97 6.54,4.27 11,4V8.03C8.75,8.28 7,10.18 7,12.5M11.5,21C8.53,21 5.92,19.5 4.4,17.18L7.88,15.17C8.7,16.28 10,17 11.5,17C12.14,17 12.75,16.87 13.3,16.62L15.31,20.1C14.16,20.68 12.87,21 11.5,21Z'],
    series: [
      {
        label: 'Metrik',
      }
    ],
    dimensions: [
      {
        label: 'Dilim',
      }
    ]
  },
  gauge: {
    id: 'gauge',
    name: 'Gauge',
    paths: ['M17.3,18C19,16.5 20,14.4 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12C4,14.4 5,16.5 6.7,18C8.2,16.7 10,16 12,16C14,16 15.9,16.7 17.3,18M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M7,9A1,1 0 0,1 8,10A1,1 0 0,1 7,11A1,1 0 0,1 6,10A1,1 0 0,1 7,9M10,6A1,1 0 0,1 11,7A1,1 0 0,1 10,8A1,1 0 0,1 9,7A1,1 0 0,1 10,6M17,9A1,1 0 0,1 18,10A1,1 0 0,1 17,11A1,1 0 0,1 16,10A1,1 0 0,1 17,9M14.4,6.1C14.9,6.3 15.1,6.9 15,7.4L13.6,10.8C13.8,11.1 14,11.5 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12C10,11 10.7,10.1 11.7,10L13.1,6.7C13.3,6.1 13.9,5.9 14.4,6.1Z'],
    series: [
      {
        label: 'Metrik'
      }
    ]
  },
  // ring: {
  //   series: [
  //     {
  //       label: 'Metrik',
  //     }
  //   ],
  //   dimensions: [
  //     {
  //       label: 'Çember',
  //     }
  //   ]
  // },
  // scatter: {
  //   series: [
  //     {
  //       label: 'Y Ekseni',
  //     }
  //   ],
  //   dimensions: [
  //     {
  //       label: 'X Ekseni',
  //     }
  //   ]
  // }
};

