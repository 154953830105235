import {LayerController} from './layer.controller';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {catchError, forkJoin, Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {Marker} from '../../shared/models/map/marker.model';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {NGSIEntity, NGSIResultUtil} from '../../shared/utils/ngsi-result-util';
import {EntityRepresentation} from "../../shared/models/visualization/representation.model";
import {Notification} from "../../shared/models/notification.model";
import {DefibrillatorLayerService} from "../services/data/defibrillator-layer.service";


export class DefibrillatorLayerController extends  LayerController {
  displaysTooltip: boolean = true;
  private readonly entityRep : EntityRepresentation;
  private defibrillatorService : DefibrillatorLayerService;

  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
    if(layer){
      this.entityRep = layer.representations[0];
      this.defibrillatorService = DefibrillatorLayerService.getInstance(layer, injector);
      this.defibrillatorService.addSubscriber(this);
    }
  }
  onDestroy(): void {
    super.onDestroy();
    this.defibrillatorService.unsubscribe(this);
  }

  getDefibrillators() {
    this.processData(this.defibrillatorService.getDefibrillatorsForRepresentation(this.entityRep), this.entityRep);
  }

  public getDialogContext(result: NGSIResult, cBrokerEndpoint:string = null): any {
    const query: NGSIQuery = new NGSIQuery();
    const defibrillatorId = this.getIdFromNGSIResult(result);
    query.filter = new NGSIFilter();
    query.filter.id = [defibrillatorId];
    let contextObservable: Observable<any> = null;

    const defibrillatorQuery = this.extracted(defibrillatorId);
    const defibrillatorTestRef =  this.cbrokerService.getEntities(defibrillatorQuery, new QueryOptions(false), null, null ,false, null, cBrokerEndpoint).pipe(catchError(() => of(null)));
    const defibrillatorRefQuery = this.cbrokerService.getEntity(query, new QueryOptions(false), false, cBrokerEndpoint).pipe(catchError(() => of(null)));
    const healthCaseQuery = this.extractedHealthCase(defibrillatorId);
    const healthCaseRefQuery = this.cbrokerService.getEntity(healthCaseQuery, new QueryOptions(false),false, cBrokerEndpoint).pipe(catchError(() => of(null)));
    const defibrillatorPositionQuery = this.getMarkerEntity(result.getEntityId(),false, cBrokerEndpoint);
    contextObservable = forkJoin([defibrillatorRefQuery, defibrillatorPositionQuery, defibrillatorTestRef, healthCaseRefQuery]).pipe(
      take(1),
      map(response => {
        return {
          entity: response[0],
          positionEntity: response[1],
          subEntities: response[2],
          healthCases: response[3]
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }
  protected handleCreateEvent(representation: EntityRepresentation, notification: Notification): NGSIResult {
    const entityResult: NGSIResult = NGSIResultUtil.mapEntity(notification.eventPayload.content);

    if(notification.eventPayload.eventType === 'C'){
      if(notification.eventPayload.entityType === 'DefibrillatorPosition'){
        this.defibrillatorService.updatePositionColorWithPosition(entityResult);
      }else{
        this.defibrillatorService.updatePositionColorWithTest(entityResult);
      }
    }
    return null;
  }

  private getIdFromNGSIResult(ngsiResult: NGSIResult): string {
    return ngsiResult.getEntityResult().getSingleElementValue('refId');
  }

  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    const entitiesFound: Set<string> = new Set();
    const finalResults: NGSIResult[] = [];
    if (results === null) {
       return finalResults;
    }
    results.forEach(result => {
      const defibrillatorId: string = this.getIdFromNGSIResult(result);
      if (!entitiesFound.has(defibrillatorId)) {
        finalResults.push(result);
        entitiesFound.add(defibrillatorId);
      }
    })
    return finalResults;
  }

  createTooltip(marker: Marker): string {
    const id: string = marker.entity.getSingleElementValue("refId").split(':');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text">${id[3].substring(12)}</div>`;
    html += '</div';

    return html;
  }

  private extractedHealthCase(defibrillatorId: string) {
    return new NGSIQuery({
      filter: {
        type: [NGSIEntity.HEALTHCASE],
        q: {
          jsonClass: "NGSIQueryTerm",
          path: {
            jsonClass: "NGSIPath",
            propOrRelPath: [
              "refId"
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: "==",
          value: [
            defibrillatorId
          ]
        }
      },
      limit: 1000
    });
  }

  private extracted(defibrillatorId: string) {
    return new NGSIQuery({
      filter: {
        type: [NGSIEntity.DEFIBRILLATOR_TEST],
        q: {
          jsonClass: "NGSIQueryTerm",
          path: {
            jsonClass: "NGSIPath",
            propOrRelPath: [
              "refId"
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: "==",
          value: [
            defibrillatorId
          ]
        }
      },
      limit: 1000
    });
  }

  private checkStatus(results: NGSIResult[]) {
    let result : NGSIResult[] = [];
    let resultMove : NGSIResult[] = [];

    results.forEach(cursor => {
      if(cursor.getEntityResult().entity.status !== undefined && cursor.getEntityResult().getElementValue('status') === 1){
        resultMove.push(cursor);
      }else{
        result.push(cursor);
      }
    });

    return {result, resultMove};
  }

  /**
   * Sets entities with corresponding map constructs (markers, polylines, polygons, etc) of this layer
   * @param results
   * @param representation A specific representation for the retrieved data
   */
  processData(results: NGSIResult[], representation: EntityRepresentation) {
    results = this.beforeEntitiesProcessed(results);
    let response = this.checkStatus(results);
    let resultMove: NGSIResult[] = response.resultMove;
    let result: NGSIResult[] = response.result;

    this.clearEntities(representation);
    this.clearEntities(this.layer.representations[1]);

    this.entities.set(representation, result);
    this.entities.set(this.layer.representations[1], resultMove);

    this.createMapObjects(this.entities.get(representation), representation);
    this.createMapObjects(this.entities.get(this.layer.representations[1]), this.layer.representations[1]);

    // layer's visibility is set only for the first processing of the results. I.e. once the layer is initialized, this part does not override the visibility
    if (this.initializedLayerCount < this.layer.representations.length) {
      this.setVisibility(true);

      this.initializedLayerCount++;
    }
    this.mergeMappedEntities();
    this.afterEntitiesProcessed();
    this.eventService.broadcastLayerProcessingFinishedEvent(this);
  }
}
