<closeable-dialog class="announceSensor-dialog-container">
  <div class="dialog-header" dialog-card-header>
    <label class="column-info title">{{'announceSensor' | translate}}</label>
    <label [ngClass]="'column-info content'">({{sensorUnitId}}, {{rtuId}})</label>
  </div>
  <div dialog-card-body class="dialog-body full-height">
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab
        [tabIcon]="statusColor[0] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : statusColor[0] === '-yellow' ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
              tabTitle="{{'announceSensorInformation' | translate}}" [active]="activeTab === 'announceSensorInformation'" [tabId]="'announceSensorInformation'">
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'sensorUnitId' | translate}} :  &nbsp;  </label>
            <label class="column-info content">{{sensorUnitId}}</label>
          </div>
          <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'90px'}">
            <label class="column-info title"> {{'rtuId' | translate}} :</label>
            <label class="column-info content">{{rtuId}}</label>
          </div>
        </div>
        <div  class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'sensorType' | translate}} :  &nbsp; </label>
            <label class="column-info content"> {{sensorType}} </label>
          </div>
          <div [ngClass]="'flex-50'" [ngStyle]="{'margin-left':'90px'}">
            <label class="column-info title"> {{'status' | translate}} :  &nbsp; </label>
            <label [ngClass]="'column-info content'+statusColor[0]">{{connectionStatus}}</label>
          </div>
        </div>
        <div  class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'description' | translate}} :  &nbsp;  </label>
            <label class="column-info content">{{description}}</label>
          </div>
        </div>
      </nb-tab>
      <nb-tab
        [tabIcon]="statusColor[1] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : statusColor[1] === '-yellow' ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
        tabTitle="{{'amplifierState' | translate}}" [active]="activeTab === 'amplifierState'" [tabId]="'amplifierState'">
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'amplifierState1' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorAmplifierError1">{{amplifierStateMap[amplifierState1]}} / {{amplifierErrorMap[amplifierError1]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'amplifierState2' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorAmplifierError2">{{amplifierStateMap[amplifierState2]}} / {{amplifierErrorMap[amplifierError2]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'amplifierState3' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorAmplifierError3">{{amplifierStateMap[amplifierState3]}} / {{amplifierErrorMap[amplifierError3]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'amplifierState4' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorAmplifierError4">{{amplifierStateMap[amplifierState4]}} / {{amplifierErrorMap[amplifierError4]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'amplifierState5' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorAmplifierError5">{{amplifierStateMap[amplifierState5]}} / {{amplifierErrorMap[amplifierError5]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'amplifierState6' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorAmplifierError6"> {{amplifierStateMap[amplifierState6]}} / {{amplifierErrorMap[amplifierError6]}}</label>
          </div>
        </div>
          <div class="d-flex ">
            <div class="flex-50">
              <label class="column-info title"> {{'amplifierState7' | translate}} :</label>
              <label [ngClass]="'column-info content'+ colorAmplifierError7"> {{amplifierStateMap[amplifierState7]}} / {{amplifierErrorMap[amplifierError7]}}</label>
            </div>
            <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
              <label class="column-info title"> {{'amplifierState8' | translate}} :</label>
              <label [ngClass]="'column-info content'+ colorAmplifierError8"> {{amplifierStateMap[amplifierState8]}} / {{amplifierErrorMap[amplifierError8]}}</label>
            </div>
          </div>
      </nb-tab>

      <nb-tab
        [tabIcon]="statusColor[2] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : statusColor[2] === '-yellow' ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
        tabTitle="{{'driverError' | translate}}" [active]="activeTab === 'driverError'" [tabId]="'driverError'">
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'driverError1' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorDriverError1"> {{driverErrorMap[driverError1]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'driverError2' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorDriverError2"> {{driverErrorMap[driverError2]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'driverError3' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorDriverError3"> {{driverErrorMap[driverError3]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'driverError4' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorDriverError4"> {{driverErrorMap[driverError4]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'driverError5' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorDriverError5"> {{driverErrorMap[driverError5]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'driverError6' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorDriverError6"> {{driverErrorMap[driverError6]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'driverError7' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorDriverError7"> {{driverErrorMap[driverError7]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'driverError8' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorDriverError8"> {{driverErrorMap[driverError8]}}</label>
          </div>
        </div>
      </nb-tab>

      <nb-tab
        [tabIcon]="statusColor[3] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00'}} : statusColor[3] === '-yellow' ? {icon:'alert-triangle', options:{color:'#FFB800'}} : {}"
        tabTitle="{{'announceSensorBitStatus' | translate}}" [active]="activeTab === 'announceSensorBitStatus'" [tabId]="'announceSensorBitStatus'">
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'speakerVolume1' | translate}} : </label>
            <label class="column-info content">{{speakerVolume1}} (dB)</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'speakerVolume2' | translate}} : </label>
            <label class="column-info content">{{speakerVolume2}} (dB)</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'speakerVolume3' | translate}} :</label>
            <label class="column-info content">{{speakerVolume3}} (dB)</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'speakerVolume4' | translate}} :</label>
            <label class="column-info content">{{speakerVolume4}} (dB)</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'batteryVoltage' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorBatteryVoltage"> {{batteryVoltage}} (V)</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'cabinetTemperature' | translate}} : </label>
            <label [ngClass]="'column-info content'+ colorCabinetTemperature">  {{cabinetTemperature}} (&deg;C )</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'gpsStatus' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorGpsStatus">  {{gpsStatusMap[gpsStatus]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'doorStatus' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorDoorStatus">  {{doorStatusMap[doorStatus]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'cityNetworkInformationStatus' | translate}} :</label>
            <label [ngClass]="'column-info content' + colorCityNetworkInformationStatus">  {{cityNetworkStatusMap[cityNetworkInformationStatus]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'pvSolarPanelStatusInformation' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorPvSolarPanelStatusInformation">  {{pvSolarPanelStatusMap[pvSolarPanelStatusInformation]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'acChargerErrorStatusInformation' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorAcChargerErrorStatusInformation"> {{statusMap[acChargerErrorStatusInformation]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'pvChargerErrorStatusInformation' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorPvChargerErrorStatusInformation"> {{statusMap[pvChargerErrorStatusInformation]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'tcpIpConnectionStatusInformation' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorTcpIpConnectionStatusInformation"> {{statusMap[tcpIpConnectionStatusInformation]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'aircraftWarningLambStatusInformation' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorAircraftWarningLambStatusInformation"> {{statusMap[aircraftWarningLambStatusInformation]}}</label>
          </div>
        </div>
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'radioLinkStatusInformation' | translate}} :</label>
            <label [ngClass]="'column-info content'+ colorRadioLinkStatusInformation"> {{radioLinkStatusMap[radioLinkStatusInformation]}}</label>
          </div>
          <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
            <label class="column-info title"> {{'endPointWorkingMode' | translate}} :</label>
            <label [ngClass]="'column-info content' + colorEndPointWorkingMode"> {{endPointWorkingModeMap[endPointWorkingMode]}}</label>
          </div>
        </div>
      </nb-tab>


    </nb-tabset>
  </div>
  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info title">{{'coordinate' | translate}}: </label>
      <label class="column-info content">{{location}}</label>
    </div>
  </div>
</closeable-dialog>
