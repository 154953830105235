import {Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {BaseDialogTemplateComponent } from '../base-dialog-template.component';
import {PaginationCallbackFunction} from '../../../../../shared/components/list/list.component';
import {PAGINATION_DEFAULT_PAGE_SIZE} from '../../../../../shared/components/list/pagination.component';
import {ContextService} from '../../../../../core/services/http/context.service';
import {Subscription} from 'rxjs';
import {JemusTak} from '../../../../../shared/models/jemusTak.model';

@Component({
  selector: 'jkgys-template',
  templateUrl: './jkgys-template.component.html',
  styleUrls: ['./jkgys-template.component.scss']
})
export class JkgysTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  entity: any;
  takEntity: any;
  id: any;
  gendarmerieStationLocation: any;
  sensorType: any;
  city: any;
  district: any;
  description: any;
  connectionStatus: any;
  colorConnectionStatus: any;
  cbrokerService: ContextService;
  jemusTaks: JemusTak[] = [];
  paginatedJemusTaks: JemusTak[] = [];
  showFooter: boolean = true;

  activeTab: string;

  private pageSize = PAGINATION_DEFAULT_PAGE_SIZE;

  private mgubPage = 1;

  public paginationCallback: PaginationCallbackFunction;

  private refreshingIntervalId: Subscription;

  columnMetadata = [
    {
      label: 'city',
      field: 'city',
      columnWidth: 3
    },
    {
      label: 'direction',
      field: 'direction',
      columnWidth: 3
    },
    {
      label: 'district',
      field: 'district',
      columnWidth: 3
    },
    {
      label: 'description',
      field: 'description',
      columnWidth: 3
    },
    {
      label: 'sensorType',
      field: 'sensorType',
      columnWidth: 4
    }
  ];

  connectionStatusMap = new Map<number, string>([
    [0, 'Durum Bilgisi Yok'],
    [1, 'Bağlantı Yok'],
    [9, 'Bağlantı Var'],
  ]);

  constructor(injector: Injector) {
    super(injector);
    this.cbrokerService = injector.get(ContextService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.paginationCallback = this.setPaginatedJemusTaks.bind(this);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.refreshingIntervalId) {
      this.refreshingIntervalId.unsubscribe();
    }
  }

  protected mapContextData(context: any): void {
    this.entity = context.entity ? context.entity : null;
    this.takEntity = context.takEntity ? context.takEntity : null;

    this.district = this.entity.results[0].result.entity.district ? this.entity.results[0].result.entity.district.value : '-';
    this.description = this.entity.results[0].result.entity.description ? this.entity.results[0].result.entity.description.value : '-';
    this.connectionStatus = this.connectionStatusMap.get(this.entity.results[0].result.entity.connectionStatus.value);
    this.colorConnectionStatus = this.entity.results[0].result.entity.connectionStatus.value !== 9 ? 'red' : '';
    this.sensorType = this.entity.results[0].result.entity.sensorType ? this.entity.results[0].result.entity.sensorType.value : '-';
    this.city = this.entity.results[0].result.entity.city ? this.entity.results[0].result.entity.city.value : '-';
    this.gendarmerieStationLocation = this.entity.results[0].result.entity.location ?  this.entity.results[0].result.entity.location.value.coordinates.join(', ') : '-';
    this.id = this.entity.results[0].result.entity.id ? this.entity.results[0].result.entity.id.split(':')[3] : '-';

    for (let i = 0; i < this.takEntity.results.length; i++)
        this.jemusTaks.push(new JemusTak({
          id:  this.takEntity.results[i].result.entity.id ? this.takEntity.results[i].result.entity.id.split(':')[3] : '-',
          direction:  this.takEntity.results[i].result.entity.direction ? this.takEntity.results[i].result.entity.direction.value.split('|') : '-',
          city:   this.takEntity.results[i].result.entity.city ? this.takEntity.results[i].result.entity.city.value : '-',
          description:   this.takEntity.results[i].result.entity.description ? this.takEntity.results[i].result.entity.description.value : '-',
          colorConnectionStatus: this.checkConnectionStatus( this.takEntity.results[i].result.entity.connectionStatus),
          connectionStatus:  this.takEntity.results[i].result.entity.connectionStatus === undefined ? 'Durum Bilgisi Yok' :
            (this.connectionStatusMap.has( this.takEntity.results[i].result.entity.connectionStatus.value) ?
              this.connectionStatusMap.get( this.takEntity.results[i].result.entity.connectionStatus.value) : 'Durum Bilgisi Yok'),
        }));
    this.paginatedJemusTaks = this.jemusTaks.slice(0, this.jemusTaks.length);
  }

  private setPaginatedJemusTaks(pageSize = PAGINATION_DEFAULT_PAGE_SIZE, page = 1) {
    this.pageSize = pageSize;
    this.mgubPage = page;
    this.paginatedJemusTaks = this.jemusTaks.slice(this.pageSize * (this.mgubPage - 1), this.pageSize * this.mgubPage);
  }

  public onTabChange(event) {
    this.activeTab = event.tabId;
  }
  checkConnectionStatus(connectionStatus) {
    if (connectionStatus === undefined || connectionStatus.value === null || connectionStatus.value !== 9) {
      return 'red';
    } else {
      return 'green';
    }
  }
}
