import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogComponent} from './base-dialog.component';

@Component({
  selector: 'closeable-dialog',
  templateUrl: './closeable-dialog.component.html',
  styleUrls: ['./closeable-dialog.component.scss']
})
export class CloseableDialogComponent extends BaseDialogComponent implements OnInit, OnDestroy {

  @Input() cardClass;
  @Input() displayFooter = true;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
