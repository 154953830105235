import {QueryType} from './query-type';

export enum NGSIGeoRelation {
  NEAR = 'near',
  WITHIN = 'within',
  CONTAINS = 'contains',
  OVERLAPS = 'overlaps',
  INTERSECTS = 'intersects',
  EQUALS = 'equals',
  DISJOINT = 'disjoint',
  MAX_DISTANCE = 'maxDistance',
}

export enum Geometry {
  POLYGON = 'Polygon',
  RECTANGLE = 'Rectangle',
  CIRCLE = 'Circle',
  POINT = 'Point',
  LINE_STRING = 'LineString',
}

export enum NGSITemporalRelation {
  BEFORE = 'before',
  AFTER = 'after',
  BETWEEN = 'between',
}

export enum TimeIntervalUnit {
  HOUR = 'h',
  DAY = 'd',
  MONTH = 'M',
  YEAR = 'y'
}

export enum OperatorType {
  /*
  Scalar operators - UDF
   */
  DIV = 'div',
  ADD = 'add',
  /*
  Aggregation operators - UDAF
   */
  SUM = 'sum',
  COUNT = 'count',
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  GREATEST = 'greatest',
  LEAST = 'least',
  /*
  Group by operators - UDBF
   */
  GROUP_BY = 'groupBy',
  TIME_INTERVAL = 'timeInterval',
  SEASONAL = 'seasonal',
  GEOHASH = 'geohash',
  /*
  UDBFF
  */
  MAX_N = 'maxN',
}

export enum SeasonalOperatorParam {
  DAY_OF_WEEK = 'DOW',
  MONTH_OF_YEAR = 'M',
  QUARTER = 'Q',
  HOUR_OF_DAY = 'h',
  WEEKEND_OR_WEEKDAY = 'WD'
}

export enum NGSIOperator {
  EQUAL = '==',
  UNEQUAL = '!=',
  GREATER = '>',
  GREATER_OR_EQUAL_TO = '>=',
  LESS = '<',
  LESS_OR_EQUAL_TO = '<=',
}

export enum RangeOperator {
  IN_CLOSED_RANGE = '...',
  IN_OPEN_RANGE = '..<'
}

export enum NGSISeparator {
  VALUE = ',',
  QUERY = ';'
}

export enum NGSIQueryBlock {
  ID = 'id',
  TYPE = 'type',
  ATTRS = 'attrs',
  QUERY = 'q',
  LAST_N = 'lastN',
  OPTIONS = 'options',
  KEY_VALUES = 'keyValues',
  SYS_ATTRS = 'sysAttrs',
  BUCKETS = 'buckets',
  PIPELINE = 'pipeline',
  TIME_REL = 'timerel',
  TIME = 'time',
  END_TIME = 'endTime',
  ID_PATTERN = 'idPattern'
}

export enum NGSIOperatorCategory {
  UDBF = 'UDBF',
  UDBFF = 'UDBFF',
  UDF = 'UDF',
  UDAF = 'UDAF',
  UDWF = 'UDWF'
}

export enum OperatorParameterExpressionType {
  CONST = 'const',
  PATH = 'path',
  EXPR = 'expr'
}

/**
 * Utilities on enumerations
 */
export class NgsiQueryUtil {

  /**
   * Gets all possible metric operators
   */
  static getMetricOperators(): OperatorType[] {
    return [
      OperatorType.SUM,
      OperatorType.COUNT,
      OperatorType.AVG,
      OperatorType.MIN,
      OperatorType.MAX,
      OperatorType.GREATEST,
      OperatorType.LEAST,
    ];
  }

  /**
   * Gets possible time interval values
   */
  static getTimeIntervals(): string[] {
    return Object.values(TimeIntervalUnit);
  }

  /**
   * Gets possible season values
   */
  static getSeasons(): string[] {
    return Object.values(SeasonalOperatorParam);
  }

  /**
   * Gets a list of {@link OperatorType}s that are applicable for the given KPI query type.
   * @param queryType
   */
  static getOperators(queryType: QueryType): OperatorType[] {
    if (queryType === QueryType.AGGREGATION || queryType === QueryType.CROSS_AGGREGATION) {
      return [OperatorType.GROUP_BY, OperatorType.GEOHASH];
    } else if (queryType === QueryType.TEMPORAL_AGGREGATION || queryType === QueryType.CROSS_TEMPORAL_AGGREGATION) {
      return [OperatorType.GROUP_BY, OperatorType.TIME_INTERVAL, OperatorType.SEASONAL, OperatorType.GEOHASH];
    }
    return [];
  }
}
