import {Component, Injector, Input, OnInit} from '@angular/core';
import {ChartComponent} from '../chart.component';
import {NGSIResult} from '../../../models/ngsi/ngsi-result';
import {ChartDataFormat} from '../../../enums/chart-data-format';
import {ColorUtil} from '../../../utils/color-util';
import {AxisSettings} from '../../../models/visualization/axis-settings.model';
import {DataSeries} from '../../../models/visualization/data-series.model';
import {DataFormatUtil} from '../../../utils/data-format-util';

@Component({
  selector: 'uruk-gauge',
  templateUrl: '../chart.component.html',
  styleUrls: ['../chart.component.scss']
})
export class GaugeComponent extends ChartComponent implements OnInit {

  // Radius of the gauge
  @Input() radius: string | string[] = '90%';

  // responsiveness parameters
  private basePointerTick = 3; // minimum possible thickness for the pointer
  private dimensionScaleFactor = 20; // a scale factor used to determine the gauge width
  private gaugeDetailFontScaleFactor = 1.4; // a scale factor to determine the responsive font size for the value label
  private pointerScaleFactor = 250; // a scale factor to determine pointer thickness in every X pixels

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  exportCSV() {
    if (this.data && this.data.length > 0) {
      const data = this.data[0];

      const csvData = data.name + '\n' + data.value + '\n';

      // download csv file
      // add a BOM as first characters in the file to force Excel to use UTF-8
      this.downloadFile('data:text/csv;charset=utf-8,%EF%BB%BF' + csvData, this.panelTitle + '.csv');
    }
  }

  /**
   * Returns the default options that won't change based on the chart container dimensions
   */
  defaultChartOptions() {
    const defaultOptions: any = {
      series: [{
        type: 'gauge',
        name: this.seriesName || this.defaultSeriesName, // KPI Name
        radius: this.radius,
        axisLine: {
          lineStyle: {
            color: [ // TODO handle chart-specific configurations while adding a panel
              [0.3, ColorUtil.gaugeChartPalette[0]],
              [0.7, ColorUtil.gaugeChartPalette[1]],
              [1, ColorUtil.gaugeChartPalette[0]]
            ]
          }
        },
        axisTick: {
          lineStyle: {
            color: '#fff',
            width: 1
          }
        },
        axisLabel: {
          color: 'auto'
        },
        splitLine: {
          lineStyle: {
            color: '#fff',
            width: 2
          }
        },
        pointer: { // Gauge chart pointer.
          itemStyle: {
            color: 'auto'
          },
        },
        title: {
          show: false
        },
        detail: { // The detail about gauge chart which is used to show data.
          valueAnimation: true,
         // formatter: '{value}',
          formatter: this.labelFormatter.bind(this),
          color: 'auto'
        }
      }]
    };

    // set the data if the chart is initialized with some data
    if (this.data) {
      defaultOptions.series[0].data = this.data;
      if (this.colors) {
        defaultOptions.color = this.colors;
      }
    }

    return defaultOptions;
  }

  /**
   * Returns the chart options with responsive parameters
   */
  responsiveChartOptions() {
    const viewportMin = Math.min(this.echartsContainer.nativeElement.offsetWidth, this.echartsContainer.nativeElement.offsetHeight);
    const responsiveGaugeBarWidth = viewportMin / this.dimensionScaleFactor;
    const responsiveFontSize = 0.05525 * viewportMin - 0.4270; // calculated with linear regression of the best values for specific viewportMins

    return {
      series: [{
        axisLine: {
          lineStyle: {
            width: responsiveGaugeBarWidth // The width of axis line/bar
          }
        },
        axisTick: {
          distance: -responsiveGaugeBarWidth, // The distance between the tick line and axis line.
          length: responsiveGaugeBarWidth / 2, // The length of tick line, can be a percentage value relative to radius.
        },
        axisLabel: {
          distance: responsiveGaugeBarWidth + responsiveFontSize / 2, // The distance between the label and tick line.
          fontSize: responsiveFontSize,
        },
        splitLine: {
          distance: -responsiveGaugeBarWidth, // The distance between the split line and axis line.
          length: responsiveGaugeBarWidth, // The length of split line, can be a pecentage value relative to radius.
        },
        pointer: {
          width: this.basePointerTick + viewportMin / this.pointerScaleFactor
        },
        detail: {
          fontSize: responsiveFontSize * this.gaugeDetailFontScaleFactor,
        }
      }
      ]
    };
  }

  labelFormatter(value) {
    let label = '';

    if (this.chartSettings.seriesSettings.length > 0) {
      const chartSeries = this.chartSettings.seriesSettings[0];
      const kpiSeries = this.kpi.series[chartSeries.index];

      // set value & unit
      const unit = kpiSeries.unit || '';

      // set label
      label += `${value} ${unit}`;
    }

    return label;
  }

  setData(data: NGSIResult[]) {
    [this.rawData, this.colors] = this.dataExtractionUtil.extractData(this.kpi, this.chartSettings, data);

    if (this.rawData.length > 0) {
      const seriesData = this.rawData[0];
      if (seriesData.data.length > 0 && this.chartSettings.seriesSettings.length > 0) {
        const chartSeries: AxisSettings = this.chartSettings.seriesSettings[0];
        const kpiSeries: DataSeries = this.kpi.series[chartSeries.index];

        const formatted = DataFormatUtil.formatValue(seriesData.data[0], kpiSeries.format, chartSeries.precision, chartSeries.thousandSeparator, false);
        const value = kpiSeries.format === ChartDataFormat.INTEGER ? parseInt(formatted) : parseFloat(formatted);
        const label = chartSeries.label;

        this.data = [{
          name: label,
          value: value
        }];

        // update chart data
        this.setSeriesData(this.data);
      }
    }
  }
}
