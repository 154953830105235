<!-- Checkbox to select/deselect all -->
<nb-checkbox (checkedChange)="selectAll($event)" [(ngModel)]="allOptionsSelected">
    {{'Select all'|translate}}</nb-checkbox>
<hr>
<!-- A group of checkboxes -->
<div class="checkbox-group">
    <ng-container *ngFor="let option of options;let i=index">
        <nb-checkbox (checkedChange)="toggle(option,$event)" [checked]="selectedOptions.has(option)">
            {{nameFormatter(option)}}</nb-checkbox>
    </ng-container>
</div>