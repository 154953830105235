export class StorageUtil {
  private static KEY_STATE = 'state';
  private static KEY_NONCE = 'nonce';
  private static KEY_ID_TOKEN = 'id_token';
  private static KEY_UMA_TICKET = 'uma_token'; // includes all permissions granted by the server unlike id_token
  private static REFRESH_TOKEN = 'refresh_token';
  private static PRELOADER_DISPLAYED = 'preloader_displayed';
  private static LANGUAGE = 'language';
  private static CONNECTION0_ICON = 'connectionIcon';
  private static CONNECTION1_ICON = 'connection1Icon';
  private static CONNECTION2_ICON = 'connection2Icon';
  private static CONNECTIONLOST_ICON = 'connectionLostIcon';
  private static DATASTREAMING_ICON = 'dataStreamingIcon';

  public static getJWT(): string {
    return localStorage.getItem(this.KEY_ID_TOKEN);
  }

  public static setJWT(data: string) {
    localStorage.setItem(this.KEY_ID_TOKEN, data);
  }

  public static getUMATicket(): string {
    return localStorage.getItem(this.KEY_UMA_TICKET);
  }

  public static setUMATicket(data: string) {
    localStorage.setItem(this.KEY_UMA_TICKET, data);
  }

  public static removeUMATicket() {
    localStorage.removeItem(this.KEY_UMA_TICKET);
  }

  public static getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  public static setRefreshToken(data: string) {
    localStorage.setItem(this.REFRESH_TOKEN, data);
  }

  public static removeRefreshToken() {
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  public static removeJWT() {
    localStorage.removeItem(this.KEY_ID_TOKEN);
  }


  public static getLanguage(): string {
    return sessionStorage.getItem(this.LANGUAGE);
  }

  public static setLanguage(data: string) {
    sessionStorage.setItem(this.LANGUAGE, data);
  }
  public static getConnection0Icon(): string {
    return sessionStorage.getItem(this.CONNECTION0_ICON);
  }

  public static setConnection0Icon(svgContent: string) {
    sessionStorage.setItem(this.CONNECTION0_ICON, svgContent);
  }

  public static getConnection1Icon(): string {
    return sessionStorage.getItem(this.CONNECTION1_ICON);
  }

  public static setConnection1Icon(svgContent: string) {
    sessionStorage.setItem(this.CONNECTION1_ICON, svgContent);
  }


  public static getConnection2Icon(): string {
    return sessionStorage.getItem(this.CONNECTION2_ICON);
  }

  public static setConnection2Icon(svgContent: string) {
    sessionStorage.setItem(this.CONNECTION2_ICON, svgContent);
  }

  public static getDataStreamingIcon(): string {
    return sessionStorage.getItem(this.DATASTREAMING_ICON);
  }

  public static setDataStreamingIcon(svgContent: string) {
    sessionStorage.setItem(this.DATASTREAMING_ICON, svgContent);
  }

  public static getConnectionLostIcon(): string {
    return sessionStorage.getItem(this.CONNECTIONLOST_ICON);
  }

  public static setConnectionLostIcon(svgContent: string) {
    sessionStorage.setItem(this.CONNECTIONLOST_ICON, svgContent);
  }

  public static getState(): string {
    return sessionStorage.getItem(this.KEY_STATE);
  }

  public static setState(data: string) {
    sessionStorage.setItem(this.KEY_STATE, data);
  }

  public static removeState() {
    sessionStorage.removeItem(this.KEY_STATE);
  }


  public static getNonce(): string {
    return sessionStorage.getItem(this.KEY_NONCE);
  }

  public static setNonce(data: string) {
    sessionStorage.setItem(this.KEY_NONCE, data);
  }

  public static removeNonce() {
    sessionStorage.removeItem(this.KEY_NONCE);
  }

  public static getPreloaderDisplayed() {
    return localStorage.getItem(this.PRELOADER_DISPLAYED);
  }

  public static setPreloaderDisplayed(data: string) {
    localStorage.setItem(this.PRELOADER_DISPLAYED, data);
  }

}
