<mgl-map
  [style]="mapLibreSettings.styleUrl"
  [center]="mapSettings ? [mapSettings.point.coordinates[1], mapSettings.point.coordinates[0]] : [mapLibreSettings.center.lng, mapLibreSettings.center.lat]"
  [zoom]="[mapSettings?.zoomLevel ? this.mapSettings.zoomLevel : mapLibreSettings.defaultZoom]"
  [maxZoom]="mapLibreSettings.maxZoom"
  [minZoom]="mapLibreSettings.minZoom"
  [maxBounds]="[ [generalMapSettings.bounds[0], generalMapSettings.bounds[1]], [generalMapSettings.bounds[2], generalMapSettings.bounds[3]] ]"
  (mapLoad)="onMapReady($event)"
  (moveEnd)="onMapMoveEnd($event)"
  (mapClick)="onMapClick($event)"
  (zoomEnd)="onZoomChange($event)"
  [transformRequest]="transformRequest">

  <ng-container *ngFor="let layer of layers">
    <ng-container *ngIf="layer.visibleOnMap">

      <ng-container *ngFor="let marker of markers.get(layer)">
        <!-- markers -->
        <mgl-marker *ngIf="marker.visible" [lngLat]="marker.coordinates" [anchor]="'bottom'" >
          <div (click)="onMarkerClicked(marker)" class="clickable"
               (mouseenter)="setTooltipVisibility(marker, layer.displaysTooltip, true)"
               (mouseleave)="setTooltipVisibility(marker, layer.displaysTooltip,false); layer.toolTipClosed(marker)">
            <ng-container [ngSwitch]="marker.style ? 'true' : 'false'">
              <div *ngSwitchCase="'true'" style="{{marker.style}}"></div>
              <div *ngSwitchCase="'false'" style= "{{marker.htmlStyle}}" [innerHTML]="marker.html"></div>
            </ng-container>
          </div>
          <!-- Marker tooltip -->
          <div *ngIf="marker.displayTooltip" class="icon-tooltip" [innerHTML]="layer.createTooltip(marker)"></div>
        </mgl-marker>
      </ng-container>

      <!-- line layer -->
      <!-- Handled in the controller -->
    </ng-container>
  </ng-container>
</mgl-map>
