import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from './core/guards/auth.guard';
import {NoAuthGuard} from './core/guards/no-auth.guard';
import {AdminGuard} from './core/guards/admin.guard';
import {environment} from '../environments/environment';
import {AuthorizationScope} from './shared/enums/authorization-scope.enum';
import {AuthScopeGuard, AuthScopeGuardStrategy} from './core/guards/auth-scope.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.routes.pages,
    pathMatch: 'full'
  },
  {
    path: environment.routes.pages,
    loadChildren: () => import('app/modules/page/page.module')
      .then(m => m.PageModule),
    canActivate: environment.auth.enabled ? [AuthGuard] : [NoAuthGuard]
  },
  {
    path: environment.routes.platform.platform,
    loadChildren: () => import('app/modules/admin/admin.module')
      .then(m => m.AdminModule),
    canActivate: environment.auth.enabled ? [AuthGuard, AdminGuard] : [NoAuthGuard]
  },
  {
    path: environment.routes.alerts,
    loadChildren: () => import('app/modules/alert/alert.module')
      .then(m => m.AlertModule),
    canActivate: environment.auth.enabled ? [AuthGuard, AuthScopeGuard] : [NoAuthGuard],
    data: {strategy: AuthScopeGuardStrategy.AFFIRMATIVE , policies: [
      {
        resourceId: 'AlertManagement', 
        permission: AuthorizationScope.DOMAIN_MANAGE
      },
      {
        resourceId: 'AlertManagement', 
        permission: AuthorizationScope.DOMAIN_READ
      }
    ]}
  },
  {
    path: environment.routes.analytics,
    loadChildren: () => import('app/modules/analytics/analytics.module')
      .then(m => m.AnalyticsModule),
    canActivate: environment.auth.enabled ? [AuthGuard] : [NoAuthGuard]
  },
  {
    path: environment.routes.reports,
    loadChildren: () => import('app/modules/reporting/reporting.module')
      .then(m => m.ReportingModule),
    canActivate: environment.auth.enabled ? [AuthGuard] : [NoAuthGuard]
  },
  {
    path: environment.auth.redirect_endpoint,
    loadChildren: () => import('app/modules/auth/auth.module')
      .then(m => m.AuthModule)
  },
  {
    path: environment.routes.workflow,
    loadChildren: () => import('app/modules/workflow/workflow.module')
      .then(m => m.WorkflowModule),
    canActivate: environment.auth.enabled ? [AuthGuard, AdminGuard] : [NoAuthGuard]
  },
  {path: '**', redirectTo: environment.routes.pages},
];

const config: ExtraOptions = {
  useHash: false,
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
