import {BaseMetaModel} from './base/base-meta-model.model';
import {GeoJsonFactory} from './factories/geo-json.factory';
/**
 * Indicates a geographic region
 */
export class DefibrillatorTest extends BaseMetaModel {

  IOTModuleBatteryVoltageLevel: String;
  AEDDeviceBatteryVoltageLevel: String;
  testDay: String;
  testMonth: String;
  testYear: String;
  testHour: String;
  testMinute: String;
  testType: String;
  adsTestResult: Number;
  ecgTestResult: Number;
  cryptoTestResult: Number;
  audioTestResult: Number;
  padTestResult: Number;
  chargeCircuit1TestResult: Number;
  chargeCircuit2TestResult: Number;
  capacitorVoltageTestResult: Number;
  hBridgeCircuit1TestResult: Number;
  hBridgeCircuit2TestResult: Number;
  onOffButtonTestResult: Number;
  shockButtonTestResult: Number;
  colorIOTModuleBatteryVoltageLevel: String;
  colorAEDDeviceBatteryVoltageLevel: String;
  colorADSTestResult: String;
  colorECGTestResult: String;
  iotGsmConnectionType: Number;
  iotAccelerometerTestResult: Number;
  iotInternalMemoryTestResult: Number;
  iotGsmSignalQualityLte: Number;
  iotAedConnectionTestResult: Number;
  iotGsmSignalQuality2G: Number;
  testResult: Number;
  colorTestResult: String;
  ads4v6Result : number;
  dacSetVoltage: Number;
  chargingVoltage: Number;
  chargeTime: number;
  hBridge1: Number;
  hBridge2: Number;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.IOTModuleBatteryVoltageLevel = data.IOTModuleBatteryVoltageLevel;
    this.AEDDeviceBatteryVoltageLevel = data.AEDDeviceBatteryVoltageLevel;
    this.testDay = data.testDay < 10 ? '0' + data.testDay : data.testDay;
    this.testMonth = data.testMonth < 10 ? '0' + data.testMonth : data.testMonth;
    this.testYear = data.testYear;
    this.testHour = data.testHour < 10 ? '0' + data.testHour : data.testHour;
    this.testMinute = data.testMinute < 10 ? '0' + data.testMinute : data.testMinute;
    this.testType = data.testType;
    this.adsTestResult = data.adsTestResult;
    this.ecgTestResult = data.ecgTestResult;
    this.cryptoTestResult = data.cryptoTestResult;
    this.audioTestResult = data.audioTestResult;
    this.padTestResult = data.padTestResult;
    this.chargeCircuit1TestResult = data.chargeCircuit1TestResult;
    this.chargeCircuit2TestResult = data.chargeCircuit2TestResult;
    this.capacitorVoltageTestResult = data.capacitorVoltageTestResult;
    this.hBridgeCircuit1TestResult = data.hBridgeCircuit1TestResult;
    this.hBridgeCircuit2TestResult = data.hBridgeCircuit2TestResult;
    this.onOffButtonTestResult = data.onOffButtonTestResult;
    this.shockButtonTestResult = data.shockButtonTestResult;
    this.colorAEDDeviceBatteryVoltageLevel = data.colorAEDDeviceBatteryVoltageLevel;
    this.colorIOTModuleBatteryVoltageLevel = data.colorIOTModuleBatteryVoltageLevel;
    this.colorADSTestResult = data.colorADSTestResult;
    this.colorECGTestResult = data.colorECGTestResult;
    this.iotGsmConnectionType = data.iotGsmConnectionType;
    this.iotAccelerometerTestResult = data.iotAccelerometerTestResult;
    this.iotInternalMemoryTestResult = data.iotInternalMemoryTestResult;
    this.iotGsmSignalQualityLte = data.iotGsmSignalQualityLte;
    this.iotAedConnectionTestResult = data.iotAedConnectionTestResult;
    this.iotGsmSignalQuality2G = data.iotGsmSignalQuality2G;
    this.testResult = data.testResult;
    this.colorTestResult = data.colorTestResult;
    this.ads4v6Result = data.ads4v6Result / 50;
    this.dacSetVoltage = data.dacSetVoltage;
    this.chargingVoltage = data.chargingVoltage;
    this.chargeTime = data.chargeTime / 10;
    this.hBridge1 = data.hBridge1;
    this.hBridge2 = data.hBridge2;
  }
}
