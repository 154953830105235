import {NGSIFilter} from './ngsi-filter.model';
import {BaseQuery} from './base-query.model';
import {QueryOperatorExpr} from '../query/query-operator-expr.model';
import {QueryOperatorExpressionFactory} from '../factories/query-operator-expression.factory';
import {Persistable} from '../persistanble';

/**
 * Base NGSI query that includes a set of query filters along with pagination attributes
 */
export class NGSIQuery extends BaseQuery implements Persistable {
  /**
   * Base NGSI filters
   */
  filter: NGSIFilter;

  /**
   * Attributes (properties or relationships) to be included in the response
   */
  attrs?: QueryOperatorExpr[];

  /**
   * Last N of the results satisfying the given filters in temporal queries
   */
  lastN?: number;

  /**
   * Maximum number of results to be fetched
   */
  limit?: number;

  /**
   * Offset for result list to consider result items included in the response
   */
  skip?: number;

  /**
   * Sorting field
   */
  sortField?: string;

  /**
   * Direction in which results are sorted 
   */
  sortDirection?: string;

  /**
   * Parameter to filter attribute values which are observed in last N days.
   * If it is "null", no time filtering for the attribute values
   */
  lastNDays?:number = 7;

  constructor(data?: any) {
    super(data);
    this.jsonClass = 'NGSIQuery';

    if (!data) {
      return;
    }

    this.filter = new NGSIFilter(data.filter);
    if (data.attrs) {
      this.attrs = data.attrs.map(attr => QueryOperatorExpressionFactory.create(attr));
    }
    this.lastN = data.lastN;
    this.limit = data.limit;
    this.skip = data.skip;
    this.sortField = data.sortField;
    this.sortDirection = data.sortDirection;
    if(data.lastNDays !== undefined){
      this.lastNDays = data.lastNDays;
    }
  }

  createNGSIParameters(): any {
    const parameters: any = {};
    Object.assign(parameters, this.filter.createNGSIParameters());

    if (this.attrs?.length > 0) {
      parameters['attrs'] = this.attrs.map(attr => attr.createQuerySerialization()).join(',');
    }
    if (this.limit) {
      parameters['limit'] = this.limit;
    }
    if (this.sortField) {
      parameters['sortField'] = this.sortField;
    }
    if (this.sortDirection) {
      parameters['sortDirection'] = this.sortDirection;
    }
    if(this.lastNDays){
      parameters['lastNDays'] = this.lastNDays;
    }
    return parameters;
  }

  createPersistableObject(): any {
    const {filter, ...rest} = this;
    rest['filter'] = filter.createPersistableObject();
    return rest;
  }
}
