import {Point} from '../geometry/point.model';

/**
 * Defines the default map settings for pages.
 */
export class MapSettings {

  /**
   * Zoom level
   */
  zoomLevel: number;

  /**
   * The default location of map
   */
  point: Point;

  constructor(data?) {
    if (!data) {
      return;
    }

    this.zoomLevel = data.zoomLevel;
    if (data.point) {
      this.point = new Point(data.point);
    }
  }
}
