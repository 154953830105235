import {TranslateService} from '@ngx-translate/core';

/**
 * Keeps locale id of application.
 * */
export class LocaleId extends String {

  constructor(private service: TranslateService) {
    super();
  }

  /**
   * Returns the current language of application.
   * @return the current language of application. It returns the default language if there is no currently used language.
   * */
  toString() {
    // current language used by translate service
    let localeId = this.service.currentLang;
    // if it is not available, return the default language
    if (!localeId) {
      localeId = this.service.defaultLang;
    }
    return localeId;
  }
}
