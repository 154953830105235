import {Component, Injector, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {LayerService} from '../../../core/services/meta/layer.service';
import {BaseFormComponent} from '../../../shared/components/form/base-form.component';

@Component({
  templateUrl: './add-layer-dialog.component.html',
  styleUrls: ['./add-layer-dialog.component.scss']
})
export class AddLayerDialogComponent extends BaseFormComponent implements OnInit {

  // the list of layers which are already selected
  @Input() layerIds: string[];
  // the list of domains for layers
  @Input() domains: string[];

  // selected layers
  selectedLayerIds: string[];

  public layerService: LayerService;

  constructor(protected injector: Injector,
              private dialogRef: NbDialogRef<AddLayerDialogComponent>) {
    super(injector);
    this.layerService = injector.get(LayerService);
  }

  ngOnInit() {
    // initialize selected layer ids
    this.selectedLayerIds = [].concat(this.layerIds);
  }

  /**
   * Sets the selected layers
   * @param layerIds the list of selected layer ids
   * */
  public setSelectedLayerIds(layerIds: string[]) {
    this.selectedLayerIds = layerIds;
  }

  /**
   * Saves the selection and closes the dialog.
   * */
  public save() {
    if (this.selectedLayerIds.length) {
      this.dialogRef.close(this.selectedLayerIds);
    } else {
      this.displayErrors = true;
    }
  }
}
