import {LayerController} from './layer.controller';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {Observable} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIEntity} from '../../shared/utils/ngsi-result-util';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {Marker} from '../../shared/models/map/marker.model';

export class ParkingLayerController extends LayerController {

  zoomSensitive: boolean;
  deeperLevel: boolean;
  deeperLevelZoomTreshold = 12;
  displaysTooltip = true;

  constructor(layer: Layer, page: Page, injector: Injector) {
    super(layer, page, injector);

    this.layoutService.onMapZoomChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe(zoomLevel => {

        if (!this.zoomSensitive) {
          return ;
        }

        if (this.deeperLevel !== undefined && this.deeperLevel !== null) {

          // detect changes
          if (this.deeperLevel === true && zoomLevel <= this.deeperLevelZoomTreshold) {
            this.deeperLevel = false;
            this.getOnStreetParking();
          } else if (this.deeperLevel === false && zoomLevel > this.deeperLevelZoomTreshold) {
            this.deeperLevel = true;
            this.getOnStreetParking();
          }
        } else {
          this.deeperLevel = zoomLevel > this.deeperLevelZoomTreshold ? true : false;
        }
      });
  }

  /**
   * Collects the values to be provided to the dialog to be opened on entity click
   * @param result
   * @private
   */
  getDialogContext(result: NGSIResult): any {
    const contextObservable: Observable<any> = this.getMarkerEntity(result.getEntityId(), false).pipe(
      take(1),
      map(entity => {
        return {
          title: this.getPropertyValue(entity, 'name', false),
          entity: entity.result,
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }

  getOnStreetParking() {
    // create an NGSI query for on street parking entities
    const query: NGSIQuery = new NGSIQuery({
      filter: {
        type: [NGSIEntity.ON_STREET_PARKING]
      },
      limit: 1000
    });

    // retrieve corresponding entities & process
    this.cbrokerService.getEntities(query, new QueryOptions(false, false, true)).subscribe(entityResponse => {
      if (this.layer.representations && this.layer.representations.length > 0) {
        this.processData(entityResponse.results, this.layer.representations[0]);
      }
    });
  }

  createTooltip(marker: Marker): string {
    const name = marker.entity.getSingleElementValue('name');
    const availableSpotNumber = marker.entity.getSingleElementValue('availableSpotNumber');
    const totalSpotNumber = marker.entity.getSingleElementValue('totalSpotNumber');

    const availableSpotsLabel = this.translateService.instant('Available Spots');
    const capacityLabel = this.translateService.instant('Capacity');

    let html = '<div class="entity-tooltip">';

    html += `<div class="text">${name}</div>`;
    html += `<div class="text"><span class="title">${availableSpotsLabel}: </span>${availableSpotNumber}</div>`;
    html += `<div class="text"><span class="title">${capacityLabel}: </span>${totalSpotNumber}</div>`;

    html += '</div';

    return html;
  }
}
