import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageUtil} from '../../shared/utils/storage-util';
import {BEARER_TOKEN_PREFIX, Headers} from '../../shared/utils/rest-util';

/**
 * Intercepts all HTTP messages to inject {@link Headers.AUTHORIZATION} header to requests.
 */
@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get UMA token
    const token = StorageUtil.getUMATicket();
    // the request might have a Authorization header, for example, the request retrieving UMA token has its own token
    // do not add Authorization header in such cases
    if (token && !req.headers.has(Headers.AUTHORIZATION)) {
      req = req.clone({
        headers: req.headers.set(Headers.AUTHORIZATION, `${BEARER_TOKEN_PREFIX} ${token}`)
      });
    }

    return next.handle(req);
  }
}
