import {NGSIOperatorParameter} from './ngsi-operator-parameter.model';
import {NGSIOperatorCategory} from '../../../enums/ngsi-query.enum';

export class NGSIOperator {
  name: string;
  description: string;
  parameters: NGSIOperatorParameter[];
  category: NGSIOperatorCategory;
  outputType?: string;
  timeDependent = false;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.name = data.name;
    this.description = data.description;
    this.category = data.category;
    this.parameters = data.parameters.map(parameter => new NGSIOperatorParameter(parameter));
    this.timeDependent = data.timeDependent;
  }
}

export const bucketOperators = [
  new NGSIOperator({
    name: 'groupBy',
    parameters: [
      {
        name: 'Kırılım Parametresi',
        unlimited: true,
        type: 'Text',
        allowedExprs: ['path']
      }
    ],
    category: NGSIOperatorCategory.UDBF
  }),
  new NGSIOperator({
    name: 'timeInterval',
    parameters: [
      {
        type: 'Text',
        allowedExprs: ['const'],
        default: '1h',
        valueSet: ['1h', '1d', '1M', '1y']
      }
    ],
    category: NGSIOperatorCategory.UDBF
  }),
  new NGSIOperator({
    name: 'seasonal',
    parameters: [
      {
        type: 'Text',
        allowedExprs: ['const'],
        default: 'DOW',
        valueSet: ['DOW', 'M', 'Q', 'h', 'WD']
      }
    ],
    category: NGSIOperatorCategory.UDBF
  }),
  new NGSIOperator({
    name: 'geohash',
    parameters: [
      {
        type: 'Text',
        allowedExprs: ['path']
      },
      {
        type: 'Number',
        allowedExprs: ['const'],
        default: 5,
        valueSet: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      }
    ],
    category: NGSIOperatorCategory.UDBF
  })
];

export const metricOperators = [
  new NGSIOperator({
    name: 'avg',
    parameters: [
      {
        type: 'Number',
        allowedExprs: ['expr', 'path']
      }
    ],
    category: NGSIOperatorCategory.UDAF
  }),
  new NGSIOperator({
    name: 'count',
    parameters: [
      {
        type: 'Number',
        allowedExprs: ['path']
      }
    ],
    category: NGSIOperatorCategory.UDAF
  }),
  new NGSIOperator({
    name: 'max',
    parameters: [
      {
        type: 'Number',
        allowedExprs: ['expr', 'path']
      }
    ],
    category: NGSIOperatorCategory.UDAF
  }),
  new NGSIOperator({
    name: 'min',
    parameters: [
      {
        type: 'Number',
        allowedExprs: ['expr', 'path']
      }
    ],
    category: NGSIOperatorCategory.UDAF
  }),
  new NGSIOperator({
    name: 'sum',
    parameters: [
      {
        type: 'Number',
        allowedExprs: ['expr', 'path', 'const'],
        unlimited: true
      }
    ],
    category: NGSIOperatorCategory.UDAF
  })
];

export const scalarOperators = [
  new NGSIOperator({
    name: 'add',
    parameters: [
      {
        type: 'Number',
        allowedExprs: ['expr', 'path', 'const'],
        unlimited: true
      }
    ],
    category: NGSIOperatorCategory.UDF
  }),
  new NGSIOperator({
    name: 'div',
    parameters: [
      {
        type: 'Number',
        allowedExprs: ['path', 'expr', 'const']
      },
      {
        type: 'Number',
        allowedExprs: ['path', 'expr', 'const']
      }
    ],
    category: NGSIOperatorCategory.UDF
  })
];
