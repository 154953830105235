import {LayerController} from './layer.controller';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {catchError, forkJoin, Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {Marker} from '../../shared/models/map/marker.model';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {EntityRepresentation} from '../../shared/models/visualization/representation.model';
import {Notification} from '../../shared/models/notification.model';
import {NGSIResultUtil} from '../../shared/utils/ngsi-result-util';

export class DroneLayerController extends  LayerController {
  displaysTooltip: boolean = true;
  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }
  onDestroy(): void {
    super.onDestroy();
  }

  protected handleCreateEvent(representation: EntityRepresentation, notification: Notification): NGSIResult {
    const newEntityVehicleRef: string = NGSIResultUtil.extractEntityAttributeValue(notification.eventPayload.content, 'refId', new QueryOptions());
    const representationEntities = this.entities.get(representation);
    const entityIndex = representationEntities ? representationEntities.findIndex(e => {
      return this.getDroneRefFromNGSIResult(e) === newEntityVehicleRef;
    }) : -1;
    if (entityIndex !== -1) {
      this.entities.get(representation).splice(entityIndex, 1);
      this.markers.get(representation).splice(entityIndex, 1);
    }
    return super.handleCreateEvent(representation, notification);
  }

  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    const entitiesFound: Set<string> = new Set();
    const finalResults: NGSIResult[] = [];
    results.forEach(result => {
      const trainRef: string = this.getDroneRefFromNGSIResult(result);
      if (!entitiesFound.has(trainRef)) {
        finalResults.push(result);
        entitiesFound.add(trainRef);
      }
    })
    return finalResults;
  }

  private getDroneRefFromNGSIResult(ngsiResult: NGSIResult): string {
    return ngsiResult.getEntityResult().getSingleElementValue('refId');
  }


  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    query.filter.id = [result.getEntityResult().getSingleElementValue('refId')];
    const droneRefQuery = this.cbrokerService.getEntity(query, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const droneQuery = this.getMarkerEntity(result.getEntityId(),false);
    const contextObservable: Observable<any> = forkJoin([droneRefQuery, droneQuery]).pipe(
      take(1),
      map(response => {
        return {
          entity: response[0]?.getEntityResult(),
          positionEntity: response[1]?.getEntityResult()
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(),
      asyncContext: contextObservable
    };
  }


  createTooltip(marker: Marker): string {
    const id: string = marker.entity.getSingleElementValue('refId').split(':');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text"><span class="title">Drone ID: </span> ${id[3]} </div>`;
    html += '</div';

    return html;
  }
}
