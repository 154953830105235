import {QueryOperatorParam} from './query-operator-param.model';

export abstract class QueryOperatorExpr extends QueryOperatorParam {

  /**
   * If the root property or relationship is temporal or not (Only true for the temporal attributes of mixed entities)
   * */
  isTemporal = false;

  constructor(data?: any) {
    super(data);
    this.jsonClass = 'QueryOperatorExpr';

    if (!data) {
      return;
    }

    this.isTemporal = data.isTemporal;
  }

  /**
   * Returns whether this expression contains a temporal element
   */
  abstract containsTemporalElement(): boolean;

  /**
   * Returns the query serialization.
   * */
  abstract createQuerySerialization(): string;
}
