import {ChartDataFormat} from '../enums/chart-data-format';
import {TimeUtil} from './time-util';
import {DimensionSeriesData} from '../models/report/dimension-series-data.model';
import {FormUtil} from './form-util';
import {BaseDataType} from '../enums/base-data-type';

export class DataFormatUtil {

  public static formatReportData(data: DimensionSeriesData[]): void {
    data.forEach(columnData => {
      if (columnData.series) {
        for (let i = 0; i < columnData.data.length; i++) {
          columnData.data[i] = this.formatValue(columnData.data[i], columnData.getDataType(), columnData.chartAxisSetting.precision, columnData.chartAxisSetting.thousandSeparator);
        }
      }
    });
  }

  /**
   * Formats a given value based on the given chart settings
   */
  public static formatValue(value: any, format: ChartDataFormat, precision: number = 2, addThousandSeparators: boolean = true, shouldLanguageConsidered = true, lang = 'tr'): string {
    if (FormUtil.isEmptyValue(value)) {
      return '';
    }

    const decimalSeparator = shouldLanguageConsidered && lang === 'tr' ? ',' : '.';
    const thousandSeparator = shouldLanguageConsidered && lang === 'tr' ? '.' : ',';

    switch (format) {
      case ChartDataFormat.INTEGER:
        const rounded = Math.round(value as number).toString();
        if (addThousandSeparators) {
          return DataFormatUtil.addThousandSeparator(rounded, thousandSeparator);
        } else {
          return rounded;
        }
      case ChartDataFormat.DECIMAL:
      case ChartDataFormat.PERCENTAGE:

        let formatted;
        if (format === ChartDataFormat.DECIMAL) {
          formatted = (value as number).toFixed(precision);
        } else {
          const percentageValue = value as number;
          formatted = (percentageValue > 0 && percentageValue <= 1 ? percentageValue * 100 : percentageValue).toFixed(precision);
        }

        if (addThousandSeparators) {
          const split = formatted.split('.');

          let formattedValue = split[0].split('').reverse().reduce(function (acc, num, i, orig) {
            return num === '-' ? acc : num + (i && !(i % 3) ? thousandSeparator : '') + acc;
          }, '');
          // handle negative values
          if (split[0].startsWith('-')) {
            formattedValue = `-${formattedValue}`;
          }
          // handle decimal part if exists
          if (split.length === 2) {
            formattedValue += decimalSeparator + split[1];
          }
          return formattedValue;
        } else {
          return formatted.replaceAll('.', decimalSeparator);
        }
      case ChartDataFormat.DATETIME:
        return value as string;
      case ChartDataFormat.YEAR:
        return TimeUtil.serializeYear(TimeUtil.parseDatetime(value as string));
      case ChartDataFormat.MONTH:
        return TimeUtil.serializeMonth(TimeUtil.parseDatetime(value as string));
      case ChartDataFormat.DATE:
        return TimeUtil.serializeDay(TimeUtil.parseDatetime(value as string));
      case ChartDataFormat.HOUR:
        return TimeUtil.serializeShortTime(TimeUtil.parseDatetime(value as string));
      case ChartDataFormat.BOOLEAN:
      case ChartDataFormat.TEXT:
        return value.toString();
      case ChartDataFormat.TEXT_ARRAY:
        return value.join();
      default:
        return value;
    }
  }

  static addThousandSeparator(value: string, thousandSeparator: string) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  }

  static isNumeric(dataFormat: string, dataType?: string) {
    if (dataFormat || dataType) {
      return dataFormat === ChartDataFormat.INTEGER || dataFormat === ChartDataFormat.DECIMAL || dataType === BaseDataType.NUMBER;
    }
    return false;
  }
}
