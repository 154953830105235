export const AVAILABLE_MARKERS = [
  {
    file: 'alert-marker.png',
  },
  {
    file: 'autopark-marker.svg',
  },
  {
    file: 'autopark-marker-busy.png',
  },
  {
    file: 'autopark-marker-empty.png',
  },
  {
    file: 'autopark-marker-inaccessible.png',
  },
  {
    file: 'autopark-marker-occupied.png',
  },
  {
    file: 'announceSensor.png',
    width: 40,
    height: 40
  },
  {
    file: 'announceSensor_red.png',
    width: 40,
    height: 40
  },
  {
    file: 'default-marker.png',
  },
  {
    file: 'junction-marker.png',
  },
  {
    file: 'offstreetparking-busy.png',
    width: 33,
    height: 40
  },
  {
    file: 'offstreetparking-busy.png',
    width: 33,
    height: 40
  },
  {
    file: 'offstreetparking-closed.png',
    width: 33,
    height: 40
  },
  {
    file: 'offstreetparking-empty.png',
    width: 33,
    height: 40
  },
  {
    file: 'offstreetparking-nodata.png',
    width: 33,
    height: 40
  },
  {
    file: 'offstreetparking-occupied.png',
    width: 33,
    height: 40
  },
  {
    file: 'riser-marker.png',
  },
  {
    file: 'store-marker.png',
  },
  {
    file: 'store-marker-critical.png',
    width: 30,
    height: 48
  },
  {
    file: 'vehicle-marker.png',
    width: 49,
    height: 40
  },
  {
    file: 'castle_128.png',
    width: 40,
    height: 40
  },
  {
    file: 'castle_red.png',
    width: 40,
    height: 40
  },
  {
    file: 'defibrillator.png',
    width: 40,
    height: 40
  },
  {
    file: 'defibrillator_red.png',
    width: 40,
    height: 40
  },
  {
    file: 'defibrillator_yellow.png',
    width: 40,
    height: 40
  },
  {
    file: 'defibrillator_gray.png',
    width: 40,
    height: 40
  },
  {
    file: 'defibrillator_orange.png',
    width: 40,
    height: 40
  },
  {
    file: 'defibrillator_purple.png',
    width: 40,
    height: 40
  },
  {
    file: 'defibrillator_burgundy.png',
    width: 40,
    height: 40
  },
  {
    file: 'tak.png',
    width: 40,
    height: 40
  },
  {
    file: 'tak_red.png',
    width: 40,
    height: 40
  },
  {
    file: 'train-marker-yellow.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-gray.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-red.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-green.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-blue.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-red-right.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-green-right.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-red-left.png',
    width: 50,
    height: 40
  },
  {
    file: 'train-marker-green-left.png',
    width: 50,
    height: 40
  },
  {
    file: 'bus-marker-gray.png',
    width: 40,
    height: 40
  },
  {
    file: 'bus-marker-green.png',
    width: 40,
    height: 40
  },
  {
    file: 'bus-marker-yellow.png',
    width: 40,
    height: 40
  },
  {
    file: 'bus-marker-red.png',
    width: 40,
    height: 40
  },
  {
    file: 'vms-marker.png',
  },
  {
    file: 'weather-marker.png',
  },
  {
    file: 'weather-pollution-good.png',
  },
  {
    file: 'weather-pollution-hazardeous.png',
  },
  {
    file: 'weather-pollution-moderate.png',
  },
  {
    file: 'weather-pollution-unhealthy.png',
  },
  {
    file: 'weather-pollution-unhealthy-for-sensitive.png',
  },
  {
    file: 'weather-pollution-very-unhealthy.png'
  },
  {
    file: 'weather-pollution-very-unknown.png'
  },
  {
    file: 'well-marker.png'
  },
  {
    file: 'well-marker-critical.png',
  },
  {
    file: 'solarPole_red.png',
    width: 40,
    height: 40
  },
  {
    file: 'solarPole_yellow.png',
    width: 40,
    height: 40
  },
  {
    file: 'solarPole_green.png',
    width: 40,
    height: 40
  },
  {
    file: 'drone_green.png',
    width: 30,
    height: 30
  },
  {
    file: 'drone_red.png',
    width: 30,
    height: 30
  },
  {
    file: 'drone_green_move.png',
    width: 30,
    height: 30
  },
  {
    file: 'drone_red_move.png',
    width: 30,
    height: 30
  }
];

const DEFAULT_ICON_WIDTH  = 25;
const DEFAULT_ICON_HEIGHT = 40;

export function getMarkerList(): string[] {
  return AVAILABLE_MARKERS.map(m => m.file);
}

export function getMarkerDimensions(iconFile: string): any {
  const marker: any = AVAILABLE_MARKERS.find(m => m.file === iconFile);
  // if no dimension is specified, return the default ones
  if (!(marker?.width)) {
    return {
      width: DEFAULT_ICON_WIDTH,
      height: DEFAULT_ICON_HEIGHT
    };
  } else {
    return {
      width: marker.width,
      height: marker.height
    };
  }
}
