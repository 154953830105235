import {ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {BaseFormComponent} from './base-form.component';
import {BaseDataType} from '../../enums/base-data-type';
import {FormUtil} from '../../utils/form-util';

/**
 * Input component to deal with inputs that can take multiple values
 */
@Component({
  selector: 'multi-value-input',
  styleUrls: ['multi-value-input.component.scss'],
  templateUrl: './multi-value-input.component.html',
})
export class MultiValueInputComponent extends BaseFormComponent implements OnInit {
  // label of the input
  @Input() label: string;
  // values that are already set for this input
  @Input() values: any[];
  // label to displayed in the add button
  @Input() addButtonLabel: string;
  // whether multiple values are allowed or not. We have this option as only a single might be allowed for certain cases
  @Input() multiValue = false;
  // whether the input is required or not
  @Input() required = false;
  // regular expression to validate the new values
  @Input() regex: string;
  // data type of the input
  @Input() dataType: string = BaseDataType.TEXT;
  // function to transform the given inputs to values that can be managed by this component
  @Input() inputFormatter: (input: any) => any = null;
  // function to transform the output value to some other form. It is applied by the event emitter. By default it returns the output as it is
  @Input() outputFormatter: (output: any) => any = (output: any) => output;
  @Input() displayMode: 'view' | 'edit' = 'view';
  // event emitter that notifies the parent component with the update value of the input
  @Output() valuesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  // when #multiValue is true, it keeps the new value to be added to the list of #managedValues
  // when #multiValue is false, it keeps the single value managed by this component
  newValue: any;
  // managed values when #multiValue is true
  managedValues: any[];

  dataTypes = BaseDataType;

  private ref: ChangeDetectorRef;

  constructor(injector: Injector) {
    super(injector);
    this.ref = injector.get(ChangeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.values) {
      this.managedValues = [];
      return;
    }

    if (this.inputFormatter) {
      this.managedValues = this.values.map(value => this.inputFormatter(value));
    } else {
      this.managedValues = this.copyValues(this.values);
    }

    if (!this.multiValue) {
      this.newValue = this.managedValues[0];
    }
  }

  validateForm(): boolean {
    if (this.required) {
      if (this.multiValue) {
        if (this.managedValues?.length === 0) {
          this.displayErrors = true;
          return false;
        }

        for (const value of this.managedValues) {
          if (FormUtil.isEmptyValue(value)) {
            this.displayErrors = true;
            return false;
          }
        }

      } else {
        if (FormUtil.isEmptyValue(this.newValue)) {
          this.displayErrors = true;
          return false;
        }
      }
    }
    if(this.regex){
      if(this.multiValue){
        for(let value of this.managedValues) {
          if(!new RegExp(this.regex).test(value)){
            this.displayErrors = true;
            return false;
          }
        }
      } else {
        if (!new RegExp(this.regex).test(this.newValue)){
          this.displayErrors = true;
          return false;
        }
      }
    }
    return true;
  }

  getValue(): any {
    // if multiple values are allowed, values entered by the user are managed in the 'managedValues' field
    if (this.multiValue) {
      return this.managedValues;

      // if multiple values are not allowed, we use the 'newValue' field
    } else {
      if (!FormUtil.isEmptyValue(this.newValue)) {
        return [this.newValue];
      } else {
        return [];
      }
    }
  }

  /**
   * Adds the given value to the list of managed values
   */
  onAddValue(): void {
    this.managedValues.push(this.newValue);
    this.emitValueChange(this.managedValues);
  }

  /**
   * Deletes the value specified with the index from the value list
   * @param index
   */
  onDeleteValue(index: number): void {
    this.managedValues.splice(index, 1);
    this.emitValueChange(this.managedValues);
  }

  handleModelChange(value: any, index: number): void {
    this.managedValues[index] = value;
    this.emitValueChange(this.managedValues);
  }

  handleSingleValueModelChange(value: any): void {
    this.newValue = value;
    this.emitValueChange([this.newValue]);
  }

  private emitValueChange(newValue: any[]): void {
    newValue = newValue.map(value => this.outputFormatter(value));
    this.valuesChange.emit(newValue);
  }

  /*
  template getters
 */

  /**
   * Track method for elements of the array with primitive values
   * @param index
   * @param item
   */
  trackFn(index, item) {
    return index;
  }

  displayErrorForValue(index: number): boolean {
    return this.displayErrors && !this.managedValues[index];
  }

  displayErrorForNewValue(): boolean {
    return this.displayErrors && this.required && this.managedValues.length === 0;
  }

  displayRegexError(index: number = null): boolean {
    if(index === null){
      return !new RegExp(this.regex).test(this.newValue);
    }
    return !new RegExp(this.regex).test(this.managedValues[index]);
  }
}

