import {EntityRepresentation} from './visualization/representation.model';
import {BaseMetaModel} from './base/base-meta-model.model';
import {Persistable} from './persistanble';

/**
 * A layer is an extension on a map to display entities with different visualization options
 */
export class Layer extends BaseMetaModel implements Persistable {

  /**
   * Name of the layer
   */
  name: string;

  /**
   * display name of the layer
   */
  layerDisplayName: string;

  /**
   * Icon of the layer
   */
  icon: string;

  /**
   * Page of the icon
   */
  iconPage: string;

  /**
   * Entity representations to be displayed in this layer
   */
  representations: EntityRepresentation[];

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.name = data.name;
    this.layerDisplayName = data.layerDisplayName;
    this.icon = data.icon;
    this.iconPage = data.iconPage;

    if (data.representations) {
      this.representations = data.representations.map(rep => new EntityRepresentation(rep));
    }
  }

  createPersistableObject(): any {
    const {representations, ...rest} = this;
    rest['representations'] = representations.map(representation => representation.createPersistableObject());
    return rest;
  }
}
