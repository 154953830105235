import {BaseMetaModel} from './base/base-meta-model.model';
import {NGSIResult} from "./ngsi/ngsi-result";

export class Atu extends BaseMetaModel {

  direction: string;

  sensorType: number;

  description: String;

  connectionStatus: number;
  colorConnectionStatus: string;
  bitResults: NGSIResult[];
  status : boolean;
  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.direction = data.direction;
    this.sensorType = data.sensorType;
    this.description = data.description;
    this.connectionStatus = data.connectionStatus;
    this.bitResults = data.bitResults;
    this.status = true;
    for (let i = 0; i < this.bitResults.length; i++) {
      if(this.bitResults[i].getEntityResult().entity.bitResult.value !== 1){
        this.status = false;
        break;
      }
    }
    if(this.bitResults.length === 0){
      this.status = true;
    }
    this.colorConnectionStatus = data.colorConnectionStatus;
  }
}
