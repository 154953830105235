import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from './base.component';

@Component({
  template: '',
})
export abstract class NavigatableComponent extends BaseComponent implements OnInit, OnDestroy {

  // Services used in derived components
  protected route: ActivatedRoute;
  protected router: Router;

  constructor(injector: Injector) {
    super(injector);

    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
