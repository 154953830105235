import {SearchQuery} from './search-query.model';

/**
 * Model used for querying structure definitions
 * */
export class StructureDefinitionQuery extends SearchQuery {
  /**
   * Filter the structure definitions in a certain kind
   * */
  kind: string;
  /**
   * Filter the structure definitions which are in the given status
   * */
  status: string;
  /**
   * Filter the structure definitions which are abstract or not by the given value
   * */
  isAbstract: boolean;

  constructor(data?: any) {
    super(data);
    if (!data) {
      return;
    }

    this.kind = data.kind;
    this.status = data.status;
    this.isAbstract = data.isAbstract;
  }
}
