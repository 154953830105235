<div (click)="onLayerClicked(layer.id)" *ngFor="let layer of layers"
     [ngClass]="{'selected': isLayerSelected(layer.id)}" class="layer-items flexible-cell">
  <nb-icon
    [icon]="layer.icon" [pack]="getIconPage(layer)"
    [nbPopover]="getTooltip(layer) | translate"
    nbPopoverPlacement="bottom"
    nbPopoverTrigger="hint"
    size="100px">
  </nb-icon>
</div>
