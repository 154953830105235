import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ListViewFilterFunction} from '../../../modules/admin/components/list-view.component';
import {PAGINATION_DEFAULT_PAGE_SIZE} from './pagination.component';
import {tr} from "date-fns/locale";
import {AnimationOptions} from "ngx-lottie";

export type PaginationCallbackFunction<T = any> = (pageSize: number, page: number) => void;

@Component({
  selector: 'uruk-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy, OnChanges {

  // items in the list
  @Input() list: any[] = [];
  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef<HTMLInputElement>;
  // function to set the visibility of delete button for the item
  @Input() displayDeleteButton: ListViewFilterFunction = (item: any) => true;
  @Input() displayActionButton: boolean;
  @Input() displayEditButton: boolean = true;
  @Input() displayCount: boolean = true;
  @Input() displaySearch: boolean = false;
  @Input() tableDataLoading: boolean = false;
  @Input() displayFilter: boolean = false;
  @Input() displayMultipleActions: boolean = false;
  @Input() isMultipleActionsDisable: boolean = true;
  @Input() displayCheckBox: boolean = false;
  @Input() columnMetadata: any[];
  /**
   * Keeps the metadata of statuses displayed at the top of component.
   * Each object in metadata should have the following format:
   * {
   *   count: number; the status count displayed next to title
   *   title: string; the status title
   *   class: string; a css class to be applied to the title
   *   commaSeparated: boolean; whether a comma should be appended to the status text
   * }
   * */
  @Input() statusMetadata: any[];
  // type of entries displayed in the list component
  @Input() type: string;
  @Input() searchPlaceholder: string = '';
  @Input() deleteIconClass: string = 'fa fa-trash';
  @Input() editIconClass: string = 'fa fa-eye';
  @Input() actionIconClass: string = 'fa fa-arrow-right';
  // callback function to be called when entries are paginated
  @Input() paginationCallback: any;
  @Output() onEditItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() onActionItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelectAllItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdateItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResetItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResetAllSelections: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSortAscending: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSortDescending: EventEmitter<string> = new EventEmitter<string>();
  @Output() onTextSearch: EventEmitter<string> = new EventEmitter<string>();

  // search text typed by the user
  searchText: string;
  // keeps the visibility of delete buttons for items
  deleteButtonVisibilityForItem: boolean[];
  // page size of pagination
  public pageSize: number = PAGINATION_DEFAULT_PAGE_SIZE;
  isInitialized: boolean;

  lottieOptions: AnimationOptions = {
    path: '/assets/animation/uruk-5sec.json',
  };

  constructor() {

  }

  ngOnInit(): void {
    this.isInitialized = true;
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.list && changes.list.currentValue !== changes.list.previousValue) || (changes.displayDeleteButton && changes.displayDeleteButton.currentValue !== changes.displayDeleteButton.previousValue)) {
      this.setDeleteButtonVisibilityForItems();
      if(this.isInitialized && this.displayCheckBox){
        this.selectAllCheckbox.nativeElement.checked = false;
        this.isMultipleActionsDisable = true;
        this.onResetAllSelections.emit();
      }
    }
  }

  /**
   * Handles the pagination event.
   * @param event the pagination event
   */
  public paginate(event) {
    this.pageSize = event.pageSize;
    this.paginationCallback(event.pageSize, event.page);
  }

  /**
   * Populates {@link deleteButtonVisibilityForItem} array by applying {@link displayDeleteButton} function to each item
   * in the list.
   * */
  private setDeleteButtonVisibilityForItems() {
    if (this.list) {
      this.deleteButtonVisibilityForItem = this.list.map(item => this.displayDeleteButton(item));
    }
  }

  handleTextSearch() {
    this.onTextSearch.emit(this.searchText);
  }

  public sortAscending(field: string) {
    this.onSortAscending.emit(field);
  }

  public sortDescending(field: string) {
    this.onSortDescending.emit(field);
  }

  handleSelectAll() {
    const checkbox = this.selectAllCheckbox.nativeElement;
    const isChecked = checkbox.checked;

    this.onSelectAllItem.emit(isChecked)
    this.selectAll(isChecked);
  }


  selectAll(isChecked : boolean) {
    const checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll('.actions-check-input');

    // Check if the checkbox is checked or not
    checkboxes.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  }
}
