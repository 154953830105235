import {GeoJson} from './geo-json.model';

export class Point extends GeoJson {

  /**
   * The coordinates of point
   * */
  coordinates: number[];

  constructor(data?) {
    super(data);

    this.type = 'Point';

    if (!data) {
      return;
    }

    this.coordinates = data.coordinates;
  }
}
