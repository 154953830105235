/**
 * Describes an individual series of data that can be returned in a KPI response
 */
import {ChartDataFormat} from '../../enums/chart-data-format';

export class DataSeries {

  /**
   * Label representing a single data point in the series e.g. junction density
   */
  label?: string;

  /**
   * Json Path to the element value in aggregation/query result for the dimension
   */
  path: string;

  /**
   * Data type of the series. Corresponds to data type of the corresponding ElementDefinition for series of entity results. For aggregation results, it is one of the
   * {@link BaseDataType}s
   */
  dataType: string;

  /**
   * The type of the displayed data
   */
  format?: ChartDataFormat;

  /**
   * Unit of the data
   */
  unit?: string;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.label = data.label;
    this.path = data.path;
    this.format = data.format;
    this.dataType = data.dataType;
    this.unit = data.unit;
  }
}
