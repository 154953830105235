export enum MapFramework {
  LEAFLET = 'Leaflet',
  MAPLIBRE = 'Maplibre',
  MAPRAYS = 'Maprays'
}

export enum MapTileType {
  DEFAULT = 'Default',
  SATELLITE = 'Satellite',
  ROADMAP = 'RoadMap'
}
