import {Injectable, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base.component';
import {MenuItemsUtil} from '../../shared/utils/menu-items-util';
import {MenuItem} from '../../shared/models/menu/menu-item';
import {Attribute} from '../../shared/models/attribute.model';
import {MainMenuItemEnum} from '../../shared/enums/main-menu-item.enum';

@Injectable({
  providedIn: 'root',
})

/**
 * Utility class for handling menu items based on realm attributes.
 * Extends BaseComponent to leverage common functionality.
 */
export class MenuItemsService extends BaseComponent implements OnInit {

  constructor(injector: Injector, private menuItemsUtil: MenuItemsUtil) {
    super(injector);
  }

  ngOnInit() {
  }

  /**
   * Populates the menu by filtering items based on provided attributes.
   * Returns the filtered menu items based on the attributes.
   * @param attributes Array of attributes to filter the menu items.
   * @returns Filtered menu items.
   */
  public populateMenu(attributes: Attribute[]): MenuItem[] {
    return this.initializeMenuBasedOnAttributes(attributes, this.getMenuItems());
  }

  /**
   * Fetches the list of menu items from MenuItemsUtil.
   * @returns An array of MenuItem objects.
   */
  public getMenuItems(): MenuItem[] {
    return this.menuItemsUtil.getMenuItems();
  }

  /**
   * Initializes the menu items based on the provided attributes.
   * @param attributes Array of attributes to filter the menu items.
   * @param menuItems Array of all menu items to filter.
   * @returns Filtered menu items array.
   */
  private initializeMenuBasedOnAttributes(attributes: Attribute[], menuItems: MenuItem[]): MenuItem[] {
    if (this.authService.getRealmId() === '*') {
      return menuItems;
    }

    return this.hasAttributes(attributes)
      ? this.filterMenuItemsBasedOnAttributes(attributes, menuItems)
      : this.setLogout(menuItems); // If no attributes, show only 'Logout'
  }

  /**
   * Checks if the provided attributes are defined and non-empty.
   * @param attributes Array of attributes.
   * @returns True if attributes are defined and non-empty, false otherwise.
   */
  private hasAttributes(attributes: Attribute[]): boolean {
    return attributes !== undefined && attributes !== null && attributes.length > 0;
  }

  /**
   * Returns only the 'Logout' menu item.
   * This can be used when no realm-specific menu is available.
   * @param menuItems Array of menu items.
   *
   * @returns Array containing only the 'Logout' menu item.
   */
  private setLogout(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.filter(item => item.title === 'Logout');
  }

  /**
   * Filters the menu items based on the provided attributes.
   * @param realmAttributes Array of attributes associated with the current realm.
   * @param menuItems Array of all menu items to filter.
   * @returns Array of filtered menu items.
   */
  private filterMenuItemsBasedOnAttributes(realmAttributes: Attribute[], menuItems: MenuItem[]): MenuItem[] {
    return menuItems.filter(item => {
      const isEnumItem = Object.values(MainMenuItemEnum).includes(item.title as MainMenuItemEnum);
      return isEnumItem ? this.filterEnumItem(item, realmAttributes) : true;  // Keep other menu items as they are
    });
  }

  /**
   * Filters a single menu item if it matches one of the enum names and the realm's attributes.
   * @param item The menu item to potentially filter.
   * @param realmAttributes The array of attributes for the current realm.
   * @returns True if the menu item should be kept, false otherwise.
   */
  private filterEnumItem(item: MenuItem, realmAttributes: Attribute[]): boolean {
    const matchingAttribute = realmAttributes.find(attribute => attribute.name === item.title);
    // If the attribute matches, filter the sub-menu items (children) as well
    if (matchingAttribute) {
      this.filterMenuItemChildren(realmAttributes, matchingAttribute.name, item);
      return true; // Keep the item if there's a matching attribute
    }
    return false; // Exclude the item if no matching attribute is found
  }

  /**
   * Filters the sub-menu items (children) of a menu item based on the realm's attributes.
   * @param realmAttributes Array of realm attributes to match.
   * @param mainAttributeName The name of the main attribute (parent).
   * @param menuItem The parent menu item whose children will be filtered.
   */
  private filterMenuItemChildren(realmAttributes: Attribute[], mainAttributeName: string, menuItem: MenuItem): void {
    if (menuItem.children && menuItem.children.length > 0) {
      // Filter the children based on sub-attributes
      menuItem.children = menuItem.children.filter(subItem => {
        // Check if the subItem matches a sub-attribute with the mainAttributeName as its parent
        return realmAttributes.some(attr => attr.name === subItem.title && attr.parentName === mainAttributeName);
      });
    }
  }
}
