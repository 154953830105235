import {QueryOperatorParamValue} from '../../query/query-operator-param-value.model';
import {OperatorParameterExpressionType} from '../../../enums/ngsi-query.enum';

export class NGSIOperatorParameter {
  name: string;
  description: string;
  type: string;
  unlimited = false;
  allowedExprs: OperatorParameterExpressionType[];
  default: any;
  valueSet: string[];
  regex: string;

  /*
    UI-specific fields
   */
  wrappedValueSet: QueryOperatorParamValue[];

  constructor(data) {
    this.name = data.name;
    this.description = data.description;
    this.type = data.type;
    this.unlimited = data.unlimited;
    this.allowedExprs = data.allowedExprs;
    this.default = data.default;
    this.regex = data.regex;
    this.valueSet = data.valueSet;
    if (this.valueSet) {
      this.wrappedValueSet = this.valueSet.map(value => new QueryOperatorParamValue(value));
    }
  }
}
