import {BaseMetaModel} from './base/base-meta-model.model';
import {RealmLogo} from './realm-logo.model';
import {Attribute} from './attribute.model';

/**
 * Indicates a realm; a smart city organization; managed independently within itself
 */
export class Realm extends BaseMetaModel {

  /**
   * Name of the realm e.g. Samsun Büyükşehir Belediyesi
   */
  name: string;

  /**
   * Id of the region that defines the realm
   */
  regionId: String;

  /**
   * Whether realm is in use or not
   * */
  inUse: boolean;

  /**
   * Attributes of the realm
   */
  attributes: Attribute[];

  // Realm logo
  logo: RealmLogo;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.name = data.name;
    this.regionId = data.regionId;
    this.inUse = data.inUse;
    this.attributes = data.attributes;
    this.logo = new RealmLogo(data.logo);
  }
}
