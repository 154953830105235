import {Layer} from '../../shared/models/layer.model';
import {Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {NGSIResultUtil} from '../../shared/utils/ngsi-result-util';
import {EntityRepresentation} from '../../shared/models/visualization/representation.model';
import {Notification} from '../../shared/models/notification.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import { LayerController } from './layer.controller';
import { catchError, forkJoin, map, Observable, of, take } from 'rxjs';
import { NGSIQuery } from 'app/shared/models/ngsi/ngsi-query.model';
import { NGSIFilter } from 'app/shared/models/ngsi/ngsi-filter.model';
import {Marker} from '../../shared/models/map/marker.model';

export class VehicleLayerController extends LayerController {

  displaysTooltip = true;

  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    query.filter.id = [result.getEntityResult().getSingleElementValue('vehicleRef')];
    const vehicleRefQuery = this.cbrokerService.getEntity(query, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const vehiclePositionQuery = this.getMarkerEntity(result.getEntityId(),false);
    const contextObservable: Observable<any> = forkJoin([vehicleRefQuery, vehiclePositionQuery]).pipe(
      take(1),
      map(response => {
        return {
          entity: response[0]?.getEntityResult(),
          positionEntity: response[1]?.getEntityResult()
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }

  /**
   * First deletes the existing marker, if any, for the corresponding vehicle and then creates the new marker for the notification
   * @param representation
   * @param notification
   * @protected
   */
  protected handleCreateEvent(representation: EntityRepresentation, notification: Notification): NGSIResult {
    const newEntityVehicleRef: string = NGSIResultUtil.extractEntityAttributeValue(notification.eventPayload.content, 'vehicleRef', new QueryOptions());
    const representationEntities = this.entities.get(representation)
    const entityIndex = representationEntities ? representationEntities.findIndex(e => {
      return this.getVehicleRefFromNGSIResult(e) === newEntityVehicleRef;
    }) : -1;
    if (entityIndex !== -1) {
      this.entities.get(representation).splice(entityIndex, 1);
      this.markers.get(representation).splice(entityIndex, 1);
    }
    return super.handleCreateEvent(representation, notification);
  }

  /**
   * Filters out the duplicate results that are belong to the same vehicle
   * TODO non-duplicate results will be fetched from server one we have the "last" operator
   * @param results
   * @protected
   */
  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    const entitiesFound: Set<string> = new Set();
    const finalResults: NGSIResult[] = [];
    results.forEach(result => {
      const vehicleRef: string = this.getVehicleRefFromNGSIResult(result);
      if (!entitiesFound.has(vehicleRef)) {
        finalResults.push(result);
        entitiesFound.add(vehicleRef);
      }
    })
    return finalResults;
  }

  private getVehicleRefFromNGSIResult(ngsiResult: NGSIResult): string {
    return ngsiResult.getEntityResult().getSingleElementValue('vehicleRef');
  }

  createTooltip(marker: Marker): string {
    const id = marker.entity.entity.id.substring(marker.entity.entity.id.lastIndexOf(':') + 1);
    const speed = marker.entity.getSingleElementValue('speed');

    const plateLabel = this.translateService.instant('plate');
    const speedLabel = this.translateService.instant('Speed');
    const unitLabel = this.translateService.instant('unit.km/h');

    let html = '<div class="entity-tooltip">';
    html += `<div class="text"><span class="title">${plateLabel}: </span> ${id}</div>`;
    html += `<div class="text"><span class="title">${speedLabel}: </span> ${speed} ${unitLabel}</div>`;

    if (marker.entity.entity.linecode) {
      const linecode = marker.entity.getSingleElementValue('linecode');
      const linecodeLabel = this.translateService.instant('Linecode');
      html += `<div class="text"><span class="title">${linecodeLabel}: </span> ${linecode}</div>`;
    }

    html += '</div>';
    return html;
  }
}
