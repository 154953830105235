import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseHttpClientService} from '../base-http-client.service';
import {MLModel} from '../../../shared/models/analytics/mlmodel.model';
import {environment} from '../../../../environments/environment';

/**
 * A service to handle analytics models
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService extends BaseHttpClientService {

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'ml-models');
  }

  /**
   * Retrieves all ML models defined on the platform
   */
  getModelConfigs(text?: string): Observable<MLModel[]> {
    const urlParameter = text ? '?text=' + text : '';
    const url: string = `${this.endpoint}${urlParameter}`;
    return this.httpClient.get<MLModel[]>(url)
      .pipe(
        map(response => {
          return response.map(item => new MLModel(item));
        })
      );
  }

  /**
   * Retrieves the content of the ML Model with given id
   */
  getModelConfig(id: string): Observable<MLModel> {
    const url: string = `${this.endpoint}/${id}`;
    return this.httpClient.get(url)
      .pipe(
        map(response => {
          return new MLModel(response);
        })
      );
  }

  /**+
   * Creates a new ML Model with given data and script file content on the platform
   * @param model Model data
   * @param script ML script for training
   */
  createModel(model: MLModel, script: File): Observable<MLModel> {
    const formData: any = new FormData();
    formData.append('mlModel', JSON.stringify(model));
    formData.append('script', script);

    const url: string = `${this.endpoint}`;
    return this.httpClient.post(url, formData)
      .pipe(
        map(response => {
          return new MLModel(response);
        })
      );
  }

  /**
   * Deletes the ML model with given ID.
   * @param modelId Identifier of the ML model
   */
  deleteModel(modelId: string) {
    const url: string = `${this.endpoint}/${modelId}`;
    return this.httpClient.delete(url);
  }

  /**
   * Predicts new values for the given entity based on corresponding trained models
   */
  predict(model: MLModel, modelInstance: string) {
    const api = environment.server.predictionApi;
    const split = modelInstance.split('_');
    const url = `${api}?entityid=urn:ngsi-ld:${model.config.entityType}:${split[0]}&modelfile=${modelInstance}&script=${model.config.script}&entitytype=${model.config.entityType}&entityattribute=${model.config.entityAttribute}&realm=${this.authService.getRealmId()}`;
    return this.httpClient.get(url);
  }
}
