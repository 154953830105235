<closeable-dialog class="weather-pollution-dialog-container">
  <div class="dialog-header" dialog-card-header> <label>{{ 'Weather Pollution' | translate }} </label> <div class="badge" [ngClass]="'weather-pollution-badge-' + weatherPollutionLevel"> {{ getWeatherPollutionLabel(weatherPollutionLevel) | translate }}</div></div>

  <div dialog-card-body class="dialog-body full-height">
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab tabTitle="{{ 'Summary' | translate }}" [active]="activeTab === 'summary'" [tabId]="'summary'">

        <div class="summary">

          <div class="row">
            <div class="col-4 column-title">
              {{ 'General Information' | translate }}:
            </div>
            <div class="col-1"></div>
            <div class="col-6 column-title">
              {{ 'Activity Suggestions' | translate}}:
            </div>
            <div class="col-1">

            </div>
          </div>

          <div class="row info-row" >
            <div class="col-4">
              <div>
                <label class="column-info title"> {{ 'Station' | translate }}: </label>
                <label class="column-info content"> {{name ? name : address?.addressLocality + ' - ' + address?.addressRegion}} </label>
              </div>
              <div>
                <label class="column-info title"> {{ 'City' | translate }}: </label>
                <label class="column-info content"> {{address?.addressLocality}} </label>
              </div>
              <div>
                <label class="column-info title"> {{ 'County' | translate }}: </label>
                <label class="column-info content"> {{address?.addressRegion}} </label>
              </div>
            </div>
            <div class="col-1"></div>
            <div class="col-6">
              <div>
                <label class="column-info content"> <strong>{{ 'General Population' | translate }}:</strong> {{ getActivitySuggestionsForGeneralPopulation() | translate }}</label>
              </div>
              <div>
                <label class="column-info content"> <strong>{{ 'Sensitive Groups' | translate }}:</strong> {{ getActivitySuggestionsForSensitive() | translate }}</label>
              </div>
            </div>
            <div class="col-1"></div>
          </div>

          <div class="row row-title">
            <div class="col-4 column-title">
              {{ 'Current Measurements' | translate }}:
            </div>
          </div>
          <div class="row value-container">
            <div class="value-box">
              <div class="value-title">AQI</div>
              <div class="value" [ngClass]="'weather-pollution-' + weatherPollutionLevel">{{ getAQI(aqi) }}</div>
            </div>
            <div class="value-box">
              <div class="value-title">CO</div>
              <div class="value" [ngClass]="'weather-pollution-' + assessCO(getValue(entity, 'co'))">{{ getValue(entity, 'co') }} µg/m³</div>
            </div>
            <div class="value-box">
              <div class="value-title">NO₂</div>
              <div class="value" [ngClass]="'weather-pollution-' + assessNO2(getValue(entity, 'no2'))">{{ getValue(entity, 'no2') }} µg/m³</div>
            </div>
            <div class="value-box">
              <div class="value-title">SO₂</div>
              <div class="value" [ngClass]="'weather-pollution-' + assessSO2(getValue(entity, 'so2'))">{{ getValue(entity, 'so2') }} µg/m³</div>
            </div>
            <div class="value-box">
              <div class="value-title">O₃</div>
              <div class="value" [ngClass]="'weather-pollution-' + assessO3(getValue(entity, 'o3'))">{{ getValue(entity, 'o3') }} µg/m³</div>
            </div>
            <div class="value-box">
              <div class="value-title">PM10</div>
              <div class="value" [ngClass]="'weather-pollution-' + assessPM10(getValue(entity, 'pm10'))">{{ getValue(entity, 'pm10') }} µg/m³</div>
            </div>
            <div class="value-box">
              <div class="value-title">PM25</div>
              <div class="value" [ngClass]="'weather-pollution-' + assessPM25(getValue(entity, 'pm25'))">{{ getValue(entity, 'pm25') }} µg/m³</div>
            </div>
          </div>
        </div>

      </nb-tab>
      <nb-tab tabTitle="{{ 'Air Quality' | translate }}" [active]="activeTab === 'aqi'" [tabId]="'aqi'">
        <div class="observation-title"><span>{{'Air Quality'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="aqiPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
          <nb-tab tabTitle="{{'Future Predictions'|translate}}">
            <uruk-panel [panel]="aqiPredictionPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
        <div class="legend">
          <uruk-legend [settings]="aqiLegend"></uruk-legend>
        </div>
      </nb-tab>
      <nb-tab tabTitle="CO" [active]="activeTab === 'co'" [tabId]="'co'">
        <div class="observation-title"><span>CO</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="coPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
          <nb-tab tabTitle="{{'Future Predictions'|translate}}">
            <uruk-panel [panel]="coPredictionPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
        <div class="legend">
          <uruk-legend [settings]="coLegend"></uruk-legend>
        </div>
      </nb-tab>
      <nb-tab tabTitle="NO₂" [active]="activeTab === 'no2'" [tabId]="'no2'">
        <div class="observation-title"><span>NO₂</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="no2Panel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
          <nb-tab tabTitle="{{'Future Predictions'|translate}}">
            <uruk-panel [panel]="no2PredictionPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
        <div class="legend">
          <uruk-legend [settings]="no2Legend"></uruk-legend>
        </div>
      </nb-tab>
      <nb-tab tabTitle="SO₂" [active]="activeTab === 'so2'" [tabId]="'so2'">
        <div class="observation-title"><span>SO₂</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="so2Panel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
          <nb-tab tabTitle="{{'Future Predictions'|translate}}">
            <uruk-panel [panel]="so2PredictionPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
        <div class="legend">
          <uruk-legend [settings]="so2Legend"></uruk-legend>
        </div>
      </nb-tab>
      <nb-tab tabTitle="O₃" [active]="activeTab === 'o3'" [tabId]="'o3'">
        <div class="observation-title"><span>O₃</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="o3Panel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
          <nb-tab tabTitle="{{'Future Predictions'|translate}}">
            <uruk-panel [panel]="o3PredictionPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
        <div class="legend">
          <uruk-legend [settings]="o3Legend"></uruk-legend>
        </div>
      </nb-tab>
      <nb-tab tabTitle="PM10" [active]="activeTab === 'pm10'" [tabId]="'pm10'">
        <div class="observation-title"><span>PM10</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="pm10Panel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
          <nb-tab tabTitle="{{'Future Predictions'|translate}}">
            <uruk-panel [panel]="pm10PredictionPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
        <div class="legend">
          <uruk-legend [settings]="pm10Legend"></uruk-legend>
        </div>
      </nb-tab>
      <nb-tab tabTitle="PM25" [active]="activeTab === 'pm25'" [tabId]="'pm25'">
        <div class="observation-title"><span>PM25</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="pm25Panel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
          <nb-tab tabTitle="{{'Future Predictions'|translate}}">
            <uruk-panel [panel]="pm25PredictionPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
        <div class="legend">
          <uruk-legend [settings]="pm25Legend"></uruk-legend>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>

  <div *ngIf="address" dialog-card-footer class="dialog-footer">
    {{address.addressLocality}} | ({{address.addressRegion}} / {{address.addressLocality}})
    <ng-container [ngSwitch]="activeTab">
      <ng-container *ngSwitchCase="'aqi'">
        <ng-container *ngIf="aqiTabModifiedDate">| {{ aqiTabModifiedDate }}</ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'co'">
        <ng-container *ngIf="coTabModifiedDate">| {{ coTabModifiedDate }}</ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'no2'">
        <ng-container *ngIf="no2TabModifiedDate">| {{ no2TabModifiedDate }}</ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'so2'">
        <ng-container *ngIf="so2TabModifiedDate">| {{ so2TabModifiedDate }}</ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'o3'">
        <ng-container *ngIf="o3TabModifiedDate">| {{ o3TabModifiedDate }}</ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'pm10'">
        <ng-container *ngIf="pm10TabModifiedDate">| {{ pm10TabModifiedDate }}</ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'pm25'">
        <ng-container *ngIf="pm25TabModifiedDate">| {{ pm25TabModifiedDate }}</ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="summaryTabModifiedDate">| {{ summaryTabModifiedDate }}</ng-container>
      </ng-container>
    </ng-container>
  </div>

</closeable-dialog>
