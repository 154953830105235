import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

/**
 * Custom form control for uploading a file to the system.
 */
@Component({
    selector: 'file-selector',
    styleUrls: ["file-selector.component.scss"],
    templateUrl: './file-selector.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FileSelectorComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: FileSelectorComponent
        }
    ]
})
export class FileSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {
    // label for input component
    @Input() label: string;
    // flag indicating whether errors are displayed for input fields have invalid values
    @Input() displayErrors = false;
    // whether file selection is mandatory
    @Input() required = false;
    // field indicating how this component stores the file content
    //  - File: Stores file content as a File
    //  - DataURL: Stores file content as a data url (base64 encoded string)
    @Input() type: 'File' | 'DataURL' = 'DataURL';

    // form group to keep selected file content and name
    form: FormGroup;

    onTouched: Function = () => { };

    onChangeSubs: Subscription[] = [];

    disabled = false;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        // initialize the form and add validators if any
        this.form = this.fb.group({
            content: ["", this.required ? Validators.required : null],
            name: ["", this.required ? Validators.required : null]
        });
    }

    ngOnDestroy() {
        for (let sub of this.onChangeSubs) {
            sub.unsubscribe();
        }
    }

    /** Implement ControlValueAccessor interface */

    registerOnChange(onChange: any) {
        const sub = this.form.valueChanges.subscribe(onChange);
        this.onChangeSubs.push(sub);
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
        if (disabled) {
            this.form.disable();
        }
        else {
            this.form.enable();
        }
    }

    writeValue(value: any) {
        if (value) {
            this.form.setValue(value, { emitEvent: false });
        }
    }

    validate(control: AbstractControl): ValidationErrors {
        if (this.form.valid) {
            return null;
        }

        let errors: any = {};
        errors = this.addControlErrors(errors, "content");
        errors = this.addControlErrors(errors, "name");
        return errors;
    }

    /**
     * Opens a file browser to upload a realm logo
     * @param e Related event
     */
    public browseFiles(e) {
        // necessary check for below logic to execute only once (due to JS event propagation mess)
        if (e.target.id !== 'browse') {
            const input = document.getElementById('browse');
            input.addEventListener('change', async (event: any) => {
                let reader = new FileReader();
                // set 'content' form control based on the 'type' of component
                if (this.type === 'File') {
                    const file = event.target.files[0];
                    this.form.get('content').setValue(file);
                    this.form.get('name').setValue(file.name);
                } else {
                    reader.onload = () => {
                        this.form.get('content').setValue(reader.result);
                        this.form.get('name').setValue(event.target.files[0].name);
                    }
                    reader.readAsDataURL(event.target.files[0]);
                }
            });

            input.click();
        }
    }

    /**
    * Clears logo selection by resetting the corresponding form controls.
    */
    public clearLogoSelection() {
        this.form.get('content').reset("")
        this.form.get('name').reset("");
    }

    /**
     * Adds the errors of given form control to 'allErrors' list.
     * @param allErrors Existing errors of form
     * @param controlName Form control name whose errors will be added to the list
     * @returns the list of errors
     */
    private addControlErrors(allErrors: any, controlName: string) {
        const errors = { ...allErrors };
        const controlErrors = this.form.controls[controlName].errors;
        if (controlErrors) {
            errors[controlName] = controlErrors;
        }
        return errors;
    }
}