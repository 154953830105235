export class ReportEndpoint {
  public uri: string;
  public accept: string[];

  constructor(data?) {
    if (!data) {
      return;
    }

    this.uri = data.uri;
    this.accept = data.accept;
  }
}
