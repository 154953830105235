<div class="form-group form-item" [ngClass]="{'disabled': disabled}">
    <label [ngClass]="{'error-label': displayErrors && form.get('name').invalid}" class="form-item-title">
        {{ label | translate }}
    </label>

    <div *ngIf="!form.get('name').value" class="upload-button flex-centered" (click)="browseFiles($event)">
        <i class="fa fa-upload"></i>
        <input type="file" id="browse" style="display:none">
    </div>

    <div *ngIf="form.get('name').value" class="uploaded-file">
        <input fieldSize="tiny" [fullWidth]="true" [disabled]="true" [placeholder]="form.get('name').value" nbInput
            type="text">
        <div class="upload-icon flex-centered" (click)="clearLogoSelection()">
            <i class="fa fa-trash"></i>
        </div>
    </div>
</div>