export class PollingSettings {
  /**
   * Frequency to trigger polling
   */
  interval: string;

  constructor(data?) {
    if (!data) {
      return;
    }

    this.interval = data.interval;
  }
}
