export class DoorModel {
  door1LevelFault: any;
  door2LevelFault: any;
  ickapi1SeviyeAriza: any;
  kapi1SeviyeAriza: any;
  prmAsansorKapi1Devrede: any;
  doorLoopOkLed: any;
  slDoorControlByPass: any;
  innerDoorError: any[];
  outerDoorStatus: any[];
  doorFaultPriorityAActive: any[];
  doorFaultPriorityBActive: any[];
  doorFaultPriorityCActive: any[];
  stepFaultPriorityAActive: any[];
  stepFaultPriorityBActive: any[];
  stepFaultPriorityCActive: any[];
  doorInsulationList: any[];

  constructor(data: any) {
    this.door1LevelFault = data.door1LevelFault;
    this.door2LevelFault = data.door2LevelFault;
    this.ickapi1SeviyeAriza = data.ickapi1SeviyeAriza;
    this.kapi1SeviyeAriza = data.kapi1SeviyeAriza;
    this.prmAsansorKapi1Devrede = data.prmAsansorKapi1Devrede;
    this.doorLoopOkLed = data.doorLoopOkLed;
    this.slDoorControlByPass = data.slDoorControlByPass;
    this.innerDoorError = data.innerDoorError;
    this.outerDoorStatus = data.outerDoorStatus;
    this.doorFaultPriorityAActive = data.doorFaultPriorityAActive;
    this.doorFaultPriorityBActive = data.doorFaultPriorityBActive;
    this.doorFaultPriorityCActive = data.doorFaultPriorityCActive;
    this.stepFaultPriorityAActive = data.stepFaultPriorityAActive;
    this.stepFaultPriorityBActive = data.stepFaultPriorityBActive;
    this.stepFaultPriorityCActive = data.stepFaultPriorityCActive;
    this.doorInsulationList = data.doorInsulationList;
  }
}
