/**
 * A utility class to manipulate JavaScript objects
 */
export class ObjectUtil {

  /**
   * Checks if given variable is object or not
   * @param item
   * @returns {boolean}
   */
  static isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  /**
   * Checks if given variable is array or not
   * @param item
   * @returns {boolean}
   */
  static isArray(item) {
    return (item && Array.isArray(item));
  }

  /**
   * Checks if given array contains arrays
   * @param item
   * @returns {boolean}
   */
  static isArrayOfArray(item) {
    return item && item.every(function(x){ return Array.isArray(x); });
  }

  /**
   * Checks if given object or array is empty
   * @param object
   */
  static isEmpty(object) {
    if (Array.isArray(object)) {
      return object.length === 0;
    } else {
      return Object.keys(object).length === 0;
    }
  }

  /**
   * Returns the value of the first key of an object. If an empty object is provided, then a "null" value is returned
   */
  static getFirstValue(object) {
    const keys = Object.keys(object);
    if (keys.length > 0) {
      return object[keys[0]];
    }
    return null;
  }

  /**
   * Returns the value of the first array object. In an empty array is provided, then a "null" value is returned
   * @param array
   */
  static getFirstArrayItem(array) {
    if (array && array.length > 0) {
      return array[0];
    }
    return null;
  }

  /**
   * Recursively merges two objects
   * @param target
   * @param source
   */
  static mergeDeep(target, source) {
    const output = Object.assign({}, target);
    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach(key => {
        if (this.isArray(target[key]) && this.isArray(source[key])) { // handle arrays
          // merge objects at the same index
          const minLength = Math.min(target[key].length, source[key].length);
          for (let i = 0; i < minLength; i++) {
            output[key][i] = this.mergeDeep(target[key][i], source[key][i]);
          }
          // push the remaining objects
          if (source[key].length > target[key].length) {
            for (let i = target[key].length; i < source[key].length; i++) {
              output[key].push(source[key][i]);
            }
          }
        } else if (this.isObject(source[key])) { // handle objects
          if (!(key in target))
            Object.assign(output, {[key]: source[key]});
          else
            output[key] = this.mergeDeep(target[key], source[key]);
        } else { // handle primitives
          output[key] = source[key];
        }
      });
    }
    return output;
  }

  /**
   * Makes a deep copy of the given object.
   * @param type
   * @param object
   * @param discardedFields Fields that won't be included in the new object
   * @return the deep copy of given object
   * */
  static deepCopy<T>(type: (new(json: any) => T), object: T, discardedFields: string[] = []): T {
    return new type(
      JSON.parse(
        JSON.stringify(
          object,
          (key, value) => {
            if (!discardedFields.includes(key)) {
              return value;
            }
          }
        )
      )
    );
  }
}
