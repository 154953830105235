/**
 * An information model containing the information to be reported to be sent to the backend
 */
import {DimensionSeriesData} from './dimension-series-data.model';

export class PanelContextData {
  /**
   * Title of the panel
   */
  panelTitle: string;

  /**
   * Data presented in the panel
   */
  data: DimensionSeriesData[];

  /**
   * The identifier of page to which panel belongs
   * */
  pageId: string;

  /**
   * Base64 encoded version of the screenshot of the chart displayed in the panel
   */
  image?: string;
}
