import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageUtil} from '../../shared/utils/storage-util';
import {BEARER_TOKEN_PREFIX, Headers} from '../../shared/utils/rest-util';

/**
 * Checks request of HttpParams for any null or undefined values. Such values are converted to empty strings
 */
@Injectable()
export class PayloadEmptyCheckInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body instanceof HttpParams) {
      const params: HttpParams = req.body;
      params.keys()
        // filter empty values
        .filter(key => !params.get(key))
        .forEach(key => params.set(key, ''));
    }

    return next.handle(req);
  }
}
