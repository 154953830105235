import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';

@Component({
  selector: 'announceSensor-dialog-template',
  templateUrl: './announceSensor-dialog-template.component.html',
  styleUrls: ['./announceSensor-dialog-template.component.scss']
})
export class AnnounceSensorDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  // Context variables
  entity: any;
  sensorUnitId: any;
  description: any;
  status: any;
  sensorType: any;
  rtuId: any;
  speakerVolume1: any;
  speakerVolume2: any;
  speakerVolume3: any;
  speakerVolume4: any;
  batteryVoltage: any;
  cabinetTemperature: any;
  lowBatteryThresholdValue: any;
  veryLowBatteryThresholdValue: any;
  lowTemperatureThresholdValue: any;
  highTemperatureThresholdValue: any;
  gpsStatus: any;
  amplifierState1: any;
  amplifierState2: any;
  amplifierState3: any;
  amplifierState4: any;
  amplifierState5: any;
  amplifierState6: any;
  amplifierState7: any;
  amplifierState8: any;
  amplifierError1: any;
  amplifierError2: any;
  amplifierError3: any;
  amplifierError4: any;
  amplifierError5: any;
  amplifierError6: any;
  amplifierError7: any;
  amplifierError8: any;
  driverError1: any;
  driverError2: any;
  driverError3: any;
  driverError4: any;
  driverError5: any;
  driverError6: any;
  driverError7: any;
  driverError8: any;
  doorStatus: any;
  cityNetworkInformationStatus: any;
  pvSolarPanelStatusInformation: any;
  acChargerErrorStatusInformation: any;
  pvChargerErrorStatusInformation: any;
  tcpIpConnectionStatusInformation: any;
  aircraftWarningLambStatusInformation: any;
  radioLinkStatusInformation: any;
  endPointWorkingMode: any;
  location: any;
  colorBatteryVoltage: any;
  colorCabinetTemperature: any;
  colorGpsStatus: any;
  colorAmplifierState1: any;
  colorAmplifierError1: any;
  colorAmplifierError2: any;
  colorAmplifierError3: any;
  colorAmplifierError4: any;
  colorAmplifierError5: any;
  colorAmplifierError6: any;
  colorAmplifierError7: any;
  colorAmplifierError8: any;
  colorDriverError1: any;
  colorDriverError2: any;
  colorDriverError3: any;
  colorDriverError4: any;
  colorDriverError5: any;
  colorDriverError6: any;
  colorDriverError7: any;
  colorDriverError8: any;
  colorDoorStatus: any;
  colorCityNetworkInformationStatus: any;
  colorPvSolarPanelStatusInformation: any;
  colorAcChargerErrorStatusInformation: any;
  colorPvChargerErrorStatusInformation: any;
  colorTcpIpConnectionStatusInformation: any;
  colorAircraftWarningLambStatusInformation: any;
  colorRadioLinkStatusInformation: any;
  colorEndPointWorkingMode: any;
  connectionStatus: any;

  isCollapsed: boolean[] = [true, true, true, true];
  statusColor: string[] = ['', '', '', ''];

  // keeps the active tab
  activeTab: string;

  amplifierStateMap: string[] = ['Pasif', 'Aktif'];

  amplifierErrorMap: string[] = ['Normal', 'Aşırı Akım'];
  driverErrorMap: string[] = ['Normal', 'Hata'];
  doorStatusMap: string[] = ['Kapalı', 'Açık'];
  gpsStatusMap: string[] = ['Gps Bağlı ve Veri Hazır', 'GPS Bağlı ve Veri Hazır Değil', 'Gps Arızası', 'Gps Takılı Değil'];
  statusMap: string[] = ['Normal', 'Arıza'];
  cityNetworkStatusMap: string[] = ['Var', 'Yok'];
  radioLinkStatusMap: string[] = ['Normal', 'Telsize Ulaşılamıyor'];
  endPointWorkingModeMap: string[] = ['Normal Mod', 'Servis Dışı Modu'];
  pvSolarPanelStatusMap: string[] = ['Çalışıyor', 'Çalışmıyor'];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Sets the active tab based on the tab selection event.
   * @param event the tab selection event
   * */
  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  protected mapContextData(context: any): void {

  this.entity =  context.entity ? context.entity.entity : context.result.entity;

    this.sensorUnitId = super.getValue(this.entity, 'sensorUnitId');
    this.description = super.getValue(this.entity, 'description');
    this.status = super.getValue(this.entity, 'status');
    this.sensorType = super.getValue(this.entity, 'sensorType') === 104 ? 'YSSM' : 'SKB';
    this.rtuId = super.getValue(this.entity, 'rtuId');
    this.speakerVolume1 = super.getValue(this.entity, 'speakerVolume1');
    this.speakerVolume2 = super.getValue(this.entity, 'speakerVolume2');
    this.speakerVolume3 = super.getValue(this.entity, 'speakerVolume3');
    this.speakerVolume4 = super.getValue(this.entity, 'speakerVolume4');
    this.batteryVoltage = super.getValue(this.entity, 'batteryVoltage');
    this.cabinetTemperature = super.getValue(this.entity, 'cabinetTemperature');
    this.lowBatteryThresholdValue = 22;
    this.veryLowBatteryThresholdValue = 20;
    this.lowTemperatureThresholdValue = 5;
    this.highTemperatureThresholdValue = 50;
    this.location = super.getValue(this.entity, 'location').coordinates.join(', ');
    this.gpsStatus = super.getValue(this.entity, 'gpsStatus');
    this.amplifierState1 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 0)) % 10);
    this.amplifierState2 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 1)) % 10);
    this.amplifierState3 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 2)) % 10);
    this.amplifierState4 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 3)) % 10);
    this.amplifierState5 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 4)) % 10);
    this.amplifierState6 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 5)) % 10);
    this.amplifierState7 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 6)) % 10);
    this.amplifierState8 = Math.floor((super.getValue(this.entity, 'amplifierState') / Math.pow(10, 7)) % 10);
    this.amplifierError1 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 0)) % 10);
    this.amplifierError2 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 1)) % 10);
    this.amplifierError3 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 2)) % 10);
    this.amplifierError4 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 3)) % 10);
    this.amplifierError5 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 4)) % 10);
    this.amplifierError6 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 5)) % 10);
    this.amplifierError7 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 6)) % 10);
    this.amplifierError8 = Math.floor((super.getValue(this.entity, 'amplifierError') / Math.pow(10, 7)) % 10);
    this.driverError1 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 0)) % 10);
    this.driverError2 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 1)) % 10);
    this.driverError3 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 2)) % 10);
    this.driverError4 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 3)) % 10);
    this.driverError5 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 4)) % 10);
    this.driverError6 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 5)) % 10);
    this.driverError7 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 6)) % 10);
    this.driverError8 = Math.floor((super.getValue(this.entity, 'driverError') / Math.pow(10, 7)) % 10);
    this.doorStatus = super.getValue(this.entity, 'doorStatus');
    this.cityNetworkInformationStatus = super.getValue(this.entity, 'cityNetworkInformationStatus');
    this.pvSolarPanelStatusInformation = super.getValue(this.entity, 'pvSolarPanelStatusInformation');
    this.acChargerErrorStatusInformation = super.getValue(this.entity, 'acChargerErrorStatusInformation');
    this.pvChargerErrorStatusInformation = super.getValue(this.entity, 'pvChargerErrorStatusInformation');
    this.tcpIpConnectionStatusInformation = super.getValue(this.entity, 'tcpIpConnectionStatusInformation');
    this.aircraftWarningLambStatusInformation = super.getValue(this.entity, 'aircraftWarningLambStatusInformation');
    this.radioLinkStatusInformation = super.getValue(this.entity, 'radioLinkStatusInformation');
    this.endPointWorkingMode = super.getValue(this.entity, 'endPointWorkingMode');
    this.highTemperatureThresholdValue = super.getValue(this.entity, 'highTemperatureThresholdValue');
    this.lowTemperatureThresholdValue = super.getValue(this.entity, 'lowTemperatureThresholdValue');
    this.lowBatteryThresholdValue = super.getValue(this.entity, 'lowBatteryThresholdValue');
    this.veryLowBatteryThresholdValue = super.getValue(this.entity, 'veryLowBatteryThresholdValue');

    this.checkStatus();
    this.checkAmplifier();
    this.checkDriverError();
    this.checkBitResults();
    this.checkAmplifiersColor();
    this.checkDriversColor();
    this.checkBatteryColor();
    this.checkTemperatureColor();
    this.checkGps();
    this.checkDoorStatus();
    this.checkACCharger();
    this.checkPVCharger();
    this.checkCityNetwork();
    this.checkPVSolarPanel();
    this.checkTCPIP();
    this.checkWarningLamb();
    this.checkRadioLink();
    this.checkWorkingMode();
    this.checkConnectionStatus();
  }

  setVisibility(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }

  checkStatus() {
    // Status
    if (this.status === 1) {
      this.statusColor[0] = '-red';
    } else {
      this.statusColor[0] = '';
      if (this.statusColor[0] !== '-red') {
        this.statusColor[0] = '';
      }
    }
  }

  checkAmplifier() {
    // Amplifier
    if (this.checkAmplifierError()) {
      this.statusColor[1] = '-red';
    } else {
      this.statusColor[1] = '';
      if (this.statusColor[1] !== '-red') {
        this.statusColor[1] = '';
      }
    }
  }

  checkDriverError() {
    // Driver Error
    if (super.getValue(this.entity, 'driverError') &&  super.getValue(this.entity, 'driverError').includes('1')) {
      this.statusColor[2] = '-red';
    } else {
      this.statusColor[2] = '';
      if (this.statusColor[2] !== '-red') {
        this.statusColor[2] = '';
      }
    }
  }

  checkBitResults() {
    // Bit Results
    if (this.checkGps() || this.checkDoorStatus() || this.checkACCharger()
    || this.checkPVCharger() ||  this.checkCityNetwork() || this.checkPVSolarPanel() || this.checkTCPIP() ||  this.checkWarningLamb()
    || this.checkRadioLink() || this.checkWorkingMode()) {

      this.statusColor[3] = '-red';
    } else {

      if (this.cabinetTemperature >= this.highTemperatureThresholdValue || this.batteryVoltage <= this.veryLowBatteryThresholdValue) {
        this.statusColor[3] = '-red';
      } else if (this.cabinetTemperature < this.lowTemperatureThresholdValue || this.batteryVoltage < this.lowBatteryThresholdValue) {
        this.statusColor[3] = '-yellow';
      } else {
        this.statusColor[3] = '';
        if (this.statusColor[3] !== '-red') {
          this.statusColor[3] = '';
        }
      }
    }
  }
  checkAmplifierError() {
    if (super.getValue(this.entity, 'amplifierError') && super.getValue(this.entity, 'amplifierError').includes('1')) {
      return true;
    } else {
      return false;
    }
  }

  checkWorkingMode() {
    if (this.endPointWorkingMode === 1) {
      this.colorEndPointWorkingMode = '-red';
      return true;
    } else {
      this.colorEndPointWorkingMode = '';
      return false;
    }
  }


   checkRadioLink() {
     if (this.radioLinkStatusInformation === 1) {
       this.colorRadioLinkStatusInformation = '-red';
       return true;
     } else {
       this.colorRadioLinkStatusInformation = '';
       return false;
     }
  }

   checkPVCharger() {
     if (this.pvChargerErrorStatusInformation === 1) {
       this.colorPvChargerErrorStatusInformation = '-red';
       return true;
     } else {
       this.colorPvChargerErrorStatusInformation = '';
       return false;
     }
  }

   checkCityNetwork() {
     if (this.cityNetworkInformationStatus === 1) {
       this.colorCityNetworkInformationStatus = '-red';
       return true;
     } else {
       this.colorCityNetworkInformationStatus = '';
       return false;
     }
  }

   checkPVSolarPanel() {
     if (this.pvSolarPanelStatusInformation === 1) {
       this.colorPvSolarPanelStatusInformation = '-red';
       return true;
     } else {
       this.colorPvSolarPanelStatusInformation = '';
       return false;
     }
  }

   checkDoorStatus() {
     if (this.doorStatus === 1) {
       this.colorDoorStatus = '-red';
       return true;
     } else {
       this.colorDoorStatus = '';
       return false;
     }
  }

   checkACCharger() {
     if (this.acChargerErrorStatusInformation === 1) {
       this.colorAcChargerErrorStatusInformation = '-red';
       return true;
     } else {
       this.colorAcChargerErrorStatusInformation = '';
       return false;
     }
  }

   checkWarningLamb() {
     if (this.aircraftWarningLambStatusInformation === 1) {
       this.colorAircraftWarningLambStatusInformation = '-red';
       return true;
     } else {
       this.colorAircraftWarningLambStatusInformation = '';
       return false;
     }
  }

    checkTCPIP() {
     if (this.tcpIpConnectionStatusInformation === 1) {
       this.colorTcpIpConnectionStatusInformation = '-red';
       return true;
     } else {
       this.colorTcpIpConnectionStatusInformation = '';
       return false;
     }
  }

  checkGps() {
    if (this.gpsStatus === 2) {
      this.colorGpsStatus = '-red';
      return true;
    } else {
      this.colorGpsStatus = '';
      return false;
    }
  }

   checkAmplifiersColor() {

    if (this.amplifierError1 === 1) {
      this.colorAmplifierError1 = '-red';
    } else {
      this.colorAmplifierError1 = '';
    }

    if (this.amplifierError2 === 1) {
      this.colorAmplifierError2 = '-red';
    } else {
      this.colorAmplifierError2 = '';
    }
    if (this.amplifierError3 === 1) {
      this.colorAmplifierError3 = '-red';
    } else {
      this.colorAmplifierError3 = '';
    }

    if (this.amplifierError4 === 1) {
      this.colorAmplifierError4 = '-red';
    } else {
      this.colorAmplifierError4 = '';
    }
    if (this.amplifierError5 === 1) {
      this.colorAmplifierError5 = '-red';
    } else {
      this.colorAmplifierError5 = '';
    }

    if (this.amplifierError6 === 1) {
      this.colorAmplifierError6 = '-red';
    } else {
      this.colorAmplifierError6 = '';
    }
    if (this.amplifierError7 === 1) {
      this.colorAmplifierError7 = '-red';
    } else {
      this.colorAmplifierError7 = '';
    }

    if (this.amplifierError8 === 1) {
      this.colorAmplifierError8 = '-red';
    } else {
      this.colorAmplifierError8 = '';
    }
  }

   checkDriversColor() {
     if (this.driverError1 === 1) {
       this.colorDriverError1 = '-red';
     } else {
       this.colorDriverError1 = '';
     }

     if (this.driverError2 === 1) {
       this.colorDriverError2 = '-red';
     } else {
       this.colorDriverError2 = '';
     }
     if (this.driverError3 === 1) {
       this.colorDriverError3 = '-red';
     } else {
       this.colorDriverError3 = '';
     }

     if (this.driverError4 === 1) {
       this.colorDriverError4 = '-red';
     } else {
       this.colorDriverError4 = '';
     }
     if (this.driverError5 === 1) {
       this.colorDriverError5 = '-red';
     } else {
       this.colorDriverError5 = '';
     }

     if (this.driverError6 === 1) {
       this.colorDriverError6 = '-red';
     } else {
       this.colorDriverError6 = '';
     }
     if (this.driverError7 === 1) {
       this.colorDriverError7 = '-red';
     } else {
       this.colorDriverError7 = '';
     }

     if (this.driverError8 === 1) {
       this.colorDriverError8 = '-red';
     } else {
       this.colorDriverError8 = '';
     }
  }

   checkTemperatureColor() {
    if (this.cabinetTemperature >= this.highTemperatureThresholdValue) {
      this.colorCabinetTemperature = '-red';
    } else if (this.cabinetTemperature < this.lowTemperatureThresholdValue) {
      this.colorCabinetTemperature = '-yellow';
    } else {
      this.colorCabinetTemperature = '';
    }
  }

   checkBatteryColor() {
     if (this.batteryVoltage <= this.veryLowBatteryThresholdValue) {
       this.colorBatteryVoltage = '-red';
     } else if (this.batteryVoltage < this.lowBatteryThresholdValue) {
       this.colorBatteryVoltage = '-yellow';
     } else {
       this.colorBatteryVoltage = '';
     }
  }

  checkConnectionStatus() {
    if (this.status === 9) {
      this.connectionStatus = 'Bağlantı Var';
    } else {
      this.connectionStatus = 'Bağlantı Yok';
    }
  }
}
