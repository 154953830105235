import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {KPIService} from '../meta/kpi.service';
import {NGSIQuery} from '../../../shared/models/ngsi/ngsi-query.model';
import {RestUtil} from '../../../shared/utils/rest-util';
import {ObjectUtil} from '../../../shared/utils/object-util';
import {KPI} from '../../../shared/models/kpi.model';
import {NGSIResult} from '../../../shared/models/ngsi/ngsi-result';
import {NGSIResultUtil} from '../../../shared/utils/ngsi-result-util';
import {NGSIGeoQuery} from '../../../shared/models/ngsi/ngsi-geo-query.model';
import {UrukTemporalQuery} from '../../../shared/models/query/uruk-temporal-query.model';
import {NGSIContext} from '../../../shared/models/generic/ngsi-context.model';
import {QueryType, QueryTypeUtil} from '../../../shared/enums/query-type';
import {NGSIQueryBlock} from '../../../shared/enums/ngsi-query.enum';
import {environment} from '../../../../environments/environment';
import {UrukAggregationQuery} from '../../../shared/models/ngsi/uruk-aggregation-query.model';
import {AuthService} from '../auth/auth.service';
import {PaginatedNgsiResults} from '../../../shared/models/ngsi/paginated-ngsi-results';
import {QueryOptions} from '../../../shared/models/ngsi/query-options';
import {UserService} from '../data/user.service';
import {query} from '@angular/animations';

/**
 * Communicates with the Context Broker
 */
@Injectable({
  providedIn: 'root'
})
export class ContextService {

  // Application-wise geological context. Whenever this is updated, all subscribers will execute a new NGSI query based on geological changes
  geographicalContext: BehaviorSubject<NGSIGeoQuery>;

  // Application-wise temporal context. Whenever this is updated, all subscribers will execute a new NGSI query based on time-related changes
  temporalContext: BehaviorSubject<UrukTemporalQuery>;

  private readonly cbrokerEndpoint = null;
  private readonly ingestionEndpoint = null;
  private readonly analyticsEndpoint = null;

  constructor(private kpiService: KPIService,
              private httpClient: HttpClient,
              private userService: UserService,
              private authService: AuthService) {
    this.cbrokerEndpoint = `${environment.server.contextBrokerApi}/realms/${this.authService.getRealmId()}/ngsi-ld/v1`;
    this.analyticsEndpoint = `${environment.server.analyticsApi}/realms/${this.authService.getRealmId()}`;
    this.ingestionEndpoint = `${environment.server.ingestionApi}/${this.authService.getRealmId()}/ngsi-ld/v1`;

    this.geographicalContext = new BehaviorSubject<NGSIGeoQuery>(null);
    this.temporalContext = new BehaviorSubject<UrukTemporalQuery>(UrukTemporalQuery.today());
  }

  /**
   * Returns entities for the given NGSI-LD context
   */
  getEntities(query: NGSIQuery, queryOptions: QueryOptions, cursorAfter: string = null, cursorBefore: string = null, disableTracking: boolean = false, filters: any = null, cBrokerEndpoint:string = null): Observable<PaginatedNgsiResults> {
    const extraParameters: any = {
      cursorPagination: true,
      cursorAfter,
      cursorBefore,
      options: queryOptions.keyValues ? NGSIQueryBlock.KEY_VALUES : undefined,
      contains: filters ? `${filters[0]},${filters[1]}` : undefined
    };
    let endpoint: string = cBrokerEndpoint ? cBrokerEndpoint : this.cbrokerEndpoint;
    if (!queryOptions.isTemporal) {
      endpoint += '/entities';
    } else {
      endpoint += '/temporal/entities';
    }
    return this.executeNGSIOperation(query, endpoint, false, extraParameters, true, disableTracking)
      .pipe(
        map(response => {
          const results: NGSIResult[] = NGSIResultUtil.mapEntities(response.body, queryOptions);
            if (query.limit) {
              const paginationResponse: [string, string] = this.extractPaginationInformation(response);
              return new PaginatedNgsiResults(results, ...paginationResponse);

            } else {
              return new PaginatedNgsiResults(results);
            }
          }
        )
      );
  }

  getEntitiesWithOffsetPagination(query: NGSIQuery, queryOptions: QueryOptions, page: number, pageSize: number, disableTracking: boolean = false): Observable<PaginatedNgsiResults> {
    const extraParameters: any = {
      cursorPagination: false,
      page: page,
      limit: pageSize,
      options: queryOptions.keyValues ? NGSIQueryBlock.KEY_VALUES : undefined
    };
    let endpoint: string = this.cbrokerEndpoint;
    if (!queryOptions.isTemporal) {
      endpoint += '/entities';
    } else {
      endpoint += '/temporal/entities';
    }
    return this.executeNGSIOperation(query, endpoint, false, extraParameters, true, disableTracking)
      .pipe(
        map(response => {
            const results: NGSIResult[] = NGSIResultUtil.mapEntities(response.body, queryOptions);
            if (query.limit) {
              const paginationResponse: [string, string] = this.extractPaginationInformation(response);
              return new PaginatedNgsiResults(results, ...paginationResponse);

            } else {
              return new PaginatedNgsiResults(results);
            }
          }
        )
      );
  }

  /**
   * Queries a single entity
   * @param query Query to fetch the entity
   * @param queryOptions Options to be used in the query
   * @param disableTracking Whether this call should be tracked or not
   * Both key-value and sysAttrs options cannot be applied at the same time as system attributes can only be included in the longer representation.
   * @param cBrokerEndpoint
   */
  getEntity(query: NGSIQuery, queryOptions: QueryOptions = new QueryOptions(), disableTracking: boolean = false , cBrokerEndpoint:string = null): Observable<NGSIResult> {
    const extraParameters: any = {};
    if (queryOptions.keyValues) {
      extraParameters.options = NGSIQueryBlock.KEY_VALUES;
    } else if (queryOptions.sysAttrs) {
      extraParameters.options = NGSIQueryBlock.SYS_ATTRS;
    }
    let endpoint: string = cBrokerEndpoint ? cBrokerEndpoint : this.cbrokerEndpoint;
    if (queryOptions.isTemporal) {
      endpoint += '/temporal/entities';
    } else {
      endpoint += '/entities';
    }
    return this.executeNGSIOperation(query, endpoint, true, extraParameters, false, disableTracking)
      .pipe(map(response => {
        return NGSIResultUtil.mapEntity(response, queryOptions);
      }));
  }

  /**
   * Executes the given aggregation query
   * @param query aggregation query to be executed
   * @param isTemporal whether the query is a temporal one or not
   * @param disableTracking
   */
  executeAggregation(query: UrukAggregationQuery, isTemporal = false, disableTracking: boolean = false): Observable<NGSIResult[]> {
    let endpoint: string = this.analyticsEndpoint;
    if (!isTemporal) {
      endpoint += '/entities';
    } else {
      endpoint += '/temporal/entities';
    }
    return this.executeNGSIOperation(query, endpoint, false, {options: NGSIQueryBlock.KEY_VALUES}, false, disableTracking).pipe(
      map(response => {
        // data level count corresponds to bucket aggregation count when there is no pipeline aggregation. In case of a pipeline aggregation, the number of data levels decreases
        // as much as the pipeline aggregation count
        const dataLevelCount: number = query.bucketAgg ? query.bucketAgg.length : 0 - (query.pipelineAgg ? query.pipelineAgg.length : 0);
        return NGSIResultUtil.mapAggregationResults(response, query.bucketAgg, dataLevelCount);
      })
    );
  }

  /**
   * Executes the given KPI after generating an NGSI query out of it.
   * @param kpi
   * @param extraParameters
   */
  executeKpiAsNGSIOperation(kpi: KPI, extraParameters?: any): Observable<any> {
    if (QueryTypeUtil.isAggregationQuery(kpi.queryType)) {
      return this.executeAggregation(kpi.getQueryAsAggregationQuery(), QueryTypeUtil.isTemporalQuery(kpi.queryType));
    } else {
      if (QueryTypeUtil.isRetrieveQuery(kpi.queryType)) {
        return this.getEntity(kpi.getQueryAsNgsiQuery(), new QueryOptions(QueryTypeUtil.isTemporalQuery(kpi.queryType)));
      } else {
        return this.getEntities(kpi.getQueryAsNgsiQuery(), new QueryOptions(QueryTypeUtil.isTemporalQuery(kpi.queryType)));
      }
    }
  }

  /**
   * Executes the given NGSI-LD query
   */
  private executeNGSIOperation(query: NGSIQuery | UrukAggregationQuery, endpoint: string, retrieveQuery: boolean, extraParameters?: any, paginated?: boolean, disableTracking?: boolean): Observable<any> {
    const options: any = {
      headers: RestUtil.defaultHeaders(),
      observe: paginated ? 'response' : 'body'
    };

    // if (disableTracking) {
    //   options.headers[RequestTrackerService.DISABLE_HEADER] = RequestTrackerService.DISABLE_HEADER;
    // }

    const parameters = query.createNGSIParameters();

    if (extraParameters) {
      Object.assign(parameters, extraParameters);
    }

    let url: string = endpoint;
    if (retrieveQuery) {
      // if a retrieve queries runs, we do not pass the id parameter as a query parameter but a path parameter
      delete parameters.id;
      url += (query.filter.id?.length > 0 ? '/' + query.filter.id[0] : '');
    }

    const urlParameters = RestUtil.createURLParameters(parameters);
    url += (urlParameters ? '?' + urlParameters : '');
    return this.httpClient.get<any[]>(url, options);
  }


  /**
   * Executes a KPI whose model is provided
   * @param kpi KPI to be executed
   * @param context The context in which given KPI should be executed
   * @param parameters
   */
  executeKPI(kpi: KPI, context: NGSIContext, parameters: any = {}): Observable<NGSIResult[]> {
    // make a copy of the context in order not to change the original values e.g. during the calculation of dates dynamically according to the temporal query
    context = new NGSIContext({...context});

    if (kpi.overrideIdQuery) {
      const id = context.idQuery;
      parameters.id = id;
    }

    if (kpi.overrideCompanyQuery){
      parameters.companyName = context.companyQuery;
    }

    // check the existence of geographical query parameters
    if (kpi.overrideGeoQuery) {
      // execute this KPI if a geographical query is defined within a given context; otherwise, the global geographical context will be used
      const geoQuery = context.geoQuery ? context.geoQuery.createNGSIParameters() : this.geographicalContext.value.createNGSIParameters();
      Object.assign(parameters, geoQuery);
    }

    // check the existence of temporal query parameters
    if (kpi.overrideTemporalQuery) {
      // execute this KPI if a geographical query is defined within a given context; otherwise, the global temporal context will be used
      const temporalQuery = context.temporalQuery ? context.temporalQuery.createNGSIParameters() : this.temporalContext.value.createNGSIParameters();
      Object.assign(parameters, temporalQuery);
    }

    let url = this.analyticsEndpoint + '/kpi/' + kpi.id;

    // append parameters if they exist
    if (!ObjectUtil.isEmpty(parameters)) {
      const urlParameters = RestUtil.createURLParameters(parameters);
      url += '?' + urlParameters;
    }

    return this.httpClient.get(url).pipe(map(response => {
      return this.parseNgsiResponse(response, kpi);
    }));
  }

  /**
   * Returns parsed NGSI response as a single result or as an array according to the query type of the KPI.
   * @param response
   * @param kpi
   * @param keyValue
   */
  public parseNgsiResponse(response: any, kpi: KPI, keyValue = false): NGSIResult[] {
    if (!kpi) {
      return [];
    }
    if (kpi.queryType === QueryType.RETRIEVE_ENTITY || kpi.queryType === QueryType.RETRIEVE_ENTITY_TEMPORAL) {
      return [NGSIResultUtil.mapEntity(response, new QueryOptions(kpi.queryType === QueryType.RETRIEVE_ENTITY_TEMPORAL, keyValue))];
    } else if (kpi.queryType === QueryType.QUERY_ENTITIES || kpi.queryType === QueryType.QUERY_ENTITIES_TEMPORAL) {
      return NGSIResultUtil.mapEntities(response, new QueryOptions(kpi.queryType === QueryType.QUERY_ENTITIES_TEMPORAL, keyValue));
    } else if (kpi.queryType === QueryType.AGGREGATION || kpi.queryType === QueryType.TEMPORAL_AGGREGATION) {
      return NGSIResultUtil.mapAggregationResults(response, kpi.getQueryAsAggregationQuery().bucketAgg, kpi.dimensions.length);
    } else if (kpi.queryType === QueryType.CROSS_QUERY || kpi.queryType === QueryType.CROSS_TEMPORAL_QUERY ) {
      return NGSIResultUtil.mapEntities(response, new QueryOptions(kpi.queryType === QueryType.CROSS_TEMPORAL_QUERY, keyValue));
    } else if (kpi.queryType === QueryType.CROSS_AGGREGATION || kpi.queryType === QueryType.CROSS_TEMPORAL_AGGREGATION) {
      const bucketAgg = [];
      const crossAgg  = kpi.getQueryAsCrossAggQuery();
      crossAgg.subQueries.forEach(agg => {
        bucketAgg.push(...agg.bucketAgg);
      });
      return NGSIResultUtil.mapAggregationResults(response, bucketAgg, kpi.dimensions.length);
    }
  }

  /**
   * Updates a specific instance of a temporal attribute
   * @param entityId
   * @param attribute
   * @param instanceId
   * @param newValue
   */
  updateTemporalAttribute(entityId: string, attribute: string, instanceId: string, newValue: any): Observable<any> {
    const url: string = this.cbrokerEndpoint + '/temporal/entities/' + entityId + '/attrs/' + attribute + '/' + instanceId;
    return this.httpClient.patch(url, newValue);
  }

  /**
   * Adds a temporal update for a entity instance
   * @param entityId
   * @param entityType
   * @param elementName
   * @param value
   */
  addTemporalUpdate(entityId: string, entityType: string, elementName: string, value: any): Observable<void> {
    const url: string = this.ingestionEndpoint + '/temporal/entities/' + entityId + '/attrs';
    const data: any = {
      id: entityId,
      type: entityType
    };
    data[elementName] = [{
      type: 'Property',
      value: value,
      asserter: {
        'type': 'Property',
        'value': this.userService.getUsername()
      }
    }];
    return this.httpClient.post<void>(url, data);
  }

  /**
   * Extracts pagination information from the Link header. First element of the array corresponds to "cursorBefore" and the scond element to "cursorAfter"
   * @param response
   * @private
   */
  private extractPaginationInformation(response: any): [string, string] {
    const paginationInfo: [string, string] = [null, null];
    const linkHeader: string = response.headers.get('Link');
    if (linkHeader) {
      const cursorInfo: string[] = linkHeader.split(',');
      cursorInfo.forEach(info => {
        const infoProps: string[] = info.split(';');
        const urlParams: string = infoProps[0].substring(infoProps[0].indexOf('?') + 1, infoProps[0].length - 1);
        urlParams.split('&').forEach(paramPair => {
          const paramInfo: string[] = paramPair.split('=');
          if (paramInfo[0] === 'cursorBefore') {
            paginationInfo[0] = paramInfo[1];
          } else if (paramInfo[0] === 'cursorAfter') {
            paginationInfo[1] = paramInfo[1];
          }
        });
      });
    }
    return paginationInfo;
  }
}
