import {Component} from '@angular/core';
import {EventService} from '../../../core/services/event.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <div class="w-100 btn btn-primary" (click)="drawShape()">Draw circle</div>
        <div class="w-100 btn btn-primary" (click)="clearShape()">Clear shapes</div>
        <div class="w-100 btn btn-primary" (click)="zoomIn()">Zoom in</div>
        <div class="w-100 btn btn-primary" (click)="zoomOut()">Zoom out</div>
        <div class="w-100 btn btn-primary" (click)="drawPolygon()">drawPolygon</div>
        <div class="w-100 btn btn-primary" (click)="drawRectangle()">drawRectangle</div>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  constructor(private eventService: EventService) {

  }

  drawShape() {
    this.eventService.broadcastDrawCircleEvent();
  }
  drawPolygon() {
    this.eventService.broadcastDrawPolygonEvent();
  }
  drawRectangle() {
    this.eventService.broadcastDrawRectangleEvent();
  }
  clearShape() {
    this.eventService.broadcastDrawRemoveEvent();
  }
  zoomIn(): void {
    this.eventService.broadcastZoomInEvent();
  }
  zoomOut(): void {
    this.eventService.broadcastZoomOutEvent();
  }
}
