import {QueryOperatorParam} from './query-operator-param.model';
import {BaseDataType} from '../../enums/base-data-type';

export class QueryOperatorParamValue extends QueryOperatorParam {
  constructor(public value: any) {
    super();
    this.jsonClass = 'QueryOperatorParamValue';
  }

  get columnName(): string {
    return '';
  }
}
