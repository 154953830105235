import {VisualizationSetting} from './visualization-setting.model';
import {BaseModel} from '../base/base-model.model';
import {NGSIPath} from '../ngsi/ngsi-path.model';
import {Persistable} from '../persistanble';

export abstract class Visualization extends BaseModel implements Persistable {

  /**
   * Name of the actual entity attribute that keeps the geographic coordinate information
   */
  locationAttribute: NGSIPath;

  /**
   * Custom visualization settings to be applied to the icon
   */
  settings?: VisualizationSetting[] = [];

  /**
   * Identifier of the page to be shown when the visualization entity is clicked
   */
  popupPageId?: string;


  protected constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.locationAttribute = new NGSIPath(data.locationAttribute);
    if (data.settings) {
      this.settings = data.settings.map(setting => new VisualizationSetting(setting));
    }
    this.popupPageId = data.popupPageId;
  }

  createPersistableObject(): any {
    const {settings, ...rest} = this;
    rest['settings'] = settings.map(setting => setting.createPersistableObject());
    return rest;
  }
}
