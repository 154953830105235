import {BaseMetaModel} from './base/base-meta-model.model';
import {GeoJsonFactory} from './factories/geo-json.factory';
import {GeoJson} from './geometry/geo-json.model';
import { Point } from './geometry/point.model';

/**
 * Indicates a geographic region
 */
export class Region extends BaseMetaModel {

  /**
   * Name of the region
   */
  name: string;

  /**
   * Category of the region which can be e.g. city, district, custom area and so on
   */
  category: String;

  /**
   * Identifier of the parent Region containing this Region, if at all
   */
  parentRegionId?: String;

  /**
   * Coordinates of the polygon forming the geographic area
   */
  coordinates: GeoJson;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.name = data.name;
    this.category = data.category;
    this.parentRegionId = data.parentRegionId;

    if (data.coordinates) {
      this.coordinates = GeoJsonFactory.create(data.coordinates);
    }
  }

  /**
   * Returns the center point of region.
   * @return the center point of region
   */
  public getCenter(): Point{
    switch (this.coordinates.type) {
      case 'Point':
        return this.coordinates as Point;
      case 'Polygon':
        return new Point({
          coordinates: GeoJson.getCenter(this.coordinates)
        });
    }
  }
}
