import { Component, Input } from '@angular/core';
import { NbDialogRef, NbLayoutModule, NbThemeModule } from '@nebular/theme';

@Component({
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent {
  @Input() body: string;
  @Input() title: string;
  @Input() id: number;

  constructor(protected dialogRef: NbDialogRef<CreateDialogComponent>) {  }

  cancel() {
    this.dialogRef.close({id: -1});
  }

  submit(object) {
    this.dialogRef.close(object);
  }
}
