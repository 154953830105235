import {NGSIQueryTerm} from './ngsi-query-term.model';
import {NGSIQueryTermAssoc} from './ngsi-query-term-assoc.model';
import {NGSIGeoQuery} from './ngsi-geo-query.model';
import {NGSITemporalQuery} from './ngsi-temporal-query.model';
import {BaseQuery} from './base-query.model';
import {NGSIQueryBlock, NGSISeparator} from '../../enums/ngsi-query.enum';
import {TimeUtil} from '../../utils/time-util';
import {NGSIQueryExpression} from './ngsi-query-expression.model';
import {Persistable} from '../persistanble';

/**
 * Contains filters that can be used in an NGSI query
 */
export class NGSIFilter extends BaseQuery implements Persistable {
  /**
   * Identifier of entity to be queried. Either id or type can exist in the same filter.
   */
  id?: string[];

  /**
   * Type of entity to be queried. Either id or type can exist in the same filter.
   */
  type?: string[];

  /**
   * Id patterns to be queried
   */
  idPattern?: string;

  /**
   * NGSI query
   */
  q?: NGSIQueryExpression;

  /**
   * NGSI geo query
   */
  geoQuery?: NGSIGeoQuery;

  /**
   * NGSI temporal query
   */
  temporalQuery?: NGSITemporalQuery;

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.id = data.id;
    this.type = data.type;
    this.idPattern = data.idPattern;
    if (data.q) {
      if (this.isNGSITerm(data.q)) {
        this.q = new NGSIQueryTerm(data.q);
      } else {
        this.q = new NGSIQueryTermAssoc(data.q);
      }
    }
    if (data.geoQuery) {
      this.geoQuery = new NGSIGeoQuery();
    }
    if (data.temporalQuery) {
      this.temporalQuery = new NGSITemporalQuery(data.temporalQuery);
    }
  }

  private isNGSITerm(queryData: any): boolean {
    if (queryData.value || queryData.valueRange) {
      return true;
    } else {
      return false;
    }
  }

  createNGSIParameters(): any {
    const parameters: any = {};
    if (this.id) {
      parameters[NGSIQueryBlock.ID] = this.id.join(NGSISeparator.VALUE);
    }

    if (this.type) {
      parameters[NGSIQueryBlock.TYPE] = this.type.join(NGSISeparator.VALUE);
    }

    if(this.idPattern){
      parameters[NGSIQueryBlock.ID_PATTERN] = this.idPattern;
    }

    if (this.temporalQuery) {
      parameters[NGSIQueryBlock.TIME_REL] = this.temporalQuery.timerel;
      parameters[NGSIQueryBlock.TIME] = TimeUtil.serializeISODatetimeUTC(this.temporalQuery.time);
      if (this.temporalQuery.endTime) {
        parameters[NGSIQueryBlock.END_TIME] = TimeUtil.serializeISODatetimeUTC(this.temporalQuery.endTime);
      }
    }

    if (this.geoQuery) {
      Object.assign(parameters, (this.geoQuery as NGSIGeoQuery).createNGSIParameters());
    }

    if (this.q) {
      Object.assign(parameters, {q: this.q.createNGSIParameters()});
    }

    return parameters;
  }



  /**
   * Returns the first type filter, if any
   */
  getFirstTypeFilter(): string {
    return this.type?.length > 0 ? this.type[0] : null;
  }

  createPersistableObject(): any {
    const {q, ...rest} = this;
    if (q) {
      rest['q'] = q.createPersistableObject();
    }
    return rest;
  }
}
