import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../../../../shared/components/dialog/base-dialog.component';

@Component({
  templateUrl: './defibrillator-infodialog.component.html',
  styleUrls: ['./defibrillator-infodialog.component.scss']
})
export class DefibrillatorInfoDialogComponent extends BaseDialogComponent implements OnInit {

  @Input() image: String;
  @Input() errorHeader: String;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {

  }
}
