<div class="leaflet-frame">
  <div class="leaflet-box" leaflet
       [leafletOptions]="mapOptions"
       (leafletMapReady)="onMapReady($event)"
       (leafletMapMoveEnd)="onMapMoveEnd($event)"
       (leafletZoomChange)="onZoomChange($event)">

    <div leafletDraw
         [leafletDrawOptions]="drawOptions"
         (leafletDrawReady)="onDrawReady($event)"
         (leafletDrawCreated)="onShapeCreated($event)">
    </div>

    <ng-container *ngFor="let layer of layers">
      <ng-container *ngIf="layer.visibleOnMap">
        <div *ngIf="showMergedMarkers" [leafletLayers]="layer.mergedMarkers"></div>
      </ng-container>
    </ng-container>
  </div>
</div>
