


export enum ConnectionIcons {
  Connection0 = 'Connection0',
  Connection1 = 'Connection1',
  Connection2 = 'Connection2',
  ConnectionLost = 'ConnectionLost',
  DataStreaming = 'DataStreaming',
}

export class DefibrillatorConnection {

}


