import {AfterViewInit, Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MapComponent} from '../map/map.component';
import {Coordinate} from '../../models/generic/coordinate.model';
import {NGSIGeoQuery} from '../../models/ngsi/ngsi-geo-query.model';
import {BaseDialogComponent} from './base-dialog.component';
import {EventService} from '../../../core/services/event.service';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {MapFramework} from '../../enums/map-framework.enum';
import {Geometry, NGSIGeoRelation} from '../../enums/ngsi-query.enum';

/**
 * Base component to provide common map-related properties
 */
@Component({
  selector: 'location-selection-dialog',
  templateUrl: './location-selection-dialog.component.html',
  styleUrls: ['./location-selection-dialog.component.scss']
})
export class LocationSelectionDialogComponent extends BaseDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() coordinate: Coordinate;
  @Input() geoQuery: NGSIGeoQuery;

  realmGeoQuery: NGSIGeoQuery;
  countryGeoQuery: NGSIGeoQuery;

  @ViewChild('map') urukmap: MapComponent;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // get realm and region details
    this.realmService.getRealm(this.authService.getRealmId()).subscribe(realm => {
      this.regionService.getRegion(realm.regionId).subscribe(region => {
        this.realmGeoQuery = this.createGeoQuery([(region.coordinates as any).coordinates[0].map(item => item.reverse())]);
      });
    });

    // initialize country geo query
    this.countryGeoQuery = this.createGeoQuery([[[environment.map.bounds[0], environment.map.bounds[3]],
      [environment.map.bounds[2], environment.map.bounds[3]],
      [environment.map.bounds[2], environment.map.bounds[1]],
      [environment.map.bounds[0], environment.map.bounds[1]],
      [environment.map.bounds[0], environment.map.bounds[3]]]]);
  }

  ngAfterViewInit() {
    if (environment.map.framework === MapFramework.LEAFLET) {
      this.setMapObjects();
    }
    // Maplibre requires to be initialized in ngAfterViewInit
    else if (environment.map.framework === MapFramework.MAPLIBRE) {
      this.handleMapInitialization();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleMapInitialization() {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.MAP_INITIALIZED: {
            this.setMapObjects();
          }
        }
      });
  }

  setMapObjects() {
    if (this.coordinate != null) {
      this.urukmap.map.addIconMarker(this.coordinate);
    }
    if (this.geoQuery != null) {
      this.urukmap.map.addGeoQueryAsShape(this.geoQuery);

      // zoom to the geoQuery
      const longitudeArray = this.geoQuery.coordinates[0].map(coordinates => coordinates[0]);
      const midLongitude = longitudeArray.reduce((a, b) => a + b, 0) / longitudeArray.length;

      const latitudeArray = this.geoQuery.coordinates[0].map(coordinates => coordinates[1]);
      const midLatitude = latitudeArray.reduce((a, b) => a + b, 0) / latitudeArray.length;

      const coordinate = new Coordinate({
        latitude: midLatitude,
        longitude: midLongitude
      });
      this.urukmap.map.panAndZoom(coordinate, 8);
    }
  }

  onAreaSelected(geoQuery: NGSIGeoQuery): void {
    // broadcast here
    this.eventService.broadcastShapeChangedEvent(geoQuery);
    this.closeDialog();
  }

  createGeoQuery(coordinates: any) {
    const geoQuery = new NGSIGeoQuery();
    geoQuery.geometry = Geometry.POLYGON;
    geoQuery.coordinates = coordinates;
    geoQuery.georel = NGSIGeoRelation.INTERSECTS;
    return geoQuery;
  }
}
