export class TractionModel {
  fDriveForceAx3: any;
  fDriveForceAx4: any;
  requiredTractionForce: any;
  forceAppliedAx3: any;
  forceAppliedAx4: any;
  appliedTractionForce: any;
  cutOffTraction: any;
  cutOffByPass: any;
  tractionNull: any;
  motorCooling2mvcbProtection: any;
  tempTrcInv1: any[];
  tempTrcInv2: any[];
  tempTrcRec: any[];
  vTrcDclink: any;
  tempMotorAx3: any[];
  tempMotorAx4: any[];
  forceAvmaxAx3: any[];
  forceAvmaxAx4: any[];
  velMotorAx3: any[];
  velMotorAx4: any[];
  edBar: any[];
  tractionBar: any;
  tractionCutOffLed: any;
  tractionDcLinkOk: any[];

  constructor(data: any) {
    this.fDriveForceAx3 = data.fDriveForceAx3;
    this.fDriveForceAx4 = data.fDriveForceAx4;
    this.requiredTractionForce = data.requiredTractionForce;
    this.forceAppliedAx3 = data.forceAppliedAx3;
    this.forceAppliedAx4 = data.forceAppliedAx4;
    this.appliedTractionForce = data.appliedTractionForce;
    this.cutOffTraction = data.cutOffTraction;
    this.cutOffByPass = data.cutOffByPass;
    this.tractionNull = data.tractionNull;
    this.motorCooling2mvcbProtection = data.motorCooling2mvcbProtection;
    this.tempTrcInv1 = data.tempTrcInv1;
    this.tempTrcInv2 = data.tempTrcInv2;
    this.tempTrcRec = data.tempTrcRec;
    this.vTrcDclink = data.vTrcDclink;
    this.tempMotorAx3 = data.tempMotorAx3;
    this.tempMotorAx4 = data.tempMotorAx4;
    this.forceAvmaxAx3 = data.forceAvmaxAx3;
    this.forceAvmaxAx4 = data.forceAvmaxAx4;
    this.velMotorAx3 = data.velMotorAx3;
    this.velMotorAx4 = data.velMotorAx4;
    this.edBar = data.edBar;
    this.tractionBar = data.tractionBar;
    this.tractionCutOffLed = data.tractionCutOffLed;
    this.tractionDcLinkOk = data.tractionDcLinkOk;
  }
}
