import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {EventService} from '../../core/services/event.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../core/services/layout.service';
import {NbDialogService} from '@nebular/theme';
import {MenuService} from '../../@theme/services/menu/menu.service';
import {StructureDefinitionService} from '../../core/services/meta/structure-definition.service';
import {KPIService} from '../../core/services/meta/kpi.service';
import {OperatorDefinitionService} from '../../core/services/meta/operator-definition.service';
import {AuthService} from '../../core/services/auth/auth.service';
import {RealmService} from '../../core/services/meta/realm.service';
import {RegionService} from '../../core/services/meta/region.service';
import {TagService} from '../../core/services/meta/tag.service';
import {LayerService} from '../../core/services/meta/layer.service';
import {PageService} from '../../core/services/meta/page.service';
import {UserService} from '../../core/services/data/user.service';
import {UserManagementService} from '../../core/services/meta/user-management.service';
import {KpiExecutionService} from '../../core/services/kpi-execution.service';
import {ContextService} from '../../core/services/http/context.service';
import {UserPreferenceService} from '../../core/services/meta/user-preference.service';
import {ContextSourceService} from '../../core/services/meta/context-source.service';
import {PageTemplateService} from '../../core/services/meta/page-template.service';
import {SocketService} from '../../core/services/http/socket.service';
import {environment} from '../../../environments/environment';
import {AnalyticsService} from '../../core/services/meta/analytics.service';
import {PatternService} from '../../core/services/meta/pattern.service';

/**
 * Base component to provide common properties
 */
@Component({
  template: '',
})
export abstract class BaseComponent implements OnInit, OnDestroy {

  // Services used in derived components
  protected eventService: EventService;
  protected translateService: TranslateService;
  protected layoutService: LayoutService;
  protected menuService: MenuService;
  protected dialogService: NbDialogService;
  protected structureDefinitionService: StructureDefinitionService;
  protected kpiService: KPIService;
  protected realmService: RealmService;
  protected regionService: RegionService;
  protected tagService: TagService;
  protected layerService: LayerService;
  protected pageService: PageService;
  protected pageTemplateService: PageTemplateService;
  protected ngsiService: OperatorDefinitionService;
  protected userService: UserService;
  protected userPreferenceService: UserPreferenceService;
  protected userManagementService: UserManagementService;
  protected authService: AuthService;
  protected kpiExecutionService: KpiExecutionService;
  protected contextService: ContextService;
  protected contextSourceService: ContextSourceService;
  protected socketService: SocketService;
  protected analyticsService: AnalyticsService;
  protected patternService: PatternService;

  // Subscription object for unsubscribing when destroying the component to avoid leaks
  destroy$ = new Subject<void>();

  constructor(injector: Injector) {
    this.eventService = injector.get(EventService);
    this.translateService = injector.get(TranslateService);
    this.layoutService = injector.get(LayoutService);
    this.menuService = injector.get(MenuService);
    this.dialogService = injector.get(NbDialogService);
    this.structureDefinitionService = injector.get(StructureDefinitionService);
    this.kpiService = injector.get(KPIService);
    this.ngsiService = injector.get(OperatorDefinitionService);
    this.authService = injector.get(AuthService);
    this.realmService = injector.get(RealmService);
    this.regionService = injector.get(RegionService);
    this.tagService = injector.get(TagService);
    this.layerService = injector.get(LayerService);
    this.pageService = injector.get(PageService);
    this.pageTemplateService = injector.get(PageTemplateService);
    this.userService = injector.get(UserService);
    this.userPreferenceService = injector.get(UserPreferenceService);
    this.userManagementService = injector.get(UserManagementService);
    this.kpiExecutionService = injector.get(KpiExecutionService);
    this.contextService = injector.get(ContextService);
    this.contextSourceService = injector.get(ContextSourceService);
    this.socketService = injector.get(SocketService);
    this.analyticsService = injector.get(AnalyticsService);
    this.patternService = injector.get(PatternService);
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
