import {Component, Injector, OnInit} from '@angular/core';
import {CreatePageDialogComponent} from '../../dialog/create-page-dialog/create-page-dialog.component';
import {BasePageComponent} from '../../../shared/components/base-page.component';

/**
 * The default template for pages module. It is used when the active user has no page.
 * */
@Component({
  selector: 'no-page',
  templateUrl: './no-page.component.html',
  styleUrls: ['./no-page.component.scss'],
  host: {
    class: 'w-100 h-100'
  }
})
export class NoPageComponent extends BasePageComponent implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.menuService.setMenuVisibility(true);
  }

  /**
   * Opens {@link CreatePageDialogComponent} to allow users to create new pages
   * */
  openCreatePageDialog() {
    this.dialogService.open(CreatePageDialogComponent);
  }
}
