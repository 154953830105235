export class BaseModel {
  jsonClass: string;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.jsonClass = data.jsonClass;
  }
}
