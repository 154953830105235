import {AggregationResult} from './aggregation-result';
import {KPI} from '../../kpi.model';
import {OperatorType} from '../../../enums/ngsi-query.enum';
import {QueryOperator} from '../../query/query-operator.model';
import {UrukAggregationQuery} from '../../ngsi/uruk-aggregation-query.model';
import {ObjectUtil} from '../../../utils/object-util';

/**
 * Represents any bucket result such as
 [
 {
		"key": {
	      "name": "X OTOPARKI",
	      "id": "..."
	    },
		"value": {
		  "avg_availableSpotNumber": 7,
		  "sum_avaialableSpotNumber": 150
		}
	},
 ...
 ]

 or

 [
 {
    "key": {
      "name": "X OTOPARKI",
      "id": "..."
    },
    "bucket": [
      {
        "key": {
          "ts": "..."
        },
        "value": {
          "avg_availableSpotNumber": 7,
          "sum_avaialableSpotNumber": 150
        }
      },
      ...
     ]
    },
 ...

 or

 [
 {
    "key": {
      "name": "X OTOPARKI",
      "id": "..."
    },
    "bucket": [
      {
        "key": {
          "ts": "..."
        },
        "bucket": [
        	{
        		"key": {
        			"ts": "..."
        		},
        		"value": {
		          "avg_availableSpotNumber": 7,
		          "sum_avaialableSpotNumber": 150
		        }
        	},
        	...
        ]
      },
      ...
    ]
  },
 ...

 */
export class BucketResult {
  /**
   * Key object for the bucket. It would usually be a timestamp but in case of group by buckets, it can contain multiple fields like:
   "key": {
      "name": "X OTOPARKI",
      "id": "..."
    },
   *
   */
  key: any;

  /**
   * Inner bucket results for this bucket result. This field is mutually exclusive with the aggregation result
   */
  bucketResults?: BucketResult[];

  /**
   * If the bucket has no inner buckets, it should have an aggregation result.
   */
  aggregationResult?: AggregationResult;

  /**
   * Transforms raw NGSI response to a list of {@link BucketResult}s.
   * @param data Raw NGSI response
   * @param bucketAggregations aggregations for which the results are generated
   * @param dataLevelCount number of levels in the resultant data. This differentiates from the bucket aggregation count in case of a pipeline aggregation
   */
  public static createBuckets(data: any, bucketAggregations: QueryOperator[], dataLevelCount: number): BucketResult[] {
    const results: BucketResult[] = data.map(result => {
      return BucketResult.createBucketsRecursively(result, bucketAggregations, dataLevelCount);
    });
    // sort the results as they are returned in mixed order for certain type of bucketing operations such time interval or season
    const operator: string = bucketAggregations[0].op;
    BucketResult.sortBuckets(results, operator);

    return results;
  }

  /**
   * Transforms raw NGSI response to a {@link BucketResult}.
   * @param data Raw NGSI data for a single bucket
   * @param bucketAggregations Bucket aggregations corresponding to each dimension in the NGSI response
   * @param totalDimensionCount Total number of dimensions in the response
   * @param dimensionIndex Index of the current dimension being processed during the recursive processing
   * @private
   */
  private static createBucketsRecursively(data: any, bucketAggregations: QueryOperator[], totalDimensionCount: number, dimensionIndex: number = 0): BucketResult {
    const bucket: BucketResult = new BucketResult();
    bucket.key = data.key;
    // special processing for geohash aggregation results to reverse the coordinate information
    if (bucket.key.geohash_location) {
      bucket.key.geohash_location.coordinates = bucket.key.geohash_location.coordinates.reverse();
    }

    // parse the inner buckets until reaching the last level in the NGSI response
    if (dimensionIndex < totalDimensionCount - 1) {
      bucket.bucketResults = data.bucket.map(subBucket => BucketResult.createBucketsRecursively(subBucket, bucketAggregations, totalDimensionCount, dimensionIndex + 1));
      const operator: string = bucketAggregations[dimensionIndex + 1].op; // +1, because we are sorting the results of the one deeper level
      BucketResult.sortBuckets(bucket.bucketResults, operator);

      // reached last dimension. Put the value into an AggregationResult
    } else {
      bucket.aggregationResult = new AggregationResult(data.value);
    }

    return bucket;
  }

  /**
   * Sorts the bucket values according to the operator generated the values.
   * @param results Results to be sorted
   * @param operator NGSI aggregation operator that is used to generate the bucket values
   * @private
   */
  private static sortBuckets(results: BucketResult[], operator: string): void {
    if (operator === OperatorType.TIME_INTERVAL) {
      results
        .sort((a: BucketResult, b: BucketResult) => {
          return (a.key.ts > b.key.ts) ? 1 : ((b.key.ts > a.key.ts) ? -1 : 0);
        });
    } else if (operator === OperatorType.SEASONAL) {
      results
        .sort((a: BucketResult, b: BucketResult) => {
          return a.key.season - b.key.season;
        });
    }
  }

  getGeohashCoordinates(): number[] {
    if (this.key.geohash_location) {
      return this.key.geohash_location.coordinates;
    } else {
      return null;
    }
  }

  /**
   * Determines whether bucket result is empty or not.
   */
  isEmpty(): boolean {
    if (this.aggregationResult) {
      const aggregationValue = ObjectUtil.getFirstValue(this.aggregationResult.result);
      return aggregationValue === undefined || aggregationValue === null;
    } else if (this.bucketResults?.length) {
      return !this.bucketResults.some(bucketResult => !bucketResult.isEmpty());
    }
    return true;
  }
}

