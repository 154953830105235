<closeable-dialog class="train-dialog-container">
  <div class="dialog-header" dialog-card-header [ngStyle]="{'color': hasError ? '#FF1F00' : 'white'}">
    <span class="column-info title">{{ 'trainNumber' | translate }}</span> :
    <span class="column-info content">{{ trainId }}</span> &nbsp;
    <div class="badge" [ngClass]="'train-badge-' + trainStatus">
      {{ 'train-badge-' + trainStatus | translate }}
    </div>
  </div>

  <div dialog-card-body class="dialog-body full-height">
    <div class="main-svg-container">
      <div class="svg-container" id="svgContainer"></div>
      <i [ngStyle]="getBackIconStyle()" class="fa fa-angle-left back-icon"
         (click)="goBack()">&nbsp;{{ 'back' | translate }}</i>

      <div *ngIf="systemLabels[activeTab] && this.previousSVGPath === null">
        <div class="label-container">
          <ng-container *ngFor="let item of systemLabels[activeTab]">
            <label class="status-label">
              <span class="status-color" [ngStyle]="{'background-color': item.color}"></span>
              <span class="status-text">{{ item.label | translate }}</span>
            </label>
          </ng-container>
        </div>
      </div>
    </div>

    <nb-tabset class="main-nb-tabset" (changeTab)="onMainTabChange($event)">
      <nb-tab
        [tabIcon]="diagnosticSystem ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
        tabTitle="{{ 'diagnosticSystem' | translate }}"
        [active]="activeTab === 'diagnosticSystem'"
        [tabId]="'diagnosticSystem'"
        class="test">
        <nb-tabset class="diagnostic-nb-tabset" (changeTab)="onTabChange($event)">
          <nb-tab
            [tabIcon]="tractionSystem ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{ 'tractionSystem' | translate }}"
            [active]="activeTab === 'tractionSystem'"
            [tabId]="'tractionSystem'">
            <div style="height: 410px">
              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'requiredTractionForce' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.tractionModel?.requiredTractionForce || 'N/A' }} kN</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'appliedTractionForce' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.tractionModel?.appliedTractionForce || 'N/A' }} kN</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'fDriveForceAx3' | translate }} :&nbsp; </label>
                  <label class="column-info content">{{ trainInfo?.tractionModel?.fDriveForceAx3 || 'N/A' }} kN</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'fDriveForceAx4' | translate }} :&nbsp; </label>
                  <label class="column-info content">{{ trainInfo?.tractionModel?.fDriveForceAx4 || 'N/A' }} kN</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'forceAppliedAx3' | translate }} :&nbsp; </label>
                  <label class="column-info content">{{ trainInfo?.tractionModel?.forceAppliedAx3 || 'N/A' }} kN</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'forceAppliedAx4' | translate }} :&nbsp; </label>
                  <label class="column-info content">{{ trainInfo?.tractionModel?.forceAppliedAx4 || 'N/A' }} kN</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'cutOffTraction' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('tractionSystemRules', 'cutOffTraction', trainInfo?.tractionModel?.cutOffTraction) }">
                    {{ isVehicleMove(trainInfo?.tractionModel?.cutOffTraction) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'cutOffByPass' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('tractionSystemRules', 'cutOffByPass', trainInfo?.tractionModel?.cutOffByPass) }">
                    {{ getCutOffByPassStatus(trainInfo?.tractionModel?.cutOffByPass) || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'tractionNull' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('tractionSystemRules', 'tractionNull', trainInfo?.tractionModel?.tractionNull) }">
                    {{ isVehicleMove(trainInfo?.tractionModel?.tractionNull) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'motorCooling2mvcbProtection' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[11]">{{ trainInfo?.tractionModel?.motorCooling2mvcbProtection || 'N/A' | translate }} </label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 2">
                <div class="flex-50">
                  <label class="column-info title">{{ 'tempTrcInv1' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[9]">
                    {{ trainInfo?.tractionModel?.tempTrcInv1?.[selectedWagonIndex]  }} &deg;C </label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'tempTrcInv2' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.tractionModel?.tempTrcInv2?.[selectedWagonIndex] }} &deg;C </label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 2">
                <div class="flex-50">
                  <label class="column-info title">{{ 'tempTrcRec' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.tractionModel?.tempTrcRec?.[selectedWagonIndex] }} &deg;C </label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'vTrcDclink' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[6]">
                    {{ trainInfo?.tractionModel?.vTrcDclink?.[selectedWagonIndex] }} V</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'tempMotorAx3' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[5]">
                    {{ trainInfo?.tractionModel?.tempMotorAx3?.[selectedWagonIndex] }} &deg;C </label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'tempMotorAx4' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[10]">
                    {{ trainInfo?.tractionModel?.tempMotorAx4?.[selectedWagonIndex] }} &deg;C </label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title">{{ 'forceAvmaxAx3' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.tractionModel?.forceAvmaxAx3?.[selectedWagonIndex] / 1000 }} kN</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'forceAvmaxAx4' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[7]">
                    {{ trainInfo?.tractionModel?.forceAvmaxAx4?.[selectedWagonIndex] / 1000 }} kN</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title">{{ 'velMotorAx3' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.tractionModel?.velMotorAx3?.[selectedWagonIndex] }} rpm</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'velMotorAx4' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[8]">
                    {{ trainInfo?.tractionModel?.velMotorAx4?.[selectedWagonIndex] }} rpm</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'edBar' | translate }} :&nbsp;</label>
                  <label class="column-info content">
                    {{ trainInfo?.tractionModel?.edBar?.[selectedWagonIndex] }} kN </label>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="brakeSystem ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{ 'brakeSystem' | translate }}"
            [active]="activeTab === 'brakeSystem'"
            [tabId]="'brakeSystem'">
            <div style="height: 410px">
              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'bp' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[2]">
                    {{ trainInfo?.brakeModel?.bp || 'N/A' }} bar</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'mrp' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[3]">
                    {{ trainInfo?.brakeModel?.mrp || 'N/A' }} bar</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'pressureGovernorPBReleased' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'pressureGovernorPBReleased', trainInfo?.brakeModel?.pressureGovernorPBReleased) }">
                    {{ isBrakeError('pressureGovernorPBReleased', trainInfo?.brakeModel?.pressureGovernorPBReleased) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">
                    {{ 'pressureGovernorDetectionEmergencyBrake' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'pressureGovernorDetectionEmergencyBrake', trainInfo?.brakeModel?.pressureGovernorDetectionEmergencyBrake) }">
                    {{ isBrakeError('pressureGovernorDetectionEmergencyBrake', trainInfo?.brakeModel?.pressureGovernorDetectionEmergencyBrake) || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">
                    {{ 'pressureGovernorIndirectBrakeControl' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'pressureGovernorIndirectBrakeControl', trainInfo?.brakeModel?.pressureGovernorIndirectBrakeControl) }">
                    {{ isBrakeError('pressureGovernorIndirectBrakeControl', trainInfo?.brakeModel?.pressureGovernorIndirectBrakeControl) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'diagBrDirect' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'diagBrDirect', trainInfo?.brakeModel?.diagBrDirect) }">
                    {{ isBrakeError('diagBrDirect', trainInfo?.brakeModel?.diagBrDirect) || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex" *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'majorBrPark' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'majorBrPark', trainInfo?.brakeModel?.majorBrPark) }">
                    {{ errorMap[trainInfo?.brakeModel?.majorBrPark] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 2 || tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'majorBrWsp' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'majorBrWsp', trainInfo?.brakeModel?.majorBrWsp?.[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.brakeModel?.majorBrWsp?.[selectedWagonIndex]] | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'ebcu' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'ebcu', trainInfo?.brakeModel?.ebcu?.[selectedWagonIndex]) }">
                    {{ errorMap1[trainInfo?.brakeModel?.ebcu?.[selectedWagonIndex]] | translate }}</label>
                </div>
              </div>

              <div class="d-flex" *ngIf="tabInfo === 2 || tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'majorBrPark' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'majorBrPark', trainInfo?.brakeModel?.majorBrParkList?.[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.brakeModel?.majorBrParkList?.[selectedWagonIndex]] | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'diagBrDirect' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'diagBrDirect', trainInfo?.brakeModel?.diagBrDirectList?.[selectedWagonIndex]) }">
                    {{ isBrakeError('diagBrDirect', trainInfo?.brakeModel?.diagBrDirectList?.[selectedWagonIndex]) | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 2 || tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'diagBrCPress' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('brakeSystemRules', 'diagBrCPress', trainInfo?.brakeModel?.diagBrCPress?.[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.brakeModel?.diagBrCPress?.[selectedWagonIndex]] | translate }}</label>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="powerVoltageSystem ? { icon: 'alert-triangle', options: { color: '#FF1F00', class: 'nb-tab-custom-icon' } } : null"
            tabTitle="{{ 'powerVoltageSystem' | translate }}"
            [active]="activeTab === 'powerVoltageSystem'"
            [tabId]="'powerVoltageSystem'">
            <div style="height: 410px">
              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'majorUnderVoltageError' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.powerVoltageModel?.majorUnderVoltageError || 'N/A' }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'catenaryVoltage' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.powerVoltageModel?.catenaryVoltage || 'N/A' }} kV</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'yuksekAkimKaynakliVakumHatasi' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'yuksekAkimKaynakliVakumHatasi', trainInfo?.powerVoltageModel?.yuksekAkimKaynakliVakumHatasi) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.yuksekAkimKaynakliVakumHatasi] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'hvConfigError' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'hvConfigError', trainInfo?.powerVoltageModel?.hvConfigError) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.hvConfigError] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'pantoABar' | translate }} :&nbsp; </label>
                  <label [ngClass]="'flex-50'+status[1]">
                    {{ trainInfo?.powerVoltageModel?.pantoABar || 'N/A' }} A</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'tumPanolarIzole' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tumPanolarIzole', trainInfo?.powerVoltageModel?.tumPanolarIzole) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.tumPanolarIzole] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'pantografDustu' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'pantografDustu', trainInfo?.powerVoltageModel?.pantografDustu) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.pantografDustu] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'diagPantoComp' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'diagPantoComp', trainInfo?.powerVoltageModel?.diagPantoComp) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.diagPantoComp] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'pTrain' | translate }} :&nbsp;</label>
                  <label class="column-info content">{{ trainInfo?.powerVoltageModel?.pTrain || 'N/A' }} kW</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'majorMvError' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'majorMvError', trainInfo?.powerVoltageModel?.majorMvError) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.majorMvError] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'vcbCloseSka1' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'vcbCloseSka', trainInfo?.powerVoltageModel?.vcbCloseSka1) }">
                    {{ getVacuumStatus(trainInfo?.powerVoltageModel?.vcbCloseSka1) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'vcbCloseSka2' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'vcbCloseSka', trainInfo?.powerVoltageModel?.vcbCloseSka2) }">
                    {{ getVacuumStatus(trainInfo?.powerVoltageModel?.vcbCloseSka2) || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'pressurePs1SafeguardAddSka1' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'pressurePs1SafeguardAddSka', trainInfo?.powerVoltageModel?.pressurePs1SafeguardAddSka1) }">
                    {{ getPantographStatus(trainInfo?.powerVoltageModel?.pressurePs1SafeguardAddSka1) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'pressurePs1SafeguardAddSka2' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'pressurePs1SafeguardAddSka', trainInfo?.powerVoltageModel?.pressurePs1SafeguardAddSka2) }">
                    {{ getPantographStatus(trainInfo?.powerVoltageModel?.pressurePs1SafeguardAddSka2) || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'hvDisconnectorCloseSKA1' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'hvDisconnectorCloseSKA', trainInfo?.powerVoltageModel?.hvDisconnectorCloseSKA1) }">
                    {{ getDisconnectorStatus(trainInfo?.powerVoltageModel?.hvDisconnectorCloseSKA1) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title">{{ 'hvDisconnectorCloseSKA2' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'hvDisconnectorCloseSKA', trainInfo?.powerVoltageModel?.hvDisconnectorCloseSKA2) }">
                    {{ getDisconnectorStatus(trainInfo?.powerVoltageModel?.hvDisconnectorCloseSKA2) || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'hvDisconnectorCloseOA2' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'hvDisconnectorCloseOA', trainInfo?.powerVoltageModel?.hvDisconnectorCloseOA2) }">
                    {{ getDisconnectorStatus(trainInfo?.powerVoltageModel?.hvDisconnectorCloseOA2) || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 2">
                <div class="flex-50">
                  <label class="column-info title">{{ 'tractionTransformerOilFlow' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tractionTransformerOilFlow', trainInfo?.powerVoltageModel?.tractionTransformerOilFlow?.[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.tractionTransformerOilFlow?.[selectedWagonIndex]] | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'tractionTransformerOilLevel' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tractionTransformerOilLevel', trainInfo?.powerVoltageModel?.tractionTransformerOilLevel?.[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.tractionTransformerOilLevel?.[selectedWagonIndex]] | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 2">
                <div class="flex-50">
                  <label class="column-info title">{{ 'tractionTransformerOverPressure' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tractionTransformerOverPressure', trainInfo?.powerVoltageModel?.tractionTransformerOverPressure?.[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.powerVoltageModel?.tractionTransformerOverPressure?.[selectedWagonIndex]] | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'tractionTransformerTemp' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tractionTransformerTemp', trainInfo?.powerVoltageModel?.tractionTransformerTemp?.[selectedWagonIndex]) }">
                    {{ trainInfo?.powerVoltageModel?.tractionTransformerTemp?.[selectedWagonIndex] }} &deg;C </label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title">{{ 'tempApsInv' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tempApsInv', trainInfo?.powerVoltageModel?.tempApsInv?.[selectedWagonIndex]) }">
                    {{ trainInfo?.powerVoltageModel?.tempApsInv?.[selectedWagonIndex] }} &deg;C </label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'tempApsRec' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tempApsRec', trainInfo?.powerVoltageModel?.tempApsRec?.[selectedWagonIndex]) }">
                    {{ trainInfo?.powerVoltageModel?.tempApsRec?.[selectedWagonIndex] }} &deg;C </label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'vApsDclink' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'vApsDclink', trainInfo?.powerVoltageModel?.vApsDclink?.[selectedWagonIndex]) }">
                    {{ trainInfo?.powerVoltageModel?.vApsDclink?.[selectedWagonIndex] }} V</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 4">
                <div class="flex-50">
                  <label class="column-info title">{{ 'measuringBatteryVoltage' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'measuringBatteryVoltage', trainInfo?.powerVoltageModel?.measuringBatteryVoltage?.[selectedWagonIndex]) }">
                    {{ trainInfo?.powerVoltageModel?.measuringBatteryVoltage?.[selectedWagonIndex] }} V</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'tempBattery' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('powerVoltageSystemRules', 'tempBattery', trainInfo?.powerVoltageModel?.tempBattery?.[selectedWagonIndex]) }">
                    {{ trainInfo?.powerVoltageModel?.tempBattery?.[selectedWagonIndex] }} &deg;C</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 4">
                <div class="flex-50">
                  <label class="column-info title">{{ 'aBatteryCharger' | translate }} :&nbsp; </label>
                  <label
                    class="column-info content">{{ trainInfo?.powerVoltageModel?.aBatteryCharger?.[selectedWagonIndex] }} A</label>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="airConditionSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'airConditionSystem' | translate}}"
            [active]="activeTab === 'airConditionSystem'"
            [tabId]="'airConditionSystem'">
            <div style="height: 410px">
              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title">{{ 'driverAirConditioningTemperature' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ trainInfo?.airConditionModel?.driverAirConditioningTemperature || 'N/A' }} &deg;C</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'passengerAirConditioningMode' | translate }} :&nbsp; </label>
                  <label class="column-info content">
                    {{ getAirConditionMod(trainInfo?.airConditionModel?.passengerAirConditioningMode) || 'N/A'  | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <label class="column-info title"> {{ 'hvacsInhibition' | translate }} :&nbsp; </label>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <table id="hvacsInhibition-table" class="styled-table">
                  <thead>
                  <tr [ngClass]="'header'">
                    <th>{{ 'SKA1' | translate }}</th>
                    <th>{{ 'OA1' | translate }}</th>
                    <th>{{ 'OA2' | translate }}</th>
                    <th>{{ 'OA3' | translate }}</th>
                    <th>{{ 'SKA2' | translate }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ errorMap[trainInfo?.airConditionModel?.hvacsInhibitionList[0]] || 'N/A' | translate }}</td>
                    <td>{{ errorMap[trainInfo?.airConditionModel?.hvacsInhibitionList[1]] || 'N/A' | translate }}</td>
                    <td>{{ errorMap[trainInfo?.airConditionModel?.hvacsInhibitionList[2]] || 'N/A' | translate }}</td>
                    <td>{{ errorMap[trainInfo?.airConditionModel?.hvacsInhibitionList[3]] || 'N/A' | translate }}</td>
                    <td>{{ errorMap[trainInfo?.airConditionModel?.hvacsInhibitionList[4]] || 'N/A' | translate }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 2">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'majorHvac' | translate }} :&nbsp;</label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('airConditionSystemRules', 'majorHvac', trainInfo?.airConditionModel?.driverMajorHvac[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.airConditionModel?.driverMajorHvac[selectedWagonIndex]] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'minorHvac' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('airConditionSystemRules', 'minorHvac', trainInfo?.airConditionModel?.driverMinorHvac[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.airConditionModel?.driverMinorHvac[selectedWagonIndex]] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 3">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'majorHvac' | translate }} :&nbsp;</label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('airConditionSystemRules', 'majorHvac', trainInfo?.airConditionModel?.passengerMajorHvac1[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.airConditionModel?.passengerMajorHvac1[selectedWagonIndex]] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'minorHvac' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('airConditionSystemRules', 'minorHvac', trainInfo?.airConditionModel?.passengerMinorHvac1[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.airConditionModel?.passengerMinorHvac1[selectedWagonIndex]] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 4">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'majorHvac' | translate }} :&nbsp;</label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('airConditionSystemRules', 'majorHvac', trainInfo?.airConditionModel?.passengerMajorHvac2[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.airConditionModel?.passengerMajorHvac2[selectedWagonIndex]] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'minorHvac' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('airConditionSystemRules', 'minorHvac', trainInfo?.airConditionModel?.passengerMinorHvac2[selectedWagonIndex]) }">
                    {{ errorMap[trainInfo?.airConditionModel?.passengerMinorHvac2[selectedWagonIndex]] || 'N/A' | translate }}</label>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="doorSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'doorSystem' | translate}}"
            [active]="activeTab === 'doorSystem'"
            [tabId]="'doorSystem'">
            <div style="height: 410px">
              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'door1LevelFault' | translate }} :&nbsp;</label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('doorSystemRules', 'door1LevelFault', trainInfo?.doorModel?.door1LevelFault) }">
                    {{ errorMap[trainInfo?.doorModel?.door1LevelFault] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'door2LevelFault' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('doorSystemRules', 'door2LevelFault', trainInfo?.doorModel?.door2LevelFault) }">
                    {{ errorMap[trainInfo?.doorModel?.door2LevelFault] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'slDoorControlByPass' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('doorSystemRules', 'slDoorControlByPass', trainInfo?.doorModel?.slDoorControlByPass) }">
                    {{ getDoorBypassStatus(trainInfo?.doorModel?.slDoorControlByPass) || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'kapi1SeviyeAriza' | translate }} :&nbsp;</label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('doorSystemRules', 'kapi1SeviyeAriza', trainInfo?.doorModel?.kapi1SeviyeAriza) }">
                    {{ errorMap[trainInfo?.doorModel?.kapi1SeviyeAriza] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'prmAsansorKapi1Devrede' | translate }} :&nbsp;</label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('doorSystemRules', 'prmAsansorKapi1Devrede', trainInfo?.doorModel?.prmAsansorKapi1Devrede) }">
                    {{ errorMap[trainInfo?.doorModel?.prmAsansorKapi1Devrede] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'doorLoopOkLed' | translate }} :&nbsp;</label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('doorSystemRules', 'doorLoopOkLed', trainInfo?.doorModel?.doorLoopOkLed) }">
                    {{ errorMap[trainInfo?.doorModel?.doorLoopOkLed] || 'N/A' | translate }}</label>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="otherSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'otherSystem' | translate}}"
            [active]="activeTab === 'otherSystem'"
            [tabId]="'otherSystem'">
            <div style="height: 410px">
              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'toiletIsNotActive' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('otherSystemRules', 'toiletIsNotActive', trainInfo?.otherModel?.toiletIsNotActive) }">
                    {{ errorMap[trainInfo?.otherModel?.toiletIsNotActive] || 'N/A'  | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'toiletBowlFull' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('otherSystemRules', 'toiletBowlFull', trainInfo?.otherModel?.toiletBowlFull) }">
                    {{ errorMap[trainInfo?.otherModel?.toiletBowlFull] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'toiletWaterLevelLow' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('otherSystemRules', 'toiletWaterLevelLow', trainInfo?.otherModel?.toiletWaterLevelLow) }">
                    {{ errorMap[trainInfo?.otherModel?.toiletWaterLevelLow] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'toiletWastewaterTankFull' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('otherSystemRules', 'toiletWastewaterTankFull', trainInfo?.otherModel?.toiletWastewaterTankFull) }">
                    {{ errorMap[trainInfo?.otherModel?.toiletWastewaterTankFull] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'toiletSosSignal' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('otherSystemRules', 'toiletSosSignal', trainInfo?.otherModel?.toiletSosSignal) }">
                    {{ errorMap[trainInfo?.otherModel?.toiletSosSignal] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <label class="column-info title"> {{ 'interiorLightingMode' | translate }} :&nbsp; </label>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <table id="interiorLighting-table" class="styled-table">
                  <thead>
                  <tr [ngClass]="'header'">
                    <th>{{ 'SKA1' | translate }}</th>
                    <th>{{ 'OA1' | translate }}</th>
                    <th>{{ 'OA2' | translate }}</th>
                    <th>{{ 'OA3' | translate }}</th>
                    <th>{{ 'SKA2' | translate }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ getInteriorLightingMode(trainInfo?.otherModel?.interiorLightingModeList[0]) || 'N/A' | translate }}</td>
                    <td>{{ getInteriorLightingMode(trainInfo?.otherModel?.interiorLightingModeList[1]) || 'N/A' | translate }}</td>
                    <td>{{ getInteriorLightingMode(trainInfo?.otherModel?.interiorLightingModeList[2]) || 'N/A' | translate }}</td>
                    <td>{{ getInteriorLightingMode(trainInfo?.otherModel?.interiorLightingModeList[3]) || 'N/A' | translate }}</td>
                    <td>{{ getInteriorLightingMode(trainInfo?.otherModel?.interiorLightingModeList[4]) || 'N/A' | translate }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            [tabIcon]="communicationSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon' }} : null"
            tabTitle="{{'communicationSystem' | translate}}"
            [active]="activeTab === 'communicationSystem'"
            [tabId]="'communicationSystem'" style="padding: 0; overflow: hidden !important;">
            <nb-tabset>
              <nb-tab tabTitle="{{'mvbCommunication' | translate}}" [active]="activeTab === 'mvbCommunication'"
                      [tabId]="'mvbCommunication'">
                <div style="height: 100%; margin-bottom: 200px;">
                  <table id="mvbCom-table">
                    <thead>
                    <tr [ngClass]="'header'">
                      <th>{{ 'SKA1' | translate }}</th>
                      <th>{{ 'OA1' | translate }}</th>
                      <th>{{ 'OA2' | translate }}</th>
                      <th>{{ 'OA3' | translate }}</th>
                      <th>{{ 'SKA2' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let mvbCom of mvbComList">
                      <td [ngClass]="{'no-border': mvbCom.ska1 === ''}">{{ mvbCom.ska1 | translate }}</td>
                      <td [ngClass]="{'no-border': mvbCom.oa1 === ''}">{{ mvbCom.oa1 | translate }}</td>
                      <td [ngClass]="{'no-border': mvbCom.oa2 === ''}">{{ mvbCom.oa2 | translate }}</td>
                      <td [ngClass]="{'no-border': mvbCom.oa3 === ''}">{{ mvbCom.oa3 | translate }}</td>
                      <td [ngClass]="{'no-border': mvbCom.ska2 === ''}">{{ mvbCom.ska2 | translate }}</td>
                    </tr>
                    </tbody>
                  </table>

                  <div style="display: flex; align-items: center; justify-content: flex-end; margin-top: 20px;">
                    <label style="display: flex; align-items: center; margin-right: 20px;">
                      <span style="width: 4px; height: 16px; background-color: #31C048; margin-right: 10px;"></span>
                      <span style="color: gray;">{{ 'noError' | translate }}</span>
                    </label>
                    <label style="display: flex; align-items: center;">
                      <span style="width: 4px; height: 16px; background-color: #FF0012; margin-right: 10px;"></span>
                      <span style="color: gray;">{{ 'error' | translate }}</span>
                    </label>
                  </div>
                </div>
              </nb-tab>

              <nb-tab tabTitle="{{'canCommunication' | translate}}" [active]="activeTab === 'canCommunication'"
                      [tabId]="'canCommunication'">
                <div style="height: 100%; margin-bottom: 100px;">
                  <table id="canCom-table">
                    <thead>
                    <tr class="header">
                      <th colspan="4">SKA-1 RIOM</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let canCom of camComList">
                      <td [ngClass]="{'no-border': canCom.riom1 === ''}">{{ canCom.riom1 | translate }}</td>
                      <td [ngClass]="{'no-border': canCom.riom2 === ''}">{{ canCom.riom2 | translate }}</td>
                      <td [ngClass]="{'no-border': canCom.riom3 === ''}">{{ canCom.riom3 | translate }}</td>
                      <td [ngClass]="{'no-border': canCom.riom4 === ''}">{{ canCom.riom4 | translate }}</td>
                    </tr>
                    </tbody>
                  </table>

                  <div style="display: flex; align-items: center; justify-content: flex-end; margin-top: 20px;">
                    <label style="display: flex; align-items: center; margin-right: 20px;">
                      <span style="width: 4px; height: 16px; background-color: #31C048; margin-right: 10px;"></span>
                      <span style="color: gray;">{{ 'noError' | translate }}</span>
                    </label>
                    <label style="display: flex; align-items: center;">
                      <span style="width: 4px; height: 16px; background-color: #FF0012; margin-right: 10px;"></span>
                      <span style="color: gray;">{{ 'error' | translate }}</span>
                    </label>
                  </div>
                </div>
              </nb-tab>
            </nb-tabset>
          </nb-tab>

          <nb-tab
            [tabIcon]="signalizationSystem ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{'signalizationSystem' | translate}}"
            [active]="activeTab === 'signalizationSystem'"
            [tabId]="'signalizationSystem'">
            <div style="height: 410px">
              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'etcsIsolationByPass' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('signalizationSystemRules', 'etcsIsolationByPass', trainInfo?.signalizationModel?.etcsIsolationByPass) }">
                    {{ errorMap[trainInfo?.signalizationModel?.etcsIsolationByPass] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'ertmsCutOff' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('signalizationSystemRules', 'ertmsCutOff', trainInfo?.signalizationModel?.ertmsCutOff) }">
                    {{ errorMap1[trainInfo?.signalizationModel?.ertmsCutOff] || 'N/A' | translate }}</label>
                </div>
              </div>

              <div class="d-flex " *ngIf="tabInfo === 1">
                <div class="flex-50">
                  <label class="column-info title"> {{ 'atsByPass' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('signalizationSystemRules', 'atsByPass', trainInfo?.signalizationModel?.atsByPass) }">
                    {{ errorMap[trainInfo?.signalizationModel?.atsByPass] || 'N/A' | translate }}</label>
                </div>
                <div class="flex-50" [ngStyle]="{'margin-left':'25px'}">
                  <label class="column-info title"> {{ 'ertmsServiceBrake' | translate }} :&nbsp; </label>
                  <label class="column-info content"
                         [ngStyle]="{ 'color': getDynamicColor('signalizationSystemRules', 'ertmsServiceBrake', trainInfo?.signalizationModel?.ertmsServiceBrake) }">
                    {{ errorMap[trainInfo?.signalizationModel?.ertmsServiceBrake] || 'N/A' | translate }}</label>
                </div>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab
        [tabIcon]="status[4] === '-red' ? {icon: 'alert-triangle', options: {color: '#FF1F00', class: 'nb-tab-custom-icon'}} : null"
        tabTitle="{{ 'prognosticSystem' | translate }}"
        [active]="activeTab === 'prognosticSystem'"
        [tabId]="'prognosticSystem'"
        class="test">
        <nb-tabset (changeTab)="onTabChange($event)" class="prognostic-nb-tabset">
          <nb-tab
            [tabIcon]="status[4] === '-red' ? {icon:'alert-triangle', options:{color:'#FF1F00', class: 'nb-tab-custom-icon'}} : null"
            tabTitle="{{'predictiveMaintenance' | translate}}" [active]="activeTab === 'predictiveMaintenance'"
            [tabId]="'predictiveMaintenance'">
            <div style="height: 410px">
              <div class="d-flex ">
                <div class="flex-50">
                  <label [ngClass]="'flex-50'+status[4]"
                         [style.white-space]="'pre-line'"> {{ predictiveMaintenanceInfo | translate }} </label>
                </div>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
    </nb-tabset>
  </div>

  <div dialog-card-footer class="footer">
    <div class="footer-left">
      <label class="column-info title">{{ 'ascLimit' | translate }} :&nbsp; </label>
      <label [ngClass]="'flex-50'+status[0]"> {{ trainInfo?.ascLimit || 'N/A' }} km/h </label> <br/>
      <label class="column-info title">{{ 'coordinate' | translate }} :&nbsp; </label>
      <label class="column-info content">{{ trainInfo?.location || 'N/A' }}</label>
    </div>
    <div class="footer-right">
      <label class="column-info title">{{ 'speed' | translate }} :&nbsp; </label>
      <label class="column-info content">{{ trainInfo?.speed || 'N/A' }} km/h</label> <br/>
      <label class="action-button" (click)="getFirstData()">
        <nb-icon [icon]="'arrow-back'" pack="eva" nbTooltip="Geriye Dön"></nb-icon>
      </label>
      <label class="action-button" (click)="refreshData()">
        <nb-icon [icon]="'refresh-outline'" pack="eva" nbTooltip="Yenile"></nb-icon>
      </label>
      <label *ngIf="isLiveMode" class="column-info title">{{ 'liveMode' | translate }} </label>
      <label *ngIf="!isLiveMode" class="column-info title">{{ 'firstMode' | translate }} </label>
    </div>
  </div>
</closeable-dialog>
