export class QueryOptions {
  /**
   * @param isTemporal Whether the parsed data is retrieved for a temporal query
   * @param keyValues Whether the given data has "keyValue" format or not
   * @param sysAttrs Whether the system attributes like instanceId will be included in the results
   */
  constructor(public isTemporal = false, public keyValues = false, public sysAttrs = false) {
  }
}

