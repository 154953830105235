import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

/**
 * A service that permits visiting each route
 */
@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(public router: Router) {
  }

  canActivate(): boolean {
    return true;
  }
}
