/**
 * Minimum or maximum indicator for a distance
 */
export class MaxMinDistance {
  distance: number;

  /**
   * Whether the specified distance should be interpreted as minimum or maximum distance
   */
  isMax: boolean;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.distance = data.distance;
    this.isMax = data.isMax;
  }
}
