import {BaseMetaModel} from './base/base-meta-model.model';

/**
 * Defines a tag that can be used to annotate other entities
 */
export class Tag extends BaseMetaModel {
  /**
   * Type of the tag, which could one of the values defined in TagType enum
   */
  type: string;

  /**
   * Coded value of the tag. Code of a tag MUST NOT be changed as it is used inside the Meta objects of tagged entities
   */
  code: string;

  /**
   * Human readable name of the tag
   */
  name: string;

  /**
   * Description of the tag
   */
  description?: string;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.type = data.type;
    this.code = data.code;
    this.name = data.name;
    this.description = data.description;
  }
}
