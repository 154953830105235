export class OtherModel {
  contactorHalfLightList: any[];
  contactorEmergencyLightList: any[];
  interiorLightingModeList: any[];
  toiletIsNotActive: any;
  toiletWaterLevelLow: any;
  toiletBowlFull: any;
  toiletWastewaterTankFull: any;
  toiletSosSignal: any;

  constructor(data: any) {
    this.contactorHalfLightList = data.contactorHalfLightList;
    this.contactorEmergencyLightList = data.contactorEmergencyLightList;
    this.interiorLightingModeList = data.interiorLightingModeList;
    this.toiletIsNotActive = data.toiletIsNotActive;
    this.toiletWaterLevelLow = data.toiletWaterLevelLow;
    this.toiletBowlFull = data.toiletBowlFull;
    this.toiletWastewaterTankFull = data.toiletWastewaterTankFull;
    this.toiletSosSignal = data.toiletSosSignal;
  }
}
