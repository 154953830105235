import {Injector, NgZone} from '@angular/core';
import * as L from 'leaflet';
import {marker, polyline, polygon} from 'leaflet';
import 'leaflet.heat/dist/leaflet-heat';
import {Layer} from '../../shared/models/layer.model';
import {NbDialogService} from '@nebular/theme';
import {Page} from '../../shared/models/page.model';
import {EntityRepresentation} from '../../shared/models/visualization/representation.model';
import {Visualization} from '../../shared/models/visualization/visualization.model';
import {TranslateService} from '@ngx-translate/core';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {IconVisualization} from '../../shared/models/visualization/icon-visualization.model';
import {
  ReservedCssAttribute,
  VisualizationSetting
} from '../../shared/models/visualization/visualization-setting.model';
import {evaluateExpression} from '../expression/expression-evaluator';
import {LineVisualization} from '../../shared/models/visualization/line-visualization.model';
import {PageService} from '../services/meta/page.service';
import {Observable, Subject, takeUntil} from 'rxjs';
import {LayoutService} from '../services/layout.service';
import {BucketResult} from '../../shared/models/schema/aggregation/bucket-result';
import {Coordinate} from '../../shared/models/generic/coordinate.model';
import {HeatmapVisualization} from '../../shared/models/visualization/heatmap-visualization.model';
import {NavigationService} from '../services/navigation.service';
import {EventService} from '../services/event.service';
import {StringUtil} from '../../shared/utils/string-util';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from 'app/shared/models/ngsi/query-options';
import {GeoProperty} from '../../shared/models/schema/geo-property.model';
import {Linestring} from '../../shared/models/map/linestring.model';
import {Marker} from '../../shared/models/map/marker.model';
import {environment} from '../../../environments/environment';
import {MapFramework} from '../../shared/enums/map-framework.enum';
import {ContextService} from '../services/http/context.service';
import {NGSIPath} from '../../shared/models/ngsi/ngsi-path.model';
import {ObjectUtil} from '../../shared/utils/object-util';
import {GenericVisualization} from '../../shared/models/visualization/generic-visualization.model';
import {Polygon} from '../../shared/models/map/polygon.model';
import {PolygonVisualization} from '../../shared/models/visualization/polygon-visualization.model';
import {VisualizationType} from '../../shared/enums/viualization-type.enum';
import {Geometry} from '../../shared/enums/ngsi-query.enum';
import {SocketService} from '../services/http/socket.service';
import {NGSIEventType, Notification} from '../../shared/models/notification.model';
import {NGSIResultUtil} from '../../shared/utils/ngsi-result-util';
import {getMarkerDimensions} from '../../shared/utils/marker-util';

export abstract class LayerController {

  // Subscription object for unsubscribing when destroying the controller to avoid leaks
  destroy$ = new Subject<void>();

  /**
   * Layer instance
   */
  layer: Layer;

  /**
   * Page on which the layer is displayed
   */
  page: Page;

  /**
   * Popup page to be displayed upon clicking on the layer
   * */
  popupPage: Page;

  /**
   * Entities retrieved for each representation in the layer. Keys of the map correspond to the order of representation in the layer definition.
   */
  entities: Map<EntityRepresentation, NGSIResult[]> = new Map<EntityRepresentation, NGSIResult[]>();

  /**
   * Map markers (icons, lines, etc) for given ngsi entities or aggregation results
   */
  markers: Map<EntityRepresentation, any[]> = new Map<EntityRepresentation, any[]>();

  /**
   * Cache list in order to prevent merging all the mapped entities for each UI event
   */
  mergedMarkers: any[] = [];

  /**
   * Flag that indicates the visibility of the tooltip for layer items on the map
   */
  displaysTooltip: boolean = false;

  /**
   * Flag that indicates the visibility of the layer on the map
   */
  private _visibleOnMap: boolean;

  /**
   * Number of layer counts initialized
   * @private
   */
  protected initializedLayerCount: number = 0;

  // services
  result : NGSIResult[] = null;
  representation : EntityRepresentation = null;
  pageService: PageService;
  cbrokerService: ContextService;
  layoutService: LayoutService;
  navigationService: NavigationService;
  dialogService: NbDialogService;
  translateService: TranslateService;
  eventService: EventService;
  socketService: SocketService;
  zone: NgZone;

  protected constructor(layer: Layer, page: Page, injector: Injector) {
    this.layer = layer;
    this.page = page;
    this.pageService = injector.get(PageService);
    this.cbrokerService = injector.get(ContextService);
    this.layoutService = injector.get(LayoutService);
    this.navigationService = injector.get(NavigationService);
    this.dialogService = injector.get(NbDialogService);
    this.translateService = injector.get(TranslateService);
    this.eventService = injector.get(EventService);
    this.socketService = injector.get(SocketService);
    this.zone = injector.get(NgZone);

    if (environment.subscriptions.enabled) {
      this.subscribeWebsocket();
    }
  }

  get visibleOnMap(): boolean {
    return this._visibleOnMap;
  }

  set visibleOnMap(value: boolean) {
    this._visibleOnMap = value;
  }

  /**
   * Obtains an observable for each representation with a subscription id. Observables are then used to monitor the notifications that are generated for each subscription.
   * The callback handling the notifications updates the map as needed based on the notification content.
   */
  subscribeWebsocket(): void {
    this.layer?.representations.forEach(representation => {
      if (representation.entityQuery.subscriptionId) {
        this.socketService.subscribe(representation.entityQuery.subscriptionId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(notification => {
          this.websocketCallback(representation, notification);
        });
      }
    });
  }

  /**
   * Callback to be called when a notification is received. For "create" notifications a new item is added to the map. For "update" notifications, an existing item on the map
   * is updated based on the update content.
   * @param representation
   * @param notification
   * @private
   */
  protected websocketCallback(representation: EntityRepresentation, notification: Notification): void {
    let entity: NGSIResult = null;
    if (notification.eventClass === 'NGSIEvent') {
      if (notification.eventPayload.eventType === NGSIEventType.CREATE) {
        entity = this.handleCreateEvent(representation, notification);

      } else if (notification.eventPayload.eventType === NGSIEventType.UPDATE_TEMPORAL) {
        const entityIndex = this.entities.get(representation).findIndex(e => e.getEntityId() === notification.entityId);
        if (entityIndex !== -1) {
          entity = this.entities.get(representation)[entityIndex];
          this.entities.get(representation).splice(entityIndex, 0);
          this.markers.get(representation).splice(entityIndex, 0);
          NGSIResultUtil.mergeTemporalUpdate(entity.getEntityResult(), notification);
        }
      }

      if (entity) {
        this.addResultItem(entity, representation);
        this.createMapObjects([entity], representation);
        this.mergeMappedEntities();
        this.eventService.broadcastLayerProcessingFinishedEvent(this);
        this.afterEntitiesProcessed();
      }
    }
  }

  protected handleCreateEvent(representation: EntityRepresentation, notification: Notification): NGSIResult {
    return NGSIResultUtil.mapEntity(notification.eventPayload.content);
  }

  /**
   * Clears the resources on this controller is destroyed. This is expected to occur when the corresponding UI component (e.g. a page) is destroyed.
   */
  onDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Sets entities with corresponding map constructs (markers, polylines, polygons, etc) of this layer
   * @param results
   * @param representation A specific representation for the retrieved data
   */
  processData(results: NGSIResult[], representation: EntityRepresentation) {
    results = this.beforeEntitiesProcessed(results);

    this.clearEntities(representation);

    results.forEach(entity => {
      this.addResultItem(entity, representation);
    });

    this.createMapObjects(this.entities.get(representation), representation);

    // layer's visibility is set only for the first processing of the results. I.e. once the layer is initialized, this part does not override the visibility
    if (this.initializedLayerCount < this.layer.representations.length) {
      // after all processing make the icons visible
      this.setVisibility(true);

      this.initializedLayerCount++;
    }

    // merge all mapped entities
    this.mergeMappedEntities();

    // post-process the retrieved entities
    this.afterEntitiesProcessed();

    // broadcast the event
    this.eventService.broadcastLayerProcessingFinishedEvent(this);
  }

  /**
   * Creates map objects for the given representation. For each entity obtained for the given representation, a marker is created and a click handler
   * is associated to the marker.
   * @param results
   * @param representation
   * @protected
   */
  protected createMapObjects(results: NGSIResult[], representation: EntityRepresentation): void {
    // TODO handle all dynamic visualization settings e.g. position of the marker
    if (results) {
      if (!(representation.visualization instanceof HeatmapVisualization)) {
        this.createNonHeatmapObjects(results, representation);
      } else {
        this.createHeatmapObject(results, representation);
      }
    }
  }

  /**
   * Creates individual markers for each result and adds them to the layer context
   * @param results
   * @param representation
   * @private
   */
  private createNonHeatmapObjects(results: NGSIResult[], representation: EntityRepresentation): void {
    const visualization: Visualization = representation.visualization;
    for (const result of results) {
      // check whether the attribute keeping the location information is available. Otherwise, it is not possible to add the entity to the map
      const location = result.getEntityResult().getSingleElementValueByNgsiPath(visualization.locationAttribute);
      if (location?.coordinates) {
        const entityMarkers = this.createMarkers(result, location, visualization);

        entityMarkers.forEach(entityMarker => {
          if (environment.map.framework === MapFramework.LEAFLET) {
            // bind click events
            entityMarker.on('click', () => {
              this.entityClicked(result, visualization.popupPageId);
            });
          } else if (environment.map.framework === MapFramework.MAPLIBRE) {
            entityMarker.callback = () => {
              this.entityClicked(result, visualization.popupPageId);
            }
          }

          // add marker
          this.addMarkerForResult(entityMarker, representation);
        });
      }
    }
  }

  /**
   * Creates the single heat marker and adds it to the layer context.
   * @param results
   * @param representation
   * @private
   */
  private createHeatmapObject(results: NGSIResult[], representation: EntityRepresentation): void {
    // find the largest value in the results to adjust the other results accordingly
    const max = Math.max.apply(Math, results.map(function (result) {
      return result.getBucketResult().aggregationResult.result.count_id;
    }));

    const heatData: number[][] = results.map(result => {
      // assuming that the result contains geohash aggregation
      const bucketResult: BucketResult = result.getBucketResult();
      // coordinates is a lat long array
      const coordinates: number[] = bucketResult.getGeohashCoordinates();
      return [coordinates[0], coordinates[1], bucketResult.aggregationResult.result.count_id / max];
    });

    // add marker
    const heat: any = L.heatLayer(heatData, {radius: (representation.visualization as HeatmapVisualization).radius});
    this.addMarkerForResult(heat, representation);
  }

  /**
   * Click handler for the marker
   */
  public entityClicked(result: NGSIResult, popupPageId: string) {
    if (popupPageId) {
      this.getDialogPage(popupPageId).subscribe(page => {
        // set popup page
        this.popupPage = page;
        // trigger a marker click event
        this.navigationService.onMarkerClicked(page.templateType, this.getDialogContext(result));
      });

      // zoom to the marker if no popup page is provided
    } else {
      // get the zoom level at which the clicked marker is not shown in cluster mode
      if (result.result instanceof BucketResult) {
        const location: any = result.result.getGeohashCoordinates();
        if (location) {
          const coordinate: Coordinate = Coordinate.fromLatLongArray(location);
          this.layoutService.panAndZoomToMarker(coordinate, location.radius);
        }
      }
    }
  }

  /**
   * Returns the dialog template component to be initialized dynamically.
   */
  private getDialogPage(pageId: string): Observable<Page> {
    // TODO consider caching pages
    return this.pageService.getPage(pageId);
  }

  /**
   * Collects the data to be provided to the dialog.
   * @param result
   */
  protected abstract getDialogContext(result: NGSIResult): any;

  /**
   * Creates a marker for the given result.
   * @param result
   * @param location
   * @param visualization
   * @protected
   */
  protected createMarkers(result: NGSIResult, location: any, visualization: Visualization): any {
    const markers = [];

    if (visualization instanceof IconVisualization) {
      markers.push( this.getIconMarker(result, location.coordinates, visualization) );
    } else if (visualization instanceof LineVisualization) {
      markers.push( this.getLineMarker(result, location.coordinates, visualization) );
    } else if (visualization instanceof PolygonVisualization) {
      markers.push( this.getPolygonMarker(result, location.coordinates, visualization) );
    } else if (visualization instanceof GenericVisualization) {
        const iconVisualization = ObjectUtil.getFirstArrayItem( visualization.visualizations.filter(v => v.jsonClass === VisualizationType.ICON) );
        const lineVisualization = ObjectUtil.getFirstArrayItem( visualization.visualizations.filter(v => v.jsonClass === VisualizationType.LINE) );
        const polygonVisualization = ObjectUtil.getFirstArrayItem( visualization.visualizations.filter(v => v.jsonClass === VisualizationType.POLYGON) );

      if (location.type === Geometry.POINT && iconVisualization) {
        markers.push( this.getIconMarker(result, location.coordinates, iconVisualization) );
      } else if (location.type === Geometry.LINE_STRING && lineVisualization) {

        if (visualization.shouldReduceToPoints) {
          if (this.layoutService.getZoomLevel() > visualization.breakpointZoomLevel) {
            markers.push( this.getLineMarker(result, location.coordinates, lineVisualization) );
          } else {
            markers.push( this.getIconMarker(result, location.coordinates, iconVisualization) );
          }
        } else {
          markers.push( this.getLineMarker(result, location.coordinates, lineVisualization) );

          if (visualization.shouldDisplayPoints) {
            markers.push( this.getIconMarker(result, location.coordinates, iconVisualization) );
          }
        }
      } else if (location.type === Geometry.POLYGON && polygonVisualization) {
        if (visualization.shouldReduceToPoints) {
          if (this.layoutService.getZoomLevel() > visualization.breakpointZoomLevel) {
            markers.push( this.getPolygonMarker(result, location.coordinates, polygonVisualization) );
          } else {
            markers.push( this.getIconMarker(result, location.coordinates, iconVisualization) );
          }
        } else {
          if (visualization.shouldDisplayPoints) {
            markers.push( this.getIconMarker(result, location.coordinates, iconVisualization) );
          }
        }
      }
    }

    markers.forEach( resultMarker => {
      if (resultMarker) {
        resultMarker.entity = result.result;
        resultMarker.context = this;
      }
    });

    return markers;
  }

  /**
   * Standard implementation for creating icon markers based on the given result and visualization configuration
   * @param result
   * @param coordinates
   * @param visualization
   * @protected
   */
  protected getIconMarker(result: NGSIResult, coordinates: any, visualization: IconVisualization) {
    // sometimes LineString or Polygon coordinates might be provided, normalize such cases
    let normalizedCoordinates = coordinates;
    if (ObjectUtil.isArrayOfArray(normalizedCoordinates)) {
      let lats = normalizedCoordinates.map(array => ObjectUtil.isArray(array[0]) ? array.map(a => a[0]) : array[0])
      if(ObjectUtil.isArrayOfArray(lats)){
        lats = lats[0]
      }
      const normalizedLat = lats.reduce((a, b) => a + b, 0) / lats.length;
      let lngs = normalizedCoordinates.map(array => ObjectUtil.isArray(array[0]) ? array.map(a => a[1]) : array[1])
      if(ObjectUtil.isArrayOfArray(lngs)){
        lngs = lngs[0]
      }
      const normalizedLng = lngs.reduce((a, b) => a + b, 0) / lngs.length;
      normalizedCoordinates = [normalizedLng, normalizedLat];
    }

    // check whether there is a specific setting for icon type
    let settings: VisualizationSetting[] = visualization.settings.filter(setting => setting.cssAttribute === ReservedCssAttribute.ICON_TYPE);
    let iconFile: string = visualization.icon;
    let html: string = visualization.html;
    let className: string = visualization.className;
    let customSetting: VisualizationSetting = null;
    let sizeFactor: number = visualization.sizeFactor;
    for (const setting of settings) {
      if (evaluateExpression(setting.expression, result)) {
        customSetting = setting;
        break;
      }
    }
    /*
      TODO: icon type settings is supposed to have many icon-related configurations such as
            {
              "icon": "someicon",
              "className": "well-marker-critical"
            }, however VisualizationSetting.value can keep only the icon file for now.
     */
    if (customSetting) {
      const value: any = customSetting.value;
      iconFile = value;
      // if (value.className) {
      //   className = value.className;
      // }
      // if (value.sizeFactor){
      //   sizeFactor = value.sizeFactor;
      // }
    }

    settings = visualization.settings.filter(setting => setting.cssAttribute === ReservedCssAttribute.HTML);
    for (const setting of settings) {
      if (evaluateExpression(setting.expression, result)) {
        customSetting = setting;
        break;
      }
    }
    if (customSetting) {
      const value: any = customSetting.value;
      html = value;
    }
    // common icon settings for icon or divicon visualizations
    // we calculate the icon size in terms of pixels as we have to specify the iconAnchor property in order to prevent markers moving from their original locations on zoom.
    // original dimensions of icons are supposed be suitable for 1920 x 1080 resolution
    const widthScaleFactor: number = this.layoutService.getApplicationSize()[0] / 1920;
    const heightScaleFactor: number = this.layoutService.getApplicationSize()[1] / 930; // 930 is the viewport size in 1920 x 1080 resolution
    const iconDimensions: any = getMarkerDimensions(iconFile);
    const iconHeight: number = heightScaleFactor * sizeFactor * iconDimensions.height;
    const iconWidth: number = widthScaleFactor * sizeFactor * iconDimensions.width;
    const iconSettings: any = {
      // setting icon size explicitly so that the size configurations in the css class would be applied
      iconSize: [iconWidth, iconHeight],
      iconAnchor: [iconWidth / 2, iconHeight],
      className: className
    };

    // create regular icon or divicon based on the availability of the icon information
    let icon;
    const iconUrl = iconFile ? 'assets/map-markers/' + iconFile : null;
    if (visualization.icon) {
      iconSettings.iconUrl = iconUrl;
      icon = L.icon(iconSettings);
    } else {
      // use the same width for the cluster html
      iconSettings.iconSize[0] = iconSettings.iconSize[1];
      iconSettings.html = this.populateHtml(result, visualization.html);
      icon = L.divIcon(iconSettings);
    }

    if (environment.map.framework === MapFramework.LEAFLET) {
      return marker(normalizedCoordinates, {icon: icon});
    } else if (environment.map.framework === MapFramework.MAPLIBRE) {
      return new Marker(normalizedCoordinates, iconUrl, iconWidth, iconHeight, html);
    }
  }

  /**
   * Populates the HTML with the actual data to be obtained from the result.
   * TODO we can consider getting the complete HTML instead of just populating it. (I.e. we would not keep the HTML snippet in the layer
   *  definition but in the layer implementation.
   * @param result
   * @param html
   * @protected
   */
  protected populateHtml(result: NGSIResult, html: string): string {
    return html;
  }

  /**
   * Standard implementation for creating line markers based on the given result and visualization configuration
   * @param result
   * @param coordinates
   * @param visualization
   * @protected
   */
  protected getLineMarker(result: NGSIResult, coordinates: any, visualization: LineVisualization) {
    // check whether there is a specific setting for the color of the line
    let settings: VisualizationSetting[] = visualization.settings.filter(setting => setting.cssAttribute === ReservedCssAttribute.LINE_COLOR);
    let color: string = (result.getEntityResult() && result.getEntityResult().entity.color) ? result.getEntityResult().entity.color.value : visualization.color;
    let customSetting: VisualizationSetting = null;
    for (const setting of settings) {
      if (evaluateExpression(setting.expression, result)) {
        customSetting = setting;
        break;
      }
    }
    if (customSetting) {
      color = customSetting.value;
    }
    // check whether there is a specific setting for the thickness of the line
    settings = visualization.settings.filter(setting => setting.cssAttribute === ReservedCssAttribute.LINE_WEIGHT);
    let weight: number = visualization.weight;
    customSetting = null;
    for (const setting of settings) {
      if (evaluateExpression(setting.expression, result)) {
        customSetting = setting;
        break;
      }
    }
    if (customSetting) {
      weight = customSetting.value;
    }

    if (environment.map.framework === MapFramework.LEAFLET) {
      return polyline(coordinates, {
        color: color,
        weight: weight,
        opacity: visualization.opacity
      });
    } else if (environment.map.framework === MapFramework.MAPLIBRE) {
      return new Linestring(new GeoProperty({
        type: 'LineString',
        coordinates: coordinates
      }), color, weight, visualization.opacity);
    }
  }

  protected getPolygonMarker(result: NGSIResult, coordinates: any, visualization: PolygonVisualization) {
    // check whether there is a specific setting for the color of the polygon
    let settings: VisualizationSetting[] = visualization.settings.filter(setting => setting.cssAttribute === ReservedCssAttribute.FILL_COLOR);
    let fillColor: string = visualization.fillColor;
    let customSetting: VisualizationSetting = null;
    for (const setting of settings) {
      if (evaluateExpression(setting.expression, result)) {
        customSetting = setting;
        break;
      }
    }
    if (customSetting) {
      fillColor = customSetting.value;
    }
    if (environment.map.framework === MapFramework.LEAFLET) {
      return polygon(coordinates, {
        color: fillColor,
        opacity: visualization.opacity
      });
    } else if (environment.map.framework === MapFramework.MAPLIBRE) {
      return new Polygon(new GeoProperty({
        type: 'Polygon',
        coordinates: coordinates
      }), fillColor, visualization.opacity);
    }
  }

  /**
   * Clears the cached entities and markers for the given representation
   * @param representation
   */
  clearEntities(representation: EntityRepresentation): void {
    this.entities.delete(representation);
    this.markers.delete(representation);
    this.mergeMappedEntities();
  }

  /**
   * Toggles the visibility of this layer
   */
  toggleLayerVisibility(): void {
    this.visibleOnMap = !this.visibleOnMap;

    this.eventService.broadcastLayerVisibilityChangeEvent(this);
  }

  /**
   * Sets the visibility of the layer
   * @param visibility
   */
  setVisibility(visibility: boolean): void {
    this.visibleOnMap = visibility;
  }

  /**
   * Caches the entity for the given representation
   * @param result
   * @param representation
   * @protected
   */
  protected addResultItem(result: NGSIResult, representation: EntityRepresentation): void {
    if (!this.entities.has(representation)) {
      this.entities.set(representation, []);
    }
    this.entities.get(representation).push(result);
  }

  /**
   * Caches the mapped result for for the given representation
   * @param result
   * @param representation
   * @protected
   */
  protected addMarkerForResult(result: any, representation: EntityRepresentation): void {
    let findCount = 0;
    if (!this.markers.has(representation)) {
      this.markers.set(representation, []);
      this.markers.get(representation).push(result);
    } else {
      for (let i = 0; i < this.markers.get(representation).length; i++) {
        if (this.markers.get(representation)[i].entity.entity.id === result.entity.entity.id) {
          findCount ++;
          this.markers.get(representation)[i] = result;
        }
      }
      if (findCount === 0) {
        this.markers.get(representation).push(result);
      }
    }
  }

  /**
   * Returns the concatenation of layer property value and unit.
   * @param property the property
   * @param trimDecimal whether the decimal part of value should be trimmed
   * @param unit the unit of property
   * */
  protected getLayerPropertyValue(property: any, trimDecimal: boolean = false, unit: string = null) {
    let displayedValue = '';
    if (property && property.value) {
      // trim decimal if it is asked to
      displayedValue = trimDecimal ? StringUtil.trimDecimal(property.value, 2) : property.value;

      if (property.unitCode) {
        displayedValue += ' ' + this.translateService.instant(property.unitCode);
      } else if (unit) {
        displayedValue += ' ' + this.translateService.instant(unit);
      }
    }
    return displayedValue;
  }

  /**
   * A generic-purpose method that can be overridden for layer-specific behaviors before entities are processed e.g. to eliminate duplicates
   * @protected
   */
  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    return results;
  }

  /**
   * A generic-purpose method that can be overridden for layer-specific behaviors after entities are processed i.e. map markers are generated.
   * @protected
   */
  protected afterEntitiesProcessed(): void {

  }

  /**
   * Merges all entities to be shown on the map into a single array for this layer
   */
  protected mergeMappedEntities(): void {
    this.mergedMarkers = [];
    Array.from(this.markers.values()).forEach(value => {
      this.mergedMarkers = this.mergedMarkers.concat(value);
    });
  }

  protected getMarkerEntity(entityId: string, isTemporal: boolean = true, cBrokerEndpoint: string = null): Observable<NGSIResult> {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    query.filter.id = [entityId];
    return this.cbrokerService.getEntity(query, new QueryOptions(isTemporal, false, true), false, cBrokerEndpoint);
  }

  /**
   * Returns the value of property.
   * @param entity
   * @param name the property name
   * @param trimDecimal
   * @param unit the unit of property
   * @return the property value
   * */
  protected getPropertyValue(entity, name: string, trimDecimal = true, unit: string = null): any {
    const path = new NGSIPath({
      propOrRelPath: [name]
    });
    const property = entity.extractValueByNgsiPath(path, false);

    return this.getLayerPropertyValue(property, trimDecimal, unit);
  }

  /**
   * Creates a tooltip for a given entity associated with a marker;
   * @param marker
   */
  createTooltip(marker: Marker): string {
    return null;
  }

  toolTipClosed(marker: Marker) {

  }
}
