import {LayerController} from './layer.controller';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {catchError, forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {Marker} from '../../shared/models/map/marker.model';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {NGSIEntity} from '../../shared/utils/ngsi-result-util';


export class JkgysTakLayerController extends  LayerController {
  displaysTooltip: boolean = true;
  atus: any;
  gekos: any;

  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    const jemusTakId = result.getEntityResult().entity.id;
    let contextObservable: Observable<any> = null;
    const atuQuery = this.getAtuQuery(jemusTakId);
    const gekoQuery = this.getGekoQuery(jemusTakId);
    const takRef = (this.getTakQuery(jemusTakId));
    takRef.lastNDays = 365;
    const takQuery = this.cbrokerService.getEntities(takRef, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const atuRefQuery = this.sendAtuQuery(atuQuery);
    const gekoRefQuery =  this.sendGekoQuery(gekoQuery);

    contextObservable = forkJoin([ takQuery, atuRefQuery, gekoRefQuery ]).pipe(
      map(response => {
        return {
          entity: response[0],
          atuEntity: response[1],
          gekoEntity: response[2]
        };
      })
    );
        return {
          page: this.popupPage,
          entity: result.getEntityResult(),
          asyncContext: contextObservable
        };
      }

  createTooltip(marker: Marker): string {
    const description = marker.entity.getSingleElementValue('description');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text">${description}</div>`;
    html += '</div';

    return html;
  }
  protected getAtuQuery(jemusTakId) {
    return  new NGSIQuery({
      filter: {
        type: [NGSIEntity.ATU] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'refId'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            jemusTakId
          ]
        }
      },
      limit: 1000
    });
  }


  protected getTakQuery(jemusTakId) {
    return new NGSIQuery({
      filter: {
        type: [NGSIEntity.JEMUSTAK] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'id'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            jemusTakId
          ]
        }
      },
      limit: 1000,
    });
  }

  protected getGekoQuery(jemusTakId) {
    return new NGSIQuery({
      filter: {
        type: [NGSIEntity.GEKO] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'refId'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            jemusTakId
          ]
        }
      },
      limit: 1000
    });
  }

  protected getBitResultQuery(atuOrGeko) {
    return new NGSIQuery({
      filter: {
        type: [NGSIEntity.BITRESULTS] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'refId'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            atuOrGeko.result.entity.id
          ]
        }
      },
      limit: 1000
    });
  }

  protected sendAtuQuery(atuQuery): Observable<any> {
    atuQuery.lastNDays = 365;
    return this.cbrokerService.getEntities(atuQuery, new QueryOptions(false)).pipe(
      map(atu => {
        return atu.results.map(result => {
          const bitResultQuery = this.getBitResultQuery(result);
          bitResultQuery.lastNDays = 365;
          return this.cbrokerService.getEntities(bitResultQuery, new QueryOptions(false)).pipe(
            map(bitResults => ({
              'atu': result,
              'bitResults': bitResults
            }))
          );
        });
      }),
      map(queries => forkJoin(queries))
    );
  }

  protected sendGekoQuery(gekoQuery): Observable<any> {
    gekoQuery.lastNDays = 365;
    return this.cbrokerService.getEntities(gekoQuery, new QueryOptions(false)).pipe(
      map(geko => {
        return geko.results.map(result => {
          const bitResultQuery = this.getBitResultQuery(result);
          bitResultQuery.lastNDays = 365;
          return this.cbrokerService.getEntities(bitResultQuery, new QueryOptions(false)).pipe(
            map(bitResults => ({
              'geko': result,
              'bitResults': bitResults
            }))
          );
        });
      }),
      map(queries => forkJoin(queries))
    );
  }
}
