import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Component for providing a list of values. It presents an input component to type a value and displays the provided values in a 
 * select-box which allows user to remove a value from the list.
 */
@Component({
  selector: 'value-list',
  styleUrls: ['value-list.component.scss'],
  templateUrl: './value-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ValueListComponent
    }
  ]
})
export class ValueListComponent implements ControlValueAccessor, OnChanges {
  // list of values managed by this component
  @Input() valueList: string[] = null;
  @Output() valueListChange = new EventEmitter<any>();
  // input to set label of add button
  @Input() addButtonLabel: string = 'Add';
  // placeholder for text input
  // if a placeholder is provided, the input will be presented to the user even if he did not click on the add button
  // if there is no placeholder for text input, then the user should click on the add button to get text input
  @Input() inputPlaceholder: string = "";
  // size of text input
  @Input() inputFieldSize: string = "medium";

  // a flag indicating whether user is adding a value to the list
  // if it is true, a text input will be displayed for user to type a value
  public isAdding: boolean = false;
  // value to be added
  public valueToBeAdded = null;

  ngOnChanges(changes: SimpleChanges): void {
    const inputPlaceholder = changes.inputPlaceholder
    // display text input when there is a placeholder for it
    if (inputPlaceholder && inputPlaceholder.currentValue !== inputPlaceholder.previousValue) {
      this.isAdding = true
    }
  }

  /** fields to handle form control state **/

  onChange = (valueList) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;

  /** Implement ControlValueAccessor interface **/
  writeValue(valueList: string[]): void {
    this.valueList = valueList;
    // if there are values in the list, show them
    if(this.valueList?.length){
      this.isAdding = false;
    }
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** The end of implemention of ControlValueAccessor interface **/

  /**
   * Adds the provided value {@link valueToBeAdded} to {@link valueSet}
   */
  addValue(): void {
    if (this.isAdding) {
      // initialize value list if needed
      if (!this.valueList) {
        this.valueList = [];
      }

      // add value
      if (this.valueToBeAdded) {
        this.valueList.push(this.valueToBeAdded);
        this.valueListChange.emit(this.valueList);
        this.onChange(this.valueList)
        this.valueToBeAdded = null;
      }
      // if no value is typed and input placeholder is provided, the user should see the text input
      else if (this.inputPlaceholder && !this.valueList.length) {
        return;
      }
    }

    this.isAdding = !this.isAdding;
  }

  /**
   * Removes the given value from {@link valueSet}
   * @param value 
   */
  removeValue(value): void {
    if (!this.disabled) {
      this.valueList = this.valueList.filter(v => v !== value);
      this.valueListChange.emit(this.valueList);
      this.onChange(this.valueList);

      // if no value is left in the list and input placeholder is provided, the user should see the text input
      if (this.inputPlaceholder && !this.valueList.length) {
        this.isAdding = true;
      }
    }
  }
}

