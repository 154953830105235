import {BaseMetaModel} from '../base/base-meta-model.model';

export class ControllableDevice extends BaseMetaModel {
    id: string;
    type: string;
    DistributorName: string;
    BaseUrl: string;
    BaseRealm: string;
    BaseClient: string;
    BaseSecret: string;
    DataUrl: string;
    DataRealm: string;
    DataClient: string;
    DataSecret: string;
    DeviceType: string;
    DeviceId: string;

  constructor(data? :any) {
    super(data);

    if (!data) {
      return;
    }

    this.id = data.id;
    this.type = data.type;

    if (data.DistributorName != null) {
      this.DistributorName = data.DistributorName.value;
    } else {
      this.DistributorName = '';
    }

    if (data.BaseUrl != null) {
      this.BaseUrl = data.BaseUrl.value;
    } else {
      this.BaseUrl = '';
    }

    if (data.BaseRealm != null) {
      this.BaseRealm = data.BaseRealm.value;
    } else {
      this.BaseRealm = '';
    }

    if (data.BaseClient != null) {
      this.BaseClient = data.BaseClient.value;
    } else {
      this.BaseClient = '';
    }

    if (data.BaseSecret != null) {
      this.BaseSecret = data.BaseSecret.value;
    } else {
      this.BaseSecret = '';
    }

    if (data.DataUrl != null) {
      this.DataUrl = data.DataUrl.value;
    } else {
      this.DataUrl = '';
    }

    if (data.DataRealm != null) {
      this.DataRealm = data.DataRealm.value;
    } else {
      this.DataRealm = '';
    }

    if (data.DataClient != null) {
      this.DataClient = data.DataClient.value;
    } else {
      this.DataClient = '';
    }

    if (data.DataSecret != null) {
      this.DataSecret = data.DataSecret.value;
    } else {
      this.DataSecret = '';
    }

    if (data.DeviceType != null) {
      this.DeviceType = data.DeviceType.value;
    } else {
      this.DeviceType = '';
    }

    if (data.DeviceId != null) {
      this.DeviceId = data.DeviceId.value;
    } else {
      this.DeviceId = '';
    }
  }
}
