import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as maplibregl from 'maplibre-gl';
import {AnySourceData, CircleLayer, GeoJSONSource, LngLat, LngLatLike, MapboxOptions} from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {v4 as uuidv4} from 'uuid';
import {EventService} from '../../../core/services/event.service';
import {LayoutService} from '../../../core/services/layout.service';
import {LayerController} from '../../../core/controllers/layer.controller';
import {UrukMap} from '../../models/map/uruk-map.interface';
import {Layer} from '../../models/layer.model';
import {NGSIGeoQuery} from '../../models/ngsi/ngsi-geo-query.model';
import {Coordinate} from '../../models/generic/coordinate.model';
import {MapSettings} from '../../models/visualization/map-settings.model';
import {Linestring} from '../../models/map/linestring.model';
import {Marker} from '../../models/map/marker.model';
import {environment} from '../../../../environments/environment';
import {Geometry} from '../../enums/ngsi-query.enum';
import {IconVisualization} from '../../models/visualization/icon-visualization.model';
import {ClusterSetting} from '../../models/visualization/cluster-setting.model';
import {EntityRepresentation} from '../../models/visualization/representation.model';
import {evaluateExpression} from '../../../core/expression/expression-evaluator';
import {NGSIResult} from '../../models/ngsi/ngsi-result';
import {WeatherPollutionUtil} from '../../utils/weather-pollution-util';
import {Polygon} from '../../models/map/polygon.model';
import {FormUtil} from 'app/shared/utils/form-util';

@Component({
  selector: 'maplibre-map',
  templateUrl: './maplibre-map.component.html',
  styles: [
    `mgl-map {
      height: 100%;
      width: 100%;
    }`,
  ]
})
export class MaplibreMapComponent implements OnInit, OnDestroy, UrukMap {
  @Input() layers: LayerController[];
  @Input() mapSettings: MapSettings;
  @Output() onMapInitialized: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();
  @Output() onMapBoundariesChanged: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();
  @Output() onMapClicked: EventEmitter<Coordinate> = new EventEmitter<Coordinate>();
  @Output() onShapeDrawn: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();

  /**
   * Reference to the map instance
   */
  map: maplibregl.Map;

  /**
   * Generic map settings
   */
  mapLibreSettings = environment.map.maplibre;
  generalMapSettings = environment.map;

  /**
   * Array that keeps markers and shapes that are not a part of regular layers given as input.
   * Shape objects that are created as a result of drawing activities are also kept in this list
   */
  markers: Map<LayerController, Marker[]> = new Map<LayerController, Marker[]>();
  lines: Map<LayerController, any> = new Map<LayerController, any>();
  polygons: Map<LayerController, any> = new Map<LayerController, any>();

  /**
   * Reference to the current drawn object
   */
  drawControl: any;
  currentDrawnObject: any;

    // Constants
  readonly externalSourceId = 'xSource';
  readonly externalLayerId = 'xLayer';
  readonly externalBackgroundSourceId = 'background';
  readonly externalBackgroundLayerId = 'backgroundLayer';

  // Subscription object for unsubscribing when destroying the component to avoid leaks
  destroy$ = new Subject<void>();

  // Services
  private layoutService;
  private eventService;

  round = Math.round;
  assessWeatherPollutionAQI = WeatherPollutionUtil.assessWeatherPollutionAQI;

  constructor(private injector: Injector) {
    this.layoutService = injector.get(LayoutService);
    this.eventService = injector.get(EventService);
  }

  ngOnInit() {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.LAYER_PROCESSING_COMPLETED: {
            const layer = event.data as LayerController;

            // identify map objects
            this.markers.set(layer, layer.mergedMarkers.filter(item => this.isMarker(item)));

            if (!this.lines.get(layer)) {
              this.lines.set(layer, {});
            }
            this.lines.get(layer).lines = layer.mergedMarkers.filter(item => this.isLine(item));

            if (!this.polygons.get(layer)) {
              this.polygons.set(layer, {});
            }
            this.polygons.get(layer).polygons = layer.mergedMarkers.filter(item => this.isPolygon(item));

            // process lines
            this.processLines(layer);
            this.processPolygons(layer);
            this.processClusters(layer);
            break;
          }
          case EventService.HIDE_LAYERS:
            this.getClusterLayers().forEach(layer => this.map.setLayoutProperty(layer.id, 'visibility', 'none'));

            for (let [key, layerData] of this.lines.entries()) {
              this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'none');
            }
            for (let [key, layerData] of this.polygons.entries()) {
              this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'none');
            }
            break;
          case EventService.SHOW_LAYERS:
            this.getClusterLayers().forEach(layer => this.map.setLayoutProperty(layer.id, 'visibility', 'visible'));

            for (let [key, layerData] of this.lines.entries()) {
              this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'visible');
            }
            for (let [key, layerData] of this.polygons.entries()) {
              this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'visible');
            }
            break;
          case EventService.LAYER_VISIBILITY_TOGGLED:
            const layer = event.data as LayerController;

            if (layer.visibleOnMap) {
              this.getClusterLayers(layer.layer.id).forEach(layer => this.map.setLayoutProperty(layer.id, 'visibility', 'visible'));

              this.map.setLayoutProperty(this.lines.get(layer).layer.id, 'visibility', 'visible');
              this.map.setLayoutProperty(this.polygons.get(layer).layer.id, 'visibility', 'visible');
            } else {
              this.getClusterLayers(layer.layer.id).forEach(layer => this.map.setLayoutProperty(layer.id, 'visibility', 'none'));

              this.map.setLayoutProperty(this.lines.get(layer).layer.id, 'visibility', 'none');
              this.map.setLayoutProperty(this.polygons.get(layer).layer.id, 'visibility', 'none');
            }
            break;
        }
      });
    this.getZoomCoordinates();
    this.getZoomLevel();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onMapReady(map: maplibregl.Map) {
    this.map = map;
    // initialize drawing tools
    this.drawControl = new MapboxDraw({
      displayControlsDefault: false
    });
    this.map.addControl(this.drawControl, 'top-left');

    // Handle map events
    this.map.on('draw.create', this.onShapeCreated.bind(this));
    this.map.on('draw.update', this.onShapeUpdated.bind(this));
    this.map.on('draw.delete', this.onShapeDeleted.bind(this));

    // emit map initialize event
    this.onMapInitialized.emit(this.getGeoQuery());

    // if map is initialized with a specific zoom level, broadcast it
    if (this.mapSettings?.zoomLevel) {
      this.onZoomChange(this.mapSettings.zoomLevel);
    }
  }

  onMapClick(event) {
    this.onMapClicked.emit(new Coordinate({latitude: event.lngLat.lat, longitude: event.lngLat.lng}));
  }

  onMapMoveEnd(data) {
    // broadcast map change event when map is moved only if there are no drawn shapes
    // TODO: handle cases when there are drawn shapes
    //if (this.getFirstDrawnShape() === null) {
    localStorage.setItem('mapCoordinateLat', String(this.map.getCenter().lat));
    localStorage.setItem('mapCoordinateLng', String(this.map.getCenter().lng));
    this.onMapBoundariesChanged.emit(this.getGeoQuery());
    // }
  }

  onZoomChange(event): void {
    if(this.map){
      localStorage.setItem("mapZoomProvider", "mapLibre");
      localStorage.setItem("mapZoomLevel", this.map.getZoom().toFixed());
      this.layoutService.onMapZoomChange(this.map.getZoom());
    }
  }

  onZoomIn(): void {
    this.map.zoomIn();
  }

  onZoomOut(): void {
    this.map.zoomOut();
  }

  onMapAreaChanged(): void {
    this.map?.resize();
  }

  panAndZoom(coordinate: Coordinate, zoomLevel: number, speed: number = 1.2): void {
    this.map.flyTo({center: [coordinate.longitude, coordinate.latitude], zoom: zoomLevel, speed: speed});
  }

  pan(coordinate: Coordinate): void {
    this.map.panTo(new LngLat(coordinate.longitude, coordinate.latitude));
  }

  addLayers(layers: Layer[]): void {
  }

  addIconMarker(coordinate: Coordinate, options: any): void {
    const marker = new maplibregl.Marker();
    marker.setLngLat([coordinate.longitude, coordinate.latitude]);
    marker.addTo(this.map);
    //this.markers.push(marker);
  }

  addGeoQueryAsShape(geoQuery: NGSIGeoQuery): void {
    const object: any = this.transformGeoQuery(geoQuery);
    if (object instanceof maplibregl.Marker) {
      object.addTo(this.map);
    } else {
      // delete the existing external objects before creating a new one
      this.removeExternalObjects();
      // delete the drawn shapes
      this.deleteDrawnShapes();
      this.addPolygon(object, this.externalSourceId, this.externalLayerId);
      // draw the shape on the map so that user can edit it
      const featureIds = this.drawControl.add(object.data);
      // update the reference of drawn object
      this.currentDrawnObject = {
        features: featureIds.map(featureId => this.drawControl.get(featureId))
      }
    }

    //this.markers.push(marker);
  }

  addGeoQueryAsBackgroundShape(geoQuery: NGSIGeoQuery): void {
    const object: any = this.transformGeoQuery(geoQuery);
    if (object instanceof maplibregl.Marker) {
      throw new Error('Method not implemented to handle Marker objects.');
    } else {
      this.addPolygon(object, this.externalBackgroundSourceId, this.externalBackgroundLayerId,'#de0910',0.2);
    }
  }

  clearBackgroundShapes(): void {
    // if the background shape exists, remove the corresponding source and layer
    if(this.map.getSource(this.externalBackgroundSourceId)){
      this.map.removeLayer(this.externalBackgroundLayerId);
      this.map.removeSource(this.externalBackgroundSourceId);
    }
  }

  drawnShapeExists(): boolean {
    return !FormUtil.isEmptyValue(this.currentDrawnObject);
  }

  clearMarkers(): void {
    for (let [key, markers] of this.markers) {
      this.markers.set(key, []);
    }
  }

  getBounds(): Coordinate[] {
    if(this.map){
      const bounds: any = this.map.getBounds();
      const north = bounds.getNorth();
      const south = bounds.getSouth();
      const west = bounds.getWest();
      const east = bounds.getEast();
      // First and last coordinates must be the same to construct a loop for MongoDB queries
      return [
        new Coordinate({longitude: west, latitude: north}),
        new Coordinate({longitude: east, latitude: north}),
        new Coordinate({longitude: east, latitude: south}),
        new Coordinate({longitude: west, latitude: south}),
        new Coordinate({longitude: west, latitude: north})
      ];
    }
  }

  getShapeCoordinates(shape: any): Coordinate[] {
    if (shape && shape.features && shape.features.length > 0) {
      const coordinates = shape.features[0].geometry.coordinates;
      return coordinates[0].map(item => new Coordinate({longitude: item[0], latitude: item[1]}));
    }
    return [];
  }

  fitBounds(bounds: [number, number, number, number]): void {
    this.map.fitBounds(bounds, {linear: true})
  }

  setMaxBounds(bounds?: [number, number, number, number]): void {
    if(bounds){
      this.map.setMaxBounds(bounds)
    } else {
      this.map.setMaxBounds([this.generalMapSettings.bounds[0], this.generalMapSettings.bounds[1], this.generalMapSettings.bounds[2], this.generalMapSettings.bounds[3]]);
    }
  }

  onDrawCircleInitiated(): void {
    // not supported by maplibre
  }

  onDrawPolygonInitiated(): void {
    //remove the external objects first
    this.removeExternalObjects();
    this.onShapeDeleted();

    this.drawControl.changeMode('draw_polygon');
  }

  onDrawRectangleInitiated(): void {
    //remove the external objects first
    this.removeExternalObjects();

    this.drawControl.changeMode('draw_rectangle');
  }

  onDrawnShapeRemoved(): void {
    //remove the external objects first
    this.removeExternalObjects();
    this.onShapeDeleted();

    this.drawControl.trash();
  }

  onShapeCreated(data) {
    this.currentDrawnObject = data;

    // broadcast the information containing the geological boundaries of the drawn shape
    this.onShapeDrawn.emit(this.getGeoQuery());
  }

  onShapeUpdated(data) {
    this.currentDrawnObject = data;

    // broadcast the information containing the geological boundaries of the drawn shape
    this.onShapeDrawn.emit(this.getGeoQuery());
  }

  onShapeDeleted() {
    this.deleteDrawnShapes();

    // broadcast the information containing the geological boundaries of the drawn shape
    this.onShapeDrawn.emit(this.getGeoQuery());
  }

  /**
   * Function to modify requests for an external URL in map
   * */
  public transformRequest: MapboxOptions['transformRequest'] = (url, resourceType) => {
    return {
      url: url,
      // TODO: Map Test server returns 400 when we send Authorization header. Uncomment it when the problem is fixed.
      //headers: { 'Authorization': 'Bearer ' + StorageUtil.getUMATicket() }
    };
  }

  public getGeoQuery(): NGSIGeoQuery {
    const coordinates = this.currentDrawnObject ? this.getShapeCoordinates(this.currentDrawnObject) : this.getBounds();
    return coordinates ? NGSIGeoQuery.createPolygonQuery(coordinates) : null;
  }

  transformGeoQuery(query: NGSIGeoQuery): any {
    if (query.geometry === Geometry.POINT) {
      return new maplibregl.Marker().setLngLat(query.coordinates as LngLatLike)
    } else {
      return {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': query.coordinates
          }
        }
      }
    }
  }

  onMarkerClicked(marker: Marker) {
    marker.callback();
  }

  onLineClicked(event) {
    const feature = event.features[0]; // GeoJson feature corresponding to a Linestring

    // find the related line && execute the callback
    for (let [key, layerData] of this.lines.entries()) {
      const filtered = layerData.lines.filter(line => line.id === feature.properties.id);
      if (filtered.length > 0) {
        const line = filtered[0];
        line.callback();
        break;
      }
    }
  }

  onPolygonClicked(event) {
    const feature = event.features[0]; // GeoJson feature corresponding to a Polygon

    // find the related line && execute the callback
    for (let [key, layerData] of this.polygons.entries()) {
      const filtered = layerData.polygons.filter(line => line.id === feature.properties.id);
      if (filtered.length > 0) {
        const line = filtered[0];
        line.callback();
        break;
      }
    }
  }

  isMarker(object) {
    return object instanceof Marker;
  }

  isLine(object) {
    return object instanceof Linestring;
  }

  isPolygon(object) {
    return object instanceof Polygon;
  }

  /**
   * Deletes shapes which are drawn via MapboxDraw.
   */
  private deleteDrawnShapes(){
    if(this.currentDrawnObject){
      // remove the features of drawn object
      this.drawControl.delete(this.currentDrawnObject.features.map(feature => feature.id))
      this.currentDrawnObject = undefined
    }
  }

  private processLines(layerController: LayerController) {
    if(!this.map){
      return;
    }
    // corresponding layer data for the current layerController
    const layerData = this.lines.get(layerController);

    // create data source for lines
    const geoJsonSource = {
      type: 'geojson',
      data: {
        type: "FeatureCollection",
        features: layerData.lines.map(item => (item as Linestring).toGeoJson())
      }
    } as AnySourceData;

    // manage the data source
    if (layerData.layer) { // if there is already an existing layer, remove it with its source
      // remove the old layer and source
      this.map.removeLayer(layerData.layer.id);
      this.map.removeSource(layerData.layer.source);

      this.map.off('click', layerData.layer.id);
      this.map.off('mouseenter', layerData.layer.id);
      this.map.off('mouseleave', layerData.layer.id);
    }

    // create a new layer and add it to the map along with its data source
    layerData.layer = this.createLineLayer();
    this.map.addSource(layerData.layer.source, geoJsonSource);
    this.map.addLayer(layerData.layer);

    this.map.on('click', layerData.layer.id, (event) => {this.onLineClicked(event) });
    this.map.on('mouseenter', layerData.layer.id, () => { this.map.getCanvas().style.cursor = 'pointer' });
    this.map.on('mouseleave', layerData.layer.id, () => { this.map.getCanvas().style.cursor = '' });
  }

  private processPolygons(layerController: LayerController) {
    // corresponding layer data for the current layerController
    const layerData = this.polygons.get(layerController);
    if(!this.map){
      return;
    }
    // create data source for polygons
    const geoJsonSource = {
      type: 'geojson',
      data: {
        type: "FeatureCollection",
        features: layerData.polygons.map(item => (item as Polygon).toGeoJson())
      }
    } as AnySourceData;

    // manage the data source
    if (layerData.layer) { // if there is already an existing layer, remove it with its source
      // remove the old layer and source
      this.map.removeLayer(layerData.layer.id);
      this.map.removeSource(layerData.layer.source);

      this.map.off('click', layerData.layer.id);
      this.map.off('mouseenter', layerData.layer.id);
      this.map.off('mouseleave', layerData.layer.id);
    }

    // create a new layer and add it to the map along with its data source
    layerData.layer = this.createPolygonLayer();
    this.map.addSource(layerData.layer.source, geoJsonSource);
    this.map.addLayer(layerData.layer);

    this.map.on('click', layerData.layer.id, (event) => {this.onPolygonClicked(event) });
    this.map.on('mouseenter', layerData.layer.id, () => { this.map.getCanvas().style.cursor = 'pointer' });
    this.map.on('mouseleave', layerData.layer.id, () => { this.map.getCanvas().style.cursor = '' });
  }

  private createLineLayer(): any {
    return {
      id: uuidv4(),
      type: 'line',
      source: uuidv4(),
      layout: {
        visibility: 'visible',
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': ['get', 'weight'],
        'line-opacity': ['get', 'opacity']
      }
    };
  }

  private createPolygonLayer(): any {
    return {
      id: uuidv4(),
      type: 'fill',
      source: uuidv4(),
      paint: {
        'fill-color': ['get', 'fillColor'],
        'fill-opacity': ['get', 'opacity']
      }
    };
  }

  private removeExternalObjects() {
    const externalLayer = this.map.getLayer(this.externalLayerId);
    if (externalLayer) {
      this.map.removeLayer(this.externalLayerId);
    }

    const externalSource = this.map.getSource(this.externalSourceId);
    if (externalSource) {
      this.map.removeSource(this.externalSourceId);
    }
  }

  /**
   * Creates clusters for each icon representation of layer if the clustering is enabled for them.
   * @param layerController the layer controller
   * */
  private processClusters(layerController: LayerController) {
    if(!this.map){
      return;
    }
    // create a cluster for each icon visualization in layer
    const entityRepresentations: EntityRepresentation[] = Array.from(layerController.markers.keys());
    entityRepresentations.forEach((representation, index
    ) => {
      if (representation.visualization instanceof IconVisualization) {
        const clusterSettings: ClusterSetting[] = representation.visualization.clusterSettings;
        const markers = layerController.markers.get(representation);
        // keeps the markers which do not belong to any cluster
        const unClusteredMarkers = new Set(markers);
        // if the clustering is enabled for icon representation, cluster the markers
        if (clusterSettings?.length && markers.length) {
          clusterSettings.forEach(clusterSetting => {
            const markersForCluster = [];
            markers.forEach(marker => {
              // add marker to the cluster if there is no condition for the cluster or the marker satisfies the condition
              if (!clusterSetting.expression || evaluateExpression(clusterSetting.expression, new NGSIResult(marker.entity))) {
                markersForCluster.push(marker);
                // since the marker will be added to the cluster, remove it from unclustered markers list
                unClusteredMarkers.delete(marker)
              }
            });

            const clusterLayerId = `cluster-${layerController.layer.id}-${index}-${clusterSetting.color}`;
            const clusterCountLayerId = `cluster-count-${layerController.layer.id}-${index}-${clusterSetting.color}`;
            // create data source for markers
            const geoJsonSource = {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: markersForCluster.map(item => item.toGeoJson())
              },
              cluster: true,
              clusterMaxZoom: clusterSetting.zoom,
              clusterRadius: clusterSetting.radius
            } as GeoJSONSource;

            // if there is already an existing layer, update its data
            if (this.map.getLayer(clusterLayerId)) {
              (this.map.getSource(clusterLayerId) as GeoJSONSource).setData(geoJsonSource.data);
            } else {
              // create a layer for cluster
              const layer: CircleLayer = {
                type: 'circle',
                source: clusterLayerId,
                id: clusterLayerId,
                filter: ['has', 'point_count'],
                paint: {
                  'circle-color': clusterSetting.color,
                  'circle-stroke-color': 'white',
                  'circle-stroke-width': 1,
                  'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40
                  ]
                }
              };
              // add source and cluster layer
              this.map.addSource(layer.source as string, geoJsonSource);
              this.map.addLayer(layer);
              // add a layer to display entity count in clusters
              this.map.addLayer({
                id: clusterCountLayerId,
                type: 'symbol',
                source: clusterLayerId,
                filter: ['has', 'point_count'],
                layout: {
                  'text-field': '{point_count_abbreviated}',
                  'text-size': 16
                },
                paint: {
                  'text-color': 'white'
                }
              });
            }
            // when the map is loaded, mark the visibility of markers
            let isLoaded = false;
            this.map.on('render', function() {
              if (!this.map.loaded() || isLoaded) return;
              this.handleMarkerVisibilityForClusters(clusterLayerId, markersForCluster);
              isLoaded = true;
            }.bind(this));
            // when a zoom event occurs, handle the visibility of markers
            this.map.on('zoomend', function () {
              this.handleMarkerVisibilityForClusters(clusterLayerId, markersForCluster);
            }.bind(this));
          })
        }
        // display unclustered markers
        unClusteredMarkers.forEach(marker => marker.visible = true)
      }
    });
  }

  /**
   * Decides whether the markers are supposed to be shown on the map or not. If a marker does not belong to a cluster,
   * it should be displayed on the map.
   * @param clusterLayerId the cluster layer id
   * @param markers the marker list
   * */
  private handleMarkerVisibilityForClusters(clusterLayerId, markers) {
    const unClusteredFeatures = this.map.querySourceFeatures(clusterLayerId, {filter: ['!', ['has', 'point_count']]});

    for (const marker of markers) {
      marker.visible = !!unClusteredFeatures.find(feature => marker.coordinates.toString() === feature.properties.coordinates);
    }
  }

  /**
   * Retrieves the list of Maplibre layers for the given id. If no id is given, it returns the all of them.
   * @param id the layer id
   * @returns the map layers
   */
  private getClusterLayers(id = null): any[]{
    if(id){
      const layerId = `cluster-${id}`
      const layerCountId = `cluster-count-${id}`
      return this.map.getStyle().layers
              .filter(layer => layer.id.startsWith(layerId) || layer.id.startsWith(layerCountId));
    } else {
      return this.map.getStyle().layers
      .filter(layer => layer.id.startsWith('cluster-'));
    }
  }

  /**
   * Adds the given geojson polygon to map.
   * @param object Geojson object
   * @param sourceId The id of source to be added to map's style.
   * @param layerId The id of layer to be added to the map's style.
   * @param fillColor The color of polygon
   * @param fillOpacity The opacity of polygon
   */
  private addPolygon(object:any, sourceId: string, layerId: string, fillColor: string = '#e9a43a', fillOpacity: number = 0.5){
    this.map.addSource(sourceId, object);
    this.map.addLayer({
      'id': layerId,
      'type': 'fill',
      'source': sourceId,
      'layout': {},
      'paint': {
        'fill-color': fillColor,
        'fill-opacity': fillOpacity
      }
    });
  }

  /**
   * Sets the visibility of tooltip for the marker.
   * @param marker the marker
   * @param layerDisplaysTooltip whether the layer items has a tooltip display or not
   * @param display whether the tooltip will be displayed or not
   * */
  public setTooltipVisibility(marker: Marker, layerDisplaysTooltip: boolean, display: boolean) {
    let style : string;
    marker.displayTooltip = layerDisplaysTooltip && display;

    if (marker.style) {
      if (display) {
        style = `background-image: url(${marker.iconUrl});  transition: transform 0.2s; transform-origin: bottom center; transform: scale(2); background-size: 100% 100%; background-repeat:no-repeat; width: ${marker.width}px; height: ${marker.height}px`;
      } else {
        style = `background-image: url(${marker.iconUrl}); background-size: 100% 100%; background-repeat:no-repeat; width: ${marker.width}px; height: ${marker.height}px`;
      }
      marker.style = style;
    } else {
      if (display) {
        marker.htmlStyle = 'transition: transform 0.2s; transform-origin: bottom center; transform: scale(2)';
      } else {
        marker.htmlStyle = '';
      }
    }

  }

  localStorage = localStorage;

  getZoomLevel() {
    if(localStorage.getItem("mapZoomProvider") === 'mapLibre'  && this.mapSettings){
      this.mapSettings.zoomLevel = Number(localStorage.getItem("mapZoomLevel"));
    }else if(localStorage.getItem("mapZoomProvider") === 'leaflet' && this.mapSettings){
      this.mapSettings.zoomLevel = Number(localStorage.getItem("mapZoomLevel")) - 1;
    }
  }

  getZoomCoordinates() {
    if(localStorage.getItem("mapZoomProvider") === 'mapLibre' && this.mapSettings){
      this.mapSettings.point.coordinates = [Number(localStorage.getItem("mapCoordinateLat")), Number(localStorage.getItem("mapCoordinateLng"))];
    }else if(localStorage.getItem("mapZoomProvider") === 'leaflet' && this.mapSettings){
      this.mapSettings.point.coordinates = [Number(localStorage.getItem("mapCoordinateLat")), Number(localStorage.getItem("mapCoordinateLng"))];
    }
  }
}
