import {Component, Injector, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {BaseComponent} from '../../../shared/components/base.component';

@Component({
  templateUrl: './add-page-icon-dialog.component.html',
  styleUrls: ['./add-page-icon-dialog.component.scss']
})
export class AddPageIconDialogComponent extends BaseComponent {

  @Input() selectedIcon: string;

  constructor(protected injector: Injector,
              private dialogRef: NbDialogRef<AddPageIconDialogComponent>) {
    super(injector);
  }

  /**
   * Marks the given icon as selected.
   * @param icon the selected icon
   * */
  onIconSelected(icon) {
    this.selectedIcon = icon;
  }

  /**
   * Closes dialog with selected icon.
   * */
  public save() {
    this.dialogRef.close(this.selectedIcon);
  }
}
