import {BaseMetaModel} from './base/base-meta-model.model';
import {GridLocation} from './visualization/grid-location.model';

/**
 * Page templates are the entities representing the structure of pages. Specifically, they mark the locations of panels
 * in the page. Each template is visualized as grid.
 */
export class PageTemplate extends BaseMetaModel {

  /**
   * The title of page template
   * */
  title: string;

  /**
   * Reference to the items in the template
   */
  items: GridLocation[];

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    if (data.items) {
      this.items = data.items.map(item => new GridLocation(item));
    }
    this.title = data.title;
  }

}
