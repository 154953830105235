<div class="kpi-search">

  <div class="column search-column">
    <label for="searchByName" class="label header">
      {{ 'Search with name' | translate }}
    </label>
    <input type="text" nbInput fullWidth id="searchByName" name="searchByName" [(ngModel)]="kpiSearchTerm" (ngModelChange)="onKpiSearchTermChanged()">

    <div class="separator"></div>

    <label class="label header">
      {{ 'Search with tag' | translate }}
    </label>

    <search-tag-input (onTagSelected)="selectTag($event)" *ngIf="tags && tags.length > 0"
                      [tags]="tags"></search-tag-input>
  </div>

  <div class="column list-column">
    <div class="selected-tag-container flex-box" *ngIf="selectedTags.length > 0">
      <div class="selected-tag" *ngFor="let tag of selectedTags;let i=index">
        <label class="label">{{ tag.name | translate }}</label>
        <i class="fa fa-times" (click)="removeSelectedTag(i)"></i>
      </div>

      <button class="clear-all" nbButton status="danger" size="small"
              *ngIf="selectedTags.length > 1" (click)="removeAllSelectedTags()">
        {{ 'Clear All' | translate }}
      </button>
    </div>

    <div class="kpi-container" [ngClass]="{ 'flex-centered': kpis.length === 0, 'error': displayErrors && !selectedKPI }">
      <ng-container *ngIf="kpis.length > 0">
        <div class="line-item" *ngFor="let kpi of kpis" (click)="selectKPI(kpi)">
          <label class="label" [ngClass]="{ 'selected': selectedKPI && selectedKPI.id === kpi.id }">{{ kpi.name }}</label>
        </div>
      </ng-container>
      <ng-container *ngIf="kpis.length === 0">
        <label class="label"> {{ 'No indicator found.' | translate }}</label>
      </ng-container>
    </div>
  </div>

</div>
