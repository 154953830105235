<div class="release-notes-dialog">
  <div class="header">
    <span>{{'Bilgilendirme'|translate}}</span>
    <uruk-svg
      [paths]="['M12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0ZM12.6 18H11.4C11.0688 18 10.8 17.7312 10.8 17.4V16.2C10.8 15.8688 11.0688 15.6 11.4 15.6H12.6C12.9312 15.6 13.2 15.8688 13.2 16.2V17.4C13.2 17.7312 12.9312 18 12.6 18ZM12 13.2C11.3376 13.2 10.8 12.6624 10.8 12V7.2C10.8 6.5376 11.3376 6 12 6C12.6624 6 13.2 6.5376 13.2 7.2V12C13.2 12.6624 12.6624 13.2 12 13.2Z']"
      [svgContainerClass]="'uruk-icon'"
      [svgContainerStyle]="'width: 1.5vw'"
      [viewbox]="'0 0 24 24'">
    </uruk-svg>
    <span>{{releaseNotes.date}}</span>
    <div class="header-close">
      <uruk-svg
        (click)="close()"
        [paths]="['M1.00472 0.000434763C0.80579 0.000487008 0.611391 0.0598657 0.446382 0.170979C0.281372 0.282093 0.153249 0.439893 0.0783918 0.624205C0.00353476 0.808517 -0.0146545 1.01097 0.0261495 1.20567C0.0669535 1.40037 0.164897 1.57848 0.307457 1.71723L6.60043 8.0102L0.307457 14.3032C0.211484 14.3953 0.134861 14.5057 0.0820759 14.6278C0.0292909 14.7499 0.00140533 14.8814 5.17615e-05 15.0144C-0.00130181 15.1475 0.0239041 15.2794 0.0741933 15.4026C0.124482 15.5258 0.198844 15.6377 0.292923 15.7318C0.387002 15.8258 0.498906 15.9002 0.622083 15.9505C0.745261 16.0008 0.877234 16.026 1.01027 16.0246C1.14332 16.0233 1.27475 15.9954 1.39688 15.9426C1.51901 15.8898 1.62937 15.8132 1.72152 15.7172L8.01449 9.42426L14.3075 15.7172C14.3996 15.8132 14.51 15.8898 14.6321 15.9426C14.7542 15.9954 14.8857 16.0233 15.0187 16.0246C15.1517 16.026 15.2837 16.0008 15.4069 15.9505C15.5301 15.9002 15.642 15.8259 15.7361 15.7318C15.8301 15.6377 15.9045 15.5258 15.9548 15.4026C16.0051 15.2794 16.0303 15.1475 16.0289 15.0144C16.0276 14.8814 15.9997 14.7499 15.9469 14.6278C15.8941 14.5057 15.8175 14.3953 15.7215 14.3032L9.42855 8.0102L15.7215 1.71723C15.866 1.57677 15.9647 1.39593 16.0047 1.19841C16.0446 1.00089 16.024 0.795905 15.9455 0.610319C15.8669 0.424733 15.7341 0.267204 15.5645 0.158375C15.3949 0.0495457 15.1964 -0.0055056 14.995 0.000434763C14.7352 0.0081759 14.4886 0.116755 14.3075 0.303169L8.01449 6.59614L1.72152 0.303169C1.62833 0.207375 1.51689 0.13123 1.39377 0.0792331C1.27066 0.0272363 1.13837 0.000442554 1.00472 0.000434763Z']"
        [svgContainerClass]="'uruk-icon'"
        [svgContainerStyle]="'width: 1vw'"
        [viewbox]="'0 0 17 17'" class="action-icon">
      </uruk-svg>
    </div>
  </div>
  <div class="body">
    <div class="title">
      {{releaseNotes.title}}
    </div>
    <div class="content custom-vertical-scrollbar">
      <span>{{releaseNotes.message}}</span>
      <ul>
        <li *ngFor="let item of releaseNotes.items">{{item}}</li>
      </ul>
    </div>
  </div>
</div>
