<div class="option-input">
  <span *ngIf="label" class="label input-label">{{label | translate}}</span>
  <div *ngIf="multiValue">
    <ng-container *ngFor="let option of managedValues; let i = index">
<!--      <span *ngIf="displayMode === 'view'">{{nameFormatter(value) | translate}}</span>-->
      <div *ngIf="displayMode === 'edit'" class="value-row">
        <nb-select [selected]="option" (selectedChange)="onExistingValueChanged(i, $event)" selectWidth [options]="options" [formatter]="optionFormatter">
          <!-- The selected option -->
          <nb-option [value]="option">{{nameFormatter(option) | translate}}</nb-option>
          <!-- Remaining options which can be used to replace selected one -->
          <nb-option *ngFor="let op of remainingOptions" [value]="op">{{nameFormatter(op) | translate}}</nb-option>
        </nb-select>
        <i class="action-icon fa fa-minus" (click)="onDeleteValue(i)"></i>
      </div>
    </ng-container>
    <div>
      <!-- Remaining options which can be added to the managed ones -->
      <ng-container *ngIf="remainingOptions">
        <hr *ngIf="managedValues?.length > 0">
        <nb-select [selected]="newValue" (selectedChange)="onNewValueChanged($event)" selectWidth [options]="options" [formatter]="optionFormatter">
          <nb-option *ngFor="let option of remainingOptions" [value]="option">{{nameFormatter(option) | translate}}</nb-option>
        </nb-select>
        <i class="action-icon fa fa-plus" (click)="onAddValue()"></i>
      </ng-container>
    </div>
  </div>
<!--  <nb-select *ngIf="!multiValue" [selected]="getOptionValue(newValue)" (selectedChange)="onNewValueChanged($event)">-->
  <nb-select *ngIf="!multiValue" [selected]="newValue" (selectedChange)="onNewValueChanged($event)" selectWidth [options]="options" [formatter]="optionFormatter">
    <nb-select-label>
      {{ nameFormatter(newValue, true) | translate }}
    </nb-select-label>
    <nb-option *ngFor="let option of options" [value]="option">{{nameFormatter(option) | translate}}</nb-option>
  </nb-select>
</div>

