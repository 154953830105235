import {Component, Injector, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MenuItem} from '../../../shared/models/menu/menu-item';
import {NbSidebarService} from '@nebular/theme';
import {BaseComponent} from '../../../shared/components/base.component';
import {takeUntil} from 'rxjs/operators';
import {MenuItemComponent} from './menu-item.component';
import {Router} from '@angular/router';

/**
 * Displays the menu for the given menu items.
 * */
@Component({
  selector: 'uruk-menu',
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html',
})
export class MenuComponent extends BaseComponent implements OnInit {

  public menuItems: MenuItem[];

  // keeps MenuItemComponents for the menu items
  @ViewChildren(MenuItemComponent) menuItemRefs: QueryList<MenuItemComponent>;
  // keeps whether the sidebar is expanded or not
  public isSidebarExpanded: boolean = true;

  constructor(injector: Injector,
              private router: Router,
              private sidebarService: NbSidebarService) {
    super(injector);
  }

  ngOnInit() {
    this.subscribeToData();
  }

  /**
   * Handles the click on icon of first level menu items
   * @param index the index of menu item which is clicked
   * */
  onIconClicked(index: number) {
    // navigate to the link if the menu item has any
    if (this.menuItems[index].link) {
      this.router.navigate([this.menuItems[index].link]);
    }
    // navigate user to the url in a new tab
    else if (this.menuItems[index].url) {
      window.open(this.menuItems[index].url, '_blank');
    } else if (this.menuItems[index].callbackFunction) {
      this.menuItems[index].callbackFunction();
    }
    // otherwise, expand the menu item
    else {
      // expand the sidebar if it is collapsed
      if (!this.isSidebarExpanded) {
        this.sidebarService.toggle(true, 'menu-sidebar');
      }
      // call onMenuItemClicked method of the corresponding menu item to expand it
      const menuItemComponent: MenuItemComponent = this.menuItemRefs.get(index);
      menuItemComponent.onMenuItemClicked();
    }
  }

  private subscribeToData() {
    this.menuService.isSidebarExpanded
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(isSidebarExpanded => {
        this.isSidebarExpanded = isSidebarExpanded;
        // when the sidebar is expanded, expand the selected menu item according to the current url
        if (this.isSidebarExpanded && this.menuItems) {
          this.menuService.expandSelectedMenuItem();
        }
        // otherwise, collapse menu items
        else {
          this.menuService.onMenuItemSelected(null);
        }
      });
    this.menuService.menuItems
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(menuItems => {
        this.menuItems = menuItems;
        // expand the selected menu item if the sidebar is expanded
        if (this.isSidebarExpanded) {
          setTimeout(() => {
            this.menuService.expandSelectedMenuItem();
          });
        }
      });
  }
}
