import { BaseMetaModel } from './base/base-meta-model.model';

/**
 * Indicates a realm logo.
 */
export class RealmLogo extends BaseMetaModel {

    // file content as a base64 encoded string
    content: string;

    // file name of realm logo
    fileName: string;

    constructor(data?) {
        super(data);

        if (!data) {
            return;
        }

        this.content = data.content;
        this.fileName = data.fileName;
    }
}
