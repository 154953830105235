import {EntityResult} from '../schema/entity-result';

export class Marker {
  coordinates: number[];
  iconUrl: string;
  html: string;
  width: number;
  height: number;
  visible: boolean = false; // indicates the visibility of marker on the map
  entity: EntityResult;
  displayTooltip: boolean; // whether the tooltip is displayed when it is hovered
  callback: () => {};

   style: string;

   htmlStyle : string;

  constructor(coordinates, iconUrl, width, height, html) {
    this.coordinates = coordinates.reverse();
    if(iconUrl){
      this.iconUrl = '/' + iconUrl;
    }
    if(html){
      this.html = html;
    }
    this.width = width;
    this.height = height;

    if(this.iconUrl){
      this.style = `background-image: url(${this.iconUrl}); background-size: 100% 100%; background-repeat:no-repeat; width: ${this.width}px; height: ${this.height}px`;
    }
  }

  /**
   * Transforms the marker to GeoJson format
   */
  toGeoJson(): any {
    return {
      type: 'Feature',
      properties: {
        coordinates: this.coordinates.toString() // to be used as unique identifier for marker
      },
      geometry: {
        type: 'Point',
        coordinates: this.coordinates
      }
    };
  }
}
