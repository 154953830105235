import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';

@Component({
  selector: 'tcddTrain-dialog-template',
  templateUrl: './tcddTrain-dialog-template.component.html',
  styleUrls: ['./tcddTrain-dialog-template.component.scss']
})
export class TcddTrainDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  activeTrainEntity: any;
  passiveTrainEntity: any;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected mapContextData(context: any): void {
    this.activeTrainEntity = context.activeTrain ? context.activeTrain.entity : context.result.activeTrain.entity;
    this.passiveTrainEntity =  context.passiveTrain ? context.passiveTrain.entity : context.result.passiveTrain.entity;
  }
}
