<closeable-dialog class="defibrillator-dialog-container" [displayFooter]="showFooter">
  <div class="dialog-header title" dialog-card-header><label>{{ 'droneInformation' | translate }} : {{droneId}} </label> </div>

  <div dialog-card-body class="dialog-body full-height">
    <div class="centered-image">
      <img class="img1" src="assets/images/drone.png" width="300" height="300" />
    </div>
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab tabTitle="{{'summary' | translate}}" [active]="activeTab === 'summary'" [tabId]="'summary'">
        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'General Information' | translate}} : </label>
          </div>
          <div class="flex-50">
            <label class="column-info title">{{'IOT Module' | translate}} : </label>
          </div>
        </div>


        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'AEDDeviceModel' | translate}} : </label>
            <label class="column-info content">{{AEDDeviceModel}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'iotModuleSerialNumber' | translate}} :</label>
            <label class="column-info content">{{iotModuleSerialNumber}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'AEDSerialNumber' | translate}} : </label>
            <label class="column-info content">{{AEDSerialNumber}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'iotModuleSoftwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{iotModuleSoftwareRevisionNumber}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'batterySerialNumber' | translate}} : </label>
            <label class="column-info content">{{batterySerialNumber}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'iotModuleHardwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{iotModuleHardwareRevisionNumber}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title"> {{'softwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{softwareRevisionNumber}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'hardwareRevisionNumber' | translate}} :</label>
            <label class="column-info content">{{hardwareRevisionNumber}}</label>
          </div>
        </div>

        <div class="d-flex">
          <div class="flex-50 ">

            <div  class="d-flex ">
              <div class="flex-50">
                <label class="column-info title"> {{'primaryLanguagePack' | translate}} :</label>
                <label class="column-info content">{{primaryLanguagePack}}</label>
              </div>
            </div>

            <div  class="d-flex ">
              <div class="flex-50">
                <label class="column-info title"> {{'secondaryLanguagePack' | translate}} :</label>
                <label class="column-info content">{{secondaryLanguagePack}}</label>
              </div>
            </div>
          </div>
        </div>

      </nb-tab>
      <nb-tab tabTitle="{{'bit' | translate}}" [active]="activeTab === 'bit'" [tabId]="'bit'">
        <table id="defibrillator-table">
          <thead>
          <tr [ngClass]="'header'">
            <th>{{'testTime' | translate}}</th>
            <th>{{'testType' | translate}}</th>
            <th>{{'IOTModuleBatteryVoltageLevel' | translate}}</th>
            <th>{{'testResult' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let defibrillatorTest of paginatedDefibrillatorTests">
            <td>{{ defibrillatorTest.testDay+'.'+defibrillatorTest.testMonth+ '.'+defibrillatorTest.testYear+','+defibrillatorTest.testHour+':'+defibrillatorTest.testMinute}}</td>
            <td>{{ defibrillatorTest.testType }}</td>
            <td [ngClass]= defibrillatorTest.colorIOTModuleBatteryVoltageLevel>{{ defibrillatorTest.IOTModuleBatteryVoltageLevel }} V</td>
          </tr>
          </tbody>
        </table>
      </nb-tab>
    </nb-tabset>
  </div>
  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info title">{{'coordinate' | translate}}: </label>
      <label class="column-info content">{{defibrillatorLocation}} </label>
    </div>
    <div *ngIf="showSignalQuality" dialog-card-footer class="footer-right">
      <label class="column-info content">  {{this.connectionTypeName}} &nbsp; </label>
      <label>
        <img src="{{this.signalQualityIcon}}">
      </label>
      <label style="color:{{this.colorSignalQuality}};" class="column-info content"> &nbsp;  {{this.signalQuality}}</label>
    </div>
  </div>
</closeable-dialog>


