import {MenuItem} from './menu/menu-item';
import {Page} from './page.model';

/**
 * Represents a group of pages organised by the user
 */
export class PageGroup {

  /**
   * Name of the group
   */
  name: string;

  /**
   * Ids of the pages represented by this group
   */
  pageIds: string[];

  constructor(data?) {
    if (!data) {
      return;
    }

    this.name = data.name;
    this.pageIds = data.pageIds;
  }

  /**
   * Creates a menu item from the given page group
   */
  createMenuItem(pageSummaries: Map<string, Page>): MenuItem {
    const children: MenuItem[] = this.pageIds.map(pageId => {
      return pageSummaries.get(pageId).createMenuItem();
    });

    return new MenuItem({
      title: this.name,
      children: children,
      dragDisabled: true // page groups are not draggable
    });
  }
}
