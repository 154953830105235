import {BaseMetaModel} from './base/base-meta-model.model';
import {GeoJsonFactory} from './factories/geo-json.factory';
/**
 * Indicates a geographic region
 */
export class Sensor extends BaseMetaModel {

  sensorId: string;

  sensorType: string;

  description: String;

  status: String;


  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.sensorId = data.sensorId;
    this.sensorType = data.sensorType;
    this.description = data.description;
    this.status = data.status;
  }
}
