/**
 * Metadata that is owned by every entity instance managed by the system.
 */
export class Meta {
  /**
   * Tags that show association of the entity with specific domains or realms like urn:uruk:domain:smart-transport, urn:uruk:realm:samsun, ...
   */
  tags?: string[] = [];

  /**
   * Last modification date of the corresponding entity
   */
  updatedAt: Date;

  /**
   * Creation date of the corresponding entity
   */
  createdAt: Date;

  /**
   * Identifier of the user created this entity
   */
  source: string;

  /**
   * Version of the entity
   */
  version: string;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.tags = data.tags;
    if (data.updatedAt) {
      this.updatedAt = new Date(data.updatedAt);
    }
    if (data.createdAt) {
      this.createdAt = new Date(data.createdAt);
    }
    this.source = data.source;
    this.version = data.version;
  }
}
