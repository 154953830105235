import {Layer} from '../../shared/models/layer.model';
import {Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {NGSIResultUtil} from '../../shared/utils/ngsi-result-util';
import {EntityRepresentation} from '../../shared/models/visualization/representation.model';
import {Notification} from '../../shared/models/notification.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {LayerController} from './layer.controller';
import {catchError, forkJoin, map, Observable, of, take} from 'rxjs';
import {NGSIQuery} from 'app/shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from 'app/shared/models/ngsi/ngsi-filter.model';
import {Marker} from '../../shared/models/map/marker.model';

export class TrainLayerController extends LayerController {

  displaysTooltip = true;

  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    query.filter.id = [result.getEntityResult().getSingleElementValue('trainRef')];
    const trainRefQuery = this.cbrokerService.getEntity(query, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const trainPositionQuery = this.getMarkerEntity(result.getEntityId(), false);
    const contextObservable: Observable<any> = forkJoin([trainRefQuery, trainPositionQuery]).pipe(
      take(1),
      map(response => {
        return {
          entity: response[0]?.getEntityResult(),
          positionEntity: response[1]?.getEntityResult()
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }


  protected handleCreateEvent(representation: EntityRepresentation, notification: Notification): NGSIResult {
    const newEntityTrainRef: string = NGSIResultUtil.extractEntityAttributeValue(notification.eventPayload.content, 'trainRef', new QueryOptions());
    const representationEntities = this.entities.get(representation);
    const entityIndex = representationEntities ? representationEntities.findIndex(e => {
      return this.getTrainRefFromNGSIResult(e) === newEntityTrainRef;
    }) : -1;
    if (entityIndex !== -1) {
      this.entities.get(representation).splice(entityIndex, 1);
      this.markers.get(representation).splice(entityIndex, 1);
    }
    return super.handleCreateEvent(representation, notification);
  }

  /**
   * Filters out the duplicate results that are belong to the same train
   * TODO non-duplicate results will be fetched from server one we have the "last" operator
   * @param results
   * @protected
   */
  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    const entitiesFound: Set<string> = new Set();
    const finalResults: NGSIResult[] = [];
    results.forEach(result => {
      const trainRef: string = this.getTrainRefFromNGSIResult(result);
      if (!entitiesFound.has(trainRef)) {
        finalResults.push(result);
        entitiesFound.add(trainRef);
      }
    });
    return finalResults;
  }

  private getTrainRefFromNGSIResult(ngsiResult: NGSIResult): string {
    return ngsiResult.getEntityResult().getSingleElementValue('trainRef');
  }

  createTooltip(marker: Marker): string {
    const hmiJourneyNo = marker.entity.entity.hmiJourneyNo.value ? marker.entity.entity.hmiJourneyNo.value : marker.entity.entity.hmiJourneyNo[0].value;
    const speed = marker.entity.entity.speed.value ? marker.entity.entity.speed.value : marker.entity.entity.speed[0].value;
    const route = marker.entity.entity.route.value ? marker.entity.entity.route.value : marker.entity.entity.route[0].value;
    const array = marker.entity.entity.trainRef.object ? marker.entity.entity.trainRef.object.split(':') : marker.entity.entity.trainRef[0].object.split(':');
    const trainID = array[3];
    const routeName = this.getRouteName(route, speed);
    const trainLabel = this.translateService.instant('train');
    const journeyNoLabel = this.translateService.instant('journeyNo');
    const speedLabel = this.translateService.instant('speed');
    const routeLabel = this.translateService.instant('route');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text"><span class="title">${trainLabel} : </span> ${trainID} </div>`;
    html += `<div class="text"><span class="title">${journeyNoLabel} : </span> ${hmiJourneyNo} </div>`;
    html += `<div class="text"><span class="title">${speedLabel} : </span> ${speed} </div>`;
    html += `<div class="text"><span class="title">${routeLabel} : </span> ${routeName} </div>`;
    html += '</div>';
    this.eventService.broadcastRouteVisibilityChangedEvent(route, true);
    return html;
  }

  toolTipClosed(marker: Marker) {
    const route = marker.entity.entity.route.value ? marker.entity.entity.route.value : marker.entity.entity.route[0].value;
    this.eventService.broadcastRouteVisibilityChangedEvent(route, false);
  }

  getRouteName(route, speed) {
    if (speed === 0) {
      return 'Servis Dışı';
    } else if (route === 1) {
      return 'Edirne-Eskişehir';
    } else if (route === 2) {
      return 'Zonguldak-Eskişehir';
    } else if (route === 3) {
      return 'Irmak-Karalar';
    } else if (route === 4) {
      return 'Uşak-İzmir';
    } else if (route === 5) {
      return 'Bandırma-İzmir';
    } else if (route === 6) {
      return 'Menemen-Aliağa';
    } else if (route === 7) {
      return 'Alsancak-Ödemiş';
    } else if (route === 8) {
      return 'Çatal-Tire';
    } else if (route === 9) {
      return 'Torbalı-Söke';
    } else if (route === 10) {
      return 'Ortaklar-Kaklık';
    } else if (route === 11) {
      return 'Goncalı-Denizli';
    } else if (route === 12) {
      return 'Samsun-Karanlık';
    } else if (route === 13) {
      return 'Sivas-Hudut';
    } else if (route === 14) {
      return 'Mezra-Aktaş';
    } else if (route === 15) {
      return 'Kayseri-Kalın';
    } else if (route === 16) {
      return 'Hanlı-Bostankaya';
    } else if (route === 17) {
      return 'Kayapınar-Yenikangal';
    } else if (route === 18) {
      return 'Bitlis-K.Maraş';
    } else if (route === 19) {
      return 'Elazığ-Batman';
    } else if (route === 20) {
      return 'Malatya-Çetinkaya';
    } else if (route === 21) {
      return 'Van-Hudut';
    } else if (route === 22) {
      return 'K.Maraş-Osmaniye';
    } else if (route === 23) {
      return 'Adana-Konya';
    } else if (route === 24) {
      return 'Adana-Osmangazi';
    } else if (route === 25) {
      return 'Köprüağzı-Başpınar';
    } else if (route === 26) {
      return 'Fevzipaşa-Tahtaköprü';
    } else if (route === 27) {
      return 'Toprakkale-İskenderun';
    } else if (route === 28) {
      return 'Yenice-Mersin';
    } else if (route === 29) {
      return 'Balıkesir-Konya';
    } else if (route === 30) {
      return 'Tavşanlı-Tunçbilek';
    } else if (route === 31) {
      return 'Alanyurt-Eskişehir';
    } else if (route === 32) {
      return 'Kütahya-Seyitören';
    } else if (route === 33) {
      return 'Afyon-Dumlupınar';
    } else if (route === 34) {
      return 'Afyon-Kaklık';
    } else if (route === 35) {
      return 'Karakuyu-Isparta';
    } else if (route === 36) {
      return 'Gümüşgün-Burdur';
    } else if (route === 37) {
      return 'Ankara-Sivas';
    } else if (route === 38) {
      return 'Ankara-Eskişehir';
    } else if (route === 39) {
      return 'Polatlı-Konya';
    } else if (route === 40) {
      return 'Gebze-Adapazarı';
    } else if (route === 41) {
      return 'Gebze-Halkalı';
    }
  }
}
