/**
 * Individual NGSI query term that can be used to construct more complex queries. A term is composed of an operator and one or more operands
 * based on the operator type.
 */
import {NGSIQueryExpression} from './ngsi-query-expression.model';
import {Range} from '../generic/range.model';
import {QueryOperatorExpr} from '../query/query-operator-expr.model';
import {QueryOperatorExpressionFactory} from '../factories/query-operator-expression.factory';
import {NGSIPath} from './ngsi-path.model';
import {QueryOperator} from '../query/query-operator.model';

export class NGSIQueryTerm extends NGSIQueryExpression {
  /**
   * Path to access a specific value in an entity like availableSpotNumber, address[city], ... It is used as the first operand for the operator
   */
  path: QueryOperatorExpr;

  /**
   * Query operator like ==, !=, <, ...
   */
  op: string;

  /**
   * Other operands for the operator than the one coming from the entity. It is conditional with the valueRange. Either value or valueRange can be provided as the second operand
   */
  value: any[];

  /**
   * Value range that can be used as the operand. It is conditional with the value. Either value or valueRange can be provided as the second operand
   */
  valueRange: Range;

  constructor(data?: any) {
    super(data);
    this.jsonClass = 'NGSIQueryTerm';

    if (!data) {
      return;
    }

    this.path = QueryOperatorExpressionFactory.create(data.path);
    this.op = data.op;
    this.value = data.value;

    if (data.valueRange) {
      this.valueRange = new Range(data.valueRange);
    }
  }

  createNGSIParameters() {
    let parameter: string = this.path.columnName + this.op;
    if (this.value) {
      parameter += this.isQueryTermNull() ? 'NULL' : this.value.map(val => typeof val === 'string' ? `'${val}'` : val).join(',');
    } else {
      parameter += this.valueRange.start + '..' + this.valueRange.end;
    }
    return parameter;
  }

  createPersistableObject(): any {
    const {parentAssociation, ...rest} = this;
    return rest;
  }

  /**
   * Checks whether the query term is 'null'
   * @returns true if the query term is 'null'.
   */
  isQueryTermNull(){
    return this.value.length === 1 && this.value[0] === null;
  }
}
