import {Injectable, Injector} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {EMPTY, Observable} from 'rxjs';
import {KPI} from '../../../shared/models/kpi.model';
import {catchError, map} from 'rxjs/operators';
import {RestUtil} from '../../../shared/utils/rest-util';
import {BaseHttpClientService} from '../base-http-client.service';
import {OperatorDefinitionService} from './operator-definition.service';
import {DataSeries} from '../../../shared/models/visualization/data-series.model';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {SearchQuery} from '../../../shared/models/query/search-query.model';

/**
 * A service to handle page KPIs
 */
@Injectable()
export class KPIService extends BaseHttpClientService {
  private readonly dimensionsPath = 'dimensions';
  private readonly seriesPath = 'series';

  private operatorDefinitionService: OperatorDefinitionService;

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'kpis');
    this.operatorDefinitionService = injector.get(OperatorDefinitionService);
  }

  /**
 * Retrieves all KPIs defined on the platform
 */
  getAllKPIs(): Observable<KPI[]> {
    return this.getKPIs(new SearchQuery());
  }

  /**
   * Retrieves KPIs satisfying the given query.
   * @param kpiQuery the query
   */
  getKPIs(kpiQuery?: SearchQuery): Observable<KPI[]> {
    let url: string = this.endpoint;

    if (kpiQuery) {
      url += '?' + RestUtil.createURLParameters(kpiQuery);
    }

    return this.httpClient.get<KPI[]>(url)
      .pipe(
        map(response => {
          return response.map(item => new KPI(item));
        })
      );
  }

  /**
   * Retrieves the details of a single KPI
   * @param kpiId Identifier of the KPI to be retrieved
   */
  getKPI(kpiId: string): Observable<KPI> {
    const url = this.endpoint + '/' + kpiId;
    return this.httpClient.get(url).pipe(
      map(response => {
        if (response) {
          return new KPI(response);
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Creates a new KPI on the platform
   * @param kpi the KPI to be created
   */
  createKPI(kpi: KPI): Observable<KPI> {
    return this.httpClient.post(this.endpoint, kpi.createPersistableObject()).pipe(map(response => new KPI(response)));
  }

  /**
   * Updates the given KPI
   * @param kpiId
   * @param patch Content to be patched to the existing KPI
   */
  patchKPI(kpiId: string, patch: any): Observable<any> {
    const url = this.endpoint + '/' + kpiId;
    return this.httpClient.patch(url, patch);
  }

  /**
   * Deletes the KPI from the platform
   * @param kpiId Id of the KPI to be deleted
   */
  deleteKPI(kpiId: String) {
    const url = this.endpoint + '/' + kpiId;
    return this.httpClient.delete(url);
  }

  /**
   * Sets the label of the specified dimension's key
   * @param kpiId
   * @param dimensionIndex
   * @param dimensionKey
   * @param dimensionLabel
   */
  setDimensionLabel(kpiId: string, dimensionIndex: number, dimensionKey: string, dimensionLabel: string): Observable<void> {
    const url = `${this.endpoint}/${kpiId}/dimensions/${dimensionIndex}/${dimensionKey}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    const body = new HttpParams()
      .set('label', dimensionLabel);
    return this.httpClient.post<void>(url, body, {headers: headers});
  }

  /**
   * Sets details of the series specified by the index
   * @param kpiId
   * @param seriesIndex
   * @param series
   */
  setSeries(kpiId: string, seriesIndex: number, series: DataSeries): Observable<void> {
    const url = `${this.endpoint}/${kpiId}/series/${seriesIndex}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    let body = new HttpParams()
      .set('label', series.label)
      .set('format', series.format);
    if(series.unit){
      body = body.set('unit', series.unit);
    }
    return this.httpClient.post<void>(url, body, {headers: headers});
  }

  /**
   * Validates and enriches the kpi with dimensions and series based on the filter definitions incldued
   * @param kpi
   * @param errorHandler
   */
  validateAndEnrichKPI(kpi: KPI, errorHandler = null): Observable<KPI> {
    const endpoint: string = `${environment.server.analyticsApi}/realms/${this.authService.getRealmId()}/kpi`;
    return this.httpClient.post(endpoint, kpi.createPersistableObject()).pipe(
      map(response => new KPI(response)),
      catchError(response => {
        if (errorHandler) {
          errorHandler(response);
        }
        return EMPTY;
      })
    );
  }
}
