import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';
import {Relay} from '../../../../../shared/models/relay.model';

@Component({
  selector: 'solarPole-dialog-template',
  templateUrl: './solarPole-dialog-template.component.html',
  styleUrls: ['./solarPole-dialog-template.component.scss']
})
export class SolarPoleDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  // Context variables
  entity: any;
  id: any;
  chargeStatus: any;
  panelPower: any;
  batteryType: any;
  batteryVoltage: any;
  batteryCurrent: any;
  panelVoltage: any;
  loadCurrent: any;
  loadStatus: any;
  loadPower: any;
  temperature: any;
  doorStatus: any;
  batteryPercentage: any;
  location: any;
  chargeStatusColor: String = '';
  panelPowerColor: String = '';
  batteryVoltageColor: String = '';
  batteryCurrentColor: String = '';
  panelVoltageColor: String = '';
  loadCurrentColor: String = '';
  loadStatusColor: String = '';
  loadPowerColor: String = '';
  temperatureColor: String = '';
  doorStatusColor: String = '';
  batteryPercentageColor: String = '';
  // keeps the active tab
  activeTab: string;
  relays: Relay[] = [];
  hasRelayTabAlarm: boolean = false;
  hasSolarPoleTabRedAlarm: boolean = false;
  hasSolarPoleTabYellowAlarm: boolean = false;
  passiveRelaysCount: number = 0;


  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  protected mapContextData(context: any): void {
    this.entity =  context.entity[1].result.entity;
    for (let i = 0; i < context.entity[0].results.length; i++) {
      this.relays.push(new Relay({
        relayId: context.entity[0].results[i].result.entity.relayId.value,
        relayStatus: context.entity[0].results[i].result.entity.relayStatus.value,
        description: context.entity[0].results[i].result.entity.description.value,
        id: context.entity[0].results[i].result.entity.id
      }));
    }
    this.chargeStatus = super.getValue(this.entity, 'chargeStatus');
    this.panelPower = super.getValue(this.entity, 'panelPower') / 100;
    this.batteryType = super.getValue(this.entity, 'batteryType');
    this.batteryVoltage = super.getValue(this.entity, 'batteryVoltage') / 100;
    this.batteryCurrent = super.getValue(this.entity, 'batteryCurrent') / 100;
    this.panelVoltage = super.getValue(this.entity, 'panelVoltage') / 100;
    this.loadCurrent = super.getValue(this.entity, 'loadCurrent') / 100;
    this.loadStatus = super.getValue(this.entity, 'loadStatus');
    this.loadPower = this.loadCurrent * this.batteryVoltage;
    this.temperature = super.getValue(this.entity, 'temperature') / 100;
    this.doorStatus = super.getValue(this.entity, 'doorStatus');
    this.batteryPercentage = super.getValue(this.entity, 'batteryPercentage');
    this.location = super.getValue(this.entity, 'location').coordinates.join(', ');

    this.checkChargeStatus();
    this.checkPanelPower();
    this.checkBatteryVoltage();
    this.checkBatteryCurrent();
    this.checkPanelVoltage();
    this.checkLoadCurrent();
    this.checkLoadStatus();
    this.checkLoadPower();
    this.checkTemperature();
    this.checkDoorStatus();
    this.checkBatteryPercentage();
    this.checkRelayTabAlarm();
    this.totalPassiveRelaysCount();
  }

  private checkChargeStatus() {
    if (this.chargeStatus === 0) {
      this.chargeStatusColor = '-red';
    }
  }

  private checkPanelPower() {
    if (!(this.panelPower > 0 && this.panelPower < 99.99)) {
      this.panelPowerColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    }
  }

  private checkBatteryVoltage() {
    if (!(this.batteryVoltage >= 12.5 && this.batteryVoltage <= 30)) {
      this.batteryVoltageColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    }
  }

  private checkBatteryCurrent() {
    if (!(this.batteryCurrent >= -45 && this.batteryCurrent <= 10)) {
      this.batteryCurrentColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    }
  }

  private checkPanelVoltage() {
    if (!(this.panelVoltage >= 0 && this.panelVoltage <= 45)) {
      this.panelVoltageColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    }
  }

  private checkLoadCurrent() {
    if (!(this.loadCurrent >= 0 && this.loadCurrent <= 10)) {
      this.loadCurrentColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    }
  }

  private checkLoadStatus() {
    if (this.loadStatus === 2) {
      this.loadStatusColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    } else if (this.loadStatus === 0) {
        this.loadStatusColor = '-yellow';
        this.hasSolarPoleTabYellowAlarm = true;
      }
  }

  private checkLoadPower(){
    if(this.loadPower >= 100){
      this.loadPowerColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    }
  }

  private checkTemperature() {
    if (this.temperature <= -20) {
      this.temperatureColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    } else if (this.temperature > 40) {
      this.temperatureColor = '-yellow';
      this.hasSolarPoleTabYellowAlarm = true;
    }
  }

  private checkDoorStatus() {
    if (this.doorStatus === 1) {
      this.doorStatusColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    }
  }

  private checkBatteryPercentage() {
    if (this.batteryPercentage < 10) {
      this.batteryPercentageColor = '-red';
      this.hasSolarPoleTabRedAlarm = true;
    } else if (this.batteryPercentage < 20) {
      this.batteryPercentageColor = '-yellow';
      this.hasSolarPoleTabYellowAlarm = true;
    }
  }

  private checkRelayTabAlarm() {
    this.hasRelayTabAlarm = false;
    for (let i = 0; i < this.relays.length; i++) {
      console.log(this.hasRelayTabAlarm);
      if (this.relays[i].relayStatus === 1) {
        this.hasRelayTabAlarm = true;
        return;
      }
    }
  }

  private totalPassiveRelaysCount() {
    this.passiveRelaysCount = 0;
    for (let j = 0; j < this.relays.length; j++) {
      if (this.relays[j].relayStatus === 1) {
        this.passiveRelaysCount++;
      }
    }
  }
}
