import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {RequestTrackerService} from '../../core/services/http/request-tracker.service';
import {KPIService} from '../../core/services/meta/kpi.service';
import {NavigatableComponent} from './navigatable.component';
import {AddPanelDialogComponent, AddPanelState} from '../../modules/dialog/add-panel-dialog/add-panel-dialog.component';
import {Page} from '../models/page.model';
import {Panel} from '../models/visualization/panel.model';
import {PageService} from '../../core/services/meta/page.service';
import {PanelContextData} from '../models/report/panel-context-data.model';
import {ReportService} from '../../core/services/report.service';
import {ReportContextData} from '../models/report/report-context-data.model';
import {EventService} from '../../core/services/event.service';
import {ChartType} from '../enums/chart-type.enum';
import {StorageUtil} from '../../shared/utils/storage-util';

/**
 * Base component for smart city pages to provide common properties
 */
@Component({
  template: '',
})
export abstract class BasePageComponent extends NavigatableComponent implements OnInit, OnDestroy {

  @Input() page: Page;

  // A panel map for locating panels in an easier way so that they can be bound to angular components
  panels = {};

  // Visibility of panels of a page
  panelsVisible = true;

  /*
   * Reporting-related variables
   */
  // keeps the number of panels that send data when whole page is reported
  private receivedPanelData: any[] = [];
  // flag indicating whether the reporting is enabled or not. Reporting becomes disabled until an existing reporting operation completes
  private reportingEnabled = true;

  // Services used in derived components
  protected pageService: PageService;
  protected kpiService: KPIService;
  protected reportService: ReportService;
  protected requestTrackerService: RequestTrackerService;

  PANEL_LOCATION_DELIMITER = '-'; // a panel location consists of "[PANEL_LOCATION_X]-[index]"
  public currentLang: string;

  constructor(injector: Injector) {
    super(injector);

    this.pageService = injector.get(PageService);
    this.kpiService = injector.get(KPIService);
    this.reportService = injector.get(ReportService);
    this.requestTrackerService = injector.get(RequestTrackerService);
  }

  ngOnInit() {
    super.ngOnInit();

    // process panels in the deriving controllers
    this.mapPanels();

    // subscribe to other relevant events
    this.handleSubscriptions();
    this.currentLang = this.translateService.defaultLang;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * Removes the given panel from this page
   * @param panel
   */
  protected deletePanel(panel: Panel): void {
    // find the index of panel
    const index = this.page.panels.findIndex(pagePanel => pagePanel.panelLocation === panel.panelLocation);
    // remove it from page
    this.page.panels.splice(index, 1);
    // remove it from panel map
    this.panels[panel.panelLocation] = null;
  }

  protected handleSubscriptions(): void {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.PANEL_REPORT_DATA_SENT:
            this.handlePanelReportClick(event.data.panelData, event.data.singlePanelReport);
            break;
        }
      });
  }

  /**
   * Opens a dialog {@link AddPanelDialogComponent} to update panel.
   * @param panel Panel to be updated
   * */
  updatePanel(panel: Panel) {
    const panelToEdit: Panel = new Panel({
      ...panel
    });
    // trick for having the same ngsi context that is used in the context service to access to the kpi execution object. Without this explicit assignment,
    // we would have an error in context service while accessing the execution context associated with this panel.
    panelToEdit.ngsiContext = panel.ngsiContext;
    // open the dialog
    const dialogRef = this.dialogService.open(AddPanelDialogComponent, {
      context: {
        // pass the copy of panel
        panelInEditMode: panelToEdit,
        step: panel.ngsiContext.mlModelConfig ? AddPanelState.ANALYTICS_SELECTION : AddPanelState.CHART_TYPE_SELECTION
      }
    });

    // retrieve the updated panel on close
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(updatedPanel => {
        if (updatedPanel) {
          // update the panel in page panel list
          const index = this.page.panels.findIndex(pagePanel => pagePanel.panelLocation === panel.panelLocation);
          this.page.panels[index] = updatedPanel;
          // set updated panel in panels map
          this.panels[panel.panelLocation] = updatedPanel;
          this.eventService.broadcastPanelUpdatedEvent(updatedPanel);
        }
      });
  }

  /**
   * Broadcasts the panel updated event for all panels in page
   * */
  protected updatePanelData() {
    this.page.panels.forEach(panel => this.eventService.broadcastPanelUpdatedEvent(panel));
  }


  /******* PAGE & PANEL OPERATIONS *******/

  /**
   * Processes the panel locations so that they appear in the correct and exact location on the videowall
   * @param panels
   */
  protected mapPanels() {
    // reset panels map
    this.panels = {};
    this.page?.panels.forEach(panel => {
      this.panels[panel.panelLocation] = panel;
    });
  }

  /**
   * Returns the panel at given location at given index
   * @param location location of the panel
   * @param index index of the panel at the given location
   */
  protected getPanelAtLocation(location: string, index: string) {
    return this.panels[location + this.PANEL_LOCATION_DELIMITER + index];
  }

  /**
   * Adds a new panel at given location at given index
   * @param location location of the panel
   * @param index index of the panel at the given location
   * @param defaultChartType the default chart type for the panel (if available)
   */
  protected addPanelToLocation(location: string, index: string, defaultChartType?: ChartType) {
    const panelLocation = location + this.PANEL_LOCATION_DELIMITER + index;

    // open a dialog and get a reference to it
    const dialogRef = this.dialogService.open(AddPanelDialogComponent, {
      context: {
        panelLocation: panelLocation,
        defaultChartType: defaultChartType
      }
    });

    // retrieve the created panel on close
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(panel => {

        // call service method to add the panel to the page
        this.pageService.addPanel(this.page.id, panelLocation, panel)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {

            // add panel to the page
            this.page.panels.push(panel);
            this.panels[panelLocation] = panel;
          });
      });

    return panelLocation;
  }

  /**
   * Handles panel visibility toggling
   */
  protected onPanelsVisibilityToggled(): void {
    this.panelsVisible = !this.panelsVisible;
  }

  protected languageChange(language): void {
    this.translateService.setDefaultLang(language);
    StorageUtil.setLanguage(language);

    this.translateService.use(language).subscribe(() => {
      console.log('Language changed to:', this.translateService.currentLang);
    });
    this.currentLang = language;
  }


  /**
   * Initiates a report generated event to trigger panels to send their panel information back to the page
   */
  handlePageReportClick(): void {
    // prevent reporting when there is an ongoing whole-page reporting.
    if (this.reportingEnabled) {
      this.receivedPanelData = [];
      this.eventService.broadcastReportGeneratedEvent();
    }
  }

  /**
   * Generates a report for the given panel information
   * @param panelData
   * @param singlePanelReport
   */
  handlePanelReportClick(panelData: PanelContextData, singlePanelReport: boolean): void {
    // if the report event is triggered via a click, report it immediately
    if (singlePanelReport) {
      // report the panel if and only if it belongs to this page
      if (panelData.pageId === this.page.id) {
        this.reportService.generateReport(this.generateReportData([panelData]))
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }

      // otherwise wait all panels to send their data and initiate reporting once all the data is available
    } else {
      this.reportingEnabled = false;
      this.receivedPanelData.push(panelData);

      if (this.receivedPanelData.length === this.page.panels.length) {
        const nonEmptyPanelData = this.receivedPanelData.filter(receivedData => receivedData.data.length);
        this.reportService.generateReport(this.generateReportData(nonEmptyPanelData))
          .pipe(takeUntil(this.destroy$))
          .subscribe(_ => {
            this.reportingEnabled = true;
          });
      }
    }
  }

  /**
   * Transforms received panel data into reportable format as {@link ReportContextData}.
   * @private
   */
  private generateReportData(panelData: PanelContextData[]): ReportContextData {
    const contextData: ReportContextData = new ReportContextData();
    contextData.pageId = this.page.id;
    contextData.pageName = this.page.title;
    contextData.panels = panelData;
    return contextData;
  }
}
