import {Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {BaseDialogTemplateComponent } from '../base-dialog-template.component';
import {PaginationCallbackFunction} from '../../../../../shared/components/list/list.component';
import {PAGINATION_DEFAULT_PAGE_SIZE} from '../../../../../shared/components/list/pagination.component';
import {ContextService} from '../../../../../core/services/http/context.service';
import {Subscription} from 'rxjs';
import {Atu} from '../../../../../shared/models/atu.model';
import {Geko} from '../../../../../shared/models/geko.model';
import {JkgysTakBitResultdialogComponent} from './bitResults/jkgysTak-bitResultdialog.component';

@Component({
  selector: 'jkgysTak-template',
  templateUrl: './jkgysTak-template.component.html',
  styleUrls: ['./jkgysTak-template.component.scss']
})
export class JkgysTakTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  entity: any;
  atuEntity: any;
  gekoEntity: any;
  id: any;
  jemusTakLocation: any;
  sensorType: any;
  description: any;
  direction: any;
  district: any;
  city: any;
  connectionStatus: any;
  colorTakConnectionStatus: any;
  cbrokerService: ContextService;
  atus: Atu[] = [];
  gekos: Geko[] = [];
  paginatedAtus: Atu[] = [];
  paginatedGekos: Geko[] = [];
  showFooter: boolean = true;

  activeTab: string;

  private pageSize = PAGINATION_DEFAULT_PAGE_SIZE;

  private mgubPage = 1;

  public paginationCallback: PaginationCallbackFunction;

  private refreshingIntervalId: Subscription;

  columnMetadata = [
    {
      label: 'city',
      field: 'city',
      columnWidth: 3
    },
    {
      label: 'direction',
      field: 'direction',
      columnWidth: 3
    },
    {
      label: 'district',
      field: 'district',
      columnWidth: 3
    },
    {
      label: 'description',
      field: 'description',
      columnWidth: 3
    },
    {
      label: 'sensorType',
      field: 'sensorType',
      columnWidth: 4
    },
    {
      label: 'connectionStatus',
      field: 'connectionStatus',
      columnWidth: 5
    },
  ];

  connectionStatusMap = new Map<number, string>([
    [0, 'Durum Bilgisi Yok'],
    [1, 'Bağlantı Yok'],
    [9, 'Bağlantı Var'],
  ]);

  constructor(injector: Injector) {
    super(injector);
    this.cbrokerService = injector.get(ContextService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.paginationCallback = this.setPaginatedJemusTaks.bind(this);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.refreshingIntervalId) {
      this.refreshingIntervalId.unsubscribe();
    }
  }

  protected mapContextData(context: any): void {
    this.entity = context.entity ? context.entity : null;
    context.gekoEntity.subscribe({
      next: gekoList => {
        for (let i = 0; i < gekoList.length; i++) {
          const value = gekoList[i];
          this.gekos.push(new Geko({
            id:                value.geko.result.entity.id ? value.geko.result.entity.id.split(':')[3]  : '-',
            bitResults:        value.bitResults && value.bitResults.results ? value.bitResults.results : [],
            direction:         value.geko.result.entity.direction ?  value.geko.result.entity.direction.value.split('|') : '-',
            sensorType:        value.geko.result.entity.sensorType ?  value.geko.result.entity.sensorType.value : '-',
            description:       value.geko.result.entity.description ?  value.geko.result.entity.description.value : '-',
            colorConnectionStatus: this.checkConnectionStatus(value.geko.result.entity.connectionStatus),
            connectionStatus:   value.geko.result.entity.connectionStatus === undefined ? 'Durum Bilgisi Yok' :
              (this.connectionStatusMap.has(  value.geko.result.entity.connectionStatus.value) ?
                this.connectionStatusMap.get(  value.geko.result.entity.connectionStatus.value) : 'Durum Bilgisi Yok'),
          }));
          this.paginatedGekos = this.gekos.slice(0, this.gekos.length);
        }
      }
    });
    context.atuEntity.subscribe({
      next: atuList => {
        for (let i = 0; i < atuList.length; i++) {
          const value = atuList[i];
          this.atus.push(new Atu({
            id:                value.atu.result.entity.id ? value.atu.result.entity.id.split(':')[3]  : '-',
            bitResults:        value.bitResults && value.bitResults.results ? value.bitResults.results : [],
            direction:         value.atu.result.entity.direction ?  value.atu.result.entity.direction.value.split('|') : '-',
            sensorType:        value.atu.result.entity.sensorType ?  value.atu.result.entity.sensorType.value : '-',
            description:       value.atu.result.entity.description ?  value.atu.result.entity.description.value : '-',
            colorConnectionStatus: this.checkConnectionStatus(value.atu.result.entity.connectionStatus),
            connectionStatus:   value.atu.result.entity.connectionStatus === undefined ? 'Durum Bilgisi Yok' :
              (this.connectionStatusMap.has(  value.atu.result.entity.connectionStatus.value) ?
                this.connectionStatusMap.get(  value.atu.result.entity.connectionStatus.value) : 'Durum Bilgisi Yok'),
          }));
          this.paginatedAtus = this.atus.slice(0, this.atus.length);
        }
      }
    });
    this.id = this.entity.results[0].result.entity.id ? this.entity.results[0].result.entity.id.split(':')[3] : '-';
    this.connectionStatus = this.connectionStatusMap.get(this.entity.results[0].result.entity.connectionStatus ? this.entity.results[0].result.entity.connectionStatus.value : 0);
    this.colorTakConnectionStatus = this.checkConnectionStatus(this.entity.results[0].result.entity.connectionStatus);
    this.sensorType = this.entity.results[0].result.entity.sensorType ? this.entity.results[0].result.entity.sensorType.value : '-';
    this.description = this.entity.results[0].result.entity.description ? this.entity.results[0].result.entity.description.value : '-';
    this.direction = this.entity.results[0].result.entity.direction ? this.entity.results[0].result.entity.direction.value.split('|') : '-';
    this.city = this.entity.results[0].result.entity.city ? this.entity.results[0].result.entity.city.value : '-';
    this.district = this.entity.results[0].result.entity.district ? this.entity.results[0].result.entity.district.value : '-';
    this.jemusTakLocation = this.entity.results[0].result.entity.location ?  this.entity.results[0].result.entity.location.value.coordinates.join(', ') : '-';
  }
  private setPaginatedJemusTaks(pageSize = PAGINATION_DEFAULT_PAGE_SIZE, page = 1) {
    this.pageSize = pageSize;
    this.mgubPage = page;
    this.paginatedAtus = this.atus.slice(this.pageSize * (this.mgubPage - 1), this.pageSize * this.mgubPage);
    this.paginatedGekos = this.gekos.slice(this.pageSize * (this.mgubPage - 1), this.pageSize * this.mgubPage);
  }

  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  checkConnectionStatus(connectionStatus) {
    if (connectionStatus === undefined || connectionStatus.value === null || connectionStatus.value !== 9) {
      return 'red';
    } else {
      return 'green';
    }
  }

  openBitResultsDialog(bitResults, atuOrGeko , atuGekoId, atuGekoDescription) {
    this.dialogService.open(JkgysTakBitResultdialogComponent, {
      context: {
        bitResults: bitResults,
        atuOrGeko: atuOrGeko,
        atuGekoId: atuGekoId,
        atuGekoDescription: atuGekoDescription
      }
    });
  }
}
