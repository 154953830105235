<closeable-dialog class="vehicle-dialog-container">
    <div *ngIf="isArnetaVehicle !== undefined" class="dialog-header title" dialog-card-header>
        {{title}}-{{vehicleTrimmed}}</div>

    <div dialog-card-body class="dialog-body full-height">
        <div>
            <div class="d-flex">
                <div class="title flex-40">
                    Genel Bilgiler:
                </div>
            </div>
            <div class="d-flex small-margin-top">
                <div class="flex-25">
                    <label>Plaka:</label> {{vehiclePlateNumber}}
                </div>
                <div class="flex-20">
                    <label>Tipi:</label> {{vehicleType}}
                </div>
                <div>
                    <label>Koordinat:</label> {{location}}
                </div>
            </div>
            <div class="d-flex">
                <div class="flex-25">
                    <ng-container *ngIf="isArnetaVehicle !== undefined" [ngSwitch]="isArnetaVehicle.toString()">
                        <label class="container-25" *ngSwitchCase="'true'">
                            <label>Kilometresi:</label> {{mileageFromOdometer}}
                        </label>
                        <label class="container-25" *ngSwitchDefault>
                            <label>Sınıfı:</label> {{vehicleCategory}}
                        </label>
                    </ng-container>
                </div>
              <div>
                <label>Sene:</label> {{vehicleModel}}
              </div>
            </div>
            <div class="d-flex">
                <div class="flex-25">
                    <ng-container *ngIf="isArnetaVehicle !== undefined" [ngSwitch]="isArnetaVehicle.toString()">
                        <label class="container-25" *ngSwitchCase="'true'">
                            <label>Günlük km:</label> {{dailyMileageFromOdometer}}
                        </label>
                        <label class="container-25" *ngSwitchDefault>
                            <label>Marka:</label> {{vehicleBrand}}
                        </label>
                    </ng-container>
                </div>
              <div class="flex-20">
                <label>Hız:</label> {{speed}} {{'unit.km/h' | translate}}
              </div>
            </div>

          <div class="d-flex small-margin-top" *ngIf="isAsisVehicle">
            <div class="flex-40">
              <label>Sürücü Adı:</label> {{drivername}}
            </div>
            <div class="flex-40">
              <label>Yön:</label> {{direction}}
            </div>
          </div>
          <div class="d-flex small-margin-top" *ngIf="isAsisVehicle">
            <div class="flex-40">
              <label>Toplam Yolcu Sayısı:</label> {{totalnumberofpassengers}}
            </div>
            <div class="flex-40">
              <label>Toplam Gelir:</label> {{totalpassengerincome}}
            </div>
          </div>
          <div class="d-flex small-margin-top" *ngIf="isAsisVehicle">
            <div class="flex-40">
              <label>Hat No:</label> {{linecode}}
            </div>
          </div>
        </div>
    </div>

    <div dialog-card-footer>
        <span>ŞEHİR</span>
        <span>{{address}}</span>
        <span>{{date}}</span>
    </div>

</closeable-dialog>
