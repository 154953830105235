import {NGSIFilter} from './ngsi-filter.model';
import {BaseQuery} from './base-query.model';
import {QueryOperator} from '../query/query-operator.model';
import {QueryOperatorParamFactory} from '../factories/query-operator-param.factory';
import {Persistable} from '../persistanble';

export class UrukAggregationQuery extends BaseQuery implements Persistable {
  /**
   * Main aggregation query for the aggregation query
   */
  filter: NGSIFilter;

  /**
   * Metric aggregations
   */
  metricAgg: QueryOperator[];

  /**
   * Bucket aggregations
   */
  bucketAgg?: QueryOperator[];

  /**
   * Pipeline aggregations
   */
  pipelineAgg?: QueryOperator[];

  /**
   * Parameter to filter attribute values which are observed in last N days
   * If it is "null", no time filtering for the attribute values
   */
  lastNDays?:number = 7;

  constructor(data?: any) {
    super(data);
    this.jsonClass = 'UrukAggregationQuery';

    if (!data) {
      return;
    }

    this.filter = new NGSIFilter(data.filter);
    if (data.metricAgg) {
      this.metricAgg = data.metricAgg.map(agg => QueryOperatorParamFactory.create(agg));
    }
    if (data.bucketAgg) {
      this.bucketAgg = data.bucketAgg.map(agg => QueryOperatorParamFactory.create(agg));
    }
    if (data.pipelineAgg) {
      this.pipelineAgg = data.pipelineAgg.map(agg => QueryOperatorParamFactory.create(agg));
    }
    if(data.lastNDays !== undefined){
      this.lastNDays = data.lastNDays;
    }
  }

  createNGSIParameters() {
    const parameters: any = {};
    Object.assign(parameters, this.filter.createNGSIParameters());
    if (this.bucketAgg?.length > 0) {
      Object.assign(parameters, {
        buckets: this.bucketAgg.map(agg => agg.createQuerySerialization()).join(',')
      });
    }
    if (this.metricAgg?.length > 0) {
      Object.assign(parameters, {
        attrs: this.metricAgg.map(agg => agg.createQuerySerialization()).join(',')
      });
    }
    if (this.pipelineAgg?.length > 0) {
      Object.assign(parameters, {
        pipeline: this.pipelineAgg.map(agg => agg.createQuerySerialization()).join(',')
      });
    }
    if(this.lastNDays){
      Object.assign(parameters, {
        lastNDays: this.lastNDays
      });
    }
    return parameters;
  }

  createPersistableObject(): any {
    const {filter, ...rest} = this;
    rest['filter'] = filter.createPersistableObject();
    return rest;
  }
}
