import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {NOTIFICATIONS} from '../notification/notifications-tooltip.component';

/**
 * Dialog component to display release notes of the application.
 * */
@Component({
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss']
})
export class ReleaseNotesDialogComponent implements OnInit {

  @Input() releaseNotes = null;

  constructor(protected ref: NbDialogRef<ReleaseNotesDialogComponent>) {
  }

  ngOnInit(): void {
    if (!this.releaseNotes) {
      this.releaseNotes = NOTIFICATIONS[0];
    }
  }

  /**
   * Closes the dialog.
   * */
  close() {
    this.ref.close(false);
  }

}
