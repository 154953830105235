import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../base.component';

/**
 * Provides a group of checkboxes for the selection of some options.
 * Each option is represented by a checkbox and it allows select/deselect all of them at once.
 * */
@Component({
  selector: 'checkbox-group-input',
  templateUrl: './checkbox-group-input.component.html',
  styleUrls: ['./checkbox-group-input.component.scss']
})
export class CheckboxGroupInputComponent extends BaseComponent implements OnInit {

  // previously selected values which are used to mark selected ones in the initialization of component
  @Input() values: any[];
  // available options for the selection
  @Input() options: any[];
  // name formatter to extract the display name for the options and values. By default, it just returns the given value
  @Input() nameFormatter: (value: any) => string = (x: any) => x;
  // function to map given value to the corresponding option among the options list
  @Input() valueToOptionMapper: (value: any, options: any[]) => any = (x: any, _: any[]) => x;
  // function to extract value from the given option
  @Input() optionValueExtractor: (option: any) => any = (option: any) => option;
  // event emitter for 2-way binding on the values
  @Output() valuesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  // selected options
  public selectedOptions: Set<any> = new Set<any>();
  // flag indicating that all options are selected
  public allOptionsSelected: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    // map the given values to the corresponding options
    if (this.values?.length) {
      this.values.map(value => this.valueToOptionMapper(value, this.options))
        .forEach(option => this.selectedOptions.add(option));;
      this.setAllOptionsSelectedFlag();
    }
  }

  /**
   * Toogles the selection of option.
   * @param option the option
   * @param selected whether the option is selected or deselected
   */
  public toggle(option: any, selected: boolean): any {
    if (selected) {
      this.selectedOptions.add(option)
    } else {
      this.selectedOptions.delete(option);
    }
    this.setAllOptionsSelectedFlag();
    this.valuesChange.emit(this.getValue())
  }

  /**
   * Selects/deselects all options.
   * @param selected whether the all options are selected or deselected
   */
  public selectAll(selected: boolean): void {
    if (selected) {
      this.options.forEach(option => this.selectedOptions.add(option));
    } else {
      this.selectedOptions.clear();
    }
    this.valuesChange.emit(this.getValue())
  }

  /**
   * Returns the values selected in this component
   * @return the selected values
   */
  public getValue(): any[] {
    return Array.from(this.selectedOptions.keys()).map(option => this.optionValueExtractor(option));
  }

  /**
   * Sets {@link allOptionsSelected} flag
   */
  private setAllOptionsSelectedFlag(): void {
    this.allOptionsSelected = this.selectedOptions.size === this.options.length;
  }
}
