import {NGSIQueryExpression} from '../ngsi/ngsi-query-expression.model';
import {NGSIQueryTerm} from '../ngsi/ngsi-query-term.model';
import {NGSIQueryTermAssoc} from '../ngsi/ngsi-query-term-assoc.model';

/**
 * Factory class to handle different types NGSI query expressions
 */
export class NGSIQueryExpressionFactory {

  /**
   * Factory method to create a specific NGSI query expression
   * @param data
   */
  static create(data: any): NGSIQueryExpression {
    if (data.jsonClass === 'NGSIQueryTerm') {
      return new NGSIQueryTerm(data);
    } else if (data.jsonClass === 'NGSIQueryTermAssoc') {
      return new NGSIQueryTermAssoc(data);
    }
  }
}
