/**
 * Uruk notification that would be received from the subscription service
 */
export class Notification {
  // identifier of the subscription from which this notification is retrieved
  subscriptionId: string;
  // identifier of the realm for which this notification is generated
  realmId: string;
  // identifier of the entity for which this notification is generated
  entityId: string;
  eventClass: string;
  // content of the notification it can either be a full entity for creation events or a partial entity for temporal update events
  eventPayload: any;
  // informative content that is not be related with an event
  infoPayload: string;
  // error content indicating an erroneous situation
  errorPayload: string;
}

export enum NGSIEventType {
  CREATE = 'C',
  UPDATE = 'U',
  DELETE = 'D',
  DELETE_ATTR = 'DA',
  UPDATE_TEMPORAL ='UT',
  DELETE_ATTR_INSTANCE_TEMPORAL = 'DAT',
  MODIFY_ATTR_INSTANCE_TEMPORAL = 'MAT'
}
