import {SystemLabel} from './system-label';
import {Color} from '../../enums/color';

export class TrainSystemLabels {
  public tractionSystem: SystemLabel[] = [
    {color: Color.Green, label: 'noError'},
    {color: Color.Red, label: 'error'}
  ];

  public brakeSystem: SystemLabel[] = [
    {color: Color.Gray, label: 'ePBrakeFree'},
    {color: Color.Green, label: 'ePBrakeEngaged'},
    {color: Color.Red, label: 'ePBrakeError'}
  ];

  public powerVoltageSystem: SystemLabel[] = [
    {color: Color.Gray, label: 'passive'},
    {color: Color.Green, label: 'active'},
    {color: Color.Orange, label: 'minorError'},
    {color: Color.Red, label: 'majorError'}
  ];

  public airConditionSystem: SystemLabel[] = [
    {color: Color.Gray, label: 'passive'},
    {color: Color.Green, label: 'active'},
    {color: Color.Orange, label: 'minorError'},
    {color: Color.Red, label: 'majorError'},
    {color: Color.Pink, label: 'communicationError'}
  ];

  public doorSystem: SystemLabel[] = [
    {color: Color.Gray, label: 'doorClosed'},
    {color: Color.Yellow, label: 'doorIsolated'},
    {color: Color.Green, label: 'doorOpen'},
    {color: Color.Blue, label: 'doorActive'},
    {color: Color.Orange, label: 'minorError'},
    {color: Color.Red, label: 'majorError'},
    {color: Color.Pink, label: 'communicationError'}
  ];

  public otherSystem: SystemLabel[] = [
    {color: Color.Green, label: 'noError'},
    {color: Color.Red, label: 'error'}
  ];

  public communicationSystem: SystemLabel[] = [
    {color: Color.Green, label: 'noError'},
    {color: Color.Red, label: 'majorError'}
  ];

  public predictiveMaintenance: SystemLabel[] = [
    {color: Color.Green, label: 'noError'},
    {color: Color.Red, label: 'error'}
  ];

  public signalizationSystem: SystemLabel[] = [
    {color: Color.Green, label: 'noError'},
    {color: Color.Red, label: 'error'}
  ];
}
