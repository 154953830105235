import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {KPIService} from './meta/kpi.service';
import {TimeUtil} from '../../shared/utils/time-util';
import {KPI} from '../../shared/models/kpi.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {NGSIContext} from '../../shared/models/generic/ngsi-context.model';
import {NGSIExecutionContext} from '../../shared/models/generic/ngsi-execution-context';
import {ContextService} from './http/context.service';

/**
 * Service that keeps track of observables for KPI execution
 */
@Injectable({
  providedIn: 'root'
})
export class KpiExecutionService {

  // Subjects and corresponding observables for kpis. They are used to manage information retrieval by considering debouncing and order of the queries.
  private kpiExec: Map<string, NGSIExecutionContext> = new Map<string, NGSIExecutionContext>();

  constructor(private kpiService: KPIService, private cbrokerService: ContextService) {
  }

  /**
   * Triggers the execution of the KPI of which id is given
   * @param ngsiContext
   * @private
   */
  triggerKpiExecution(ngsiContext: NGSIContext): void {
    const context: NGSIExecutionContext = this.kpiExec.get(this.serializeNgsiContext(ngsiContext));
    if (context) {
      context.subject.next(null);
    }
  }

  /**
   * Creates a subject and corresponding observable for the execution of the given NGSI context. Also, schedules periodic
   * execution for contexts configured poll settings.
   * @param ngsiContext
   * @param kpi
   */
  registerKpiExecution(ngsiContext: NGSIContext, kpi: KPI): Observable<NGSIResult[]> {
    let ngsiExecutionContext: NGSIExecutionContext = this.kpiExec.get(this.serializeNgsiContext(ngsiContext));
    let result: Observable<NGSIResult[]>;
    if (ngsiExecutionContext) {
      ngsiExecutionContext.count++;
      result = ngsiExecutionContext.observable;

    } else {
      const subject: Subject<NGSIResult[]> = new Subject<NGSIResult[]>();
      const observable: Observable<NGSIResult[]> = subject.asObservable().pipe(
        // switch map fetches the results of the latest execution
        switchMap(() => {
          // execute KPI with given NGSI context (if applicable)
          return this.cbrokerService.executeKPI(kpi, ngsiContext);
        })
      );

      ngsiExecutionContext = new NGSIExecutionContext(subject, observable);
      this.kpiExec.set(this.serializeNgsiContext(ngsiContext), ngsiExecutionContext);

      result = observable;
    }

    // handle periodic executions
    if (ngsiContext.pollingSettings) {
      this.addPeriodicExecutionForKpi(ngsiContext);
    }
    return result;
  }

  /**
   * Sets periodic execution for the given ngsi context
   * @param ngsiContext
   */
  addPeriodicExecutionForKpi(ngsiContext: NGSIContext): void {
    const interval: number = TimeUtil.convertDurationToMilliSecond(ngsiContext.pollingSettings.interval);
    this.kpiExec.get(this.serializeNgsiContext(ngsiContext)).interval = setInterval(() => {
      this.triggerKpiExecution(ngsiContext);
    }, interval);
  }

  /**
   * Completes subject and terminates the timeout for the given execution context and removes it from the execution context map.
   * @param ngsiContext
   */
  removeKpiExecution(ngsiContext: NGSIContext): void {
    // Get the KPI execution context of this panel.
    // It should be noted that panels can also execute predictions based on trained models;
    // Therefore, the executionContext is null in such cases
    const executionContext: NGSIExecutionContext = this.kpiExec.get(this.serializeNgsiContext(ngsiContext));
    if (executionContext) {
      if (executionContext.count === 1) {
        clearInterval(executionContext.interval);
        executionContext.subject.complete();
        this.kpiExec.delete(this.serializeNgsiContext(ngsiContext));
      } else {
        executionContext.count--;
      }
    }
  }

  private serializeNgsiContext(context: NGSIContext): string {
    return JSON.stringify(context);
  }
}
