<closeable-dialog class="add-layer-dialog" xmlns="http://www.w3.org/1999/html">
  <!-- Dialog header -->
  <div dialog-card-header>
    <label>
      {{ 'Filtre Ekle' | translate }}
    </label>
  </div>

  <!-- Dialog body displaying a list of layers for the selection-->
  <div class="dialog-width" dialog-card-body>
    <div *ngIf="selectedParameter">
      <!-- parameters -->
      <div>
        <nb-select [selected]="selectedParameter" (selectedChange)="onSelectedChange($event)" selectWidth [options]="elements" [formatter]="elementDefinitionFormatter">
          <nb-option *ngFor="let parameter of elements" [value]="parameter">{{parameter.path | translate}}</nb-option>
        </nb-select>
      </div>

      <!-- operator -->
      <div class="margin-top">
        <nb-select [selected]="availableOperators[0]" [(ngModel)]="selectedOperator" selectWidth [options]="availableOperators">
          <nb-option *ngFor="let operator of availableOperators" [value]="operator">{{operator}}</nb-option>
        </nb-select>
      </div>

      <!-- value -->
      <!-- boolean type-->
      <ng-container *ngIf="selectedParameter.type === BaseDataType.BOOLEAN">
        <!-- TODO -->
      </ng-container>

      <!-- geojson type -->
      <ng-container *ngIf="selectedParameter.type === BaseDataType.GEOJSON">
        <!-- TODO -->
      </ng-container>

      <!-- text or uri types and relationships -->
      <div class="margin-top"
        *ngIf="selectedParameter.type === BaseDataType.TEXT || selectedParameter.type === BaseDataType.URI || selectedParameter.class === 'Relationship'">
        <multi-value-input #valueInput
                           [addButtonLabel]="'Değer Ekle' | translate"
                           [dataType]="BaseDataType.TEXT"
                           [multiValue]="isMultiValueAvailable()"
                           [required]="false"
                           [displayMode]="'edit'"
                           [values]="values">
        </multi-value-input>
      </div>

      <!-- values that can have a range -->
      <div *ngIf="isRangeValueAvailable()" class="margin-top">
        <div class="d-flex">
          <nb-radio-group *ngIf="selectedOperator === NGSIOperator.EQUAL" [(ngModel)]="valueModality">
            <nb-radio [value]="'single'">{{ 'Değer' | translate }}</nb-radio>
          </nb-radio-group>
          <div>
            <ng-container [ngSwitch]="selectedParameter.type">
              <multi-value-input #valueInput
                                 [addButtonLabel]="'Değer Ekle' | translate"
                                 [dataType]="selectedParameter.type"
                                 [multiValue]="isMultiValueAvailable()"
                                 [required]="true"
                                 [displayMode]="valueModality !== 'single' ? 'view' : 'edit'"
                                 [values]="values">
              </multi-value-input>
              <!-- TODO *ngSwitchCase="dataTypes.DATE" type="number" nbInput -->
            </ng-container>
          </div>
        </div>
        <div class="d-flex margin-top">
          <nb-radio-group *ngIf="selectedOperator === NGSIOperator.EQUAL" [(ngModel)]="valueModality">
            <nb-radio [value]="'range'">{{ 'Değer Aralığı' | translate }}</nb-radio>
          </nb-radio-group>              
          <div>
            <div class="d-flex align-items-center" [ngSwitch]="selectedParameter.type">
              <ng-container *ngSwitchCase="BaseDataType.NUMBER">
                <input [(ngModel)]="startValue" type="number" nbInput [disabled]="valueModality !== 'range'">
                <span class="form-error" *ngIf="displayErrorForStartValue()">*</span>
                -
                <input [(ngModel)]="endValue" type="number" nbInput  [disabled]="valueModality !== 'range'">
                <span class="form-error" *ngIf="displayErrorForEndValue()">*</span>
              </ng-container>
              <ng-container *ngSwitchCase="BaseDataType.DATE">
                <uruk-date-selection [temporalQuery]="startValue"
                      [parentComponentId]="'query_term_editor_start'"
                      [onlyDayTab]="true"
                      (onTemporalQueryChanged)="startValue=$event">
                </uruk-date-selection>
                -
                <uruk-date-selection [temporalQuery]="endValue"
                      [parentComponentId]="'query_term_editor_end'"
                      [onlyDayTab]="true"
                      (onTemporalQueryChanged)="endValue=$event">
                </uruk-date-selection>
              </ng-container>
              <ng-container *ngSwitchCase="BaseDataType.DATETIME">
                <uruk-date-time-selection
                  [temporalQuery]="startValue"
                  [parentComponentId]="'query_term_editor_start'"
                  (onTemporalQueryChanged)="startValue=$event">
                </uruk-date-time-selection>
                -
                <uruk-date-time-selection
                  [temporalQuery]="endValue"
                  [parentComponentId]="'query_term_editor_end'"
                  (onTemporalQueryChanged)="endValue=$event">
                </uruk-date-time-selection>
              </ng-container>
              <ng-container *ngSwitchCase="BaseDataType.TIME">
                <uruk-date-time-selection
                  [temporalQuery]="startValue"
                  [onlyTimeSelection]="true"
                  [parentComponentId]="'query_term_editor_start'"
                  (onTemporalQueryChanged)="startValue=$event">
                </uruk-date-time-selection>
                -
                <uruk-date-time-selection
                  [temporalQuery]="endValue"
                  [onlyTimeSelection]="true"
                  [parentComponentId]="'query_term_editor_end'"
                  (onTemporalQueryChanged)="endValue=$event">
                </uruk-date-time-selection>
              </ng-container>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dialog footer to save selection-->
  <div dialog-card-footer>
    <div class="btn btn-primary float-right" nbButton status="primary" type="submit" (click)="onAddClicked()">
      <span *ngIf="displayMode === 'create'">{{ 'Ekle' | translate }}</span>
      <span *ngIf="displayMode === 'edit'">{{ 'Güncelle' | translate }}</span>
    </div>
  </div>
</closeable-dialog>
