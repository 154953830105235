import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';
import {PaginationCallbackFunction} from '../../../../../shared/components/list/list.component';
import {PAGINATION_DEFAULT_PAGE_SIZE} from '../../../../../shared/components/list/pagination.component';
import {Sensor} from '../../../../../shared/models/sensor.model';
import {ContextService} from "../../../../../core/services/http/context.service";
import {NGSIResult} from "../../../../../shared/models/ngsi/ngsi-result";
import {NGSIQuery} from "../../../../../shared/models/ngsi/ngsi-query.model";
import {NGSIFilter} from "../../../../../shared/models/ngsi/ngsi-filter.model";
import {catchError, forkJoin, interval, Observable, of, Subscription} from "rxjs";
import {NGSIEntity} from "../../../../../shared/utils/ngsi-result-util";
import {QueryOptions} from "../../../../../shared/models/ngsi/query-options";
import {map, take} from "rxjs/operators";

@Component({
  selector: 'mgub-castle-template',
  templateUrl: './mgub-castle-template.component.html',
  styleUrls: ['./mgub-castle-template.component.scss']
})
export class MgubCastleTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  cbrokerService: ContextService;
  // the list of sensors retrieved from server
  sensors: Sensor[] = [];
  // paginated sensors displayed in the list view
  paginatedSensors: Sensor[] = [];

  isRefreshing: boolean = false;

  mgubCastle: any;

  mgubCastleDescription: string;

  mgubCastleLocation: string;

  displayDeleteButton: boolean = false;

  showFooter: boolean = true;

  hasPassive: boolean = false;

  passiveSensorCount: number = 0;

  private pageSize = PAGINATION_DEFAULT_PAGE_SIZE;

  private mgubPage = 1;

  public paginationCallback: PaginationCallbackFunction;

  private refreshingIntervalId: Subscription;

  isCollapsedMap = new Map<string, boolean>([]);

  colorMap = new Map<string, boolean>([]);

  columnMetadata = [
    {
      label: 'sensorId',
      field: 'sensorId',
      columnWidth: 3
    },
    {
      label: 'description',
      field: 'description',
      columnWidth: 3
    },
    {
      label: 'sensorType',
      field: 'sensorType',
      columnWidth: 4
    },
    {
      label: 'status',
      field: 'status',
      columnWidth: 5
    },
  ];

  statusMap = new Map<number, string>([
    [0, 'Durum Bilgisi Yok'],
    [1, 'Pasif'],
    [9, 'Aktif'],
  ]);

  sensorMaps = new Map<string, Sensor[]>();

  constructor(injector: Injector) {
    super(injector);
    this.cbrokerService = injector.get(ContextService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.paginationCallback = this.setPaginatedSensors.bind(this);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.refreshingIntervalId) {
      this.refreshingIntervalId.unsubscribe();
    }
  }

  private clearLocalStorages() {
    this.sensors = [];
    this.paginatedSensors = [];
    this.sensorMaps = new Map<string, Sensor[]>();
    this.hasPassive = false;
  }

  protected mapContextData(context: any): void {
    this.clearLocalStorages();
    for (let j = 0; j < context.entity.length - 1; j++)
      for (let i = 0; i < context.entity[j].results.length; i++) {
        this.sensors.push(new Sensor({
          sensorId: context.entity[j].results[i].result.entity.sensorId.value,
          sensorType: sensorTypeMap.get(context.entity[j].results[i].result.entity.sensorType.value),
          description: context.entity[j].results[i].result.entity.description.value,
          status: context.entity[j].results[i].result.entity.status === undefined ? 'Durum Bilgisi Yok' :
            (this.statusMap.has(context.entity[j].results[i].result.entity.status.value) ?
            this.statusMap.get(context.entity[j].results[i].result.entity.status.value) : 'Durum Bilgisi Yok'),
        }));
      }
    this.mgubCastle = context.entity[context.entity.length - 1].result.entity;
    this.mgubCastleDescription = this.mgubCastle.description.value;
    this.mgubCastleLocation = this.mgubCastle.location.value.coordinates;
    this.paginatedSensors = this.sensors.slice(0, this.sensors.length);
    //this.isCollapsedMap.clear();
    this.colorMap.clear();
    for (let sensor of this.paginatedSensors){
      let partialSensor: Sensor[] = [];
      if (this.sensorMaps.has(sensor.sensorType)){
        partialSensor = this.sensorMaps.get(sensor.sensorType);
      } else {
        if(!this.isRefreshing){
          this.isCollapsedMap.set(sensor.sensorType, true);
        }
      }
      if (!this.colorMap.has(sensor.sensorType) && (sensor.status === 'Pasif' || sensor.status === 'Durum Bilgisi Yok')){
        this.colorMap.set(sensor.sensorType, true);
      }
      if (sensor.status === 'Pasif' || sensor.status === 'Durum Bilgisi Yok') {
        this.hasPassive = true;
      }
      partialSensor.push(sensor);
      this.sensorMaps.set(sensor.sensorType, partialSensor);
    }
    this.totalPassiveSensorCount();

    if(!this.isRefreshing){
      this.isRefreshing = true;
      this.refreshingIntervalId = interval(10000).subscribe(() => {
        this.refreshPopup();
      });
    }
  }

  private setPaginatedSensors(pageSize = PAGINATION_DEFAULT_PAGE_SIZE, page = 1) {
    this.pageSize = pageSize;
    this.mgubPage = page;
    // @ts-ignore
    this.paginatedSensors = this.sensors.slice(this.pageSize * (this.mgubPage - 1), this.pageSize * this.mgubPage);
  }

  public getDisplayDeleteButton() {
    return !this.displayDeleteButton;
  }

  setVisibility(key: string){
    this.isCollapsedMap.set(key, !this.isCollapsedMap.get(key));
  }

  hasPassiveSensor(sensorType: string) {
    let sensors: Sensor[] = this.sensorMaps.get(sensorType);

    for (let j = 0; j < sensors.length; j++) {
      if (sensors[j].status === 'Pasif' || sensors[j].status === 'Durum Bilgisi Yok') {
        return true;
      }
    }
    return false;
  }

  totalPassiveSensorCount() {
    this.passiveSensorCount = 0;
    for (let j = 0; j < this.sensors.length; j++) {
      if (this.sensors[j].status === 'Pasif' || this.sensors[j].status === 'Durum Bilgisi Yok') {
        this.passiveSensorCount++;
      }
    }
  }

  private refreshPopup(){
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    const mgubId = this.mgubCastle.id;
    let contextObservable: Observable<any> = null;

    const sensorQuery: NGSIQuery = new NGSIQuery({
        filter: {
            type: [NGSIEntity.SENSOR] ,
            q : {
                jsonClass: "NGSIQueryTerm",
                path: {
                    jsonClass: "NGSIPath",
                    propOrRelPath: [
                        "refId"
                    ],
                    isTemporal: false,
                    isPropOrRelArray: false
                },
                op: "==",
                value: [
                    mgubId
                ]
            }
        },
        limit: 1000
    });

    const mgubRef =  this.cbrokerService.getEntities(sensorQuery, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const mgubRefQuery = this.getMarkerEntity(this.mgubCastle.id, false);
    contextObservable = forkJoin([mgubRef, mgubRefQuery]).pipe(
        map(response => {
            return {
                entity: response,
            };
        })
    );

    contextObservable.pipe(
        take(1)
    ).subscribe(context => {
      this.mapContextData(context);
    });
  }
  protected getMarkerEntity(entityId: string, isTemporal: boolean = true): Observable<NGSIResult> {
      const query: NGSIQuery = new NGSIQuery();
      query.filter = new NGSIFilter();
      query.filter.id = [entityId];
      return this.cbrokerService.getEntity(query, new QueryOptions(isTemporal, false, true));
  }
}

const sensorTypeMap = new Map<number, string>([
  [1, 'LIGHT'],
  [2, 'FENCE'],
  [5, 'MAGNETIC'],
  [6, 'SEISMIC'],
  [7, 'RADAR_FUSION'],
  [8, 'RADAR'],
  [9, 'VOLUMETRIC'],
  [10, 'DATASINK'],
  [11, 'MINE_CLAYMORE'],
  [12, 'ACOUSTIC'],
  [13, 'JAMMER'],
  [14, 'ALARMDEVICE'],
  [15, 'FIBER'],
  [16, 'PIR'],
  [31, 'LRSCAMERA'],
  [32, 'SRSCAMERA'],
  [33, 'FRSCAMERA'],
  [34, 'ROTATOR'],
  [35, 'ALGORITHMBOX'],
  [41, 'INFRARED'],
  [42, 'INFRARED_FENCE'],
  [43, 'INFRARED_LIGHT'],
  [71, 'DOOR'],
  [72, 'CABIN'],
  [73, 'BARRIER'],
  [74, 'SLIDING_DOOR'],
  [90, 'UAV'],
  [100, 'VEHICLE'],
  [101, 'ANNOUNCE'],
  [103, 'LRF'],
  [105, 'METEOROLOGY'],
  [106, 'MORTAR'],
  [107, 'OPERATOR'],
  [108, 'PLATFORM'],
  [109, 'ANS'],
  [201, 'STATION'],
  [202, 'TOWER'],
  [203, 'DIRECTION'],
]);
