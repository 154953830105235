<closeable-dialog class="jkgysTak-bitResult-dialog-container">
  <div class="dialog-header title" dialog-card-header [ngStyle]="{'color':hasPassive ? '#FF1F00' : 'white'}">
    <label>{{headerDescription}} : {{headerId}} - {{description}} &nbsp;</label>
  </div>
  <div dialog-card-body class="dialog-body full-height">
    <div *ngFor="let map of bitResultsMap | keyvalue">
      <div class="dialog-item" >
        <div class="d-flex" (click)='setVisibility(map.key)' [ngStyle]="{'cursor': 'pointer', 'background': !isCollapsedMap.get(map.key) ? '#1E1E1E' : '#2A2A2A', 'border-radius':'5px'}">
          <div [ngClass]="'title'"  >
            {{'bit.jkgys.' + map.key | translate}}
          </div>
          <div [ngClass]="'title left-icon'"  *ngIf='hasError(map.key)'>
            <i class="fa fa-exclamation-triangle"></i>
          </div>
          <div *ngIf='isCollapsedMap.get(map.key)' [ngClass]="'title right-icon'">
            <i class="fa fa-2x fa-caret-right"></i>
          </div>
          <div *ngIf='!isCollapsedMap.get(map.key)' [ngClass]="'title right-icon'">
            <i class="fa fa-2x fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf='!isCollapsedMap.get(map.key)'>
          <table id="bitResult-table">
            <tr [ngClass]="'header'">
              <th>{{'description' | translate}}</th>
              <th>{{'status' | translate}}</th>
            </tr>
            <tr *ngFor="let bit of map.value">
              <td>
                <div [ngClass]= "{'redDiv':bit.result !== 1}">
                  {{bit.description | translate}}
                </div>
              </td>
              <td>
                <div [ngClass]= "{'redDiv':bit.result !== 1}">
                  {{statusMap[bit.result]}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</closeable-dialog>
