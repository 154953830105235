import {PasswordPolicyType} from '../../enums/password-policy-type.enum';

/**
 * Describes the password policy.
 */
export class PasswordPolicy {
  /**
   * Type of password policy
   */
  type: PasswordPolicyType;

  /**
   * Value of password policy.
   */
  value: number;

  constructor(data?: string) {
    // data has the following format: type(value). For example, length(4) or digits(1)
    if (!data) {
      return;
    }

    // extract the value between parentheses
    const i = data.indexOf('(');
    const j = data.indexOf(')');

    this.type = data.substring(0, i) as PasswordPolicyType;

    const value = data.substring(i + 1, j);
    this.value = 'undefined' === value ? null : Number(value);
  }
}
