import {BaseMetaModel} from './base/base-meta-model.model';
/**
 * Indicates a geographic region
 */
export class JemusTak extends BaseMetaModel {

  direction: string;

  sensorType: number;

  description: String;

  city: String;

  district: String;

  connectionStatus: number;
  colorConnectionStatus: string;


  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.direction = data.direction;
    this.sensorType = data.sensorType;
    this.description = data.description;
    this.connectionStatus = data.connectionStatus;
    this.district = data.district;
    this.city = data.city;
    this.colorConnectionStatus = data.colorConnectionStatus;
  }
}
