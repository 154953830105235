import {BaseQuery} from './base-query.model';
import {QueryOperator} from '../query/query-operator.model';
import {QueryOperatorParamFactory} from '../factories/query-operator-param.factory';
import {Persistable} from '../persistanble';
import {UrukAggregationQuery} from './uruk-aggregation-query.model';
import {NGSIFilter} from './ngsi-filter.model';

export class UrukCrossAggQuery extends BaseQuery implements Persistable {

  // TODO: Should not be here but put for increasing processing consistency and should be deleted in the future
  filter: NGSIFilter;

  /**
   * Join type e.g. INNER, LEFT OUTER JOIN, etc
   */
  joinType: string;

  /**
   * Queries where results will be joined
   */
  subQueries: UrukAggregationQuery[];

  /**
   * Further pipeline aggregations on joined results
   */
  pipelineAgg?: QueryOperator[];

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.jsonClass = 'UrukCrossAggQuery';
    this.joinType = data.joinType;

    if (data.filter) {
      this.filter = new NGSIFilter(data.filter);
    }
    if (data.subQueries) {
      this.subQueries = data.subQueries.map(query => new UrukAggregationQuery(query));
    }
    if (data.pipelineAgg) {
      this.pipelineAgg = data.pipelineAgg.map(agg => QueryOperatorParamFactory.create(agg));
    }
  }

  createNGSIParameters() {
    const parameters: any = {};

    return parameters;
  }

  createPersistableObject(): any {
    return {};
  }
}
