<!--<div id="map" style="height: 800px; width: 800px"></div>-->
<div id="map"></div>

<!--<script type="text/javascript">-->
<!--  var map = new maprays.Map('map', {-->
<!--    coordinateViewer: true,-->
<!--    navigation: true,-->
<!--    overviewMap: true,-->
<!--    toolbar: true,-->
<!--    toolbarButtons: ['scenePicker', 'zoomBox', 'azimuth', 'distance', 'area']-->
<!--  });-->
