import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Layer} from '../../../shared/models/layer.model';
import {environment} from '../../../../environments/environment';
import {RestUtil} from '../../../shared/utils/rest-util';
import {BaseHttpClientService} from '../base-http-client.service';
import {SearchQuery} from '../../../shared/models/query/search-query.model';

/**
 * A service to handle page layers
 */
@Injectable()
export class LayerService extends BaseHttpClientService {

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'layers');
  }

  /**
   * Retrieves all layers defined on the platform
   */
  getAllLayers(): Observable<Layer[]> {
    return this.getLayers(new SearchQuery());
  }

  /**
   *
   * @param layerId
   */
  getLayer(layerId: string): Observable<Layer> {
    const url = this.endpoint + '/' + layerId;
    return this.httpClient.get<Layer>(url).pipe(map(response => new Layer(response)));
  }

  /**
   * Get layers for the layer query
   * @param layerQuery
   */
  getLayers(layerQuery: SearchQuery): Observable<Layer[]> {
    let url: string = this.endpoint;
    if (layerQuery) {
      const parameters: string = RestUtil.createURLParameters(layerQuery);
      if (parameters) {
        url += '?' + parameters;
      }
    }

    return this.httpClient.get<Layer[]>(url)
      .pipe(
        map(response => {
          return response.map(item => new Layer(item));
        })
      );
  }

  /**
   * Creates a new layer on the platform
   * @param layer the layer to be created
   */
  createLayer(layer: Layer) {
    return this.httpClient.post(this.endpoint, layer.createPersistableObject()).pipe(map(response => new Layer(response)));
  }

  /**
   *
   * @param layer
   */
  updateLayer(layer: Layer) {
    const url = this.endpoint + '/' + layer.id;
    return this.httpClient.put(url, layer.createPersistableObject()).pipe(map(response => new Layer(response)));
  }

  /**
   *
   * @param layerId
   */
  deleteLayer(layerId: string) {
    const url = this.endpoint + '/' + layerId;
    return this.httpClient.delete(url);
  }

}
