import {LayerController} from './layer.controller';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIQueryTerm} from '../../shared/models/ngsi/ngsi-query-term.model';
import {NGSIPath} from '../../shared/models/ngsi/ngsi-path.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {NGSIEntity} from '../../shared/utils/ngsi-result-util';
import {NGSIOperator} from '../../shared/enums/ngsi-query.enum';
import { LayerType } from 'app/shared/enums/layer-type';
import { LogicalOperator } from 'app/shared/enums/logical-operator.enum';
import { NGSIQueryTermAssoc } from 'app/shared/models/ngsi/ngsi-query-term-assoc.model';
import {Marker} from '../../shared/models/map/marker.model';
import {WeatherPollutionUtil} from '../../shared/utils/weather-pollution-util';

export class WeatherPollutionLayerController extends LayerController {

  deeperLevel: boolean;
  deeperLevelZoomTreshold = 8.5;
  displaysTooltip = true;

  constructor(layer: Layer, page: Page, injector: Injector) {
    super(layer, page, injector);

    this.layoutService.onMapZoomChanged().subscribe(zoomLevel => {

      if (this.deeperLevel !== undefined && this.deeperLevel !== null) {
        // detect changes
        if (this.deeperLevel === true && zoomLevel <= this.deeperLevelZoomTreshold) {
          // get weather pollution observations with rank 1
          this.deeperLevel = false;
          this.getWeatherPollutionObservations(true);
        } else if (this.deeperLevel === false && zoomLevel > this.deeperLevelZoomTreshold) {
          // otherwise, get all weather pollution observations
          this.deeperLevel = true;
          this.getWeatherPollutionObservations();
        }
      } else {
        this.deeperLevel = zoomLevel > this.deeperLevelZoomTreshold ? true : false;
      }
    });
  }

  /**
   * Collects the values to be provided to the dialog to be opened on entity click
   * @param result
   * @private
   */
  getDialogContext(result: NGSIResult): any {
    const contextObservable: Observable<any> = this.getMarkerEntity(result.getEntityId(), false).pipe(
      take(1),
      map(entity => {
        return {
          title: this.getPropertyValue(entity, 'name', false),
          entity: entity.result,
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }

  getWeatherPollutionObservations(rankQuery: boolean = false) {
    // create an NGSI query for weather pollution observations
    // NGSI query to filter weather pollution observations by their sources
    const sourceQueryTerm: NGSIQueryTerm = new NGSIQueryTerm({
      path: new NGSIPath({
        propOrRelPath: ["source"]
      }),
      op: NGSIOperator.EQUAL,
      value: this.layer.name === LayerType.WeatherPollutionCSB ? ['http://sim.csb.gov.tr'] : ['https://www.copernicus.eu']
    });
    const query: NGSIQuery = new NGSIQuery({
      filter: {
        type: [NGSIEntity.WEATHER_POLLUTION_OBSERVATION],
        q: sourceQueryTerm
      },
      limit: 1000
    });

    // create a filter based on the rank value
    if (rankQuery) {
      const condition: NGSIQueryTerm = new NGSIQueryTerm();
      condition.value = [1];
      condition.op = NGSIOperator.EQUAL;
      condition.path = new NGSIPath({
        propOrRelPath: ['rank']
      });

      const filterQuery = new NGSIQueryTermAssoc({
        op: LogicalOperator.AND,
        queries: [sourceQueryTerm, condition]
      })
      query.filter.q = filterQuery;
    }

    // retrieve corresponding entities & process
    this.cbrokerService.getEntities(query, new QueryOptions(false, false, true)).subscribe(entityResponse => {
      if (this.layer.representations && this.layer.representations.length > 0) {
        this.processData(entityResponse.results, this.layer.representations[0]);
      }
    });
  }

  createTooltip(marker: Marker) {
    const name = marker.entity.getSingleElementValue('name');
    const address = marker.entity.getSingleElementValue('address');
    const aqi = marker.entity.getSingleElementValue('aqi');

    let html = '<div class="entity-tooltip">';

    if (name) {
      html = `<span>${name}</span>`;
    }

    if (address) {
      html += '<span>';

      if (address.addressLocality) {
        html += address.addressLocality;
      }

      if (address.addressRegion) {
        html += '-' + address.addressRegion;
      }

      html += '</span>';
    }

    if (aqi) {
      html += `<span class="badge weather-pollution-badge weather-pollution-badge-${WeatherPollutionUtil.assessWeatherPollutionAQI(aqi)}">`;
      const aqiLabel = WeatherPollutionUtil.getWeatherPollutionLabel(WeatherPollutionUtil.assessWeatherPollutionAQI(Math.round(aqi)));
      if (aqiLabel) {
        const label = this.translateService.instant(aqiLabel);
        html += label;
      }
      html += '</span>';
    }

    html += '</div>';

    return html;
  }
}
