export class PowerVoltageModel {
  majorUnderVoltageError: any;
  catenaryVoltage: any;
  yuksekAkimKaynakliVakumHatasi: any;
  hvConfigError: any;
  pantoABar: any;
  tumPanolarIzole: any;
  pantografDustu: any;
  diagPantoComp: any;
  pTrain: any;
  majorMvError: any;
  vcbCloseSka1: any;
  vcbCloseSka2: any;
  pressurePs1SafeguardAddSka1: any;
  pressurePs1SafeguardAddSka2: any;
  hvDisconnectorCloseSKA1: any;
  hvDisconnectorCloseSKA2: any;
  hvDisconnectorCloseOA2: any;
  tractionTransformerOilFlow: any[];
  tractionTransformerOilLevel: any[];
  tractionTransformerOverPressure: any[];
  measuringBatteryVoltage: any[];
  tempBattery: any[];
  aBatteryCharger: any[];
  tempApsRec: any[];
  tempApsInv: any[];
  vApsDclink: any[];
  tractionTransformerTemp: any[];
  voltPantoSka1: any;
  mediumVoltageBackupEnabled2Vehicles: any;

  constructor(data: any) {
    this.majorUnderVoltageError = data.majorUnderVoltageError;
    this.catenaryVoltage = data.catenaryVoltage;
    this.yuksekAkimKaynakliVakumHatasi = data.yuksekAkimKaynakliVakumHatasi;
    this.hvConfigError = data.hvConfigError;
    this.pantoABar = data.pantoABar;
    this.tumPanolarIzole = data.tumPanolarIzole;
    this.pantografDustu = data.pantografDustu;
    this.diagPantoComp = data.diagPantoComp;
    this.pTrain = data.pTrain;
    this.majorMvError = data.majorMvError;
    this.vcbCloseSka1 = data.vcbCloseSka1;
    this.vcbCloseSka2 = data.vcbCloseSka2;
    this.pressurePs1SafeguardAddSka1 = data.pressurePs1SafeguardAddSka1;
    this.pressurePs1SafeguardAddSka2 = data.pressurePs1SafeguardAddSka2;
    this.hvDisconnectorCloseSKA1 = data.hvDisconnectorCloseSKA1;
    this.hvDisconnectorCloseSKA2 = data.hvDisconnectorCloseSKA2;
    this.hvDisconnectorCloseOA2 = data.hvDisconnectorCloseOA2;
    this.tractionTransformerOilFlow = data.tractionTransformerOilFlow;
    this.tractionTransformerOilLevel = data.tractionTransformerOilLevel;
    this.tractionTransformerOverPressure = data.tractionTransformerOverPressure;
    this.measuringBatteryVoltage = data.measuringBatteryVoltage;
    this.tempBattery = data.tempBattery;
    this.aBatteryCharger = data.aBatteryCharger;
    this.tempApsRec = data.tempApsRec;
    this.tempApsInv = data.tempApsInv;
    this.vApsDclink = data.vApsDclink;
    this.tractionTransformerTemp = data.tractionTransformerTemp;
    this.voltPantoSka1 = data.voltPantoSka1;
    this.mediumVoltageBackupEnabled2Vehicles = data.mediumVoltageBackupEnabled2Vehicles;
  }
}
