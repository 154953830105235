import {ElementDefinition} from './element-definition.model';
import {BaseMetaModel} from '../base/base-meta-model.model';

/**
 * Schema concept to be used to define domain-specific entities (i.e. NGSI-LD entities such as OffStreetParking) and complex data types (e.g. Address).
 */
export class StructureDefinition extends BaseMetaModel {
  /**
   * Business version of the definition
   */
  version: string;

  /**
   * Human friendly title for the item
   */
  title?: string;

  /**
   * Human friendly description for the item
   */
  description?: string;

  /**
   * Category of Resource kind of structure definitions
   * "temporal" means entity itself is created for each time point (even its location changes) e.g. AirQualityObserved.
   * "mixed" means entity is created once (installed on a location) but some of its properties are temporal and they can independently observed in each time point"
   */
  category?: String;

  /**
   * Kind of the the concept defined with this schema;
   * "Resource" means this is an entity definition
   * "Value" means this is a data type definition"
   */
  kind: String;

  /**
   * URL of the context that this NGSI-LD item is defined e.g. https://uruk.com/ns/data-models, https://uri.fiware.org/ns/data-models
   */
  context: URL;

  /**
   * Whether this is an abstract definition
   */
  isAbstract = false;

  /**
   * URL of the base definition (<context-url>/<name>) of this definition e.g. http://aselsan.com.tr/smart-city/BaseEntity
   */
  baseDefinition: URL;

  /**
   * Pattern for values of entities with simple string type
   */
  pattern: RegExp;

  /**
   * Published state of the definition. Draft status indicates that data associated with this entity can be deleted.
   * */
  state: string;

  /**
   * Definitions of elements that are defined in addition to the base definition or further constraints on existing elements
   */
  elements: ElementDefinition[];

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.version = data.version;
    this.title = data.title;
    this.description = data.description;
    this.category = data.category;
    this.kind = data.kind;
    this.state = data.state;
    this.context = new URL(data.context);
    this.isAbstract = data.isAbstract;
    if (data.baseDefinition) {
      this.baseDefinition = new URL(data.baseDefinition);
    }
    if (data.pattern) {
      this.pattern = new RegExp(data.pattern);
    }
    if (data.elements) {
      this.elements = data.elements.map(element => new ElementDefinition(element));
    }
  }
}
