import {AuthScope} from '../models/auth/auth-scope.model';

export enum AuthorizationScope {
  DOMAIN_READ = 'domain.read',
  DOMAIN_MANAGE = 'domain.manage',
  ENTITY_READ = 'entity.read',
  ENTITY_WRITE = 'entity.write',
  ENTITY_TU = 'entity.tu',
  REALM_MANAGE = 'realm.manage',
  PLATFORM_MANAGE = 'platform.manage'
}

export class AuthorizationScopeUtil {

  /**
   * Extracts the domains out of given token.
   * @param jwt JWT token
   * @return the list of domains which are available for the given token
   */
  public static extractDomains(jwt): string[] {
    if (jwt.authorization?.permissions) {
      return jwt.authorization.permissions
        .filter(permission => permission.rsname !== '*' && (permission.scopes.includes(AuthorizationScope.DOMAIN_READ) || permission.scopes.includes(AuthorizationScope.DOMAIN_MANAGE)))
        .map(permission => permission.rsname);
    }
    return [];
  }

  /**
   * Extracts the authorization scopes out of given token.
   * @param jwt JWT token
   * @return the list of authorization scopes.
   */
  public static extractAuthScopes(jwt): AuthScope[] {
    if (jwt.authorization?.permissions) {
      return jwt.authorization.permissions
        .map(permission => {
          return new AuthScope({permission: permission.scopes, resourceId: permission.rsname});
        });
    }
    return [];
  }
}
