import {NGSIQueryExpression} from './ngsi-query-expression.model';
import {NGSIQueryExpressionFactory} from '../factories/ngsi-query-expression.factory';

/**
 * Logical connection of NGSI query terms
 */
export class NGSIQueryTermAssoc extends NGSIQueryExpression {
  /**
   * Logical operator (and, or)
   */
  op: string;

  /**
   * Query terms as operands of the operator
   */
  queries: NGSIQueryExpression[];

  constructor(data?: any) {
    super(data);
    this.jsonClass = 'NGSIQueryTermAssoc';

    if (!data) {
      return;
    }

    this.op = data.op;
    this.queries = data.queries.map(term => {
      const expression: NGSIQueryExpression = NGSIQueryExpressionFactory.create(term);
      expression.parentAssociation = this;
      return expression;
    });
  }

  createNGSIParameters(): string {
    const querySerializations: string[] = this.queries.map(query => query.createNGSIParameters());
    let finalSerialization: string = querySerializations.join(this.getOperatorRepresentation(this.op));
    if (this.allLeaf()) {
      finalSerialization = '(' + finalSerialization + ')';
    }
    return finalSerialization;
  }

  allLeaf(): boolean {
    return this.queries.filter(q => q instanceof NGSIQueryTermAssoc).length === 0;
  }

  private getOperatorRepresentation(operator: string): string {
    if (operator === 'and') {
      return ';';
    } else {
      return '|';
    }
  }

  createPersistableObject(): any {
    const {parentAssociation, queries, ...rest} = this;
    rest['queries'] = queries.map(query => query.createPersistableObject());
    return rest;
  }
}
