export enum ChartDataFormat {
  INTEGER = 'Integer',
  DECIMAL = 'Decimal',
  PERCENTAGE = 'Percentage',
  DATETIME = 'DateTime',
  DATE = 'Date',
  YEAR = 'Year',
  MONTH = 'Month',
  HOUR = 'Hour',
  TEXT = 'Text',
  BOOLEAN = 'Boolean',
  TEXT_ARRAY = 'Text[]'
}
