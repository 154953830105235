import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {Subject} from 'rxjs';

/**
 * Service that manages all observable subscriptions to avoid leaks.
 */
@Injectable()
export class RequestTrackerService {

  /**
   * A special custom HTTP header indicating whether current request should be tracked or not even if its endpoint is
   * in the list of trackedEndpoints. Requests having this header will not be tracked; therefore, will not be cancelled,
   * either.
   */
  public static DISABLE_HEADER = 'disable-tracking';

  /**
   * Subscription object for unsubscribing when destroying the component to avoid leaks
   */
  public destroy$ = new Subject<void>();

  /**
   * Current list of all pending requests
   */
  public requests: HttpRequest<any>[] = [];

  /**
   * A list of tracked endpoints that we want to have control over.
   */
  public trackedEndpoints: string[] = ['/cbroker/entities', 'cbroker/aggregation'];

  constructor() {
  }

  /**
   * Adds given request to 'requests' lists
   */
  addRequest(request: HttpRequest<any>) {
    this.requests.push(request);
  }

  /**
   * Removes given request from 'requests' lists
   */
  removeRequest(request: HttpRequest<any>) {
    const index = this.requests.indexOf(request);
    if (index >= 0) {
      this.requests.splice(index, 1);
    }
  }

  /**
   * Returns if there is any pending requests
   */
  hasPendingRequests(): boolean {
    return this.requests.length > 0;
  }

  /**
   * Checks whether given request should be tracked by this service or not
   */
  shouldTrackRequest(request: HttpRequest<any>): boolean {
    for (let i = 0; i < this.trackedEndpoints.length; i++) {
      if (request.url.includes(this.trackedEndpoints[i]) && !request.headers.get(RequestTrackerService.DISABLE_HEADER)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Clears the list of requests
   */
  clear() {
    this.requests = [];
  }

  /**
   * Destroys all pending requests when called
   */
  destroyRequests() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject();
    this.clear();
  }

}
