/**
 * Represents the icon used in a menu item
 * */
export class MenuIcon {

  /**
   * Icon class name
   * */
  public icon: string;
  /**
   * The pack to which icon belongs
   * */
  public pack: string;

  constructor(data?: any) {
    if (!data) {
      return;
    }
    this.icon = data.icon;
    this.pack = data.pack;
  }
}
