import {BaseMetaModel} from './base/base-meta-model.model';
import {GeoJsonFactory} from './factories/geo-json.factory';
/**
 * Indicates a geographic region
 */
export class Relay extends BaseMetaModel {

  relayId: number;

  relayStatus: number;

  description: String;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.relayId = data.relayId;
    this.relayStatus = data.relayStatus;
    this.description = data.description;
  }
}
