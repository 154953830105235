import {BaseMetaModel} from '../base/base-meta-model.model';
import {TractionModel} from './traction.model';
import {BrakeModel} from './brake.model';
import {PowerVoltageModel} from './power-voltage.model';
import {AirConditionModel} from './air-condition.model';
import {DoorModel} from './door.model';
import {OtherModel} from './other.model';
import {CommunicationModel} from './communication.model';
import {SignalizationModel} from './signalization.model';

export class TrainDefinitionModel extends BaseMetaModel {

  tractionModel: TractionModel;
  brakeModel: BrakeModel;
  powerVoltageModel: PowerVoltageModel;
  airConditionModel: AirConditionModel;
  doorModel: DoorModel;
  otherModel: OtherModel;
  communicationModel: CommunicationModel;
  signalizationModel: SignalizationModel;
  fDriveApplied: any;
  hmiJourneyNo: any;
  location: any;
  speed: any;
  distanceTraveled: any;
  ascLimit: any;
  externalTemperature: any;
  predictiveMaintenanceCode: any;
  ApsDcLinkOk: any[];
  lcuMaintenance1: any[];

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.tractionModel = new TractionModel(data.traction);
    this.brakeModel = new BrakeModel(data.brake);
    this.powerVoltageModel = new PowerVoltageModel(data.powerVoltage);
    this.airConditionModel = new AirConditionModel(data.airCondition);
    this.doorModel = new DoorModel(data.door);
    this.otherModel = new OtherModel(data.other);
    this.communicationModel = new CommunicationModel(data.communication);
    this.signalizationModel = new SignalizationModel(data.signalization);
    this.fDriveApplied = data.fDriveApplied;
    this.hmiJourneyNo = data.hmiJourneyNo;
    this.location = data.location;
    this.speed = data.speed;
    this.distanceTraveled = data.distanceTraveled;
    this.ascLimit = data.ascLimit;
    this.externalTemperature = data.externalTemperature;
    this.predictiveMaintenanceCode = data.predictiveMaintenanceCode;
    this.ApsDcLinkOk = data.ApsDcLinkOk;
    this.lcuMaintenance1 = data.lcuMaintenance1;
  }
}
