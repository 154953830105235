<div *ngIf="searchMetadata" #searchInputContainer class="search-input-container">
  <ng-container [ngSwitch]="displaySearchInput.toString()">
    <div *ngSwitchCase="'false'" style="background-color: rgba(35, 35, 35, 0.7);padding: 1vmin;">
      <uruk-svg [width]="'100%'" [height]="'100%'" [viewbox]="'0 0 26 26'"
        [paths]="['M9.25 0.5C4.43231 0.5 0.5 4.43231 0.5 9.25C0.5 14.0677 4.43231 18 9.25 18C11.435 18 13.4313 17.185 14.9678 15.8516L15.5 16.3838V18L22.4824 24.9824C23.1724 25.6724 24.2924 25.6724 24.9824 24.9824C25.6724 24.2924 25.6724 23.1724 24.9824 22.4824L18 15.5H16.3838L15.8516 14.9678C17.185 13.4313 18 11.435 18 9.25C18 4.43231 14.0677 0.5 9.25 0.5ZM9.25 3C12.7166 3 15.5 5.78341 15.5 9.25C15.5 12.7166 12.7166 15.5 9.25 15.5C5.78341 15.5 3 12.7166 3 9.25C3 5.78341 5.78341 3 9.25 3Z']"
        [svgContainerClass]="'uruk-icon'" (click)="$event.stopPropagation();openSearchInput()">
      </uruk-svg>
    </div>
    <div *ngSwitchDefault class="entity-search">
      <div class="d-flex" style="align-items: center;">
        <uruk-svg style="padding-left:1vmin" [width]="'100%'" [height]="'100%'" [viewbox]="'0 0 26 26'"
          [paths]="['M9.25 0.5C4.43231 0.5 0.5 4.43231 0.5 9.25C0.5 14.0677 4.43231 18 9.25 18C11.435 18 13.4313 17.185 14.9678 15.8516L15.5 16.3838V18L22.4824 24.9824C23.1724 25.6724 24.2924 25.6724 24.9824 24.9824C25.6724 24.2924 25.6724 23.1724 24.9824 22.4824L18 15.5H16.3838L15.8516 14.9678C17.185 13.4313 18 11.435 18 9.25C18 4.43231 14.0677 0.5 9.25 0.5ZM9.25 3C12.7166 3 15.5 5.78341 15.5 9.25C15.5 12.7166 12.7166 15.5 9.25 15.5C5.78341 15.5 3 12.7166 3 9.25C3 5.78341 5.78341 3 9.25 3Z']"
          [svgContainerClass]="'uruk-icon'">
        </uruk-svg>
        <!-- Search input -->
        <input #searchInput type="text" nbInput style="background-color:#232323 !important" [(ngModel)]="searchText">
      </div>
      <!-- Search results -->
      <ng-container *ngIf="searchText && (searchMetadata | entitySearch:searchText) as results">
        <div *ngIf="results.length" class="results">
          <div *ngFor="let entity of results" (click)="selectEntity(entity.id)">
            <span>{{entity.id}}</span>
            <span>{{entity.match}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>