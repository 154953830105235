import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {LayerFactoryService} from '../../../core/services/layer-factory.service';
import {EventService} from '../../../core/services/event.service';
import {LayerService} from '../../../core/services/meta/layer.service';
import {LayerController} from '../../../core/controllers/layer.controller';
import {Layer} from '../../../shared/models/layer.model';
import {BasePageComponent} from '../../../shared/components/base-page.component';
import {NGSIGeoQuery} from '../../../shared/models/ngsi/ngsi-geo-query.model';
import {environment} from '../../../../environments/environment';
import {KPI} from '../../../shared/models/kpi.model';
import {EntityRepresentation} from '../../../shared/models/visualization/representation.model';
import {Coordinate} from '../../../shared/models/generic/coordinate.model';
import {SearchQuery} from '../../../shared/models/query/search-query.model';
import {Subscription} from 'rxjs';
import { NGSIResult } from 'app/shared/models/ngsi/ngsi-result';
import {MapComponent} from "../../../shared/components/map/map.component";
import {DefibrillatorLayerService} from "../../../core/services/data/defibrillator-layer.service";
import {LayerType} from "../../../shared/enums/layer-type";
import {DefibrillatorLayerController} from "../../../core/controllers/defibrillator-layer.controller";

@Component({
  template: '',
})
export abstract class PageWithMapComponent extends BasePageComponent implements OnInit, OnDestroy {
  /**
   * Layer controllers containing layer-related data and state
   */
  layerControllers: LayerController[] = [];

  /**
   * KPIs referred from the layers
   */
  kpis: Map<string, KPI> = new Map<string, KPI>();

  /**
   * Keeps the entities of layers
   */
  private entitiesMap: Map<string, NGSIResult[]> = new Map<string, NGSIResult[]>();
  // entities displayed on the page
  entities:NGSIResult[] = [];

  // Visibility of layers over map
  layersVisible = true;

  // Indicates whether map is initialized or not
  mapInitialized = false;

  @ViewChild('map') urukmap: MapComponent;

  // Services used in derived components
  protected layerService: LayerService;
  protected layerFactoryService: LayerFactoryService;
  private socketObservable: Subscription;

  constructor(injector: Injector) {
    super(injector);

    this.layerService = injector.get(LayerService);
    this.layerFactoryService = injector.get(LayerFactoryService);
  }

  ngOnInit() {
    super.ngOnInit();

    // handle event subscriptions
    this.handleEventSubscriptions();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.clearLayerExecutionsOnDestroy();
  }

  /**
   * Subscribes to the events to be used in components that are supposed contain maps
   */
  handleEventSubscriptions() {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.MAP_INITIALIZED: {
            // wrap with setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => this.mapInitialized = true);
            // if the location is provided for the page, adjust the map center accordingly
            if (history.state?.location) {
              this.eventService.broadcastPanEvent(new Coordinate(history.state.location));
            }
            this.initializeLayers(); // get layer details and corresponding entities "after" map is initialized
            this.contextService.geographicalContext.next(event.data as NGSIGeoQuery);
            break;
          }
          case EventService.LAYER_PROCESSING_COMPLETED:
            // handle the events only for the layers included in this page
            if(this.page.layerIds.includes(event.data.layer.id)){
              // add layer entities to entities map
              const layerEntities = [];
              for(const representationEntities of event.data.entities.values()){
                representationEntities.forEach(entity => layerEntities.push(entity.getEntityResult()));
              }
              this.entitiesMap.set(event.data.layer.id,layerEntities);
              // retrieve entities from the entities map
              this.entities = [];
              for(let entities of this.entitiesMap.values()){
                entities.forEach(entity => this.entities.push(entity))
              }
            }
            break;
          case EventService.MAP_CHANGED: {
            this.contextService.geographicalContext.next(event.data as NGSIGeoQuery);
            break;
          }
          case EventService.SHAPE_CHANGED: {
            this.contextService.geographicalContext.next(event.data.geoQuery as NGSIGeoQuery);
            break;
          }
          case EventService.MAP_TYPE_CHANGED: {
            this.initializeLayers();
            break;
          }
        }
      });
  }

  /**
   * First retrieves the layer details and creates corresponding LayerControllers. For each layer, underlying KPIs are executed and
   * corresponding controllers are populated with the retrieved data.
   */
  protected initializeLayers() {
    // reset layer controllers
    this.layerControllers = [];
    if (this.page.layerIds.length > 0) {

      // get details of the alert layer
      let layerQuery: SearchQuery = new SearchQuery();
      layerQuery.text = 'Alert';
      this.layerService.getLayers(layerQuery).subscribe(result => {
        // get details of the layers associated with the page
        layerQuery = new SearchQuery();
        layerQuery.ids = this.page.layerIds;
        this.layerService.getLayers(layerQuery).subscribe(layers => {
          // add the alert layer to the page layers if it is not already included
          const alertLayerId = result[0]?.id;
          if (!layers.some(layer => layer.id === alertLayerId)) {
            layers = layers.concat(result);
          }
          if (layers.some(layer => layer.name === LayerType.DEFIBRILLATOR)) {
            DefibrillatorLayerService.instance = null;
          }
          this.eventService.broadcastPageNavigatedEvent(this.page);

          this.layerControllers = this.layerFactoryService.createControllers(layers, this.page);

          // TODO logic about the layers themselves can be moved to the layer controllers
          // initialize kpi execution subject and observables
          this.initializeKpiExecutionObservables();
        });
      });
    }
  }

  protected deleteLayer(layerId: string) {
    // remove layer controller
    const index = this.layerControllers.findIndex(layerController => layerController.layer.id === layerId);
    this.layerControllers.splice(index, 1);
    // remove layer from the page
    this.page.layerIds.splice(index, 1);
  }

  /**
   * By default, when a page component is destroyed, clear layer executions associated with the layers.
   */
  protected clearLayerExecutionsOnDestroy(): void {
    // clear KPI execution observables
    this.layerControllers.forEach(layer => {
      this.clearLayerExecutionOnDestroy(layer);
    });
  }

  /**
   * Clears the KPI execution resources when the layer thumbnail component is destroyed
   * @param layerController
   */
  protected clearLayerExecutionOnDestroy(layerController: LayerController): void {
    // remove kpi executions for layer
    layerController.layer.representations.forEach(representation => {
      this.kpiExecutionService.removeKpiExecution(representation.entityQuery);
    });
    layerController.onDestroy();
  }

  /**
   * Initializes the KPI-specific subjects and subscribes to them. Subscriptions are handle with switchMap to cancel incomplete requests and display
   * the results of the latest query.
   */
  private initializeKpiExecutionObservables(): void {
    // aggregate all KPI ids
    const kpiQuery = new SearchQuery();
    kpiQuery.ids = [];
    this.layerControllers.forEach(layerController => {
      const layer: Layer = layerController.layer;
      layer.representations.forEach(representation => {
        kpiQuery.ids.push(representation.entityQuery.kpiId);
      });
    });

    // retrieve KPI details
    this.kpiService.getKPIs(kpiQuery)
      .pipe(takeUntil(this.destroy$))
      .subscribe(kpis => {
        // populate the kpi map
        kpis.forEach(kpi => {
          this.kpis.set(kpi.id, kpi);
        });

        this.layerControllers.forEach(layerController => {
          const layer: Layer = layerController.layer;
          layer.representations.forEach(representation => {
            const kpi: KPI = this.kpis.get(representation.entityQuery.kpiId);
            // register kpi for later execution
            if(kpi){
              this.kpiExecutionService.registerKpiExecution(representation.entityQuery, kpi)
                .pipe(takeUntil(this.destroy$))
                .subscribe(result => {
                  try {
                    layerController.processData(result, representation);
                    layerController.result = result;
                    layerController.representation = representation;
                  } catch (e) {
                    // TODO check subscriptions to determine whether such an error handling is required or not
                    console.log('Error in processing layer data', e);
                  }
                });
            }

            // subscribe to geographical changes if the kpi overrides geographical queries
            if (kpi?.overrideGeoQuery) {
              this.contextService.geographicalContext
                .pipe(takeUntil(this.destroy$), debounceTime(environment.timeouts.debounceTimes.geologicalQueries))
                .subscribe(_ => {
                  this.refreshRepresentation(layerController, representation);
                });
            }

            // subscribe to temporal changes if the kpi overrides temporal queries
            if (kpi?.overrideTemporalQuery) {
              this.contextService.temporalContext
                .pipe(takeUntil(this.destroy$), debounceTime(environment.timeouts.debounceTimes.geologicalQueries))
                .subscribe(_ => {
                  this.refreshRepresentation(layerController, representation);
                });
            }
          });
      });

      if (this.layerControllers.length > 0) {
        this.refreshAllLayers();
      }
    });
  }

  /**
   * Refreshes all layer representations
   */
  private refreshAllLayers() {
    this.layerControllers.forEach(layerController => {
      layerController.layer.representations.forEach(representation => {
        // check whether the zoom level is not set for the current representation or the current zoom level is between the range of the representation
          this.refreshRepresentation(layerController, representation);
          if (layerController.layer.name === LayerType.DEFIBRILLATOR){
            (layerController as DefibrillatorLayerController).getDefibrillators();
          }
      });
    });
  }

  /**
   * Triggers KPI execution for the given representation if the representation is visible in the current zoom level
   * @param layerController
   * @param representation
   * @private
   */
  private refreshRepresentation(layerController: LayerController, representation: EntityRepresentation): void {
    if (!representation.zoomLevels ||
      (this.layoutService.getZoomLevel() >= representation.zoomLevels[0] && this.layoutService.getZoomLevel() <= representation.zoomLevels[1])) {
      this.kpiExecutionService.triggerKpiExecution(representation.entityQuery);

      // if the representation is not active at the current zoom level, clear the entities for it
    } else {
      layerController.clearEntities(representation);
    }
  }

  /**
   * Display layers (markers, polygons) over map
   */
  onLayersVisibilityToggled() {
    this.layersVisible = !this.layersVisible;
    this.layerControllers.forEach(layer => {
      layer.setVisibility(this.layersVisible);
    });

    if (this.layersVisible) {
      this.eventService.broadcastShowLayersEvent();
    } else {
      this.eventService.broadcastHideLayersEvent();
    }
  }
}
