import {Component, EventEmitter, Injector, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseFormComponent} from '../form/base-form.component';
import {MLModel} from '../../models/analytics/mlmodel.model';
import {AnalyticsService} from '../../../core/services/meta/analytics.service';
import {PAGINATION_DEFAULT_PAGE_SIZE} from '../list/pagination.component';
import {PaginationCallbackFunction} from '../list/list.component';
import {
  GenericConfirmationDialogComponent
} from '../../../modules/dialog/confirmation-dialog/generic-confirmation-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {ListViewFilterFunction} from '../../../modules/admin/components/list-view.component';
import {PageTemplate} from '../../models/page-template.model';
import {Coordinate} from '../../models/generic/coordinate.model';

/**
 * A component to search for and select KPIs.
 */
@Component({
  selector: 'uruk-analytics-search',
  templateUrl: './analytics-search.component.html',
  styleUrls: ['./analytics-search.component.scss']
})
export class AnalyticsSearchComponent extends BaseFormComponent implements OnInit, OnDestroy {

  models: MLModel[] = []; // list of ML models created by the users
  paginatedModels: MLModel[]; // paginated version of the ML model list
  selectedModel: MLModel; // the ML Model selected by the user
  selectedTrainedModel: string; // the trained model file selected by the user

  statusMetadata = []; // status information (count, etc.) for the previously created ML models
  columnMetadata = MLModel.getSummaryColumnMetadata(); // column information to display specific information regarding ML models

  step = 0; // the step identifier

  // Event emitters
  @Output() onModelSelected: EventEmitter<MLModel> = new EventEmitter<MLModel>();
  @Output() onTrainedModelSelected: EventEmitter<string> = new EventEmitter<string>();

  // Pagination variables
  private pageSize = PAGINATION_DEFAULT_PAGE_SIZE;
  private page = 1;
  public paginationCallback: PaginationCallbackFunction;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getModels();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * Retrieves all ML models from the server
   */
  getModels(searchText?: string) {
    this.analyticsService.getModelConfigs(searchText).subscribe(models => {
      this.models = models;
      this.updateStatusMetadata();
    });
  }

  /**
   * Orchestrates the model deletion with given model id
   * @param id Identifier of the model to be deleted
   */
  deleteModel(id) {
    // open the dialog
    const dialogRef = this.dialogService.open(GenericConfirmationDialogComponent, {
      context: {
        title: 'Delete Model',
        body: this.translateService.instant('Are you sure you want to delete model ?',
          {name: this.models.find(template => template.id === id).name})
      }
    });

    // delete model based on user answer
    dialogRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.analyticsService.deleteModel(id).subscribe(() => {
            this.models = this.models.filter(template => template.id !== id);
            this.updateStatusMetadata(this.pageSize, this.page);
          });
        }
      });
  }

  /**
   * Searches for models based on user typing
   * @param searchText
   */
  searchModels(searchText) {
    this.getModels(searchText);
  }

  /**
   * Selects the given model and displays its trained model files
   * @param model
   */
  selectModel(model) {
    // find the correct model
    const models = this.models.filter(m => m.id = model.id);
    if (models.length > 0) {
      this.step = 1;
      this.selectedModel = models[0];
      this.onModelSelected.emit(this.selectedModel);
    }
  }

  /**
   * Updates {@link statusMetadata} with page template count.
   * */
  private updateStatusMetadata(pageSize = PAGINATION_DEFAULT_PAGE_SIZE, page = 1) {
    this.statusMetadata = [{
      'title': 'Models',
      'class': 'title',
      'count': this.models.length,
      'commaSeparated': false
    }];

    this.setPaginatedModels(pageSize, page);
  }

  /**
   * Sets {@link paginatedModels} based on the page size and current page.
   */
  private setPaginatedModels(pageSize = PAGINATION_DEFAULT_PAGE_SIZE, page = 1) {
    this.pageSize = pageSize;
    this.page = page;
    this.paginatedModels = this.models.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
  }

  /**
   * Sorts ML models ascending based on given the field
   * @param field
   */
  sortAscending(field) {
    // TODO: Handle paginated queries
    if (field === 'creationTime') {
      this.models.sort((a, b) => {
        return a.meta.createdAt.getTime() - b.meta.createdAt.getTime();
      });
      this.updateStatusMetadata(this.pageSize, this.page);
    }
  }

  /**
   * Sorts ML models descending based on given the field
   * @param field
   */
  sortDescending(field) {
    // TODO: Handle paginated queries
    if (field === 'creationTime') {
      this.models.sort((a, b) => {
        return b.meta.createdAt.getTime() - a.meta.createdAt.getTime();
      });
      this.updateStatusMetadata(this.pageSize, this.page);
    }
  }

  /**
   * {@link ListViewFilterFunction} which sets the visibility of delete buttons in {@link ListViewComponent}
   * */
  public displayDeleteButton: ListViewFilterFunction = (template: PageTemplate) => {
    return false;
  }

  /**
   * Fired when a trained model file is selected
   */
  onTrainedModelSelect() {
    this.onTrainedModelSelected.emit(this.selectedTrainedModel);
  }

  /**
   * Returns back to the ML model selection
   */
  previousStep() {
    this.step = 0;
  }
}
