import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class EcgService {
  private packetDurSec: number = 10;
  private fSamp: number = 250;
  private sigBuffSize: number;
  private sigBuffWriteInd: number = 0;
  private cycle: number = 0;

  private ecgRawBuff: number[];
  private ecgLpfBuff: number[];
  private ecgBpfBuff: number[];

  private lpfDenom: number[] = [0.0003, 0.0020, 0.0051, 0.0068, 0.0051, 0.0020, 0.0003];
  private lpfNumer: number[] = [1, -3.5794, 5.6587, -4.9654, 2.5295, -0.7053, 0.0838];
  private hpfDenom: number[] = [0.9938, -0.9938];
  private hpfNumer: number[] = [1, -0.9876];

  constructor() {
    this.initializeEcgDisplayFilterAlgo();
  }

  public initializeEcgDisplayFilterAlgo(size = undefined): void {
    this.sigBuffSize = size === undefined ? (30 * this.packetDurSec * this.fSamp) : size;
    this.sigBuffWriteInd = 0;

    this.ecgRawBuff = new Array(this.sigBuffSize).fill(0);
    this.ecgLpfBuff = new Array(this.sigBuffSize).fill(0);
    this.ecgBpfBuff = new Array(this.sigBuffSize).fill(0);

    this.cycle = 0;
  }

  public filterEcgPacket(ecgPacket: any[]): any[] {
    this.cycle += 1;

    this.sigBuffWriteInd += ecgPacket.length;
    if (this.sigBuffWriteInd > this.sigBuffSize) {
      const halfLength = Math.floor(this.ecgRawBuff.length / 2);

      for (let i = 0; i < halfLength; i++) {
        this.ecgRawBuff[i] = this.ecgRawBuff[i + halfLength];
        this.ecgLpfBuff[i] = this.ecgLpfBuff[i + halfLength];
        this.ecgBpfBuff[i] = this.ecgBpfBuff[i + halfLength];
      }

      for (let i = halfLength; i < this.ecgRawBuff.length; i++) {
        this.ecgRawBuff[i] = 0;
        this.ecgLpfBuff[i] = 0;
        this.ecgBpfBuff[i] = 0;
      }

      this.sigBuffWriteInd = halfLength + 1;
    }
    for (let i = 0; i < ecgPacket.length; i++) {
      const index = (this.sigBuffWriteInd - ecgPacket.length + i) % this.sigBuffSize;
      this.ecgRawBuff[index] = ecgPacket[i].value;
    }


      if (this.cycle === 1) {
        for (let i = 0; i < ecgPacket.length; i++) {
          const index = (this.sigBuffWriteInd - ecgPacket.length + i) % this.sigBuffSize;
          if (index > 5) {
            this.ecgLpfBuff[index] = this.lpfDenom[0] * this.ecgRawBuff[index] + this.lpfDenom[1] * this.ecgRawBuff[index - 1] + this.lpfDenom[2] * this.ecgRawBuff[index - 2]
              + this.lpfDenom[3] * this.ecgRawBuff[index - 3] + this.lpfDenom[4] * this.ecgRawBuff[index - 4] + this.lpfDenom[5] * this.ecgRawBuff[index - 5]
              + this.lpfDenom[6] * this.ecgRawBuff[index - 6] - (this.lpfNumer[1] * this.ecgLpfBuff[index - 1] + this.lpfNumer[2] * this.ecgLpfBuff[index - 2]
                + this.lpfNumer[3] * this.ecgLpfBuff[index - 3] + this.lpfNumer[4] * this.ecgLpfBuff[index - 4] + this.lpfNumer[5] * this.ecgLpfBuff[index - 5]
                + this.lpfNumer[6] * this.ecgLpfBuff[index - 6]);
          } else {
            this.ecgLpfBuff[index] = 0;
          }
          if (index > 6) {
            this.ecgBpfBuff[index] = this.hpfDenom[0] * this.ecgLpfBuff[index] + this.hpfDenom[1] * (this.ecgLpfBuff[index - 1]) - this.hpfNumer[1] * (this.ecgBpfBuff[index - 1]);
          } else {
            this.ecgBpfBuff[index] = 0;
          }
        }
      } else {
        for (let i = 0; i < ecgPacket.length; i++) {
          const index = (this.sigBuffWriteInd - ecgPacket.length + i) % this.sigBuffSize;
          let index1 = index - 1;
          if (index1 < 0) {
            index1 += this.sigBuffSize;
          }
          let index2 = index - 2;
          if (index2 < 0) {
            index2 += this.sigBuffSize;
          }
          let index3 = index - 3;
          if (index3 < 0) {
            index3 += this.sigBuffSize;
          }
          let index4 = index - 4;
          if (index4 < 0) {
            index4 += this.sigBuffSize;
          }
          let index5 = index - 5;
          if (index2 < 0) {
            index5 += this.sigBuffSize;
          }
          let index6 = index - 6;
          if (index6 < 0) {
            index6 += this.sigBuffSize;
          }

          this.ecgLpfBuff[index] = this.lpfDenom[0] * this.ecgRawBuff[index] + this.lpfDenom[1] * this.ecgRawBuff[index1] + this.lpfDenom[2] * this.ecgRawBuff[index2]
            + this.lpfDenom[3] * this.ecgRawBuff[index3] + this.lpfDenom[4] * this.ecgRawBuff[index4] + this.lpfDenom[5] * this.ecgRawBuff[index5]
            + this.lpfDenom[6] * this.ecgRawBuff[index6] - (this.lpfNumer[1] * this.ecgLpfBuff[index1] + this.lpfNumer[2] * this.ecgLpfBuff[index2]
              + this.lpfNumer[3] * this.ecgLpfBuff[index3] + this.lpfNumer[4] * this.ecgLpfBuff[index4] + this.lpfNumer[5] * this.ecgLpfBuff[index5]
              + this.lpfNumer[6] * this.ecgLpfBuff[index6]);
          this.ecgBpfBuff[index] = this.hpfDenom[0] * this.ecgLpfBuff[index] + this.hpfDenom[1] * this.ecgLpfBuff[index1] - this.hpfNumer[1] * this.ecgBpfBuff[index1];
        }
      }

    // Extract the filtered ECG packet
    for (let i = 0; i < ecgPacket.length; i++) {
      const index = (this.sigBuffWriteInd - ecgPacket.length + i) % this.sigBuffSize;
      ecgPacket[i] = this.ecgBpfBuff[index];
    }

    return ecgPacket;
  }

  public ecgDisplayFilterAlgoFlow(ecgData: any[]): any[] {
    ecgData.sort((a, b) => {
      const dateA = new Date(a.observedAt);
      const dateB = new Date(b.observedAt);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });
    if(ecgData.length > this.sigBuffSize){
      this.initializeEcgDisplayFilterAlgo(ecgData.length)
    }
    const ecgDispArray: number[] = new Array(ecgData.length).fill(0);
    const ecgSamplesPerPacket: number = this.packetDurSec * this.fSamp;
    const nPackets: number = Math.ceil(ecgData.length / ecgSamplesPerPacket);

    for (let i = 0; i < nPackets; i++) {
      const indMin: number = (i * ecgSamplesPerPacket);
      const indMax: number = Math.min((i+1) * ecgSamplesPerPacket, ecgData.length);

      const ecgPacket: any[] = ecgData.slice(indMin, indMax);
      const ecgDispPacket: any[] = this.filterEcgPacket(ecgPacket);

      for (let j = indMin, k = 0; j < indMax; j++, k++) {
        ecgData[j].value = ecgDispPacket[k];
      }
    }
    // this.initializeEcgDisplayFilterAlgo();
    console.log(ecgData[ecgData.length - 1]);
    return ecgData;
  }
}
