export class AirConditionModel {
  driverAirConditioningTemperature: any;
  passengerAirConditioningMode: any;
  hvacsInhibitionList: any[];
  driverMajorHvac: any[];
  passengerMajorHvac1: any[];
  passengerMajorHvac2: any[];
  driverMinorHvac: any[];
  passengerMinorHvac1: any[];
  passengerMinorHvac2: any[];

  constructor(data: any) {
    this.driverAirConditioningTemperature = data.driverAirConditioningTemperature;
    this.passengerAirConditioningMode = data.passengerAirConditioningMode;
    this.hvacsInhibitionList = data.hvacsInhibitionList;
    this.driverMajorHvac = data.driverMajorHvac;
    this.passengerMajorHvac1 = data.passengerMajorHvac1;
    this.passengerMajorHvac2 = data.passengerMajorHvac2;
    this.driverMinorHvac = data.driverMinorHvac;
    this.passengerMinorHvac1 = data.passengerMinorHvac1;
    this.passengerMinorHvac2 = data.passengerMinorHvac2;
  }
}
