import {Color} from '../enums/color';

export class SVGTrainRules {
  public static getColorRules() {
    return {
      tractionSystem: [
        {
          condition: (index, data) => data.predictiveMaintenanceCode === 6,
          color: Color.Red,
          target: 'cer'
        },
        {
          condition: function (index, data) {
            return data.predictiveMaintenanceCode === 2 ||
              data.predictiveMaintenanceCode === 3;
          },
          color: Color.Red,
          target: 'cer'
        },
        {
          condition: (index, data) => data.predictiveMaintenanceCode === 1,
          color: Color.Red,
          target: 'bogi'
        },
        {
          condition: (index, data) => data.predictiveMaintenanceCode === 7,
          color: Color.Red,
          target: 'bogi'
        },
        {
          condition: (index, data) => data.predictiveMaintenanceCode === 4,
          color: Color.Red,
          target: 'bogi'
        },
        {
          condition: (index, data) => data.predictiveMaintenanceCode === 5,
          color: Color.Red,
          target: 'bogi'
        },
      ],

      brakeSystem: [
        {
          condition: (index, data) => data.brakeModel.majorBrWsp[index] === 1,
          color: Color.Red,
          target: 'brake'
        },
        {
          condition: (index, data) => data.brakeModel.ebcu[index] === 0,
          color: Color.Red,
          target: 'brake'
        },
        {
          condition: (index, data) => data.brakeModel.majorBrParkList[index] === 1,
          color: Color.Red,
          target: 'brake'
        },
        {
          condition: (index, data) => data.brakeModel.diagBrDirectList[index] === 1,
          color: Color.Red,
          target: 'brake'
        },
        {
          condition: (index, data) => data.brakeModel.diagBrCPress[index] === 1,
          color: Color.Red,
          target: 'brake'
        },
        {
          condition: function (index, data) {
            return data.brakeModel.majorBrWsp[index] === 0 &&
              data.brakeModel.ebcu[index] === 1 &&
              data.brakeModel.majorBrParkList[index] === 0 &&
              data.brakeModel.diagBrDirectList[index] === 0 &&
              data.brakeModel.diagBrCPress[index] === 0;
          },
          color: Color.Green,
          target: 'text',
          unit: ' bar',
          min: 6,
          max: 12
        },
        {
          condition: function (index, data) {
            return data.brakeModel.majorBrWsp[index] === 1 ||
             data.brakeModel.ebcu[index] === 0 ||
             data.brakeModel.majorBrParkList[index] === 1 ||
             data.brakeModel.diagBrDirectList[index] === 1 ||
             data.brakeModel.diagBrCPress[index] === 1;
          },
          color: Color.Red,
          target: 'text',
          unit: ' bar',
          min: 13,
          max: 20
        },
      ],

      powerVoltageSystem: [
        {
          condition: function (index, data) {
            return data.powerVoltageModel.tractionTransformerTemp[index] >= 55 &&
              data.powerVoltageModel.tractionTransformerTemp[index] <= 110;
          },
          color: Color.Orange,
          target: 'transformer'
        },
        {
          condition: (index, data) => data.powerVoltageModel.tractionTransformerTemp[index] > 110,
          color: Color.Red,
          target: 'transformer'
        },
        {
          condition: (index, data) => data.powerVoltageModel.tractionTransformerOilFlow[index] === 1,
          color: Color.Red,
          target: 'transformer'
        },
        {
          condition: (index, data) => data.powerVoltageModel.tractionTransformerOilLevel[index] === 1,
          color: Color.Red,
          target: 'transformer'
        },
        {
          condition: (index, data) => data.powerVoltageModel.tractionTransformerOverPressure[index] === 1,
          color: Color.Red,
          target: 'transformer'
        },
        {
          condition: function (index, data) {
            return data.powerVoltageModel.measuringBatteryVoltage[index] >= 80 &&
              data.powerVoltageModel.measuringBatteryVoltage[index] <= 90;
          },
          color: Color.Orange,
          target: 'battery'
        },
        {
          condition: (index, data) => data.powerVoltageModel.measuringBatteryVoltage[index] < 80,
          color: Color.Red,
          target: 'battery'
        },
        {
          condition: function (index, data) {
            return data.powerVoltageModel.tempBattery[index] > 40 ||
              data.powerVoltageModel.measuringBatteryVoltage[index] < -10;
          },
          color: Color.Red,
          target: 'battery'
        },
      ],

      airConditionSystem: [
        {
          condition: (index, data) => data.airConditionModel.driverMinorHvac[index] === 1,
          color: Color.Orange,
          target: 'driver-aircondition'
        },
        {
          condition: (index, data) => data.airConditionModel.driverMajorHvac[index] === 1,
          color: Color.Red,
          target: 'driver-aircondition'
        },
        {
          condition: (index, data) => data.airConditionModel.passengerMinorHvac1[index] === 1,
          color: Color.Orange,
          target: 'passenger-aircondition1'
        },
        {
          condition: (index, data) => data.airConditionModel.passengerMajorHvac1[index] === 1,
          color: Color.Red,
          target: 'passenger-aircondition1'
        },
        {
          condition: (index, data) => data.airConditionModel.passengerMinorHvac2[index] === 1,
          color: Color.Orange,
          target: 'passenger-aircondition2'
        },
        {
          condition: (index, data) => data.airConditionModel.passengerMajorHvac2[index] === 1,
          color: Color.Red,
          target: 'passenger-aircondition2'
        },
        {
          condition: (index, data) => true,
          color: Color.White,
          target: 'text',
          unit: ' \u00B0C',
          min: -10,
          max: 50
        },
      ],

      doorSystem: [
        {
          condition: (index, data) => data.doorModel.innerDoorError[index] === 1,
          color: Color.Red,
          target: 'inner'
        },
        {
          condition: (index, data) => data.doorModel.outerDoorStatus[index] === 0,
          color: Color.Gray,
          target: 'outer'
        },
        {
          condition: (index, data) => data.doorModel.outerDoorStatus[index] === 1,
          color: Color.Blue,
          target: 'outer'
        },
        {
          condition: (index, data) => data.doorModel.outerDoorStatus[index] === 2,
          color: Color.Green,
          target: 'outer'
        },
        {
          condition: function (index, data) {
            return data.doorModel.doorFaultPriorityBActive[index] === 1 ||
              data.doorModel.stepFaultPriorityBActive[index] === 1 ||
              data.doorModel.doorFaultPriorityCActive[index] === 1 ||
              data.doorModel.stepFaultPriorityCActive[index] === 1;
          },
          color: Color.Orange,
          target: 'outer'
        },
        {
          condition: function (index, data) {
            return data.doorModel.doorFaultPriorityAActive[index] === 1 ||
              data.doorModel.stepFaultPriorityAActive[index] === 1;
          },
          color: Color.Red,
          target: 'outer'
        },
        {
          condition: (index, data) => data.doorModel.doorInsulationList[index] === 1,
          color: Color.Yellow,
          target: 'outer'
        },
      ],

      communicationSystem: []
    };
  }
}
