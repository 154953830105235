import {BaseMetaModel} from '../base/base-meta-model.model';
import {ReportTarget} from './report-target.model';
import {UrukTemporalQuery} from '../query/uruk-temporal-query.model';
import {NGSIGeoQuery} from '../ngsi/ngsi-geo-query.model';

export class ReportConfig extends BaseMetaModel {

  public name: string;
  public individualSourceKpiIds: string[];
  public crossAggregationSourceKpiIds: string[][];
  public temporalQuery: UrukTemporalQuery;
  public geoQuery: NGSIGeoQuery;
  public format: string;
  public frequency: string;
  public active: boolean;
  public time: number;
  public target: ReportTarget;

  constructor(data?: any) {
    super(data);
    this.setData(data);
  }

  public setData(data?: any) {
    if (!data) {
      return;
    }

    this.name = data.name;
    this.individualSourceKpiIds = data.individualSourceKpiIds;
    this.crossAggregationSourceKpiIds = data.crossAggregationSourceKpiIds;

    if (data.temporalQuery) {
      this.temporalQuery = new UrukTemporalQuery(data.temporalQuery);
    } else {
      this.temporalQuery = undefined;
    }

    if (data.geoQuery) {
      this.geoQuery = new NGSIGeoQuery(data.geoQuery);
    } else {
      this.geoQuery = undefined;
    }

    this.format = data.format;
    this.frequency = data.frequency;
    this.active = data.active;
    this.time = data.time;
    this.target = new ReportTarget(data.target);
  }
}
