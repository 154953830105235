import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {NbCalendarRange} from '@nebular/theme';
import {BaseDialogComponent} from '../../../shared/components/dialog/base-dialog.component';
import {UrukTemporalQuery} from '../../../shared/models/query/uruk-temporal-query.model';
import {TimeInterval, TimeUtil} from '../../../shared/utils/time-util';
import {TemporalQueryType} from '../../../shared/enums/temporal-query-type.enum';
import {EventService} from '../../../core/services/event.service';
import {SelectWidthFormatterFunction} from '../../../shared/directives/select-width.directive';
import {NGSITemporalRelation} from '../../../shared/enums/ngsi-query.enum';

@Component({
  selector: 'uruk-date-selection-dialog',
  templateUrl: './date-selection-dialog.component.html',
  styleUrls: ['./date-selection-dialog.component.scss']
})
export class DateSelectionDialogComponent extends BaseDialogComponent implements OnInit, OnDestroy {
  // temporal query being updated
  @Input() temporalQuery: UrukTemporalQuery;

  // angular-specific component id of the component that has initiated this dialog i.e. c265
  @Input() dialogSource: string;

  // whether the custom date buttons such as 'Last 3 Months' are displayed for the selection
  @Input() displayCustomDateButtons: boolean = true;

  // whether period tab is visible or not
  @Input() showPeriodTab: boolean = false;

  // whether only the day will be shown or not. It overrides the "showPeriodTab" input
  @Input() onlyDayTab = false;

  // keeps the active tab
  activeTab: string;

  // variables to keep selected day, month, year or custom dates
  startDate: Date;
  endDate: Date;
  queryType: string;
  month = new Date();
  year = new Date();
  range: NbCalendarRange<Date>;
  interval: number;

  // variables to keep selected period
  periodValue: string = null;
  periodUnit: string = null;

  TimeInterval = TimeInterval;
  TimeIntervalKeys = Object.keys(TimeInterval);
  // service definitions
  protected eventService: EventService;

  constructor(injector: Injector) {
    super(injector);

    this.eventService = injector.get(EventService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // initialize the dialog based on the given temporal query
    if (this.temporalQuery) {
      this.startDate = this.temporalQuery.time;
      this.endDate = this.temporalQuery.endTime;
      this.queryType = this.temporalQuery.type;
    }
    this.interval = this.year.getFullYear();

    // set the active tab based on the query type
    switch (this.queryType) {
      case TemporalQueryType.DATE:
        this.activeTab = '1';
        break;
      case TemporalQueryType.MONTH:
        this.activeTab = '2';
        break;
      case TemporalQueryType.YEAR:
        this.activeTab = '3';
        break;
      case TemporalQueryType.CUSTOM:
        this.activeTab = '4';
        break;
      case TemporalQueryType.PERIOD:
        this.activeTab = '5';
        break;
    }

    if (this.queryType === TemporalQueryType.MONTH) {
      this.month = this.endDate;
    }

    if (this.queryType === TemporalQueryType.YEAR) {
      this.year = this.endDate;
    }

    if (this.queryType === TemporalQueryType.CUSTOM) {
      this.range = {
        start: this.startDate,
        end: this.endDate
      };
    }

    if (this.queryType === TemporalQueryType.PERIOD) {
      // parse the period
      const periodValueUnit = TimeUtil.parsePeriod(this.temporalQuery.period);

      this.periodValue = periodValueUnit[0];
      this.periodUnit = periodValueUnit[1];
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onDateChange(event) {
    const temporalQuery = UrukTemporalQuery.createTemporalQuery(TimeUtil.startOfDay(this.startDate), TimeUtil.endOfDay(this.startDate), TemporalQueryType.DATE);
    this.setTemporalQueryAndClose(temporalQuery);
  }

  onMonthChange(event) {
    const startDate = new Date(this.interval, this.month.getMonth(), 1);
    const temporalQuery = UrukTemporalQuery.createTemporalQuery(TimeUtil.startOfDay(startDate), TimeUtil.endOfMonth(startDate), TemporalQueryType.MONTH);
    this.setTemporalQueryAndClose(temporalQuery);
  }

  onYearChange(event) {
    const temporalQuery = UrukTemporalQuery.createTemporalQuery(TimeUtil.startOfYear(this.year), TimeUtil.endOfYear(this.year), TemporalQueryType.YEAR);
    this.setTemporalQueryAndClose(temporalQuery);
  }

  onCustomRangeChange(event) {
    if (event.start && event.end) {
      const temporalQuery = UrukTemporalQuery.createTemporalQuery(TimeUtil.startOfDay(event.start), TimeUtil.endOfDay(event.end), TemporalQueryType.CUSTOM);
      this.setTemporalQueryAndClose(temporalQuery);
    }
  }

  onPeriodChange() {
    if (this.periodValue && this.periodUnit) {
      const period = `${this.periodValue}` + this.periodUnit;
      this.setTemporalQueryAndClose(UrukTemporalQuery.createTemporalQueryForPeriod(period));
    }
  }

  onPeriodValueChange(event) {
    // if the period value is selected previously, clear period unit to allow user to select a new period
    if (this.periodValue) {
      this.periodUnit = null;
    }
    this.periodValue = event;
    this.onPeriodChange();
  }

  onPeriodUnitChange(event) {
    // if the period unit is selected previously, clear period value to allow user to select a new period
    if (this.periodUnit) {
      this.periodValue = null;
    }
    this.periodUnit = event;

    this.onPeriodChange();
  }

  onTodayClicked() {
    this.setTemporalQueryAndClose(UrukTemporalQuery.today());
  }

  onLast24HoursClicked() {
    this.setTemporalQueryAndClose(UrukTemporalQuery.last24Hours());
  }

  onLastWeekClicked() {
    this.setTemporalQueryAndClose(UrukTemporalQuery.lastWeek());
  }

  onLastMonthClicked() {
    this.setTemporalQueryAndClose(UrukTemporalQuery.lastMonth());
  }

  onLast3MonthsClicked() {
    this.setTemporalQueryAndClose(UrukTemporalQuery.last3Months());
  }

  onLastYearClicked() {
    this.setTemporalQueryAndClose(UrukTemporalQuery.lastYear());
  }

  onLast3YearsClicked() {
    this.setTemporalQueryAndClose(UrukTemporalQuery.last3Years());
  }

  setTemporalQueryAndClose(temporalQuery: UrukTemporalQuery) {
    this.eventService.broadcastTimeChangeEvent(temporalQuery, this.dialogSource);
    this.dialogRef.close();
  }

  increaseInterval() {
    if (this.activeTab === '2') {
      this.interval++;
    } else if (this.activeTab === '3') {
      this.interval += 12;
      this.year = TimeUtil.addYears(this.year, 12);
    }
  }

  decreaseInterval() {
    if (this.activeTab === '2') {
      this.interval--;
    } else if (this.activeTab === '3') {
      this.interval -= 12;
      this.year = TimeUtil.addYears(this.year, -12);
    }
  }

  getYearInterval() {
    const start = Math.floor(this.interval / 12) * 12;
    const end = start + 11;

    return start + ' - ' + end;
  }

  onTabChange(event) {
    this.activeTab = event.tabId;
  }

  /**
   * {@link SelectWidthFormatterFunction} for the keys in {@link TimeInterval}.
   * */
  public timeIntervalFormatter: SelectWidthFormatterFunction = (timeIntervalKeys: string[]) => {
    return timeIntervalKeys ? timeIntervalKeys.map(key => `timeInterval_${TimeInterval[key]}`) : [];
  }
}
