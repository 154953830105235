import {LegendItem} from '../components/legend/legend.item';

/****** COLORS ******/
const weatherPollutionGoodColor = '#34A522';
const weatherPollutionModerateColor = '#E3BB2C';
const weatherPollutionSensitiveColor = '#F96B1B';
const weatherPollutionUnhealthyColor = '#F91B1B';
const weatherPollutionVeryUnhealthyColor = '#D2007E';
const weatherPollutionHazardousColor = '#980000';

/****** TOP LEVELS OF WEATHER POLLUTANTS ******/

const AQI_GOOD = 50;
const AQI_MODERATE = 100;
const AQI_SENSITIVE = 150;
const AQI_UNHEALTHY = 200;
const AQI_VERY_UNHEALTHY = 300;
const AQI_HAZARDOUS = 500;

const CO_GOOD = 5500;
const CO_MODERATE = 10000;
const CO_SENSITIVE = 16000;
const CO_UNHEALTHY = 24000;
const CO_VERY_UNHEALTHY = 32000;
const CO_HAZARDOUS = 50000;

const NO2_GOOD = 40;
const NO2_MODERATE = 90;
const NO2_SENSITIVE = 120;
const NO2_UNHEALTHY = 230;
const NO2_VERY_UNHEALTHY = 340;
const NO2_HAZARDOUS = 1000;

const SO2_GOOD = 100;
const SO2_MODERATE = 200;
const SO2_SENSITIVE = 350;
const SO2_UNHEALTHY = 500;
const SO2_VERY_UNHEALTHY = 750;
const SO2_HAZARDOUS = 1250;

const O3_GOOD = 50;
const O3_MODERATE = 100;
const O3_SENSITIVE = 130;
const O3_UNHEALTHY = 240;
const O3_VERY_UNHEALTHY = 380;
const O3_HAZARDOUS = 800;

const PM10_GOOD = 20;
const PM10_MODERATE = 40;
const PM10_SENSITIVE = 50;
const PM10_UNHEALTHY = 100;
const PM10_VERY_UNHEALTHY = 150;
const PM10_HAZARDOUS = 1200;

const PM25_GOOD = 12;
const PM25_MODERATE = 35;
const PM25_SENSITIVE = 55;
const PM25_UNHEALTHY = 150;
const PM25_VERY_UNHEALTHY = 250;
const PM25_HAZARDOUS = 1200;

/****** LEGENDS ******/

const AQI_LEGEND: LegendItem[] = [{
  min: 0, max: AQI_GOOD, title: 'Weather Pollution - Good', description: '', color: weatherPollutionGoodColor
}, {
  min: AQI_GOOD,
  max: AQI_MODERATE,
  title: 'Weather Pollution - Moderate',
  description: '',
  color: weatherPollutionModerateColor
}, {
  min: AQI_MODERATE,
  max: AQI_SENSITIVE,
  title: 'Weather Pollution - Sensitive',
  description: '',
  color: weatherPollutionSensitiveColor
}, {
  min: AQI_SENSITIVE,
  max: AQI_UNHEALTHY,
  title: 'Weather Pollution - Unhealthy',
  description: '',
  color: weatherPollutionUnhealthyColor
}, {
  min: AQI_UNHEALTHY,
  max: AQI_VERY_UNHEALTHY,
  title: 'Weather Pollution - Very Unhealthy',
  description: '',
  color: weatherPollutionVeryUnhealthyColor
}, {
  min: AQI_VERY_UNHEALTHY,
  max: AQI_HAZARDOUS,
  title: 'Weather Pollution - Hazardous',
  description: '',
  color: weatherPollutionHazardousColor
}];

const CO_LEGEND: LegendItem[] = [{
  min: 0, max: CO_GOOD, title: 'Weather Pollution - Good', description: '', color: weatherPollutionGoodColor
}, {
  min: CO_GOOD,
  max: CO_MODERATE,
  title: 'Weather Pollution - Moderate',
  description: '',
  color: weatherPollutionModerateColor
}, {
  min: CO_MODERATE,
  max: CO_SENSITIVE,
  title: 'Weather Pollution - Sensitive',
  description: '',
  color: weatherPollutionSensitiveColor
}, {
  min: CO_SENSITIVE,
  max: CO_UNHEALTHY,
  title: 'Weather Pollution - Unhealthy',
  description: '',
  color: weatherPollutionUnhealthyColor
}, {
  min: CO_UNHEALTHY,
  max: CO_VERY_UNHEALTHY,
  title: 'Weather Pollution - Very Unhealthy',
  description: '',
  color: weatherPollutionVeryUnhealthyColor
}, {
  min: CO_VERY_UNHEALTHY,
  max: CO_HAZARDOUS,
  title: 'Weather Pollution - Hazardous',
  description: '',
  color: weatherPollutionHazardousColor
}];

const NO2_LEGEND: LegendItem[] = [{
  min: 0, max: NO2_GOOD, title: 'Weather Pollution - Good', description: '', color: weatherPollutionGoodColor
}, {
  min: NO2_GOOD,
  max: NO2_MODERATE,
  title: 'Weather Pollution - Moderate',
  description: '',
  color: weatherPollutionModerateColor
}, {
  min: NO2_MODERATE,
  max: NO2_SENSITIVE,
  title: 'Weather Pollution - Sensitive',
  description: '',
  color: weatherPollutionSensitiveColor
}, {
  min: NO2_SENSITIVE,
  max: NO2_UNHEALTHY,
  title: 'Weather Pollution - Unhealthy',
  description: '',
  color: weatherPollutionUnhealthyColor
}, {
  min: NO2_UNHEALTHY,
  max: NO2_VERY_UNHEALTHY,
  title: 'Weather Pollution - Very Unhealthy',
  description: '',
  color: weatherPollutionVeryUnhealthyColor
}, {
  min: NO2_VERY_UNHEALTHY,
  max: NO2_HAZARDOUS,
  title: 'Weather Pollution - Hazardous',
  description: '',
  color: weatherPollutionHazardousColor
}];

const SO2_LEGEND: LegendItem[] = [{
  min: 0, max: SO2_GOOD, title: 'Weather Pollution - Good', description: '', color: weatherPollutionGoodColor
}, {
  min: SO2_GOOD,
  max: SO2_MODERATE,
  title: 'Weather Pollution - Moderate',
  description: '',
  color: weatherPollutionModerateColor
}, {
  min: SO2_MODERATE,
  max: SO2_SENSITIVE,
  title: 'Weather Pollution - Sensitive',
  description: '',
  color: weatherPollutionSensitiveColor
}, {
  min: SO2_SENSITIVE,
  max: SO2_UNHEALTHY,
  title: 'Weather Pollution - Unhealthy',
  description: '',
  color: weatherPollutionUnhealthyColor
}, {
  min: SO2_UNHEALTHY,
  max: SO2_VERY_UNHEALTHY,
  title: 'Weather Pollution - Very Unhealthy',
  description: '',
  color: weatherPollutionVeryUnhealthyColor
}, {
  min: SO2_VERY_UNHEALTHY,
  max: SO2_HAZARDOUS,
  title: 'Weather Pollution - Hazardous',
  description: '',
  color: weatherPollutionHazardousColor
}];

const O3_LEGEND: LegendItem[] = [{
  min: 0, max: O3_GOOD, title: 'Weather Pollution - Good', description: '', color: weatherPollutionGoodColor
}, {
  min: O3_GOOD,
  max: O3_MODERATE,
  title: 'Weather Pollution - Moderate',
  description: '',
  color: weatherPollutionModerateColor
}, {
  min: O3_MODERATE,
  max: O3_SENSITIVE,
  title: 'Weather Pollution - Sensitive',
  description: '',
  color: weatherPollutionSensitiveColor
}, {
  min: O3_SENSITIVE,
  max: O3_UNHEALTHY,
  title: 'Weather Pollution - Unhealthy',
  description: '',
  color: weatherPollutionUnhealthyColor
}, {
  min: O3_UNHEALTHY,
  max: O3_VERY_UNHEALTHY,
  title: 'Weather Pollution - Very Unhealthy',
  description: '',
  color: weatherPollutionVeryUnhealthyColor
}, {
  min: O3_VERY_UNHEALTHY,
  max: O3_HAZARDOUS,
  title: 'Weather Pollution - Hazardous',
  description: '',
  color: weatherPollutionHazardousColor
}];

const PM10_LEGEND: LegendItem[] = [{
  min: 0, max: PM10_GOOD, title: 'Weather Pollution - Good', description: '', color: weatherPollutionGoodColor
}, {
  min: PM10_GOOD,
  max: PM10_MODERATE,
  title: 'Weather Pollution - Moderate',
  description: '',
  color: weatherPollutionModerateColor
}, {
  min: PM10_MODERATE,
  max: PM10_SENSITIVE,
  title: 'Weather Pollution - Sensitive',
  description: '',
  color: weatherPollutionSensitiveColor
}, {
  min: PM10_SENSITIVE,
  max: PM10_UNHEALTHY,
  title: 'Weather Pollution - Unhealthy',
  description: '',
  color: weatherPollutionUnhealthyColor
}, {
  min: PM10_UNHEALTHY,
  max: PM10_VERY_UNHEALTHY,
  title: 'Weather Pollution - Very Unhealthy',
  description: '',
  color: weatherPollutionVeryUnhealthyColor
}, {
  min: PM10_VERY_UNHEALTHY,
  max: PM10_HAZARDOUS,
  title: 'Weather Pollution - Hazardous',
  description: '',
  color: weatherPollutionHazardousColor
}];

const PM25_LEGEND: LegendItem[] = [{
  min: 0, max: PM25_GOOD, title: 'Weather Pollution - Good', description: '', color: weatherPollutionGoodColor
}, {
  min: PM25_GOOD,
  max: PM25_MODERATE,
  title: 'Weather Pollution - Moderate',
  description: '',
  color: weatherPollutionModerateColor
}, {
  min: PM25_MODERATE,
  max: PM25_SENSITIVE,
  title: 'Weather Pollution - Sensitive',
  description: '',
  color: weatherPollutionSensitiveColor
}, {
  min: PM25_SENSITIVE,
  max: PM25_UNHEALTHY,
  title: 'Weather Pollution - Unhealthy',
  description: '',
  color: weatherPollutionUnhealthyColor
}, {
  min: PM25_UNHEALTHY,
  max: PM25_VERY_UNHEALTHY,
  title: 'Weather Pollution - Very Unhealthy',
  description: '',
  color: weatherPollutionVeryUnhealthyColor
}, {
  min: PM25_VERY_UNHEALTHY,
  max: PM25_HAZARDOUS,
  title: 'Weather Pollution - Hazardous',
  description: '',
  color: weatherPollutionHazardousColor
}];

export {
  AQI_LEGEND,
  CO_LEGEND,
  NO2_LEGEND,
  SO2_LEGEND,
  O3_LEGEND,
  PM10_LEGEND,
  PM25_LEGEND,
  AQI_GOOD,
  AQI_MODERATE,
  AQI_SENSITIVE,
  AQI_UNHEALTHY,
  AQI_VERY_UNHEALTHY,
  AQI_HAZARDOUS,
  CO_GOOD,
  CO_MODERATE,
  CO_SENSITIVE,
  CO_UNHEALTHY,
  CO_VERY_UNHEALTHY,
  CO_HAZARDOUS,
  NO2_GOOD,
  NO2_MODERATE,
  NO2_SENSITIVE,
  NO2_UNHEALTHY,
  NO2_VERY_UNHEALTHY,
  NO2_HAZARDOUS,
  SO2_GOOD,
  SO2_MODERATE,
  SO2_SENSITIVE,
  SO2_UNHEALTHY,
  SO2_VERY_UNHEALTHY,
  SO2_HAZARDOUS,
  O3_GOOD,
  O3_MODERATE,
  O3_SENSITIVE,
  O3_UNHEALTHY,
  O3_VERY_UNHEALTHY,
  O3_HAZARDOUS,
  PM10_GOOD,
  PM10_MODERATE,
  PM10_SENSITIVE,
  PM10_UNHEALTHY,
  PM10_VERY_UNHEALTHY,
  PM10_HAZARDOUS,
};

/**
 * Weather pollution levels
 * */
export enum WeatherPollutionLevel {
  Good = 'good',
  Moderate = 'moderate',
  UnhealthyForSensitive = 'sensitive',
  Unhealthy = 'unhealthy',
  VeryUnhealthy = 'very-unhealthy',
  Hazardous = 'hazardous'
}

/**
 * A utility class for weather pollution entities.
 */
export class WeatherPollutionUtil {

  public static getWeatherPollutionLabel(weatherPollutionLevel) {
    switch (weatherPollutionLevel) {
      case WeatherPollutionLevel.Good:
        return 'Weather Pollution - Good';
      case WeatherPollutionLevel.Moderate:
        return 'Weather Pollution - Moderate';
      case WeatherPollutionLevel.UnhealthyForSensitive:
        return 'Weather Pollution - Sensitive';
      case WeatherPollutionLevel.Unhealthy:
        return 'Weather Pollution - Unhealthy';
      case WeatherPollutionLevel.VeryUnhealthy:
        return 'Weather Pollution - Very Unhealthy';
      case WeatherPollutionLevel.Hazardous:
        return 'Weather Pollution - Hazardous';
    }
  }

  public static assessWeatherPollutionAQI(aqi) {
    if (aqi <= AQI_GOOD) {
      return WeatherPollutionLevel.Good;
    } else if (aqi <= AQI_MODERATE) {
      return WeatherPollutionLevel.Moderate;
    } else if (aqi <= AQI_SENSITIVE) {
      return WeatherPollutionLevel.UnhealthyForSensitive;
    } else if (aqi <= AQI_UNHEALTHY) {
      return WeatherPollutionLevel.Unhealthy;
    } else if (aqi <= AQI_VERY_UNHEALTHY) {
      return WeatherPollutionLevel.VeryUnhealthy;
    } else if (aqi <= AQI_HAZARDOUS) {
      return WeatherPollutionLevel.Hazardous;
    }
  }

  public static assessCO(co) {
    if (co <= CO_GOOD) {
      return WeatherPollutionLevel.Good;
    } else if (co <= CO_MODERATE) {
      return WeatherPollutionLevel.Moderate;
    } else if (co <= CO_SENSITIVE) {
      return WeatherPollutionLevel.UnhealthyForSensitive;
    } else if (co <= CO_UNHEALTHY) {
      return WeatherPollutionLevel.Unhealthy;
    } else if (co <= CO_VERY_UNHEALTHY) {
      return WeatherPollutionLevel.VeryUnhealthy;
    } else if (co <= CO_HAZARDOUS) {
      return WeatherPollutionLevel.Hazardous;
    }
  }

  public static assessO3(o3) {
    if (o3 <= O3_GOOD) {
      return WeatherPollutionLevel.Good;
    } else if (o3 <= O3_MODERATE) {
      return WeatherPollutionLevel.Moderate;
    } else if (o3 <= O3_SENSITIVE) {
      return WeatherPollutionLevel.UnhealthyForSensitive;
    } else if (o3 <= O3_UNHEALTHY) {
      return WeatherPollutionLevel.Unhealthy;
    } else if (o3 <= O3_VERY_UNHEALTHY) {
      return WeatherPollutionLevel.VeryUnhealthy;
    } else if (o3 <= O3_HAZARDOUS) {
      return WeatherPollutionLevel.Hazardous;
    }
  }

  public static assessNO2(no2) {
    if (no2 <= NO2_GOOD) {
      return WeatherPollutionLevel.Good;
    } else if (no2 <= NO2_MODERATE) {
      return WeatherPollutionLevel.Moderate;
    } else if (no2 <= NO2_SENSITIVE) {
      return WeatherPollutionLevel.UnhealthyForSensitive;
    } else if (no2 <= NO2_UNHEALTHY) {
      return WeatherPollutionLevel.Unhealthy;
    } else if (no2 <= NO2_VERY_UNHEALTHY) {
      return WeatherPollutionLevel.VeryUnhealthy;
    } else if (no2 <= NO2_HAZARDOUS) {
      return WeatherPollutionLevel.Hazardous;
    }
  }

  public static assessPM10(pm10) {
    if (pm10 <= PM10_GOOD) {
      return WeatherPollutionLevel.Good;
    } else if (pm10 <= PM10_MODERATE) {
      return WeatherPollutionLevel.Moderate;
    } else if (pm10 <= PM10_SENSITIVE) {
      return WeatherPollutionLevel.UnhealthyForSensitive;
    } else if (pm10 <= PM10_UNHEALTHY) {
      return WeatherPollutionLevel.Unhealthy;
    } else if (pm10 <= PM10_VERY_UNHEALTHY) {
      return WeatherPollutionLevel.VeryUnhealthy;
    } else if (pm10 <= PM10_HAZARDOUS) {
      return WeatherPollutionLevel.Hazardous;
    }
  }

  public static assessPM25(pm25) {
    if (pm25 <= PM25_GOOD) {
      return WeatherPollutionLevel.Good;
    } else if (pm25 <= PM25_MODERATE) {
      return WeatherPollutionLevel.Moderate;
    } else if (pm25 <= PM25_SENSITIVE) {
      return WeatherPollutionLevel.UnhealthyForSensitive;
    } else if (pm25 <= PM25_UNHEALTHY) {
      return WeatherPollutionLevel.Unhealthy;
    } else if (pm25 <= PM25_VERY_UNHEALTHY) {
      return WeatherPollutionLevel.VeryUnhealthy;
    } else if (pm25 <= PM25_HAZARDOUS) {
      return WeatherPollutionLevel.Hazardous;
    }
  }

  public static assessSO2(so2) {
    if (so2 <= SO2_GOOD) {
      return WeatherPollutionLevel.Good;
    } else if (so2 <= SO2_MODERATE) {
      return WeatherPollutionLevel.Moderate;
    } else if (so2 <= SO2_SENSITIVE) {
      return WeatherPollutionLevel.UnhealthyForSensitive;
    } else if (so2 <= SO2_UNHEALTHY) {
      return WeatherPollutionLevel.Unhealthy;
    } else if (so2 <= SO2_VERY_UNHEALTHY) {
      return WeatherPollutionLevel.VeryUnhealthy;
    } else if (so2 <= SO2_HAZARDOUS) {
      return WeatherPollutionLevel.Hazardous;
    }
  }
}
