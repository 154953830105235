import {LayerController} from "./layer.controller";
import {NGSIResult} from "../../shared/models/ngsi/ngsi-result";
import {Layer} from "../../shared/models/layer.model";
import {Page} from "../../shared/models/page.model";
import {Injector} from "@angular/core";
import {Marker} from "../../shared/models/map/marker.model";
import {EventService} from "../services/event.service";
import {takeUntil} from "rxjs/operators";
import {EntityRepresentation} from "../../shared/models/visualization/representation.model";

export class UrukRouteLayerController extends LayerController {

  result : NGSIResult[] = null;
  representation : EntityRepresentation = null;
  public visibilityMap: Map<number, boolean>;
  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
    this.visibilityMap = new Map<number, boolean>();

    // subscribe to the event service
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.ENTITY_VISIBILITY_CHANGE:{
            this.changeRouteVisibility(event.data)
            break;
          }
        }
      });

    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.LAYER_VISIBILITY_TOGGLED: {
            if (event.data instanceof UrukRouteLayerController) {
              this.showAllRoute(event.data , false);
            }
            break;
          }
          case EventService.SHOW_LAYERS: {
              this.showAllRoute(event.data, true);
            break;
          }
        }
      });
  }

  /**
   * Collects the values to be provided to the dialog to be opened on entity click
   * @param result
   * @private
   */
  getDialogContext(result: NGSIResult): any {
    return null;
  }
  createTooltip(marker: Marker): string {
    const name = marker.entity.getSingleElementValue('name');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text">${name}</div>`;
    html += '</div';

    return html;
  }
  /**
   * Callback to be called when a notification is received. For "create" notifications a new item is added to the map. For "update" notifications, an existing item on the map
   * is updated based on the update content.
   * @param representation
   * @param notification
   * @private
   */
  /*websocketCallback(representation: EntityRepresentation, notification: Notification): void {
    let entity: NGSIResult = null;
    let marker: Marker = null;
    if (notification.eventClass === 'NGSIEvent') {
      if (notification.eventPayload.eventType === NGSIEventType.CREATE) {
        entity = this.handleCreateEvent(representation, notification);
        this.addResultItem(entity, representation);
        this.createMapObjects([entity], representation);
        this.mergeMappedEntities();
      }else if (notification.eventPayload.eventType === NGSIEventType.UPDATE_TEMPORAL) {
        const entityIndex = this.entities.get(representation).findIndex(e => e.getEntityId() === notification.entityId);
        if (entityIndex !== -1) {
          entity = this.entities.get(representation)[entityIndex];
          marker = this.markers.get(representation)[entityIndex];
          if(notification.eventPayload.content.visibility[0].value !== 0){
            this.entities.get(representation).splice(entityIndex, 1);
            this.markers.get(representation).splice(entityIndex, 1);
          }
          marker.visible = notification.eventPayload.content.visibility[0].value === 1;
        }
      }
      if (entity) {
        this.eventService.broadcastLayerProcessingFinishedEvent(this);
        this.afterEntitiesProcessed();
      }
    }
  }*/

  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    this.result = results;
    const finalResults: NGSIResult[] = [];
    if (results === null) {
      return finalResults;
    }

    results.forEach(result => {
      if(!this.visibilityMap.has(Number(result.getEntityId().split(":")[3]))){
        this.visibilityMap.set(Number(result.getEntityId().split(":")[3]), false);
      }
      if(this.visibilityMap.get(Number(result.getEntityId().split(":")[3]))){
        finalResults.push(result);
      }
    })
    return finalResults;
  }

  private changeRouteVisibility(data: any) {
    let entityId: any = data.entityId;
    let visibility: any = data.visibility;
    if(visibility !== this.visibilityMap.get(entityId)){
      this.visibilityMap.set(entityId, visibility);
      if (!this.visibleOnMap) {
        this.processData(this.result, this.representation);
      }
    }
  }

  private showAllRoute(data: any , visibleMap: boolean) {
    if (visibleMap || data._visibleOnMap) {
      this.result.forEach(result => {
        let entity: NGSIResult[] = [];
        entity.push(result);
        this.createMapObjects(entity, this.representation);
        if (this.initializedLayerCount < this.layer.representations.length) {
          // after all processing make the icons visible
          this.setVisibility(true);

          this.initializedLayerCount++;
        }

        // merge all mapped entities
        this.mergeMappedEntities();

        // post-process the retrieved entities
        // this.afterEntitiesProcessed();

        // broadcast the event
        this.eventService.broadcastLayerProcessingFinishedEvent(this);
      })
    }
  }

  protected afterEntitiesProcessed(): void {
    this.visibleOnMap = false;
  }
}
