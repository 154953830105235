import {BaseMetaModel} from './base/base-meta-model.model';

/**
 * Indicates a pattern that is used for complex event processing (CEP)
 */
export class UrukPattern extends BaseMetaModel {

  /**
   * Title of the pattern
   */
  title: string;

  /**
   * Description of the pattern
   */
  description: string;

  inputs: Object | string;

  pattern: Object | string;

  active: boolean;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.title = data.title;
    this.description = data.description;
    this.inputs = data.inputs;
    this.pattern = data.pattern;
    this.active = !!data.active;
  }

  convertPatternDataToString(): UrukPattern {
    this.inputs = JSON.stringify(this.inputs);
    this.pattern = JSON.stringify(this.pattern);
    return this;
  }

  convertPatternDataToJSON(): UrukPattern {
    if (typeof this.inputs === 'string') {
      this.inputs = JSON.parse(this.inputs);
    }
    if (typeof this.pattern === 'string') {
      this.pattern = JSON.parse(this.pattern);
    }
    return this;
  }
}
