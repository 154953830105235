export class SVGTrainClickHandlers {
  public static getHandlers() {
    return {
      tractionSystem: (id: string) => {
        if (id.includes('cer')) {
          return {path: 'assets/images/cer-unit.svg', tabInfo: 2};
        } else if (id.includes('bogi')) {
          return {path: 'assets/images/motor-bogi-unit.svg', tabInfo: 3};
        } else {
          return {path: 'assets/images/train-traction.svg', tabInfo: 1};
        }
      },

      brakeSystem: (id: string) => {
        if (id.includes('brake1')) {
          return {path: 'assets/images/brake-unit.svg', tabInfo: 2};
        } else if (id.includes('brake2')) {
          return {path: 'assets/images/motor-bogi-unit.svg', tabInfo: 3};
        } else {
          return {path: 'assets/images/train-brake.svg', tabInfo: 1};
        }
      },

      powerVoltageSystem: (id: string) => {
        if (id.includes('transformer')) {
          return {path: 'assets/images/transformer-unit.svg', tabInfo: 2};
        } else if (id.includes('apu')) {
          return {path: 'assets/images/apu-unit.svg', tabInfo: 3};
        } else if (id.includes('battery')) {
          return {path: 'assets/images/battery-unit.svg', tabInfo: 4};
        } else {
          return {path: 'assets/images/train-powervoltage.svg', tabInfo: 1};
        }
      },

      airConditionSystem: (id: string) => {
        if (id.includes('ska1-driver-aircondition')) {
          return {path: 'assets/images/driver-aircondition-ska1-unit.svg', tabInfo: 2};
        } else if (id.includes('ska2-driver-aircondition')) {
          return {path: 'assets/images/driver-aircondition-ska2-unit.svg', tabInfo: 2};
        } else if (id.includes('ska1-passenger-aircondition1')) {
          return {path: 'assets/images/passenger-ska1-aircondition1-unit.svg', tabInfo: 3};
        } else if (id.includes('ska1-passenger-aircondition2')) {
          return {path: 'assets/images/passenger-ska1-aircondition2-unit.svg', tabInfo: 4};
        } else if (id.includes('ska2-passenger-aircondition1')) {
          return {path: 'assets/images/passenger-ska2-aircondition1-unit.svg', tabInfo: 3};
        } else if (id.includes('ska2-passenger-aircondition2')) {
          return {path: 'assets/images/passenger-ska2-aircondition2-unit.svg', tabInfo: 4};
        } else if (id.includes('oa') && id.includes('passenger-aircondition1')) {
          return {path: 'assets/images/passenger-oa-aircondition1-unit.svg', tabInfo: 3};
        } else if (id.includes('oa') && id.includes('passenger-aircondition2')) {
          return {path: 'assets/images/passenger-oa-aircondition2-unit.svg', tabInfo: 4};
        } else {
          return {path: 'assets/images/train-aircondition.svg', tabInfo: 1};
        }
      },

      doorSystem: (id: string) => {
        if (id.includes('inner')) {
          return {path: 'assets/images/inner-door-unit.svg', tabInfo: 1};
        } else if (id.includes('outer')) {
          if (id.includes('door1') || id.includes('door4')) {
            return {path: 'assets/images/outer-door1-unit.svg', tabInfo: 1};
          } else {
            return {path: 'assets/images/outer-door2-unit.svg', tabInfo: 1};
          }
        } else {
          return {path: 'assets/images/train-door.svg', tabInfo: 1};
        }
      },

      communicationSystem: (id: string) => {
        if (id.includes('driver-communication')) {
          return {path: 'assets/images/communication-driver-unit.svg', tabInfo: 1};
        } else if (id.includes('ska')) {
          return {path: 'assets/images/communication-ska-unit.svg', tabInfo: 1};
        } else if (id.includes('oa1') || id.includes('oa2')) {
          return {path: 'assets/images/communication-oa-unit.svg', tabInfo: 1};
        } else if (id.includes('oa3')) {
          return {path: 'assets/images/communication-oa3-unit.svg', tabInfo: 1};
        } else {
          return {path: 'assets/images/train-communication.svg', tabInfo: 1};
        }
      }
    };
  }
}
