import { Component, HostListener, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

/**
 * Dialog to display success messages.
 * */
@Component({
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent {

  // the success message to be displayed
  @Input() message: string;

  constructor(private dialogRef: NbDialogRef<SuccessDialogComponent>) {
  }

  /**
   * Closes the dialog when the user clicks on the screen.
   */
  @HostListener('document:click')
  close() {
    this.dialogRef.close();
  }
}
