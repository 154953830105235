import {DataDimensionKey} from './data-dimension-key.model';

/**
 * A dimension in a KPI result
 */
export class DataDimension {
  /**
   * A map of which entries correspond to the fields of a key generated for the dimension. Value of each entry provides additional details for the field contained in the key
   */
  // we do not use the Map structure as it is not passed to the http calls without explicit serialization
  // keys?: Map<string, DataDimensionKey> = new Map<string, DataDimensionKey>();
  keys: any;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.keys = data.keys;
  }
}
