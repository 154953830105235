import {BaseMetaModel} from './base/base-meta-model.model';

export class HealthCase extends BaseMetaModel {

  healthCaseId: String;
  caseDate: String;
  caseHour: String;
  observedAt: String;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.healthCaseId = data.healthCaseId;
    this.caseDate = data.caseDate;
    this.caseHour = data.caseHour;
    this.observedAt = data.observedAt;
  }
}
