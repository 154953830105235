<div class="date-selection">
  <uruk-date-selection *ngIf="!onlyTimeSelection"
                       [temporalQuery]="temporalQuery"
                       [showReset]="true"
                       [onlyDayTab]="true"
                       [parentComponentId]="parentComponentId"
                       (onTemporalQueryChanged)="handleTimeChangedEvent($event)">
  </uruk-date-selection>
  <ng-container *ngIf="temporalQuery">
    <nb-form-field>
      <input class="date-time-input"
             nbInput status="basic" type="text"
             [ngModel]="temporalQuery.time?.getHours()"
             (ngModelChange)="handleHourChange($event)"
             [placeholder]="'HH' | translate">
    </nb-form-field>
    <nb-form-field>
      <input class="date-time-input"
             nbInput status="basic" type="text"
             [ngModel]="temporalQuery.time?.getMinutes()"
             (ngModelChange)="handleMinuteChange($event)"
             [placeholder]="'MM' | translate">
    </nb-form-field>
  </ng-container>
</div>
