import {Component, Injector, OnInit} from '@angular/core';
import {PanelComponent} from '../../../../shared/components/panel/panel.component';

/**
 * A derived class to represent small videowall panels
 */
@Component({
  selector: 'uruk-videowall-panel',
  templateUrl: './videowall-panel.component.html',
  styleUrls: ['./videowall-panel.component.scss']
})
export class VideowallPanelComponent extends PanelComponent implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
