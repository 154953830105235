import {GeoProperty} from '../schema/geo-property.model';
import {v4 as uuidv4} from 'uuid';

export class Polygon {
  id: string;
  location: GeoProperty;
  fillColor: string;
  opacity: number;

  constructor(location, color, opacity) {
    this.id = uuidv4();
    this.location = location;
    this.fillColor = color;
    this.opacity = opacity;
  }

  toGeoJson() {
    const geoJson = this.location.toGeoJson();
    geoJson.properties = {
      id: this.id,
      fillColor: this.fillColor,
      opacity: this.opacity
    }
    return geoJson;
  }
}
