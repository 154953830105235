import {Injectable} from '@angular/core';

/**
 * Service to keep application-wide alert-related informaiton
 */
@Injectable({
  providedIn: 'root'
})
export class AlertManagementService {

  // keeps identifiers of alerts for which an automatic popup is shown already
  private popupShownForAlerts: string[] = [];

  constructor() {}

  /**
   * Registers an alert indicating that automatic popup is shown for that alert already
   * @param alertId
   */
  public alertShownForPopup(alertId: string): void {
    this.popupShownForAlerts.push(alertId);
  }

  /**
   * Returns whether a popup is shown for an alert already
   * @param alertId
   */
  public isPopupShownForAlert(alertId: string): boolean {
    return this.popupShownForAlerts.includes(alertId);
  }

}
