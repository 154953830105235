import {AfterViewInit, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {TimeUtil} from '../../../../../shared/utils/time-util';
import {MvbCommunication} from '../../../../../shared/models/mvbCommunication.model';
import {CanCommunication} from '../../../../../shared/models/canCommunication.model';
import {NGSIEventType, Notification} from '../../../../../shared/models/notification.model';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {TrainColorRules} from '../../../../../shared/color-rules/train-color-rules';
import {TrainSystemLabels} from '../../../../../shared/components/label/train-system-labels';
import {SVGManagerUtil} from '../../../../../shared/utils/svg-manager-util';
import {SVGTrainRules} from '../../../../../shared/svg-rules/svg-train-rules';
import {SVGTrainClickHandlers} from '../../../../../shared/svg-click/svg-train-click-handlers';
import {TrainDefinitionModel} from '../../../../../shared/models/train-definition/train-definition.model';
import {WagonIndexEnum} from '../../../../../shared/enums/index-map/wagon-index.enum';
import {TrainDataUtil} from './info/train-data.util';

const ASELSAN_TRAIN_SOURCE_URL = 'http://aselsan.com.tr';

@Component({
  selector: 'train-dialog-template',
  templateUrl: './train-dialog-template.component.html',
  styleUrls: ['./train-dialog-template.component.scss']
})
export class TrainDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  // Context variables
  entity: any;
  positionEntity: any;
  date: any;
  trainId: any;
  isAselsanTrain = undefined;
  trainRef: any;
  status: string[] = ['', '', '', '', '', '', '', '', '', '', '', ''];
  errorMap: string[] = ['noError', 'error'];
  errorMap1: string[] = ['error', 'noError'];
  boolMap = new Map<number, string>();
  hasError: boolean = false;
  isError: boolean = false;
  isLiveMode: number = 0;
  firstTrainData: TrainDefinitionModel;
  liveTrainData: TrainDefinitionModel;
  trainInfo: TrainDefinitionModel;
  currentSVGPath: string | null = null;
  previousSVGPath: string | null = null;
  defaultSVGPath: string = '';
  activeTab: string = '';
  tabInfo: number = 1;
  trainStatus: any;
  shouldUnsubscribe: boolean = false;

  // General Data
  fDriveApplied: any[] = [];
  hmiJourneyNo: any;
  location: any;
  speed: any;
  distanceTraveled: any[] = [];
  ascLimit: any;
  externalTemperature: any;
  ApsDcLinkOk: any[] = [];
  lcuMaintenance1: any[] = [];

  // Traction
  motorCooling2mvcbProtection: any;
  tempTrcInv1: any[] = [];
  tempTrcInv2: any[] = [];
  tempTrcRec: any[] = [];
  vTrcDclink: any[] = [];
  tempMotorAx3: any[] = [];
  tempMotorAx4: any[] = [];
  forceAvmaxAx3: any[] = [];
  forceAvmaxAx4: any[] = [];
  velMotorAx3: any[] = [];
  velMotorAx4: any[] = [];
  edBar: any[] = [];
  tractionBar: any;
  tractionCutOffLed: any;
  tractionDcLinkOk: any[] = [];

  // Brake
  bp: any;
  mrp: any;
  emergencyStatusBrakeLed: any[] = [];

  // Power Voltage
  majorUnderVoltageError: any;
  catenaryVoltage: any;
  pantoABar: any;
  pTrain: any;
  voltPantoSka1: any[] = [];
  mediumVoltageBackupEnabled2Vehicles: any;

  // Air Condition
  driverAirConditioningTemperature: any;

  // Communication
  mvbComList: MvbCommunication[] = [];
  camComList: CanCommunication[] = [];

  // Predictive
  predictiveMaintenanceCode: any;
  predictiveMaintenanceInfo: any;

  // Diagnostic and Prognostic Systems
  prognosticSystem: any = false;
  diagnosticSystem: any = false;
  tractionSystem: any = false;
  brakeSystem: any = false;
  powerVoltageSystem: any = false;
  airConditionSystem: any = false;
  doorSystem: any = false;
  otherSystem: any = false;
  communicationSystem: any = false;
  predictiveMaintenance: any = false;
  signalizationSystem: any = false;
  selectedWagonIndex: number = 0;

  // Define SVG file paths
  svgPaths = {
    'tractionSystem': 'assets/images/train-traction.svg',
    'brakeSystem': 'assets/images/train-brake.svg',
    'powerVoltageSystem': 'assets/images/train-powervoltage.svg',
    'airConditionSystem': 'assets/images/train-aircondition.svg',
    'doorSystem': 'assets/images/train-door.svg',
    'otherSystem': 'assets/images/train-other.svg',
    'communicationSystem': 'assets/images/train-communication.svg',
    'predictiveMaintenance': 'assets/images/predictive-maintenance.svg',
    'signalizationSystem': 'assets/images/train-signalization.svg',
  };

  private svgContainer: HTMLElement;
  private backIcon: HTMLElement;
  private svgManager: SVGManagerUtil;
  private colorRules: TrainColorRules;
  systemLabels = new TrainSystemLabels();
  trainDataUtil = new TrainDataUtil();

  constructor(injector: Injector, private translate: TranslateService) {
    super(injector);

    this.trainInfo = new TrainDefinitionModel();
    this.activeTab = 'tractionSystem'; // Default active tab value
    this.defaultSVGPath = 'assets/images/train-traction.svg'; // Default svg path
    this.translate.get(['noError', 'error']).subscribe(translations => {
      // Adding translations to the map
      this.boolMap.set(0, translations['noError']);
      this.boolMap.set(1, translations['error']);
    });

    this.colorRules = new TrainColorRules();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeWebsocket();
  }

  ngAfterViewInit() {
    this.initializeSVGManager();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.shouldUnsubscribe = true;
  }

  public onMainTabChange(event) {
    this.setDefaultTabInfo();
    if (event.tabId === 'diagnosticSystem') {
      this.activeTab = 'tractionSystem';
    } else {
      this.activeTab = 'predictiveMaintenance';
    }

    this.loadAndModifySVG(this.getSvgPath(), true);
  }

  public onTabChange(event) {
    this.setDefaultTabInfo(); // Reset the tab information to default values when a tab change event occurs
    this.activeTab = event.tabId; // Set the activeTab variable to the ID of the selected tab

    this.loadAndModifySVG(this.getSvgPath(), true); // Load the corresponding SVG and modify it based on the selected tab
  }

  private setDefaultTabInfo() {
    // Set default values for all system information related to the tabs
    this.tabInfo = 1;
  }

  private getSvgPath(): string {
    // Return the SVG path corresponding to the active tab, or the default path if none is found
    return this.svgPaths[this.activeTab] || this.defaultSVGPath;
  }

  private initializeSVGManager() {
    // Get the container to load the SVG and the back icon element
    this.svgContainer = document.getElementById('svgContainer');
    this.backIcon = document.querySelector('.back-icon') as HTMLElement;

    this.svgManager = new SVGManagerUtil(this.svgContainer, SVGTrainRules.getColorRules(), SVGTrainClickHandlers.getHandlers(), WagonIndexEnum, true, true);

    if (this.svgManager.resultEmitter) {
      this.svgManager.resultEmitter.subscribe((result) => {
        this.handleSelectedResult(result);
      });
    }
  }

  private handleSelectedResult(selectedResult: any) {
    if (selectedResult) {
      this.tabInfo = selectedResult.tabInfo;
      this.selectedWagonIndex = selectedResult.index;
      this.loadAndModifySVG(selectedResult.path);
    }
  }

  private loadAndModifySVG(svgPath: string, resetFlags: boolean = false) {

    if (this.currentSVGPath !== svgPath) {
      // Store the previous SVG path and load the new one
      this.previousSVGPath = this.currentSVGPath;
      this.currentSVGPath = svgPath;
    }

    if (resetFlags) {
      this.previousSVGPath = null; // Optionally reset the previousSVGPath if resetFlags is true
    }

    this.updateBackIconVisibility(this.backIcon); // Show or hide the back icon
    this.svgManager.loadAndModifySVG(this.currentSVGPath, this.trainInfo, this.activeTab);
  }

  // Show the back icon if there is a previous SVG loaded
  private updateBackIconVisibility(backIcon: HTMLElement) {
    if (this.previousSVGPath) {
      backIcon.style.display = 'block'; // Make the back icon visible
    } else {
      backIcon.style.display = 'none'; // Hide the back icon initially
    }
  }

  getBackIconStyle() {
    const paths = [
      'assets/images/inner-door-unit.svg',
      'assets/images/outer-door1-unit.svg',
      'assets/images/outer-door2-unit.svg'
    ];

    // If currentSVGPath is one of the specified paths, the color will be 'black', otherwise the default style
    return paths.includes(this.currentSVGPath) ? {
      fontsize: '16px',
      color: '#808080',
      fontWeight: 'bold',
      backgroundColor: 'white'
    } : {};
  }

  public goBack() {
    // Go back to the previous SVG if it exists, and reset tab information
    if (this.previousSVGPath) {
      this.setDefaultTabInfo();

      this.currentSVGPath = null;
      this.loadAndModifySVG(this.previousSVGPath);
    }
  }

  protected mapContextData(context: any): void {
    this.positionEntity = context.positionEntity ? context.positionEntity.entity : context.result.posistionEntity.entity;
    this.entity = context.entity ? context.entity.entity : context.result.entity;

    // get source
    this.isAselsanTrain = super.getValue(this.entity, 'source') === ASELSAN_TRAIN_SOURCE_URL;
    // get the values to be displayed on UI
    this.trainRef = super.getValue(this.positionEntity, 'trainRef');
    this.fDriveApplied = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'fDriveApplied'));
    this.voltPantoSka1 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'voltPantoSka1'));
    this.hmiJourneyNo = super.getValue(this.positionEntity, 'hmiJourneyNo');
    this.location = this.formattedLocation(super.getValue(this.positionEntity, 'location').coordinates.join(', '));
    const date = new Date(super.getValue(this.positionEntity, 'hmiJourneyNo', 'modifiedAt'));
    this.date = TimeUtil.serializeCustomDatetime(date);
    this.trainId = this.trainRef.object.substring(this.trainRef.object.lastIndexOf(':') + 1);
    this.speed = super.getValue(this.positionEntity, 'speed');
    this.distanceTraveled = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'distanceTraveled'));
    this.ascLimit = super.getValue(this.positionEntity, 'ascLimit');
    this.tractionBar = super.getValue(this.positionEntity, 'tractionBar');
    this.tractionCutOffLed = this.boolMap.get(super.getValue(this.positionEntity, 'tractionCutOffLed'));
    this.majorUnderVoltageError = this.boolMap.get(super.getValue(this.positionEntity, 'majorUnderVoltageError'));
    this.mediumVoltageBackupEnabled2Vehicles = this.boolMap.get(super.getValue(this.positionEntity, 'mediumVoltageBackupEnabled2Vehicles'));
    this.catenaryVoltage = super.getValue(this.positionEntity, 'catenaryVoltage');
    this.pantoABar = super.getValue(this.positionEntity, 'pantoABar');
    this.pTrain = super.getValue(this.positionEntity, 'pTrain');
    this.bp = super.getValue(this.positionEntity, 'bp');
    this.mrp = super.getValue(this.positionEntity, 'mrp');
    this.driverAirConditioningTemperature = super.getValue(this.positionEntity, 'driverAirConditioningTemperature');
    this.externalTemperature = super.getValue(this.positionEntity, 'externalTemperature');
    this.predictiveMaintenanceCode = super.getValue(this.positionEntity, 'predictiveMaintenanceCode');
    this.motorCooling2mvcbProtection = this.predictiveMaintenanceCode === 8 ? 'error' : 'noError';
    this.edBar = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'edBar'));
    this.emergencyStatusBrakeLed = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'emergencyStatusBrakeLed'));
    this.tempTrcInv1 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempTrcInv1'));
    this.tempTrcInv2 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempTrcInv2'));
    this.tempTrcRec = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempTrcRec'));
    this.tempMotorAx3 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempMotorAx3'));
    this.tempMotorAx4 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tempMotorAx4'));
    this.tractionDcLinkOk = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'tractionDcLinkOk'));
    this.vTrcDclink = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'vTrcDclink'));
    this.ApsDcLinkOk = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'ApsDcLinkOk'));
    this.forceAvmaxAx3 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'forceAvmaxAx3'));
    this.forceAvmaxAx4 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'forceAvmaxAx4'));
    this.velMotorAx3 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'velMotorAx3'));
    this.velMotorAx4 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'velMotorAx4'));
    this.lcuMaintenance1 = Array.from({length: 5}, () => super.getValue(this.positionEntity, 'lcuMaintenance1'));

    this.setFirstTrainInfo();
    this.setData();
    this.setCommunicationList();
  }

  protected setData() {
    this.trainInfo = this.isLiveMode ? this.liveTrainData : this.firstTrainData;

    this.checkStatus();
    this.calculatePredictiveInfo();
    this.setDiagnosticSystem();
    this.loadAndModifySVG(this.currentSVGPath);
  }

  protected assignTrainData(targetTrainData, source = null) {
    targetTrainData.fDriveApplied = source ? source.fDriveApplied.value : this.fDriveApplied;
    targetTrainData.hmiJourneyNo = source ? source.hmiJourneyNo.value : this.hmiJourneyNo;
    targetTrainData.location = source ? this.formattedLocation(source.location.value.coordinates.join(', ')) : this.formattedLocation(this.location);
    targetTrainData.speed = source ? source.speed.value : this.speed;
    targetTrainData.distanceTraveled = source ? source.distanceTraveled.value : this.distanceTraveled;
    targetTrainData.ascLimit = source ? source.ascLimit.value : this.ascLimit;
    targetTrainData.externalTemperature = source ? source.externalTemperature.value : this.externalTemperature;
    targetTrainData.predictiveMaintenanceCode = source ? source.predictiveMaintenanceCode.value : this.predictiveMaintenanceCode;
    targetTrainData.ApsDcLinkOk = this.ApsDcLinkOk;
    targetTrainData.lcuMaintenance1 = this.lcuMaintenance1;

    // traction
    targetTrainData.tractionModel.tempTrcInv1 = this.tempTrcInv1;
    targetTrainData.tractionModel.tempTrcInv2 = this.tempTrcInv2;
    targetTrainData.tractionModel.tempTrcRec = this.tempTrcRec;
    targetTrainData.tractionModel.vTrcDclink = this.vTrcDclink;
    targetTrainData.tractionModel.tempMotorAx3 = this.tempMotorAx3;
    targetTrainData.tractionModel.tempMotorAx4 = this.tempMotorAx4;
    targetTrainData.tractionModel.forceAvmaxAx3 = this.forceAvmaxAx3;
    targetTrainData.tractionModel.forceAvmaxAx4 = this.forceAvmaxAx4;
    targetTrainData.tractionModel.velMotorAx3 = this.velMotorAx3;
    targetTrainData.tractionModel.velMotorAx4 = this.velMotorAx4;
    targetTrainData.tractionModel.edBar = this.edBar;
    targetTrainData.tractionModel.tractionBar = source ? source.tractionBar.value : this.tractionBar;
    targetTrainData.tractionModel.tractionCutOffLed = source ? this.boolMap.get(source.tractionCutOffLed.value) : this.tractionCutOffLed;
    targetTrainData.tractionModel.tractionDcLinkOk = this.tractionDcLinkOk;
    targetTrainData.tractionModel.motorCooling2mvcbProtection = source && source.predictiveMaintenanceCode.value === 8 ? 'error' : 'noError';

    // brake
    targetTrainData.brakeModel.bp = source ? source.bp.value : this.bp;
    targetTrainData.brakeModel.mrp = source ? source.mrp.value : this.mrp;
    targetTrainData.brakeModel.emergencyStatusBrakeLed = source ? this.boolMap.get(source.emergencyStatusBrakeLed.value) : this.emergencyStatusBrakeLed;

    // power voltage
    targetTrainData.powerVoltageModel.majorUnderVoltageError = source ? this.boolMap.get(source.majorUnderVoltageError.value) : this.majorUnderVoltageError;
    targetTrainData.powerVoltageModel.catenaryVoltage = source ? source.catenaryVoltage.value : this.catenaryVoltage;
    targetTrainData.powerVoltageModel.pantoABar = source ? source.pantoABar.value : this.pantoABar;
    targetTrainData.powerVoltageModel.pTrain = source ? source.pTrain.value : this.pTrain;
    targetTrainData.powerVoltageModel.voltPantoSka1 = source ? source.voltPantoSka1.value : this.voltPantoSka1;
    targetTrainData.powerVoltageModel.mediumVoltageBackupEnabled2Vehicles = source ? this.boolMap.get(source.mediumVoltageBackupEnabled2Vehicles.value) : this.mediumVoltageBackupEnabled2Vehicles;

    // air condition
    targetTrainData.airConditionModel.driverAirConditioningTemperature = source ? source.driverAirConditioningTemperature.value : this.driverAirConditioningTemperature;
  }

  protected setFirstTrainInfo() {
    this.firstTrainData = this.trainDataUtil.generateRandomTrainData(this.isError);
    this.assignTrainData(this.firstTrainData);
    this.liveTrainData = this.firstTrainData;
  }

  subscribeWebsocket(): void {
    this.socketService.subscribe('20d7a274-337e-46e3-b85a-8bac9d157106')
      .pipe(
        filter(() => !this.shouldUnsubscribe),
      )
      .subscribe(notification => {
        this.websocketCallback(notification);
      });
  }

  protected websocketCallback(notification: Notification): void {
    if (notification.eventClass === 'NGSIEvent') {
      if (notification.entityId.includes('TrainPosition')) {
        if (notification.eventPayload.eventType === NGSIEventType.CREATE &&
          this.trainRef &&
          this.trainRef.object &&
          this.trainRef.object === notification.eventPayload.content.trainRef.object) {

          if (this.isLiveMode) {
            this.setLiveTrainInfo(notification.eventPayload.content);
          }
        }
      }
    }
  }

  protected setLiveTrainInfo(content) {
    this.liveTrainData = this.trainDataUtil.generateRandomTrainData(this.isError);
    this.assignTrainData(this.liveTrainData, content);

    this.liveTrainData.tractionModel.edBar[0] = content.edBar.value;
    this.liveTrainData.tractionModel.tempTrcInv1[0] = content.tempTrcInv1.value;
    this.liveTrainData.tractionModel.tempTrcInv2[0] = content.tempTrcInv2.value;
    this.liveTrainData.tractionModel.tempTrcRec[0] = content.tempTrcRec.value;
    this.liveTrainData.powerVoltageModel.tempApsInv[0] = content.tempApsInv.value;
    this.liveTrainData.tractionModel.tempMotorAx3[0] = content.tempMotorAx3.value;
    this.liveTrainData.tractionModel.tempMotorAx4[0] = content.tempMotorAx4.value;
    this.liveTrainData.powerVoltageModel.tractionTransformerTemp[0] = content.tractionTransformerTemp.value;
    this.liveTrainData.tractionModel.vTrcDclink[0] = content.vTrcDclink.value;
    this.liveTrainData.powerVoltageModel.vApsDclink[0] = content.vApsDclink.value;
    this.liveTrainData.tractionModel.forceAvmaxAx3[0] = content.forceAvmaxAx3.value;
    this.liveTrainData.tractionModel.forceAvmaxAx4[0] = content.forceAvmaxAx4.value;
    this.liveTrainData.tractionModel.velMotorAx3[0] = content.velMotorAx3.value;
    this.liveTrainData.tractionModel.velMotorAx4[0] = content.velMotorAx4.value;
    this.liveTrainData.lcuMaintenance1[0] = content.lcuMaintenance1.value;
    this.liveTrainData.ApsDcLinkOk[0] = content.ApsDcLinkOk.value;
    this.liveTrainData.tractionModel.tractionDcLinkOk[0] = content.tractionDcLinkOk.value;

    this.setData();
  }

  public refreshData() {
    if (!this.isLiveMode) {
      this.isLiveMode = 1;
      this.setData();
    }
  }

  public getFirstData() {
    if (this.isLiveMode) {
      this.isLiveMode = 0;
      this.setData();
    }
  }

  checkStatus() {
    if (this.trainInfo.predictiveMaintenanceCode > 0) {
      this.trainStatus = 'error';
      this.isError = true;
    } else {
      this.trainStatus = 'avaliable';
      this.isError = false;
    }

    if (this.trainInfo.ascLimit > 177) {
      this.status[0] = '-red';
      this.hasError = true;
    } else {
      this.status[0] = '';
    }

    if (this.trainInfo.powerVoltageModel.pantoABar > 50) {
      this.status[1] = '-red';
      this.hasError = true;
    } else {
      this.status[1] = '';
    }

    if (this.trainInfo.brakeModel.bp > 6) {
      this.status[2] = '-red';
      this.hasError = true;
    } else {
      this.status[2] = '';
    }

    if (this.trainInfo.brakeModel.mrp < 7) {
      this.status[3] = '-red';
      this.hasError = true;
    } else {
      this.status[3] = '';
    }
  }

  private calculatePredictiveInfo() {
    if (this.trainInfo.predictiveMaintenanceCode === 3 || this.trainInfo.predictiveMaintenanceCode === 5 ||
      this.trainInfo.predictiveMaintenanceCode === 7 || this.trainInfo.predictiveMaintenanceCode === 8 ||
      this.trainInfo.predictiveMaintenanceCode === 11 || this.trainInfo.predictiveMaintenanceCode === 12 || this.trainInfo.predictiveMaintenanceCode === 13) {
      this.predictiveMaintenanceInfo = 'predictiveMaintenance-' + this.trainInfo.predictiveMaintenanceCode;
      this.status[4] = '-red';
    } else {
      this.predictiveMaintenanceInfo = 'predictiveMaintenance-' + 0;
      this.status[4] = '';
    }

    if (this.trainInfo.predictiveMaintenanceCode === 1) {
      this.status[5] = '-red';
    } else {
      this.status[5] = '';
    }
    if (this.trainInfo.predictiveMaintenanceCode === 2 || this.trainInfo.predictiveMaintenanceCode === 3) {
      this.status[6] = '-red';
    } else {
      this.status[6] = '';
    }
    if (this.trainInfo.predictiveMaintenanceCode === 4) {
      this.status[7] = '-red';
    } else {
      this.status[7] = '';
    }
    if (this.trainInfo.predictiveMaintenanceCode === 5) {
      this.status[8] = '-red';
    } else {
      this.status[8] = '';
    }
    if (this.trainInfo.predictiveMaintenanceCode === 6) {
      this.status[9] = '-red';
    } else {
      this.status[9] = '';
    }
    if (this.trainInfo.predictiveMaintenanceCode === 7) {
      this.status[10] = '-red';
    } else {
      this.status[10] = '';
    }
    if (this.trainInfo.predictiveMaintenanceCode === 8) {
      this.status[11] = '-red';
    } else {
      this.status[11] = '';
    }

    this.controlTractionSystemStatus();
    this.controlBrakeSystemStatus();
    this.controlPowerVoltageSystemStatus();
    this.controlAirConditionSystemStatus();
    this.controlDoorSystemStatus();
    this.controlOthersSystemStatus();
    this.controlSignalizationSystemStatus();
  }

  private setCommunicationList() {
    this.mvbComList.push(new MvbCommunication({
      ska1: 'bcu',
      oa1: 'bcu',
      oa2: 'bcu',
      oa3: 'bcu',
      ska2: 'bcu'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom1Ec',
      oa1: 'riom1Ec',
      oa2: 'riom1Ec',
      oa3: 'riom1Ec',
      ska2: 'riom1Ec',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'tcu',
      oa1: 'tcu',
      oa2: 'tcu',
      oa3: 'tcu',
      ska2: 'tcu'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom2Ec',
      oa1: 'riom2Ec',
      oa2: 'riom2Ec',
      oa3: 'riom2Ec',
      ska2: 'riom2Ec',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'wtbmvb(0521)',
      oa1: '',
      oa2: '',
      oa3: 'eventRecorder',
      ska2: 'wtbmvb(0521)',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'ccu',
      oa1: '',
      oa2: '',
      oa3: 'rdu',
      ska2: 'ccu'
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom1Cd',
      oa1: '',
      oa2: '',
      oa3: '',
      ska2: 'riom1Cd',
    }));
    this.mvbComList.push(new MvbCommunication({
      ska1: 'riom2Cd',
      oa1: '',
      oa2: '',
      oa3: '',
      ska2: 'riom2Cd',
    }));


    this.camComList.push(new CanCommunication({
      riom1: 'hvacCab',
      riom2: 'papis',
      riom3: 'energyMeter',
      riom4: 'internalDoor',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'cctv',
      riom3: 'hvac2',
      riom4: 'hvac1',
    }));

    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'fdu',
      riom3: 'door1',
      riom4: 'door2',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'door1',
      riom3: 'wc',
      riom4: 'innerDoor2',
    }));
    this.camComList.push(new CanCommunication({
      riom1: '',
      riom2: 'innerDoor1',
      riom3: 'door2',
      riom4: '',
    }));
  }

  getDynamicColor(rules: any, context: string, value: number): string {
    const rule = this.colorRules.getRule(rules)[context];

    if (!rule) {
      console.error(`Context "${context}" not found in rules.`);
      return '#FFFFFF';
    }

    if (typeof rule === 'function') {
      return rule(value);
    }

    return rule[value] !== undefined ? rule[value] : rule.default || '#FFFFFF';
  }

  hasBrakeError() {
    return this.trainInfo.brakeModel.majorBrPark === 1
      || this.trainInfo.brakeModel.pressureGovernorPBReleased === 0
      || this.trainInfo.brakeModel.pressureGovernorDetectionEmergencyBrake === 0
      || this.trainInfo.brakeModel.pressureGovernorIndirectBrakeControl === 0
      || this.trainInfo.brakeModel.diagBrDirect === 1
      || this.trainInfo.brakeModel.majorBrWsp.includes(1)
      || this.trainInfo.brakeModel.ebcu.includes(0)
      || this.trainInfo.brakeModel.diagBrCPress.includes(1);
  }

  isVehicleMove(value: number): string {
    switch (value) {
      case 0:
        return 'vehicleCannotMove';
      case 1:
        return 'vehicleCanMove';
      default:
        return 'vehicleCannotMove';
    }
  }

  isBrakeError(context: string, value: number): string {
    const contextMapping = {
      pressureGovernorPBReleased: {
        0: 'vehicleCannotMove',
        default: 'vehicleCanMove'
      },
      pressureGovernorDetectionEmergencyBrake: {
        0: 'emergencyBrakeDetected',
        default: 'vehicleCanMove'
      },
      pressureGovernorIndirectBrakeControl: {
        0: 'indirectBrakeDetected',
        default: 'vehicleCanMove'
      },
      diagBrDirect: {
        0: 'vehicleCanMove',
        1: 'directBrakeDetected'
      }
    };

    const contextConfig = contextMapping[context];
    if (contextConfig) {
      return contextConfig[value] || contextConfig.default;
    }

    return 'vehicleCannotMove';
  }

  getCutOffByPassStatus(value: number): string {
    return value === 0 ? 'active' : 'passive';
  }

  getVacuumStatus(value: number): string {
    return value === 0 ? 'active' : 'passive';
  }

  getPantographStatus(value: number): string {
    return value === 0 ? 'pantographDown' : 'pantographUp';
  }

  getDisconnectorStatus(value: number): string {
    return value === 0 ? 'passive' : 'active';
  }

  getAirConditionMod(mod: number): string {
    switch (mod) {
      case 1:
        return 'manualMod';
      case 2:
        return 'automaticMod';
      default:
        return 'inActiveMod';
    }
  }

  getDoorBypassStatus(value: number): string {
    return value === 0 ? 'bypassModeInactive' : 'bypassModeActive';
  }

  getInteriorLightingMode(mode: number): string {
    switch (mode) {
      case 0:
        return 'lightingOff'; // Off
      case 1:
        return 'emergencyLighting'; // Emergency Lighting
      case 2:
        return 'halfLighting'; // Half Lighting
      case 3:
        return 'fullLighting'; // Full Lighting
      default:
        return 'lightingOff';  // Off
    }
  }

  public setDiagnosticSystem() {
    if (this.tractionSystem || this.brakeSystem || this.powerVoltageSystem || this.otherSystem ||
      this.signalizationSystem || this.airConditionSystem || this.doorSystem) {
      this.diagnosticSystem = 1;
    } else {
      this.diagnosticSystem = 0;
    }
  }

  public controlTractionSystemStatus() {
    this.tractionSystem = this.trainInfo.predictiveMaintenanceCode === 1 || this.trainInfo.predictiveMaintenanceCode === 2 ||
      this.trainInfo.predictiveMaintenanceCode === 3 || this.trainInfo.predictiveMaintenanceCode === 4 ||
      this.trainInfo.predictiveMaintenanceCode === 5 || this.trainInfo.predictiveMaintenanceCode === 6 ||
      this.trainInfo.predictiveMaintenanceCode === 7 || this.trainInfo.predictiveMaintenanceCode === 8;
  }

  public controlBrakeSystemStatus() {
    this.brakeSystem = this.status[2] === '-red ' || this.status[3] === '-red' || this.hasBrakeError();
  }

  public controlPowerVoltageSystemStatus() {
    this.powerVoltageSystem = this.trainInfo.powerVoltageModel.yuksekAkimKaynakliVakumHatasi === 1 || this.trainInfo.powerVoltageModel.hvConfigError === 1
      || this.trainInfo.powerVoltageModel.tumPanolarIzole === 1 || this.trainInfo.powerVoltageModel.pantografDustu === 1
      || this.trainInfo.powerVoltageModel.diagPantoComp === 1 || this.trainInfo.powerVoltageModel.majorMvError === 1
      || this.trainInfo.powerVoltageModel.tractionTransformerOverPressure.includes(1) || this.trainInfo.powerVoltageModel.measuringBatteryVoltage.some(value => value < 80)
      || this.trainInfo.powerVoltageModel.tractionTransformerTemp.some(value => value > 110) || this.trainInfo.powerVoltageModel.tempBattery.some(value => value > 40) || this.trainInfo.powerVoltageModel.tempBattery.some(value => value < -10)
      || this.trainInfo.powerVoltageModel.tempApsRec.includes(1) || this.trainInfo.powerVoltageModel.tempApsInv.includes(1) || this.trainInfo.powerVoltageModel.vApsDclink.some(value => value > 800)
      || this.trainInfo.powerVoltageModel.tractionTransformerOilFlow.includes(1) || this.trainInfo.powerVoltageModel.tractionTransformerOilLevel.includes(1);
  }

  public controlAirConditionSystemStatus() {
    this.airConditionSystem = this.trainInfo.airConditionModel.driverMajorHvac.includes(1) || this.trainInfo.airConditionModel.passengerMajorHvac1.includes(1) || this.trainInfo.airConditionModel.passengerMajorHvac2.includes(1);
  }

  public controlDoorSystemStatus() {
    this.doorSystem = this.trainInfo.doorModel.ickapi1SeviyeAriza === 1 || this.trainInfo.doorModel.kapi1SeviyeAriza === 1 || this.trainInfo.doorModel.prmAsansorKapi1Devrede === 1;
  }

  public controlOthersSystemStatus() {
    this.otherSystem = this.trainInfo.otherModel.toiletIsNotActive === 1 || this.trainInfo.otherModel.toiletWaterLevelLow === 1 || this.trainInfo.otherModel.toiletBowlFull === 1
      || this.trainInfo.otherModel.toiletWastewaterTankFull === 1 || this.trainInfo.otherModel.toiletSosSignal === 1;
  }

  public controlSignalizationSystemStatus() {
    this.signalizationSystem = this.trainInfo.signalizationModel.etcsIsolationByPass === 1 || this.trainInfo.signalizationModel.ertmsCutOff === 0 || this.trainInfo.signalizationModel.atsByPass === 1
      || this.trainInfo.signalizationModel.ertmsServiceBrake === 1;
  }

  public formattedLocation(location) {
    const [lat, lon] = location.split(', ');
    const formattedLat = parseFloat(lat).toFixed(3);
    const formattedLon = parseFloat(lon).toFixed(3);
    return `${formattedLat}, ${formattedLon}`;
  }
}
