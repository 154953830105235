import {Component, Injector, OnInit} from '@angular/core';
import {Panel} from '../../../../../shared/models/visualization/panel.model';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {NGSIPath} from '../../../../../shared/models/ngsi/ngsi-path.model';
import {EntityResult} from '../../../../../shared/models/schema/entity-result';
import {NGSIResult} from '../../../../../shared/models/ngsi/ngsi-result';
import {DataFormatUtil} from '../../../../../shared/utils/data-format-util';
import {ChartDataFormat} from '../../../../../shared/enums/chart-data-format';
import {BaseDataType} from '../../../../../shared/enums/base-data-type';
import {ObjectUtil} from 'app/shared/utils/object-util';

/**
 * Keeps the configurable fields of entity dialog template. These settings will be read from the page popup settings to
 * fill the relevant information in Entity Dialog.
 * */
export enum EntityDialogTemplateFields {
  TITLE = 'title',
  TOP_LABELS = 'topLabels',
  FOOTER = 'footer',
  TOP_ROW = 'top-row',
  SECOND_ROW = 'second-row',
  THIRD_ROW = 'third-row'
}

@Component({
  selector: 'entity-dialog-template',
  templateUrl: './entity-dialog-template.component.html',
  styleUrls: ['./entity-dialog-template.component.scss']
})
export class EntityDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit {

  // Context variables
  title: string;
  topLabels: string[] = [];
  topValues: string[] = [];
  footer: string;
  entity: EntityResult;

  // flag indicating that whether the values to be shown in the top row are provided directly (via the topValues field)
  // or they are configured as panels in the page definition
  showPanels = false;

  /**
   * Panels
   */
  topRowPanels: Panel[] = [];
  secondRowPanel: Panel;
  thirdRowPanel: Panel;

  constructor(injector: Injector) {
    super(injector);
    this.checkMissingValues();
  }

  ngOnInit() {
    super.ngOnInit();
    this.page.panels.forEach(panel => {
      panel.ngsiContext.idQuery = this.entity.id;
    });
  }

  deletePanel(panel: Panel): void {
  }

  mapPanels(): void {
    this.page.panels.forEach(panel => {
      switch (panel.panelLocation) {
        case EntityDialogTemplateFields.SECOND_ROW:
          this.secondRowPanel = panel;
          break;
        case EntityDialogTemplateFields.THIRD_ROW:
          this.thirdRowPanel = panel;
          break;
        case EntityDialogTemplateFields.TOP_ROW:
          this.topRowPanels.push(panel);
          break;
      }
    });
  }

  protected mapContextData(context: NGSIResult): void {
    const entity: EntityResult = context.result as EntityResult;
    const popupSettings: any = this.page.popupSettings;
    // set the context data according to the popup settings
    if (popupSettings[EntityDialogTemplateFields.TOP_LABELS]) {
      this.topLabels = [];
      this.topValues = [];

      this.structureDefinitionService.getEntityElements(entity.type)
        .subscribe(elements => {
          const topLabels: any[] = popupSettings[EntityDialogTemplateFields.TOP_LABELS];
          for (const topLabel of topLabels) {
            // top label
            this.topLabels.push(topLabel.label);
            // retrieve the value for label
            const ngsiPath = new NGSIPath(topLabel.path);
            const columnName = ngsiPath.columnName;
            // find the default unit of element
            const defaultUnit = elements.find(element => element.path === columnName).unit;
            const property = context.extractValueByNgsiPath(ngsiPath, false);
            if (property) {
              const propertyValue = ObjectUtil.isObject(property) ? property.value : property;
              let value = Array.isArray(propertyValue) ? propertyValue.join(',') : propertyValue;

              // format numeric values
              if (topLabel.path.targetType === BaseDataType.NUMBER) {
                if (defaultUnit === '%') {
                  value = DataFormatUtil.formatValue(value, ChartDataFormat.PERCENTAGE, topLabel.precision);
                } else {
                  value = DataFormatUtil.formatValue(value, ChartDataFormat.DECIMAL, topLabel.precision);
                }
              }
              // concatenate the value with unit
              if (property.unitCode) {
                this.topValues.push(`${value} ${this.translateService.instant(`unit.${property.unitCode}`)}`);
              } else if (defaultUnit) {
                this.topValues.push(`${value} ${this.translateService.instant(`unit.${defaultUnit}`)}`);
              } else {
                this.topValues.push(value);
              }
            } else {
              this.topValues.push('-');
            }
          }
        });
    }
    if (popupSettings[EntityDialogTemplateFields.TITLE]) {
      this.title = context.extractValueByNgsiPath(new NGSIPath(popupSettings[EntityDialogTemplateFields.TITLE]), false).value;
    }
    if (popupSettings[EntityDialogTemplateFields.FOOTER]) {
      const footer = context.extractValueByNgsiPath(new NGSIPath(popupSettings[EntityDialogTemplateFields.FOOTER]), false);
      if (footer) {
        this.footer = footer;
      }
    }
  }

  /**
   * Puts a dash (-) for null or undefined values
   * @private
   */
  private checkMissingValues(): void {
    this.topValues = this.topValues.map(value => {
      if (value) {
        return value;
      } else {
        return '-';
      }
    });
  }
}
