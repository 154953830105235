<closeable-dialog class="onstreetparking-dialog-container">
  <div class="dialog-header" dialog-card-header> <label>{{ 'Parking Details' | translate }} </label>
    <div class="badge" [ngClass]="styleClass"> {{ statusLabel | translate }}</div>
  </div>

  <div dialog-card-body class="dialog-body full-height">
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab tabTitle="{{ 'Summary' | translate }}" [active]="activeTab === 'summary'" [tabId]="'summary'">
        <div class="summary">
          <div class="row">
            <div class="col-5 no-pad">
              <div class="row column-title">
                {{ 'General Information' | translate }}:
              </div>
              <div class="row info-row">
                <label class="column-info title"> {{ 'Name' | translate }}: </label>
                <label class="column-info content"> {{name}} </label>
              </div>
              <div class="row info-row">
                <label class="column-info title"> {{ 'Parking Type' | translate }}: </label>
                <label class="column-info content"> {{'On-street parking' | translate}} </label> <!-- TODO: get appropriate parking type from the data -->
              </div>
              <div class="row info-row">
                <label class="column-info title"> {{ 'Capacity' | translate }}: </label>
                <label class="column-info content"> {{totalSpotNumber}} </label>
              </div>
              <div class="row info-row">
                <label class="column-info title"> {{ 'Available Spots' | translate }}: </label>
                <label class="column-info content"> {{availableSpotNumber}} </label>
              </div>
              <div class="row info-row">
                <label class="column-info title"> {{ 'Occupancy' | translate }}: </label>
                <label class="column-info content"> {{occupancy}}% </label>
              </div>
              <div class="row info-row">
                <label class="column-info title"> {{ 'Payment Type' | translate }}: </label>
                <label class="column-info content"> {{ paymentMethods }} </label>
              </div>
              <div class="row info-row">
                <label class="column-info title"> {{ 'Working Hours' | translate }}: </label>
                <label class="column-info content" *ngIf="workingHours"> {{workingHours.opens + ' - ' + workingHours.closes}} </label>
              </div>
            </div>

            <div class="col-1"></div>
            <div class="col-6">
              <div class="row column-title" *ngIf="description">
                {{ 'Descriptions' | translate}}:
              </div>
              <div class="row info-row description-box" *ngIf="description">
                <div class="description-content">
                  {{ description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ 'Charts' | translate }}" [active]="activeTab === 'charts'" [tabId]="'charts'">
        <div class="charts">
          <div class="charts-child">
            <label>{{leftPanel.title}}</label>
            <uruk-panel [panel]="leftPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </div>

          <div class="charts-child">
            <label>{{rightPanel.title}}</label>
            <uruk-panel [panel]="rightPanel"[displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>

  <div *ngIf="address" dialog-card-footer class="dialog-footer">
    <strong>{{address.addressLocality.toUpperCase()}}</strong> | ({{address.addressRegion}} / {{address.addressLocality}}) - {{ date }}
  </div>

</closeable-dialog>
