import {Meta} from './meta.model';
import {BaseModel} from './base-model.model';

/**
 * Base class for entities that are built on the NGSI Entity concept.
 */
export class BaseMetaModel extends BaseModel {
  id: string;
  meta: Meta;

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.id = data.id;
    this.meta = new Meta(data.meta);
  }
}
