import {Visualization} from './visualization.model';
import {VisualizationType} from '../../enums/viualization-type.enum';
import {ClusterSetting} from './cluster-setting.model';

export class IconVisualization extends Visualization {

  /**
   * Type of the icon. Icon and html fields are mutually exclusive. Either a predefined icon or a HTML-based divicon is displayed as a icon.
   */
  icon?: string;

  /**
   * HTML snippet to be used for divicons
   */
  html?: string;

  /**
   * Custom CSS classname to be associated with the icon
   */
  className?: string;

  /**
   * Size of the icon
   */
  sizeFactor: number;

  /**
   * Animation class of the icon
   */
  animation?: string;

  /**
   * Position of the icon relative to the position of the actual entity
   */
  position?: string;

  /**
   * Cluster settings for icon representation. If it is provided, icons are clustered on the map.
   */
  clusterSettings?: ClusterSetting[];

  constructor(data?) {
    super(data);

    this.jsonClass = VisualizationType.ICON;
    if (!data) {
      return;
    }

    this.icon = data.icon;
    this.html = data.html;
    this.className = data.className;
    this.sizeFactor = data.sizeFactor;
    this.animation = data.animation;
    this.position = data.position;
    if (data.clusterSettings) {
      this.clusterSettings = data.clusterSettings.map(setting => new ClusterSetting(setting));
    }
  }
}
