<closeable-dialog class="jkgysTak-dialog-container" [displayFooter]="showFooter">
  <div class="dialog-header title" dialog-card-header><label>{{ 'jemusTakInformation' | translate }} </label> </div>

  <div dialog-card-body class="dialog-body full-height">
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab tabTitle="{{'jemusTaks' | translate}}" [active]="activeTab === 'jemusTak'" [tabId]="'jemusTak'">

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'description' | translate}} : </label>
            <label class="column-info content">{{ ('description' | translate) }}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'takId' | translate}} :</label>
            <label class="column-info content">{{id}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'direction' | translate}} : </label>
            <label class="column-info content">{{direction}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'city-district' | translate}} :</label>
            <label class="column-info content">{{city}}</label>
          </div>
        </div>

      </nb-tab>
      <nb-tab tabTitle="{{'atu' | translate}}" [active]="activeTab === 'atu'" [tabId]="'atu'">
        <table id="atu-table" *ngIf="atus.length > 0">
          <thead>
          <tr [ngClass]="'header'">
            <th>{{'description' | translate}}</th>
            <th>{{'direction' | translate}}</th>
            <th>{{'connectionStatus' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let atu of paginatedAtus">
            <td>{{ atu.description }}</td>
            <td>{{ atu.direction }}</td>
            <td [ngClass]= atu.colorConnectionStatus>{{ atu.connectionStatus }}</td>
            <div class="action-button">
              <td><i [style.color]="!atu.status ? 'red' : ''" class="fa fa-eye" (click)="openBitResultsDialog(atu.bitResults, 'Atu', atu.id, atu.description)"></i></td>
            </div>
          </tr>
          </tbody>
        </table>

        <div class="d-flex ">
          <div class="flex-50" *ngIf="atus.length === 0">
            <label class="column-info content">{{'atuNotFindInfo' | translate}}</label>
          </div>
        </div>

      </nb-tab>
      <nb-tab tabTitle="{{'geko' | translate}}" [active]="activeTab === 'geko'" [tabId]="'geko'" >
        <table id="geko-table" *ngIf="gekos.length > 0">
          <thead>
          <tr [ngClass]="'header'">
            <th>{{'description' | translate}}</th>
            <th>{{'direction' | translate}}</th>
            <th>{{'connectionStatus' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let geko of paginatedGekos">
            <td>{{ geko.description }}</td>
            <td>{{ geko.direction }}</td>
            <td [ngClass]= geko.colorConnectionStatus>{{ geko.connectionStatus }}</td>
            <div class="action-button">
              <td><i [style.color]="!geko.status ? 'red' : ''"  class="fa fa-eye" (click)="openBitResultsDialog(geko.bitResults, 'Geko', geko.id, geko.description)"></i></td>
            </div>
          </tr>
          </tbody>
        </table>

        <div class="d-flex ">
          <div class="flex-50" *ngIf="gekos.length === 0">
            <label class="column-info content">{{'gekoNotFindInfo' | translate}}</label>
          </div>
        </div>

      </nb-tab>
    </nb-tabset>
  </div>
  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info title">{{'coordinate' | translate}}: </label>
      <label class="column-info content">{{jemusTakLocation}} </label>
    </div>
  </div>
</closeable-dialog>


