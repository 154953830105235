import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import * as echarts from 'echarts';
import {BaseComponent} from '../base.component';
import {ObjectUtil} from '../../utils/object-util';
import {ChartSettings} from '../../models/visualization/chart-settings.model';
import {NGSIResult} from '../../models/ngsi/ngsi-result';
import {KPI} from '../../models/kpi.model';
import {DataExtractionUtil} from '../../utils/data-extraction-util';
import {evaluateOperatorForSingleValue} from '../../../core/expression/expression-evaluator';
import {DataFormatUtil} from '../../utils/data-format-util';
import {VisualizationMode} from '../../enums/visualization-mode.enum';
import {LayoutService} from '../../../core/services/layout.service';
import {DimensionSeriesData} from '../../models/report/dimension-series-data.model';

/**
 * Base chart component to handle interactive charts based on echarts library
 */
@Component({
  template: ''
})
export abstract class ChartComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  // Chart dimension/series data before processed to be displayed in echarts
  @Input() rawData: DimensionSeriesData[];

  // The processed data to be represented by the chart component
  @Input() data: any;

  // The name of the series to be displayed
  @Input() seriesName: string;

  // The color palette for the chart elements
  @Input() colors: string[];

  // The KPI which will be visualized by this chart component
  @Input() kpi: KPI;

  // The title of the panel containing the chart, used for the file names when exporting chart data
  @Input() panelTitle: string;

  // The settings that configure the panel layout
  @Input() chartSettings: ChartSettings;

  // Indicates whether to show legend or not
  @Input() showLegend: boolean;

  // Visualization mode for the chart, depends on the where the parent panel is displayed
  @Input() visualizationMode: VisualizationMode;

  // When charts are displayed within a Dialog, it is impossible to determine the size of the chart container.
  // So the component must give a viewportMin value so that the chart is displayed smoothly
  @Input() dialogViewportMin: number;

  // Chart options which will be passed to eChart instance
  @Input() chartOptions: any;

  // Reference to the echarts DOM element
  @ViewChild('echartsContainer') echartsContainer: ElementRef;

  // echarts parameters
  echartsInstance: any; // Reference to the echarts instance
  echartsOptions: any; // The options that will configure echarts to draw the appropriate chart

  // default parameters
  defaultSeriesName = 'Series';
  maxNumberOfItemToDisplayLabels = 15;

  // responsiveness parameters
  baseDimension = 120; // the minimum dimension of the container that the responsiveness works ( like Planck length :) )
  baseFontSize = 8; // minimum possible font size for a container having the minimum dimensions

  // Service dependencies
  private changeDetector: ChangeDetectorRef;
  protected dataExtractionUtil: DataExtractionUtil;
  protected layoutService: LayoutService;

  constructor(injector: Injector) {
    super(injector);

    this.changeDetector = injector.get(ChangeDetectorRef);
    this.dataExtractionUtil = injector.get(DataExtractionUtil);
    this.layoutService = injector.get(LayoutService);

    // subscribe to side bar toggle events so that charts can be updated
    this.layoutService.onMapAreaChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onSideBarToggle());

    // subscribe to screen resize events so that charts can be updated
    this.layoutService.onApplicationWidthChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe(applicationWidth => this.onResize());
  }

  /********** Abstract Methods **********/

  /**
   * Transforms the data into an appropriate format for the specific chart and updates the data based on given dimensions and dataseries
   * @param data
   */
  abstract setData(data: NGSIResult[]);

  /**
   * Returns the default echarts parameters
   */
  abstract defaultChartOptions(): any;

  /**
   * Return the responsive echarts parameters
   */
  abstract responsiveChartOptions(): any;


  /********** Lifecycle Methods **********/

  ngOnInit(): void {
    // process additional chart settings
    if (this.chartSettings.settings) {
      const keys = Object.keys(this.chartSettings.settings);
      keys.forEach(key => {
        this[key] = this.chartSettings.settings[key];
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // after chart container is initialized, calculate the responsive parameters and merge them with default chart parameters
      const defaultOptions = this.defaultChartOptions();
      const responsiveOptions = this.responsiveChartOptions();
      this.echartsOptions = ObjectUtil.mergeDeep(defaultOptions, responsiveOptions);
      // this.changeDetector.detectChanges();
    }/*, 250*/);
  }

  /********** Event Handlers **********/

  /**
   *  Method called when echarts DOM element is initialized
   * @param ec The initialized echarts instance
   */
  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  /**
   * Called when the user changes the browser window
   */
  onResize() {
    if (this.echartsContainer && this.echartsInstance) {
      const responsiveOptions = this.responsiveChartOptions();
      this.echartsInstance.setOption(responsiveOptions);
    }
  }

  /**
   * Called when side bar has been collapsed or expanded
   */
  onSideBarToggle() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  /********** Chart Setters **********/

  /**
   * Updates the chart data. This method should be called when there is a reference to a chart.component child
   * @param data The data that is understandable by echarts
   */
  setSeriesData(data) {
    if (this.echartsInstance) {
      this.echartsInstance.setOption({
        series: [{
          data: data
        }],
      });
    }
  }

  /**
   * Updates the chart data for multiseries charts. This method should be called when there is a reference to a chart.component child
   * @param series
   */
  setMultiSeriesData(series) {
    if (this.echartsInstance) {
      this.echartsInstance.setOption({
        series: series,
      });
    }
  }

  /**
   * Updates the x axis labels. This method should be called when there is a reference to a chart.component child
   * @param labels labels for the x axis
   */
  setXAxisLabels(labels: string[]) {
    if (this.echartsInstance) {
      this.echartsInstance.setOption({
        xAxis: [{
          data: labels,
          show: labels.length <= this.maxNumberOfItemToDisplayLabels
        }],
      });
    }
  }

  /**
   * Updates the y axis labels. This method should be called when there is a reference to a chart.component child
   * @param labels labels for the y axis
   */
  setYAxisLabels(labels: string[]) {
    if (this.echartsInstance) {
      this.echartsInstance.setOption({
        yAxis: [{
          data: labels,
          show: labels.length <= this.maxNumberOfItemToDisplayLabels
        }],
      });
    }
  }

  /********** Utility Methods **********/


  /**
   * A tooltip formatter method for different types of charts
   * @param params
   */
  tooltipFormatter(params) {
    let tooltip = '';

    if (this.chartSettings.seriesSettings.length > 0) {
      const chartSeries = this.chartSettings.seriesSettings[0];
      const kpiSeries = this.kpi.series[chartSeries.index];

      // set series name
      if (params.seriesName && params.seriesName.length > 0) {
        tooltip += `<span  style="font-weight: bold; text-align: left">${params.seriesName}</span><br><br>`;
      }

      // set value
      const value = DataFormatUtil.formatValue(params.value, kpiSeries.format, chartSeries.precision, chartSeries.thousandSeparator);
      const unit = kpiSeries.unit?.length > 0 ? ' ' + kpiSeries.unit : '';
      const percentage = params.seriesType === 'pie' ? ` (% ${params.percent})` : '';

      tooltip += `${params.marker} <span>${params.name}</span> : <span style="font-weight: bold">${value}${unit}</span><span>${percentage}</span>`;
    }

    return tooltip;
  }

  /**
   * Formats the value axis labels based on their formatting configurations
   * @param value
   */
  valueAxisFormatter(value: any) {
    if (this.chartSettings.seriesSettings.length > 0) {
      const chartSeries = this.chartSettings.seriesSettings[0];
      const kpiSeries = this.kpi.series[chartSeries.index];
      return DataFormatUtil.formatValue(value, kpiSeries.format, chartSeries.precision, chartSeries.thousandSeparator);
    } else { // return directly if no series found
      return value;
    }
  }

  /**
   * Exports the chart as PNG Image
   */
  exportImage() {
    const base64 = this.echartsInstance.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#272727'
    });

    // download png
    this.downloadFile(base64, this.panelTitle + '.png');
  }

  /**
   * Exports chart data as CSV file
   */
  exportCSV() {
    if (this.rawData && this.rawData.length > 0) {
      const delimiter = ',';

      // identify series and dimensions
      let dimensionData, seriesData = [];
      this.rawData.forEach(axis => {
        if (axis.dimension) {
          dimensionData = axis;
        } else if (axis.series) {
          seriesData.push(axis);
        }
      });

      // sort series by their index
      seriesData.sort((a,b) => (a.chartAxisSetting.index > b.chartAxisSetting.index) ? 1 : ((b.chartAxisSetting.index > a.chartAxisSetting.index) ? -1 : 0));

      // set csv header
      let csvData = dimensionData.chartAxisSetting.label + delimiter + seriesData.map(series => series.chartAxisSetting.label).join(delimiter) + '\n';

      // set csv body
      for (let i = 0; i < dimensionData.data.length; i++) {
        csvData += dimensionData.data[i];

        for (let j = 0; j < seriesData.length; j++) {
          csvData += delimiter + seriesData[j].data[i];
        }

        csvData += '\n';
      }

      // download csv file
      // add a BOM as first characters in the file to force Excel to use UTF-8
      this.downloadFile('data:text/csv;charset=utf-8,%EF%BB%BF' + csvData, this.panelTitle + '.csv');
    }
  }

  /**
   * Finds a suitable label from a given object
   * @param object
   */
  getLabel(object: any) {
    if (object.hasOwnProperty('name')) {
      return object['name'];
    }
    if (object.hasOwnProperty('id')) {
      return object['id'];
    } else {
      return ObjectUtil.getFirstValue(object);
    }
  }

  /**
   * Returns appropriate translations from a given list of labels
   * @param labels list of labels whose translations will be retrieved
   * @param callback callback function that is supposed to be called after retrieving label translations
   */
  getLabelTranslations(labels: string[], callback) {
    this.translateService.get(labels).subscribe(results => {
      if (callback) {
        callback(results);
      }
    });
  }

  /**
   * Creates a linear gradient from given colors
   * @param colors
   */
  createGradient(colors: string[]) {
    const offsetIncrement = 1 / (colors.length - 1);
    const gradient = colors.map((color, index) => {
      return {
        offset: index * offsetIncrement,
        color: color
      };
    });

    return new echarts.graphic.LinearGradient(0, 0, 0, 1, gradient);
  }

  /**
   * Returns a value based on a given chart value after evaluating a set of expressions
   * @param value a chart value for which a value will be determined
   * @param expressions a list of expression in which a value will be determined
   * @param valueField the field of expressions which keeps the value to be applied
   */
  evaluateExpression(value, expressions, valueField) {
    for (let i = 0; i < expressions.length; i++) {
      const expression = expressions[i];

      if (evaluateOperatorForSingleValue(value, expression.value, expression.op)) {
        return expression[valueField];
      }
    }
  }

  /**
   * Creates a hidden html element to allow the user to download a file
   * @param fileContent Content of the file to be downloaded
   * @param fileName Name and extension of the file to be downloaded (e.g. export.png)
   * @private
   */
  protected downloadFile(fileContent, fileName) {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = fileContent;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
