<div class="videowall-panel">
  <div *ngIf="editMode" class="panel-header">
    <!-- Icon to delete panel -->
    <div class="icon-container" title="{{ 'Delete' | translate }}">
      <uruk-svg (click)="deletePanel()"
                [height]="'100%'"
                [paths]="['M8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.424 16 16 12.424 16 8C16 3.576 12.424 0 8 0ZM11.7656 10.6344C12.0784 10.9472 12.0784 11.4528 11.7656 11.7656C11.6096 11.9216 11.4048 12 11.2 12C10.9952 12 10.7904 11.9216 10.6344 11.7656L8 9.1312L5.3656 11.7656C5.2096 11.9216 5.0048 12 4.8 12C4.5952 12 4.3904 11.9216 4.2344 11.7656C3.9216 11.4528 3.9216 10.9472 4.2344 10.6344L6.8688 8L4.2344 5.3656C3.9216 5.0528 3.9216 4.5472 4.2344 4.2344C4.5472 3.9216 5.0528 3.9216 5.3656 4.2344L8 6.8688L10.6344 4.2344C10.9472 3.9216 11.4528 3.9216 11.7656 4.2344C12.0784 4.5472 12.0784 5.0528 11.7656 5.3656L9.1312 8L11.7656 10.6344Z']"
                [svgContainerClass]="'uruk-icon'"
                [viewbox]="'0 0 16 16'"
                [width]="'100%'">
      </uruk-svg>
    </div>
  </div>
  <div class="panel-body">
    <uruk-indicator #chart
                    [kpi]="this.kpi"
                    [chartSettings]="panel.chartSettings"></uruk-indicator>
  </div>

  <div class="panel-footer">
    <div class="panel-title">
      {{ panel.title }}
    </div>
  </div>
</div>
