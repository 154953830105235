import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth/auth.service';
import {EventService} from './event.service';

/**
 * Base Http Client service
 */
@Injectable({
  providedIn: 'root'
})
export class BaseHttpClientService {

  protected endpoint = null;

  protected httpClient: HttpClient;
  protected authService: AuthService;
  protected eventService: EventService;

  constructor(injector: Injector,
              private baseUrl: string,
              private path: string) {
    this.httpClient = injector.get(HttpClient);
    this.authService = injector.get(AuthService);
    this.eventService = injector.get(EventService);

    // construct the endpoint using the given base url, path and active realm id
    this.endpoint = `${baseUrl}/${this.authService.getRealmId()}`;
    if (path) {
      this.endpoint += `/${path}`;
    }
  }
}
