import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {map, skip, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MenuService} from '../../services/menu/menu.service';
import { AuthService } from 'app/core/services/auth/auth.service';
import { RealmService } from 'app/core/services/meta/realm.service';
import { environment } from 'environments/environment';
import { EventService } from 'app/core/services/event.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  // keeps whether the sidebar is expanded or not
  public isSidebarExpanded: boolean = true;
  private destroy$: Subject<void> = new Subject<void>();
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  // realm logo
  realmLogoSrc = null;
  // realm id
  keycloakRealmId = null;

  constructor(private sidebarService: NbSidebarService,
              private menuService: MenuService,
              private themeService: NbThemeService,
              private authService: AuthService,
              private realmService: RealmService,
              private eventService: EventService,
              private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.keycloakRealmId = this.authService.getRealmId(true)
    this.currentTheme = this.themeService.currentTheme;
    // set realm logo
    const realmId = this.authService.getRealmId();
    if(realmId !== environment.auth.master_realm_id){
      this.realmService.getRealmLogo(this.authService.getRealmId())
      .subscribe(logo => {
        this.realmLogoSrc = logo?.content ? logo?.content : "assets/images/aselsan-white.png"; 
      })
    } else {
      this.realmLogoSrc = "assets/images/aselsan-white.png"; 
    }

    this.subscribeToEvents();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  /**
   * Subscribes to the several events.
   */
  private subscribeToEvents(): void {
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        skip(1), // Sidebar state is always updated to 'expanded' when page is loaded, therefore,
        // skip the first event not to change sidebar state
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe(isLessThanXl => {
        if (isLessThanXl) {
          this.sidebarService.compact();
        } else {
          this.sidebarService.expand();
        }
      });
    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.isSidebarExpanded
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(isSidebarExpanded => {
        this.isSidebarExpanded = isSidebarExpanded;
      });
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.REALM_LOGO_UPDATED:
            this.realmLogoSrc = event.data ? event.data : 'assets/images/aselsan-white.png'; 
            break;
        }
      });
  }
}
