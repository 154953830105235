import { Component, ElementRef, HostListener, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/shared/components/base.component';
import { Coordinate } from 'app/shared/models/generic/coordinate.model';
import { GeoJson } from 'app/shared/models/geometry/geo-json.model';
import { EntityResult } from 'app/shared/models/schema/entity-result';
import { ObjectUtil } from 'app/shared/utils/object-util';
import { debounceTime, Subject, takeUntil } from 'rxjs';

/**
 * Input component to filter {@link EntityResult}s by text search.
 */
@Component({
  selector: 'entity-search',
  templateUrl: './entity-search.component.html',
  styleUrls: ['./entity-search.component.scss']
})
export class EntitySearchComponent extends BaseComponent implements OnInit, OnChanges {

  // EntityResults to be filtered
  @Input() entities: EntityResult[];

  // search text
  public searchText = null;
  // metadata of entities. Each entity metadata includes entity id and value of string/array of strings properties
  public searchMetadata: any[];
  // whether the search input is displayed. If it is false, an icon will be displayed instead of search input
  public displaySearchInput = false;
  // subject to keep track of populate metadata events
  private populateMetadataSubject = new Subject<void>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchInputContainer') searchInputContainer: ElementRef;

  constructor(private injector: Injector) {
    super(injector)
  }

  ngOnInit() {
    // subscribe to populate metadata events
    this.populateMetadataSubject
      .pipe(debounceTime(500))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.searchMetadata = this.entities.map(entity => this.getSearchMetadata(entity))
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // when entities are updated, populate the metadata
    if (changes.entities.currentValue !== changes.entities.previousValue) {
      this.populateMetadataSubject.next();
    }
  }

  /**
   * Handles the entity selection by broadcasting a pan and zoom event to zoom in on entity location.
   * @param id the entity id
   */
  public selectEntity(id) {
    // clear search
    this.searchText = null;
    // get the selected entity
    const entity = this.entities.find(entity => entity.id === id);
    // find the location
    const center = GeoJson.getCenter(entity.getElementValue('location'));
    const coordinate = Coordinate.fromLongLatArray(center);
    this.eventService.broadcastPanAndZoomEvent(coordinate, 18, 1.5);
  }

  /**
   * Populates the search metadata of given entity result. Metadata contains the entity id and string/array of strings
   * properties of entity.
   * @param entityResult 
   * @returns the metadata of entity result
   */
  private getSearchMetadata(entityResult: EntityResult) {
    const entity = entityResult.entity;
    const metadata = {
      "id": entity.id,
    }
    Object.keys(entity).forEach(elementKey => {
      const element = entity[elementKey]
      if (element.type === 'Property' && element.value) {
        if (typeof element.value === 'string') {
          metadata[elementKey] = this.translateService.instant(element.value)
        } else if (ObjectUtil.isArray(element.value)) {
          metadata[elementKey] = element.value.filter(v => typeof v === 'string').map(v => this.translateService.instant(v)).join(' ')
        }
      }
    })
    return metadata;
  }

  /**
   * Opens and focus on the search input.
   */
  public openSearchInput() {
    // clear search
    this.searchText = null;
    this.displaySearchInput = true;
    setTimeout(() => this.searchInput.nativeElement.focus())

  }

  /* /**
    * Listens to click events to close search input when the user clicks on the screen.
    */
  @HostListener('document:click', ['$event'])
  close(event) {
    if (this.searchInput && !this.searchInputContainer.nativeElement.contains(event.target)) {
      this.displaySearchInput = false;
    }
  }
}
