import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {Panel} from '../../../../../shared/models/visualization/panel.model';
import {VisualizationMode} from '../../../../../shared/enums/visualization-mode.enum';
import {
  AQI_LEGEND, PM25_LEGEND,
  CO_LEGEND, NO2_LEGEND, O3_LEGEND,
  PM10_LEGEND, SO2_LEGEND,
  WeatherPollutionLevel, WeatherPollutionUtil
} from '../../../../../shared/utils/weather-pollution-util';
import {TimeUtil} from '../../../../../shared/utils/time-util';

@Component({
  selector: 'weather-pollution-dialog-template',
  templateUrl: './weather-pollution-dialog-template.component.html',
  styleUrls: ['./weather-pollution-dialog-template.component.scss']
})
export class WeatherPollutionDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  // Context variables
  title: string;
  entity: any;
  aqi;
  weatherPollutionLevel: WeatherPollutionLevel;
  name: string;
  address: any;

  // Panel location variables
  PANEL_LOCATION_AQI = 'aqi';
  PANEL_LOCATION_CO = 'co';
  PANEL_LOCATION_SO2 = 'so2';
  PANEL_LOCATION_NO2 = 'no2';
  PANEL_LOCATION_O3 = 'o3';
  PANEL_LOCATION_PM10 = 'pm10';
  PANEL_LOCATION_PM25 = 'pm25';
  PANEL_LOCATION_AQI_PREDICTION = 'aqiPrediction';
  PANEL_LOCATION_CO_PREDICTION = 'coPrediction';
  PANEL_LOCATION_SO2_PREDICTION = 'so2Prediction';
  PANEL_LOCATION_NO2_PREDICTION = 'no2Prediction';
  PANEL_LOCATION_O3_PREDICTION = 'o3Prediction';
  PANEL_LOCATION_PM10_PREDICTION = 'pm10Prediction';
  PANEL_LOCATION_PM25_PREDICTION = 'pm25Prediction';

  aqiPanel: Panel;
  coPanel: Panel;
  so2Panel: Panel;
  no2Panel: Panel;
  o3Panel: Panel;
  pm10Panel: Panel;
  pm25Panel: Panel;
  aqiPredictionPanel: Panel;
  coPredictionPanel: Panel;
  so2PredictionPanel: Panel;
  no2PredictionPanel: Panel;
  o3PredictionPanel: Panel;
  pm10PredictionPanel: Panel;
  pm25PredictionPanel: Panel;

  // Legends
  aqiLegend = AQI_LEGEND;
  coLegend = CO_LEGEND;
  no2Legend = NO2_LEGEND;
  so2Legend = SO2_LEGEND;
  o3Legend = O3_LEGEND;
  pm10Legend = PM10_LEGEND;
  pm25Legend = PM25_LEGEND;

  // keeps the active tab
  activeTab: string;

  /// Modified dates of each tab
  summaryTabModifiedDate: any;
  aqiTabModifiedDate: any;
  coTabModifiedDate: any;
  no2TabModifiedDate: any;
  so2TabModifiedDate: any;
  o3TabModifiedDate: any;
  pm10TabModifiedDate: any;
  pm25TabModifiedDate: any;

  // Declarations
  VisualizationMode = VisualizationMode;
  dialogViewportMin: number;

  getWeatherPollutionLabel = WeatherPollutionUtil.getWeatherPollutionLabel;
  assessCO = WeatherPollutionUtil.assessCO;
  assessO3 = WeatherPollutionUtil.assessO3;
  assessNO2 = WeatherPollutionUtil.assessNO2;
  assessPM10 = WeatherPollutionUtil.assessPM10;
  assessPM25 = WeatherPollutionUtil.assessPM25;
  assessSO2 = WeatherPollutionUtil.assessSO2;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dialogViewportMin = Math.min(this.layoutService.getApplicationSize()[0], this.layoutService.getApplicationSize()[1]) / 4;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected mapContextData(context: any): void {
    this.title = context.title;
    this.entity = context.entity.entity;

    this.address = super.getValue(this.entity, 'address');
    this.name = super.getValue(this.entity, 'name');
    this.aqi = Math.round(super.getValue(this.entity, 'aqi'));

    this.populateModifiedDatesOfTabs();
    // TODO: temporary check, will be deleted
    if (!this.aqi) {
      this.aqi = Math.round(
        Math.max(
          super.getValue(this.entity, 'co_aqi'),
          super.getValue(this.entity, 'no2_aqi'),
          super.getValue(this.entity, 'so2_aqi'),
          super.getValue(this.entity, 'o3_aqi'),
          super.getValue(this.entity, 'pm25_aqi'),
          super.getValue(this.entity, 'pm10_aqi'))
      );
    }
    this.weatherPollutionLevel = WeatherPollutionUtil.assessWeatherPollutionAQI(this.aqi);
  }

  mapPanels(): void {
    this.page.panels.forEach(panel => {
      panel.ngsiContext.idQuery = this.entity.id;
      switch (panel.panelLocation) {
        case this.PANEL_LOCATION_AQI:
          this.aqiPanel = panel;
          break;
        case this.PANEL_LOCATION_CO:
          this.coPanel = panel;
          break;
        case this.PANEL_LOCATION_SO2:
          this.so2Panel = panel;
          break;
        case this.PANEL_LOCATION_NO2:
          this.no2Panel = panel;
          break;
        case this.PANEL_LOCATION_O3:
          this.o3Panel = panel;
          break;
        case this.PANEL_LOCATION_PM10:
          this.pm10Panel = panel;
          break;
        case this.PANEL_LOCATION_PM25:
          this.pm25Panel = panel;
          break;
        case this.PANEL_LOCATION_AQI_PREDICTION:
          this.aqiPredictionPanel = panel;
          break;
        case this.PANEL_LOCATION_CO_PREDICTION:
          this.coPredictionPanel = panel;
          break;
        case this.PANEL_LOCATION_SO2_PREDICTION:
          this.so2PredictionPanel = panel;
          break;
        case this.PANEL_LOCATION_NO2_PREDICTION:
          this.no2PredictionPanel = panel;
          break;
        case this.PANEL_LOCATION_O3_PREDICTION:
          this.o3PredictionPanel = panel;
          break;
        case this.PANEL_LOCATION_PM10_PREDICTION:
          this.pm10PredictionPanel = panel;
          break;
        case this.PANEL_LOCATION_PM25_PREDICTION:
          this.pm25PredictionPanel = panel;
          break;
      }
    });
  }

  getActivitySuggestionsForGeneralPopulation() {
    switch (this.weatherPollutionLevel) {
      case WeatherPollutionLevel.Good: return 'Weather Pollution Suggestion General Population - Good';
      case WeatherPollutionLevel.Moderate: return 'Weather Pollution Suggestion General Population - Moderate';
      case WeatherPollutionLevel.UnhealthyForSensitive: return 'Weather Pollution Suggestion General Population - Sensitive';
      case WeatherPollutionLevel.Unhealthy: return 'Weather Pollution Suggestion General Population - Unhealthy';
      case WeatherPollutionLevel.VeryUnhealthy: return 'Weather Pollution Suggestion General Population - Very Unhealthy';
      case WeatherPollutionLevel.Hazardous: return 'Weather Pollution Suggestion General Population - Hazardous';
    }
  }

  getActivitySuggestionsForSensitive() {
    switch (this.weatherPollutionLevel) {
      case WeatherPollutionLevel.Good: return 'Weather Pollution Suggestion Sensitive - Good';
      case WeatherPollutionLevel.Moderate: return 'Weather Pollution Suggestion Sensitive - Moderate';
      case WeatherPollutionLevel.UnhealthyForSensitive: return 'Weather Pollution Suggestion Sensitive - Sensitive';
      case WeatherPollutionLevel.Unhealthy: return 'Weather Pollution Suggestion Sensitive - Unhealthy';
      case WeatherPollutionLevel.VeryUnhealthy: return 'Weather Pollution Suggestion Sensitive - Very Unhealthy';
      case WeatherPollutionLevel.Hazardous: return 'Weather Pollution Suggestion Sensitive - Hazardous';
    }
  }

  getValue(entity, field): any {
    const value =  super.getValue(entity, field);
    if (value) {
      return Math.round(value);
    } else {
      return '-';
    }
  }

  getAQI(value) {
    return isNaN(value) ? '-' : value;
  }

  /**
   * Sets the active tab based on the tab selection event.
   * @param event the tab selection event
   * */
  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  /**
   * Populates the modified date of tabs.
   * */
  private populateModifiedDatesOfTabs() {
    const aqi = super.getValue(this.entity, 'aqi', 'modifiedAt');
    const coAqi = super.getValue(this.entity, 'co_aqi', 'modifiedAt');
    const no2Aqi = super.getValue(this.entity, 'no2_aqi', 'modifiedAt');
    const so2Aqi = super.getValue(this.entity, 'so2_aqi', 'modifiedAt');
    const o3Aqi = super.getValue(this.entity, 'o3_aqi', 'modifiedAt');
    const pm10Aqi = super.getValue(this.entity, 'pm10_aqi', 'modifiedAt');
    const pm25Aqi = super.getValue(this.entity, 'pm25_aqi', 'modifiedAt');

    const validDates: Date[] = [];
    if (aqi) {
      const aqiModifiedDate: Date = TimeUtil.parseISODatetime(aqi);
      this.aqiTabModifiedDate = TimeUtil.serializeCustomDatetime(aqiModifiedDate);
      validDates.push(aqiModifiedDate);
    }
    if (coAqi) {
      const coModifiedDate: Date = TimeUtil.parseISODatetime(coAqi);
      this.coTabModifiedDate = TimeUtil.serializeCustomDatetime(coModifiedDate);
      validDates.push(coModifiedDate);
    }
    if (no2Aqi) {
      const no2ModifiedDate: Date = TimeUtil.parseISODatetime(no2Aqi);
      this.no2TabModifiedDate = TimeUtil.serializeCustomDatetime(no2ModifiedDate);
      validDates.push(no2ModifiedDate);
    }
    if (so2Aqi) {
      const so2ModifiedDate: Date = TimeUtil.parseISODatetime(so2Aqi);
      this.so2TabModifiedDate = TimeUtil.serializeCustomDatetime(so2ModifiedDate);
      validDates.push(so2ModifiedDate);
    }
    if (o3Aqi) {
      const o3ModifiedDate: Date = TimeUtil.parseISODatetime(o3Aqi);
      this.o3TabModifiedDate = TimeUtil.serializeCustomDatetime(o3ModifiedDate);
      validDates.push(o3ModifiedDate);
    }
    if (pm10Aqi) {
      const pm10ModifiedDate: Date = TimeUtil.parseISODatetime(pm10Aqi);
      this.pm10TabModifiedDate = TimeUtil.serializeCustomDatetime(pm10ModifiedDate);
      validDates.push(pm10ModifiedDate);
    }
    if (pm25Aqi) {
      const pm25ModifiedDate: Date = TimeUtil.parseISODatetime(pm25Aqi);
      this.pm25TabModifiedDate = TimeUtil.serializeCustomDatetime(pm25ModifiedDate);
      validDates.push(pm25ModifiedDate);
    }

    // assign the nearest date to summary tab
    if (validDates.length) {
      this.summaryTabModifiedDate = TimeUtil.serializeCustomDatetime(
        validDates.reduce((nearestDate, date) => {
          if (date >= nearestDate) {
            return date;
          }
          return nearestDate;
        }, validDates[0]));
    }
  }
}
