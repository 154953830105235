import {AxisSettings} from '../visualization/axis-settings.model';
import {DataDimension} from '../visualization/data-dimension.model';
import {DataSeries} from '../visualization/data-series.model';
import {ChartDataFormat} from '../../enums/chart-data-format';

/**
 * Entity that contains data and accompanying dimension, series configurations from the KPI returning the data
 */
export class DimensionSeriesData {
  /**
   * Data items specific to a dimension or series
   */
  data: any[] = [];

  /**
   * Axis settings about visualization of the data
   */
  chartAxisSetting: AxisSettings;

  /**
   * Data dimension of the relevant KPI associated with the data. Mutually exclusive with the series field
   */
  dimension?: DataDimension;

  /**
   * Data series of the relevant KPI associated with the data
   */
  series?: DataSeries;

  /**
   * Returns the target DataFormat of data entities kept in this object
   */
  getDataType(): ChartDataFormat {
    if (this.dimension) {
      const dimensionKey: string = this.chartAxisSetting.path;
      return this.dimension.keys[dimensionKey].dataType;

    } else {
      return this.series.format;
    }
  }
}
