import {Geometry} from '../../enums/ngsi-query.enum';
import {Coordinate} from '../generic/coordinate.model';

export class GeoProperty {
  type: string;
  /**
   * Keeps a latitude and longitude respectively
   */
  coordinates: number[] = [];
  radius?: number;

  constructor(data: any, keyValues: boolean = true) {
    if (!keyValues) {
      data = data.value;
    }

    this.type = data.type;

    if (this.type === Geometry.POINT) {
      // copying the coordinates as in some cases reversing the given data is being used by the application. For instance, layers cache the entity results, which are given as
      // input this method. Thus, modifying the given data causes that cached data to be modified, which should not be done.
      this.coordinates = JSON.parse(JSON.stringify(data.coordinates)).reverse();
    } else if (this.type === Geometry.LINE_STRING) {
      this.coordinates = JSON.parse(JSON.stringify(data.coordinates)).map(item => item.reverse());
    } else if (this.type === Geometry.POLYGON) {
      this.coordinates = [ JSON.parse(JSON.stringify(data.coordinates)).map(item => item.reverse()) ];
    }

    if (data.radius) {
      this.radius = data.radius;
    }
  }

  /**
   * Transforms this geo property to a {@link Coordinate} instance if the property has two values in the coordinates array
   */
  getCoordinate(): Coordinate {
    if (this.coordinates.length === 2) {
      return new Coordinate({
        latitude: this.coordinates[0],
        longitude: this.coordinates[1]
      });
    } else {
      return null;
    }
  }

  /**
   * Transforms this geo property to GeoJson format
   */
  toGeoJson(): any {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: this.type,
        coordinates: this.coordinates
      }
    }
  }
}
