import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {KPI} from '../../models/kpi.model';
import {environment} from '../../../../environments/environment';
import {Tag} from '../../models/tag.model';
import {BaseFormComponent} from '../form/base-form.component';
import {SearchQuery} from '../../models/query/search-query.model';

/**
 * A component to search for and select KPIs.
 */
@Component({
  selector: 'uruk-kpi-search',
  templateUrl: './kpi-search.component.html',
  styleUrls: ['./kpi-search.component.scss']
})
export class KpiSearchComponent extends BaseFormComponent implements OnInit, OnDestroy {

  // function to filter kpis which are suitable to select
  @Input() filterKpis: (kpis:KPI[]) => KPI[];
  // The output event when user selects a KPI by clicking on it
  @Output() kpiSelected: EventEmitter<KPI> = new EventEmitter<KPI>();

  // the search term for kpi when user types something
  kpiSearchTerm: string;

  // selected KPI object
  selectedKPI: KPI;

  // selected tag to filter list of resulting KPIs
  selectedTags: Tag[] = [];

  // the list of KPI results returned from the server
  kpis: KPI[] = [];

  // the list of tags returned from the server. TODO: will be retrieved from the server
  tags: Tag[] = [];

  // Subscription object for typing events
  private searchQueries = new Subject<void>();

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    // subscribe to keyboard type events
    this.handleSubscriptionForSearching();

    // retrieve all KPIs at first
    this.getKPIs();

    // retrieve all tags
    this.getTags();

    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * Subscribes to keyboard type events when user wants to filter lists by typing some search terms
   */
  handleSubscriptionForSearching() {
    this.searchQueries.pipe(debounceTime(environment.timeouts.debounceTimes.searchQueries)).subscribe(_ => {
      this.getKPIs();
    });
  }

  /**
   * Called when user types a letter
   */
  onKpiSearchTermChanged() {
    this.searchQueries.next();
  }

  /**
   * Retrieves KPIs based on given filtering options
   */
  getKPIs() {
    const kpiQuery = new SearchQuery();
    kpiQuery.text = this.kpiSearchTerm;
    kpiQuery.domains = this.selectedTags.map(tag => tag.code);

    this.kpiService.getKPIs(kpiQuery)
      .pipe(takeUntil(this.destroy$))
      .subscribe(kpis => {
        this.kpis = kpis;
        // apply the filter if any
        if(this.filterKpis){
          this.kpis = this.filterKpis(this.kpis);
        }
      });
  }

  /**
   *  Retrieves tags from the server
   */
  getTags() {
    this.tagService.getDomainTags()
      .pipe(takeUntil(this.destroy$))
      .subscribe(tags => {
        this.tags = tags;
      });
  }

  /**
   * Emits the selected KPI when user selects (clicks) a KPI
   * @param kpi
   */
  selectKPI(kpi) {
    this.selectedKPI = kpi;
    this.kpiSelected.emit(kpi);
  }

  /**
   * Handle clicks on a tag defined on the system. When a user selected tag(s), the KPI list will be updated accordingly
   * @param tag
   */
  selectTag(tag: Tag) {
    if (!this.selectedTags.includes(tag)) {
      this.selectedTags.push(tag);
      this.getKPIs();
    }
  }

  /**
   * Removes a selected tag and performs another search
   * @param index
   */
  removeSelectedTag(index) {
    this.selectedTags.splice(index, 1);
    this.getKPIs();
  }

  /**
   * Removes all selected tags and performs another search
   */
  removeAllSelectedTags() {
    this.selectedTags = [];
    this.getKPIs();
  }
}
