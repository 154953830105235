declare const window: any; // declare 'window' variable to fix Typescript compilation errors when accessing 'window.msCrypto'

export class StringUtil {
  /**
   * Generates a random string with given number of character
   * @param numberOfCharacters number of characters in result string
   */
  public static generateRandomString(numberOfCharacters: number): string {
    return Math.random().toString(36).substr(2, numberOfCharacters);
  }

  /**
   * Generates a cryptographically random string with given number of character
   */
  public static generateCryptographicallyRandomString(): string {
    const MAXLEN = 12;
    const MINLEN = 6;

    const generate = () => {
      let array = new Uint8Array(MAXLEN);
      const cryptoObj = window.crypto || window.msCrypto; // msCrypto for IE 11
      cryptoObj.getRandomValues(array);
      array = Array.apply([], array); /* turn into non-typed array */
      array = array.filter(function (x) {
        return x > 32 && x < 127;
      });
      return String.fromCharCode.apply(String, array);
    };

    let gen = generate();
    while (gen.length < MINLEN) {
      gen += generate();
    }

    return gen;
  }

  /**
   * Creates a 53-bit hash from given string
   * @param str string to be hashed
   * @param seed seed value for hashing
   */
  public static hash53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString(16);
  }

  /**
   * Trims the decimal point number with the given decimal point count
   * @param number
   * @param trimTo
   */
  public static trimDecimal(number: number, trimTo: number = 1): string {
    return number.toFixed(trimTo);
  }

  /**
   * Returns a dash (-) if the given value is null / undefined
   * @param value
   */
  public static valueOrDash(value: string): string {
    if (value) {
      return value;
    } else {
      return '-';
    }
  }

  /**
   * Capitalizes the first character of a given string
   */
  public static capitalizeFirstCharacter(str: string): string {
    return str[0].toUpperCase() + str.substr(1);
  }
}
