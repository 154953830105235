<!-- Content goes here -->
<uruk-map #map [layers]="layerControllers" [mapSettings]="page.mapSettings"></uruk-map>

<div class="videowall fullscreen-overlay">

  <!-- 1st Column -->
  <div class="videowall-column flex-gap ">

    <div class="logo-container">
      <img class="uruk-logo click-sensitive" src="assets/images/uruk-logo.png" routerLink="/">
      <img class="uruk-name-logo click-sensitive" src="assets/images/uruk-name-logo.png" routerLink="/">
      <div class="separator"></div>
      <span class="uruk-logo-text click-sensitive" routerLink="/">Geleceğin Şehri</span>
    </div>

    <ng-container *ngFor="let item of [].constructor(largePanelCount); let i = index">
      <ng-container
        *ngTemplateOutlet="videowallPanel; context: { location: PANEL_LOCATION_LEFT, index: i}"></ng-container>
    </ng-container>
  </div>

  <!-- 2nd Column -->
  <div class="videowall-column large">

    <!-- smaller top panels -->
    <div class="top-panel-container flex-gap">
      <ng-container *ngFor="let item of [].constructor(smallPanelCount); let i = index">
        <ng-container
          *ngTemplateOutlet="videowallPanel; context: { location: PANEL_LOCATION_TOP, index: i}"></ng-container>
      </ng-container>
    </div>

    <!-- larger bottom panels -->
    <div class="bottom-panel-container flex-gap">
      <ng-container *ngFor="let item of [].constructor(largePanelCount); let i = index">
        <ng-container
          *ngTemplateOutlet="videowallPanel; context: { location: PANEL_LOCATION_BOTTOM, index: i}"></ng-container>
      </ng-container>
    </div>

  </div>

  <!-- 3rd Column -->
  <div class="videowall-column">

    <div class="logo-container right-aligned">
      <div class="date-time">
        <span>{{ time | date: 'dd.MM.yyyy' }}</span>
        <span>{{ time | date: 'HH:mm:ss' }}</span>
      </div>
      <img class="aselsan-logo click-sensitive" src="assets/images/aselsan-white.png" routerLink="/">
    </div>

    <div class="videowall-panel vertical camera">
      <div class="camera-options">
        <span class="title">{{'Cameras'|translate}}</span>

        <div class="compress-icon" (click)="collapseCameras()">
          <i class="fa fa-compress"></i>
        </div>
      </div>

      <div class="camera-container">
        <div class="camera-panel" *ngFor="let liveVideo of liveVideos">
          <span>{{liveVideo.name}}</span>
          <img [src]="liveVideo.url">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Generic template for videowall panels -->
<ng-template #videowallPanel let-location="location" let-index="index">
  <div class="videowall-panel"
       [ngClass]="{ 'hidden': !getPanelAtLocation(location, index) }">

    <!-- If there is a panel object associated with the current location, load it here -->
    <uruk-panel *ngIf="location !== PANEL_LOCATION_TOP && getPanelAtLocation(location, index)"
                [panel]="getPanelAtLocation(location, index)"
                [displayPanelSettings]="!isIdle"
                [editMode]="true" [pageId]="page.id"
                (onPanelDelete)="deletePanel(getPanelAtLocation(location, index))"
                (onPanelUpdate)="updatePanel(getPanelAtLocation(location, index))">
    </uruk-panel>
    <uruk-videowall-panel *ngIf="location === PANEL_LOCATION_TOP && getPanelAtLocation(location, index)"
                          [panel]="getPanelAtLocation(location, index)"
                          [editMode]="!isIdle"
                          (onPanelDelete)="deletePanel(getPanelAtLocation(location, index))"
                          (onPanelUpdate)="updatePanel(getPanelAtLocation(location, index))">
    </uruk-videowall-panel>

    <!-- Otherwise, display a helper so that user can add a new panel to the page -->
    <div class="add-panel" *ngIf="!getPanelAtLocation(location, index)"
         [ngClass]="location" [@idleChange]="mouseState()"
         (click)="addPanelToLocation(location, index, location === PANEL_LOCATION_TOP ? ChartType.Indicator : null)">
      <i class="fa fa-plus-square"></i>
    </div>
  </div>
</ng-template>
