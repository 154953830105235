export class PostalAddress {

  addressCountry?: string;
  addressLocality?: string;
  addressRegion?: string;
  postOfficeBoxNumber?: string;
  postalCode?: string;
  streetAddress?: string;

  constructor(data?: any, keyValue: boolean = true) {
    if (!data) {
      return;
    }

    this.addressCountry = data.addressCountry;
    this.addressLocality = data.addressLocality;
    this.addressRegion = data.addressRegion;
    this.postOfficeBoxNumber = data.postOfficeBoxNumber;
    this.postalCode = data.postalCode;
    this.streetAddress = data.streetAddress;
  }

  getDisplayText() {
    let address = '';

    if (this.streetAddress) {
      address += this.streetAddress;
    }

    if (this.postalCode) {
      address += ' ' + this.postalCode;
    }

    if (this.addressRegion) {
      address += ' ' + this.addressRegion;
    }

    if (this.addressLocality) {
      address += ' ' + this.addressLocality;
    }

    if (this.addressCountry) {
      address += ' ' + this.addressCountry;
    }

    return address.trim();
  }
}
