import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RequestTrackerService} from '../services/http/request-tracker.service';

/**
 * Intercepts all HTTP messages
 */
@Injectable()
export class RequestTrackerInterceptor implements HttpInterceptor {

  constructor(private requestTrackerService: RequestTrackerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if request should be tracked or not
    const shouldTrackRequest = this.requestTrackerService.shouldTrackRequest(req);

    // If request needs tracking, track it and handle subscriptions
    if (shouldTrackRequest) {

      // Add request immediately when a new HTTP request is made if it needs to be tracked
      this.requestTrackerService.addRequest(req);

      // We create a new observable which we return instead of the original
      return new Observable(observer => {

        // And subscribe to the original observable to ensure the HttpRequest is made
        const subscription = next.handle(req)
          .pipe(takeUntil(this.requestTrackerService.destroy$))
          .subscribe(
            event => {
              this.requestTrackerService.removeRequest(req);
              observer.next(event);
            },
            err => {
              this.requestTrackerService.removeRequest(req);
              observer.error(err);
            },
            () => {
              // Completed case. executes this in any case at the end.
              this.requestTrackerService.removeRequest(req);
              observer.complete();
            });

        // return teardown logic in case of cancelled requests
        return () => {
          this.requestTrackerService.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    }
    // Otherwise, simply handle the request
    else {
      req = req.clone({ headers: req.headers.delete(RequestTrackerService.DISABLE_HEADER) });
      return next.handle(req);
    }
  }
}
