import {AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Tag} from '../../models/tag.model';
import {BaseComponent} from '../base.component';

/**
 * Displays the available tags for the selection and provides a text input to search for tags.
 * */
@Component({
  selector: 'search-tag-input',
  templateUrl: './search-tag-input.component.html',
  styleUrls: ['./search-tag-input.component.scss']
})
export class SearchTagInputComponent extends BaseComponent implements OnInit, AfterViewInit {
  // available tags for the selection
  @Input() tags: Tag[];
  @Input() highlightSelected = false;
  @Output() onTagSelected: EventEmitter<Tag> = new EventEmitter<Tag>();

  // keeps the translated tag names
  translatedTagNames: string[] = [];
  selectedTags = {};

  @ViewChild('tagSearchText') searchTextRef;
  // keeps the tags including the given search query
  public filteredTags: Tag[] = [];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    // initialize the filtered tags with the available ones
    this.filteredTags = this.tags;
    // get the translations of tag names
    this.translatedTagNames = this.tags.map(tag => this.translateService.instant('Tag_' + tag.name));
  }

  ngAfterViewInit() {
    if (this.searchTextRef) {
      // subscribe to search query
      fromEvent(this.searchTextRef.nativeElement, 'keyup')
        .pipe(
          debounceTime(300),
          distinctUntilChanged()
        )
        .subscribe(
          () => this.searchValues(this.searchTextRef.nativeElement.value)
        );
    }
  }

  /**
   * Emits the selected tag
   * */
  selectTag(tag) {
    if (this.selectedTags[tag.id]) {
      delete this.selectedTags[tag.id];
    } else {
      this.selectedTags[tag.id] = tag;
    }

    this.onTagSelected.emit(tag);
  }

  /**
   * Searches for the given query in the tags and returns the ones including the query term
   * @param query
   */
  private searchValues(query: string): void {
    this.filteredTags = this.tags.filter((tag, index) => this.translatedTagNames[index].toLowerCase().includes(query.toLowerCase()));
  }

}
