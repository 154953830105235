export enum BaseDataType {
  TEXT = 'Text',
  NUMBER = 'Number',
  BOOLEAN = 'Boolean',
  DATETIME = 'DateTime',
  DATE = 'Date',
  TIME = 'Time',
  URI = 'URI',
  GEOJSON = 'geo:json'
}
