<div class="layer-panel">
  <nb-actions size="small" class="">
    <nb-action class="control-button" (click)="onZoomInClicked()" [nbPopover]="'Zoom In' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <div class="icon-container"><i class="control-icon fa fa-plus fa-lg"></i></div>
    </nb-action>

    <nb-action class="control-button" (click)="onZoomOutClicked()" [nbPopover]="'Zoom Out' | translate" nbPopoverTrigger="hint" nbPopoverPlacement="top">
      <div class="icon-container"><i class="control-icon fa fa-minus fa-lg"></i></div>
    </nb-action>
  </nb-actions>
</div>
