/**
 * Represents a simple aggregation result such as:
 * {
 *  "avg_availableSpotNumber": 7,
 *  "sum_avaialableSpotNumber": 150
 * }
 */
export class AggregationResult {
  /**
   * Aggregation result
   */
  result: any;

  constructor(data: any) {
    this.result = data;
  }
}
