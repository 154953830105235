import {Component, Injector} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base.component';

@Component({
  selector: 'zoom-toolbox',
  templateUrl: './zoom-toolbox.component.html',
  styleUrls: ['./zoom-toolbox.component.scss']
})
export class ZoomToolboxComponent extends BaseComponent {

  constructor(injector: Injector) {
    super(injector);
  }

  onZoomInClicked(): void {
    this.eventService.broadcastZoomInEvent();
  }

  onZoomOutClicked(): void {
    this.eventService.broadcastZoomOutEvent();
  }
}
