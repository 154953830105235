import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Tag} from '../../../shared/models/tag.model';
import {TagType} from '../../../shared/enums/tag-type.enum';
import {RestParameters, RestUtil} from '../../../shared/utils/rest-util';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {UserService} from '../data/user.service';
import { TagQuery } from 'app/shared/models/query/tag-query.model';

/**
 * A service to handle tags
 */
@Injectable()
export class TagService extends BaseHttpClientService {

  private userService: UserService;

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'tags');
    this.userService = injector.get(UserService);
  }

  /**
   * Retrieves all domains tags defined on the platform
   * @param applyDomainFilter whether the tag response is filtered based on the domains of authenticated user
   */
   getDomainTags(applyDomainFilter = true): Observable<Tag[]> {
    const query:TagQuery = new TagQuery({
      type:TagType.DOMAIN
    });
    return this.getTags(query,applyDomainFilter)
  }

  /**
   * Retrieves all tags defined on the platform
   * @param type the type of tags to be retrieved
   * @param applyDomainFilter whether the tag response is filtered based on the domains of authenticated user
   */
  getTags(query:TagQuery, applyDomainFilter = true): Observable<Tag[]> {
    const url = this.endpoint + '?' + RestUtil.createURLParameters(query);
    return this.httpClient.get<Tag[]>(url)
      .pipe(
        map(response => {
          const tags = response.map(item => new Tag(item));
          if (applyDomainFilter) {
            // discard the domains which are not available for the authenticated user
            const userDomains: string[] = this.userService.getUserDomains();
            return userDomains?.length ? tags.filter(tag => userDomains.includes(tag.code)) : tags;
          }
          return tags;
        })
      );
  }

  /**
   * Retrieves the details of a single tag
   * @param tagId Id of the tag
   */
  getTag(tagId: String): Observable<Tag> {
    const url = this.endpoint + '/' + tagId;
    return this.httpClient.get(url).pipe(map(response => new Tag(response)));
  }

  /**
   * Creates a new tag on the platform
   * @param tag Tag to be created
   */
  createTag(tag: Tag): Observable<Tag> {
    return this.httpClient.post(this.endpoint, tag).pipe(map(response => new Tag(response)));
  }

  /**
   * Updates the given tag
   * @param tag Tag to be updated
   */
  updateTag(tag: Tag): Observable<any> {
    const patch: any = this.extractEditableFields(tag);
    const url = this.endpoint + '/' + tag.id;
    return this.httpClient.patch(url, patch);
  }

  /**
   * Deletes the tag.
   * @param tagId Id of the tag to be deleted
   */
   deleteTag(tagId: string) {
    const url = this.endpoint + '/' + tagId;
    return this.httpClient.delete(url);
  }


  /**
   * Extracts and returns the editable fields of the given Tag as a json.
   *
   * @param tag
   * @return the json with editable tag fields
   * */
  private extractEditableFields(tag: Tag) {
    return {
      name: tag.name,
      description: tag.description
    };
  }
}
