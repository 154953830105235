import {TimeUtil} from '../../../../utils/time-util';

export class TemporalValue {
  value: any;
  type: string;
  observedAt: Date;
  instanceId: string;

  constructor(data) {
    this.value = data.value ? data.value : data.object;
    this.type = data.type;
    this.observedAt = TimeUtil.parseISODatetime(data.observedAt);
    this.instanceId = data.instanceId;
  }

}
