<ng-container *ngIf="preloaderService.shouldDisplayVideo()">
  <div class="overlay-video flex-centered">
    <video muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true"
           (play)="onPreloaderVideoStart()" (ended)="onPreloaderVideoEnd()"
           (error)="onPreloaderVideoError()">
      <source src="{{layoutService.isVideowall() ? env.assets.preloaderVideoVideowallUrl : videoUrl}}"
              type="video/mp4" (error)="onPreloaderVideoError()">
      <!-- Second video source -->
      <source src="{{defaultVideoUrl}}" type="video/mp4" (ended)="onPreloaderVideoEnd()" (error)="onPreloaderVideoEnd()">
    </video>
  </div>

</ng-container>

<ng-container *ngIf="!preloaderService.shouldDisplayVideo()">
  <!--Dummy divs to color sidebar background. It is outside the nb-layout as the nb-layout element does not recognize custom elements -->
  <ng-container *ngIf="showMenu">
    <div class="sidebar-dark-background"></div>
    <div class="sidebar-light-background"></div>
  </ng-container>

  <nb-layout>
    <nb-layout-header *ngIf="showMenu">
      <ngx-header></ngx-header>
    </nb-layout-header>

    <nb-sidebar *ngIf="showMenu" class="menu-sidebar">
      <uruk-menu></uruk-menu>

      <div class="app-version">
        <a class="logo" [href]="keycloakRealmId">
          <img class="header-logo" src="assets/images/aselsan-white.png">
        </a>
        <div (click)="openReleaseNotes()"> URUK v{{ env.version }}</div>
      </div>
    </nb-sidebar>

    <nb-layout-column>
      <div class="column-content">
        <router-outlet></router-outlet>
      </div>
    </nb-layout-column>
  </nb-layout>
</ng-container>
