import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export type SelectWidthFormatterFunction<T = any> = (options: any[]) => string[];

/**
 * Directive to adjust the width of nb-select button to the widest option.
 */
@Directive({
  selector: '[selectWidth]',
})
export class SelectWidthDirective {

  constructor(private renderer: Renderer2,
              private translateService: TranslateService,
              public elementRef: ElementRef) {
  }

  // the list of options
  _options: any[];

  get options(): any[] {
    return this._options;
  }

  @Input() set options(options: any[]) {
    this._options = options ? options : [];
    this.setWidth();
  }

  // function to format given options
  _formatter: SelectWidthFormatterFunction = (options: any[]) => options;

  get formatter(): SelectWidthFormatterFunction {
    return this._formatter;
  }

  @Input() set formatter(f: SelectWidthFormatterFunction) {
    this._formatter = f;
    // use the translated value of options to calculate their widths since we have the formatter
    this.setWidth(true);
  }

  /**
   * Sets the width of select button for nb-select component.
   * @param translate whether the options should be translated
   * */
  private setWidth(translate = false) {
    let formattedOptions = this.formatter(this.options);
    if (translate) {
      formattedOptions = formattedOptions.map(option => this.translateService.instant(option));
    }
    const maxOptionLength = this.getWidestOptionLength(formattedOptions);
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${maxOptionLength}rem`);
  }

  /**
   * Returns the length of widest option.
   * @param options the list of options
   * */
  private getWidestOptionLength(options: any[]): number {
    let max = 0;
    options.forEach(option => {
      const len = option.length + 1;
      max = len > max ? len : max;
    });
    return max;
  }

}
