import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth/auth.service';
import {PreloaderService} from '../services/preloader.service';

/**
 * A service that tells the router whether or not it should allow navigation to a requested route.
 * Credit: https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3
 */
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public router: Router, public authService: AuthService, public preloaderService: PreloaderService) {
  }

  canActivate(): boolean {
    // Check if the user is authenticated or not. If authenticated, allow user to visit to requested route.
    // Otherwise, navigate user to the authentication page
    if (!this.authService.isAuthenticated()) {
      this.delayAuthentication();
      return false;
    }
    return true;
  }

  delayAuthentication() {
    if (this.preloaderService.shouldDisplayVideo()) {
      setTimeout(this.delayAuthentication.bind(this), 500)
    } else {
      this.authService.authenticate();
    }
  }
}
