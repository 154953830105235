import {Panel} from './visualization/panel.model';
import {TemplateType} from '../enums/page-type.enum';
import {Duration} from './generic/duration.model';
import {BaseMetaModel} from './base/base-meta-model.model';
import {Type} from '@angular/core';
import {MenuItem} from './menu/menu-item';
import {environment} from '../../../environments/environment';
import {Persistable} from './persistanble';
import {MapSettings} from './visualization/map-settings.model';
import {TimeUtil} from '../utils/time-util';

/**
 * Pages are entities that are composed of panels and layers to be display data in varying visualizations types. While layers are rendered
 * on maps, panels are containers each of which present a specific metric i.e. KPI.
 */
export class Page extends BaseMetaModel implements Persistable {

  /**
   * Title of the page
   */
  title: string;

  /**
   * Page icon
   */
  icon: string;

  /**
   * Template
   */
  templateType: TemplateType;

  /**
   * Reference to layers to be displayed in this page
   */
  layerIds: string[];

  /**
   * Panels located in the left-hand side of the page
   */
  panels?: Panel[] = [];

  /**
   * Backward date range in history from current time e.g. 3m (3 months) or 3y (3 years)
   */
  defaultTemporalPeriod: string;

  /**
   * Duration that is used that defines the creation date of data items to be displayed in this page
   */
  defaultTemporalDuration: Duration;

  /**
   * If this is a personal page (only specific to a user), userId of owner
   * */
  owner?: string;

  /**
   * Popup page settings in JSON format
   * */
  popupSettings?: any;

  /**
   * Map settings for different map frameworks
   * */
  mapSettings?: MapSettings;

  // UI-specific fields

  /**
   * Angular type of the page
   */
  componentType: Type<any>;

  constructor(data?: any) {
    super(data);

    if (!data) {
      return;
    }

    this.title = data.title;
    this.icon = data.icon;
    this.templateType = data.templateType;
    this.layerIds = data.layerIds;
    this.owner = data.owner;
    if (data.panels) {
      this.panels = data.panels.map(item => new Panel(item));
    }
    if (data.defaultTemporalPeriod) {
      this.defaultTemporalPeriod = TimeUtil.validatePeriodUnit(data.defaultTemporalPeriod);
    }
    if (data.defaultTemporalDuration) {
      this.defaultTemporalDuration = new Duration(data.defaultTemporalDuration);
    }
    if (data.mapSettings) {
      this.mapSettings = new MapSettings(data.mapSettings);
    }
    if (data.popupSettings) {
      this.popupSettings = data.popupSettings;
    }
  }

  /**
   * Creates a menu item from the given page
   */
  public createMenuItem(): MenuItem {
    return new MenuItem({
      title: this.title,
      icon: {
        icon: this.icon,
        pack: 'fa'
      },
      link: `/${environment.routes.pages}/${this.id}`
    });
  }

  createPersistableObject(): any {
    const {panels, componentType, ...rest} = this;
    rest['panels'] = panels.map(panel => panel.createPersistableObject());
    return rest;
  }
}
