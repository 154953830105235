import {Component, EventEmitter, Injector, Input, OnDestroy, Output} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base.component';
import {Layer} from '../../../../shared/models/layer.model';

/**
 * The default template for pages module. It is used when the active user has no page.
 * */
@Component({
  selector: 'layer-thumbnail',
  templateUrl: './layer-thumbnail.component.html',
  styleUrls: ['./layer-thumbnail.component.scss']
})
export class LayerThumbnailComponent extends BaseComponent implements OnDestroy {
  @Input() layer: Layer;
  @Input() displayMode: 'view' | 'edit' = 'view';
  @Input() active = false;
  @Input() hidden = false;
  @Output() onIconClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDeleteClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDestroy: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onDestroy.emit();
  }

  handleIconClick(): void {
    this.onIconClicked.emit();
  }

  handleDeleteClick(): void {
    this.onDeleteClicked.emit();
  }

  getIconPage(): string {
    return this.layer.iconPage ? this.layer.iconPage : 'fa';
  }

  getTooltip(): string {
    return this.layer.layerDisplayName ? this.layer.layerDisplayName : this.layer.name;
  }
}
