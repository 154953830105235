import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {KPIService} from '../../../core/services/meta/kpi.service';
import {ChartComponent} from '../chart/chart.component';
import {BaseComponent} from '../base.component';
import {KPI} from '../../models/kpi.model';
import {Panel} from '../../models/visualization/panel.model';
import {ChartType} from '../../enums/chart-type.enum';
import {Observable} from 'rxjs';
import {NGSIResult} from '../../models/ngsi/ngsi-result';
import {environment} from '../../../../environments/environment';
import {EventService} from '../../../core/services/event.service';
import {BucketResult} from '../../models/schema/aggregation/bucket-result';
import {AggregationResult} from '../../models/schema/aggregation/aggregation-result';
import {ObjectUtil} from '../../utils/object-util';
import {PanelContextData} from '../../models/report/panel-context-data.model';
import {DataExtractionUtil} from '../../utils/data-extraction-util';
import {DataFormatUtil} from '../../utils/data-format-util';
import {VisualizationMode} from '../../enums/visualization-mode.enum';
import {AnimationOptions} from 'ngx-lottie';
import {NGSIEventType, Notification} from '../../models/notification.model';
import {NGSIResultUtil} from '../../utils/ngsi-result-util';
import {MLModelConfig} from '../../models/analytics/mlmodel-config.model';
import {MLModel} from '../../models/analytics/mlmodel.model';
import {AnalyticsService} from '../../../core/services/meta/analytics.service';

/**
 * Base chart component to handle interactive charts based on echarts library
 */
@Component({
  selector: 'uruk-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent extends BaseComponent implements OnInit, OnDestroy {

  // Reference to the chart component
  @ViewChild('chart') chartComponent: ChartComponent;
  @ViewChild('chart', {read: ElementRef}) chart: ElementRef;

  // The identifier of page to which panel belongs
  @Input() pageId: string;
  // Panel object that this component displays
  @Input() panel: Panel;

  // whether the panel title is visible
  @Input() displayTitle: boolean = true;
  // whether the panel settings are visible
  @Input() displayPanelSettings: boolean = true;
  // whether the panel is editable
  @Input() editMode: boolean = false;
  // Visualization mode for the panel. Check VisualizationMode enum for more details.
  @Input() visualizationMode: VisualizationMode = VisualizationMode.DOM;
  // The minimum viewport value when the visualization mode is "Dialog"
  @Input() dialogViewportMin: number;
  // emits events to notify parent component about the panel deletion request
  @Output() onPanelDelete: EventEmitter<void> = new EventEmitter<void>();
  // emits events to notify parent component about the panel update request
  @Output() onPanelUpdate: EventEmitter<void> = new EventEmitter<void>();

  // The kpi that is displayed by this panel
  kpi: KPI;

  // The analytics model that is displayed by this panel
  modelConfig: MLModelConfig;
  model: MLModel;

  // The observable that will be listened for kpi execution results
  kpiExecution: Observable<NGSIResult[]>;

  // Raw results of KPI execution
  data: NGSIResult[];

  // reference to the ChartType enum
  ChartType = ChartType;

  // flags
  loading = false; // indicates a kpi execution is in progress
  empty = false; // indicates a kpi execution result is an empty set

  lottieOptions: AnimationOptions = {
    path: '/assets/animation/uruk-white-5sec.json',
  };

  // service definitions
  protected kpiService: KPIService;
  protected analyticsService: AnalyticsService;
  protected dataExtractionUtil: DataExtractionUtil;

  constructor(protected injector: Injector) {
    super(injector);

    this.kpiService = injector.get(KPIService);
    this.analyticsService = injector.get(AnalyticsService);
    this.dataExtractionUtil = injector.get(DataExtractionUtil);
  }

  ngOnInit(): void {
    this.loading = true;

    // if this is a KPI panel, get KPI definition and execute it
    if (this.isKpiPanel()) {
      this.getKPI();
    }
    // otherwise, this is an analytics panel and get the ML model
    else if (this.isAnalyticsPanel()) {
      this.getAnalyticsModel( () => {

        // execute prediction
        this.executePrediction();
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    // remove the kpi execution from the execution pool
    this.kpiExecutionService.removeKpiExecution(this.panel.ngsiContext);
  }

  /**
   * Retrieves the KPI definition from the server
   */
  getKPI() {
    this.kpiService.getKPI(this.panel.ngsiContext.kpiId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(kpi => {
        this.kpi = kpi;

        if(this.kpi.overrideCompanyQuery){
          this.panel.ngsiContext.companyQuery = "*";
          if(this.userService.isRegularUser()){
            this.panel.ngsiContext.companyQuery = this.userService.companyName.value;
          }
        }

        // register for kpi executions
        this.kpiExecution = this.kpiExecutionService.registerKpiExecution(this.panel.ngsiContext, kpi);

        // subscribe to kpi execution and context changes
        this.handleContextEventSubscriptions();

        // subscribe to the other events
        this.handleEventSubscriptions();

        // subscribe to websocket if any
        if (environment.subscriptions.enabled) {
          this.subscribeToWebsocket();
        }

        if (this.shouldTriggerKpiExecution()) {
          this.kpiExecutionService.triggerKpiExecution(this.panel.ngsiContext);
        }
      }, error => {
        this.loading = false;
      });
  }

  /**
   * Retrieves the ML Model definition from the server
   */
  getAnalyticsModel(onComplete) {
    // get model ML model from server
    const modelId = this.panel.ngsiContext.mlModelConfig.mlModelId;
    this.analyticsService.getModelConfig(modelId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(model => {
        this.model = model;

        // get kpi definition to extract valuable information based on series/dimension information
        const kpiId = this.model.config.kpiIds[this.model.config.kpiIds.length - 1];
        this.kpiService.getKPI(kpiId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(kpi => {
            this.kpi = kpi;

            if (onComplete) {
              return onComplete();
            }
          });
      }, error => {
        console.error(error);
        this.loading = false;
      });
  }

  /**
   * Executes a prediction for given ML model and model instance
   */
  executePrediction() {
    this.loading = true;
    this.analyticsService.predict(this.model, this.panel.ngsiContext.mlModelConfig.modelInstance)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        // set data and check if it is empty or not
        this.data = this.contextService.parseNgsiResponse(result, this.kpi);
        this.empty = this.areNgsiResultsEmpty(this.data);

        this.setChartData();
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  /**
   * Subscribes to the subscription specified in the panel's NGSI context via websocket
   * @private
   */
  private subscribeToWebsocket(): void {
    if (this.panel.ngsiContext.subscriptionId) {
      this.socketService.subscribe(this.panel.ngsiContext.subscriptionId).subscribe(notification => {
        this.websocketCallback(notification);
      });
    }
  }

  /**
   * Checks whether the kpi associated to this panel should be executed
   * @private
   */
  private shouldTriggerKpiExecution(): boolean {
    // trigger kpi execution if:
    // 1) the kpi does not override geographical and temporal queries. Otherwise, the KPI
    // execution will automatically be triggered by respective subscriptions
    // 2) panel-specific geo or temporal query is specified
    return this.kpi && (
      (!!this.panel.ngsiContext.geoQuery || !!this.panel.ngsiContext.temporalQuery) ||
      (!this.kpi.overrideGeoQuery && !this.kpi.overrideTemporalQuery)
    );
  }

  /**
   * Subscribes to NGSI context change events
   */
  handleContextEventSubscriptions() {
    // subscribe to kpi execution
    this.kpiExecution.pipe(takeUntil(this.destroy$))
      .subscribe(ngsiResults => {
        this.loading = false;

        // set data and check if it is empty or not
        this.data = ngsiResults;
        this.empty = this.areNgsiResultsEmpty(ngsiResults);

        this.setChartData();
      }, error => {
        this.loading = false;
        this.empty = true;
      });

    // subscribe to geographical changes if the kpi overrides geographical queries
    if (this.kpi && this.kpi.overrideGeoQuery) {
      this.contextService.geographicalContext
        .pipe(takeUntil(this.destroy$), debounceTime(environment.timeouts.debounceTimes.geologicalQueries))
        .subscribe(_ => {
          this.kpiExecutionService.triggerKpiExecution(this.panel.ngsiContext);
        });
    }

    // subscribe to temporal changes if the kpi overrides temporal queries
    if (this.kpi && this.kpi.overrideTemporalQuery) {
      this.contextService.temporalContext
        .pipe(takeUntil(this.destroy$))
        .subscribe(_ => {
          this.kpiExecutionService.triggerKpiExecution(this.panel.ngsiContext);
        });
    }
  }

  /**
   * Subsribes to EventService events
   */
  handleEventSubscriptions(): void {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.PAGE_REPORT_GENERATED:
            this.handleExportReportIconClick(false);
            break;
          case EventService.PANEL_UPDATED:
            if (event.data.panelLocation === this.panel.panelLocation) {
              this.setChartData();
            }
            break;
          case EventService.PANEL_RESIZED:
            if(event.data.panelLocation === this.panel.panelLocation && event.data.pageId === this.pageId && this.chartComponent) {
              this.chartComponent.onResize();
            }
            break;
        }
      });
  }

  /**
   * Callback to be called when a notification is received. For "create" notifications a new item is added to the map. For "update" notifications, an existing item on the map
   * is updated based on the update content.
   * @param notification
   * @private
   */
  private websocketCallback(notification: Notification): void {
    let entity: NGSIResult = null;
    if (notification.eventClass === 'NGSIEvent') {
      if (notification.eventPayload.eventType === NGSIEventType.CREATE) {
        entity = NGSIResultUtil.mapEntity(notification.eventPayload.content);
        this.data = this.data.concat([entity]);

      } else if (notification.eventPayload.eventType === NGSIEventType.UPDATE_TEMPORAL) {
        const entityIndex = this.data.findIndex(e => e.getEntityId() === notification.entityId);
        if (entityIndex !== -1) {
          NGSIResultUtil.mergeTemporalUpdate(this.data[entityIndex].getEntityResult(), notification);
          this.data = [].concat(this.data);
        }
      }
    }
    this.setChartData();
  }

  /**
   * Sets the data for chart component so that it can display results.
   * */
  private setChartData() {
    // display the result within a chart, if the result is not empty
    if (!this.empty) {
      // chart component may not be available yet; i.e., it will be available in the next cycle when wrapped within setTimeout
      setTimeout(() => {
        this.chartComponent.setData(this.data);
      });
    }
  }

  /**
   * Callback for export icon click
   */
  handleExportReportIconClick(eventOnClick: boolean = true): void {
    const panelReportData: PanelContextData = new PanelContextData();
    panelReportData.pageId = this.pageId;
    panelReportData.panelTitle = this.kpi.name;
    panelReportData.data = this.chartComponent ? this.dataExtractionUtil.extractData(this.kpi, this.chartComponent.chartSettings, this.data)[0] : [];
    DataFormatUtil.formatReportData(panelReportData.data);

    this.eventService.broadcastPanelReportDataSentEvent(panelReportData, eventOnClick);
  }

  /**
   * Export chart as PNG file
   */
  handleExportImageIconClick() {
    this.chartComponent.exportImage();
  }

  /**
   * Export chart data as CSV file
   */
  handleExportCsvIconClick() {
    this.chartComponent.exportCSV();
  }

  /**
   * Determines whether given set of NGSI results has at least one non-null value
   */
  areNgsiResultsEmpty(ngsiResults: NGSIResult[]) {
    if (ngsiResults && ngsiResults.length > 0) {
      const first = ngsiResults[0];

      if (first.result instanceof BucketResult) {
        const nonEmptyResults = ngsiResults.filter(ngsiResult => {
          const bucketResult = ngsiResult.result as BucketResult;
          return !bucketResult.isEmpty();
        });
        return nonEmptyResults.length === 0;
      } else if (first.result instanceof AggregationResult) {
        const aggregationResult = first.result as AggregationResult;
        const aggregationValue = ObjectUtil.getFirstValue(aggregationResult.result);
        return aggregationValue === undefined || aggregationValue === null;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * Emits an event for panel deletion.
   * */
  deletePanel() {
    this.onPanelDelete.emit();
  }

  /**
   * Emits an event for panel update.
   * */
  editPanel() {
    this.onPanelUpdate.emit();
  }

  /**
   * Determines whether this panel has and executes a KPI
   */
  isKpiPanel() {
    return this.panel.ngsiContext.kpiId !== undefined && this.panel.ngsiContext.kpiId !== null;
  }

  /**
   * Determines whether this panel has and executes an analytics (AKA a prediction)
   */
  isAnalyticsPanel() {
    return this.panel.ngsiContext.mlModelConfig !== undefined && this.panel.ngsiContext.mlModelConfig !== null;
  }
}
