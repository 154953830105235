import {GeoJson} from './geo-json.model';

export class Polygon extends GeoJson {

  /**
   * The coordinates of polygon
   * */
  coordinates: any[];

  constructor(data?) {
    super(data);

    this.type = 'Polygon';

    if (!data) {
      return;
    }

    this.coordinates = data.coordinates;
  }
}
