import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * Displays the breadcrumb for the pages.
 * */
@Component({
  selector: 'breadcrumb',
  styleUrls: ['breadcrumb.component.scss'],
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {
  @Input() title: string;
  /**
   * Keeps the breadcrumb levels in the following format:
   * {
   *   "title": "title", // level title
   *   "class": "css-class" // css class applied to the title div. It can be a list of classes separated by space
   * }
   * */
  @Input() levels: any[];
  @Input() displayLevels: boolean;
  @Input() displayCreateButton: boolean;
  @Input() createButtonClass: string;
  @Input() levelsClass: string;
  @Input() displayTooltip: boolean = false;
  @Output() onCreateClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLevelSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() openTooltip: EventEmitter<any> = new EventEmitter<any>(); 
  // whether the notifications' tooltip should be displayed
  public displayNotifications: boolean = false;

  /**
   * Handles the level selection.
   * @param level the level
   */
  public selectLevel(level){
    this.onLevelSelected.emit(level);
  }
}

