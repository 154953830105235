export class BrakeModel {
  bp: any;
  mrp: any;
  pressureGovernorPBReleased: any;
  pressureGovernorDetectionEmergencyBrake: any;
  pressureGovernorIndirectBrakeControl: any;
  diagBrDirect: any;
  diagBrDirectList: any[];
  majorBrPark: any;
  majorBrParkList: any[];
  majorBrWsp: any[];
  ebcu: any[];
  diagBrCPress: any;
  emergencyStatusBrakeLed: any;

  constructor(data: any) {
    this.bp = data.bp;
    this.mrp = data.mrp;
    this.pressureGovernorPBReleased = data.pressureGovernorPBReleased;
    this.pressureGovernorDetectionEmergencyBrake = data.pressureGovernorDetectionEmergencyBrake;
    this.pressureGovernorIndirectBrakeControl = data.pressureGovernorIndirectBrakeControl;
    this.diagBrDirect = data.diagBrDirect;
    this.diagBrDirectList = data.diagBrDirectList;
    this.majorBrPark = data.majorBrPark;
    this.majorBrParkList = data.majorBrParkList;
    this.majorBrWsp = data.majorBrWsp;
    this.ebcu = data.ebcu;
    this.diagBrCPress = data.diagBrCPress;
    this.emergencyStatusBrakeLed = data.emergencyStatusBrakeLed;
  }
}
