import {Component, Input} from '@angular/core';
import { NbDialogRef, NbLayoutModule, NbThemeModule } from '@nebular/theme';

@Component({
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent {
  @Input() body: string;
  @Input() title: string;
  @Input() id: number;

  constructor(protected dialogRef: NbDialogRef<EditDialogComponent>) {
  }

  cancel() {
    this.dialogRef.close({id: -1});
  }

  submit(object) {
    this.dialogRef.close(object);
  }
}
