import {Component, Injector, Input} from '@angular/core';
import {BaseComponent} from '../base.component';
import {MapFramework, MapTileType} from '../../enums/map-framework.enum';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'drawing-toolbox',
  templateUrl: './drawing-toolbox.component.html',
  styleUrls: ['./drawing-toolbox.component.scss']
})
export class DrawingToolboxComponent extends BaseComponent {

  @Input() enableDrawingCircles = true;

  // flag keeping the show/hide status of the drawing functions
  showDrawingFunctions = false;

  showLayerSelections = false;

  // class reference
  MapFramework = MapFramework;
  MapTileType = MapTileType;
  env = environment

  constructor(injector: Injector) {
    super(injector);
  }

  onDrawCircleClicked(): void {
    this.eventService.broadcastDrawCircleEvent();
  }

  onDrawRectangleClicked(): void {
    this.eventService.broadcastDrawRectangleEvent();
  }

  onDrawPolygonClicked(): void {
    this.eventService.broadcastDrawPolygonEvent();
  }

  onClearDrawingsClicked(): void {
    this.eventService.broadcastDrawRemoveEvent();
  }

  onChangeMapType(mapType: MapTileType): void {
    this.eventService.broadcastMapTypeChangedEvent(mapType);
  }

  protected readonly environment = environment;
}
