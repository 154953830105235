import { Component } from '@angular/core';
import { ReleaseNotesDialogComponent } from '../dialog/release-notes-dialog.component';
import { BaseComponent } from '../base.component';

// TODO: dummy notifications to be removed
export const NOTIFICATIONS = [
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.22',
    type: 'RELEASE_NOTES',
    date: '25.09.2024-14:48',
    message: 'Versiyon güncelleme çalışması 25.09.2024 tarihinde tamamlanmıştır. Yeni versiyon v1.0.22 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Hata kayıtları tamamlanmıştır.',
      'Innotrans ekranları yapıldı.',
      'Innotrans için svg renklendirmesi yapıldı']
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.21',
    type: 'RELEASE_NOTES',
    date: '09.09.2024-09:10',
    message: 'Versiyon güncelleme çalışması 09.09.2024 tarihinde tamamlanmıştır. Yeni versiyon v1.0.21 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Hata kayıtları tamamlanmıştır.',
      'EKG verisi olmayan defibrilatörlerde popup ekranında Vakalar tabı gözükmeyecek.',
      'Defibrilatör verilerinin çoklanma değişikliği yapılmıştır']
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.20',
    type: 'RELEASE_NOTES',
    date: '05.07.2024-09:10',
    message: 'Versiyon güncelleme çalışması 05.07.2024 tarihinde tamamlanmıştır. Yeni versiyon v1.0.20 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Hata kayıtları tamamlanmış. Bordo Katman değişikliği yapılmıştır.']
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.19',
    type: 'RELEASE_NOTES',
    date: '21.05.2024-14:45',
    message: 'Versiyon güncelleme çalışması 21.05.2024 tarihinde tamamlanmıştır. Yeni versiyon v1.0.19 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Cihaz Yönetimi ekranı değişiklikleri yapılmıştır.']
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.18',
    type: 'RELEASE_NOTES',
    date: '19.01.2023-16:00',
    message: 'Versiyon güncelleme çalışması 19.01.2023 tarihinde tamamlanmıştır. Yeni versiyon v1.0.18 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Nesne/veri tipi yönetim sayfaları yeni tasarıma uygun olarak güncellendi.',
      'Sunucudan gelen hata mesajlarının kullanıcıya popup olarak gösterilmesi sağlandı.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.17',
    type: 'RELEASE_NOTES',
    date: '10.01.2023-15:00',
    message: 'Versiyon güncelleme çalışması 10.01.2023 tarihinde tamamlanmıştır. Yeni versiyon v1.0.17 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Coğrafi bölge yönetim sayfası yeni tasarıma uygun olarak güncellendi.',
      'Sayfa şablonları yönetim sayfasında iyileştirmeler yapıldı.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.16',
    type: 'RELEASE_NOTES',
    date: '05.01.2023-14:00',
    message: 'Versiyon güncelleme çalışması 05.01.2023 tarihinde tamamlanmıştır. Yeni versiyon v1.0.16 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Alt alan ve idari bölge yönetim sayfaları yeni tasarıma uygun olarak güncellendi.',
      'İdari bölge için logo yükleme özelliği eklendi.',
      'Veri analitiği sayfası eklendi.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.15',
    type: 'RELEASE_NOTES',
    date: '27.12.2022-16:00',
    message: 'Versiyon güncelleme çalışması 27.12.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.15 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Coğrafi bölge ve gösterge sorguları yönetim sayfalarındaki çeşitli hatalar giderildi.',
      'Çeşitli ekranlarda iyileştirmeler yapıldı.',
      'Raporlama sayfasında validasyonlar eklendi.',
      'Nesne raporlama özelliği eklendi.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.14',
    type: 'RELEASE_NOTES',
    date: '28.11.2022-16:00',
    message: 'Versiyon güncelleme çalışması 28.11.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.14 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Harita üzerindeki nesnelerin sorgulanmasını sağlayan özellik eklendi.',
      'Araçlar için tooltip eklendi.',
      'Otoparkların gösterimi için yeni ikonlar eklendi.',
      'Panel üzerine gelindiğinde, kullanılan gösterge sorgusunun detaylarının gösterilmesi sağlandı.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.13',
    type: 'RELEASE_NOTES',
    date: '15.11.2022-12:00',
    message: 'Versiyon güncelleme çalışması 15.11.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.13 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: ['Harita sunucusuna giden isteklerden Authorization headerı kaldırıldı.',
      'Merkezi belirtilmemiş haritalar için idari bölgenin merkezi dikkate alındı.',
      'Panellere eklenebilen gösterge sorgularına sınırlama getirildi.',
      'Alarm sayfasında ve uygulama menüsünde iyileştirmeler yapıldı.',
      'Nesne/veri tipi ve bağlamsal veri kaynakları yönetim ekranlarına validasyonlar eklendi.',
      'İdari bölge ve alt alan yönetim sayfalarında hata mesajlarının kullanıcılara sunulması sağlandı.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.12',
    type: 'RELEASE_NOTES',
    date: '24.10.2022-17:00',
    message: 'Versiyon güncelleme çalışması 24.10.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.12 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'Coğrafi bölge ve gösterge sorguları yönetim sayfalarındaki çeşitli buglar düzeltildi.',
      'Raporlar sayfasına filtreleme ve sıralama özellikleri eklendi.',
      'Çeşitli arayüzlerde iyileştirmeler yapıldı.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.11',
    type: 'RELEASE_NOTES',
    date: '06.10.2022-16:00',
    message: 'Versiyon güncelleme çalışması 06.10.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.11 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: [ 'İdari bölge ve katman yönetim sayfalarındaki çeşitli buglar düzeltildi.',
      'Menüye raporlar sayfası eklendi.'
    ]
  },
  {
    shortTitle: 'Versiyon güncellemesi',
    title: 'Versiyon güncellemesi - v1.0.10',
    type: 'RELEASE_NOTES',
    date: '27.09.2022-16:00',
    message: 'Versiyon güncelleme çalışması 27.09.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.10 sürümü aşağıdaki değişiklikleri içermektedir:',
    items: ['Gösterge sorguları sayfası yeni tasarıma uygun olarak güncellendi.',
      'Araçlar için yeni bir katman eklendi',
      'CEP servisinin oluşturuğu alarmlar arayüze entegre edildi'
    ]
  },
  {
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.9',
  type: 'RELEASE_NOTES',
  date: '08.09.2022-17:00',
  message: 'Versiyon güncelleme çalışması 08.09.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.9 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['KPI Editörün çeşitli adımlarına validasyon eklendi.',
    'Alarmlar sayfasına ait tablonun bazı kolonlarına sıralama özelliği eklendi.',
    'Alarmlar sayfasındaki filtrelerin çalışmasını engelleyen hatalar düzeltildi.',
    'Kullanıcı yönetimi ekranındaki parola politikasıyla ilgili hatalar düzeltildi.']
},{
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.8',
  type: 'RELEASE_NOTES',
  date: '05.08.2022-13:00',
  message: 'Versiyon güncelleme çalışması 05.08.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.8 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Hava kirliliği diyaloğu istasyon ismini gösterecek şekilde güncellendi.',
    'Hava kirliliği sayfasına biri Copernicus diğeri de Çevre ve Şehircilik Bakanlığı verilerini gösteren katmanlar eklendi.',
    'Paneller Cross aggregation tipi gösterge sorgularını destekleyecek şekilde güncellendi.',
    'Abonelik sorgularında kullanılmak üzere websocket entegrasyonu tamamlandı.']
},{
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.7',
  type: 'RELEASE_NOTES',
  date: '25.07.2022-17:00',
  message: 'Versiyon güncelleme çalışması 25.07.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.7 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Gösterge sorguları sayfasındaki çeşitli hatalar düzeltildi ve iyileştirilmeler yapıldı.',
  'Kullanıcılara sayfalarının menüdeki yerlerini değiştirebilmelerini sağlayan özellik eklendi.']
},{
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.6',
  type: 'RELEASE_NOTES',
  date: '29.06.2022-17:00',
  message: 'Versiyon güncelleme çalışması 29.06.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.6 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Gösterge sorguları ve coğrafi bölge yönetim sayfalarındaki çeşitli hatalar düzeltildi ve iyileştirilmeler yapıldı.',
    'Hava kirliliği popup sayfası istasyon ismini gösterecek şekilde güncellendi.',
    'Otopark ve caddeüstü otoparkların aynı popup sayfasını kullanması sağlandı.',
    'Bütün ölçümlerin PNG ve CSV olarak dışa aktarılabilmesi sağlandı.']
}, {
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.5',
  type: 'RELEASE_NOTES',
  date: '22.06.2022-10:00',
  message: 'Versiyon güncelleme çalışması 22.06.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.5 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Katman ve coğrafi bölge yönetim sayfalarında iyileştirilmeler yapıldı.',
    'Caddeüstü otoparklarının gösterimi belli bir zoom seviyesinden itibaren ikon olacak şekilde güncellendi.']
}, {
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.4',
  type: 'RELEASE_NOTES',
  date: '17.06.2022-15:00',
  message: 'Versiyon güncelleme çalışması 17.06.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.4 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Katman gösterme/gizleme özelliği clusterları da gösterecek/gizleyecek şekilde güncellendi.',
    'Sayfa ve nesne/veri tipi yönetim sayfalarında iyileştirmeler yapıldı.',
    'Çeşitli problemler giderildi ve iyileştirme çalışmaları yapıldı.']
}, {
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.3',
  type: 'RELEASE_NOTES',
  date: '14.06.2022-17:00',
  message: 'Versiyon güncelleme çalışması 14.06.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.3 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Uygulama ve kullanıcı yönetim sayfaları için sayfalama (pagination) özelliği eklendi.',
    'Alarmlar sayfası birden fazla alarmın onaylanmasına/bastırılmasına izin verecek şekilde güncellendi.',
    'Sayfa şablonları yönetim sayfası oluşturuldu ve farklı şablonlarla yeni sayfalar tanımlayabilmesi sağlandı.',
    'İlgili yönetim sayfaları platform yöneticilerinin kullanımda olmayan alt alanları ve coğrafi bölgeleri silebilmesine izin verecek şekilde güncellendi.',
    'Preloader videonun gösteriminde yaşanan senkronizasyon sorunu çözüldü.',
    'Caddeüstü Otoparklar katmanı ve detaylarının gösterilmesi için diyalog sayfası hazırlandı.',
    'Çeşitli sayfalardaki mesajlar türkçeleştirildi.']
}, {
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.2',
  type: 'RELEASE_NOTES',
  date: '30.05.2022-17:00',
  message: 'Versiyon güncelleme çalışması 30.05.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.2 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Kullanıcının oturum süresi dolduğunda otomatik olarak çıkış yaptırılması sağlandı.', 'Popuplardaki bilgi alanlarının ondalık basamak sayısı bilginin tipine (tam sayı ya da ondalık sayı) göre gösterildi.', 'Popuplardaki bilgi alanlarına varsayılan birimler eklendi.', 'Hava kirliliğindeki gerçek değerlerin negatif gösterilmesi ve tüm tahmini değerlerin saydam olarak gösterilmemesi hataları düzeltildi.', 'Preloader video ve paneller için animasyonlar eklendi.', 'Çeşitli buglar çözüldü.']
}, {
  shortTitle: 'Versiyon güncellemesi',
  title: 'Versiyon güncellemesi - v1.0.1',
  type: 'RELEASE_NOTES',
  date: '16.05.2022-17:00',
  message: 'Versiyon güncelleme çalışması 16.05.2022 tarihinde tamamlanmıştır. Yeni versiyon v1.0.1 sürümü aşağıdaki değişiklikleri içermektedir:',
  items: ['Hava kirliliği popupına PM25 paneli eklendi.', 'Hava kirliliği popupında tahmini değerlerin saydam gösterilmesi, verinin alındığı gerçek tarihin gösterilmesi gibi bazı geliştirmeler yapıldı.', 'Sürüm notlarını gösteren pencere tasarıma uygun olarak güncellendi.', 'Uygulama yönetim sayfalarının liste görüntüsü tasarıma uygun olarak güncellendi.', 'Çeşitli buglar çözüldü.']
}];

/**
 * A tooltip to display available notifications
 * */
@Component({
  selector: 'notifications-tooltip',
  styleUrls: ['notifications-tooltip.component.scss'],
  templateUrl: './notifications-tooltip.component.html'
})
export class NotificationsTooltipComponent extends BaseComponent {

  public notifications = NOTIFICATIONS;

  /**
   * Opens a dialog to display given notification.
   * @param notification the notification
   * */
  public openNotification(notification) {
    // open the release notes dialog
    if (notification.type === 'RELEASE_NOTES') {
      this.dialogService.open(ReleaseNotesDialogComponent, {
        context: {
          releaseNotes: notification
        }
      });
    }
  }
}

