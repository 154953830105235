import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {BaseFormComponent} from './base-form.component';
import {SelectWidthFormatterFunction} from '../../directives/select-width.directive';

/**
 * Component for select-based form input. It is aimed to be used when there are pre-defined values that can be chosen for an input.
 */
@Component({
  selector: 'option-input',
  styleUrls: ['option-input.component.scss'],
  templateUrl: './option-input.component.html',
})
export class OptionInputComponent extends BaseFormComponent implements OnInit {
  // label for the input
  @Input() label: string;
  // possible options that can be selected
  @Input() options: any[];
  // display names for the options
  @Input() values: any[];
  // event emitter for 2-way binding on the values
  @Output() valuesChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  // name formatter to extract the display name for the options and values. By default, it just returns the given value
  @Input() nameFormatter: (value: any, flag?: boolean) => string = (x: any, flag= false) => x;
  // function to map given value to the corresponding option among the options list
  @Input() valueToOptionMapper: (value: any, options: any[]) => any = (x: any, _: any[]) => x;
  // function to extract value from the given option e.g. to get NGSI path from a PathParameter
  @Input() optionValueExtractor: (option: any) => any = (option: any) => option;
  // whether this input is a required input or not
  @Input() required: boolean = true;
  // whether this input can take multiple values or not
  @Input() multiValue = true;
  // display mode for the component
  @Input() displayMode: 'view' | 'edit' = 'view';

  // values that are modified by the user
  managedValues: any[] = [];
  // new value to be added to the managed values
  newValue: any;

  /**
   * Variables for multi-value inputs
   */
  // options which are not selected yet i.e. the ones which are not added to managed values list
  remainingOptions: any[];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    // map the given values to the corresponding options
    if (this.values?.length > 0) {
      this.managedValues = this.values.map(value => this.valueToOptionMapper(value, this.options));
    } else {
      if (this.required) {
        this.managedValues.push(this.optionValueExtractor(this.options[0]));
      }
    }
    // for the multi-value input, set the remaining options
    if(this.multiValue){
      this.setRemainingOptions();
    } 
    // for the single value input, initialize the select box with the selected value
    else if(this.managedValues.length){
      this.newValue = this.managedValues[0];
    }
  }

  validateForm(): boolean {
    if (this.required) {
      if (this.managedValues?.length === 0) {
        this.displayErrors = true;
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param value
   */
  onNewValueChanged(value: any): void {
    this.newValue = value;
    // emit event about this change only in the single-value mode
    if (!this.multiValue) {
      this.valuesChange.emit(this.getValue());
    }
  }

  /**
   * Returns the values edited in this form component
   */
  getValue(): any[] {
    if (this.multiValue) {
      return this.managedValues.map(option => this.optionValueExtractor(option));
    } else {
      return [this.optionValueExtractor(this.newValue)];
    }
  }

  /**
   * {@link SelectWidthFormatterFunction} for options.
   * */
  public optionFormatter: SelectWidthFormatterFunction = (options: any[]) => {
    return options ? options.map(option => this.nameFormatter(option)) : [];
  }

  /**
   * Methods to manage multi-value inputs
   */

  /**
   * Adds the selected value to the managed values
   */
   onAddValue(): void {
    this.managedValues.push(this.newValue);
    this.valuesChange.emit(this.getValue());
    this.setRemainingOptions();
  }

  /**
   * Callback for handling the change in existing values. Sets the new value to the provided index
   * @param index
   * @param value
   */
   onExistingValueChanged(index: number, value: any): void {
    this.managedValues[index] = value;
    this.valuesChange.emit(this.getValue());
    this.setRemainingOptions();
  }

  /**
   * Deletes the value specified with the index from the value list
   * @param index
   */
   onDeleteValue(index: number): void {
    this.managedValues.splice(index, 1);
    this.valuesChange.emit(this.getValue());
    this.setRemainingOptions();
  }

  /**
   * Sets {@link remainingOptions} which are the ones not added to {@link managedValues}.
   */
   setRemainingOptions(){
    const formattedSelectedOptions: string[] = this.managedValues.map(option => this.nameFormatter(option));
    const remainingOptions: any[] = this.options.filter(option => !formattedSelectedOptions.includes(this.nameFormatter(option)));
    this.remainingOptions = remainingOptions.length ? remainingOptions : null;
    // wrap with setTimeout to make sure that nb-select populates the options before checking the selected value. Otherwise, it does not
    // detect the selected one and leaves it empty
    setTimeout(() => {
      this.newValue = this.remainingOptions?.length ? this.remainingOptions[0]: null;
    })
  }
}

