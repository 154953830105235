/**
 * Authorization scope
 */
export class AuthScope {
  /**
   * Permission on the resource/resource group  e.g. entity.write, entity.read
   */
  permission: string[];

  /**
   * Identifier of the resource or resource type that this scope is about e.g. OffStreetParking, urn:ngsi-ld:OffStreetParking:5
   */
  resourceId: string;

  constructor(data?: any) {
    if (!data) {
      return;
    }
    this.permission = data.permission;
    this.resourceId = data.resourceId === 'uruk-entity' || data.resourceId === 'uruk-realm' || data.resourceId === '*' ? null : data.resourceId;
  }
}
