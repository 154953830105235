import {ReportEndpoint} from './report-endpoint.model';

export class ReportTarget {
  public emails: string[];
  public endpoint: ReportEndpoint;

  constructor(data?) {
    if (!data) {
      return;
    }

    this.emails = data.emails;

    if (data.endpoint) {
      this.endpoint = new ReportEndpoint(data.endpoint);
    }
  }
}
