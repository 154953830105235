import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../../../shared/components/dialog/base-dialog.component';
import {BitResults} from '../../../../../../shared/models/bitResults.model';

@Component({
  templateUrl: './jkgysTak-bitResultdialog.component.html',
  styleUrls: ['./jkgysTak-bitResultdialog.component.scss']
})

export class JkgysTakBitResultdialogComponent extends BaseDialogComponent implements OnInit {

  statusMap: string[] = ['Bağlantı Yok', 'Aktif', 'Pasif', 'Düzgün Çalışmıyor', 'Bilinmeyen Cihaz'];
  hasPassive: boolean = false;
  headerDescription: string;
  headerId: string;
  description: string;

  @Input() bitResults;
  @Input() atuGekoId: string;
  @Input() atuOrGeko: string;
  @Input() atuGekoDescription: string;

  bitResultsMap = new Map<string, BitResults[]>();
  isCollapsedMap = new Map<string, boolean>([]);
  atuGekoBitResults: any[] = [];
  bitIdList: any[] = [];

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.description = this.atuGekoDescription;
    this.headerDescription = this.atuOrGeko;
    this.headerId = this.atuGekoId;
    for (let i = 0; i < this.bitResults.length; i++) {
      const result = this.bitResults[i].result.entity.bitResult.value;
      let bitId = this.bitResults[i].result.entity.bitId.value;
      const deviceId = this.bitResults[i].result.entity.deviceId.value;

      if (bitId !== 0) {
        if (result !== 1) {
          this.hasPassive = true;
        }

        if (deviceId === 0) {
          bitId = '0.' + bitId;
        }

        const bitInformation: { result: any; description: string; bitId: any } = {
          description: 'jkgys-' + bitId + '-' + deviceId,
          result: result,
          bitId: bitId
        };
        this.atuGekoBitResults.push(bitInformation);
        if (!this.bitIdList.includes(bitId)) {
          this.bitIdList.push(bitId);
        }
        if (!this.isCollapsedMap.has(bitId)) {
          this.isCollapsedMap.set(bitId, true);
        }
      }
      this.atuGekoBitResults.sort((a, b) => {
        if (a.description < b.description) return -1;
        if (a.description > b.description) return 1;
        return 0;
      });

      this.bitIdList.sort((a, b) => a - b);

      for (let k = 0; k < this.bitIdList.length; k++) {
        const partialList: BitResults[] = [];
        for (let j = 0; j < this.atuGekoBitResults.length; j++) {
          if (this.atuGekoBitResults[j].bitId === this.bitIdList[k]) {
            partialList.push(this.atuGekoBitResults[j]);
          }
          this.bitResultsMap.set(this.bitIdList[k], partialList);
        }
      }
    }
  }

  setVisibility(key: string) {
    this.isCollapsedMap.set(key, !this.isCollapsedMap.get(key));
  }
  hasError(key: string) {
    const list: BitResults[] = this.bitResultsMap.get(key);

    for (let j = 0; j < list.length; j++) {
      if (list[j].result !== 1) {
        return true;
      }
    }
    return false;
  }

}
