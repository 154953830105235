<div class="header-container">
  <div class="logo-container">
    <div>
      <a (click)="toggleSidebar()" [href]="keycloakRealmId">
        <nb-icon icon="menu-outline" pack="eva"></nb-icon>
      </a>
    </div>
    <div>
      <a  [hidden]="!isSidebarExpanded" class="logo" [href]="keycloakRealmId">
        <img class="header-logo" [src]="realmLogoSrc">
      </a>
    </div>
  </div>
  <!--
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  -->
</div>

<div class="header-container">
  <!--
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
    </nb-action>
  </nb-actions>
  -->
</div>
