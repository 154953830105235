import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {BaseFormComponent} from '../form/base-form.component';
import {EntityResult} from '../../models/schema/entity-result';

@Component({
  template: '',
})
export abstract class BaseDialogComponent extends BaseFormComponent implements OnInit, OnDestroy {

  entity: EntityResult;

  protected dialogRef: NbDialogRef<BaseDialogComponent>;

  constructor(injector: Injector) {
    super(injector);
    this.dialogRef = injector.get(NbDialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
