<closeable-dialog class="dialog-container nb-card-class">
  <div dialog-card-header>
    <label>
      {{ title }}
    </label>
  </div>
  <div dialog-card-body>
    <i class="fa fa-close"></i>
    <!-- values are provided directly -->
    <ng-container *ngIf="!topRowPanels.length">
      <div *ngIf="topLabels.length > 0" [ngClass]="{ 'four-column': topLabels.length < 5 }" class="row">
        <div *ngFor="let label of topLabels; let i = index" class="row-item">
          <div class="h6">{{ label | translate }}</div>
          <div>{{ topValues[i] | translate}}</div>
        </div>
      </div>
    </ng-container>
    <!-- panel configurations are provided instead of static values -->
    <ng-container *ngIf="topRowPanels.length">
      <div class="row-item">
        <uruk-panel *ngFor="let panel of topRowPanels" [panel]="panel" [pageId]="page.id"></uruk-panel>
      </div>
    </ng-container>

    <div *ngIf="secondRowPanel" class="row">
      <div class="col-md-12 chart no-pad" >
        <uruk-panel [panel]="secondRowPanel" [pageId]="page.id"></uruk-panel>
      </div>
    </div>

    <div *ngIf="thirdRowPanel" class="row">
      <div class="col-md-12 chart no-pad" >
        <uruk-panel [panel]="thirdRowPanel" [pageId]="page.id"></uruk-panel>
      </div>
    </div>
  </div>

  <div dialog-card-footer>
    {{footer | translate}}
  </div>
</closeable-dialog>
