export enum ApplicationManagementMenuItemEnum {
    Pages = 'Pages',
    PageTemplates = 'Page Templates',
    Realms = 'Realms',
    Domains = 'Domains',
    Layers = 'Layers',
    Regions = 'Regions',
    KPIs = 'KPIs',
    StructureDefinitionResources = 'structure-definitions.resources',
    StructureDefinitionValues = 'structure-definitions.values',
    Patterns = 'Patterns',
    ContextSources = 'Context Sources'
}
