import {Injectable} from '@angular/core';
import {StorageUtil} from '../../shared/utils/storage-util';
import {environment} from "../../../environments/environment";

/**
 * A service to manage preloader video display
 */
@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  env = environment;
  preloaderVideoDisplayed = false;
  readonly hasSessionStorageForVideoDisplay;

  constructor() {
    this.hasSessionStorageForVideoDisplay = StorageUtil.getPreloaderDisplayed() === 'true';
    this.preloaderVideoDisplayed = this.env.preloaderDisplayed;
    // if there is a session storage for preloader display, then we need to skip the video play
    if (this.hasSessionStorageForVideoDisplay) {
      this.preloaderVideoDisplayed = true;
    }
  }

  shouldDisplayVideo() {
    return !this.hasSessionStorageForVideoDisplay && !this.preloaderVideoDisplayed;
  }
}
