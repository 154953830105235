import {LayerController} from './layer.controller';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {catchError, forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {Marker} from '../../shared/models/map/marker.model';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {NGSIEntity} from '../../shared/utils/ngsi-result-util';


export class JkgysLayerController extends  LayerController {
  displaysTooltip: boolean = true;
  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    const gendarmeStationId = result.getEntityResult().entity.id;
    let contextObservable: Observable<any> = null;

    const jemusTakQuery: NGSIQuery = new NGSIQuery({
      filter: {
        type: [NGSIEntity.JEMUSTAK] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'refId'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            gendarmeStationId
          ]
        }
      },
      limit: 1000,
      lastNDays: 365
    });

    const gendarmerieQuery: NGSIQuery = new NGSIQuery({
      filter: {
        type: [NGSIEntity.GENDARMERIESTATION] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'id'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            gendarmeStationId
          ]
        }
      },
      limit: 1000,
      lastNDays: 365
    });

    jemusTakQuery.lastNDays = 365;
    const jemusTakRef =  this.cbrokerService.getEntities(jemusTakQuery, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const gendarmerieRefQuery = this.cbrokerService.getEntities(gendarmerieQuery, new QueryOptions(false)).pipe(catchError(() => of(null)));

    contextObservable = forkJoin([jemusTakRef, gendarmerieRefQuery]).pipe(
      map(response => {
        return {
          takEntity: response[0],
          entity: response[1]
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(),
      asyncContext: contextObservable
    };
  }

  createTooltip(marker: Marker): string {
    const description = marker.entity.getSingleElementValue('description');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text">${description}</div>`;
    html += '</div';

    return html;
  }

}
