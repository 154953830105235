import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';
import {PaginationCallbackFunction} from '../../../../../shared/components/list/list.component';
import {PAGINATION_DEFAULT_PAGE_SIZE} from '../../../../../shared/components/list/pagination.component';
import {DefibrillatorTest} from '../../../../../shared/models/defibrillatorTest.model';
import {VisualizationMode} from '../../../../../shared/enums/visualization-mode.enum';
import {Panel} from '../../../../../shared/models/visualization/panel.model';
import {DefibrillatorDialogComponent} from './dialog/defibrillator-dialog.component';
import {HealthCase} from '../../../../../shared/models/healthCase.model';
import {ContextService} from '../../../../../core/services/http/context.service';
import {DefibrillatorEkgDialogComponent} from './dialog/defibrillator-ekgdialog.component';
import { DateSelectionDialogComponent } from 'app/modules/dialog/date-selection-dialog/date-selection-dialog.component';
import { EventService } from 'app/core/services/event.service';
import { UrukTemporalQuery } from 'app/shared/models/query/uruk-temporal-query.model';
import { DefibrillatorService } from 'app/core/services/defibrillator.service';
import { DefibrillatorProperty, DefibrillatorUpdatedData } from 'app/shared/models/defibrillatorUpdatedData.model';
import {UserService} from '../../../../../core/services/data/user.service';

@Component({
  selector: 'defibrillator-template',
  templateUrl: './defibrillator-template.component.html',
  styleUrls: ['./defibrillator-template.component.scss']
})

export class DefibrillatorTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {
  @Input() cBrokerEndpoint: string;
  @Input() dataRealmId: string;
  cbrokerService: ContextService;
  userService: UserService;
  // the list of defibrillator retrieved from server
  defibrillatorTests: DefibrillatorTest[] = [];
  // paginated sensors displayed in the list view
  paginatedDefibrillatorTests: DefibrillatorTest[] = [];
  paginatedHealthCases: HealthCase[] = [];
  healthCases: HealthCase[] = [];

  entity: any;

  positionEntity: any;

  subEntities: any;

  healthCasesEntities: any;

  Defibrillator: any;

  defibrillatorId: string;

  defibrillatorLocation: string;

  defibrillatorService: DefibrillatorService;

  AEDDeviceModel: number;

  AEDDeviceLCD: number;

  AEDSerialNumber: string;

  batterySerialNumber: string;

  hardwareRevisionNumber: string;

  softwareRevisionNumber: string;

  primaryLanguagePack: string;

  secondaryLanguagePack: string;

  simOperatorName: string;

  simTelNo: string;

  aedDeviceAdultShockEnergySteps: string;

  aedDevicePediatricShockEnergySteps: string;

  aedDeviceEnergyRetentionTime: number;

  aedDevicePeriodicTestInterval: number;

  aedDeviceShockProtocol: number;

  aedDeviceSoundLevel: number;

  iotModuleSerialNumber: string;

  iotModuleSoftwareRevisionNumber: string;

  iotModuleHardwareRevisionNumber: string;

  iotGsmConnectionType: number;

  iotAccelerometerTestResult: number;

  iotInternalMemoryTestResult: number;

  iotGsmSignalQualityLte: number;

  iotAedConnectionTestResult: number;

  iotGsmSignalQuality2G: number;

  adultPadExpirationDate: string;

  pediatricPadExpirationDate: string;

  guaranteeStartDate: string;

  descriptionTempQuery: UrukTemporalQuery;

  adultTempQuery: UrukTemporalQuery;

  pediatricTempQuery: UrukTemporalQuery;

  guaranteeTempQuery: UrukTemporalQuery;

  isReadOnlyMode: boolean = true;
  description: string;

  initialCompanyName: string;
  companyName: string;
  isCompanyChanged: boolean = false;
  companyNameOptions = [];

  showFooter: boolean = true;

  activeTab: string;

  dialogViewportMin: number;

  casePanel: Panel;

  PANEL_LOCATION_CASE = 'case';

  testTypeMap: string[] = ['24 Saatlik', '6 Aylık', 'Manuel Test'];

  VisualizationMode = VisualizationMode;

  defibrillatorStatus: string;
  defibrillatorStatusMessage: string;

  isBatteryErrorAED: boolean;

  connectionTypeName: string;

  signalQuality: string;

  signalQualityIcon: string;

  chargedBatteryIcon: string = 'assets/images/battery.png';

  colorSignalQuality: string;

  isLastTestTimeNotValid: boolean = true;

  showTestError: boolean = false;
  isFullAutomaticDisplay: boolean = false;
  isHalfAutomaticDisplay: boolean = false;
  isFullAutomaticNonDisplay: boolean = false;
  isHalfAutomaticNonDisplay: boolean = false;
  positionStatus: number;
  showSignalQuality: boolean = true;
  isVisibleHealthCaseTab: boolean = false;

  private pageSize = PAGINATION_DEFAULT_PAGE_SIZE;

  private defibrillatorPage = 1;

  public paginationCallback: PaginationCallbackFunction;
  signalQualityMap: string[] = ['defibrillator-critical-signal', 'defibrillator-low-signal', 'defibrillator-normal-signal', 'defibrillator-good-signal'];

  constructor(injector: Injector) {
    super(injector);
    this.cbrokerService = injector.get(ContextService);
    this.defibrillatorService = injector.get(DefibrillatorService);
    this.userService = injector.get(UserService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.companyNameOptions = this.defibrillatorService.getUserAttributes(this.dataRealmId);
    this.paginationCallback = this.setPaginatedSensors.bind(this);
    this.dialogViewportMin = Math.min(this.layoutService.getApplicationSize()[0], this.layoutService.getApplicationSize()[1]) / 4;
    this.handleEventSubscriptions();

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  handleEventSubscriptions() {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.TIME_CHANGED:
            if (event.data.dialogSource === 'adult') {
              this.adultTempQuery = event.data.timeQuery;
            } else if (event.data.dialogSource === 'pediatric') {
              this.pediatricTempQuery = event.data.timeQuery;
            } else {
              this.guaranteeTempQuery = event.data.timeQuery;
            }
            break;
          default:
            break;
        }
      });
  }

  mapPanels(): void {
    this.page?.panels.forEach(panel => {
      panel.ngsiContext.idQuery = this.entity.id;
      switch (panel.panelLocation) {
        case this.PANEL_LOCATION_CASE:
          this.casePanel = panel;
          break;
      }
    });
  }

  protected mapContextData(context: any): void {
    this.entity = context.entity.result ? context.entity.result.entity : undefined;
    this.positionEntity = context.positionEntity.result.entity;
    this.subEntities = context.subEntities ? context.subEntities.results : undefined;
    this.healthCasesEntities = context.healthCases.result.entity;
    if (this.healthCasesEntities.length > 0) {
      this.isVisibleHealthCaseTab = true;
    } else {
      this.isVisibleHealthCaseTab = false;
      const defibrillatorDialog = document.querySelector('.defibrillator-dialog-container nb-card') as HTMLElement;

      if (defibrillatorDialog) {
        defibrillatorDialog.style.width = '105vh';
      }
    }
    this.isBatteryErrorAED = false;

    this.subEntities.sort(this.compareByTestDate);

    for (let i = 0; i < this.subEntities.length; i++) {
      this.defibrillatorTests.push(new DefibrillatorTest({
        IOTModuleBatteryVoltageLevel: this.subEntities[i].result.entity.IOTModuleBatteryVoltageLevel.value / 10,
        AEDDeviceBatteryVoltageLevel: this.subEntities[i].result.entity.AEDDeviceBatteryVoltageLevel.value / 10,
        testDay: this.subEntities[i].result.entity.testDay.value,
        testMonth: this.subEntities[i].result.entity.testMonth.value,
        testYear: this.subEntities[i].result.entity.testYear.value + 2000,
        testHour: this.subEntities[i].result.entity.testHour.value,
        testMinute: this.subEntities[i].result.entity.testMinute.value,
        testType: this.calculateTestType(this.subEntities[i].result.entity.testType.value),
        adsTestResult: this.subEntities[i].result.entity.adsTestResult.value,
        ecgTestResult: this.subEntities[i].result.entity.ecgTestResult.value,
        cryptoTestResult: this.subEntities[i].result.entity.cryptoTestResult.value,
        audioTestResult: this.subEntities[i].result.entity.audioTestResult.value,
        padTestResult: this.subEntities[i].result.entity.padTestResult.value,
        chargeCircuit1TestResult: this.subEntities[i].result.entity.chargeCircuit1TestResult.value,
        chargeCircuit2TestResult: this.subEntities[i].result.entity.chargeCircuit2TestResult.value,
        capacitorVoltageTestResult: this.subEntities[i].result.entity.capacitorVoltageTestResult.value,
        hBridgeCircuit1TestResult: this.subEntities[i].result.entity.hBridgeCircuit1TestResult.value,
        hBridgeCircuit2TestResult: this.subEntities[i].result.entity.hBridgeCircuit2TestResult.value,
        onOffButtonTestResult: this.subEntities[i].result.entity.onOffButtonTestResult.value,
        shockButtonTestResult: this.subEntities[i].result.entity.shockButtonTestResult.value,
        iotGsmConnectionType: this.subEntities[i].result.entity.iotGsmConnectionType ? this.subEntities[i].result.entity.iotGsmConnectionType.value : '',
        iotAccelerometerTestResult: this.subEntities[i].result.entity.iotAccelerometerTestResult ? this.subEntities[i].result.entity.iotAccelerometerTestResult.value : '',
        iotInternalMemoryTestResult: this.subEntities[i].result.entity.iotInternalMemoryTestResult ? this.subEntities[i].result.entity.iotInternalMemoryTestResult.value : '',
        iotGsmSignalQualityLte: this.subEntities[i].result.entity.iotGsmSignalQualityLte ? this.subEntities[i].result.entity.iotGsmSignalQualityLte.value : '',
        iotAedConnectionTestResult: this.subEntities[i].result.entity.iotAedConnectionTestResult ? this.subEntities[i].result.entity.iotAedConnectionTestResult.value : '',
        iotGsmSignalQuality2G: this.subEntities[i].result.entity.iotGsmSignalQuality2G ? this.subEntities[i].result.entity.iotGsmSignalQuality2G.value : '',
        colorIOTModuleBatteryVoltageLevel: this.checkIOTBatteryColor(this.subEntities[i].result.entity.IOTModuleBatteryVoltageLevel.value / 10, i),
        colorAEDDeviceBatteryVoltageLevel: this.checkAEDBatteryColor(this.subEntities[i].result.entity.AEDDeviceBatteryVoltageLevel.value / 10, i),
        testResult: this.checkAllTest(this.subEntities[i].result.entity),
        colorADSTestResult: this.checkTestResultColor(this.subEntities[i].result.entity.adsTestResult.value),
        colorECGTestResult: this.checkTestResultColor(this.subEntities[i].result.entity.ecgTestResult.value),
        colorTestResult: this.checkAllTestColor(this.checkAllTest(this.subEntities[i].result.entity), this.subEntities[i].result.entity.padTestResult.value),
        ads4v6Result: this.subEntities[i].result.entity?.ads4v6Result?.value,
        dacSetVoltage: this.subEntities[i].result.entity?.dacSetVoltage?.value,
        chargingVoltage: this.subEntities[i].result.entity?.chargingVoltage?.value,
        chargeTime: this.subEntities[i].result.entity?.chargeTime?.value,
        hBridge1: this.subEntities[i].result.entity?.hBridge1?.value,
        hBridge2: this.subEntities[i].result.entity?.hBridge2?.value
      }));
    }
    this.defibrillatorTests.sort(this.compareByTestDate);


    this.defibrillatorId = super.getValue(this.entity, 'id').split(':')[3];
    this.defibrillatorLocation = this.positionEntity.location.value.coordinates;
    this.AEDDeviceModel = this.entity.AEDDeviceModel.value;
    this.AEDDeviceLCD = this.entity.AEDDeviceLCD.value;
    this.AEDSerialNumber = this.entity.AEDSerialNumber.value;
    this.batterySerialNumber = this.entity.batterySerialNumber ? this.entity.batterySerialNumber.value : '';
    this.hardwareRevisionNumber = this.entity.hasOwnProperty('hardwareRevisionNumber') ? this.entity.hardwareRevisionNumber.value : '';
    this.softwareRevisionNumber = this.entity.hasOwnProperty('softwareRevisionNumber') ? this.entity.softwareRevisionNumber.value : '';
    this.primaryLanguagePack = this.entity.primaryLanguagePack ? this.entity.primaryLanguagePack.value : '';
    this.secondaryLanguagePack = this.entity.secondaryLanguagePack ? this.entity.secondaryLanguagePack.value : '';
    this.simOperatorName = this.entity.simOperatorName ? this.entity.simOperatorName.value : '-';
    this.simTelNo = this.entity.simTelNo ? this.entity.simTelNo.value : '-';
    this.aedDeviceAdultShockEnergySteps = this.entity.aedDeviceAdultShockEnergySteps ? this.entity.aedDeviceAdultShockEnergySteps.value : '';
    this.aedDevicePediatricShockEnergySteps = this.entity.aedDevicePediatricShockEnergySteps ? this.entity.aedDevicePediatricShockEnergySteps.value : '';
    this.aedDeviceEnergyRetentionTime = this.entity.aedDeviceEnergyRetentionTime ? this.entity.aedDeviceEnergyRetentionTime.value : '';
    this.aedDevicePeriodicTestInterval = this.entity.aedDevicePeriodicTestInterval ? this.entity.aedDevicePeriodicTestInterval.value : '';
    this.aedDeviceShockProtocol = this.entity.aedDevicePeriodicTestInterval ? this.entity.aedDeviceShockProtocol.value : '';
    this.aedDeviceSoundLevel = this.entity.aedDeviceSoundLevel ? this.entity.aedDeviceSoundLevel.value : '';
    this.iotModuleSerialNumber = this.entity.iotModuleSerialNumber ? this.entity.iotModuleSerialNumber.value : '';
    this.iotModuleSoftwareRevisionNumber = this.entity.iotModuleSoftwareRevisionNumber ? this.entity.iotModuleSoftwareRevisionNumber.value : '';
    this.iotModuleHardwareRevisionNumber = this.entity.iotModuleHardwareRevisionNumber ? this.entity.iotModuleHardwareRevisionNumber.value : '';
    this.adultPadExpirationDate = this.entity.adultPadExpirationDate ? this.entity.adultPadExpirationDate.value : '-';
    this.pediatricPadExpirationDate = this.entity.pediatricPadExpirationDate ? this.entity.pediatricPadExpirationDate.value : '-';
    this.guaranteeStartDate = this.entity.guaranteeStartDate ? this.entity.guaranteeStartDate.value : '-';
    this.companyName = this.entity.companyName ? this.findCompanyByName(this.entity.companyName.value) : undefined;
    this.initialCompanyName = this.companyName;
    this.description = this.entity.description ? this.entity.description.value : undefined;


    if (this.AEDDeviceModel === 0 && this.AEDDeviceLCD === 0) {
      this.isHalfAutomaticNonDisplay = true;
    } else if (this.AEDDeviceModel === 0 && this.AEDDeviceLCD === 1) {
      this.isHalfAutomaticDisplay = true;
    } else if (this.AEDDeviceModel === 1 && this.AEDDeviceLCD === 0) {
      this.isFullAutomaticNonDisplay = true;
    } else {
      this.isFullAutomaticDisplay = true;
    }

    this.positionStatus = this.positionEntity.status ? this.positionEntity.status.value : -1;

    for (let i = 0; i < this.healthCasesEntities.length; i++) {
      this.healthCases.push(new HealthCase({
        healthCaseId: this.getHealthCaseId(this.healthCasesEntities[i].id),
        caseDate: this.getCaseDate(this.healthCasesEntities[i].refId.observedAt),
        caseHour: this.getCaseTime(this.healthCasesEntities[i].refId.observedAt),
        observedAt: this.healthCasesEntities[i].refId.observedAt
      }));
    }

    this.paginatedDefibrillatorTests = this.defibrillatorTests.slice(0, this.defibrillatorTests.length);
    this.paginatedHealthCases = this.healthCases.slice(0, this.healthCases.length);

    if (this.defibrillatorTests.length > 0) {
      this.checkLastTestDate(this.defibrillatorTests[0].testDay, this.defibrillatorTests[0].testMonth,
        this.defibrillatorTests[0].testYear, this.defibrillatorTests[0].testHour,
        this.defibrillatorTests[0].testMinute);

      if (this.positionEntity?.iotGsmConnectionType && (this.positionEntity?.iotGsmSignalQuality2G || this.positionEntity?.iotGsmSignalQualityLte)) {
        if (this.positionEntity.iotGsmConnectionType.observedAt > this.subEntities[0].result.entity.iotGsmConnectionType.observedAt) {
          this.checkConnectionStatusType(this.positionEntity.iotGsmConnectionType.value, this.positionEntity.iotGsmSignalQualityLte.value, this.positionEntity.iotGsmSignalQuality2G.value);
        } else {
          this.checkConnectionStatusType(this.defibrillatorTests[0].iotGsmConnectionType,
            this.defibrillatorTests[0].iotGsmSignalQualityLte, this.defibrillatorTests[0].iotGsmSignalQuality2G);
        }
      }

      this.checkDefibrillatorStatus(this.defibrillatorTests[0].testResult,
        this.defibrillatorTests[0].padTestResult, this.defibrillatorTests[0].AEDDeviceBatteryVoltageLevel, this.defibrillatorTests[0].IOTModuleBatteryVoltageLevel);

      if (this.defibrillatorTests[0].testResult === 1) {
        this.showTestError = false;
      } else {
        this.showTestError = true;
      }
    }

  }

  private findCompanyByName(companyName: string): string | undefined {
    return this.companyNameOptions.find(company => {
      // tslint:disable-next-line:prefer-const
      let [id, name] = company.split('-');
      return name === companyName;
    });
  }
  private setPaginatedSensors(pageSize = PAGINATION_DEFAULT_PAGE_SIZE, page = 1) {
    this.pageSize = pageSize;
    this.defibrillatorPage = page;
    // @ts-ignore
    this.paginatedDefibrillatorTests = this.sensors.slice(this.pageSize * (this.mgubPage - 1), this.pageSize * this.mgubPage);
  }

  private compareByTestDate(a, b) {
    const dateA = new Date(a.testYear, a.testMonth - 1,
      a.testDay, a.testHour, a.testMinute);
    const dateB = new Date(b.testYear, b.testMonth - 1,
      b.testDay, b.testHour, b.testMinute);
    return dateB.getTime() - dateA.getTime();
  }

  private checkAEDBatteryColor(batteryLevel: number, index: number) {
    if (batteryLevel >= 10) {
      return 'green';
    } else if (batteryLevel >= 9.5) {
      return 'yellow';
    } else {
      if (index === 0) {
        this.isBatteryErrorAED = true;
      }
      return 'red';
    }
  }

  private checkIOTBatteryColor(batteryLevel: number, index: number) {
    if (batteryLevel >= 2.65) {
      return 'green';
    } else {
      return 'yellow';
    }
  }

  private checkTestResultColor(testResult) {
    if (testResult === 0) {
      return 'red';
    } else {
      return 'green';
    }
  }

  private checkDefibrillatorStatus(testResult, padValue, aedBatteryVoltage, iotBatteryVoltageLevel) {

    if (this.positionStatus === 2) {
      this.defibrillatorStatus = 'used';
    } else if (this.isLastTestTimeNotValid) {
      this.defibrillatorStatus = 'notconnected';
    } else if (testResult === 0 || aedBatteryVoltage < 9.5) {
      this.defibrillatorStatus = 'error';
    } else if ((aedBatteryVoltage >= 9.5 && aedBatteryVoltage < 10) || iotBatteryVoltageLevel < 2.65) {
      this.defibrillatorStatus = 'lowbattery';
    } else if (padValue === 0) {
      this.defibrillatorStatus = 'pednotengaged';
    } else {
      this.defibrillatorStatus = 'avaliable';
    }

    if (this.positionStatus === 1) {
      this.defibrillatorStatusMessage = this.defibrillatorStatus + '-move';
    } else {
      this.defibrillatorStatusMessage = this.defibrillatorStatus;
    }

  }

  private checkConnectionStatusType(gsmConnectionType, gsmLteQuality, gsm2gQuality) {
    if (gsmConnectionType === 7) {
      this.connectionTypeName = 'LTE';
      this.calculateSignalQualityLTE(gsmLteQuality);
    } else {
      this.connectionTypeName = '2G';
      this.calculateSignalQuality2G(gsm2gQuality);
    }
    this.getSignalQualityIcon(this.signalQuality);
  }

  private calculateSignalQuality2G(gsmSignalQuality) {
    if (gsmSignalQuality === 0) {
      this.signalQuality = this.signalQualityMap[0];
    } else if (gsmSignalQuality === 1) {
      this.signalQuality = this.signalQualityMap[1];
    } else if (gsmSignalQuality === 31) {
      this.signalQuality = this.signalQualityMap[3];
    } else {
      this.signalQuality = this.signalQualityMap[2];
    }
  }

  private calculateSignalQualityLTE(gsmSignalQuality) {
    if (gsmSignalQuality < -100) {
      this.signalQuality = this.signalQualityMap[0];
    } else if (gsmSignalQuality < -90 && gsmSignalQuality >= -100) {
      this.signalQuality = this.signalQualityMap[1];
    } else if (gsmSignalQuality < -80 && gsmSignalQuality >= -90) {
      this.signalQuality = this.signalQualityMap[2];
    } else {
      this.signalQuality = this.signalQualityMap[3];
    }
  }

  private getSignalQualityIcon(signalQuality) {
    if (signalQuality === this.signalQualityMap[0]) {
      this.signalQualityIcon = 'assets/images/critical-signal.png';
      this.colorSignalQuality = 'red';
    } else if (signalQuality === this.signalQualityMap[1]) {
      this.signalQualityIcon = 'assets/images/low-signal.png';
      this.colorSignalQuality = 'yellow';
    } else if (signalQuality === this.signalQualityMap[2]) {
      this.signalQualityIcon = 'assets/images/normal-signal.png';
      this.colorSignalQuality = 'green';
    } else {
      this.signalQualityIcon = 'assets/images/good-signal.png';
      this.colorSignalQuality = 'green';
    }
  }

  private checkAllTest(testEntity) {

    const entity = this.checkTestValue(testEntity);

    if (entity.adsTestResult.value === 0 || entity.ecgTestResult.value === 0 || entity.cryptoTestResult.value === 0 ||
      entity.audioTestResult.value === 0 || entity.iotInternalMemoryTestResult.value === 0 || entity.chargeCircuit1TestResult.value === 0 ||
      entity.chargeCircuit2TestResult.value === 0 || entity.capacitorVoltageTestResult.value === 0 || entity.hBridgeCircuit1TestResult.value === 0 ||
      entity.hBridgeCircuit2TestResult.value === 0 || entity.onOffButtonTestResult.value === 0 || entity.shockButtonTestResult.value === 0 ||
      entity.iotAccelerometerTestResult.value === 0 || entity.iotAedConnectionTestResult.value === 0) {

      return 0;
    } else {
      return 1;
    }
  }

  private checkAllTestColor(testResult, padTestResult) {

    if (testResult === 0) {
      return 'red';
    } else if (padTestResult === 0) {
      return 'orange';
    } else {
      return 'green';
    }
  }


  private checkTestValue(testEntity) {

    if (testEntity.adsTestResult === undefined) {
      testEntity.adsTestResult = 2;
    }
    if (testEntity.ecgTestResult === undefined) {
      testEntity.ecgTestResult = 2;
    }
    if (testEntity.cryptoTestResult === undefined) {
      testEntity.cryptoTestResult = 2;
    }
    if (testEntity.audioTestResult === undefined) {
      testEntity.audioTestResult = 2;
    }
    if (testEntity.padTestResult === undefined) {
      testEntity.padTestResult = 2;
    }
    if (testEntity.chargeCircuit1TestResult === undefined) {
      testEntity.chargeCircuit1TestResult = 2;
    }
    if (testEntity.chargeCircuit2TestResult === undefined) {
      testEntity.chargeCircuit2TestResult = 2;
    }
    if (testEntity.capacitorVoltageTestResult === undefined) {
      testEntity.capacitorVoltageTestResult = 2;
    }
    if (testEntity.hBridgeCircuit1TestResult === undefined) {
      testEntity.hBridgeCircuit1TestResult = 2;
    }
    if (testEntity.hBridgeCircuit2TestResult === undefined) {
      testEntity.hBridgeCircuit2TestResult = 2;
    }
    if (testEntity.onOffButtonTestResult === undefined) {
      testEntity.onOffButtonTestResult = 2;
    }
    if (testEntity.shockButtonTestResult === undefined) {
      testEntity.shockButtonTestResult = 2;
    }
    if (testEntity.iotAccelerometerTestResult === undefined) {
      testEntity.iotAccelerometerTestResult = 2;
    }
    if (testEntity.iotInternalMemoryTestResult === undefined) {
      testEntity.iotInternalMemoryTestResult = 2;
    }
    if (testEntity.iotAedConnectionTestResult === undefined) {
      testEntity.iotAedConnectionTestResult = 2;
    }

    return testEntity;
  }


  private calculateTestType(testType) {

    if (testType === '00') {
      return this.testTypeMap[0];
    } else if (testType === '01') {
      return this.testTypeMap[1];
    } else {
      return this.testTypeMap[2];
    }
  }

  private checkLastTestDate(day, month, year, hour, minute) {
    const lastTestDate = new Date();
    lastTestDate.setDate(day);
    lastTestDate.setMonth(month - 1);
    lastTestDate.setFullYear(year);
    lastTestDate.setHours(hour);
    lastTestDate.setMinutes(minute);
    const today = new Date();
    const validTestDate = new Date(today);
    validTestDate.setDate(today.getDate() - 1);

    if (lastTestDate > validTestDate) {
      this.isLastTestTimeNotValid = false;
    }
  }

  calculateTestResultDescription(testResult, padTestResult) {
    if (testResult === 0) {
      return '0';
    } else if (padTestResult === 0) {
      return '3';
    } else {
      return '1';
    }
  }

  getHealthCaseId(ngsiLdId) {
    const parts = ngsiLdId.split(':');
    const entityId = parts[parts.length - 1];
    return entityId;
  }

  getCaseDate(caseDate) {
    const dateObject = new Date(caseDate);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  getCaseTime(caseDate) {
    const dateObject = new Date(caseDate);
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  }

  updateDefibrillator(updatedData: DefibrillatorUpdatedData) {
    this.defibrillatorService.updateDefibrillatorInfo(updatedData);
  }

  updateDescription() {
    const descriptionProperty = new DefibrillatorProperty(this.description);
    const descriptionPropertyArray = [];
    descriptionPropertyArray.push(descriptionProperty);
    const updatedData = new DefibrillatorUpdatedData(this.defibrillatorId, undefined, undefined, descriptionPropertyArray);
    this.updateDefibrillator(updatedData);
  }

  updatePediatricPadDate() {
    this.pediatricPadExpirationDate = this.pediatricTempQuery.displayText.replace(/\//g, '-').split('-').reverse().join('-');
    const pediatricPadExpirationDateProperty = new DefibrillatorProperty(this.pediatricPadExpirationDate);
    const pediatricPadExpirationDatePropertyArray = [];
    pediatricPadExpirationDatePropertyArray.push(pediatricPadExpirationDateProperty);
    const updatedData = new DefibrillatorUpdatedData(this.defibrillatorId, undefined, pediatricPadExpirationDatePropertyArray, undefined);
    this.updateDefibrillator(updatedData);
    this.pediatricTempQuery = undefined;
  }

  updateAdultPadDate() {
    this.adultPadExpirationDate = this.adultTempQuery.displayText.replace(/\//g, '-').split('-').reverse().join('-');
    const adultPadExpirationDateProperty = new DefibrillatorProperty(this.adultPadExpirationDate);
    const adultPadExpirationDatePropertyArray = [];
    adultPadExpirationDatePropertyArray.push(adultPadExpirationDateProperty);
    const updatedData = new DefibrillatorUpdatedData(this.defibrillatorId, adultPadExpirationDatePropertyArray);
    this.updateDefibrillator(updatedData);
    this.adultTempQuery = undefined;

  }

  updateGuaranteeStartDate() {
    this.guaranteeStartDate = this.guaranteeTempQuery.displayText.replace(/\//g, '-').split('-').reverse().join('-');
    const guaranteeStartDateProperty = new DefibrillatorProperty(this.guaranteeStartDate);
    const guaranteeStartDatePropertyArray = [];
    guaranteeStartDatePropertyArray.push(guaranteeStartDateProperty);
    const updatedData = new DefibrillatorUpdatedData(this.defibrillatorId, undefined, undefined, undefined, guaranteeStartDatePropertyArray);
    this.updateDefibrillator(updatedData);
    this.guaranteeTempQuery = undefined;
  }

  updateCompanyName() {
    const companyNamePropertyArray = [];
    companyNamePropertyArray.push(new DefibrillatorProperty(this.companyName.split('-')[1]));
    const updatedData = new DefibrillatorUpdatedData(this.defibrillatorId, undefined, undefined, undefined, undefined, companyNamePropertyArray);
    try {
      this.updateDefibrillator(updatedData);
      this.initialCompanyName = this.companyName;
      this.isCompanyChanged = false;
    } catch (error) {
      console.log(error);
    }
  }

  openTestDialog(defibrillatorTest) {
    this.dialogService.open(DefibrillatorDialogComponent, {
      context: {
        test: defibrillatorTest,
        aselsanUser : this.userService.isAselsanUser()
      }
    });
  }

  openEkgDialog(healthCase, index) {
    this.showSignalQuality = false;
    this.dialogService.open(DefibrillatorEkgDialogComponent, {
      context: {
        healthCaseId: 'urn:ngsi-ld:HealthCase:' + healthCase.healthCaseId,
        healthCaseStartDate: healthCase.observedAt,
        positionStatus: this.positionStatus,
        caseIndex: index,
        cBrokerEndpoint: this.cBrokerEndpoint,
        defibrillatorId: this.defibrillatorId
      }
    }).onClose.subscribe(() => {
      this.showSignalQuality = true;
    });
  }

  handlePadDateSelection(source) {
    this.dialogService.open(DateSelectionDialogComponent, {
      context: {
        showPeriodTab: true,
        dialogSource: source
      }
    });
  }
  enterEditMode() {
    this.isReadOnlyMode = false;
  }
  saveChanges() {
    this.isReadOnlyMode = true;
    this.updateDescription();
  }

  companyChanged(selectedCompany: string) {
    this.isCompanyChanged = this.initialCompanyName !== selectedCompany;
  }
}
