import {Visualization} from './visualization.model';
import {VisualizationType} from '../../enums/viualization-type.enum';

export class HeatmapVisualization extends Visualization {
  /**
   * Radius of the heatmap point
   */
  radius: string;

  /**
   * Expression to calculate the color of the heatmap point
   * */
  color: string;

  constructor(data?: any) {
    super(data);
    this.jsonClass = VisualizationType.HEATMAP;

    if (!data) {
      return;
    }

    this.radius = data.radius;
    this.color = data.color;
  }
}
