import {QueryOperatorExpr} from './query-operator-expr.model';
import {QueryOperatorParam} from './query-operator-param.model';
import {QueryOperatorParamFactory} from '../factories/query-operator-param.factory';
import {OperatorType} from '../../enums/ngsi-query.enum';
import {NGSIPath} from '../ngsi/ngsi-path.model';
import {QueryOperatorParamValue} from './query-operator-param-value.model';

/**
 * Any metric, bucket or pipeline aggregation or other query operator defined in platform
 */
export class QueryOperator extends QueryOperatorExpr {
  /**
   * Operation name e.g. avg, sum, ...
   */
  op: string;

  /**
   * Input parameters for the aggregation either a simple parameter e.g. NGSI-LD attribute path to calculate avg or inner metric/pipeline aggregation/operator
   */
  params: QueryOperatorParam[] = [];

  constructor(data?: any) {
    super();
    this.jsonClass = 'QueryOperator';
    if (!data) {
      return;
    }

    this.op = data.op;
    this.params = data.params.map(param => QueryOperatorParamFactory.create(param));
    this.dataType = data.dataType;
  }

  copy(that: QueryOperator) {
    super.copy(that);
    this.op = that.op;
    this.params = that.params;
  }

  createQuerySerialization(): string {
    return this.createQuerySerializationForOperator(this);
  }

  containsTemporalElement(): boolean {
    return this.params
      // consider only expressions. Parameter values does not reveal information about being temporal or not
      .filter(param => param instanceof QueryOperatorExpr)
      .some(param => (param as QueryOperatorExpr).containsTemporalElement());
  }

  /**
   * Serializes the operator to be used in the query url
   * @param operator operator to be serialized
   * @private
   */
  private createQuerySerializationForOperator(operator: QueryOperator): string {
    const serialization: string = operator.params.map(param => {
      if (param instanceof QueryOperatorParamValue) {
        let paramValue: any = (param as QueryOperatorParamValue).value;
        if (typeof paramValue === 'string') {
          paramValue = `'${paramValue}'`;
        }
        return paramValue;
      } else if (param instanceof NGSIPath) {
        return param.columnName;
      } else if (param instanceof QueryOperator) {
        return this.createQuerySerializationForOperator(param);
      }
    }).join(',');

    return operator.op + '(' + serialization + ')';
  }

  get columnName(): string {
    let columnName: string = this.op;
    if (this.params.length > 0) {
      columnName = this.op + '_' + this.params
        .filter(param => !(param instanceof QueryOperatorParamValue))
        .map(param => param.columnName)
        .join('_');
    }
    return columnName;
  }
}
