import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {AuthService} from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private title: Title
  ) {
  }

  setDynamicTitle() {
    const realmName = this.authService.getRealmId();
    const jsonUrl = `https://assets.geleceginsehri.com/${realmName}/theme.json`;

    // Make the first request to MinIO URL
    this.http.get<{ title: string }>(jsonUrl).subscribe(
      (data) => {
        // If the data is received, update the title
        const newTitle = data.title || 'URUK - Merkezi İzleme Platformu';
        this.title.setTitle(newTitle);
      },
      () => {
        // If the first request fails, try the fallback URL
        const fallbackUrl = 'https://assets.geleceginsehri.com/documents/theme.json';
        this.http.get<{ title: string }>(fallbackUrl).subscribe(
          (fallbackData) => {
            // If data from fallback URL is received, update the title
            const newTitle = fallbackData.title || 'URUK - Merkezi İzleme Platformu';
            this.title.setTitle(newTitle);
          },
          () => {
            // If both requests fail, set the default title
            this.title.setTitle('URUK - Merkezi İzleme Platformu');
          }
        );
      }
    );
  }
}
