<closeable-dialog class="mgub-dialog-container" [displayFooter]="showFooter">
  <div class="dialog-header title" dialog-card-header [ngStyle]="{'color':hasPassive ? '#FF1F00' : 'white'}">{{mgubCastleDescription}}</div>
  <div dialog-card-body class="dialog-body">
    <div class="centered-image">
      <img class="img1" src="assets/images/tak.png" width="300" height="250" />
    </div>
    <div *ngFor="let sensorMap of sensorMaps | keyvalue">
      <div class="dialog-item" >
        <div class="d-flex" (click)='setVisibility(sensorMap.key)' [ngStyle]="{'cursor': 'pointer', 'background': !isCollapsedMap.get(sensorMap.key) ? '#1E1E1E' : '#2A2A2A', 'border-radius':'5px'}">
          <div [ngClass]="'title'"  >
            {{sensorMap.key | translate}}
          </div>
          <div [ngClass]="'title left-icon'"  *ngIf='hasPassiveSensor(sensorMap.key)'>
            <i class="fa fa-exclamation-triangle"></i>
          </div>
          <div *ngIf='isCollapsedMap.get(sensorMap.key)' [ngClass]="'title right-icon'">
            <i class="fa fa-2x fa-caret-right"></i>
          </div>
          <div *ngIf='!isCollapsedMap.get(sensorMap.key)' [ngClass]="'title right-icon'">
            <i class="fa fa-2x fa-caret-down"></i>
          </div>
        </div>
        <div *ngIf='!isCollapsedMap.get(sensorMap.key)'>
          <table id="sensor-table">
            <tr [ngClass]="'header'">
              <th>{{'sensorId' | translate}}</th>
              <th>{{'status' | translate}}</th>
              <th>{{'description' | translate}}</th>
            </tr>
            <tr *ngFor="let sensor of sensorMap.value">
              <td>
                <div [ngClass]= "{'redDiv':sensor.status === 'Pasif' || sensor.status === 'Durum Bilgisi Yok'}">
                  {{sensor.sensorId}}
                </div>
              </td>
              <td>
                <div [ngClass]= "{'redDiv':sensor.status === 'Pasif' || sensor.status === 'Durum Bilgisi Yok'}">
                  {{sensor.status}}
                </div>
              </td>
              <td>
                <div [ngClass]= "{'redDiv':sensor.status === 'Pasif' || sensor.status === 'Durum Bilgisi Yok'}">
                  {{sensor.description}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info title">{{'coordinate' | translate}}: </label>
      <label class="column-info content">{{mgubCastleLocation}}</label>
    </div>
    <div dialog-card-footer class="footer-right">
      <label style="color:red;" class="column-info content">{{this.passiveSensorCount}} &nbsp; </label>
      <label class="column-info content"> / {{this.paginatedSensors.length}}</label>
      <label class="column-info content">&nbsp;{{'sensor' | translate}}</label>
    </div>
  </div>
</closeable-dialog>


