import {VisualizationType} from '../../enums/viualization-type.enum';
import {IconVisualization} from '../visualization/icon-visualization.model';
import {LineVisualization} from '../visualization/line-visualization.model';
import {HeatmapVisualization} from '../visualization/heatmap-visualization.model';
import {Visualization} from '../visualization/visualization.model';
import {PolygonVisualization} from '../visualization/polygon-visualization.model';
import {GenericVisualization} from '../visualization/generic-visualization.model';

/**
 * A factory class to handle different types visualizations (and avoid circular dependencies)
 */
export class VisualizationFactory {

  /**
   * Factory method to create a specific visualization type
   * @param data
   */
  static create(data: any): Visualization {
    if (data.jsonClass === VisualizationType.ICON) {
      return new IconVisualization(data);
    } else if (data.jsonClass === VisualizationType.LINE) {
      return new LineVisualization(data);
    } else if (data.jsonClass === VisualizationType.POLYGON) {
      return new PolygonVisualization(data);
    } else if (data.jsonClass === VisualizationType.HEATMAP) {
      return new HeatmapVisualization(data);
    } else if (data.jsonClass === VisualizationType.GENERIC) {
      return new GenericVisualization(data);
    }
  }
}
