import {LayerController} from './layer.controller';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {catchError, forkJoin, Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {Marker} from '../../shared/models/map/marker.model';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {NGSIEntity} from '../../shared/utils/ngsi-result-util';


export class SolarPoleLayerController extends  LayerController {
  displaysTooltip: boolean = true;
  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }


  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    const solarPoleId = result.getEntityResult().entity.id;
    let contextObservable: Observable<any> = null;
    const queryList: NGSIQuery[] = [];

    const sensorQuery: NGSIQuery = new NGSIQuery({
      filter: {
        type: [NGSIEntity.RELAY] ,
        q : {
          jsonClass: "NGSIQueryTerm",
          path: {
            jsonClass: "NGSIPath",
            propOrRelPath: [
              "solarPoleRef"
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: "==",
          value: [
            solarPoleId
          ]
        }
      },
      limit: 1000
    });

    const solarPoleRef =  this.cbrokerService.getEntities(sensorQuery, new QueryOptions(false)).pipe(catchError(() => of(null)));
    const solarPoleRefQuery = this.getMarkerEntity(result.getEntityId(), false);
    contextObservable = forkJoin([solarPoleRef, solarPoleRefQuery]).pipe(
      map(response => {
        return {
          entity: response,
        };
      })
    );
    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }

  createTooltip(marker: Marker): string {
    const idArray = marker.entity.entity.id.split(':');
    const solarPoleId = idArray[3];
    let html = '<div class="entity-tooltip">';
    html += `<div class="text"><span class="title">Solar Pole ID : </span> ${solarPoleId}</div>`;
    html += '</div';

    return html;
  }

}
