import {AlertState} from '../enums/alert-state.enum';
import {AlertPriority} from '../enums/alert-priority';
import {TemporalValue} from '../../../shared/models/schema/entity/temporal/temporal-value';
import {TimeUtil} from '../../../shared/utils/time-util';
import {EntityResult} from '../../../shared/models/schema/entity-result';
import {NGSIPath} from '../../../shared/models/ngsi/ngsi-path.model';

export class AlertUtil {
  static getStateText(state: string) {
    switch (state) {
      case AlertState.IN_PROGRESS:
        return 'Alert_State_In_Progress';
      case AlertState.CONFIRMED:
        return 'Alert_State_Confirmed';
      case AlertState.UNCONFIRMED:
        return 'Alert_State_Unconfirmed';
      case AlertState.CLOSED:
        return 'Alert_State_Closed';
      case AlertState.SUPPRESSED:
        return 'Alert_State_Suppressed';
    }
  }

  static getPriorityText(priority: string) {
    switch (priority) {
      case AlertPriority.EXTREME:
        return 'Alert_Priority_Extreme';
      case AlertPriority.SEVERE:
        return 'Alert_Priority_Severe';
      case AlertPriority.MODERATE:
        return 'Alert_Priority_Moderate';
      case AlertPriority.MINOR:   
        return 'Alert_Priority_Minor';
      default:
        return 'Alert_Priority_Unknown';
    }
  }

  /**
   * Returns the last (i.e. current) state time
   */
  static getLastStateTime(alert: EntityResult): string {
    const states: TemporalValue[] = alert.getAttributeAsTemporalValueArray('state');
    if (states.length === 0) {
      return '';
    }
    const lastStateTime: Date = states[0].observedAt;
    return TimeUtil.serializeReadableDatetime(lastStateTime);
  }

  static getReadableStartDate(alert: EntityResult): string {
    const alertSource: TemporalValue = new TemporalValue(alert.entity.alertSource[0]);
    return TimeUtil.serializeReadableDate(alertSource.observedAt);
  }

  static getReadableStartTime(alert: EntityResult): string {
    const alertSource: TemporalValue = new TemporalValue(alert.entity.alertSource[0]);
    return TimeUtil.serializeHour(alertSource.observedAt);
  }

  static getReadableEndDate(alert: EntityResult): string {
    const states: TemporalValue[] = alert.getAttributeAsTemporalValueArray('state');
    if (states.length === 0) {
      return '';
    }

    const lastState: TemporalValue = states[0];
    if (states[0].value === AlertState.CLOSED) {
      return TimeUtil.serializeReadableDate(lastState.observedAt);
    } else {
      return 'Devam Ediyor';
    }
  }

  static getReadableEndTime(alert: EntityResult): string {
    const states: TemporalValue[] = alert.getAttributeAsTemporalValueArray('state');
    if (states.length === 0) {
      return '';
    }

    const lastState: TemporalValue = states[0];
    if (states[0].value === AlertState.CLOSED) {
      return TimeUtil.serializeHour(lastState.observedAt);
    } else {
      return '';
    }
  }

  static getUserName(alert: EntityResult): string {
    const userNgsiPath: NGSIPath = new NGSIPath({
      propOrRelPath: ['state', 'asserter']
    });
    return alert.getSingleElementValueByNgsiPath(userNgsiPath);
  }
}
