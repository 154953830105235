import {Injectable} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Coordinate} from '../../shared/models/generic/coordinate.model';
import {EventService} from './event.service';
import {environment} from '../../../environments/environment';
import {NbSidebarService} from '@nebular/theme';
import {MenuService} from '../../@theme/services/menu/menu.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  /**
   * The width and height of the window's visual viewport
   */
  private applicationSize: BehaviorSubject<[number, number]>;

  /**
   * Current zoom level of the map view. Zoom level can be between 1-22 where 1 represents the highest level i.e.
   */
  private zoomLevel: BehaviorSubject<number> = new BehaviorSubject<number>(environment.map.defaultZoom);

  /**
   * An observable for subscribing to the zoom changes on the map
   */
  private zoomLevelChange$: Observable<number> = this.zoomLevel.pipe(
    debounceTime(environment.timeouts.debounceTimes.geologicalQueries),
    distinctUntilChanged()
  );

  constructor(private eventService: EventService, private menuService: MenuService, private nbSideBarService: NbSidebarService) {
    this.applicationSize = new BehaviorSubject<[number, number]>( [0, 0]);
  }

  /***************************** WIDTH CHANGES *****************************/

  /**
   * Returns the current width of the application. The value is exactly the same as window.innerWidth property
   */
  getApplicationSize(): [number, number] {
    return this.applicationSize.value;
  }

  /**
   * Sets the application size
   * @param size size to be set
   */
  setApplicationSize(size: [number, number]) {
    this.applicationSize.next(size);
  }

  /**
   * Returns an observable so that it can be subscribed for width change events
   */
  onApplicationWidthChange(): Observable<any> {
    return this.applicationSize;
  }

  /***************************** MAP *****************************/

  /**
   * Sets the zoom level information maintained in this service. This method is not supposed to be used to change the level on the map. On the contrary,
   * the changes on the map are reflected to this page via this method.
   * @param level
   */
  onMapZoomChange(level: number): void {
    this.zoomLevel.next(level);
  }

  /**
   * Returns an observable that can be subscribed to for zoom level changed on the map
   */
  onMapZoomChanged(): Observable<number> {
    return this.zoomLevelChange$;
  }

  /**
   * Returns the current zoom level
   */
  getZoomLevel(): number {
    return this.zoomLevel.getValue();
  }

  /**
   * Pans the map to the given coordinate. The zoom amount is calculated based on radius indicating the area represented by the marker.
   * The radius represents the actual distance for latitude but 2 times of distance for longitude. I.e. for radius of 1 represents a squeare with
   * an area of 2 latitudes and 4 longitudes.
   * @param coordinate
   * @param radius
   */
  panAndZoomToMarker(coordinate: Coordinate, radius: number): void {
    const targetZoomLevel: number = this.getZoomLevelForGeohashRadius(radius);
    this.eventService.broadcastPanAndZoomEvent(coordinate, targetZoomLevel);
  }

  /**
   * Maps the given geohash aggregation to a similar zoom level
   * @param radius
   */
  getZoomLevelForGeohashRadius(radius: number): number {
    if (radius > 20) { // geohash level 1
      return 1;
    } else if (radius < 20 && radius > 2) { // geohash level 2
      return 10;
    } else if (radius < 2 && radius > 0.7) { // geohash level 3
      return 11;
    } else if (radius < 0.7 && radius > 0.08) { // geohash level 4
      return 13;
    } else if (radius < 0.08 && radius > 0.02) { // geohash level 5
      return 15;
    } else if (radius < 0.02 && radius > 0.002) { // geohash level 6
      return 17;
    } else if (radius < 0.002) { // geohash level 7
      return 19;
    }
    // } else if (radius < 0.0006 && radius > 0.00008) { // geohash level 8
    //   return 17;
    // } else if (radius < 0.00008 && radius > 0.00002) { // geohash level 9
    //   return 17;
    // } else if (radius < 0.00002 && radius > 0.000002) { // geohash level 10
    //   return 21;
    // }
    return 13;
  }

  /**
   * Merges all observables affecting the area occupied by the map. Instead of subscribing all relevant observables individually, this
   * method returns a merged observable.
   */
  onMapAreaChanged(): Observable<void> {
    return merge(
      this.nbSideBarService.onToggle(),
      this.nbSideBarService.onCompact(),
      this.nbSideBarService.onCollapse(),
      this.nbSideBarService.onExpand(),
      this.menuService.menuVisibility
    ).pipe(
      debounceTime(environment.timeouts.debounceTimes.mapLayoutChange),
      map(_ => {
        return;
      }));
  }

  /***************************** UTILS *****************************/

  isVideowall(): boolean {
    const appWidth = this.applicationSize.value[0];
    if (appWidth > 5000) {
      return true;
    }

    return false;
  }
}
