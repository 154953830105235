import {Layer} from '../../shared/models/layer.model';
import {Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {NGSIResultUtil} from '../../shared/utils/ngsi-result-util';
import {EntityRepresentation} from '../../shared/models/visualization/representation.model';
import {Notification} from '../../shared/models/notification.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import { LayerController } from './layer.controller';
import { map, Observable, of, take } from 'rxjs';
import {Marker} from '../../shared/models/map/marker.model';

export class AnnounceSensorLayerController extends LayerController {

  displaysTooltip = true;

  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  getDialogContext(result: NGSIResult): any {
    const contextObservable: Observable<any> = this.getMarkerEntity(result.getEntityId(), false).pipe(
      take(1),
      map(entity => {
        return entity;
      })
    );

    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }
  protected handleCreateEvent(representation: EntityRepresentation, notification: Notification): NGSIResult {
    const newEntityAnnounceRef: string = NGSIResultUtil.extractEntityAttributeValue(notification.eventPayload.content, 'id', new QueryOptions());
    const representationEntities = this.entities.get(representation);
    const entityIndex = representationEntities ? representationEntities.findIndex(e => {
      return this.getIdFromNGSIResult(e) === newEntityAnnounceRef;
    }) : -1;
    if (entityIndex !== -1) {
      this.entities.get(representation).splice(entityIndex, 1);
      this.markers.get(representation).splice(entityIndex, 1);
    }
    return super.handleCreateEvent(representation, notification);
  }

  private getIdFromNGSIResult(ngsiResult: NGSIResult): string {
    return ngsiResult.getEntityResult().entity.id;
  }

  createTooltip(marker: Marker): string {
    const rtuId =  marker.entity.entity.rtuId.value ?  marker.entity.entity.rtuId.value : marker.entity.entity.rtuId[0].value;
    const sensorUnitId = marker.entity.entity.sensorUnitId.value ?  marker.entity.entity.sensorUnitId.value : marker.entity.entity.sensorUnitId[0].value;
    let html = '<div class="entity-tooltip">';
    html += `<div class="text"><span class="title">Rtu ID: </span> ${rtuId} </div>`;
    html += `<div class="text"><span class="title">Sensor Unit ID: </span> ${sensorUnitId} </div>`;
    html += '</div>';
    return html;
  }
}
