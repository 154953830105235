<!-- In the edit mode, do not display Alert layer-->
<div class="layer" (click)="handleIconClick()" [ngClass]="{ 'active': active, 'hidden': hidden }">

  <nb-icon
    [ngStyle]="{ 'opacity': active ? 1 : 0.2 }"
    [icon]="layer.icon" [pack]="getIconPage()"
    [nbPopover]="getTooltip() | translate"
    nbPopoverPlacement="bottom"
    nbPopoverTrigger="hint">
  </nb-icon>

  <!-- Icon to delete layer -->
  <div *ngIf="displayMode == 'edit'" class="delete-layer" (click)="handleDeleteClick(); $event.stopPropagation()">
    <i class="fa fa-times-circle "></i>
  </div>
</div>
