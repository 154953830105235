import {BaseMetaModel} from './base/base-meta-model.model';
export class CanCommunication extends BaseMetaModel {

  riom1: string;
  riom2: string;
  riom3: string;
  riom4: string;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.riom1 = data.riom1;
    this.riom2 = data.riom2;
    this.riom3 = data.riom3;
    this.riom4 = data.riom4;
  }

}
