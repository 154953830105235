/**
 * Base class for query expressions
 */
import {NGSIQueryTermAssoc} from './ngsi-query-term-assoc.model';
import {BaseQuery} from './base-query.model';
import {Persistable} from '../persistanble';

export abstract class NGSIQueryExpression extends BaseQuery implements Persistable {
  // back reference to parent query term association
  parentAssociation: NGSIQueryTermAssoc;

  constructor(data?: any) {
    super(data);
    if (!data) {
      return;
    }
  }

  abstract createPersistableObject(): any;
}
