import {NGSIQueryExpression} from '../ngsi/ngsi-query-expression.model';
import {NGSIQueryExpressionFactory} from '../factories/ngsi-query-expression.factory';

/**
 * Settings to cluster entities.
 */
export class ClusterSetting {

  /**
   * Color of cluster
   */
  color: string;

  /**
   * Radius of each cluster
   */
  radius: number;

  /**
   * Zoom on which to cluster points
   */
  zoom: number;

  /**
   * Criteria to be satisfied to activate the cluster setting for an entity
   */
  expression: NGSIQueryExpression;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.color = data.color;
    this.radius = data.radius;
    this.zoom = data.zoom;
    if (data.expression) {
      this.expression = NGSIQueryExpressionFactory.create(data.expression);
    }
  }
}
