import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {UrukMap} from '../../models/map/uruk-map.interface';
import * as L from 'leaflet';
import {marker} from 'leaflet';
import {environment} from '../../../../environments/environment';
import {NGSIGeoQuery} from '../../models/ngsi/ngsi-geo-query.model';
import {Coordinate} from '../../models/generic/coordinate.model';
import {BaseComponent} from '../base.component';
import {LayerController} from '../../../core/controllers/layer.controller';
import {Geometry} from '../../enums/ngsi-query.enum';
import {MapSettings} from '../../models/visualization/map-settings.model';
import {MapTileType} from "../../enums/map-framework.enum";
import {MapService} from '../../../core/services/map.service';
import {Marker} from "../../models/map/marker.model";
import {takeUntil} from "rxjs/operators";
import {EventService} from "../../../core/services/event.service";
import {Linestring} from "../../models/map/linestring.model";
import {Polygon} from "../../models/map/polygon.model";
import {EntityRepresentation} from "../../models/visualization/representation.model";
import {IconVisualization} from "../../models/visualization/icon-visualization.model";
import {ClusterSetting} from "../../models/visualization/cluster-setting.model";
import 'leaflet.markercluster';
import {NbIconDefinition, NbIconLibraries} from "@nebular/theme";

@Component({
  selector: 'leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
})
export class LeafletMapComponent extends BaseComponent implements UrukMap, OnInit {
  @Input() layers: LayerController[];
  @Input() mapSettings: MapSettings;
  @Input() mapType: MapTileType;
  @Output() onMapInitialized: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();
  @Output() onMapBoundariesChanged: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();
  @Output() onMapClicked: EventEmitter<Coordinate> = new EventEmitter<Coordinate>();
  @Output() onShapeDrawn: EventEmitter<NGSIGeoQuery> = new EventEmitter<NGSIGeoQuery>();

  /**
   * Reference to the leaflet map object
   */
  map: L.Map;

  /**
   * Reference to the leaflet draw object
   */
  drawControl: L.Draw;

  /**
   * Reference to the current tile layer (e.g. OpenStreeMap, etc)
   */
  tileLayer;

  showMergedMarkers: boolean= true;
  /**
   * Array that keeps markers and shapes that are not a part of regular layers given as input. Shape objects that are created as a result of drawing activities are also also kept in this list
   */
  public markers = [];
  markersMap: Map<LayerController, any[]> = new Map<LayerController, any[]>();
  linesMap: Map<LayerController, any> = new Map<LayerController, any>();
  polygonsMap: Map<LayerController, any> = new Map<LayerController, any>();

  /**
   * An initial zoom/center and set of layers. Changes to leafletOptions are ignored after they are initially set.
   * This is because these options are passed into the map constructor, so they can't be changed anyways.
   */
  mapOptions: any;
  /**
   * Leaflet draw options to hide the toolbar.
   */
  drawOptions = {
    position: 'bottomright',
    draw: {
      polygon: false,
      rectangle: false,
      polyline: false,
      circle: false,
      marker: false,
      circlemarker: false
    },
    edit: false
  };

  private mapService: MapService;
  private satelliteSession: string;
  private roadMapsession: string;
  private apiKey: string;

  constructor(private injector: Injector,
              private iconLibraries: NbIconLibraries) {
    super(injector);
    this.mapService = injector.get(MapService);
  }

  ngOnInit(): void {
    this.initializeMapOptions();
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.LAYER_PROCESSING_COMPLETED: {
            const layer = event.data as LayerController;

            // identify map objects
            this.markersMap.set(layer, layer.mergedMarkers.filter(item => this.isMarker(item)));

            if (!this.linesMap.get(layer)) {
              this.linesMap.set(layer, {});
            }
            this.linesMap.get(layer).lines = layer.mergedMarkers.filter(item => this.isLine(item));

            if (!this.polygonsMap.get(layer)) {
              this.polygonsMap.set(layer, {});
            }
            this.polygonsMap.get(layer).polygons = layer.mergedMarkers.filter(item => this.isPolygon(item));

            // process lines
            //this.processLines(layer);
            //this.processPolygons(layer);
            this.processClusters(layer);
            break;
          }
          case EventService.HIDE_LAYERS:
            for (let [key, layerData] of this.markersMap.entries()) {
              const layer = key as LayerController;
              const entityRepresentations: EntityRepresentation[] = Array.from(layer.markers.keys());
              entityRepresentations.forEach((representation, index) => {
                if (representation.visualization instanceof IconVisualization) {
                  const clusterSettings: ClusterSetting[] = representation.visualization.clusterSettings;
                  const markers = layer.markers.get(representation);

                  // If the clustering is enabled for icon representation, cluster the markers
                  if (clusterSettings?.length && markers.length) {
                    this.showMergedMarkers = false;
                    clusterSettings.forEach(clusterSetting => {
                      const clusterLayerId = `cluster-${layer.layer.id}-${index}-${clusterSetting.color}`;
                      const clusterCountLayerId = `cluster-count-${layer.layer.id}-${index}-${clusterSetting.color}`;

                      if (this.findLayerById(clusterCountLayerId) !== null) {
                        this.map.removeLayer(this.findLayerById(clusterCountLayerId));
                      }
                    });
                  }
                }
              });
            }
            for (let [key, layerData] of this.linesMap.entries()) {
              if(layerData && layerData.layer && layerData.layer.id)
                this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'none');
            }
            for (let [key, layerData] of this.polygonsMap.entries()) {
              if(layerData && layerData.layer && layerData.layer.id)
                this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'none');
            }
            break;
          case EventService.SHOW_LAYERS:
            for (let [key, layerData] of this.markersMap.entries()) {
              const layer = key as LayerController;
              this.processClusters(layer)
            }
            for (let [key, layerData] of this.linesMap.entries()) {
              if(layerData && layerData.layer && layerData.layer.id)
                this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'visible');
            }
            for (let [key, layerData] of this.polygonsMap.entries()) {
              if(layerData && layerData.layer && layerData.layer.id)
                this.map.setLayoutProperty(layerData.layer.id, 'visibility', 'visible');
            }
            break;
          case EventService.LAYER_VISIBILITY_TOGGLED:
            const layer = event.data as LayerController;

            if (layer.visibleOnMap) {
              this.processClusters(layer)
            } else {
              const entityRepresentations: EntityRepresentation[] = Array.from(layer.markers.keys());
              entityRepresentations.forEach((representation, index) => {
                if (representation.visualization instanceof IconVisualization) {
                  const clusterSettings: ClusterSetting[] = representation.visualization.clusterSettings;
                  const markers = layer.markers.get(representation);

                  // If the clustering is enabled for icon representation, cluster the markers
                  if (clusterSettings?.length && markers.length) {
                    this.showMergedMarkers = false;
                    clusterSettings.forEach(clusterSetting => {
                      const clusterLayerId = `cluster-${layer.layer.id}-${index}-${clusterSetting.color}`;
                      const clusterCountLayerId = `cluster-count-${layer.layer.id}-${index}-${clusterSetting.color}`;

                      if (this.findLayerById(clusterCountLayerId) !== null) {
                        this.map.removeLayer(this.findLayerById(clusterCountLayerId));
                      }
                    });
                  }
                }
              });
            }
            break;
        }
      });
    this.getZoomCoordinates();
    this.getZoomLevel();
  }

  /**
   * Called when leaflet map is ready to use
   */

  onMapReady(map: L.Map) {
    if (!this.satelliteSession || !this.roadMapsession) {
      this.getMapSession()
        .then((sessionData) => {
          this.satelliteSession = sessionData.satelliteSession;
          this.roadMapsession = sessionData.roadmapSession;
          this.apiKey = sessionData.apiKey;
          this.mapReady(map);
        }).catch((error) => {
        console.error('Error fetching session:', error);
      });
    } else {
      this.mapReady(map);
      // get a reference to the map instance
      this.map = map;
      // emit the coordinate of the clicked point on the map
      this.map.on('click', (event) => {
        this.onMapClicked.emit(new Coordinate({latitude: event.latlng.lat, longitude: event.latlng.lng}));
      });
      if (this.mapType && this.mapType === MapTileType.SATELLITE) {
        // get a reference to tile layer
        this.tileLayer = L.tileLayer(environment.map.leaflet.tileUrlSatellite, {
          minZoom: 0,
          maxZoom: environment.map.leaflet.maxZoom
        }).addTo(this.map);
      } else {
        // get a reference to tile layer
        this.tileLayer = L.tileLayer(environment.map.leaflet.tileUrlRoadMap, {
          minZoom: 0,
          maxZoom: environment.map.leaflet.maxZoom
        }).addTo(this.map);
      }

      // emit map initialize event
      this.onMapInitialized.emit(this.getGeoQuery());

      // if map is initialized with a specific zoom level, broadcast it
      if (localStorage.getItem('mapZoomProvider') !== null) {
        if (localStorage.getItem("mapZoomProvider") === 'leaflet') {
          this.onZoomChange(localStorage.getItem("mapZoomLevel"));
        } else if (localStorage.getItem("mapZoomProvider") === 'mapLibre') {
          this.onZoomChange(Math.round(Number(localStorage.getItem("mapZoomLevel"))) + 1);
        }
        return;
      }
      if (this.mapSettings?.zoomLevel) {
        this.onZoomChange(this.mapSettings.zoomLevel);
      }
    }
  }

  /**
   * Called when map move ends
   */
  onMapMoveEnd(data: L.LeafletEvent) {
    // broadcast map change event when map is moved only if there are no drawn shapes
    localStorage.setItem('mapCoordinateLat', this.map.getCenter().lat);
    localStorage.setItem('mapCoordinateLng', this.map.getCenter().lng);
    if (this.getFirstDrawnShape() === null) {
      this.onMapBoundariesChanged.emit(this.getGeoQuery());
    }
  }

  onZoomChange(event): void {
    this.layoutService.onMapZoomChange(event);
    localStorage.setItem("mapZoomProvider", "leaflet");
    localStorage.setItem("mapZoomLevel", event);
  }

  /**
   * Called when polygon draw extension is ready to use
   * @param drawControl
   */
  onDrawReady(drawControl) {
    this.drawControl = drawControl;

    // localize the controls for leaflet-draw
    this.handleLocalization();
  }

  onDrawCircleInitiated() {
    // https://stackoverflow.com/questions/15775103/leaflet-draw-mapping-how-to-initiate-the-draw-function-without-toolbar
    this.drawShape(new L.Draw.Circle(this.map, this.drawControl.options.circle));
  }

  onDrawRectangleInitiated(): void {
    this.drawShape(new L.Draw.Rectangle(this.map, this.drawControl.options.rectangle));
  }

  onDrawPolygonInitiated(): void {
    this.drawShape(new L.Draw.Polygon(this.map, this.drawControl.options.polygon));
  }

  /**
   * Starts and editing session to draw a shape
   * @param shape Shape to be drawn: Can be rectangle, circle or polygon
   */
  drawShape(shape) {
    // first remove the existing shape
    this.removeDrawnShapes();
    // https://stackoverflow.com/questions/15775103/leaflet-draw-mapping-how-to-initiate-the-draw-function-without-toolbar
    shape.enable();
  }

  /**
   * Called automatically when a polygon has just been drawn
   */
  onShapeCreated(data) {
    data.layer.addTo(this.map);
    // save the shapes in an storage for later reference
    this.markers.push(data.layer);

    // broadcast the information containing the geological boundaries of the drawn shape
    this.onShapeDrawn.emit(this.getGeoQuery());
  }

  onDrawnShapeRemoved(): void {
    this.removeDrawnShapes();
  }

  /**
   * Helper method to remove the shape
   */
  private removeDrawnShapes() {
    this.markers.forEach(m => this.map.removeLayer(m));
    this.markers = [];
  }


  onZoomIn(): void {
    this.map.zoomIn();
  }

  onZoomOut(): void {
    this.map.zoomOut();
  }

  panAndZoom(coordinate: Coordinate, zoomLevel: number): void {
    this.map.setZoomAround(coordinate.toLatLongArray(), zoomLevel);
  }

  pan(coordinate: Coordinate): void {
    this.map.panTo(coordinate.toLatLongArray());
  }

  onMapAreaChanged(): void {
    this.map.invalidateSize();
  }

  addIconMarker(coordinate: Coordinate, options: any): void {
    const icon = new L.Icon.Default();
    icon.options.shadowSize = [0, 0];
    const m = marker(coordinate.toLatLongArray(), {icon: icon});
    this.markers.push(m);
    m.addTo(this.map);
  }

  addGeoQueryAsShape(geoQuery: NGSIGeoQuery): void {
    const m: any = this.transformGeoQuery(geoQuery);
    this.markers.push(m);
    m.addTo(this.map);
  }

  fitBounds(bounds: [number, number, number, number]): void {
    throw new Error('Method not implemented.');
  }

  setMaxBounds(bounds?: [number, number, number, number]): void {
    throw new Error('Method not implemented.');
  }

  addGeoQueryAsBackgroundShape(ngsiGeoQuery: NGSIGeoQuery): void {
    throw new Error('Method not implemented.');
  }

  clearBackgroundShapes(): void {
    throw new Error('Method not implemented.');
  }

  drawnShapeExists(): boolean {
    throw new Error('Method not implemented.');
  }

  clearMarkers(): void {
    for (let [key, markers] of this.markersMap) {
      this.markersMap.set(key, []);
    }
  }

  public getBounds(): Coordinate[] {
    const bounds: any = this.map.getBounds();
    const north = bounds.getNorth();
    const south = bounds.getSouth();
    const west = bounds.getWest();
    const east = bounds.getEast();
    // First and last coordinates must be the same to construct a loop for MongoDB queries
    return [
      new Coordinate({longitude: west, latitude: north}),
      new Coordinate({longitude: east, latitude: north}),
      new Coordinate({longitude: east, latitude: south}),
      new Coordinate({longitude: west, latitude: south}),
      new Coordinate({longitude: west, latitude: north})
    ];
  }

  getShapeCoordinates(shape: any): Coordinate[] {
    let coordinates = [];

    // Leaflet maintains coordinates at two levels. For polygons, coordinates that we are looking for are located at the first child of parent array.
    if (shape.length === 1) {
      coordinates = shape[0];
    }

    // convert LatLng to Coordinate
    const convertedCoordinates: Coordinate[] = coordinates.map(coordinate => new Coordinate({
      longitude: coordinate.lng,
      latitude: coordinate.lat
    }));

    // close the loop
    convertedCoordinates.push(convertedCoordinates[0]);
    return convertedCoordinates;
  }

  /**
   * Returns necessary information to query entities based on the visible area or a selected polygon
   */
  public getGeoQuery(): NGSIGeoQuery {
    // if there is a drawn object, geoquery will be created based it boundaries
    // for now we only consider the first item of the shapes as there is no requirement to consider multiple shapes
    const drawnShape: any = this.getFirstDrawnShape();
    if (drawnShape) {
      if (drawnShape instanceof L.Polygon || drawnShape instanceof L.Rectangle) {
        const coordinates: Coordinate[] = this.getShapeCoordinates(drawnShape.getLatLngs());
        return NGSIGeoQuery.createPolygonQuery(coordinates);

      } else if (drawnShape instanceof L.Circle) {
        const coordinate = drawnShape.getLatLng();
        const center = new Coordinate({longitude: coordinate.lng, latitude: coordinate.lat});
        const radius = Math.round(drawnShape.getRadius()); // in meters
        return NGSIGeoQuery.createCircularQuery(center, radius, true); // TODO isMax should be configurable
      }
    }
    // otherwise geoquery will be created based on the visible area of the map
    else {
      const coordinates = this.getBounds();
      return NGSIGeoQuery.createPolygonQuery(coordinates);
    }
  }

  /**
   * Retrieves the first shape, if any, from the list of markers
   * @private
   */
  private getFirstDrawnShape(): any {
    const shapes: any[] = this.markers
      .filter(m => m instanceof L.Polygon || m instanceof L.Circle || m instanceof L.Rectangle);
    if (shapes?.length > 0) {
      return shapes[0];
    } else {
      return null;
    }
  }

  public transformGeoQuery(query: NGSIGeoQuery): any {
    if (query.geometry === Geometry.POINT) {
      return L.circle(query.coordinates, query.maxMinDistance.distance);
    } else {
      return L.polygon(query.coordinates);
    }
  }

  /**
   * Sets the current localization for the leaflet-draw controls
   */
  private handleLocalization() {
    this.translateService.get(['Radius', 'Click and drag to draw circle.', 'Click and drag to draw rectangle.',
      'Click to start drawing shape.', 'Click to continue drawing shape.', 'Click first point to close this shape.',
      '<strong>Error:</strong> shape edges cannot cross!', 'Release mouse to finish drawing.']).subscribe(translateResults => {

      L.drawLocal = {
        draw: {
          toolbar: {
            buttons: {}
          },
          handlers: {
            circle: {
              tooltip: {
                start: translateResults['Click and drag to draw circle.']
              },
              radius: translateResults['Radius']
            },
            circlemarker: {
              tooltip: {}
            },
            marker: {
              tooltip: {}
            },
            polygon: {
              tooltip: {
                start: translateResults['Click to start drawing shape.'],
                cont: translateResults['Click to continue drawing shape.'],
                end: translateResults['Click first point to close this shape.']
              }
            },
            polyline: {
              error: translateResults['<strong>Error:</strong> shape edges cannot cross!']
            },
            rectangle: {
              tooltip: {
                start: translateResults['Click and drag to draw rectangle.']
              }
            },
            simpleshape: {
              tooltip: {
                end: translateResults['Release mouse to finish drawing.']
              }
            }
          }
        }
      };
    });
  }

  /**
   * Initializes the map options. They are retrieved from map settings if available. Otherwise, environment settings
   * are used.
   * */
  private initializeMapOptions() {
    const center = this.mapSettings ? {
      lat: localStorage.getItem("mapCoordinateLat") !== null ? localStorage.getItem("mapCoordinateLat") : this.mapSettings.point.coordinates[0],
      lng: localStorage.getItem("mapCoordinateLng") !== null ? localStorage.getItem("mapCoordinateLng") : this.mapSettings.point.coordinates[1]
    } : {lat: environment.map.leaflet.center.lat, lng: environment.map.leaflet.center.lng};
    this.mapOptions = {
      center: L.latLng(center),
      zoom: this.mapSettings?.zoomLevel ? this.mapSettings.zoomLevel : environment.map.leaflet.defaultZoom,
      zoomControl: false,
      maxZoom: environment.map.leaflet.maxZoom,
      minZoom: environment.map.leaflet.minZoom,
      //maxBounds: [[environment.map.bounds[1], environment.map.bounds[0]], [environment.map.bounds[3], environment.map.bounds[2]]],
      attributionControl: false
    };
    if (localStorage.getItem("mapZoomProvider") === 'leaflet') {
      this.mapOptions.zoom = localStorage.getItem("mapZoomLevel");
    } else if (localStorage.getItem("mapZoomProvider") === 'mapLibre') {
      this.mapOptions.zoom = Math.round(Number(localStorage.getItem("mapZoomLevel"))) + 1;
    }
  }

  isMarker(object) {
    return object instanceof Marker;
  }

  isLine(object) {
    return object instanceof Linestring;
  }

  isPolygon(object) {
    return object instanceof Polygon;
  }

  onMarkerClicked(marker: Marker) {
    marker.callback();
  }

  /**
   * Sets the visibility of tooltip for the marker.
   * @param marker the marker
   * @param layerDisplaysTooltip whether the layer items has a tooltip display or not
   * @param display whether the tooltip will be displayed or not
   * */
  public setTooltipVisibility(marker: Marker, layerDisplaysTooltip: boolean, display: boolean) {
    let style: string;
    marker.displayTooltip = layerDisplaysTooltip && display;

    if (marker.style) {
      if (display) {
        style = `background-image: url(${marker.iconUrl});  transition: transform 0.2s; transform-origin: bottom center; transform: scale(2); background-size: 100% 100%; background-repeat:no-repeat; width: ${marker.width}px; height: ${marker.height}px`;
      } else {
        style = `background-image: url(${marker.iconUrl}); background-size: 100% 100%; background-repeat:no-repeat; width: ${marker.width}px; height: ${marker.height}px`;
      }
      marker.style = style;
    } else {
      if (display) {
        marker.htmlStyle = 'transition: transform 0.2s; transform-origin: bottom center; transform: scale(2)';
      } else {
        marker.htmlStyle = '';
      }
    }

  }

  /**
   * Retrieves the list of Maplibre layers for the given id. If no id is given, it returns the all of them.
   * @param id the layer id
   * @returns the map layers
   */
  private getClusterLayers(id = null): any[] {
    if (id) {
      const layerId = `cluster-${id}`
      const layerCountId = `cluster-count-${id}`
      return this.map.options.layers
        .filter(layer => layer.id.startsWith(layerId) || layer.id.startsWith(layerCountId));
    } else {
      return this.map.options.layers
        .filter(layer => layer.id.startsWith('cluster-'));
    }
  }

  private processClusters(layerController: LayerController) {
    if (!this.map) {
      return;
    }
    if(!layerController.visibleOnMap){
      return;
    }
    const entityRepresentations: EntityRepresentation[] = Array.from(layerController.markers.keys());
    entityRepresentations.forEach((representation, index) => {
      if (representation.visualization instanceof IconVisualization) {
        const clusterSettings: ClusterSetting[] = representation.visualization.clusterSettings;
        const markers = layerController.markers.get(representation);
        // If the clustering is enabled for icon representation, cluster the markers
        if (clusterSettings?.length && markers.length) {
          this.showMergedMarkers = false;
          clusterSettings.forEach(clusterSetting => {

            const clusterLayerId = `cluster-${layerController.layer.id}-${index}-${clusterSetting.color}`;
            const clusterCountLayerId = `cluster-count-${layerController.layer.id}-${index}-${clusterSetting.color}`;

            const geoJsonLayer = L.geoJSON({
                id: clusterLayerId,
                type: 'FeatureCollection',
                  features: markers.map(item => {
                  if (typeof item.getLatLng().lng !== 'undefined' && typeof item.getLatLng().lat !== 'undefined') {
                    return {
                      type: 'Feature',
                      geometry: {
                        type: 'Point',
                        coordinates: [item.getLatLng().lng, item.getLatLng().lat]
                      },
                      properties: item // Customize properties as needed
                    };
                  } else {
                    console.warn('Invalid coordinates for marker:', item);
                    return null; // Filter out invalid markers
                  }
                }).filter(Boolean) // Remove null values from the array
              }, {
              pointToLayer  : (feature, latlng) => {
                const visualization = representation.visualization as IconVisualization;
                let icon;
                let marker;
                if (visualization.icon && !visualization.icon.includes("defibrillator")) {
                  const iconUrl = `../assets/map-markers/${visualization.icon}`;
                  icon = L.icon({
                    iconUrl: iconUrl,
                    iconSize: [visualization.sizeFactor * 35, visualization.sizeFactor * 35],
                    iconAnchor: [17, 25],
                    className: visualization.className,
                  });

                  marker = L.marker(latlng, {icon});
                } else if (visualization.html) {
                  // Use the HTML snippet to create a DivIcon with color
                  const divIcon = L.divIcon({
                    className: visualization.className || 'custom-div-icon',
                    html: visualization.html, // Set color here
                    iconSize: [25, 25], // Adjust size as necessary
                    iconAnchor: [17, 25]
                  });
                  marker = L.marker(latlng, {icon: divIcon});
                }else if(visualization.icon.includes("defibrillator")){
                  const selectedIcon: NbIconDefinition = this.iconLibraries.getIcon(visualization.icon, "uruk"); // Select the bus icon from the uruk pack
                  let svgContent = selectedIcon.icon.getContent();

                  // Set the SVG width and height dynamically based on the sizeFactor
                  svgContent = svgContent.replace(
                    /<svg /,
                    `<svg width="${visualization.sizeFactor * 35}px" height="${visualization.sizeFactor * 35}px" `
                  );
                  marker = L.marker(latlng, {
                    icon: L.divIcon({
                      html: svgContent, // Use the selected SVG icon as HTML
                      className: 'custom-div-icon', // Optional custom class for additional styling
                      iconSize: [25, 25], // Adjust size as necessary
                      iconAnchor: [17, 25]
                    })
                  });
                }

                // Bind a tooltip to the marker
                marker.bindTooltip(layerController.createTooltip(feature.properties), {
                  permanent: false, // Tooltip only shows on hover
                  direction: 'bottom'  // Tooltip will appear above the marker
                });
                marker.on('click', () => {
                  const clickEvents = feature.properties._events?.click;

                  if (Array.isArray(clickEvents) && clickEvents.length > 0 && typeof clickEvents[0].fn === 'function') {
                    clickEvents[0].fn(); // Call the first click function in the array
                  } else {
                    console.warn('No valid click event function found.');
                  }
                });
                return marker; // Default marker without a custom icon
              }
            });

            // Initialize the cluster group with custom icon for count display
            const markerClusterGroup = L.markerClusterGroup({
              id: clusterCountLayerId,
              spiderfyOnMaxZoom: false,
              showCoverageOnHover: false,
              zoomToBoundsOnClick: false,
              iconCreateFunction: function (cluster) {
                const count = cluster.getChildCount(); // Get the number of markers in the cluster

                // Create a divIcon for displaying cluster count
                return  L.divIcon({
                  html: `<div style="position: relative;">
                           <span style="color: white;
                                        background-color: ${clusterSetting.color};
                                        border-radius: 50%;
                                        padding: 12px;
                                        border: 2px solid white;
                                        font-size: 8px;
                                        line-height: 8px;">
                             ${count}
                           </span>
                         </div>`,
                  className: 'custom-cluster-count', // Custom CSS class for styling
                });
              }
            });
            if (this.findLayerById(clusterLayerId) !== null) {
              markerClusterGroup.removeLayer(geoJsonLayer);
            }
            markerClusterGroup.addLayer(geoJsonLayer);
            if (this.findLayerById(clusterCountLayerId) !== null) {
                this.map.removeLayer(this.findLayerById(clusterCountLayerId));
            }
            markerClusterGroup.addTo(this.map);
          });
        }
      }
    });
  }

  findLayerById(layerId: string) {
    if (!layerId) {
      return this.map._layers; // Return all layers if no specific ID is provided
    }
    for (const layerKey in this.map._layers) {
      if (Object.prototype.hasOwnProperty.call(this.map._layers, layerKey)) {
        const layer = this.map._layers[layerKey];

        // Check if the layer matches the provided ID
        if ((layer.options && layer.options.id === layerId) || layer.customId === layerId) {
          return layer; // Layer found
        }
      }
    }
    return null; // No layer found
  }




  /**
   * Decides whether the markers are supposed to be shown on the map or not. If a marker does not belong to a cluster,
   * it should be displayed on the map.
   * @param clusterLayerId the cluster layer id
   * @param markers the marker list
   * */
  private handleMarkerVisibilityForClusters(clusterLayerId, markers) {
    //const unClusteredFeatures = this.map.querySourceFeatures(clusterLayerId, {filter: ['!', ['has', 'point_count']]});

    for (const marker of markers) {
      marker.visible = true;
    }
  }

  getZoomLevel() {
    if (localStorage.getItem("mapZoomProvider") === 'mapLibre' && this.mapSettings) {
      this.mapSettings.zoomLevel = Number(localStorage.getItem("mapZoomLevel"));
    } else if (localStorage.getItem("mapZoomProvider") === 'leaflet' && this.mapSettings) {
      this.mapSettings.zoomLevel = Number(localStorage.getItem("mapZoomLevel")) - 1;
    }
  }

  getZoomCoordinates() {
    if (localStorage.getItem("mapZoomProvider") === 'mapLibre' && this.mapSettings) {
      this.mapSettings.point.coordinates = [Number(localStorage.getItem("mapCoordinateLat")), Number(localStorage.getItem("mapCoordinateLng"))];
    } else if (localStorage.getItem("mapZoomProvider") === 'leaflet' && this.mapSettings) {
      this.mapSettings.point.coordinates = [Number(localStorage.getItem("mapCoordinateLat")), Number(localStorage.getItem("mapCoordinateLng"))];
    }
  }

  private async getMapSession() {
    try {
      const response = await this.mapService.getSessionOfMap();
      return response;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  private mapReady(map: L.Map) {
    this.map = map;
    // emit the coordinate of the clicked point on the map
    this.map.on('click', (event) => {
      this.onMapClicked.emit(new Coordinate({latitude: event.latlng.lat, longitude: event.latlng.lng}));
    });
    if (this.mapType && this.mapType === MapTileType.SATELLITE) {
      // get a reference to tile layer
      this.tileLayer = L.tileLayer(environment.map.leaflet.tileUrl === '' ? environment.map.leaflet.tileUrlSatellite : environment.map.leaflet.tileUrl + 'session=' + this.satelliteSession + '&key=' + this.apiKey, {
        minZoom: 0,
        maxZoom: environment.map.leaflet.maxZoom
      }).addTo(this.map);
    } else {
      // get a reference to tile layer
      this.tileLayer = L.tileLayer(environment.map.leaflet.tileUrl === '' ? environment.map.leaflet.tileUrlRoadMap : environment.map.leaflet.tileUrl + 'session=' + this.roadMapsession + '&key=' + this.apiKey, {
        minZoom: 0,
        maxZoom: environment.map.leaflet.maxZoom
      }).addTo(this.map);
    }

    // emit map initialize event
    this.onMapInitialized.emit(this.getGeoQuery());

    // if map is initialized with a specific zoom level, broadcast it
    if(localStorage.getItem('mapZoomProvider') !== null){
      if(localStorage.getItem("mapZoomProvider") === 'leaflet'){
        this.onZoomChange(localStorage.getItem("mapZoomLevel"));
      }else if(localStorage.getItem("mapZoomProvider") === 'mapLibre'){
        this.onZoomChange(Math.round(Number(localStorage.getItem("mapZoomLevel"))) + 1);
      }
      return;
    }
    if (this.mapSettings?.zoomLevel) {
      this.onZoomChange(this.mapSettings.zoomLevel);
    }
  }
}
