import {Injectable} from '@angular/core';
import {Observable, share, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {Notification} from '../../../shared/models/notification.model';
import {AuthService} from '../auth/auth.service';

/**
 * A websocket management service to handle socket connections on component's lifecycle
 */
@Injectable()
export class SocketService {

  /**
   * The socket object created after a connection is established with the websocket server
   */
  private socket: WebSocketSubject<Notification> = webSocket<Notification>(environment.subscriptions.endpoint);

  /**
   * Observable map where the keys correspond to subscription ids. The corresponding observable can be used observe notifications generated for that subscription.
   * @private
   */
  private observables: Map<String, Observable<Notification>> = new Map<String, Observable<Notification>>();

  constructor(private authService: AuthService) {
  }

  /**
   * Returns a sharable observation for the given subscription if that can be used by multiple subscribers. For instance more than one panel might be monitoring the same
   * subscription. As the observable is created with the RxJS' #share operator, each client should properly unsubscribe from the returned observable so that
   * the observable transits into the "cold state" properly.
   *
   * @param subscriptionId
   */
  subscribe(subscriptionId: string): Observable<Notification> {
    let observable: Observable<Notification> = this.observables.get(subscriptionId);
    if (!observable) {
      let bindMessage: any = {
        realmId: this.authService.getRealmId(),
        subscriptionId: subscriptionId,
      };

      observable = this.socket.multiplex(
        () => ({bind: bindMessage}),
        () => ({unbind: bindMessage}),
        message => message.subscriptionId === subscriptionId
      ).pipe(
        share({ connector: () => new Subject()}),
      );
      this.observables.set(subscriptionId, observable);
    }

    return observable;
  }
}
