<div class="notifications-tooltip">
  <div class="title">
    {{'Notifications'|translate}}
  </div>
  <div class="body">
    <div (click)="openNotification(notification)" *ngFor="let notification of notifications">
      <uruk-svg
        [paths]="['M3.49998 0.416656C2.32627 0.416656 1.37498 1.36795 1.37498 2.54166C1.37498 3.71536 2.32627 4.66666 3.49998 4.66666C4.67369 4.66666 5.62498 3.71536 5.62498 2.54166C5.62498 1.36795 4.67369 0.416656 3.49998 0.416656ZM1.37498 6.08332C1.28111 6.082 1.18792 6.09934 1.10082 6.13434C1.01372 6.16934 0.934442 6.2213 0.867597 6.28721C0.800751 6.35312 0.747672 6.43165 0.711442 6.51825C0.675213 6.60485 0.656555 6.69778 0.656555 6.79166C0.656555 6.88553 0.675213 6.97846 0.711442 7.06506C0.747672 7.15166 0.800751 7.2302 0.867597 7.2961C0.934442 7.36201 1.01372 7.41397 1.10082 7.44897C1.18792 7.48398 1.28111 7.50132 1.37498 7.49999H2.08331V13.1667H1.37498C1.28111 13.1653 1.18792 13.1827 1.10082 13.2177C1.01372 13.2527 0.934442 13.3046 0.867597 13.3705C0.800751 13.4364 0.747672 13.515 0.711442 13.6016C0.675213 13.6882 0.656555 13.7811 0.656555 13.875C0.656555 13.9689 0.675213 14.0618 0.711442 14.1484C0.747672 14.235 0.800751 14.3135 0.867597 14.3794C0.934442 14.4453 1.01372 14.4973 1.10082 14.5323C1.18792 14.5673 1.28111 14.5846 1.37498 14.5833H5.62498C5.71884 14.5846 5.81203 14.5673 5.89913 14.5323C5.98624 14.4973 6.06551 14.4453 6.13236 14.3794C6.1992 14.3135 6.25228 14.235 6.28851 14.1484C6.32474 14.0618 6.3434 13.9689 6.3434 13.875C6.3434 13.7811 6.32474 13.6882 6.28851 13.6016C6.25228 13.515 6.1992 13.4364 6.13236 13.3705C6.06551 13.3046 5.98624 13.2527 5.89913 13.2177C5.81203 13.1827 5.71884 13.1653 5.62498 13.1667H4.91665V6.79166C4.91665 6.40066 4.59931 6.08332 4.20831 6.08332H2.79164H1.37498Z']"
        [svgContainerStyle]="'width: 0.4vw;fill: #989898'"
        [viewbox]="'0 0 7 15'">
      </uruk-svg>
      <div>
        <span>{{notification.shortTitle}}</span>
        <span>{{notification.date}}</span>
      </div>
    </div>
  </div>
  <div class="flex-centered footer">
    {{'See all' | translate}}
  </div>
</div>
