import {NGSITemporalRelation} from '../../enums/ngsi-query.enum';
import {TimeUtil} from '../../utils/time-util';
import {UrukTemporalQuery} from '../query/uruk-temporal-query.model';

export class NGSITemporalQuery {
  /**
   * Temporal relationship to be used in the query like before, after, between, ...
   */
  timerel: NGSITemporalRelation;

  /**
   * Time as the second operand to the specified temporal relationship than the temporal value obtained from the entity. Acts as the start time if an end time is specified.
   */
  time: Date;

  /**
   * End time as the second operand for the temporal property, if applicable
   */
  endTime?: Date;

  /**
   * Attribute of the target entity containing the temporal data
   */
  timeproperty: string = 'observedAt';

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.timerel = data.timerel;
    this.time = new Date(data.time);
    this.endTime = new Date(data.endTime);
    this.timeproperty = data.timeproperty;
  }

  /**
   * Creates an NGSI query based on the existing information
   */
  createNGSIParameters(): any {
    const query = {
      timerel: this.timerel,
      time: TimeUtil.serializeISODatetimeUTC(this.time),
    };

    if (this.endTime) {
      query['endTime'] = TimeUtil.serializeISODatetimeUTC(this.endTime);
    }

    return query;
  }

  copy(that: NGSITemporalQuery): void {
    this.timerel = that.timerel;
    this.time = that.time;
    this.endTime = that.endTime;
    this.timeproperty = that.timeproperty;
  }

}
