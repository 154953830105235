export class DefibrillatorUpdatedData {
    id: string;
    type: string;
    description: DefibrillatorProperty[];
    adultPadExpirationDate: DefibrillatorProperty[];
    pediatricPadExpirationDate: DefibrillatorProperty[];
    guaranteeStartDate: DefibrillatorProperty[];
    companyName: DefibrillatorProperty[];

    constructor(id: string, adultPadExpirationDate?: DefibrillatorProperty[], pediatricPadExpirationDate?:  DefibrillatorProperty[] , description?:  DefibrillatorProperty[],  guaranteeStartDate?: DefibrillatorProperty[],  companyName?: DefibrillatorProperty[]) {
      if (id === undefined || id === null) {
        throw new Error('ID is required.');
      }
      if (!description && !adultPadExpirationDate && !pediatricPadExpirationDate && !guaranteeStartDate && !companyName) {
        throw new Error('At least one property (description, adultPadExpirationDate, pediatricPadExpirationDate, guaranteeStartDate, companyName) must be provided.');
    }
      this.id = id;
      this.type = 'Defibrillator';
      this.description = description;
      this.adultPadExpirationDate = adultPadExpirationDate;
      this.pediatricPadExpirationDate = pediatricPadExpirationDate;
      this.guaranteeStartDate = guaranteeStartDate;
      this.companyName = companyName;
    }
  }

export class DefibrillatorProperty {
    type: string;
    value: string;

    constructor(value: string) {
      this.type = 'Property';
      this.value = value;
    }
}
