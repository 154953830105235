import {DisplayGrid, GridType} from 'angular-gridster2';

/**
 * Default options for Gridster
 * */
export const GRIDSTER_OPTIONS = {
  minCols: 6,
  minRows: 6,
  maxCols: 6,
  maxRows: 6,
  fixedColWidth: 105,
  fixedRowHeight: 105,
  allowMultiLayer: true,
  draggable: {
    enabled: true
  },
  resizable: {
    enabled: true,
  },
  itemChangeCallback: undefined,
  itemResizeCallback: undefined,
  swap: false,
  pushItems: true,
  displayGrid: DisplayGrid.Always,
  disableScrollHorizontal: true,
  disableScrollVertical: true,
  enableBoundaryControl: true
};

/**
 * Default options for Gridster whose items are draggable if and only if they have the css class specified in
 * 'dragHandleClass' settings.
 * */
export const CONDITIONALLY_DRAGGABLE_GRIDSTER_OPTIONS = {
  ...GRIDSTER_OPTIONS, draggable: {
    enabled: true,
    ignoreContent: true,
    dragHandleClass: 'draggable'
  }
};

/**
 * Default options for readonly Gridster
 * */
export const READONLY_GRIDSTER_OPTIONS = {
  ...GRIDSTER_OPTIONS,
  draggable: {
    ...GRIDSTER_OPTIONS.draggable,
    enabled: false
  },
  resizable: {
    enabled: false
  }
};

/**
 * Default options for Gridster in a dialog component.
 * */
export const GRIDSTER_DIALOG_OPTIONS = {
  gridType: GridType.Fit,
  displayGrid: DisplayGrid.Always,
  minCols: 6,
  minRows: 6,
  mobileBreakpoint: 0,
  allowMultiLayer: true,
  rowHeightRatio: 1,
  setGridSize: true,
  draggable: {
    enabled: false
  },
  resizable: {
    enabled: false
  }
};
