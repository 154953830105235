import {Component, Injector, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {AlertUtil} from '../utils/alert.util';
import {EntityResult} from '../../../shared/models/schema/entity-result';
import {AlertState} from '../enums/alert-state.enum';
import {environment} from '../../../../environments/environment';
import {NGSIQuery} from '../../../shared/models/ngsi/ngsi-query.model';
import {QueryOptions} from '../../../shared/models/ngsi/query-options';
import {BaseDialogTemplateComponent} from '../../page/templates/dialogs/base-dialog-template.component';
import {BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: '',
})
export class BaseAlertDialogComponent extends BaseDialogTemplateComponent implements OnInit {
  alerts: EntityResult[];

  _AlertUtil = AlertUtil;

  // whether the action buttons are disabled
  disabled: boolean = false;

  // subject to keep the updated version of alerts when they are confirmed/suppressed 
  private updatedAlertsSubject: BehaviorSubject<EntityResult[]> = new BehaviorSubject([]);
  private updatedAlertsObs: Observable<EntityResult[]> = this.updatedAlertsSubject.asObservable();

  constructor(protected dialogRef: NbDialogRef<BaseAlertDialogComponent>, injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToAlertUpdates();
  }

  /**
   * Updates the alerts state.
   * @param state the new state of alerts
   * */
  protected updateAlerts(state: AlertState) {
    // disable the action button to prevent further clicks
    this.disabled = true;
    this.alerts.forEach(alert => {
      this.contextService.addTemporalUpdate(alert.id, 'Alert', 'state', state).subscribe(() => {
        this.refreshAlert(alert);
      });
    })
  }

  /**
   * Fetches the alert and checks the number of its states to decide whether the alert is updated or not.
   * If the alert is updated, it pushes the updated one to corresponding BehaviourSubject object. Otherwise, it keeps calling itself with
   * 'retryCount-1' until it retrieves the updated alert or retryCount becomes zero.
   * @param retryCount the retry count to retrieve alert
   * */
  private refreshAlert(alert: EntityResult, retryCount = environment.retryCount.fetchAlert): void {
    if (retryCount) {
      setTimeout(() => {
        const alertStateCount = alert.entity.state.length;
        this.getAlert(alert.id).then(updatedAlert => {
          // the state of alert is changed, close the dialog with updated alert
          if (updatedAlert.getEntityResult().entity.state.length !== alertStateCount) {
            this.updatedAlertsSubject.next(this.updatedAlertsSubject.value.concat(updatedAlert.getEntityResult()));
          }
          // it is not updated, retry to fetch alert
          else {
            this.refreshAlert(alert, retryCount - 1);
          }
        });
      }, environment.timeouts.fetchAlert);
    }
  }

  /**
   * Subscribes to {@link updatedAlertsObs} observable.
   */
  private subscribeToAlertUpdates() {
    this.updatedAlertsObs
      .pipe(takeUntil(this.destroy$))
      .subscribe(alerts => {
        // when all alerts are updated, close the dialog with the updated ones
        if (alerts.length === this.alerts?.length) {
          this.dialogRef.close(alerts);
        }
      })
  }

  /**
   * Returns the Alert entity for the given id as Promise.
   * @param alertId
   * */
  private getAlert(alertId) {
    const query: NGSIQuery = new NGSIQuery({
      filter: {
        id: [alertId]
      }
    });
    return this.contextService.getEntity(query, new QueryOptions(true)).toPromise();
  }

  protected mapContextData(any): void {
  }
}
