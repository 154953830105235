<div class="icons flex-centered">
  <div class="selection flex-centered" (click)="onLeftSelected.emit()">
    <i [ngClass]="leftIcon"></i>
    <div>{{ leftText }}</div>
  </div>

  <div class="vrule"></div>

  <div class="selection flex-centered" (click)="onRightSelected.emit()">
    <i [ngClass]="rightIcon"></i>
    <div>{{ rightText}}</div>
  </div>
</div>
