<!-- Page breadcrumb start-->
<ng-container *ngIf="page">
  <div class="overlay-container page-breadcrumb-container">
    <div class="page-breadcrumb">
      <img class="uruk-logo" src="assets/images/uruk-name-white.png">
      <!-- Page title-->
      <div class="page-title">
        <!-- readonly page title in view mode -->
        <ng-container *ngIf="displayMode=='view'">
          <span>{{rootMenuItem | translate}}</span> - {{page.title}}
        </ng-container>
        <!-- editable page title in edit mode -->
        <ng-container *ngIf="displayMode=='edit'">
          <span>{{rootMenuItem | translate}}</span> - <input [(ngModel)]="page.title" fieldSize="tiny" nbInput>
        </ng-container>
      </div>
      <!-- Page icon-->
      <div>
        <i class="fa {{'fa-' + page.icon}} page-icon" [ngClass]="{'dashed-border':displayMode=='edit'}" (click)="openIconSelectionDialog()"></i>
      </div>
      <!-- Breadcrumb icons to add new page and edit the current one-->
      <ng-container *ngIf="breadcrumbIconsVisible">
        <!-- Icon to add new page -->
        <uruk-svg [width]="'100%'"
                  [height]="'100%'"
                  [viewbox]="'0 0 11 15'"
                  [paths]="['M6.875 0H1.375C0.61875 0 0 0.675 0 1.5V13.5C0 14.325 0.61875 15 1.375 15H9.625C10.3813 15 11 14.325 11 13.5V4.5L6.875 0ZM7.5625 9H6.1875V10.5C6.1875 10.914 5.8795 11.25 5.5 11.25C5.1205 11.25 4.8125 10.914 4.8125 10.5V9H3.4375C3.058 9 2.75 8.664 2.75 8.25C2.75 7.836 3.058 7.5 3.4375 7.5H4.8125V6C4.8125 5.586 5.1205 5.25 5.5 5.25C5.8795 5.25 6.1875 5.586 6.1875 6V7.5H7.5625C7.942 7.5 8.25 7.836 8.25 8.25C8.25 8.664 7.942 9 7.5625 9Z']"
                  [svgContainerClass]="'uruk-icon left-margined'"
                  (click)="openCreatePageDialog()" [ngClass]="{'disabled':displayMode=='edit'}">
        </uruk-svg>
        <!-- Icon to edit current page-->
        <uruk-svg *ngIf="displayMode == 'view'"
                  [width]="'100%'"
                  [height]="'100%'"
                  [viewbox]="'0 0 18 15'"
                  [paths]="['M14.3789 1.41699C13.8361 1.41699 13.2933 1.61223 12.8789 2.00358L12 2.83366L15 5.66699L15.8789 4.83691C16.7069 4.05491 16.7069 2.78629 15.8789 2.00358C15.4645 1.61223 14.9217 1.41699 14.3789 1.41699ZM10.875 3.89616L2.25 12.042V14.8753H5.25L13.875 6.72949L10.875 3.89616Z']"
                  [svgContainerClass]="'uruk-icon'"
                  (click)="openEditMode()">
        </uruk-svg>
      </ng-container>
    </div>
    <!-- Section to display/hide breadcrumb icons -->
    <div *ngIf="displayMode == 'view'" class="breadcrumb-arrows">
      <nb-icon *ngIf="!breadcrumbIconsVisible" icon="arrowhead-right-outline" (click)="breadcrumbIconsVisible=!breadcrumbIconsVisible"></nb-icon>
      <nb-icon *ngIf="breadcrumbIconsVisible" icon="arrowhead-left-outline"  (click)="breadcrumbIconsVisible=!breadcrumbIconsVisible"></nb-icon>
    </div>
    <!-- Additional breadcrumb icons in edit mode-->
    <div *ngIf="displayMode == 'edit'" class="breadcrumb-edit-page">
      <div>
        <!-- Icon to edit current page. It is disabled with scss-->
        <div class="breadcrumb-edit-icon-container">
          <i class="fa fa-pencil-alt"></i>
        </div>
        <!-- Icon to save page updates-->
        <div class="breadcrumb-edit-icon-container" (click)="save()" *ngIf="displayMode == 'edit'">
          <i class="fa fa-check-circle"></i>
        </div>
      </div>
      <!-- Icon to close edit mode-->
      <div class="close-edit-page" *ngIf="displayMode == 'edit'" (click)="closeEditMode()">
        <i class="fa fa-times-circle "></i>
      </div>
      <!--
      <uruk-svg
                [width]="'18'"
                [height]="'15'"
                [viewbox]="'0 0 24 24'"
                [paths]="['M8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.424 16 16 12.424 16 8C16 3.576 12.424 0 8 0ZM11.7656 10.6344C12.0784 10.9472 12.0784 11.4528 11.7656 11.7656C11.6096 11.9216 11.4048 12 11.2 12C10.9952 12 10.7904 11.9216 10.6344 11.7656L8 9.1312L5.3656 11.7656C5.2096 11.9216 5.0048 12 4.8 12C4.5952 12 4.3904 11.9216 4.2344 11.7656C3.9216 11.4528 3.9216 10.9472 4.2344 10.6344L6.8688 8L4.2344 5.3656C3.9216 5.0528 3.9216 4.5472 4.2344 4.2344C4.5472 3.9216 5.0528 3.9216 5.3656 4.2344L8 6.8688L10.6344 4.2344C10.9472 3.9216 11.4528 3.9216 11.7656 4.2344C12.0784 4.5472 12.0784 5.0528 11.7656 5.3656L9.1312 8L11.7656 10.6344Z']"
                [svgContainerClass]="'uruk-icon'"
                (click)="closeEditMode()"
                >
      </uruk-svg>
-->
    </div>
    <!-- Entity search component to filter entities displayed on the map -->
    <div class="search-entity">
      <entity-search [entities]="entities"></entity-search>
    </div>
    <div class="language-entity">
      <nb-select [(selected)]="currentLang" (selectedChange)="languageChange($event)" size="small"   style="width: 1px;">
        <nb-option value="tr">tr</nb-option>
        <nb-option value="en">en</nb-option>
      </nb-select>
    </div>
  </div>
</ng-container>
<!-- Layers start -->
<ng-container *ngIf="(layerControllers && layerControllers.length) || displayMode == 'edit'" >
  <div class="d-flex justify-content-center">
    <div class="layer-container">
      <!-- Layers -->
      <!-- TODO define separate elements for edit and display modes -->
      <!-- In the edit mode, do not display Alert layer-->
      <div class="layers" [ngClass]="{'disabled':displayMode=='edit', 'multiple-layers':(displayMode === 'view' && layerControllers.length > 1) || layerControllers.length > 2}">
        <ng-container *ngFor="let layer of layerControllers">
          <layer-thumbnail [hidden]="displayMode === 'edit' && layer.layer.name === 'Alert'"
                           [displayMode]="displayMode"
                           [layer]="layer.layer"
                           [active]="layer.visibleOnMap"
                           (onDeleteClicked)="deleteLayer(layer.layer.id)"
                           (onIconClicked)="layer.toggleLayerVisibility()"
                           (onDestroy)="clearLayerExecutionOnDestroy(layer)">
          </layer-thumbnail>
        </ng-container>
      </div>
      <!-- Icon to hide/display layers-->
      <div class="layer-visibility-icon-container">
        <!-- Icon to toggle the visibility of layers. Available in only view mode-->
        <div *ngIf="displayMode === 'view'" class="layer">
          <nb-action class="control-button"
                     (click)="onLayersVisibilityToggled()"
                     [nbPopover]="(layersVisible ? 'Hide Layers' : 'Show Layers') | translate"
                     nbPopoverTrigger="hint" nbPopoverPlacement="bottom">
            <uruk-svg [width]="'100%'"
                      [height]="'100%'"
                      [viewbox]="'0 0 18 21'"
                      [paths]="['M13 12.667C10.1425 12.667 8.7857 15.1164 8.45653 15.8156C8.40153 15.9323 8.40153 16.0676 8.45653 16.1842C8.7857 16.8838 10.1425 19.3337 13 19.3337C15.8475 19.3337 17.2052 16.9015 17.5402 16.1924C17.5977 16.0703 17.5977 15.9304 17.5402 15.8083C17.2052 15.0991 15.8475 12.667 13 12.667ZM13 13.917C14.1504 13.917 15.0833 14.8499 15.0833 16.0003C15.0833 17.1507 14.1504 18.0837 13 18.0837C11.8496 18.0837 10.9167 17.1507 10.9167 16.0003C10.9167 14.8499 11.8496 13.917 13 13.917ZM13 14.7503C12.6685 14.7503 12.3505 14.882 12.1161 15.1164C11.8817 15.3509 11.75 15.6688 11.75 16.0003C11.75 16.3318 11.8817 16.6498 12.1161 16.8842C12.3505 17.1186 12.6685 17.2503 13 17.2503C13.3315 17.2503 13.6495 17.1186 13.8839 16.8842C14.1183 16.6498 14.25 16.3318 14.25 16.0003C14.25 15.6688 14.1183 15.3509 13.8839 15.1164C13.6495 14.882 13.3315 14.7503 13 14.7503Z','M7 0C3.134 0 0 3.134 0 7C0 10.966 4.404 16.82 6.226 19.071C6.626 19.565 7.374 19.565 7.774 19.071C7.90377 18.9107 8.04665 18.7321 8.2001 18.5372C7.34699 17.6384 6.84689 16.699 6.63916 16.2575C6.56216 16.0941 6.56216 15.9047 6.63916 15.7414C7.09291 14.7776 8.94143 11.4382 12.817 11.3357C13.5225 9.81648 14 8.30589 14 7C14 3.134 10.866 0 7 0ZM7 9.5C5.619 9.5 4.5 8.381 4.5 7C4.5 5.619 5.619 4.5 7 4.5C8.381 4.5 9.5 5.619 9.5 7C9.5 8.381 8.381 9.5 7 9.5']"
                      [svgContainerClass]="layersVisible ? 'uruk-icon selected' : 'uruk-icon'">
            </uruk-svg>
          </nb-action>
        </div>
        <!-- Button to add new layers in edit mode-->
        <div *ngIf="displayMode === 'edit'" class="layer">
          <button class="btn-primary add-layer-button" (click)="openAddLayerDialog()">
            <nb-icon icon="plus"></nb-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- Layers end -->

<div class="functions-menu ml-auto" [ngClass]="{'disabled':displayMode==='edit'}">
  <nb-actions size="small" class="control-actions">
    <!-- Icon to display/hide layers -->
    <nb-action class="control-button panel-visibility-icon-container"
               [nbPopover]="(panelsVisible ? 'Hide Panels' : 'Show Panels') | translate"
               (click)="onPanelsVisibilityToggled()"
               nbPopoverTrigger="hint" nbPopoverPlacement="bottom">
      <uruk-svg [width]="'100%'"
                [height]="'100%'"
                [viewbox]="'0 0 26 26'"
                [paths]="['M5 21H13.3842C13.0409 20.4897 12.8119 20.0459 12.6848 19.7759C12.6023 19.6009 12.6023 19.3979 12.6848 19.2229C13.1786 18.1741 15.2138 14.5 19.5 14.5C20.0345 14.5 20.5341 14.5571 21 14.6592V5C21 3.897 20.103 3 19 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21ZM18 7V12C18 12.552 17.552 13 17 13H12C11.448 13 11 12.552 11 12V7C11 6.448 11.448 6 12 6H17C17.552 6 18 6.448 18 7ZM7 11H9V12C9 13.657 10.343 15 12 15H13V17C13 17.552 12.552 18 12 18H7C6.448 18 6 17.552 6 17V12C6 11.448 6.448 11 7 11Z','M20 16C16.571 16 14.9429 18.9393 14.5479 19.7783C14.4819 19.9183 14.4819 20.0807 14.5479 20.2207C14.9429 21.0602 16.571 24 20 24C23.417 24 25.0462 21.0815 25.4482 20.2305C25.5172 20.084 25.5172 19.916 25.4482 19.7695C25.0462 18.9185 23.417 16 20 16ZM20 17.5C21.3805 17.5 22.5 18.6195 22.5 20C22.5 21.3805 21.3805 22.5 20 22.5C18.6195 22.5 17.5 21.3805 17.5 20C17.5 18.6195 18.6195 17.5 20 17.5ZM20 18.5C19.6022 18.5 19.2206 18.658 18.9393 18.9393C18.658 19.2206 18.5 19.6022 18.5 20C18.5 20.3978 18.658 20.7794 18.9393 21.0607C19.2206 21.342 19.6022 21.5 20 21.5C20.3978 21.5 20.7794 21.342 21.0607 21.0607C21.342 20.7794 21.5 20.3978 21.5 20C21.5 19.6022 21.342 19.2206 21.0607 18.9393C20.7794 18.658 20.3978 18.5 20 18.5Z']"
                [svgContainerClass]="panelsVisible ? 'toggle-icon selected' : 'toggle-icon'">
      </uruk-svg>
    </nb-action>
    <nb-action class="control-button panel-visibility-icon-container"
               [nbPopover]="'Report Page' | translate"
               (click)="handlePageReportClick()"
               nbPopoverTrigger="hint" nbPopoverPlacement="bottom">
      <uruk-svg [width]="'100%'"
                [height]="'100%'"
                [viewbox]="'0 0 16 16'"
                [paths]="['M3.99996 1.33301C3.26663 1.33301 2.66663 1.93301 2.66663 2.66634V13.333C2.66663 14.0663 3.26663 14.6663 3.99996 14.6663H9.22131C8.86931 14.1977 8.76932 13.5856 8.99866 13.0322C9.25866 12.4049 9.86556 11.9997 10.5442 11.9997H10.6666V11.333C10.6666 10.2283 11.562 9.33301 12.6666 9.33301H13.3333V5.88509C13.3333 5.53176 13.1927 5.19238 12.9427 4.94238L9.72392 1.72363C9.47392 1.47363 9.13454 1.33301 8.78121 1.33301H3.99996ZM8.66663 2.33301L12.3333 5.99967H8.66663V2.33301ZM9.33329 7.99967C9.50396 7.99967 9.67465 8.06465 9.80465 8.19499C10.0653 8.45499 10.0653 8.87703 9.80465 9.13769L7.80465 11.1377C7.54398 11.3977 7.12194 11.3977 6.86194 11.1377L5.99996 10.2757L5.13798 11.1377C4.87798 11.3977 4.45527 11.3977 4.19527 11.1377C3.93527 10.8777 3.93527 10.455 4.19527 10.195L5.5286 8.86165C5.78927 8.60165 6.21131 8.60165 6.47131 8.86165L7.33329 9.72363L8.86194 8.19499C8.99194 8.06465 9.16263 7.99967 9.33329 7.99967ZM12.6888 10.6572C12.5122 10.66 12.3439 10.7327 12.2209 10.8594C12.0978 10.9861 12.0301 11.1564 12.0325 11.333V13.333H10.5429C10.2409 13.333 10.0893 13.6984 10.3033 13.9124L12.1731 15.7822C12.4638 16.0729 12.9346 16.0729 13.2252 15.7822L15.095 13.9124C15.3083 13.6984 15.1568 13.333 14.8541 13.333H13.3658V11.333C13.3671 11.2438 13.3504 11.1553 13.3168 11.0727C13.2832 10.99 13.2333 10.915 13.1702 10.852C13.107 10.789 13.0319 10.7393 12.9492 10.7058C12.8665 10.6724 12.778 10.6558 12.6888 10.6572Z']"
                [svgContainerClass]="'uruk-icon selected'">
      </uruk-svg>
    </nb-action>
  </nb-actions>
  <!-- Date selection -->
  <uruk-date-selection style="width: 10vw"
                       [temporalQuery]="_cbrokerService.temporalContext.value"
                       [parentComponentId]="'six_panel_map'"
                       (onTemporalQueryChanged)="onTemporalQueryChanged($event)">
  </uruk-date-selection>
  <!-- Action buttons -->
  <nb-actions size="small" class="control-actions right">
    <nb-action class="control-button thin-border-right"
               nbPopoverTrigger="hint" nbPopoverPlacement="bottom">
      <uruk-svg [width]="'100%'"
                [height]="'100%'"
                [viewbox]="'0 0 24 24'"
                [paths]="['M12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3ZM12 14C8.996 14 3 15.508 3 18.5V20C3 20.552 3.448 21 4 21H20C20.552 21 21 20.552 21 20V18.5C21 15.508 15.004 14 12 14Z']"
                [svgContainerClass]="'uruk-icon'">
      </uruk-svg>
    </nb-action>
    <nb-action *ngIf="displayMode == 'view'" class="control-button "  (mouseenter)="displayNotifications = true" (mouseleave)="displayNotifications = false"
               nbPopoverTrigger="hint" nbPopoverPlacement="bottom">
      <uruk-svg [width]="'100%'"
                [height]="'100%'"
                [viewbox]="'0 0 24 24'"
                [paths]="['M12 2C11.172 2 10.5 2.672 10.5 3.5V4.19531C7.91318 4.86209 6 7.2048 6 10V16L4.46484 17.1562H4.46289C4.32099 17.2466 4.20418 17.3713 4.12327 17.5188C4.04236 17.6663 3.99996 17.8318 4 18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H12H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18C20 17.8318 19.9576 17.6663 19.8767 17.5188C19.7958 17.3713 19.679 17.2466 19.5371 17.1562L18 16V10C18 7.2048 16.0868 4.86209 13.5 4.19531V3.5C13.5 2.672 12.828 2 12 2ZM10 20C10 21.1 10.9 22 12 22C13.1 22 14 21.1 14 20H10Z']"
                [svgContainerClass]="'uruk-icon'">
      </uruk-svg>
      <notifications-tooltip *ngIf="displayNotifications"></notifications-tooltip>
    </nb-action>
    <!-- Delete page icon -->
    <nb-action *ngIf="displayMode == 'edit'" class="control-button delete-page" (click)="deletePage()"
               nbPopoverTrigger="hint" nbPopoverPlacement="bottom">
      <i class="fa fa-trash"></i>
    </nb-action>
  </nb-actions>
</div>

<div style="width: 100%; height: 100%">
  <gridster *ngIf="page && dashboard" [options]="options">
    <div [hidden]="!panelsVisible">
      <ng-container *ngFor="let item of dashboard; let i = index">
        <!-- Panel layer -->
        <gridster-item [item]="item" *ngIf="item.layerIndex === gridLocationLayerIndex.PANEL && (getPanelAtLocation(item, i) || displayMode === 'edit')"
                       (itemResize)="onPanelResized(item.id)"
                       [ngClass]="{ 'hidden': !getPanelAtLocation(item, i), 'dashed-border': displayMode == 'edit' }">
          <ng-container *ngTemplateOutlet="panel; context: { location: item, index: i}"></ng-container>
        </gridster-item>
        <!-- Map layer -->
        <gridster-item *ngIf="item.layerIndex === gridLocationLayerIndex.MAP" [item]="item" (itemResize)="onMapResized()">
          <uruk-map #map [layers]="layerControllers" [mapSettings]="page.mapSettings"></uruk-map>
          <!-- bottom layer elements -->
          <drawing-toolbox class="overlay-container drawing-toolbox {{shapeToolsetClass}}"></drawing-toolbox>
          <zoom-toolbox class="overlay-container zoom-toolbox {{zoomButtonsClass}}"></zoom-toolbox>
        </gridster-item>
      </ng-container>
    </div>
  </gridster>
</div>

<!-- Generic template for panels -->
<ng-template #panel let-location="location" let-index="index">
  <ng-container>
      <!-- If there is a panel object associated with the current location, load it here -->
      <uruk-panel *ngIf="getPanelAtLocation(location, index)" [panel]="getPanelAtLocation(location, index)"
                  (onPanelDelete)="deletePanel(getPanelAtLocation(location,index))"
                  (onPanelUpdate)="updatePanel(getPanelAtLocation(location,index))" class="draggable"
                  [editMode]="'edit' == displayMode" [pageId]="page.id"></uruk-panel>

      <!-- Otherwise, display a helper so that user can add a new panel to the page in edit mode-->
      <div class="draggable" style="height: 100%;width: 100%">
        <i *ngIf="displayMode === 'edit' && !getPanelAtLocation(location, index)" class="fa fa-plus"
           (click)="addPanelToLocation(location, index)"></i>
      </div>
  </ng-container>
</ng-template>
