import {NGSIResult} from '../models/ngsi/ngsi-result';
import {AggregationResult} from '../models/schema/aggregation/aggregation-result';
import {BucketResult} from '../models/schema/aggregation/bucket-result';
import {TemporalValue} from '../models/schema/entity/temporal/temporal-value';
import {QueryOperator} from '../models/query/query-operator.model';
import {QueryOptions} from '../models/ngsi/query-options';
import {EntityResult} from '../models/schema/entity-result';
import {Notification} from '../models/notification.model';

export enum NGSIEntity {
  ALERT = 'Alert',
  JUNCTION = 'Junction',
  JUNCTION_ARM = 'JunctionArm',
  ON_STREET_PARKING = 'OnStreetParking',
  WEATHER_POLLUTION_OBSERVATION = 'WeatherPollutionObservation',
  MGUB_CASTLE = 'MGUBCastle',
  SENSOR = 'Sensor',
  TAK = 'Tak',
  DEFIBRILLATOR_TEST= 'DefibrillatorTest',
  DEFIBRILLATOR_POSITION= 'DefibrillatorPosition',
  HEALTHCASE= 'HealthCase',
  HEALTHCASEEVENT= 'HealthCaseEvent',
  DEFIBRILLATOR= 'Defibrillator',
  RELAY = 'Relay',
  JEMUSTAK = 'JemusTak',
  GENDARMERIESTATION = 'GendarmerieStation',
  ATU = 'Atu',
  GEKO = 'Geko',
  BITRESULTS = 'BitResults',
  TCDDCouplingPosition = 'TcddCouplingPosition',
  TCDDTrain = 'TcddTrain'
}

/**
 * Utility class to transform raw NGSI response to model classes
 */
export class NGSIResultUtil {
  /**
   * Maps the entity returned from Context Broker to the corresponding NGSI-LD types. If an unknown type is encountered,
   * the entity is returned directly
   * @param entity Entity value obtained from the context broker
   * @param queryOptions Options such temporal, key values, etc. that were used to retrieve the entity result
   */
  static mapEntity(entity: any, queryOptions: QueryOptions = new QueryOptions()): NGSIResult {
    return new NGSIResult(new EntityResult(entity, queryOptions));
  }

  /**
   * Maps each item returned from Context Broker to the corresponding NGSI-LD types.
   */
  static mapEntities(response: any[], queryOptions: QueryOptions): NGSIResult[] {
    return response.map(entity => {
      return this.mapEntity(entity, queryOptions);
    });
  }

  /**
   * Maps aggregation query results to NGSIResult entities
   * @param result
   * @param bucketAggregations
   * @param dataLevelCount
   */
  static mapAggregationResults(result: any, bucketAggregations: QueryOperator[], dataLevelCount: number): NGSIResult[] {
    // if the result is an array it should be a BucketResult
    if (Array.isArray(result)) {
      result = BucketResult.createBuckets(result, bucketAggregations, dataLevelCount);
      // wrap buckets with NGSIResult
      return result.map(value => {
        return new NGSIResult(value);
      });
    } else {
      return [new NGSIResult(new AggregationResult(result))];
    }
  }

  /**
   * Extracts value for a specific attribute of the entity. It handles key-value and full representations as well as single or multiple values of attributes.
   * @param entity Complete entity containing the attribute
   * @param attribute Name of the attribute of which value to be extracted
   * @param queryOptions Options used to retrieve the provided
   * @protected
   */
  public static extractEntityAttributeValue(entity: any,
                             attribute: string,
                             queryOptions: QueryOptions): any | any[] | TemporalValue[] {
    // if the attribute ends with '.value', which is the case when it is a KPI series, trim it
    if (attribute.endsWith('.value')) {
      attribute = attribute.substring(0, attribute.length - 6);
    }

    // the attribute might not have any value
    const attributeValue: any = entity[attribute];
    if (!attributeValue) {
      return null;
    }

    // special treatment for id as it is not a regular attribute
    if (attribute === 'id') {
      return entity[attribute];
    }

    // if it is not key-value representation, we assume that it is not a temporal representation
    if (queryOptions.keyValues) {
      return attributeValue;

    } else {
      if (queryOptions.isTemporal) {
        return attributeValue.map(singleValue => {
          return new TemporalValue({
            value: NGSIResultUtil.getAttributeValue(singleValue),
            observedAt: singleValue.observedAt,
            instanceId: singleValue.instanceId,
            type: singleValue.type
          });
        });
      } else {
        return this.getAttributeValue(attributeValue);
      }
    }
  }

  /**
   * Extracts value from a single attribute value in the form of:
   * {
   *   object: "urn:ngsi-ld:Junction:1234"
   *   observedAt: "2021-07-06T04:10:43.000000Z"
   *   type: "Relationship"
   * }
   * or
   * {
   *   observedAt: "2021-07-06T04:10:43.000000Z"
   *   type: "Property"
   *   value: "alarm"
   * }
   * @param singleValue
   * @private
   */
  private static getAttributeValue(singleValue: any): any {
    let value: any = singleValue.value;
    if (singleValue.type === 'Relationship') {
      value = singleValue.object;
    }
    return value;
  }

  /**
   * Merges the temporal update to the given entity so that entity would have the up-to-date information
   * @param entity
   * @param notification
   */
  static mergeTemporalUpdate(entity: EntityResult, notification: Notification): void {
    const updateField: string = NGSIResultUtil.extractUpdateField(notification);
    // update field contains an array such as:
    // {
    //  "id":"urn:ngsi-ld:JunctionArm:7913-3",
    //  "type":"JunctionArm",
    //  "numOfVehiclesPassed": [
    //    {
    //      "type":"Property",
    //      "value":600,
    //      "observedAt":"2022-07-26T09:45:00Z"
    //    }
    //  ]
    // }
    // so we are getting the zero index of the update field element
    entity.entity[updateField] = notification.eventPayload.content[updateField][0];
  }

  /**
   * Extracts the field, i.e. entity element, for which a notification is generated e.g. availableSpotNumber of and OffStreetParking
   * @param notification
   * @private
   */
  private static extractUpdateField(notification: Notification): string {
    return Object.keys(notification.eventPayload.content).find(key => key !== 'id' && key !== 'type');
  }
}
