import {GeoJson} from '../geometry/geo-json.model';
import {GeoJsonType} from '../../enums/geo-json-type.enum';
import {Point} from '../geometry/point.model';
import {Polygon} from '../geometry/polygon.model';

/**
 * A factory class to handle different types of GeoJson
 */
export class GeoJsonFactory {

  /**
   * Factory method to create a specific GeoJson type
   * @param data
   */
  static create(data: any): GeoJson {
    if (data.type === GeoJsonType.POINT) {
      return new Point(data);
    } else if (data.type === GeoJsonType.POLYGON) {
      return new Polygon(data);
    }
  }
}
