import {Injectable, Injector, NgZone} from '@angular/core';
import {NavigationService} from './navigation.service';
import {NbDialogService} from '@nebular/theme';
import {TemplateNameMap} from '../../modules/page/page-name-map';
import {Observable} from 'rxjs';

/**
 * A dedicated service to handle clicks on markers to prevent circular dependency between layer controllers and page templates.
 * It prevents layer controllers depending to the template component types directly.
 */
@Injectable({
  providedIn: 'root'
})
export class LayerMarkerClickHandlerService {
  private navigationService: NavigationService;
  private dialogService: NbDialogService;
  private zone: NgZone;

  constructor(injector: Injector) {
    this.navigationService = injector.get(NavigationService);
    this.dialogService = injector.get(NbDialogService);
    this.zone = injector.get(NgZone);

    // subscribe to marker click events
    this.navigationService.markerClickObservable$.subscribe(data => {
      this.handleMarkerClick(data[0], data[1]);
    });
  }

  /**
   * Opens the dialog dynamically for the given template type with the given context
   * @param dialogTemplate
   * @param dialogContext
   * @private
   */
  private handleMarkerClick(dialogTemplate: string, dialogContext: any): void {
    this.zone.run(() => {
      this.dialogService.open(TemplateNameMap.getComponentClass(dialogTemplate),
        {
          context: dialogContext,
          dialogClass: 'dialog-center',
          autoFocus: false // in order not to focus on buttons automatically
        });
    });
  }
}
