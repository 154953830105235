import {BaseMetaModel} from './base/base-meta-model.model';
export class MvbCommunication extends BaseMetaModel {

  ska1: string;
  oa1: string;
  oa2: string;
  oa3: string;
  ska2: string;

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.ska1 = data.ska1;
    this.oa1 = data.oa1;
    this.oa2 = data.oa2;
    this.oa3 = data.oa3;
    this.ska2 = data.ska2;
  }

}
