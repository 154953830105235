import {QueryOperatorExpr} from '../query/query-operator-expr.model';
import {QueryOptions} from './query-options';

/**
 * Parsed path item
 */
export class PathItem {
  /**
   * Element name
   */
  path: string;

  /**
   * Whether element is an array
   */
  isArray: boolean = false;

  constructor(data: any) {
    this.path = data.path;
    this.isArray = data.isArray;
  }
}

export class NGSIPath extends QueryOperatorExpr {
  /**
   * Path to access a specific value in an entity like availableSpotNumber, address[city], ... It is used as the first operand for the operator
   */
  propOrRelPath: string[];

  /**
   * Target data type for the whole path e.g. Text, Number, etc, Not used for relationships
   */
  targetType?: string;

  /**
   * Whether the path continues within a NGSI-LD data type
   */
  trailingPath: PathItem[];

  /**
   * True if the target property or relationship given by propOrRelPath is an array or not
   * */
  isPropOrRelArray = false;

  constructor(data?: any) {
    super(data);
    this.jsonClass = 'NGSIPath';
    if (!data) {
      return;
    }

    this.propOrRelPath = data.propOrRelPath;
    this.targetType = data.targetType;
    if (data.trailingPath) {
      this.trailingPath = data.trailingPath.map(path => new PathItem(path));
    }
    this.isTemporal = data.isTemporal;
    this.isPropOrRelArray = data.isPropOrRelArray;
  }

  createQuerySerialization(): string {
    return this.propOrRelPath.join(',');
  }

  /**
   * Runs this path on the given json
   * @param json
   * @param queryOptions
   * @param useValueField whether the actual value is retrieved from the relevant 'value' field.
   */
  executeOnJson(json: any, queryOptions = new QueryOptions(false, true), useValueField: boolean = true): any {
    json = json[this.propOrRelPath[0]];
    if (!json || (queryOptions.isTemporal && json.length === 0)) {
      return null;
    }

    if (queryOptions.isTemporal) {
      const valueField: string = useValueField ? this.getValueField(json[0]) : null;
      return (json as any[]).map(singleValue => this.executeOnSingleJson(singleValue, queryOptions, valueField));
    } else {
      const valueField: string = useValueField ? this.getValueField(json) : null;
      return this.executeOnSingleJson(json, queryOptions, valueField);
    }
  }

  /**
   * Applies the path on single json object
   * @param singleValue
   * @param queryOptions
   * @param valueField
   * @private
   */
  private executeOnSingleJson(singleValue: any, queryOptions: QueryOptions, valueField: string): any {
    const propOrRelValue: any = this.extractPropOrRelPath(singleValue);
    const trailingPathValue: any = this.extractTrailingPath(propOrRelValue, queryOptions);
    return this.applyQueryOptionsOnResult(trailingPathValue, queryOptions, valueField);
  }

  /**
   * Extracts the actual value by getting the content in the "value" field for non-key/value representations if such
   * field is provided.Otherwise, the complete content is returned.
   * @param trailingPathValue
   * @param queryOptions
   * @param valueField
   * @private
   */
  private applyQueryOptionsOnResult(trailingPathValue: any, queryOptions: QueryOptions, valueField: string): any {
    if (trailingPathValue) {
      if (!queryOptions.keyValues && valueField) {
        return trailingPathValue[valueField];
      } else {
        return trailingPathValue;
      }
    } else {
      return null;
    }
  }

  /**
   * Extracts value specified by the trailing path
   * @param value
   * @param queryOptions
   * @private
   */
  private extractTrailingPath(value: any, queryOptions: QueryOptions): any {
    if (this.trailingPath?.length > 0) {
      if (!queryOptions.keyValues) {
        value = value.value;
      }

      this.trailingPath.forEach(pathItem => {
        if (!pathItem.isArray) {
          if (value) {
            value = value[pathItem.path];
          } else {
            return null;
          }
        } else {
          // TODO handle trailing path for array elements
        }
      });
    }
    return value;
  }

  /**
   * Extracts value by following the steps specified in the "propOrRelPath". It skips the first step as it is processed at the beginning of the extraction process
   * @param singleValue
   * @private
   */
  private extractPropOrRelPath(singleValue: any): any {
    let json: any = singleValue;
    for (const path of this.propOrRelPath.slice(1)) {
      if (json) {
        json = json[path];
      } else {
        return null;
      }
    }
    return json;
  }

  private getValueField(value: any): string {
    return value.type === 'Relationship' ? 'object' : 'value';
  }

  containsTemporalElement(): boolean {
    return this.isTemporal;
  }

  get columnName(): string {
    let columnName: string = this.propOrRelPath.join('.');
    if (this.trailingPath?.length > 0) {
      columnName += '[' + this.trailingPath.map(tp => tp.path).join('.') + ']';
    }
    return columnName;
  }
}
