const MAX_COUNT = 2147483647;

/**
 * Model used for querying schemas such as KPI and Layer
 * */
export class SearchQuery {
  /**
   * Filter the schemas by given ids
   * */
  ids: string[];
  /**
   * Filter the schemas by the given text
   * */
  text: string;
  /**
   * Filter the schemas linked with one of the given domains
   * */
  domains: string[] = [];
  /**
   * Filter the schemas annotated with one of the given tags
   * */
  tags: string[] = [];
  /**
   * Number of records to retrieve
  */
  count = MAX_COUNT;
  /**
   * Page number to retrieve
   */
  page = 0;

  constructor(data?: any) {
    if (!data) {
      return;
    }
    this.text = data.text;
    this.domains = data.domains;
    this.tags = data.tags;
    this.ids = data.ids;
    if (data.count) {
      this.count = data.count;
    }
    if (data.page) {
      this.page = data.page;
    }
  }
}
