export class RandomUtil {
  private hasProducedError = false;

  public getRandomInt(min: number, max: number, errorMin = 0, errorMax = 0, isError = false): number {
    if (isError) {
      const shouldReturnError = !this.hasProducedError || Math.random() < 0.1;

      if (shouldReturnError) {
        this.hasProducedError = true;
        return Math.floor(Math.random() * (errorMax - errorMin + 1)) + errorMin;
      }
    }

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public resetErrorState() {
    this.hasProducedError = false;
  }
}
