<closeable-dialog class="add-panel-dialog">
  <div dialog-card-header>
    <label>
      {{ (panelInEditMode ? 'Update Panel' : 'Add Panel') | translate }}
    </label>
  </div>


  <div class="w-100 h-100 dialog-body" dialog-card-body>

    <uruk-path-selection *ngIf="step === undefined"
                         [leftIcon]="'fa fa-chart-pie'" [rightIcon]="'fa fa-chart-line'"
                         [leftText]="'Add KPI' | translate" [rightText]="'Add Prediction' | translate"
                         (onLeftSelected)="onAddKpiClicked()" (onRightSelected)="onAddPredictionClicked()"></uruk-path-selection>

    <uruk-kpi-search *ngIf="step === AddPanelState.KPI_SELECTION" [filterKpis]="filterKpis"
                     [displayErrors]="displayErrors" (kpiSelected)="handleKpiSelected($event)"></uruk-kpi-search>

    <uruk-analytics-search #analyticsSearch *ngIf="step === AddPanelState.ANALYTICS_SELECTION || step === AddPanelState.TRAINED_MODEL_SELECTION"
                           (onModelSelected)="onModelSelected($event)"
                           (onTrainedModelSelected)="onTrainedModelSelected($event)"></uruk-analytics-search>

    <!-- In the edit mode, wait until the selected kpi is loaded -->
    <div *ngIf="step === AddPanelState.CHART_TYPE_SELECTION && (!panelInEditMode || selectedKPI)">
      <label class="label">
        {{ 'Selected indicator' | translate }}
      </label>

      <div class="selected-kpi-container">
        <div class="title">
          {{ selectedKPI.name }}
        </div>

        <div class="description">
          {{ selectedKPI.description }}
        </div>
      </div>

      <!-- title -->
      <div class="chart-label">
        <label class="label">
          {{ 'Title' | translate }}
          <span class="form-error" *ngIf="displayErrors && !this.title">*</span>
        </label><br>
        <input [(ngModel)]="title" type="text" class="panel-header-input" nbInput>
      </div>

      <!-- temporal query -->
      <div *ngIf="selectedKPI.overrideTemporalQuery" class="temporal-query">
        <label class="label">
          {{ 'Panel Temporal Query' | translate }}
        </label><br>
        <uruk-date-selection [temporalQuery]="temporalQuery"
                             [showReset]="true"
                             [parentComponentId]="'add_panel_dialog'"
                             (onTemporalQueryChanged)="onTemporalQueryChanged($event)">
        </uruk-date-selection>
      </div>

      <!-- geo query -->
      <div *ngIf="selectedKPI.overrideGeoQuery" class="temporal-query">
        <label class="label">
          {{ 'Panel Geographical Query' | translate }}
        </label><br>
        <nb-checkbox [(checked)]="useRealmRegion">{{'Use Realm Region' | translate}}</nb-checkbox>
      </div>

      <!-- chart type -->
      <label class="chart-type-label label" *ngIf="!defaultChartType">
        {{ 'Chart type' | translate }}
        <span class="form-error" *ngIf="displayErrors && !this.selectedChartType">*</span>
      </label>

      <div class="table-container" *ngIf="!defaultChartType">
        <div class="chart-container" *ngFor="let chart of suitableChartTypes">
          <div class="chart-image" [ngClass]="{'selected': isChartSelected(chart.id)}"
               (click)="selectChartType(chart.id)">
            <!-- For more related icons, check https://cdn.materialdesignicons.com/3.3.92/ -->
            <uruk-svg
              [viewbox]="'0 0 24 24'"
              [paths]="chart.paths"
              [ngClass]="isChartSelected(chart.id) ? 'chart-svg-selected' : 'chart-svg-not-selected'"
              (click)="selectChartType(chart.id)"></uruk-svg>
          </div>

          <label [ngClass]="{'selected': isChartSelected(chart.id)}">
            {{ chart.name | translate }}
          </label>
        </div>
      </div>

      <!-- chart type specific settings -->
      <ng-container *ngIf="selectedChartType === ChartType.Indicator">
        <label class="label">
          {{ 'Icon' | translate }}
        </label><br>

        <div class="chart-indicator">
          <uruk-icon-selection (onIconSelected)="onIndicatorIconSelected($event)"></uruk-icon-selection>
        </div>
      </ng-container>

      <!-- dimensions -->
      <div class="axis-settings d-flex col-12">
        <div class="dimension-settings d-flex flex-column col-6"
             *ngIf="dimensionParameters?.length > 0 && chartDimensionAxesLabels?.length > 0">
          <label class="label">{{'Dimension Settings' | translate}}</label>
          <div *ngFor="let dimensionAxis of chartDimensionAxesLabels; let i = index" class="d-flex flex-column">

            <!-- dimension key or entity element to be used as dimension value -->
            <div class="d-flex settings-row">
              <div class="col-4 no-space">{{'Dimension Parameter' | translate}}</div>
              <div class="col-8 no-space">
                <option-input [values]="[selectedDimensionParameters[i]]"
                              [options]="getDimensionOptions(i)"
                              [multiValue]="false"
                              [displayMode]="'edit'"
                              (valuesChange)="handleDimensionParameterChange(i, $event)"></option-input>
              </div>
            </div>

            <!-- label -->
            <div class="d-flex settings-row row-margin">
              <div class="col-4 no-space">{{'Label' | translate}}</div>
              <div class="col-8 no-space">
                <input [(ngModel)]="dimensionSettings[i].label" type="text" nbInput>
                <span class="form-error" *ngIf="displayErrors && !dimensionSettings[i].label">*</span>
              </div>
            </div>
          </div>
        </div>

        <!-- series -->
        <div class="series-settings col-6" *ngIf="seriesDetails?.length > 0 && chartSeriesAxesLabels?.length > 0">
          <label class="label">{{'Value Settings' | translate}}</label>
          <div *ngFor="let seriesAxis of chartSeriesAxesLabels; let i = index">

            <!-- path -->
            <div class="d-flex settings-row">
              <div class="col-4 no-space">{{'Value Parameter' | translate}}</div>
              <div class="col-8 no-space">
                <option-input [values]="[seriesSettings[i].path]"
                              [options]="seriesParameters"
                              [multiValue]="false"
                              [displayMode]="'edit'"
                              (valuesChange)="handleSeriesParameterChange(i, $event)">
                </option-input>
              </div>
            </div>

            <!-- label -->
            <div class="d-flex settings-row row-margin">
              <div class="col-4 no-space">{{'Label' | translate}}</div>
              <div class="col-8 no-space">
                <input [(ngModel)]="seriesSettings[i].label" type="text" nbInput>
                <span class="form-error" *ngIf="displayErrors && !seriesSettings[i].label">*</span>
              </div>
            </div>

            <!-- Precision -->

            <div class="d-flex settings-row row-margin" *ngIf="selectedSeriesDetails[i].isNumeric">
              <div class="col-4 no-space">{{'Decimal Precision' | translate}}</div>
              <div class="col-8 no-space">
                <nb-select [(selected)]="seriesSettings[i].precision" [size]="'small'">
                  <nb-option *ngFor="let p of precisionList" [value]="p" class="add-panel-font-size">
                    {{p}}
                  </nb-option>
                </nb-select>
              </div>
            </div>

            <!-- Thousand Separator -->
            <div class="d-flex settings-row row-margin" *ngIf="selectedSeriesDetails[i].isNumeric">
              <div class="col-4 no-space">{{'Thousand Separator' | translate}}</div>
              <div class="col-8 no-space">
                <ngx-switcher
                  [sliderClass]="!seriesSettings[i].thousandSeparator ? 'disabled' : null"
                  [firstValue]="false"
                  [secondValue]="true"
                  [(value)]="seriesSettings[i].thousandSeparator">
                </ngx-switcher>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- TODO: Chart specific configurations -->

    <!-- pie chart -->
    <div class="chart-settings" *ngIf="selectedChartType">

      <div class="d-flex" *ngIf="selectedChartType.id === 'pie'">
        <div class="col-md-4">
          <label class="label">
            {{ 'Radius' | translate }}
          </label><br>

          <ngx-slider [(value)]="additionalSettingsModel.pie.radius" [options]="sliderOptions"></ngx-slider>
        </div>

        <div class="col-md-4 offset-1 switch-setting">
          <label class="label">
            {{ 'Display Labels' | translate }}
          </label><br>

          <ngx-switcher
            [firstValue]="false"
            [secondValue]="true"
            [(value)]="additionalSettingsModel.pie.displayLabels">
          </ngx-switcher>
        </div>
      </div>

      <div class="d-flex" *ngIf="selectedChartType.id === 'doughnut'">
        <div class="col-md-4">
          <label class="label">
            {{ 'Radius' | translate }}
          </label><br>

          <ngx-slider [(value)]="additionalSettingsModel.doughnut.innerRadius"
                      [(highValue)]="additionalSettingsModel.doughnut.outerRadius"
                      [options]="sliderOptions"></ngx-slider>
        </div>
      </div>


      <!-- line chart -->
      <div class="d-flex" *ngIf="selectedChartType.id === 'line'">
        line
      </div>

      <!-- vertical bar chart -->
      <div class="d-flex" *ngIf="selectedChartType.id === 'vbar'">
        vbar
      </div>

      <!-- horizontal bar chartt -->
      <div class="d-flex" *ngIf="selectedChartType.id === 'hbar'">
        hbar
      </div>

      <!-- gauge -->
      <div class="d-flex" *ngIf="selectedChartType.id === 'gauge'">
        gauge
      </div>

      <!-- metric chart -->
      <div class="d-flex" *ngIf="selectedChartType.id === 'metric'">
        metric
      </div>

      <!-- scatter chart -->
      <div class="d-flex" *ngIf="selectedChartType.id === 'scatter'">
        scatter
      </div>

    </div>

  </div>

  <div dialog-card-footer>
    <button *ngIf="step === AddPanelState.KPI_SELECTION"
            type="submit" nbButton status="primary" class="float-right" (click)="nextStep()">
      {{ 'Next' | translate }}
    </button>

    <!-- In the edit mode, the user is not allowed to go back kpi selection -->
    <button *ngIf="step === AddPanelState.CHART_TYPE_SELECTION && !panelInEditMode"
            type="submit" nbButton status="primary" (click)="previousStep()">
      {{ 'Previous' | translate }}
    </button>

    <button *ngIf="step === AddPanelState.CHART_TYPE_SELECTION"
            type="submit" nbButton status="primary" class="float-right" (click)="savePanelWithKPI()">
      {{ 'Save' | translate }}
    </button>

    <button *ngIf="step === AddPanelState.TRAINED_MODEL_SELECTION"
            type="submit" nbButton status="primary" (click)="previousStep()">
      {{ 'Previous' | translate }}
    </button>

    <button *ngIf="step === AddPanelState.TRAINED_MODEL_SELECTION && selectedTrainedModel"
            type="submit" nbButton status="primary" class="float-right" (click)="savePanelWithAnalytics()">
      {{ 'Save' | translate }}
    </button>

  </div>
</closeable-dialog>
