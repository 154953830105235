import {ObjectUtil} from './object-util';
import {DataFormatUtil} from './data-format-util';
import {ChartDataFormat} from '../enums/chart-data-format';

/**
 * Utility class to provide functions related to eChart options
 */
export class ChartOptionsUtil {

  /**
   * Merges the given chart options to the default one.
   * @param defaultOptions the default chart options
   * @param chartOptions options to be merged to the default ones
   */
  public static mergeChartOptions(defaultOptions: any, chartOptions: any): any {
    // save the xAxis data which is used to populate xAxis labels
    const xAxisData = chartOptions.xAxis.data;

    // move the xAxis data of the default options to chart options
    // and reset its data to be able to merge options later
    chartOptions.xAxis.data = defaultOptions.xAxis.data;
    defaultOptions.xAxis.data = [];

    chartOptions.xAxis.axisLabel.interval = (index, value) => {
      return value !== '';
    };
    // show the axisTick for the xAxis data which will be not visible
    chartOptions.xAxis.axisTick = {
      interval: (index, value) => {
        return xAxisData[index] === '';
      }
    };
    chartOptions.xAxis.axisLabel.formatter = (value, index) => {
      return xAxisData[index];
    };

    // apply the duplicate handler function to xAxis data to discard duplicates from both xAxis and series
    if (chartOptions.xAxis.duplicateHandler) {
      // KEEP_FIRST option keeps the first of duplicates in xAxis.data and discards the others
      if (chartOptions.xAxis.duplicateHandler === 'KEEP_FIRST') {
        // get the indexes of duplicates and remove them from xAxis.data
        const uniqueXAxisData = new Set();
        let duplicateIndexes = [];
        chartOptions.xAxis.data = chartOptions.xAxis.data.filter((data, index) => {
          if (uniqueXAxisData.has(data)) {
            duplicateIndexes.push(index);
            return false;
          } else {
            uniqueXAxisData.add(data);
            return true;
          }
        });

        // remote the duplicates from series as well
        duplicateIndexes = duplicateIndexes.reverse();
        defaultOptions.series.forEach(series => {
          duplicateIndexes.forEach(index => series.data.splice(index, 1));
        });
      }
    }

    if (chartOptions.series) {
      chartOptions.series.forEach(series => {
        // process series mark point settings
        if (series.markPoint) {
          series.markPoint.data[0].symbol = `image://assets/images/${series.markPoint.data[0].symbol}`;
          // handle the formatter
          if (series.markPoint.data[0].label.formatter === 'SERIES_LAST_VALUE_FORMATTER') {
            const seriesLastDataIndex = defaultOptions.series[0].data.length - 1;
            series.markPoint.data[0].label.formatter = () => {
              const value = DataFormatUtil.formatValue(defaultOptions.series[0].data[seriesLastDataIndex].value, ChartDataFormat.DECIMAL, 2);
              return `{title|Şimdi}:\n{value|${value}}`;
            };
            series.markPoint.data[0].label.rich.value.color = defaultOptions.series[0].data[seriesLastDataIndex].itemStyle.color;
          }
        }
      });
    }

    return ObjectUtil.mergeDeep(defaultOptions, chartOptions);
  }
}
