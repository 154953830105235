import {LayerController} from './layer.controller';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {catchError, forkJoin, Observable, of, take} from 'rxjs';
import {map} from 'rxjs/operators';
import {Layer} from '../../shared/models/layer.model';
import {Page} from '../../shared/models/page.model';
import {Injector} from '@angular/core';
import {Marker} from '../../shared/models/map/marker.model';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {NGSIEntity} from '../../shared/utils/ngsi-result-util';


export class TcddTrainsLayerController extends  LayerController {
  displaysTooltip: boolean = true;
  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
  }

  protected getDialogContext(result: NGSIResult): any {
    const query: NGSIQuery = new NGSIQuery();
    query.filter = new NGSIFilter();
    const activeTrainId = result.getEntityResult().entity.activeTrainId ? result.getEntityResult().entity.activeTrainId[0].value : '-';
    const passiveTrainId = result.getEntityResult().entity.passiveTrainId ? result.getEntityResult().entity.passiveTrainId[0].value : '-';

    const activeTrainRefQuery = this.cbrokerService.getEntity(this.getActiveOrPassiveTrainQuery(activeTrainId), new QueryOptions(false)).pipe(catchError(() => of(null)));
    const passiveTrainQuery = this.cbrokerService.getEntity(this.getActiveOrPassiveTrainQuery(passiveTrainId), new QueryOptions(false)).pipe(catchError(() => of(null)));
    const contextObservable: Observable<any> = forkJoin([activeTrainRefQuery, passiveTrainQuery]).pipe(
      take(1),
      map(response => {
        return {
          activeTrain: response[0]?.getEntityResult(),
          passiveTrain: response[1]?.getEntityResult()
        };
      })
    );

    return {
      page: this.popupPage,
      entity: result.getEntityResult(),
      asyncContext: contextObservable
    };
  }

  protected beforeEntitiesProcessed(results: NGSIResult[]): NGSIResult[] {
    const entitiesFound: Set<string> = new Set();
    const finalResults: NGSIResult[] = [];
    results.forEach(result => {
      const trainRef: string = this.getTrainRefFromNGSIResult(result);
      if (!entitiesFound.has(trainRef)) {
        finalResults.push(result);
        entitiesFound.add(trainRef);
      }
    })
    return finalResults;
  }

  private getTrainRefFromNGSIResult(ngsiResult: NGSIResult): string {
    return ngsiResult.getEntityResult().getSingleElementValue('trainRef');
  }

  protected getActiveOrPassiveTrainQuery(trainId) {
    return new NGSIQuery({
      filter: {
        type: [NGSIEntity.TCDDTrain] ,
        q : {
          jsonClass: 'NGSIQueryTerm',
          path: {
            jsonClass: 'NGSIPath',
            propOrRelPath: [
              'id'
            ],
            isTemporal: false,
            isPropOrRelArray: false
          },
          op: '==',
          value: [
            trainId
          ]
        }
      },
      limit: 1000,
      lastNDays: 365
    });
  }



  createTooltip(marker: Marker): string {
    const id = marker.entity.entity.id;
    const passiveTrainId = marker.entity.getSingleElementValue('passiveTrainId');
    let html = '<div class="entity-tooltip">';
    html += `<div class="text">${id}</div>`;
    html += `<div class="text">${passiveTrainId}</div>`;
    html += '</div';

    return html;
  }

}
