import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addQuarters,
  addSeconds,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  formatRFC3339,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfTomorrow,
  startOfYear,
  startOfYesterday
} from 'date-fns';

export enum TimeExpression {
  NOW = 'now',
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  TOMORROW = 'tomorrow'
}

export enum TimeInterval {
  SECONDS = 's',
  MINUTES = 'm',
  HOURS = 'h',
  DAYS = 'd',
  WEEKS = 'w',
  MONTHS = 'M',
  QUARTERS = 'q',
  YEARS = 'Y',
}

/**
 * A utility class for basic date/time operations
 * For reference: https://date-fns.org/v2.12.0/docs/
 */
export class TimeUtil {
  static YEAR_FORMAT = 'yyyy';
  static MONTH_FORMAT = 'yyyy-MM'; // include year for month serialization as results contain more than one year
  static DAY_FORMAT = 'MM-dd';
  static DATE_FORMAT = 'yyyy-MM-dd';
  static DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
  static SHORT_TIME_FORMAT = 'HH:mm';
  static TIME_FORMAT = 'HH:mm:ss';
  static CUSTOM_DATETIME_FORMAT = 'dd.MM.yyyy - HH:mm:ss';

  static READABLE_SHORT_DATE_FORMAT = 'MMMM yyyy';
  static READABLE_DATE_FORMAT = 'dd/MM/yyyy';
  static READABLE_DOT_SEPARATED_DATE_FORMAT = 'dd.MM.yyyy';
  static READABLE_DATETIME_FORMAT = 'dd/MM/yyyy HH:mm';

  static DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/m;
  static DATETIME_REGEX = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/m;
  static SERVER_DATETIME_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2})(|:(\d{2}))$/m;
  static ISO_DATETIME_REGEX = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/m;
  static SHORT_TIME_REGEX = /^(\d{2}):(\d{2})$/m;
  static TEXTUAL_DATE_REGEX = /^(yesterday|today|now|tomorrow)((-|\+)([1-9]\d*)(s|m|h|d|w|M|q|Y))?$/m;
  static NUMBER_REGEX = /\d+/g;
  static INTERVAL_UNIT_REGEX = /[smh]+/g;

  public static now() {
    return new Date();
  }

  public static today() {
    return startOfToday();
  }

  public static isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  public static yesterday() {
    return startOfYesterday();
  }

  public static tomorrow() {
    return startOfTomorrow();
  }

  public static lastMonth(date) {
    return startOfMonth(addMonths(date, -1));
  }

  public static endOfLastMonth(date) {
    return endOfMonth(addMonths(date, -1));
  }

  public static nextMonth(date) {
    return startOfMonth(addMonths(date, 1));
  }

  public static endOfNextMonth(date) {
    return endOfMonth(addMonths(date, 1));
  }

  public static lastYear(date) {
    return startOfYear(addYears(date, -1));
  }

  public static endOfLastYear(date) {
    return endOfYear(addYears(date, -1));
  }

  public static nextYear(date) {
    return startOfYear(addYears(date, 1));
  }

  public static endOfNextYear(date) {
    return endOfYear(addYears(date, 1));
  }

  public static addSeconds(date, seconds) {
    return addSeconds(date, seconds);
  }

  public static addMinutes(date, minutes) {
    return addMinutes(date, minutes);
  }

  public static addHours(date, hours) {
    return addHours(date, hours);
  }

  public static addDays(date, days) {
    return addDays(date, days);
  }

  public static addWeeks(date, weeks) {
    return addWeeks(date, weeks);
  }

  public static addMonths(date, months) {
    return addMonths(date, months);
  }

  public static addQuarters(date, quarters) {
    return addQuarters(date, quarters);
  }

  public static addYears(date, years) {
    return addYears(date, years);
  }

  public static startOfDay(date) {
    return startOfDay(date);
  }

  public static endOfDay(date) {
    return endOfDay(date);
  }

  public static startOfMonth(date) {
    return startOfMonth(date);
  }

  public static endOfMonth(date) {
    return endOfMonth(date);
  }

  public static startOfYear(date) {
    return startOfYear(date);
  }

  public static endOfYear(date) {
    return endOfYear(date);
  }

  public static serializeDate(date) {
    return format(date, this.DATE_FORMAT);
  }

  public static serializeYear(date) {
    return format(date, this.YEAR_FORMAT);
  }

  public static serializeMonth(date) {
    return format(date, this.MONTH_FORMAT);
  }

  public static serializeDay(date) {
    return format(date, this.READABLE_DATE_FORMAT);
  }

  public static serializeReadableShortDate(date) {
    return format(date, this.READABLE_SHORT_DATE_FORMAT);
  }

  public static serializeReadableDate(date) {
    return format(date, this.READABLE_DATE_FORMAT);
  }

  public static serializeReadableDotSeparatedDate(date) {
    return format(date, this.READABLE_DOT_SEPARATED_DATE_FORMAT);
  }

  public static serializeDatetime(date) {
    return format(date, this.DATETIME_FORMAT);
  }

  public static serializeCustomDatetime(date) {
    return format(date, this.CUSTOM_DATETIME_FORMAT);
  }

  public static serializeReadableDatetime(date) {
    return format(date, this.READABLE_DATETIME_FORMAT);
  }

  public static serializeShortTime(date) {
    return format(date, this.READABLE_DATETIME_FORMAT);
  }

  public static serializeHour(date) {
    return format(date, this.SHORT_TIME_FORMAT);
  }

  public static serializeISOHour(date) {
    // convert datetime to UTC
    const dateTime = new Date();
    dateTime.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);     
    return format(dateTime, this.TIME_FORMAT) + 'Z';
  }

  public static serializeISODatetime(date) {
    return formatRFC3339(date, {fractionDigits: 3});
  }

  public static serializeISODatetimeUTC(date) {
    return date.toISOString();
  }

  /**
   * Returns the display name for the given period string.
   * @param periodString a period string whose format is n{@link TimeInterval}
   * */
  public static getDisplayNameOfPeriod(periodString: string) {
    const period = this.parsePeriod(periodString);
    // the displayed name of period unit has "timeInterval_" prefix which is required for the proper translation.
    return 'Last ' + period[0] + ' timeInterval_' + period[1];
  }

  /**
   * Extracts the value and unit from the given period.
   * @param period a period string whose format is n{@link TimeInterval}
   * @return a list containing the period value as first element and period unit as second element
   * */
  public static parsePeriod(period: string) {
    const periodStringLength = period.length;
    return [period.substring(0, periodStringLength - 1), period.substring(periodStringLength - 1)];
  }

  public static parseDate(dateString) {
    return parse(dateString, this.DATE_FORMAT, new Date());
  }

  public static parseDatetimeISO(dateString) {
    return parseISO(dateString);
  }

  public static parseHour(dateString:string) {
    if(dateString.endsWith('Z')){
      dateString = dateString.substring(0, dateString.length-1)
    }
    const date = parse(dateString, this.TIME_FORMAT, new Date());
    //convert datetime to local timezone
    const dateTime = new Date();
    dateTime.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000); // extract the difference in timezones   
    return dateTime
  }

  public static parseReadableDate(dateString) {
    return parse(dateString, this.READABLE_DATE_FORMAT, new Date());
  }

  public static parseDatetime(dateString) {
    const dateTime = parse(dateString, this.DATETIME_FORMAT, new Date());
    //convert datetime to local timezone
    dateTime.setTime(dateTime.getTime() - dateTime.getTimezoneOffset() * 60 * 1000); // extract the difference in timezones
    return dateTime;
  }

  public static parseReadableDatetime(dateString) {
    return parse(dateString, this.READABLE_DATETIME_FORMAT, new Date());
  }

  public static parseISODatetime(dateString) {
    return parseISO(dateString);
  }

  public static parseTextualDate(string) {
    const groups = string.match(this.TEXTUAL_DATE_REGEX);
    if (groups && groups.length > 1) {

      const timeExpression = groups[1];
      if (timeExpression) {
        let date;

        switch (timeExpression) {
          case TimeExpression.NOW:
            date = this.now();
            break;
          case TimeExpression.TODAY:
            date = this.today();
            break;
          case TimeExpression.YESTERDAY:
            date = this.yesterday();
            break;
          case TimeExpression.TOMORROW:
            date = this.tomorrow();
            break;
        }

        const sign = groups[3];
        if (sign) {
          const factor = sign === '-' ? -1 : 1;
          const durationAmount = factor * parseInt(groups[4]);
          const durationType = groups[5];

          switch (durationType) {
            case TimeInterval.SECONDS:
              date = this.addSeconds(date, durationAmount);
              break;
            case TimeInterval.MINUTES:
              date = this.addMinutes(date, durationAmount);
              break;
            case TimeInterval.HOURS:
              date = this.addHours(date, durationAmount);
              break;
            case TimeInterval.DAYS:
              date = this.addDays(date, durationAmount);
              break;
            case TimeInterval.WEEKS:
              date = this.addWeeks(date, durationAmount);
              break;
            case TimeInterval.MONTHS:
              date = this.addMonths(date, durationAmount);
              break;
            case TimeInterval.QUARTERS:
              date = this.addQuarters(date, durationAmount);
              break;
            case TimeInterval.YEARS:
              date = this.addYears(date, durationAmount);
              break;
          }
        }

        return date;
      }
    }

    return undefined;
  }

  public static parseStringTime(string) {
    if (this.matchesIsoDatetimeRegex(string)) {
      return this.parseISODatetime(string);
    } else if (this.matchesDatetimeRegex(string)) {
      return this.parseDatetime(string);
    } else if (this.matchesDateRegex(string)) {
      return this.parseDate(string);
    } else if (this.matchesTextualRegex(string)) {
      return this.parseTextualDate(string);
    }
  }

  public static matchesDateRegex(string) {
    return this.DATE_REGEX.test(string);
  }

  public static matchesDatetimeRegex(string) {
    return this.DATETIME_REGEX.test(string);
  }

  public static matchesServerDatetimeRegex(string) {
    return this.SERVER_DATETIME_REGEX.test(string);
  }

  public static matchesIsoDatetimeRegex(string) {
    return this.ISO_DATETIME_REGEX.test(string);
  }

  public static matchesTextualRegex(string) {
    return this.TEXTUAL_DATE_REGEX.test(string);
  }

  public static matchesShortTimeRegex(string) {
    return this.SHORT_TIME_REGEX.test(string);
  }

  /**
   * Converts a duration such as 1s, 2m or 3h to millisecond
   */
  public static convertDurationToMilliSecond(duration: string): number {
    const amount: number = Number.parseFloat(duration.match(this.NUMBER_REGEX)[0]);
    const unit: string = duration.match(this.INTERVAL_UNIT_REGEX)[0];
    let milliseconds: number = 0;
    if (unit === TimeInterval.SECONDS) {
      milliseconds = amount * 1000;
    } else if (unit === TimeInterval.MINUTES) {
      milliseconds = amount * 1000 * 60;
    } else if (unit === TimeInterval.HOURS) {
      milliseconds = amount * 1000 * 60 * 60;
    }
    return milliseconds;
  }

  /**
   * Returns the amount and unit of duration as an array.
   * @param duration the duration such as 1s, 2m or 3h
   * @return the amount and unit of duration as an array.The first element of array is the amount and the second one is
   * the unit.
   */
  public static getDurationAmountAndUnit(duration: string): any[] {
    const amount: number = Number.parseFloat(duration.match(this.NUMBER_REGEX)[0]);
    const unit: string = duration.match(this.INTERVAL_UNIT_REGEX)[0];
    return [amount, unit];
  }

  /**
   * Validates that the unit of given period is one of the {@link TimeInterval}. If not, it converts the unit to lowercase
   * string.
   * @param period Period as string in the following format: Pn{@link TimeInterval}
   * @return the validated period
   * */
  public static validatePeriodUnit(period): string {
    const timeIntervals: string[] = Object.keys(TimeInterval).map(key => TimeInterval[key]);
    if (period) {
      const length = period.length;
      if (!timeIntervals.includes(period[length - 1])) {
        return `${period.substring(0, length - 1)}` + period[length - 1].toLowerCase();
      }
    }
    return period;
  }
}
