import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {BasePageComponent} from '../../../../shared/components/base-page.component';

@Component({
  template: '',
})
export abstract class BaseDialogTemplateComponent extends BasePageComponent implements OnInit, OnDestroy {
  asyncContext: any;

  protected constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    // if there is any asynchronous context, subscribe to it
    if (this.asyncContext) {
      this.subscribeToContextData(this.asyncContext);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * Dialog context might be asynchronous e.g. up-to-date entity might be fetched when the dialog is opened. Once the context is fetched,
   * the fetched content is mapped to relevant fields of the dialog by the dialog itself.
   * @param asyncContext
   * @private
   */
  private subscribeToContextData(asyncContext: Observable<any>): void {
    asyncContext.pipe(
      take(1)
    ).subscribe(context => {
      this.mapContextData(context);
    });
  }

  /**
   * Returns the value of a named field from an entity
   * @param entity Entity from which the value will be retrieved
   * @param field The name of the field whose value will be returned
   * @param valueField The name of the field to be used to retrieve value of specified entity field
   */
  public getValue(entity, field, valueField = 'value') {
    if (entity[field]) {
      const data = entity[field];
      if (typeof data === 'object' && data !== null && data.hasOwnProperty(valueField)) {
        return data[valueField];
      } else {
        return data;
      }
    }
  }

  /**
   * Maps the resolved context data to relevant fields in the dialog template implementation
   * @param any
   * @protected
   */
  protected abstract mapContextData(any): void;
}
