<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    <label> Realm Id: </label>
    <br>
    <input #createID placeholder="Realm Id" nbInput id="createID" class="search-input size-medium status-basic shape-rectangle nb-transition">
    <hr>
    <label> Name: </label>
    <br>
    <input #createname placeholder="Name" nbInput id="createname" class="search-input size-medium status-basic shape-rectangle nb-transition">
<!--    <hr>-->
<!--    <label> Namespace: </label>-->
<!--    <br>-->
<!--    <input #createnamespace placeholder="Namespace" nbInput id="createnamespace" class="search-input size-medium status-basic shape-rectangle nb-transition">-->
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-danger mr-1"  (click)="submit({id: createID.value, name: createname.value})">Submit</button>
    <button class="btn btn-primary" (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>
