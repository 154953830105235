const ENTITY_ID_REGEX = '^urn:ngsi-ld:([a-zA-Z_][a-zA-Z0-9_]*):([A-Za-z0-9-_]+)$';

export class FormUtil {
  /**
   * Checks whether the given array has at least one non-empty value
   * @param data
   */
  public static hasAtLeastOneValue(data: string[]): boolean {
    return data && data.length > 0 && !!data[0];
  }

  /**
   * Checks whether the given array has at least one valid id
   * @param data
   * @param entityTypes
   */
  public static hasAtLeastOneId(data: string[], entityTypes: string[]): boolean {
    if (!FormUtil.hasAtLeastOneValue(data)) {
      return false;
    }

    // check if there is a valid entity type in the id
    const id: string = data[0];
    const idParts: string[] = id.split(':');
    if (idParts.length < 2) {
      return false;
    }

    return entityTypes.some(_type => _type === idParts[2]);
  }

  /**
   * Extracts and returns the entity type from a valid entity id.
   * @param id the entity id
   * @returns the entity type
   * @throws Error when the provided id is not valid
   */
  public static extractEntityTypeFromId(id:string){
    const parts = id.match(ENTITY_ID_REGEX);
    // it should include three parts: prefix, entity type and entity id
    if(parts.length !== 3){
      throw new Error(`Invalid entity id:${id}`);
    }
    return parts[1];
  }

  /**
   * Checks whether the given input is an empty/unspecified value
   * @param value
   */
  public static isEmptyValue(value: any): boolean {
    return value === null || value === undefined || value === '' || value === [];
  }
}
