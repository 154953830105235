import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';
import { TimeUtil } from '../../../../../shared/utils/time-util';

const ASELSAN_ELECTRICBUS_SOURCE_URL = "http://aselsan.com.tr";
@Component({
  selector: 'electricBus-dialog-template',
  templateUrl: './electricBus-dialog-template.component.html',
  styleUrls: ['./electricBus-dialog-template.component.scss']
})
export class ElectricBusDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  // Context variables
  entity: any;
  positionEntity: any;
  id: any;
  plate: any;
  model: any;
  brand: any;
  akbState: any;
  acceleratorPedalPosition: any;
  hvBatteryCurrent: any;
  hvBatteryVoltage: any;
  maximumCellTemperature: any;
  minimumCellTemperature: any;
  torqueRequest: any;
  actualMotorSpeed: any;
  actualMotorTorque: string;
  distanceTraveledTotal: any;
  vehicleSpeed: any;
  location: any;
  activeGear: any;
  batteryRange: any;
  batterySOC: any;
  distanceTraveledTrip: string;
  motorActualConsumptionOrRegen: any;
  systemOperatingMode: any;
  isAselsanElectricBus = undefined;
  electricBusRef: any;
  isCollapsed: boolean[] = [true, true, true, true];
  status: string[] = ['', '', '', ''];
  // keeps the active tab
  activeTab: string;
  isBatterySOCGreen: boolean = false;
  isBatterySOCRed: boolean = false;
  isBatterySOCYellow: boolean = false;
  isAccelerationPedalPositionGreen: boolean = false;
  isAccelerationPedalPositionRed: boolean = false;
  isAccelerationPedalPositionYellow: boolean = false;
  isVehicleSpeedGreen: boolean = false;
  isVehicleSpeedRed: boolean = false;
  isVehicleSpeedYellow: boolean = false;
  batterySOH: any;
  systemOpMode: string[] = ['Sürüş Modu', 'Operasyonel Şarj Modu', 'Diagnostik Mod', 'Deşarj Modu', 'Balans Modu'];
  actGear: string[] = ['İleri', 'Geri', 'Nötr'];


  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Sets the active tab based on the tab selection event.
   * @param event the tab selection event
   * */
  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  protected mapContextData(context: any): void {

    this.positionEntity = context.positionEntity ? context.positionEntity.entity : context.result.posistionEntity.entity;
    this.entity =  context.entity ? context.entity.entity : context.result.entity;

    this.plate = super.getValue(this.entity, 'plate');
    this.model = super.getValue(this.entity, 'model');
    this.brand = super.getValue(this.entity, 'brand');
    this.akbState = super.getValue(this.positionEntity, 'akbState');
    this.acceleratorPedalPosition = super.getValue(this.positionEntity, 'acceleratorPedalPosition');
    this.hvBatteryCurrent = super.getValue(this.positionEntity, 'hvBatteryCurrent');
    this.hvBatteryVoltage = super.getValue(this.positionEntity, 'hvBatteryVoltage');
    this.maximumCellTemperature = super.getValue(this.positionEntity, 'maximumCellTemperature');
    this.minimumCellTemperature = super.getValue(this.positionEntity, 'minimumCellTemperature');
    this.torqueRequest = super.getValue(this.positionEntity, 'torqueRequest');
    this.actualMotorSpeed = super.getValue(this.positionEntity, 'actualMotorSpeed');
    this.actualMotorTorque = super.getValue(this.positionEntity, 'actualMotorTorque');
    this.distanceTraveledTotal = super.getValue(this.positionEntity, 'distanceTraveledTotal');
    this.vehicleSpeed = super.getValue(this.positionEntity, 'vehicleSpeed');
    this.location = super.getValue(this.positionEntity, 'location').coordinates.join(', ');
    this.activeGear = super.getValue(this.positionEntity, 'activeGear');
    this.batteryRange = super.getValue(this.positionEntity, 'batteryRange');
    this.batterySOC = super.getValue(this.positionEntity, 'batterySOC');
    this.batterySOH = super.getValue(this.positionEntity, 'batterySOH');
    this.distanceTraveledTrip = super.getValue(this.positionEntity, 'distanceTraveledTrip');
    this.motorActualConsumptionOrRegen = super.getValue(this.positionEntity, 'motorActualConsumptionOrRegen');
    this.systemOperatingMode = super.getValue(this.positionEntity, 'systemOperatingMode');
    this.electricBusRef = super.getValue(this.positionEntity, 'electricBusRef');
    const array = super.getValue(this.entity, 'id').split(':');
    this.id = array[3];
    this.checkStatus();
    this.checkBatterySOCImage();
    this.checkAccelerationPedalPositionImage();
    this.checkVehicleSpeedImage();
  }

  setVisibility(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }

  checkStatus() {
    // Acceleration Control
    if (this.acceleratorPedalPosition >= 75) {
      this.status[1] = '-red';
      this.status[0] = '-red';
    } else if (this.acceleratorPedalPosition > 50 && this.acceleratorPedalPosition < 75) {
      this.status[1] = '-yellow';
      if (this.status[0] !== '-red') {
        this.status[0] = '-yellow';
      }
    } else {
      this.status[1] = '';
      if (this.status[0] !== '-red' && this.status[0] !== '-yellow') {
        this.status[0] = '';
      }
    }

    // Speed Control
    if (this.vehicleSpeed >= 40) {
      this.status[2] = '-red';
      this.status[0] = '-red';
    } else if (this.vehicleSpeed > 20 && this.vehicleSpeed < 40) {
      this.status[2] = '-yellow';
      if (this.status[0] !== '-red') {
        this.status[0] = '-yellow';
      }
    } else {
      this.status[2] = '';
      if (this.status[0] !== '-red' && this.status[0] !== '-yellow') {
        this.status[0] = '';
      }
    }

    // Battery Control
    if (this.batterySOC <= 40) {
      this.status[3] = '-red';
      this.status[0] = '-red';
    } else if (this.batterySOC > 40 && this.batterySOC < 70) {
      this.status[3] = '-yellow';
      if (this.status[0] !== '-red') {
        this.status[0] = '-yellow';
      }
    } else {
      this.status[3] = '';
      if (this.status[0] !== '-red' && this.status[0] !== '-yellow') {
        this.status[0] = '';
      }
    }
  }

  private checkBatterySOCImage() {
    if (this.batterySOC <= 40) {
      this.isBatterySOCGreen = false;
      this.isBatterySOCYellow = false;
      this.isBatterySOCRed = true;
    } else if (this.batterySOC >= 70) {
      this.isBatterySOCGreen = true;
      this.isBatterySOCYellow = false;
      this.isBatterySOCRed = false;
    } else {
      this.isBatterySOCGreen = false;
      this.isBatterySOCYellow = true;
      this.isBatterySOCRed = false;
    }
  }

  private checkAccelerationPedalPositionImage() {
    if (this.acceleratorPedalPosition > 75) {
      this.isAccelerationPedalPositionGreen = false;
      this.isAccelerationPedalPositionYellow = false;
      this.isAccelerationPedalPositionRed = true;
    } else if (this.acceleratorPedalPosition <= 50) {
      this.isAccelerationPedalPositionGreen = true;
      this.isAccelerationPedalPositionYellow = false;
      this.isAccelerationPedalPositionRed = false;
    } else {
      this.isAccelerationPedalPositionGreen = false;
      this.isAccelerationPedalPositionYellow = true;
      this.isAccelerationPedalPositionRed = false;
    }
  }

  private checkVehicleSpeedImage() {
    if (this.vehicleSpeed >= 40) {
      this.isVehicleSpeedGreen = false;
      this.isVehicleSpeedYellow = false;
      this.isVehicleSpeedRed = true;
    } else if (this.vehicleSpeed <= 20) {
      this.isVehicleSpeedGreen = true;
      this.isVehicleSpeedYellow = false;
      this.isVehicleSpeedRed = false;
    } else {
      this.isVehicleSpeedGreen = false;
      this.isVehicleSpeedYellow = true;
      this.isVehicleSpeedRed = false;
    }
  }
}
