export class Coordinate {
  latitude: number;
  longitude: number;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.latitude = data.latitude;
    this.longitude = data.longitude;
  }

  /**
   * Creates a new instance with the given coordinates array.
   * @param coordinates Array with two items such that the first item contains the latitude and the second item contains the longitude
   */
  public static fromLatLongArray(coordinates: number[]): Coordinate {
    return new Coordinate({latitude: coordinates[0], longitude: coordinates[1]});
  }

   /**
   * Creates a new instance with the given coordinates array.
   * @param coordinates Array with two items such that the first item contains the longitude and the second item contains the latitude 
   */
    public static fromLongLatArray(coordinates: number[]): Coordinate {
      return new Coordinate({latitude: coordinates[1], longitude: coordinates[0]});
    }

  /**
   * Returns the bounds of region whose coordinates are given.
   * @param coordinates The coordinates of region.
   * @return bounds of region as an array of numbers in [west, south, east, north] order
   */
   public static getBoundsOfRegion(coordinates:Coordinate[]): [number, number, number, number] {
    const west = coordinates.reduce((c1, c2) => {
      return c1.longitude < c2.longitude ? c1 : c2;
    },coordinates[0]).longitude;
    const south = coordinates.reduce((c1, c2) => {
      return c1.latitude < c2.latitude ? c1 : c2;
    },coordinates[0]).latitude;
    const east = coordinates.reduce((c1, c2) => {
      return c1.longitude > c2.longitude ? c1 : c2;
    },coordinates[0]).longitude;
    const north = coordinates.reduce((c1, c2) => {
      return c1.latitude > c2.latitude ? c1 : c2;
    },coordinates[0]).latitude;

    return [west, south, east, north];
  }

  /**
   * Transforms this coordinate to a coordinate array such that the first item contains the latitude and the second item contains the longitude
   */
  public toLatLongArray(): number[] {
    return [this.latitude, this.longitude];
  }

  /**
   * Transforms this coordinate to a coordinate array such that the first item contains the longitude and the second item contains the latitude
   */
  public toLongLatArray(): number[] {
    return [this.longitude, this.latitude];
  }
}
