import {Component, Injector, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {TimeUtil} from '../../../shared/utils/time-util';
import {TemporalValue} from '../../../shared/models/schema/entity/temporal/temporal-value';
import {AlertState} from '../enums/alert-state.enum';
import {BaseAlertDialogComponent} from './base-alert-dialog.component';
import {Coordinate} from '../../../shared/models/generic/coordinate.model';
import {AuthorizationScope} from '../../../shared/enums/authorization-scope.enum';

/**
 * Dialog to display the details of an alert.
 */
@Component({
  selector: 'uruk-alert-detail-dialog',
  styleUrls: ['alert-detail-dialog.component.scss'],
  templateUrl: './alert-detail-dialog.component.html',
})
export class AlertDetailDialogComponent extends BaseAlertDialogComponent implements OnInit {

  _TimeUtil = TimeUtil;

  // alert values as TemporalValue array
  alertValues: TemporalValue[];

  // whether the user is allowed to update Alert state i.e., confirm/suppress the alert
  canUpdateAlertState: boolean = false;

  constructor(public dialogRef: NbDialogRef<AlertDetailDialogComponent>, private injector: Injector) {
    super(dialogRef, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.canUpdateAlertState = this.userService.hasPermissionForResource('Alert', AuthorizationScope.ENTITY_TU);
    this.setAlertValues();
  }

  showAlertLocation() {
    const coordinates: number[] = this.alerts[0].getSingleElementValue('location').coordinates;

    this.dialogRef.close(Coordinate.fromLatLongArray(coordinates));
  }

  suppress() {
    this.updateAlerts(AlertState.SUPPRESSED);
  }

  confirm() {
    this.updateAlerts(AlertState.CONFIRMED);
  }

  protected mapContextData(context: any): void {
    this.alerts = [context.entity];
    this.setAlertValues();
  }

  private setAlertValues(): void {
    // retrieve the alert values if exists
    if (this.alerts && this.alerts[0] && this.alerts[0].entity.data) {
      this.alertValues = this.alerts[0].getAttributeAsTemporalValueArray('data').reverse();
    }
  }
}
