/**
 * Factory method to create a LayerController based on the given layers
 * @param layers
 */
import {Layer} from '../../shared/models/layer.model';
import {LayerType} from '../../shared/enums/layer-type';
import {LayerController} from '../controllers/layer.controller';
import {Injectable, Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {WeatherPollutionLayerController} from '../controllers/weather-pollution-layer.controller';
import {JunctionLayerController} from '../controllers/junction-layer.controller';
import {AlertLayerController} from '../controllers/alert-layer.controller';
import {GenericLayerController} from '../controllers/generic-layer.controller';
import {ParkingLayerController} from '../controllers/parking-layer.controller';
import {TrainLayerController} from '../controllers/train-layer.controller';
import {VehicleLayerController} from '../controllers/vehicle-layer.controller';
import {ElectricBusLayerController} from '../controllers/electricBus-layer.controller';
import {MgubLayerController} from '../controllers/mgub-layer.controller';
import {SolarPoleLayerController} from '../controllers/solar-pole-layer.controller';
import {AnnounceSensorLayerController} from '../controllers/announceSensor-layer.controller';
import {DefibrillatorLayerController} from '../controllers/defibrillator-layer.controller';
import {JkgysLayerController} from '../controllers/jkgys-layer.controller';
import {JkgysTakLayerController} from '../controllers/jkgysTak-layer.controller';
import {UrukRouteLayerController} from '../controllers/uruk-route-layer.controller';
import {TcddTrainsLayerController} from '../controllers/tcddTrains-layer.controller';
import {DroneLayerController} from '../controllers/drone-layer.controller';

@Injectable({
  providedIn: 'root'
})
export class LayerFactoryService {
  constructor(private injector: Injector) {
  }

  public createControllers(layers: Layer[], page: Page): LayerController[] {
    return layers.map(layer => {
      let controller: LayerController;
      switch (layer.name) {
        case LayerType.Alert:
          controller = new AlertLayerController(layer, page, this.injector);
          break;
        case LayerType.Junctions:
          controller = new JunctionLayerController(layer, page, this.injector);
          break;
        case LayerType.OffStreetParking:
          controller = new ParkingLayerController(layer, page, this.injector);
          break;
        case LayerType.OnStreetParking:
          controller = new ParkingLayerController(layer, page, this.injector);
          (controller as ParkingLayerController).zoomSensitive = true;
          break;
        case LayerType.Vehicles:
        case LayerType.FireEngines:
        case LayerType.AsisVehicles:
          controller = new VehicleLayerController(layer, page, this.injector);
          break;
        case LayerType.WeatherPollutionCSB:
        case LayerType.WeatherPollutionCopercinus:
          controller = new WeatherPollutionLayerController(layer, page, this.injector);
          break;
        case LayerType.Trains:
          controller = new TrainLayerController(layer, page, this.injector);
          break;
        case LayerType.ElectricBuses:
          controller = new ElectricBusLayerController(layer, page, this.injector);
          break;
        case LayerType.MGUBCastle:
        case LayerType.Tak:
          controller = new MgubLayerController(layer, page, this.injector);
          break;
        case LayerType.SolarPole:
          controller = new SolarPoleLayerController(layer, page, this.injector);
          break;
        case LayerType.AnnounceSensors:
          controller = new AnnounceSensorLayerController(layer, page, this.injector);
          break;
        case LayerType.DEFIBRILLATOR:
          controller = new DefibrillatorLayerController(layer, page, this.injector);
          break;
        case LayerType.JKGYS:
          controller = new JkgysLayerController(layer, page, this.injector);
          break;
        case LayerType.JKGYSTak:
          controller = new JkgysTakLayerController(layer, page, this.injector);
          break;
        case LayerType.UrukRoute:
          controller = new UrukRouteLayerController(layer, page, this.injector);
          break;
        case LayerType.TcddTrains:
          controller = new TcddTrainsLayerController(layer, page, this.injector);
          break;
        case LayerType.Drone:
          controller = new DroneLayerController(layer, page, this.injector);
          break;
        default:
          controller = new GenericLayerController(layer, page, this.injector);
      }
      return controller;
    });
  }
}
