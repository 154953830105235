<div class="menu-container">
  <ng-container *ngFor="let menuItem of menuItems; let i=index">
    <!-- Menu items-->
    <div>
      <!-- Left-hand side of menu keeps the icons of first level menu items -->
      <div class="menu-icons">
        <nb-icon *ngIf="menuItem.icon" (click)="onIconClicked(i)" [icon]="menuItem.icon.icon"
                 [pack]="menuItem.icon.pack">
        </nb-icon>
      </div>
      <!-- Right-hand side of menu keeps the menu items -->
      <div class="menu-items">
        <!-- For the first level of menu items, do not display the icons since they are displayed in the div above -->
        <uruk-menu-item [displayMenuItemIcon]="false" [menuItem]="menuItem">
        </uruk-menu-item>
      </div>
    </div>
  </ng-container>
</div>
