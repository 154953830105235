import {Type} from '@angular/core';
import {TemplateType} from '../../shared/enums/page-type.enum';
import {SixPanelMapPageComponent} from './templates/six-panel-map-page.component';
import {EntityDialogTemplateComponent} from './templates/dialogs/entity-dialog/entity-dialog-template.component';
import {
  JunctionArmDialogTemplateComponent
} from './templates/dialogs/junction-arm-dialog/junction-arm-dialog-template.component';
import {VideowallComponent} from './templates/videowall.component';
import {AlertDetailDialogComponent} from '../alert/dialog/alert-detail-dialog.component';
import {
  WeatherPollutionDialogTemplateComponent
} from './templates/dialogs/weather-pollution-observation-dialog/weather-pollution-dialog-template.component';
import {NoPageComponent} from './templates/no-page.component';
import {ParkingDialogTemplateComponent} from './templates/dialogs/parking-dialog/parking-dialog-template.component';
import {GenericTemplatePageComponent} from './templates/generic-template-page.component';
import {VehicleDialogTemplateComponent} from './templates/dialogs/vehicle-dialog/vehicle-dialog-template.component';
import {TrainDialogTemplateComponent} from './templates/dialogs/train-dialog/train-dialog-template.component';
import {TcddTrainDialogTemplateComponent} from './templates/dialogs/tcddTrain-dialog/tcddTrain-dialog-template.component';
import {ElectricBusDialogTemplateComponent} from './templates/dialogs/electricBus-dialog/electricBus-dialog-template.component';
import {MgubCastleTemplateComponent} from './templates/dialogs/mgubCastle-dialog/mgub-castle-template.component';
import {SolarPoleDialogTemplateComponent} from './templates/dialogs/solarPole-dialog/solarPole-dialog-template.component';
import {DefibrillatorTemplateComponent} from './templates/dialogs/defibrillator-dialog/defibrillator-template.component';
import {AnnounceSensorDialogTemplateComponent} from './templates/dialogs/announceSensor-dialog/announceSensor-dialog-template.component';
import {JkgysTemplateComponent} from './templates/dialogs/jkgys-dialog/jkgys-template.component';
import {JkgysTakTemplateComponent} from './templates/dialogs/jkgysTak-dialog/jkgysTak-template.component';
import {DroneTemplateComponent} from './templates/dialogs/drone-dialog/drone-template.component';

/**
 * Keeps a mapping from templates (i.e. page types) to Component type. Such a mapping utility is required in order not to refer to component classes
 * from model classes (leading to circular dependencies)
 */
export class TemplateNameMap {
  private static classNameMappings: Map<string, Type<any>> = new Map<string, Type<any>>([
    [TemplateType.SIX_PANEL_MAP, SixPanelMapPageComponent],
    [TemplateType.ENTITY_DIALOG, EntityDialogTemplateComponent],
    [TemplateType.JUNCTION_ARM_DIALOG, JunctionArmDialogTemplateComponent],
    [TemplateType.PARKING_DIALOG, ParkingDialogTemplateComponent],
    [TemplateType.WEATHER_POLLUTION_DIALOG, WeatherPollutionDialogTemplateComponent],
    [TemplateType.ALERT_DIALOG, AlertDetailDialogComponent],
    [TemplateType.VEHICLE_DIALOG, VehicleDialogTemplateComponent],
    [TemplateType.TRAIN_DIALOG, TrainDialogTemplateComponent],
    [TemplateType.ELECTRIC_BUS_DIALOG, ElectricBusDialogTemplateComponent],
    [TemplateType.MGUBCASTLE_DIALOG, MgubCastleTemplateComponent],
    [TemplateType.SOLARPOLE_DIALOG, SolarPoleDialogTemplateComponent],
    [TemplateType.DEFIBRILLATOR_DIALOG, DefibrillatorTemplateComponent],
    [TemplateType.ELECTRIC_BUS_DIALOG, ElectricBusDialogTemplateComponent],
    [TemplateType.TCDDTRAIN_DILAOG, TcddTrainDialogTemplateComponent],
    [TemplateType.ANNOUNCESENSOR_DIALOG, AnnounceSensorDialogTemplateComponent],
    [TemplateType.JKGYS_DIALOG, JkgysTemplateComponent],
    [TemplateType.JKGYSTAK_DIALOG, JkgysTakTemplateComponent],
    [TemplateType.DRONE_DIALOG, DroneTemplateComponent],
    [TemplateType.VIDEOWALL, VideowallComponent],
    [TemplateType.NO_PAGE, NoPageComponent]
  ]);

  static getComponentClass(name: string): Type<any> {
    if (this.classNameMappings.has(name)) {
      return this.classNameMappings.get(name);
    }
    return GenericTemplatePageComponent;
  }
}
