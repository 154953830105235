import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {MenuItem} from '../../../shared/models/menu/menu-item';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {NbSidebarService} from '@nebular/theme';
import {Location} from '@angular/common';

/**
 * Handles the menu related operations such as menu item selection.
 * */
@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnDestroy {

  // Subscription object for unsubscribing when destroying the service to avoid leaks
  destroy$ = new Subject<void>();

  // Subscription object for menu items
  private _menuItems: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  public menuItems: Observable<MenuItem[]> = this._menuItems.asObservable();

  private _pathToSelectedMenuItem: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public pathToSelectedMenuItem: Observable<string[]> = this._pathToSelectedMenuItem.asObservable();

  private _menuVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public menuVisibility: Observable<boolean> = this._menuVisibility.asObservable().pipe(distinctUntilChanged());

  private _isSidebarExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isSidebarExpanded: Observable<boolean> = this._isSidebarExpanded.asObservable();

  constructor(private sidebarService: NbSidebarService,
              private location: Location) {
    this.subscribeToSidebarEvents();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Populates the path to selected menu item i.e. {@link _pathToSelectedMenuItem}. When no id is provided,
   * path is cleared.
   * @param id the identifier of selected menu item
   * @param collapse whether the menu item is collapsed or expanded
   * */
  public onMenuItemSelected(id: string, collapse: boolean = false) {
    // clear the path
    if (null === id) {
      this._pathToSelectedMenuItem.next([]);
    }
    // collapse the menu item
    else if (collapse) {
      // get the path to selected menu item
      let parentMenuItems = this._pathToSelectedMenuItem.value;
      // find its index
      const index = parentMenuItems.indexOf(id);
      // remove the selected menu item and its children from the path
      parentMenuItems = parentMenuItems.slice(0, index);
      // set the new value of _pathToSelectedMenuItem
      this._pathToSelectedMenuItem.next(parentMenuItems);
    } else {
      // get parent menu items for the selected one
      const parentMenuItems = this.getParentMenuItems(this._menuItems.value, id);
      // set the new value of _pathToSelectedMenuItem
      this._pathToSelectedMenuItem.next(parentMenuItems);
    }
  }

  /**
   * Sets the menu items
   * @param menuItems
   * */
  public setMenuItems(menuItems: MenuItem[]) {
    this._menuItems.next(menuItems);
  }

  /**
   * Expands the selected menu item according to the current url.
   * */
  public expandSelectedMenuItem() {
    if (this._menuVisibility.value) {
      this.onMenuItemSelected(this.getSelectedMenuItemId(this._menuItems.value));
    }
  }

  /**
   * Returns the root menu item for the given id.
   * @param id the identifier of root menu item
   * @return the root menu item
   * */
  public getRootMenuItem(id: string) {
    const menuItems: MenuItem[] = this._menuItems.value;
    if (menuItems) {
      return menuItems.find(menuItem => menuItem.id === id);
    }
    return null;
  }

  /**
   * Updates the visibility of the menu and notifies the subscribers about this change
   * @param visible
   */
  public setMenuVisibility(visible: boolean): void {
    this._menuVisibility.next(visible);
  }

  /**
   * Returns the identifiers of parent menu items for the given one.
   * @param menuItems the list of menu items
   * @param id the identifier of menu items whose parents are searched for
   * @return the identifiers of parent menu items
   * */
  private getParentMenuItems(menuItems: MenuItem[], id: string): string[] {
    for (const menuItem of menuItems) {
      // menu item is found, return it in the parent list
      if (menuItem.id === id) {
        return [menuItem.id];
      }
      // search for the children
      else if (menuItem.children?.length) {
        const parentMenuItems = this.getParentMenuItems(menuItem.children, id);
        if (parentMenuItems.length) {
          return [menuItem.id].concat(parentMenuItems);
        }
      }
    }
    return [];
  }

  /**
   * Returns the selected menu item id according to current url i.e. the selected menu item link
   * @param the list of menu items
   * @return the identifier of selected menu item
   * */
  private getSelectedMenuItemId(menuItems: MenuItem[]): string {
    for (const menuItem of menuItems) {
      // menu item link matches the current url
      if (menuItem.link && this.location.path().endsWith(menuItem.link)) {
        return menuItem.id;
      }
      // consider children menu item if any
      else {
        if (menuItem.children?.length) {
          const id = this.getSelectedMenuItemId(menuItem.children);
          if (id) {
            return id;
          }
        }
      }
    }
    return null;
  }

  /**
   * Subscribes to NbSidebarService events to set the sidebar state {@link _isSidebarExpanded}.
   * */
  private subscribeToSidebarEvents() {
    this.sidebarService.onToggle()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this._isSidebarExpanded.next(!this._isSidebarExpanded.value);
        if(this._isSidebarExpanded.value){
          document.body.classList.remove("compacted")
        } else {
          document.body.classList.add("compacted")
        }
      });
    this.sidebarService.onCompact()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this._isSidebarExpanded.next(false);
        document.body.classList.add("compacted")
      });
    this.sidebarService.onExpand()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this._isSidebarExpanded.next(true);
        document.body.classList.remove("compacted")
      });
  }
}
