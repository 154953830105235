export class Duration {
  startDate: Date;
  endDate: Date;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.startDate = new Date(data.startDate);
    this.endDate = new Date(data.endDate);
  }
}
