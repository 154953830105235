import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {DateSelectionDialogComponent} from '../../../modules/dialog/date-selection-dialog/date-selection-dialog.component';
import {BaseComponent} from '../base.component';
import {UrukTemporalQuery} from '../../models/query/uruk-temporal-query.model';
import {EventService} from '../../../core/services/event.service';
import {takeUntil} from 'rxjs/operators';

/**
 * Date selection component that allows selection of a specific date or a date range.
 */
@Component({
  selector: 'uruk-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss']
})
export class DateSelectionComponent extends BaseComponent implements OnInit, OnDestroy {
  // temporal query being updated
  @Input() temporalQuery: UrukTemporalQuery;
  // whether to show a reset icon to reset component status
  @Input() showReset: boolean = false;
  // an identifier specifying the parent component id including this date selection component. This parameter is used to decide which instance of date selection
  // component will handle the date selection event
  @Input() parentComponentId: string;
  // whether only the day will be shown or not. It overrides the "showPeriodTab" input
  @Input() onlyDayTab = false;
  // whether period tab is visible or not
  @Input() showPeriodTab: boolean = false;
  // whether the date selection is disabled
  @Input() disabled = false;
  // TODO check that we might need an output to inform the parent component. We can check whether the value has been edited in the dialog close callback
  // output to notify the parent component about the date change
  @Output() onTemporalQueryChanged: EventEmitter<UrukTemporalQuery> = new EventEmitter<UrukTemporalQuery>();

  @ViewChild('dateSelectionInput') dateSelectionInput: ElementRef;

  // service definitions
  // public contextService: ContextService;
  protected eventService: EventService;

  constructor(protected injector: Injector) {
    super(injector);

    // this.contextService = injector.get(ContextService);
    this.eventService = injector.get(EventService);

    this.subscribeToEvents();
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    // the string representation of UrukTemporalQuery can be passed to the component as an input
    // if it is the case,  parse the given string
    if(typeof this.temporalQuery === 'string'){
      this.temporalQuery = UrukTemporalQuery.parseTemporalQuery(this.temporalQuery)
    }
  }

  private subscribeToEvents(): void {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.TIME_CHANGED:
            this.handleTimeChangedEvent(event.data);
            break;
        }
      });
  }

  onResetClicked(): void {
    this.temporalQuery = null;
    this.onTemporalQueryChanged.emit(this.temporalQuery);
  }


  onTimeSelectionClicked(): void {
    // Remove focus from the date selection input to prevent ExpressionChangedAfterItHasBeenCheckedError on corresponding icon element
    this.dateSelectionInput.nativeElement.blur();
    this.dialogService.open(DateSelectionDialogComponent, {
      context: {
        temporalQuery: this.temporalQuery,
        dialogSource: this.parentComponentId,
        onlyDayTab: this.onlyDayTab,
        showPeriodTab: this.showPeriodTab
      }
    });
  }

  /**
   * TODO check that we might not need an event for informing the dialog parent about the dialog output.
   * Copies the content of the updated temporal query to the temporal query managed by this component
   * @private
   */
  private handleTimeChangedEvent(eventData: any): void {
    const updatedTemporalQuery: UrukTemporalQuery = eventData.timeQuery;
    const dialogSource: string = eventData.dialogSource;
    // only the component that initiated the date selection dialog will consider the changed date
    if (dialogSource === this.parentComponentId) {
      this.onTemporalQueryChanged.emit(updatedTemporalQuery);
    }
  }
}
