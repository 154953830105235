<closeable-dialog class="jkgys-dialog-container" [displayFooter]="showFooter">
  <div class="dialog-header title" dialog-card-header><label>{{ 'gendarmerieInformation' | translate }} </label> </div>

  <div dialog-card-body class="dialog-body full-height">
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab tabTitle="{{'gendarmerie' | translate}}" [active]="activeTab === 'gendarmerie'" [tabId]="'gendarmerie'">

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'description' | translate}} : </label>
            <label class="column-info content">{{description}}</label>
          </div>
          <div class="flex-50">
            <label class="column-info title"> {{'gendarmerieId' | translate}} :</label>
            <label class="column-info content">{{id}}</label>
          </div>
        </div>

        <div class="d-flex ">
          <div class="flex-50">
            <label class="column-info title">{{'city-district' | translate}} : </label>
            <label class="column-info content">{{city}}</label>
          </div>
        </div>

      </nb-tab>
      <nb-tab tabTitle="{{'jemusTaks' | translate}}" [active]="activeTab === 'jemusTaks'" [tabId]="'jemusTaks'">
        <table id="jemusTaks-table">
          <thead>
          <tr [ngClass]="'header'">
            <th>{{'takId' | translate}}</th>
            <th>{{'description' | translate}}</th>
            <th>{{'direction' | translate}}</th>
            <th>{{'city-district' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let jemusTak of paginatedJemusTaks">
            <td>{{ jemusTak.id }}</td>
            <td>{{ jemusTak.description }}</td>
            <td>{{ jemusTak.direction }}</td>
            <td>{{ jemusTak.city }}</td>
          </tr>
          </tbody>
        </table>
      </nb-tab>
    </nb-tabset>
  </div>
  <div dialog-card-footer class="footer">
    <div dialog-card-footer class="footer-left">
      <label class="column-info title">{{'coordinate' | translate}}: </label>
      <label class="column-info content">{{gendarmerieStationLocation}} </label>
    </div>
  </div>
</closeable-dialog>


