import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../../shared/models/page.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TemplateType, TemplateTypeUtil} from '../../../shared/enums/page-type.enum';
import {takeUntil} from 'rxjs/operators';
import {UserPreference} from '../../../shared/models/user-preference.model';
import {PageGroup} from '../../../shared/models/page-group.model';
import {UserPreferenceService} from '../../../core/services/meta/user-preference.service';
import {NbDialogRef} from '@nebular/theme';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {BaseFormComponent} from '../../../shared/components/form/base-form.component';
import {Namespace} from '../../../shared/enums/namespace.enum';
import {Tag} from '../../../shared/models/tag.model';
import {SelectWidthFormatterUtil} from '../../../shared/utils/select-width-formatter-util';
import { PageTemplate } from 'app/shared/models/page-template.model';
import { ObjectUtil } from 'app/shared/utils/object-util';

/**
 * Component displaying a dialog to allow users to create new pages or select some pages from the existing ones.
 * */
@Component({
  templateUrl: './create-page-dialog.component.html',
  styleUrls: ['./create-page-dialog.component.scss']
})
export class CreatePageDialogComponent extends BaseFormComponent implements OnInit {

  // keeps a reference to page groups div
  @ViewChild('pageGroups') pageGroupsDiv: ElementRef;

  // indicates the step in dialog
  // step 0 : create page or select page
  // step 1 : create page
  // step 2 : select page
  step = 0;

  // available tags in the system
  domainTags: Tag[] = [];

  // keeps the form fields for the page
  pageForm: FormGroup = null;

  // user preferences
  userPreferences: UserPreference;

  // available page templates in the system
  templates: PageTemplate[] = [];

  /* Fields for Step 2: Select Page */

  // pages for selection
  pages: Page[] = null;
  // selected pages
  selectedPages: string[] = [];

  NAMESPACE_DOMAIN = Namespace.DOMAIN;
  tagFormatter = SelectWidthFormatterUtil.tagFormatter;

  // whether the action buttons are disabled
  public disableActionButtons: boolean = false;

  public fb: FormBuilder;
  public userPreferenceService: UserPreferenceService;
  public router: Router;

  constructor(protected injector: Injector,
              private dialogRef: NbDialogRef<CreatePageDialogComponent>) {
    super(injector);
    this.fb = injector.get(FormBuilder);
    this.userPreferenceService = injector.get(UserPreferenceService);
    this.router = injector.get(Router);
  }

  ngOnInit() {
    // subscribe to data
    this.subscribeToData();
    // retrieve pages
    this.retrievePages();
  }

  /**
   * Retrieves the required data to move next step.
   * */
  public onNextStep(step: number) {
    this.step = step;
    switch (step) {
      case 1:
        // retrieve domain tags
        this.retrieveDomainTags();
        // retrieve page templates
        this.retrievePageTemplates();
        // create the form group for page
        this.createForm();
        break;
      case 2:
        // create the form group
        this.createFormForPageSelection();
    }
  }

  /**
   * Updates {@link selectedPages} field.
   * */
  public onPageSelected(id) {
    const index = this.selectedPages.indexOf(id);
    if (index === -1) {
      this.selectedPages.push(id);
    } else {
      this.selectedPages.splice(index, 1);
    }
  }

  /** Methods to handle icon selection */
  /**
   * Updates the 'icon' value of page form for the selected icon
   * @param icon the selected icon
   * */
  onIconClicked(icon) {
    this.pageForm.patchValue({'icon': icon});
  }

  /** Methods to handle layer selection */
  /**
   * Checks whether there are some selected layers.
   * */
  isAnyLayerSelected() {
    const selectedLayerIds: string[] = this.pageForm.get('layerIds').value;
    return selectedLayerIds && selectedLayerIds.length > 0;
  }

  /**
   * Sets the selected layers
   * @param layerIds the list of selected layer ids
   * */
  setSelectedLayers(layerIds: string[]) {
    this.pageForm.patchValue({'layerIds': layerIds});
  }

  /** Methods to handle template type selection */

  /**
   * Selects or deselects the given template type.
   * */
  onTemplateTypeClicked(templateType: string) {
    // ignore the event if the active template type is reselected
    if (this.pageForm.get('templateType').value !== templateType) {
      this.pageForm.patchValue({'templateType': templateType});
      // for the modal pages, clear the selected page group and page group input because they can not be added to a page group
      if (this.isModalPage()) {
        this.pageForm.patchValue({'selectedPageGroup': null});
        this.pageForm.patchValue({'pageGroup': null});
        // disable page group inputs
        this.pageForm.get('selectedPageGroup').disable();
        this.pageForm.get('pageGroup').disable();
      } else {
        // enable page group inputs
        this.pageForm.get('selectedPageGroup').enable();
        this.pageForm.get('pageGroup').enable();
      }
    }
  }
  /**
   * Adds a page group with the specified name. The page group name is kept in the page form.
   * */
  addPageGroup() {
    const pageGroupName = this.pageForm.get('pageGroup').value;
    if (pageGroupName) {
      // disable action buttons
      this.disableActionButtons = true;

      // create page group with the specified name
      const pageGroup = new PageGroup({
        name: pageGroupName
      });
      // add it to the user's page groups
      let pageGroups = [pageGroup];
      if (this.userPreferences.pageGroups) {
        pageGroups = this.userPreferences.pageGroups.concat(pageGroup);
      }
      // create user preference object with the new page groups
      const userPreferences = ObjectUtil.deepCopy(UserPreference, this.userPreferences);
      userPreferences.pageGroups = pageGroups;
      // update user preference
      this.userPreferenceService.updateUserPreference(this.userService.userId.getValue(), userPreferences).subscribe(() => {
        // reset the page group input
        this.pageForm.get('pageGroup').reset();

        // enable action buttons
        this.disableActionButtons = false;

        // scroll to the bottom of page groups div
        setTimeout(() => {
          this.scrollToBottomOfPageGroupsDiv();
        });
      }, error => this.handleError(error));
    }
  }

  deletePageGroup(groupName: String) {
      if (this.userPreferences.pageGroups) {
        for ( let i = 0; i < this.userPreferences.pageGroups.length; i = i + 1) {
          if ( this.userPreferences.pageGroups[i].name === groupName) {
            this.userPreferences.pageGroups.splice(i, 1);
          }
        }
      }
    const userPreferences = ObjectUtil.deepCopy(UserPreference, this.userPreferences);
    userPreferences.pageGroups =  this.userPreferences.pageGroups;
    this.userPreferenceService.updateUserPreference(this.userService.userId.getValue(), userPreferences).subscribe(() => {
      this.dialogRef.close();
      // scroll to the bottom of page groups div
      setTimeout(() => {
        this.scrollToBottomOfPageGroupsDiv();
      });
    }, error => this.handleError(error));

    this.userPreferenceService.getPageSummaries(this.userService.userId.getValue());
  }

  /**
   * Handles the page selection.
   * */
  public selectPages() {
    // disable action buttons
    this.disableActionButtons = true;
    if (this.validateForm()) {
      this.copyPages(this.selectedPages);
    }
  }

  /**
   * Creates a page using the page form
   * */
  createPage() {
    // disable action buttons
    this.disableActionButtons = true;

    if (this.validateForm()) {
      // create the page from page form
      const page: Page = this.createPageFromForm();

      // if the active user is a regular user, make it a personal page
      if (this.userService.isRegularUser()) {
        page.owner = this.userService.userId.value;
      }

      this.pageService.createPage(page)
        .pipe(takeUntil(this.destroy$))
        .subscribe(createdPage => {
          // broadcast the page created event
          this.eventService.broadcastPageCreatedEvent(createdPage);
          // for the pages which are not modals, update user preference with the new page
          if (!this.isModalPage()) {
            this.updateUserPreferenceWithPages([createdPage.id]);
          } else {
            this.dialogRef.close();
          }
        }, error => this.handleError(error));
    }
  }

  /**
   * Returns whether the selected template type is a modal
   * */
  public isModalPage() {
    const templateType = this.pageForm.get('templateType').value;
    return TemplateTypeUtil.isDialogTemplate(templateType);
  }

  /** Private methods */

  validateForm(): boolean {
    if (this.pageForm.invalid || (this.step === 2 && !this.selectedPages.length)) {
      // display errors
      this.displayErrors = true;
      // enable action buttons
      this.disableActionButtons = false;
      return false;
    }
    return true;
  }

  /**
   * Copies the given pages for the authenticated user.
   * @param pageIds the list of page ids to be copied
   * */
  private copyPages(pageIds: string[]) {
    const promises = pageIds.map(pageId => this.pageService.copyPage(pageId).toPromise());
    Promise.all(promises).then(pages => {
      this.updateUserPreferenceWithPages(pages.map(page => page.id));
    });
  }

  /**
   * Updates the user preference with the page ids.
   * */
  private updateUserPreferenceWithPages(pageIds: string[]) {
    // copy user preference
    const userPreference = ObjectUtil.deepCopy(UserPreference, this.userPreferences);
    // retrieve selected page group
    const selectedPageGroup = this.pageForm.get('selectedPageGroup').value;

    // add page to selected page group
    if (selectedPageGroup) {
      const pageGroup = userPreference.pageGroups.find(group => group.name === selectedPageGroup);
      pageGroup.pageIds.push(...pageIds);
    }
    // add page to user's page ids
    else {
      userPreference.pageIds = userPreference.pageIds && userPreference.pageIds.length ? userPreference.pageIds.concat(pageIds) : pageIds;
    }
    // set the new one as the default page if it does not have one
    if (!userPreference.defaultPageId) {
      userPreference.defaultPageId = pageIds[0];
    }

    // update user preference
    this.userPreferenceService.updateUserPreference(this.userService.userId.getValue(), userPreference).subscribe(() => {
      this.dialogRef.close();
      // navigate user to the new page
      this.router.navigate([`/${environment.routes.pages}/${pageIds[0]}`]);
    }, error => this.handleError(error));
  }

  /**
   * Subscribes to the data used in this component
   * */
  private subscribeToData(): void {
    this.userService.onUserPreferencesChanged().subscribe(userPreferences => {
      this.userPreferences = userPreferences;
    });
  }

  /**
   * Retrieves the domain tags.
   * */
  private retrieveDomainTags() {
    this.tagService.getDomainTags().subscribe(tags => {
      this.domainTags = tags;
    });
  }

  /**
   * Retrieves the pages.
   * */
  private retrievePages() {
    this.pageService.getAllPages().subscribe(pages => {
      // get the pages of active user
      const userPages: string[] = this.userPreferences.getPageIds();
      // filter out dialog pages and the ones which active user already have
      const availablePages = pages.filter(page => !TemplateTypeUtil.isDialogTemplate(page.templateType) && !userPages.includes(page.id));
      // if there is no page to select, move to "Create Page" step
      if (!availablePages.length) {
        this.onNextStep(1);
      }
      this.pages = availablePages;
    });
  }

  /**
   * Retrieves the page templates.
   * */
  private retrievePageTemplates() {
    this.pageTemplateService.getAllPageTemplates().subscribe(templates => {
      // add the predefined template types
      this.templates = [
        new PageTemplate({
          title: 'Harita',
          id: TemplateType.SIX_PANEL_MAP
        }),
        new PageTemplate({
          title: 'Videowall',
          id: TemplateType.VIDEOWALL
        })
      ];
      this.templates = this.templates.concat(templates);
    });
  }

  /**
   * Scrolls to the bottom of page groups div.
   * */
  private scrollToBottomOfPageGroupsDiv() {
    this.pageGroupsDiv.nativeElement.scrollTop = this.pageGroupsDiv.nativeElement.scrollHeight;
  }

  /**
   * Handles Http call errors.
   * */
  private handleError(error: any) {
    console.error(error);
    // enable action buttons
    this.disableActionButtons = false;
  }

  /** Methods to handle page form*/

  /**
   * Creates the form group for the page.
   * */
  private createForm() {
    this.pageForm = this.fb.group({
      title: [null, [Validators.required]],
      icon: [null, [Validators.required]],
      layerIds: [[], [Validators.required]],
      templateType: [null, [Validators.required]],
      tags: [[], [Validators.required]], // domain tags
      pageGroup: [null], // page group to be created
      selectedPageGroup: [null] // selected page group to which the page is added
    });
  }

  /**
   * Creates the form group for page selection.
   * */
  private createFormForPageSelection() {
    this.pageForm = this.fb.group({
      pageGroup: [null], // page group to be created
      selectedPageGroup: [null] // selected page group to which the page is added
    });
  }

  /**
   * Returns the page object created from the page form
   * */
  private createPageFromForm(): Page {
    return new Page({
      ...new Page(),
      title: this.pageForm.get('title').value,
      icon: this.pageForm.get('icon').value,
      layerIds: this.pageForm.get('layerIds').value,
      templateType: this.pageForm.get('templateType').value,
      meta: {
        tags: [
          ...this.pageForm.get('tags').value, // domain tags assigned to the page
          Namespace.REALM + this.authService.getRealmId() // bind the new page to active realm
        ]
      }
    });
  }
}
