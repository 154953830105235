
/**
 * Any exception thrown in URUK backend services
 */
export class UrukError {
  /**
   * Unique encoding of the error
   */
  errorCode: string;

  /**
   * Parameters providing additional contextual information about the error
   */
  parameters?: any[];

  constructor(data?) {
    if (!data) {
      return;
    }

    this.errorCode = data.errorCode;
    this.parameters = data.parameters ? data.parameters : [];
  }

  /**
   * Creates an object keeping the parameters of error. The return value has the following format:
   * {
   *  "0" : <Parameter 0 Value>
   *  "1" : <Parameter 1 Value>
   *  ...
   * }
   * @returns the parameters object
   */
  public createParametersObject() {
    const parameters = {};
    this.parameters.forEach((parameter, i) => {
      // regex to extract list elements
      const regex = /List\((.+[^})])\)/g;
      const groups = regex.exec(parameter);
      // handle the case where parameter is a list of string
      if (groups && groups.length > 1) {
        const matched = groups[1];
        parameters[`${i}`] = matched.split(', ').join('\n');
      } else {
        parameters[`${i}`] = parameter;
      }

    })
    return parameters;
  }
}