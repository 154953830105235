import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {User} from '../../../shared/models/user.model';
import {HttpParams} from '@angular/common/http';
import { SearchQuery } from 'app/shared/models/query/search-query.model';
import { RestUtil } from 'app/shared/utils/rest-util';

/**
 * A service to manage users on the platform
 */
@Injectable()
export class UserManagementService extends BaseHttpClientService {

  // user endpoint for the authenticated realm
  private readonly userEndpoint = null;
  // admin endpoint for the authenticated realm
  private readonly adminEndpoint = null;
  // endpoint to create an administrator user for other realms
  private readonly createAdminForOtherRealmEndpoint = `${environment.server.adminApi}/${environment.auth.master_realm_id}/realms`;

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, '');
    // set endpoints
    this.userEndpoint = `${this.endpoint}/users`;
    this.adminEndpoint = `${this.endpoint}/admins`;
  }

  /**
   * Retrieves all users in the realm.
   *
   * @return the list of users
   */
  getAllUsers(): Observable<User[]> {
    let url: string = this.userEndpoint;
    const parameters: string = RestUtil.createURLParameters(new SearchQuery());
    if (parameters) {
      url += '?' + parameters;
    }
    return this.httpClient.get<User[]>(url).pipe(map(response => response.map(item => new User(item))));
  }

  /**
   * Retrieves the user information for the given user id.
   *
   * @param userId the id of user
   * @return the user
   */
  getUser(userId: string): Observable<User> {
    return this.httpClient.get<User>(`${this.userEndpoint}/${userId}`).pipe(map(response => new User(response)));
  }

  /**
   * Creates a realm administrator user for the specified realm.
   *
   * @param username username
   * @param password password
   * @param realmId the id of realm
   * @param firstName The first name of user
   * @param lastName The last name of user
   * @return the created user
   */
  createAdminForRealm(username: string, password: string, realmId: string, firstName: string, lastName: string): Observable<User> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('firstName', firstName)
      .set('lastName', lastName);
    return this.httpClient.post(`${this.createAdminForOtherRealmEndpoint}/${realmId}/admins`, body).pipe(map(response => new User(response)));
  }

  /**
   * Creates a realm administrator user for the realm.
   *
   * @param username username
   * @param password password
   * @return the created user
   */
  createAdmin(username: string, password: string): Observable<User> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password);
    return this.httpClient.post(this.adminEndpoint, body).pipe(map(response => new User(response)));
  }

  /**
   * Creates a standard user for the realm.
   *
   * @param username username
   * @param password password
   * @param firstName The first name of user
   * @param lastName The last name of user
   * @param companyId
   * @param companyName
   * @param phoneNumber
   * @return the created user
   */
  createStandardUser(username: string, password: string, firstName: string, lastName: string, companyId: number = undefined, companyName: string = undefined, phoneNumber: string = undefined): Observable<User> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('firstName', firstName)
      .set('lastName', lastName)
      .set('companyId', companyId)
      .set('companyName', companyName)
      .set('phoneNumber', phoneNumber);
    return this.httpClient.post(this.userEndpoint, body).pipe(map(response => new User(response)));
  }

  /**
   * Updates the user's info.
   * @param userId the identifier of user
   * @param patch patch object including the updates
   */
  patchUser(userId: string, patch: any): Observable<User> {
    return this.httpClient.patch(`${this.userEndpoint}/${userId}`, patch).pipe(map(response => new User(response)));
  }

  /**
   * Deletes the user.
   *
   * @param userId the id of user
   */
  deleteUser(userId: string) {
    return this.httpClient.delete(`${this.userEndpoint}/${userId}`);
  }

  /**
   * Enables user within the realm.
   *
   * @param userId the id of user
   */
  enableUser(userId: string) {
    return this.httpClient.put(`${this.userEndpoint}/${userId}/status`, null);
  }

  /**
   * Disables user within the realm.
   *
   * @param userId the id of user
   */
  disableUser(userId: string) {
    return this.httpClient.delete(`${this.userEndpoint}/${userId}/status`);
  }

  /**
   * Assign a role to user.
   *
   * @param userId the id of user
   * @param role the role to be assigned
   */
  assignRole(userId: string, role: string) {
    return this.httpClient.put(`${this.userEndpoint}/${userId}/roles/${role}`, null);
  }

  /**
   * Revokes the role from user.
   *
   * @param userId the id of user
   * @param role the role to be revoked from user
   */
  revokeRole(userId: string, role: string) {
    return this.httpClient.delete(`${this.userEndpoint}/${userId}/roles/${role}`);
  }

  /**
   * Assign user to the domain.
   *
   * @param userId the id of user
   * @param domain the domain
   */
  assignDomain(userId: string, domain: string) {
    return this.httpClient.put(`${this.userEndpoint}/${userId}/domains/${domain}`, null);
  }

  /**
   * Revoke the user from domain.
   *
   * @param userId the id of user
   * @param domain the domain
   */
  revokeDomain(userId: string, domain: string) {
    return this.httpClient.delete(`${this.userEndpoint}/${userId}/domains/${domain}`);
  }
}
