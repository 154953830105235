import {Injectable, Injector, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map, pairwise, startWith, take, takeUntil} from 'rxjs/operators';
import {UserPreference} from '../../../shared/models/user-preference.model';
import {environment} from '../../../../environments/environment';
import {UserService} from '../data/user.service';
import {Page} from '../../../shared/models/page.model';
import {BaseHttpClientService} from '../base-http-client.service';
import {EventService} from "../event.service";

/**
 * A service to handle user preferences
 */
@Injectable()
export class UserPreferenceService extends BaseHttpClientService implements OnDestroy {
  // Subscription object for unsubscribing when destroying the service to avoid leaks
  destroy$ = new Subject<void>();
  private userService: UserService;

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'userpreferences');

    this.userService = injector.get(UserService);
    // subscribe to the user preferences changes
    this.userService.onUserPreferencesChanged()
      .pipe(
        takeUntil(this.destroy$),
        startWith(null), pairwise()
      )
      .subscribe((userPreferences) => {
        // userPreferences[0] keeps the previous value while userPreferences[1] keeps the current one
        // no need to get page summaries when page count is not changed in user preference which corresponds to the case
        // where pages are reordered in the menu
        if (!userPreferences[0] || userPreferences[0].getPageIds().length !== userPreferences[1].getPageIds().length
          || userPreferences[0].pageGroups.length !== userPreferences[1].pageGroups.length) {
          // retrieve page summaries for the user preferences
          this.getPageSummaries(this.userService.userId.getValue());
        }
      });
    // subscribe to the event service
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.PAGE_DELETED:
            // update the user preferences if the deleted page is included in the preferences
            this.userService.onUserPreferencesChanged()
              .pipe(take(1))
              .subscribe(userPreferences => {
                if(userPreferences.getPageIds().includes(event.data)){
                  this.getUserPreference(this.userService.userId.getValue())
                }
              })
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Retrieves the preferences of the user
   * @param userId Id of the user
   */
  getUserPreference(userId: String): void {
    const url = this.endpoint + '/' + userId;
    this.httpClient.get(url)
      .pipe(
        map(response => {
          this.userService.setUserPreferences(new UserPreference(response));
        }))
      .subscribe();
  }

  /**
   * Updates the preferences of the user
   * @param userId Id of the user
   * @param userPreference Updated user preference
   */
  updateUserPreference(userId: String, userPreference: UserPreference): Observable<void> {
    const url = this.endpoint + '/' + userId;
    return this.httpClient.put(url, userPreference)
      .pipe(
        map(response => {
          this.userService.setUserPreferences(new UserPreference(response));
        }));
  }

  /**
   * Retrieves the page summaries of the user
   * @param userId Id of the user
   */
  getPageSummaries(userId: String): void {
    const url = this.endpoint + '/' + userId + '/pagesummaries';
    this.httpClient.get(url)
      .pipe(
        map(response => {
          const responseJson: any = response;
          const pages: Map<string, Page> = new Map<string, Page>();
          if (responseJson.pages) {
            for (const pageId of Object.keys(responseJson.pages)) {
              pages.set(pageId, new Page({
                ...responseJson.pages[pageId],
                id: pageId
              }));
            }
          }
          this.userService.setPageSummaries(pages);
        }))
      .subscribe();
  }
}
