<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    <label> Name: </label>
    <hr>
    <input #editname placeholder="Name" nbInput id="editname" class="search-input size-medium status-basic shape-rectangle nb-transition">
    <hr>
    <label> Namespace: </label>
    <hr>
    <input #editnamespace placeholder="Namespace" nbInput id="editnamespace" class="search-input size-medium status-basic shape-rectangle nb-transition">
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-danger"  (click)="submit({id: this.id, name: editname.value, namespace: editnamespace.value})">Submit</button>
    <button class="btn btn-primary" (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>
