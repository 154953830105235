export enum Color {
  Red = '#FF6B6B',
  Green = '#31C048',
  Blue = '#4D96FF',
  Orange = '#FFA556',
  White = '#FFFFFF',
  Pink = '#FF8FB1',
  Yellow = '#FFD93D',
  Gray = '#B0B0B0'
}
