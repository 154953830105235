import {BucketResult} from '../schema/aggregation/bucket-result';
import {AggregationResult} from '../schema/aggregation/aggregation-result';
import {NGSIPath} from './ngsi-path.model';
import {EntityResult} from '../schema/entity-result';
import { ObjectUtil } from 'app/shared/utils/object-util';

/**
 * Wrapper class on different types of NGSI responses
 */
export class NGSIResult {

  constructor(public result: EntityResult | BucketResult | AggregationResult) {
  }

  getEntityResult(): EntityResult {
    return this.result as EntityResult;
  }

  getBucketResult(): BucketResult {
    return this.result as BucketResult;
  }

  getAggregationResult(): AggregationResult {
    return this.result as AggregationResult;
  }

  /**
   * Extracts value of which location is specified by the given path
   * @param path
   * @param useValueField
   */
  extractValueByNgsiPath(path: NGSIPath, useValueField: boolean = true): any {
    if (this.result instanceof EntityResult) {
      return path.executeOnJson(this.result.entity, this.result.queryOptions, useValueField);
    } else {
      console.error('Value extraction is not implemented for Bucket or Aggregation results yet');
    }
  }

  /**
   * Extracts value of which location is specified by the given path. If the entity contains data for a temporal query, 
   * it returns the first value which is the latest one.
   * @param path
   * @param useValueField
   */
   extractSingleValueByNgsiPath(path: NGSIPath, useValueField: boolean = true): any {
    const values = this.extractValueByNgsiPath(path, useValueField);
    if (this.result instanceof EntityResult && this.result.queryOptions.isTemporal && ObjectUtil.isArray(values) && values.length) {
      return values[0]; 
    }
    return values;
  }

  getEntityId(): string {
    return (this.result as EntityResult).entity.id;
  }
}

