/**
 * Possible types of pages that can be instantiated with customized content
 */

export enum TemplateType {
  SIX_PANEL_MAP = 'SixPanelMap',
  ENTITY_DIALOG = 'EntityDialog',
  JUNCTION_ARM_DIALOG = 'JunctionArmDialog',
  PARKING_DIALOG = 'ParkingDialog',
  WEATHER_POLLUTION_DIALOG = 'WeatherPollutionObservationDialog',
  ALERT_DIALOG = 'AlertDialog',
  VIDEOWALL = 'Videowall',
  VEHICLE_DIALOG = 'VehicleDialog',
  TRAIN_DIALOG = 'TrainDialog',
  MGUBCASTLE_DIALOG = 'MGUBCastleDialog',
  ELECTRIC_BUS_DIALOG = 'ElectricBusDialog',
  SOLARPOLE_DIALOG = 'SolarPoleDialog',
  ANNOUNCESENSOR_DIALOG = 'AnnounceSensorDialog',
  DEFIBRILLATOR_DIALOG = 'DefibrillatorDialog',
  JKGYS_DIALOG = 'JkgysDialog',
  JKGYSTAK_DIALOG = 'JkgysTakDialog',
  TCDDTRAIN_DILAOG = 'TcddTrainDialog',
  DRONE_DIALOG = 'DroneDialog',
  NO_PAGE = 'NoPage'
}

export class TemplateTypeUtil {

  /**
   * Keeps the dialog template types
   * */
  private static DIALOG_TEMPLATE_TYPES: TemplateType[] = [TemplateType.ENTITY_DIALOG, TemplateType.JUNCTION_ARM_DIALOG, TemplateType.PARKING_DIALOG, TemplateType.WEATHER_POLLUTION_DIALOG, TemplateType.ALERT_DIALOG
    , TemplateType.VEHICLE_DIALOG, TemplateType.TRAIN_DIALOG, TemplateType.ELECTRIC_BUS_DIALOG, TemplateType.ANNOUNCESENSOR_DIALOG];

  /**
   * Returns whether the given template type represents a dialog.
   * @param type the template type
   * @return whether the given template type represents a dialog.
   */
  public static isDialogTemplate(type: TemplateType): boolean {
    return this.DIALOG_TEMPLATE_TYPES.includes(type);
  }
}
