import {LayerController} from './layer.controller';
import {Layer} from '../../shared/models/layer.model';
import {Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, retry, take} from 'rxjs/operators';
import {NGSIQuery} from '../../shared/models/ngsi/ngsi-query.model';
import {NGSIFilter} from '../../shared/models/ngsi/ngsi-filter.model';
import {EntityRepresentation} from '../../shared/models/visualization/representation.model';
import {AlertManagementService} from '../services/alert-management.service';
import {EntityResult} from '../../shared/models/schema/entity-result';
import {QueryOptions} from '../../shared/models/ngsi/query-options';
import {FormUtil} from '../../shared/utils/form-util';
import { AlertPriority } from 'app/modules/alert/enums/alert-priority';
import { environment } from 'environments/environment';
export class AlertLayerController extends LayerController {

  private alertService: AlertManagementService;

  constructor(layer: Layer, page: Page, private injector: Injector) {
    super(layer, page, injector);
    this.alertService = injector.get(AlertManagementService);
  }

  protected getDialogContext(result: NGSIResult): any {
    // fetch alert
    const alertQuery = this.getMarkerEntity(result.getEntityId());
    const contextObservable: Observable<any> = alertQuery
      .pipe(
        take(1),
        map(response => {
          return {
            entity: response.getEntityResult()          
          };
        }),
        // when an alert entity comes from the subscription, retry is needed since it takes some time to save alert to the database
        retry({ count: 3, delay: environment.timeouts.fetchAlert }),
        catchError((e) => {
          return of(null);
        })
      );

    return {
      page: this.popupPage,
      entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
      asyncContext: contextObservable
    };
  }

  /**
   * Processes the alerts and simulates a click event for alerts having at least severe severity
   * @protected
   */
  protected afterEntitiesProcessed(): void {
    for (const results of this.entities.entries()) {
      // there should only be one set of NGSIResults
      const entityRepresentation: EntityRepresentation = results[0];
      for (const result of results[1]) {
        const alert: EntityResult = result.getEntityResult();

        // do not show a popup for this alert if one was shown already
        if (!this.alertService.isPopupShownForAlert(alert.id)) {
          // extract alert source id
          const severityValues = alert.getElementValue('severity');
          const severity: string = Array.isArray(severityValues) ? alert.getFirstTemporalValue('severity').value : severityValues;
          if (!FormUtil.isEmptyValue(severity) && severity >= AlertPriority.SEVERE) {
            this.entityClicked(result, entityRepresentation.visualization.popupPageId);
            this.alertService.alertShownForPopup(alert.id);
          }
        }
      }
      break;
    }
  }
}
