import {LayerController} from './layer.controller';
import {Layer} from '../../shared/models/layer.model';
import {Injector} from '@angular/core';
import {Page} from '../../shared/models/page.model';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {EntityResult} from '../../shared/models/schema/entity-result';
import {NGSIPath} from '../../shared/models/ngsi/ngsi-path.model';

export class JunctionLayerController extends LayerController {

  constructor(layer: Layer, page: Page, injector: Injector) {
    super(layer, page, injector);
  }

  /**
   * Collects the values to be provided to the dialog to be opened on entity click
   * @param result
   * @private
   */
  getDialogContext(result: NGSIResult): any {
    if (this.isJunctionArmResult(result)) {
      const contextObservable: Observable<any> = this.getMarkerEntity(result.getEntityId(), false).pipe(
        take(1),
        map(entity => {
          const junctionArm: EntityResult = entity.getEntityResult();

          const topLabels: string[] = [];
          topLabels.push('Average Vehicle Length');
          topLabels.push('Average Vehicle Speed');
          topLabels.push('Number of Vehicles Passed');

          const topValues: string[] = [];
          topValues.push(this.getPropertyValue(entity, 'averageVehicleLength', true, 'unit.m'));
          topValues.push(this.getPropertyValue(entity, 'averageVehicleSpeed', true, 'unit.km/h'));
          topValues.push(this.getPropertyValue(entity, 'numberOfVehiclesPassed', false).numberOfVehiclesPassed);
          return {
            title: this.getPropertyValue(entity, 'name', false),
            topLabels: topLabels,
            topValues: topValues,
            entity: result.result,
          };
        })
      );
      return {
        page: this.popupPage,
        entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
        asyncContext: contextObservable
      };

    } else {
      const contextObservable: Observable<any> = this.getMarkerEntity(result.getEntityId(), false).pipe(
        take(1),
        map(entity => {
          const junction: EntityResult = entity.getEntityResult();

          const topLabels: string[] = [];
          topLabels.push('Average CO2 Emission Saving');
          topLabels.push('Average Fuel Saving');
          topLabels.push('Average Time Saving');

          const topValues: string[] = [];
          topValues.push(this.getPropertyValue(entity, 'averageCo2EmissionSaving', true, 'kg'));
          topValues.push(this.getPropertyValue(entity, 'averageFuelSaving', true, 'lt'));
          topValues.push(this.getPropertyValue(entity, 'averageTimeSaving', true, 'sec'));

          return {
            title: this.getPropertyValue(entity, 'name', false),
            page: this.popupPage,
            topLabels: topLabels,
            topValues: topValues,
            entity: junction,
            footer: junction.getAddress()
          };
        })
      );
      return {
        page: this.popupPage,
        entity: result.getEntityResult(), // passing existing entity as it is being to retrieve the updated one by the panels in the dialog
        asyncContext: contextObservable
      };
    }
  }

  private isJunctionArmResult(result: NGSIResult): boolean {
    const entityType: string = result.getEntityId().split(':')[2];
    return entityType === 'JunctionArm';
  }
}
