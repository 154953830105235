<div [ngClass]="{'parent-menu-item': menuItem.children?.length,'expanded':pathToSelectedMenuItem.includes(menuItem.id)}"
       class="menu-item">
  <!-- Details of menu item such as icon and title -->
  <div [ngClass]="{'marked':marked}" class="menu-item-title">
    <!--  Icon of menu item-->
    <nb-icon (click)="onMenuItemClicked()" *ngIf="displayMenuItemIcon && menuItem.icon" [icon]="menuItem.icon.icon"
             [pack]="menuItem.icon.pack"></nb-icon>

    <!--  Title of menu item-->
    <!-- Title with a link -->
    <a (click)="onMenuItemClicked()" *ngIf="menuItem.link" [routerLink]="menuItem.link">{{menuItem.title | translate}}</a>
    <!-- Title without a link -->
    <a (click)="onMenuItemClicked()" *ngIf="!menuItem.link">{{menuItem.title | translate}}</a>

    <!-- Arrow icons to hide/display children items-->
    <div (click)="onMenuItemClicked()" *ngIf="menuItem.children && menuItem.children.length">
      <!-- Icon to be displayed if the menu item is collapsed -->
      <nb-icon icon="chevron-right"></nb-icon>
      <!-- Icon to be displayed if the menu item is expanded -->
      <nb-icon icon="chevron-down"></nb-icon>
    </div>
  </div>
  <!--  Children of menu item-->
  <ng-container *ngIf="displayChildrenMenuItems">
    <!-- children items which do not have children are draggable and they can be reordered within this menu item -->
    <div cdkDropList (cdkDropListDropped)="drop($event)" class="drop-list">
      <div *ngFor="let menuItem of childrenItems[0]" cdkDrag>
        <uruk-menu-item
                      (markParentMenuItem)="markMenuItem()"
                      [menuItem]="menuItem" ></uruk-menu-item>
      </div>
    </div>
    <div *ngFor="let menuItem of childrenItems[1]">
      <uruk-menu-item
                    (markParentMenuItem)="markMenuItem()"
                    [menuItem]="menuItem" ></uruk-menu-item>
    </div>
  </ng-container>
</div>
