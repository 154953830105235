import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Region} from '../../../shared/models/region.model';
import {environment} from '../../../../environments/environment';
import {RestParameters, RestUtil} from '../../../shared/utils/rest-util';
import {BaseHttpClientService} from '../base-http-client.service';
import {SearchQuery} from '../../../shared/models/query/search-query.model';
import { RegionQuery } from 'app/shared/models/query/region-query.model';

/**
 * A service to handle regions
 */
@Injectable()
export class RegionService extends BaseHttpClientService {

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'regions');
  }

  /**
   * Retrieves all regions defined on the platform
   * @param parentRegionId Id of the region whose subregions should be returned
   */
  getAllRegions(parentRegionId?: String): Observable<Region[]> {
    const query:RegionQuery = new RegionQuery({
      parentRegionId: parentRegionId
    });
    return this.getRegions(query);
  }

  /**
   * Get regions for the query
   * @param query
   */
  getRegions(query?: RegionQuery): Observable<Region[]> {
    let url: string = this.endpoint;
    if (query) {
      const parameters: string = RestUtil.createURLParameters(query);
      if (parameters) {
        url += '?' + parameters;
      }
    }

    return this.httpClient.get<Region[]>(url)
      .pipe(
        map(response => {
          return response.map(item => new Region(item));
        })
      );
  }

  /**
   * Retrieves the details of a single region
   * @param regionId Id of the region
   */
  getRegion(regionId: String): Observable<Region> {
    const url = this.endpoint + '/' + regionId;
    return this.httpClient.get(url).pipe(map(response => new Region(response)));
  }

  /**
   * Creates a new region on the platform
   * @param region Region to be created
   */
  createRegion(region: Region): Observable<Region> {
    return this.httpClient.post(this.endpoint, region).pipe(map(response => new Region(response)));
  }

  /**
   * Updates the given region
   * @param region Region to be updated
   */
  updateRegion(region: Region): Observable<Region> {
    const url = this.endpoint + '/' + region.id;
    return this.httpClient.put(url, region).pipe(map(response => new Region(response)));
  }

  /**
   * Deletes the region.
   * @param regionId Id of the region to be deleted
   */
   deleteRegion(regionId: string) {
    const url = this.endpoint + '/' + regionId;
    return this.httpClient.delete(url);
  }
}
