import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {BaseDialogComponent} from '../../../../shared/components/dialog/base-dialog.component';
import {NGSIOperator} from '../../../../shared/enums/ngsi-query.enum';
import {BaseDataType} from '../../../../shared/enums/base-data-type';
import {NGSIQueryTerm} from '../../../../shared/models/ngsi/ngsi-query-term.model';
import {Range} from '../../../../shared/models/generic/range.model';
import {MultiValueInputComponent} from '../../../../shared/components/form/multi-value-input.component';
import {ElementDefinition} from '../../../../shared/models/schema/element-definition.model';
import {NGSIPath} from '../../../../shared/models/ngsi/ngsi-path.model';
import {FormUtil} from '../../../../shared/utils/form-util';
import {SelectWidthFormatterUtil} from '../../../../shared/utils/select-width-formatter-util';
import { UrukTemporalQuery } from 'app/shared/models/query/uruk-temporal-query.model';
import { TimeUtil } from 'app/shared/utils/time-util';

@Component({
  templateUrl: './query-term-editor-dialog.component.html',
  styleUrls: ['./query-term-editor-dialog.component.scss']
})
export class QueryTermEditorDialogComponent extends BaseDialogComponent implements OnInit {
  // available parameters that can be used to define a filter for the given entity type
  @Input() elements: ElementDefinition[];
  // the query term can be passed directly e.g. in case of edit mode
  @Input() queryTerm: NGSIQueryTerm;
  // mode for the dialog
  @Input() displayMode: 'create' | 'edit' = 'create';

  // current selected parameter as the operand of the operator
  selectedParameter: ElementDefinition;
  // operators available for the selected parameter
  availableOperators: string[];
  // operator selected by the user
  selectedOperator: string = NGSIOperator.EQUAL;
  // whether the filter value is a single value or a range value
  valueModality: 'single' | 'range' = 'single';
  // keeps single values for the filter
  values: any[] = [];
  // start value for the range value
  startValue: any;
  // end value for the range value
  endValue: any;

  @ViewChild('valueInput') valueInput: MultiValueInputComponent;

  BaseDataType = BaseDataType;
  NGSIOperator = NGSIOperator;
  elementDefinitionFormatter = SelectWidthFormatterUtil.elementDefinitionFormatter;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.queryTerm) {
      this.selectedOperator = this.queryTerm.op;
      this.selectedParameter = this.elements.find(element => element.ngsiPath.columnName === this.queryTerm.path.columnName);
      if (this.queryTerm.value) {
        this.valueModality = 'single';
        this.values = this.queryTerm.value;
      } else {
        this.valueModality = 'range';
        this.startValue = this.queryTerm.valueRange.start;
        this.endValue = this.queryTerm.valueRange.end;
      }
    } else {
      this.selectedParameter = this.elements[0];
    }

    this.setAvailableOperators();
  }

  /**
   * Handles the change of selected parameter. Updates available operators and resets the existing values
   * @param selected
   */
  onSelectedChange(selected: ElementDefinition): void {
    this.selectedParameter = selected;
    // set available operators for the current parameter
    this.setAvailableOperators();
    // clear the values
    this.values = [];
  }

  onAddClicked(): void {
    if (!this.validateForm()) {
      return;
    }

    // get the formatter function for the selected parameter
    const formatMapper = this.getFormatMapper(this.selectedParameter.type);
    // create filter
    const filter: NGSIQueryTerm = new NGSIQueryTerm();
    filter.op = this.selectedOperator;
    filter.path = this.selectedParameter.ngsiPath as NGSIPath;
    if (this.valueModality === 'single') {     
      const values = this.valueInput.getValue()
        .filter(v => !FormUtil.isEmptyValue(v)) // discard empty values
        .map(v => formatMapper(v)) // format values
      // filter with no values means that it will be used for null check. Therefore, set filter value to [null]
      filter.value = values.length ? values : [null] ;
      filter.valueRange = null;
    } else {
      filter.valueRange = new Range({start: formatMapper(this.startValue), end: formatMapper(this.endValue)});
      filter.value = null;
    }

    this.dialogRef.close(filter);
  }

  validateForm(): boolean {
    let formValid = true;

    if (this.valueModality === 'single') {
      if (!this.valueInput.validateForm()) {
        formValid = false;
        this.displayErrors = true;
      }

    } else if (this.valueModality === 'range') {
      if (FormUtil.isEmptyValue(this.startValue) || FormUtil.isEmptyValue(this.endValue)) {
        formValid = false;
        this.displayErrors = true;
      }
    }
    return formValid;
  }

  /**
   * Returns a function to be used to format the value of an NGSI Filter based on the given data type.
   * @param dataType the data type
   * @returns a formatter function 
   */
  private getFormatMapper(dataType){
    switch(dataType){
      case BaseDataType.DATE:
        return (x:UrukTemporalQuery|string) => {
          if(typeof x === 'string')
            return x
          return TimeUtil.serializeDate(x.time)
        };
      case BaseDataType.DATETIME:
        return (x:UrukTemporalQuery|string) => {
          if(typeof x === 'string')
            return x
          return x.time.toISOString()
        };
        case BaseDataType.TIME:
          return (x:UrukTemporalQuery|string) => {
            if(typeof x === 'string')
              return x
            return TimeUtil.serializeISOHour(x.time)
          };
      default:
        return x => x;
    }
  }

  /**
   *
   * @private
   */
  private setAvailableOperators(): void {
    const dataType: string = this.selectedParameter.type;
    if (dataType === BaseDataType.TEXT) {
      this.availableOperators = [NGSIOperator.EQUAL, NGSIOperator.UNEQUAL];

    } else if (dataType === BaseDataType.BOOLEAN) {
      this.availableOperators = [NGSIOperator.EQUAL];

    } else {
      this.availableOperators = Object.values(NGSIOperator);
    }
  }

  /**
   * Checks whether range values are available for the selected parameter
   */
  isRangeValueAvailable(): boolean {
    return this.selectedParameter.type === BaseDataType.NUMBER || this.selectedParameter.type === BaseDataType.DATE ||
      this.selectedParameter.type === BaseDataType.DATETIME || this.selectedParameter.type === BaseDataType.TIME;
  }

  isMultiValueAvailable(): boolean {
    return this.selectedOperator === NGSIOperator.EQUAL || this.selectedOperator === NGSIOperator.UNEQUAL;
  }

  displayErrorForStartValue(): boolean {
    return this.displayErrors && !this.startValue;
  }

  displayErrorForEndValue(): boolean {
    return this.displayErrors && !this.endValue;
  }
}
