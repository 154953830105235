import {Component, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {EventService} from '../../../core/services/event.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../../core/services/layout.service';
import {NbDialogService} from '@nebular/theme';
import {BaseComponent} from '../base.component';

/**
 * Base component providing form-related capabilities
 */
@Component({
  template: '',
})
export abstract class BaseFormComponent extends BaseComponent implements OnInit, OnDestroy {

  // flag indicating whether errors are displayed for input fields have invalid values
  @Input() displayErrors = false;

  protected constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Validates the form managed by this component
   * @protected
   */
  validateForm(): boolean {
    return true;
  }

  /**
   * Utility method to copy values by respecting their type
   * @param values actual content to be copied
   * @param _type constructor to instantiate typed values
   * @param factoryMethod factory method to construct a polymorphic typed value e.g. implementation of an interface or abstract class
   * @protected
   */
  protected copyValues(values: any[], _type: new (content: any) => any = null, factoryMethod: (content: any) => any = null): any[] {
    if (!values) {
      return [];
    } else {
      return values.map(value => this.copyValue(value, _type, factoryMethod));
    }
  }

  /**
   * Copies a single value in a type-aware manner
   * @param value
   * @param _type
   * @param factoryMethod
   * @protected
   */
  protected copyValue(value: any, _type: new (content: any) => any = null, factoryMethod: (content: any) => any): any {
    if (!_type) {
      return value;
    } else {
      if (_type) {
        return new _type(value);
      } else {
        return factoryMethod(value);
      }
    }
  }

  /**
   * Returns the data managed in this form component
   */
  getValue(): any {
    // Root forms can use this base method as they do not need to return any value to some parent component.
    return null;
  }
}
