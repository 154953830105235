import { Component, Input, OnInit } from '@angular/core';
import { DefibrillatorTest } from '../../../../../../shared/models/defibrillatorTest.model';
import { BaseDialogComponent } from '../../../../../../shared/components/dialog/base-dialog.component';
import { NbDialogRef } from '@nebular/theme';
import { DefibrillatorInfoDialogComponent } from './info/defibrillator-infodialog.component';

@Component({
  templateUrl: './defibrillator-dialog.component.html',
  styleUrls: ['./defibrillator-dialog.component.scss']
})
export class DefibrillatorDialogComponent extends BaseDialogComponent implements OnInit {

  showImage = false;
  @Input() test: DefibrillatorTest;
  @Input() aselsanUser: any;
  defibrillatorTest: DefibrillatorTest;
  dialogRef: NbDialogRef<any>;

  ads4v6ResultSuccess: number;
  dacSetVoltageSuccess: number;
  chargingVoltageSuccess: number;
  chargeTimeSuccess: number;
  hBridge1Success: number;
  hBridge2Success: number;


  ngOnInit(): void {
    this.defibrillatorTest = this.test;
    this.ads4v6ResultSuccess = this.checkAds4v6Result();
    this.dacSetVoltageSuccess = this.checkDacSetVoltage();
    this.chargingVoltageSuccess = this.checkChargingVoltage();
    this.chargeTimeSuccess = this.checkChargeTime();
    this.hBridge1Success = this.checkHBridge1();
    this.hBridge2Success = this.checkHBridge2();
  }

  checkAds4v6Result(): number {
    return this.defibrillatorTest.ads4v6Result !== null
    && this.defibrillatorTest.ads4v6Result > 4.1
    && this.defibrillatorTest.ads4v6Result < 5.1 ? 1 : 0;
  }

  checkDacSetVoltage(): number {
    return this.defibrillatorTest.dacSetVoltage !== null
    && this.defibrillatorTest.dacSetVoltage >= 0
    && this.defibrillatorTest.dacSetVoltage<2150 ? 1 : 0;
  }

  checkChargingVoltage(): number {
    return this.defibrillatorTest.chargingVoltage !== null
    && this.defibrillatorTest.chargingVoltage >= 0
    && this.defibrillatorTest.chargingVoltage < 2300 ? 1 : 0;
  }

  checkChargeTime(): number {
    return this.defibrillatorTest.chargeTime !== null
    && this.defibrillatorTest.chargeTime < 12
    && this.defibrillatorTest.chargeTime > 4 ? 1 : 0;
  }

  checkHBridge1(): number {
    return this.defibrillatorTest.hBridge1 !== null
    && this.defibrillatorTest.hBridge1 >= 50
    && this.defibrillatorTest.hBridge1 <= 60 ? 1 : 0;
  }

  checkHBridge2(): number {
    return this.defibrillatorTest.hBridge2 !== null
    && this.defibrillatorTest.hBridge2 >= 40
    && this.defibrillatorTest.hBridge2 <= 50 ? 1 : 0;
  }

  openInfoDialog(imageName, errorHeader): void {
    this.showImage = true;
    this.dialogRef = this.dialogService.open(DefibrillatorInfoDialogComponent, {
      context: {
        image: 'assets/images/' + imageName,
        errorHeader: errorHeader
      }
    });
  }
}
