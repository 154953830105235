<closeable-dialog class="dialog-container">
  <label dialog-card-header>{{ title }}</label>

  <!-- do not present visualization options, if there is an anomaly -->
  <div dialog-additional-buttons class="additional-buttons" (click)="displayCamera()" *ngIf="!entity?.getSingleElementValue('anomaly') && shouldDisplayInfo()">
    <i class="fa fa-camera"></i>
  </div>

  <div dialog-additional-buttons class="additional-buttons" (click)="displayInfo()" *ngIf="!entity?.getSingleElementValue('anomaly') && shouldDisplayCamera()">
    <i class="fa fa-info-circle"></i>
  </div>

  <div dialog-card-body class="full-height">

    <div *ngIf="topLabels.length > 0" class="row" [ngClass]="{ 'four-column': topLabels.length < 5 }">
      <div *ngFor="let label of topLabels; let i = index" class="row-item ">
        <div class="h6">{{ label | translate }}</div>
        <div [ngClass]="entity?.getSingleElementValue('anomaly') ? 'warning-value' : 'regular-value'">{{ topValues[i] }}</div>
      </div>
    </div>

    <ng-container *ngIf="!entity?.getSingleElementValue('anomaly')">
      <div class="image-container" [hidden]="!shouldDisplayCamera()">
        <img class="image margin" [src]="entity?.getSingleElementValue('videoUrl')">
      </div>

      <div [hidden]="!shouldDisplayInfo()">
        <div *ngIf="secondRowPanel" class="row">
          <div class="col-md-12 chart no-pad">
            <uruk-panel [panel]="secondRowPanel" [pageId]="page.id"></uruk-panel>
          </div>
        </div>

        <div *ngIf="thirdRowPanel" class="row">
          <div class="col-md-12 chart no-pad">
            <uruk-panel [panel]="thirdRowPanel" [pageId]="page.id"></uruk-panel>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="entity?.getSingleElementValue('anomaly')">
      <video *ngIf="entity?.getSingleElementValue('anomaly')" id="my_video_1" style="width: 100%" controls preload="none"
             data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
        <source [src]="'https://www.geleceginsehri.com/6.mp4'" type='video/mp4' />
      </video>
    </ng-container>
  </div>

  <div *ngIf="entity?.getAddress()" dialog-card-footer>
    {{'Address' | translate}}: {{ entity.getAddress() }}
  </div>
</closeable-dialog>
