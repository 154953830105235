<nb-card>
  <nb-card-header>
    <div class="header-container">
      <div class="header">
        <ng-content select="[dialog-card-header]"></ng-content>
      </div>

      <ng-content select="[dialog-additional-buttons]"></ng-content>

      <div class="close-button" (click)="closeDialog()">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <ng-content select="[dialog-card-body]"></ng-content>
  </nb-card-body>
  <nb-card-footer *ngIf="displayFooter">
    <ng-content select="[dialog-card-footer]"></ng-content>
  </nb-card-footer>
</nb-card>
