import {BaseModel} from '../base/base-model.model';

export abstract class QueryOperatorParam extends BaseModel {
  dataType: string;

  constructor(data?: any) {
    super(data);
    this.jsonClass = 'QueryOperatorParam';
    if (!data) {
      return;
    }
    this.dataType = data.dataType;
  }

  copy(that: QueryOperatorParam): void {
    this.dataType = that.dataType;
  }

  abstract get columnName(): string;
}
