import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../base.component';
import {UrukTemporalQuery} from '../../models/query/uruk-temporal-query.model';
import {ObjectUtil} from '../../utils/object-util';

@Component({
  selector: 'uruk-date-time-selection',
  templateUrl: './date-time-selection.component.html',
  styleUrls: ['./date-time-selection.component.scss']
})
export class DateTimeSelectionComponent extends BaseComponent implements OnInit, OnDestroy {
  // temporal query being updated
  @Input() temporalQuery: UrukTemporalQuery;
  // an identifier specifying the parent component id including this date selection component. This parameter is used to decide which instance of date selection
  // component will handle the date selection event
  @Input() parentComponentId: string;
  // if this flag is true, the component will be used to select only time not date time
  @Input() onlyTimeSelection:boolean = false;
  // output to notify the parent component about the date change
  @Output() onTemporalQueryChanged: EventEmitter<UrukTemporalQuery> = new EventEmitter<UrukTemporalQuery>();

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    // the string representation of UrukTemporalQuery can be passed to the component as an input
    // if it is the case,  parse the given string
    if(typeof this.temporalQuery === 'string'){
      this.temporalQuery = UrukTemporalQuery.parseTemporalQuery(this.temporalQuery)
    } else if(this.temporalQuery){
      this.temporalQuery = ObjectUtil.deepCopy(UrukTemporalQuery, this.temporalQuery);
    } else if(this.onlyTimeSelection){
      this.temporalQuery = UrukTemporalQuery.today();
    }
  }

  /**
   * Copies the content of the updated temporal query to the temporal query managed by this component
   * @private
   */
  handleTimeChangedEvent(eventData: any): void {
    this.temporalQuery = eventData;
    this.onTemporalQueryChanged.emit(this.temporalQuery);
  }

  handleHourChange(hour: number): void {
    this.temporalQuery.time.setHours(hour);
    this.onTemporalQueryChanged.emit(this.temporalQuery);
  }

  handleMinuteChange(minute: number): void {
    this.temporalQuery.time.setMinutes(minute);
    this.onTemporalQueryChanged.emit(this.temporalQuery);
  }
}
