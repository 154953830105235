import {Namespace} from './namespace.enum';

export enum TagType {
  REALM = 'realm',
  DOMAIN = 'domain',
  CUSTOM = 'custom'
}

export class TagTypeUtil {

  /**
   * Returns the prefix for the given tag type
   * @param tagType
   */
  public static getTagPrefix(tagType: TagType): string {
    if (tagType === TagType.DOMAIN) {
      return Namespace.DOMAIN;
    } else if (tagType === TagType.REALM) {
      return Namespace.REALM;
    } else {
      return Namespace.CUSTOM;
    }
  }

  /**
   * Checks whether the given tag is a domain tag. If it starts with domain namespace, it is a domain tag.
   * @param tag The tag consisting of two parts: namespace and code
   */
  public static isDomainTag(tag: string): boolean {
    return tag.startsWith(Namespace.DOMAIN);
  }

  /**
   * Extracts the tag code by removing the namespace from the tag.
   * @param tag The tag consisting of two parts: namespace and code
   * @param namespace The namespace
   * @return the tag code
   */
  public static extractTagCode(tag: string, namespace: Namespace = Namespace.DOMAIN): string {
    return tag.substring(namespace.length);
  }
}
