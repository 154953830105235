import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * Returns the word-for-word translation of a sentence.
 * */
@Pipe({
  name: 'wordForWordTranslate',
  pure: false // required to update the value when the language id is changed for the application
})
export class WordForWordTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {

  }

  transform(sentence: string): string {
    if (sentence) {
      const words: string[] = sentence.split(' ');
      return words.map(word => this.translateService.instant(word)).join(' ');
    } else {
      return '';
    }
  }
}
