import {BaseMetaModel} from '../base/base-meta-model.model';
import {ModelConfig} from './model-config.model';

export class MLModel extends BaseMetaModel {

  /**
   * Name of the ML model
   */
  public name: string;

  /**
   * Description of the ML model
   */
  public description: string;

  /**
   * Configuration to train the ML model
   */
  public config: ModelConfig;

  /**
   * Names of the individual models generated for the script
   */
  public models: string[];

  /**
   * Keeps the status of the training activity. Could be started, completed, error
   */
  public trainingStatus: string;

  constructor(data?: any) {
    super(data);

    this.name = data.name;
    this.description = data.description;
    this.models = data.models;
    this.trainingStatus = data.trainingStatus;

    if (data.config) {
      this.config = new ModelConfig(data.config);
    }
  }

  /**
   * Returns a metadata for the fields so that the fields can correspond to list columns
   */
  public static getAllColumnMetadata() {
    const metadata: any = [ // column information to display specific information regarding ML models
      {
        label: 'Model name',
        field: 'name',
        columnWidth: 2
      },
      {
        label: 'Model script',
        field: 'script',
        columnWidth: 2
      },
      {
        label: 'Description',
        field: 'description',
        columnWidth: 2
      },
      {
        label: 'Related Domain',
        field: 'entityType',
        columnWidth: 2
      },
      {
        label: 'Creation Time',
        field: 'creationTime',
        columnWidth: 2,
        sortable: true
      },
      {
        label: 'Status',
        field: 'status',
        columnWidth: 1
      }
    ];
    return metadata;
  }

  /**
   * Returns a metadata for the fields so that the fields can correspond to list columns
   */
  public static getSummaryColumnMetadata() {
    const metadata: any = [ // column information to display specific information regarding ML models
      {
        label: 'Model name',
        field: 'name',
        columnWidth: 3
      },
      {
        label: 'Model script',
        field: 'script',
        columnWidth: 2
      },
      {
        label: 'Description',
        field: 'description',
        columnWidth: 2
      },
      {
        label: 'Related Domain',
        field: 'entityType',
        columnWidth: 2
      },
      {
        label: 'Status',
        field: 'status',
        columnWidth: 2
      }
    ];
    return metadata;
  }
}
