import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';
import {NGSIQueryTerm} from '../../models/ngsi/ngsi-query-term.model';
import {NGSIQueryTermAssoc} from '../../models/ngsi/ngsi-query-term-assoc.model';
import {NGSIQueryExpression} from '../../models/ngsi/ngsi-query-expression.model';

@Component({
  selector: 'query-expression',
  styleUrls: ['query-expression.component.scss'],
  templateUrl: './query-expression.component.html',
})
export class QueryExpressionComponent extends BaseComponent implements OnInit {
  @Input() queryExpression: NGSIQueryExpression;
  @Input() operatorDisabled = false;
  @Input() disabled = false;

  simpleExpression: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setExpressionType();
  }

  public onExpressionEditClicked(): void {
    this.eventService.broadcastFilterEditClickedEvent(this.queryExpression);
  }

  public onExpressionDeleteClicked(): void {
    this.eventService.broadcastFilterDeletedEvent(this.queryExpression);
  }

  onAssociationOperatorChanged(operator: string): void {
    this.getExpressionAsQueryTermAssoc().op = operator;
  }

  getExpressionAsQueryTerm(): NGSIQueryTerm {
    return this.queryExpression as NGSIQueryTerm;
  }

  getExpressionAsQueryTermAssoc(): NGSIQueryTermAssoc {
    return this.queryExpression as NGSIQueryTermAssoc;
  }

  private setExpressionType(): void {
    this.simpleExpression = this.queryExpression instanceof NGSIQueryTerm;
  }
}

