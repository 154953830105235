import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

/**
 * Displays the available icons and allow users to select one
 * */
@Component({
  selector: 'uruk-icon-selection',
  templateUrl: './icon-selection.component.html',
  styleUrls: ['./icon-selection.component.scss']
})
export class IconSelectionComponent implements OnInit {
  @Input() selectedIcon: string = null;
  @Input() disabled = false;

  @Output() onIconSelected: EventEmitter<string> = new EventEmitter<string>();

  // available icons for the selection
  iconList = [
    'address-book', 'address-card', 'ambulance', 'anchor', 'archive', 'asterisk', 'battery-full', 'bell', 'bicycle',
    'binoculars', 'bolt', 'book', 'briefcase', 'building', 'burn', 'bus', 'calculator', 'calendar-alt', 'calendar-check',
    'camera', 'car', 'car-battery', 'car-crash', 'car-side', 'charging-station', 'check-circle', 'clinic-medical',
    'cloud', 'cloud-sun', 'cloud-moon', 'cloud-showers-heavy', 'cloud-rain', 'cloud-moon-rain',
    'cloud-sun-rain', 'code-branch', 'cog', 'cogs', 'coins', 'compass', 'database', 'desktop', 'directions',
    'exclamation-circle', 'eye', 'faucet', 'fire', 'fire-alt', 'fire-extinguisher', 'first-aid', 'gas-pump', 'gavel',
    'globe-europe', 'hand-holding-medical', 'hand-holding-water', 'helicopter', 'home', 'hospital', 'info-circle', 'key',
    'landmark', 'laptop-medical', 'layer-group', 'lightbulb', 'list', 'magnet', 'map', 'map-marked', 'map-marker-alt',
    'map-pin', 'medkit', 'minus-circle', 'mobile-alt', 'moon', 'motorcycle', 'parking', 'phone', 'plane', 'plug',
    'power-off', 'radiation-alt', 'road', 'satellite-dish', 'shield-alt', 'shuttle-van', 'snowflake', 'subway', 'sun',
    'tachometer-alt', 'taxi', 'thermometer-half', 'tint', 'tools', 'tractor', 'traffic-light', 'train', 'truck',
    'umbrella', 'wind', 'wrench'
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  selectIcon(icon: string) {
    this.selectedIcon = icon;
    this.onIconSelected.emit(this.selectedIcon);
  }
}
