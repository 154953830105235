/**
 * An information model containing the information to be reported to be sent to the backend
 */
import {PanelContextData} from './panel-context-data.model';

export class ReportContextData {
  /**
   * Identifier of the page in which the reporting is initiated
   * */
  pageId: string;

  /**
   * Name of the page in which the reporting is initiated
   */
  pageName: string;

  /**
   * Panel contexts containing the actual data to be reported and other information about the panel
   */
  panels: PanelContextData[];
}
