import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {switchMap, takeUntil} from 'rxjs/operators';
import {KPI} from '../../../shared/models/kpi.model';
import {Panel} from '../../../shared/models/visualization/panel.model';
import {NGSIContext} from '../../../shared/models/generic/ngsi-context.model';
import {ChartSettings} from '../../../shared/models/visualization/chart-settings.model';
import {ChartType, chartTypes} from '../../../shared/enums/chart-type.enum';
import {BaseFormComponent} from '../../../shared/components/form/base-form.component';
import {AxisSettings} from '../../../shared/models/visualization/axis-settings.model';
import {AxisDetails} from '../../../shared/models/visualization/axis-details.model';
import {DataDimension} from '../../../shared/models/visualization/data-dimension.model';
import {QueryType, QueryTypeUtil} from '../../../shared/enums/query-type';
import {Observable, of} from 'rxjs';
import {DataFormatUtil} from '../../../shared/utils/data-format-util';
import {Geometry, NGSIGeoRelation} from '../../../shared/enums/ngsi-query.enum';
import {UrukTemporalQuery} from '../../../shared/models/query/uruk-temporal-query.model';
import {Region} from '../../../shared/models/region.model';
import {Polygon} from '../../../shared/models/geometry/polygon.model';
import {NGSIGeoQuery} from '../../../shared/models/ngsi/ngsi-geo-query.model';
import {MLModel} from '../../../shared/models/analytics/mlmodel.model';
import {AnalyticsSearchComponent} from '../../../shared/components/analytics/analytics-search.component';
import {MLModelConfig} from '../../../shared/models/analytics/mlmodel-config.model';

export enum AddPanelState {
  KPI_SELECTION,
  CHART_TYPE_SELECTION,
  ANALYTICS_SELECTION,
  TRAINED_MODEL_SELECTION
}

@Component({
  templateUrl: './add-panel-dialog.component.html',
  styleUrls: ['./add-panel-dialog.component.scss']
})
export class AddPanelDialogComponent extends BaseFormComponent implements OnInit {

  @ViewChild('analyticsSearch') analyticsSearchComponent: AnalyticsSearchComponent; // Reference to the chart component
  @Input() panelLocation: string; // placement of the panel within the page
  @Input() defaultChartType: ChartType; // the default chart type offered to the user
  @Input() panelInEditMode: Panel = null; // keeps the panel to be edited
  @Input() step: AddPanelState; // reference to the current step (kpi selection, chart type selection, or analytics selection)

  // reference to internal sources and Angular API
  AddPanelState = AddPanelState;
  ChartType = ChartType;

  // TODO: it can be configurable via environment variable
  precisionList: number[] = [0, 1, 2, 3, 4, 5];

  // selected objects by the user
  selectedKPI: KPI;
  selectedChartType: any;
  // title of the panel
  title: string = null;
  // temporal query for panels including a kpi allowing temporal query override
  temporalQuery: UrukTemporalQuery = new UrukTemporalQuery();
  // whether the region associated with the realm will be used in the panel
  useRealmRegion = false;

  // selected model data by the user
  selectedModel: MLModel;
  selectedTrainedModel: string;

  // settings for the dimension axes of the panel
  dimensionSettings: AxisSettings[] = [];
  // settings for the series axes of the panel
  seriesSettings: AxisSettings[] = [];

  // selected dimension parameters by the user
  selectedDimensionParameters: string[];
  selectedSeriesDetails: any[];

  // parameters that can be used to fetch labels for the dimensions e.g. id or name for a groupBy bucket.
  // In case of aggregation KPIs, this variable contains an array of strings for each dimension.
  // For non-aggregation KPIs, it contains entity elements that can be used as dimension.
  dimensionParameters: string[][];
  // paths of series to shown to the user via a select box
  seriesParameters: string[];

  // keeps details for the series including data format, whether the data format is numeric, path, label and unit.
  // In other words, details from KPI series details (DataSeries) and some additional inferred information e.g. isNumeric information
  seriesDetails: any[];

  // labels for the dimension and series axes
  chartDimensionAxesLabels: AxisDetails[];
  chartSeriesAxesLabels: AxisDetails[];

  // additional settings for the selected chart type
  additionalSettingsModel: any = {};
  additionalSettings: any = {};

  // Available chart types.
  suitableChartTypes = [];

  sliderOptions = {
    floor: 0,
    ceil: 100,
    step: 10
  };

  // flag indicating whether errors are displayed for input fields have invalid values
  displayErrors: boolean;

  constructor(protected injector: Injector,
              private dialogRef: NbDialogRef<AddPanelDialogComponent>) {
    super(injector);
  }

  ngOnInit() {
    // handle the edit mode
    if (this.panelInEditMode) {
      this.handleEditMode();
    }
  }

  /**
   * Fired when the user decides to add KPI to panel
   */
  onAddKpiClicked() {
    this.step = AddPanelState.KPI_SELECTION;
  }

  /**
   * Fired when the user decides to add prediction to panel
   */
  onAddPredictionClicked() {
    this.step = AddPanelState.ANALYTICS_SELECTION;
  }

  /**
   * Handles the edit mode for dialog.
   * */
  private handleEditMode() {
    // retrieve the selected kpi "if" the panel contains a KPI, not analytics
    if (this.panelInEditMode.ngsiContext.kpiId) {
      this.kpiService.getKPI(this.panelInEditMode.ngsiContext.kpiId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(kpi => {
          this.onKPISelected(kpi);
          // set panel title
          this.title = this.panelInEditMode.title;
          // set selected chart type
          this.selectChartType(this.panelInEditMode.chartSettings.type);
          // set additional settings
          this.additionalSettings = this.panelInEditMode.chartSettings.settings;
          // set series and dimension settings
          this.seriesSettings = this.panelInEditMode.chartSettings.seriesSettings;
          this.dimensionSettings = this.panelInEditMode.chartSettings.dimensionSettings;
          // populate selected values for dimensions and series
          this.populateSelectedParameters();
          // set ngsi context's temporal query
          if (this.panelInEditMode.ngsiContext.temporalQuery) {
            this.temporalQuery = this.panelInEditMode.ngsiContext.temporalQuery;
          }
          // set geo query check
          this.useRealmRegion = !!this.panelInEditMode.ngsiContext.geoQuery;
        });
    }
  }


  /**
   * Validates user actions to check if anything is missing for panel operations
   */
  validateForm() {
    switch (this.step) {
      case AddPanelState.KPI_SELECTION:
        if (!this.selectedKPI) {
          this.displayErrors = true;
          return false;
        }
        return true;
      case AddPanelState.CHART_TYPE_SELECTION:
        if (!this.selectedChartType || !this.title) {
          this.displayErrors = true;
          return false;
        }
        // check label of each series and dimensions
        if (this.seriesSettings.some(setting => !setting.label) || this.dimensionSettings?.some(setting => !setting.label)) {
          this.displayErrors = true;
          return false;
        }
        return true;
      default:
        return false;
    }
  }

  /**
   * Fired when user selectes a KPI
   * @param kpi
   */
  handleKpiSelected(kpi: KPI): void {
    this.onKPISelected(kpi);
  }

  /**
   * Filters the kpis which can be used in a panel.
   * @param kpis the kpi list
   * @returns kpis which are suitable to select for a panel
   */
  filterKpis(kpis:KPI[]):KPI[] {
    return kpis.filter(kpi =>
      kpi.queryType !== QueryType.RETRIEVE_ENTITY_TEMPORAL &&
      kpi.queryType !== QueryType.QUERY_ENTITIES_TEMPORAL &&
      kpi.queryType !== QueryType.CROSS_AGGREGATION &&
      kpi.queryType !== QueryType.CROSS_TEMPORAL_AGGREGATION &&
      kpi.dimensions.length < 2)
  }


  /**
   * Called when user selects a KPI from the list. KPI selection is the first step to initialize series and dimensions. Once the KPI is available, details are initialized for all
   * series and dimensions.
   * @param kpi
   */
  onKPISelected(kpi: KPI): void {
    this.selectedKPI = kpi;
    this.selectedChartType = undefined;
    this.selectedDimensionParameters = [];
    this.selectedSeriesDetails = [];
    this.dimensionParameters = [];
    this.seriesParameters = [];
    this.seriesDetails = [];
    this.chartDimensionAxesLabels = [];
    this.chartSeriesAxesLabels = [];

    this.setSuitableChartTypes();

    // get details related to series and dimensions and initialize
    this.seriesDetails = this.getSeriesDetails();
    this.seriesParameters = this.seriesDetails.map(details => details.path);
    this.dimensionParameters = this.getDimensionParameters();
    // set the selected chart type if the default value is provided for create mode
    if (!this.panelInEditMode && this.defaultChartType) {
      this.selectChartType(this.defaultChartType);
    }
  }

  /**
   * Selects a chart type to be displayed by the panel
   * @param chart
   */
  selectChartType(chart) {
    this.selectedChartType = chart;
    this.additionalSettings = {}; // clear additional chart settings on selecting a different chart
    this.initializeChartSettings();
  }

  /**
   * A helper method to determine whether given chart type is selected by the user or not
   * @param chart
   */
  isChartSelected(chart) {
    return this.selectedChartType === chart;
  }

  onTemporalQueryChanged(newQuery: UrukTemporalQuery): void {
    this.temporalQuery = newQuery;
  }

  /**
   * Fired when the user selects an ML model
   * @param model
   */
  onModelSelected(model: MLModel) {
    this.selectedModel = model;
    this.step = AddPanelState.TRAINED_MODEL_SELECTION;
  }

  /**
   * Fired when the user selects trained model file from the selected ML model
   * @param trainedModel
   */
  onTrainedModelSelected(trainedModel: string) {
    this.selectedTrainedModel = trainedModel;
  }

  /**
   * Navigates user to the chart type selection step from the kpi selection
   */
  nextStep() {
    if (this.step === AddPanelState.KPI_SELECTION && this.validateForm()) {
      this.step = AddPanelState.CHART_TYPE_SELECTION;
      // initialize panel title
      this.title = this.selectedKPI.name;
    }
  }

  /**
   * Navigates user back to the  kpi selection step from the chart type selection
   */
  previousStep() {
    if (this.step === AddPanelState.CHART_TYPE_SELECTION) {
      this.step = AddPanelState.KPI_SELECTION;
    } else if (this.step === AddPanelState.TRAINED_MODEL_SELECTION) {
      this.step = AddPanelState.ANALYTICS_SELECTION;
      this.analyticsSearchComponent.previousStep();
    }
  }

  /**
   * Creates a new panel with selected KPI and closes this dialog with it
   */
  savePanelWithKPI() {
    if (this.validateForm()) {

      let subs: Observable<Region> = of(null);
      if (this.useRealmRegion) {
        subs = this.realmService.getRealm(this.authService.getRealmId()).pipe(
          switchMap(realm => {
            return this.regionService.getRegion(realm.regionId);
          })
        );
      }

      subs.subscribe(region => {
        let panel: Panel;
        if (this.panelInEditMode) {
          // for the edit mode, set the selected settings
          panel = this.panelInEditMode;
          panel.title = this.title;
          panel.chartSettings.type = this.selectedChartType;
          panel.chartSettings.settings = this.additionalSettings;

        } else {
          // create a new panel
          panel = new Panel();
          panel.title = this.title;
          panel.panelLocation = this.panelLocation;
          panel.ngsiContext = new NGSIContext({
            kpiId: this.selectedKPI.id
          });

          panel.chartSettings = new ChartSettings({
            type: this.selectedChartType,
            dimensionSettings: this.dimensionSettings,
            seriesSettings: this.seriesSettings,
            settings: this.additionalSettings
          });
        }

        // check type to decide whether the temporal filter is set
        if (this.temporalQuery.type) {
          panel.ngsiContext.temporalQuery = this.temporalQuery;
        } else {

        }

        // if a region is selected for the target geoquery of the panel, map its coordinates to the geographic query of the NGSI context
        if (region) {
          const geoQuery: NGSIGeoQuery = new NGSIGeoQuery({
            georel: NGSIGeoRelation.INTERSECTS,
            geometry: Geometry.POLYGON
          });
          geoQuery.coordinates = (region.coordinates as Polygon).coordinates;
          panel.ngsiContext.geoQuery = geoQuery;

          // reset the geoquery of the panel as the region is not selected for the target area
        } else {
          panel.ngsiContext.geoQuery = null;
        }

        // close this dialog with this panel
        this.dialogRef.close(panel);
      });
    }
  }

  /**
   * Creates a new panel with selected ML models and closes this dialog with it
   */
  savePanelWithAnalytics() {
    let panel: Panel;

    if (this.panelInEditMode) {
      panel = this.panelInEditMode;
    } else {
      panel = new Panel();
      panel.panelLocation = this.panelLocation;
    }

    panel.title = this.selectedModel.name;
    panel.ngsiContext = new NGSIContext({
      mlModelConfig: new MLModelConfig({
        mlModelId: this.selectedModel.id,
        modelInstance: this.selectedTrainedModel
      })
    });

    // get model and KPI definition from the server, so that chart settings can be created for the panel
    const modelId = this.selectedModel.id;
    this.analyticsService.getModelConfig(modelId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(model => {

        // get kpi definition
        const kpiId = model.config.kpiIds[model.config.kpiIds.length - 1];
        this.kpiService.getKPI(kpiId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(kpi => {

            // handle chart settings
            panel.chartSettings = new ChartSettings({
              type: ChartType.Line, // default is line chart
              settings: {
                lineColors: [
                  '#5F86F9',
                  '#3C62D2',
                  '#1D398E'
                ]
              }
            });

            panel.chartSettings.dimensionSettings = kpi.dimensions.map((dimension, index) => {
              const keys = Object.keys(dimension.keys);
              const firstKey = keys.length > 0 ? keys[0] : '';
              return new AxisSettings({
                index: index,
                path: firstKey,
                label: firstKey,
                targetType: dimension.keys[firstKey].dataType,
              });
            });

            panel.chartSettings.seriesSettings = kpi.series.map((serie, index) => {
              return new AxisSettings({
                index: index,
                path: serie.path,
                label: serie.label,
                thousandSeparator: true,
                targetType: serie.dataType,
              });
            });

            // close this dialog with this panel
            this.dialogRef.close(panel);

          }, error => {
            console.error(error);
            this.dialogRef.close(panel);
          });
      }, error => {
        console.error(error);
        this.dialogRef.close(panel);
      });
  }

  /**
   * Handler for the changing values of the selected dimension parameter. It updates the corresponding
   * @param index
   * @param value
   */
  public handleDimensionParameterChange(index: number, value: string[]): void {
    this.dimensionSettings[index].path = value[0];
  }

  /**
   * Handler for the changing values of the selected series parameter. It updates the corresponding series setting with the new value.
   * @param index
   * @param value
   */
  public handleSeriesParameterChange(index: number, value: string[]): void {
    // find the series details with the given value as its path
    const series: any = this.seriesDetails.find(details => details.path === value[0]);
    this.seriesSettings[index] = this.convertSeriesDetailsToAxisSettings(series);
  }

  /***************** Chart Specific Implementation  ********************/

  onIndicatorIconSelected(icon: string) {
    this.additionalSettings.icon = icon;
  }

  /**
   * Gets parameters that can be used as dimension values. Dimensions of the selected KPI are used for the aggregation queries. For non-aggregation queries, entity elements
   * can be used as dimension values.
   * @private
   */
  private getDimensionParameters(): string[][] {
    if (!QueryTypeUtil.isAggregationQuery(this.selectedKPI.queryType)) {
      /*let entityId: string;
      // extract entity id either from the if filter or type filter
      if (QueryTypeUtil.isRetrieveQuery(this.selectedKPI.queryType)) {
        entityId = this.selectedKPI.query.filter.id[0].split(':')[2];
      } else {
        entityId = this.selectedKPI.query.filter.type[0];
      }

      // use entity elements as potential dimensions. For temporal queries, only static elements are consider as dimensions.
      let parameterCategory: string = null;
      if (QueryTypeUtil.isTemporalQuery(this.selectedKPI.queryType)) {
        parameterCategory = 'static';
      }
      return this.structureDefinitionService.getEntityElements(entityId, parameterCategory).pipe(
        map(elements => {
          // TODO check available attributes in the KPI
          return [elements.map(element => element.path)];
        })
      );*/
      // for non-aggregation queries just offer the id as dimension for now
      return [['id']];

    } else {
      return this.selectedKPI.dimensions.map(dimension => {
        return Object.keys(dimension.keys);
      });
    }
  }

  /**
   * Gets details including dataFormat, isNumeric, path and label for series. For aggregation queries, KPI series are used as basis. For non-aggregation queries, corresponding
   * entity type is used as basis such that entity elements are treated as series.
   * @private
   */
  private getSeriesDetails(): any[] {
    // for aggregation queries, get the series details from the KPI
    this.seriesDetails = [];
    const details: any[] = this.selectedKPI.series
      .filter(series => DataFormatUtil.isNumeric(series.format, series.dataType))
      .map((series, index) => {
        const seriesDetails: any = {};
        seriesDetails.dataFormat = series.format;
        seriesDetails.isNumeric = true;
        seriesDetails.path = series.path;
        seriesDetails.label = series.label;
        seriesDetails.index = index;
        // the following two fields in the details do not seem to be used, adding them just for completeness
        seriesDetails.dataType = series.dataType;
        seriesDetails.unit = series.unit;
        return seriesDetails;
      });
    return details;
  }

  /**
   * Initializes chart settings according to the chart-specific series and dimensions. For "create" mode, it creates initial settings by using first possible values for
   * labels and so on. For "edit mode, it simply uses the existing chart settings. Finally, it also populates variables that are used in the form components with the
   * values set for the settings.
   */
  initializeChartSettings(): void {
    this.chartSeriesAxesLabels = chartTypes[this.selectedChartType].series?.map(series => series.label);
    this.chartDimensionAxesLabels = chartTypes[this.selectedChartType].dimensions?.map(series => series.label);

    if (!this.panelInEditMode) {
      // assuming that the selected chart type is appropriate to visualize the results of the selected KPI
      this.dimensionSettings = this.chartDimensionAxesLabels?.map((axis, index) => {
        const kpiDimension: DataDimension = this.selectedKPI.dimensions[index];
        const dimensionKey: string = kpiDimension.keys.hasOwnProperty('name') ? 'name' : Object.keys(kpiDimension.keys)[0];
        return new AxisSettings({
          index: index,
          label: kpiDimension.keys[dimensionKey].label,
          path: dimensionKey,
          targetType: kpiDimension.keys[dimensionKey].dataType
        });
      });

      this.seriesSettings = this.chartSeriesAxesLabels?.map((axis, index) => {
        const axisSettings: AxisSettings = new AxisSettings({
          index: index,
          path: this.seriesDetails[index].path,
          label: this.seriesDetails[index].label,
          targetType: this.seriesDetails[index].dataFormat,
          thousandSeparator: false,
          precision: 2
        });
        return axisSettings;
      });

    } else {
      this.dimensionSettings = this.panelInEditMode.chartSettings.dimensionSettings;
      this.seriesSettings = this.panelInEditMode.chartSettings.seriesSettings;
    }

    this.populateSelectedParameters();
  }

  /**
   * Initializes the selected dimension and series details to be used in the form components based on the available chart settings.
   * @private
   */
  private populateSelectedParameters(): void {
    // set variables that are used in the template
    this.selectedDimensionParameters = this.dimensionSettings?.map(setting => setting.path);
    this.selectedSeriesDetails = this.seriesSettings?.map(setting => {
      const seriesDetails: any = {};
      seriesDetails.dataFormat = setting.chartDataFormat;
      seriesDetails.isNumeric = DataFormatUtil.isNumeric(seriesDetails.dataFormat);
      seriesDetails.path = setting.path;
      seriesDetails.label = setting.label;
      return seriesDetails;
    });
  }

  /**
   * Sets the suitable charts for the selected KPI. It simply seeks equivalence between dimension and series counts of the kpi and chart types
   * @private
   */
  private setSuitableChartTypes(): void {
    // for 'retrieve' kpis, only metric charts can be used.
    if (this.selectedKPI.queryType === QueryType.RETRIEVE_ENTITY) {
      this.suitableChartTypes = [chartTypes.metric];
      return;
    }
    this.suitableChartTypes = Object.values(chartTypes).filter((chartType: any) => {
      const chartDimensionCount = chartType.dimensions ? chartType.dimensions.length : 0;
      const chartSeriesCount = chartType.series ? chartType.series.length : 0;
      const kpiDimensionCount = this.selectedKPI.dimensions ? this.selectedKPI.dimensions.length : 0;
      const kpiSeriesCount = this.selectedKPI.series ? this.selectedKPI.series.length : 0;
      return !(
        // list of conditions to decide a chart type is not suitable
        (kpiDimensionCount !== chartDimensionCount) ||
        (kpiSeriesCount < chartSeriesCount) ||
        (chartType.disabledInPanels !== undefined && chartType.disabledInPanels)
      );
    });
  }

  private convertSeriesDetailsToAxisSettings(seriesDetails: any): AxisSettings {
    return new AxisSettings({
      index: seriesDetails.index,
      path: seriesDetails.path,
      label: seriesDetails.label,
      precision: seriesDetails.precision,
      thousandSeparator: seriesDetails.thousandSeparator,
      targetType: seriesDetails.dataFormat
    });
  }

  /*
   * template getters
   */

  /**
   * Gets parameters that can be used as dimension values e.g. either id or name for a dimension generated by groupBy
   * @param index
   */
  public getDimensionOptions(index: number): string[] {
    if (QueryTypeUtil.isAggregationQuery(this.selectedKPI.queryType)) {
      return this.dimensionParameters[index];
    } else {
      return this.dimensionParameters[0];
    }
  }

  /*
  initializeChartSettings(chartSettings?: any) {
    if (chartSettings) {
      if (this.selectedChartType.id === ChartType.Pie) {
        this.additionalSettingsModel = {
          pie: {
            radius: chartSettings.radius
          },
          doughnut: {
            innerRadius: chartSettings.radius && chartSettings.radius.length > 1 ? chartSettings.radius[0] : undefined,
            outerRadius: chartSettings.radius && chartSettings.radius.length > 1 ? chartSettings.radius[1] : undefined
          }
        };
      }
    } else {
      this.additionalSettingsModel = {
        pie: {
          radius: 80
        },
        doughnut: {
          innerRadius: 40,
          outerRadius: 80
        }
      };
    }
  }

  processAdditionalChartSettings() {
  }
  */
}
