import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * A component to allows users to pick a path for themselves
 */
@Component({
  selector: 'uruk-path-selection',
  templateUrl: './path-selection.component.html',
  styleUrls: ['./path-selection.component.scss']
})
export class PathSelectionComponent {
  @Input() leftIcon: string;
  @Input() rightIcon: string;

  @Input() leftText: string;
  @Input() rightText: string;

  @Output() onLeftSelected: EventEmitter<void> = new EventEmitter();
  @Output() onRightSelected: EventEmitter<void> = new EventEmitter();
}
