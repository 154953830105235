/**
 * Visualization settings for charts
 */
import {AxisSettings} from './axis-settings.model';
import {ChartType} from '../../enums/chart-type.enum';
import {Persistable} from '../persistanble';

export class ChartSettings implements Persistable {
  /**
   * Type of the chart
   */
  type: ChartType;

  /**
   * Settings for {@link DataDimension}s presented in the chart
   */
  dimensionSettings: AxisSettings[] = [];

  /**
   * Settings for {@link DataSeries} presented in the chart
   */
  seriesSettings: AxisSettings[] = [];

  /**
   * Generic visualization settings. Each chart type is free to handle their own settings
   */
  settings?: any;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.type = data.type;
    if (data.dimensionSettings) {
      this.dimensionSettings = data.dimensionSettings.map(settings => new AxisSettings(settings));
    }
    if (data.seriesSettings) {
      this.seriesSettings = data.seriesSettings.map(settings => new AxisSettings(settings));
    }
    // TODO think about how to map settings
    this.settings = data.settings;
  }

  createPersistableObject(): any {
    const {dimensionSettings, seriesSettings, ...rest} = this;
    if (this.dimensionSettings) {
      rest['dimensionSettings'] = this.dimensionSettings.map(dimensionSettings => dimensionSettings.createPersistableObject());
    }
    if (this.seriesSettings) {
      rest['seriesSettings'] = this.seriesSettings.map(seriesSettings => seriesSettings.createPersistableObject());
    }
    return rest;
  }
}
