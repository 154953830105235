import {PageGroup} from './page-group.model';
import {BaseMetaModel} from './base/base-meta-model.model';

/**
 * Data model representing user preferences
 */
export class UserPreference extends BaseMetaModel {
  /**
   *  Identifier of the user as a reference
   */
  userId: string;

  /**
   * Identifier of the page to be displayed for the user by default
   */
  defaultPageId: string;

  /**
   * Identifier of pages that the user owns
   */
  pageIds: string[];

  /**
   * PageGroups that the user owns
   */
  pageGroups: PageGroup[];

  constructor(data?) {
    super(data);

    if (!data) {
      return;
    }

    this.userId = data.userId;
    this.defaultPageId = data.defaultPageId;
    this.pageIds = data.pageIds;

    if (data.pageGroups) {
      this.pageGroups = data.pageGroups.map(group => new PageGroup(group));
    }
  }

  /**
   * Retrieves the all page ids included in the user preference.
   * @return the all page ids included in the user preference.
   * */
  getPageIds(): string[] {
    let pageIds = this.pageIds;
    this.pageGroups.forEach(pageGroup => pageIds = pageIds.concat(pageGroup.pageIds));
    return pageIds;
  }
}
