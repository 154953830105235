export class ColorUtil {

  /* GRADIENT COLORS */
  static softOrangeGradient = ['#fd746c', '#ff9068'];
  static orangeGradient = ['#D7610C', '#B28718'];
  static pinkGradient = ['#B953C1', '#FAC7FB'];
  static purpleGradient = ['#D34FE9', '#9C1B80'];
  static violetGradient = ['#6a3093', '#a044ff'];
  static softRedGradient = ['#B24592', '#F15F79'];
  static greenGradient = ['#0D9B78', '#5DF6B6'];
  static greenBlueGradient = ['#c2e59c', '#64b3f4'];
  static softBlueGradient = ['#83a4d4', '#b6fbff'];
  static lightBlueGradient = ['#457fca', '#5691c8'];
  static nightBlueGradient = ['#5F86F9', '#3C62D2', '#1D398E'];
  static woodGradient = ['#eacda3', '#d6ae7b'];
  static grayGradient = ['#bdc3c7', '#2c3e50'];
  static darkGradient = ['#485563', '#29323c'];

  // palettes are from https://learnui.design/tools/data-color-picker.html
  static gaugeChartPalette = ['#67e0e3', '#37a2da', '#fd666d'];
  static colorPalette = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600']; // palette
  static colorPalette2 = ['#002954', '#233c68', '#3d517c', '#556791', '#6e7ea6', '#8795bb', '#a0add2', '#bac6e8', '#d4dfff']; // single hue
}
