import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UrukMap} from '../../models/map/uruk-map.interface';
import {Layer} from '../../models/layer.model';
import {NGSIGeoQuery} from '../../models/ngsi/ngsi-geo-query.model';
import {Coordinate} from '../../models/generic/coordinate.model';
import {environment} from '../../../../environments/environment';
import {LayerController} from '../../../core/controllers/layer.controller';
import {MapSettings} from '../../models/visualization/map-settings.model';

@Component({
  selector: 'maprays-map',
  templateUrl: './maprays-map.component.html'
})
export class MapraysMapComponent implements OnInit, UrukMap {
  @Input() layers: LayerController[];
  @Input() mapSettings: MapSettings;
  @Output() onMapBoundariesChanged: EventEmitter<NGSIGeoQuery>;
  @Output() onMapClicked: EventEmitter<Coordinate>;
  @Output() onShapeDrawn: EventEmitter<NGSIGeoQuery>;

  /**
   * Reference to the map instance
   */
  map: any;

  /**
   * Boundaries for the map
   */
  mapBounds: any;

  /**
   * Reference to the current drawn object
   */
  currentDrawnObject: any;

  /**
   * Layer keeping the drawn shapes
   */
  drawnShapesLayer;

  /**
   * Layer keeping the drawn markers
   */
  markersLayer;

  ngOnInit() {
    this.map = new maprays.Map('map', {
      drawingTools: true
    });
    this.map.getPoint((coordinate) => { console.log(coordinate); });
    this.map.on('map.movestart', () => { console.log('map.movestart'); });
    this.map.on('map.moveend', () => { console.log('map.moveend'); });


    const options = {
      url: environment.map.maprays.tileUrl,
      // maxLevel: this.mapSettings?.maxZoomLevel ? this.mapSettings.maxZoomLevel : environment.map.maprays.maxLevel
      maxLevel: environment.map.maprays.maxLevel
    };
    const tileLayer = new maprays.TileLayer(options);
    tileLayer.addTo(this.map);
    this.map.setExtent(
      new maprays.Coordinate(this.mapBounds.southwest.latitude, this.mapBounds.southwest.longitude),
      new maprays.Coordinate(this.mapBounds.northeast.latitude, this.mapBounds.northeast.longitude)
    );

    // add default layers to the map
    this.drawnShapesLayer = new maprays.FeatureLayer();
    this.drawnShapesLayer.addTo(this.map);
    this.markersLayer = new maprays.FeatureLayer();
    this.markersLayer.addTo(this.map);
  }

  onDrawCircleInitiated(): void {
    this.map.drawController.createCircle({
      onStart: () => { console.log(this.getShapeCoordinates(null)); },
      onComplete: (feature) => {
        console.log('F', feature);
      },
    });
  }

  onDrawPolygonInitiated(): void {
    this.map.drawController.createPolygon({
      onComplete: (feature) => {
        console.log('F', feature);
      },
    });
  }

  onDrawRectangleInitiated(): void {
    this.map.drawController.createRectangle({
      onComplete: (feature) => {
        console.log('F', feature);
      },
    });
  }

  onDrawnShapeRemoved(): void {
  }

  onZoomIn(): void {
    this.map.zoomController.zoomIn();
  }

  onZoomOut(): void {
    this.map.zoomController.zoomOut();
  }

  onMapAreaChanged(): void {
  }

  panAndZoom(coordinate: Coordinate, zoomLevel: number): void {
  }

  pan(coordinate: Coordinate): void {
  }

  addLayers(layers: Layer[]): void {
  }

  fitBounds(bounds: [number, number, number, number]): void {
    throw new Error('Method not implemented.');
  }

  setMaxBounds(bounds?: [number, number, number, number]): void {
    throw new Error('Method not implemented.');
  }

  addIconMarker(coordinate: Coordinate, options: any): void {
    // TODO to be tested
    const mapraysCoordinate = new maprays.Coordinate(coordinate.latitude, coordinate.longitude);
    const url = 'assets/map-markers/default-marker.png';
    const icon = new maprays.Icon({coordinate: mapraysCoordinate, url: url});
    this.markersLayer.addFeature(icon);
  }

  addGeoQueryAsShape(shape: NGSIGeoQuery): void {
  }

  addGeoQueryAsBackgroundShape(ngsiGeoQuery: NGSIGeoQuery): void {
    throw new Error('Method not implemented.');
  }

  clearBackgroundShapes(): void {
    throw new Error('Method not implemented.');
  }

  drawnShapeExists(): boolean {
    throw new Error('Method not implemented.');
  }

  clearMarkers(): void {
    this.markersLayer.clearLayer();
  }

  onShapeRemoved(): EventEmitter<NGSIGeoQuery> {
    return undefined;
  }

  onZoomChanged(): EventEmitter<NGSIGeoQuery> {
    return undefined;
  }

  getBounds(): Coordinate[] {
    const bounds: any = this.map.getExtent().viewRectangle;
    const north = bounds.north;
    const south = bounds.south;
    const west = bounds.west;
    const east = bounds.east;
    // First and last coordinates must be the same to construct a loop for MongoDB queries
    return [
      new Coordinate({longitude: west, latitude: north}),
      new Coordinate({longitude: east, latitude: north}),
      new Coordinate({longitude: east, latitude: south}),
      new Coordinate({longitude: west, latitude: south}),
      new Coordinate({longitude: west, latitude: north})
    ];
  }

  getShapeCoordinates(shape: any): Coordinate[] {
    if (shape instanceof maprays.Circle) {
      const center = shape.getCenter();
      return [new Coordinate({latitude: center.getLatitude(), longitude: center.getLongitude()})];
    } else if (shape instanceof maprays.Polygon) {
      // TODO
    }
    return [];
  }

  public getGeoQuery(): NGSIGeoQuery {
    return null;
  }

  transformGeoQuery(query: NGSIGeoQuery): any {
    return null;
  }

  /**
   * Initializes the boundries of map. They are retrieved from map settings if available. Otherwise, environment settings
   * are used.
   * */
  private initializeMapBounds() {
    let southwestLatitude = environment.map.maprays.mapBounds.southwest.latitude;
    let southwestLongitude = environment.map.maprays.mapBounds.southwest.longitude;
    let northeastLatitude = environment.map.maprays.mapBounds.northeast.latitude;
    let northeastLongitude = environment.map.maprays.mapBounds.northeast.longitude;
    // if (this.mapSettings) {
    //   southwestLatitude = this.mapSettings.maprays.mapBounds.southwest.latitude;
    //   southwestLongitude = this.mapSettings.maprays.mapBounds.southwest.longitude;
    //   northeastLatitude = this.mapSettings.maprays.mapBounds.northeast.latitude;
    //   northeastLongitude = this.mapSettings.maprays.mapBounds.northeast.longitude;
    // }

    this.mapBounds = {
      southwest: {
        latitude: southwestLatitude,
        longitude: southwestLongitude
      },
      northeast: {
        latitude: northeastLatitude,
        longitude: northeastLongitude
      }
    };
  }
}
