/**
 * MLModel configuration to refer a specific model trained in the scope of an {@link MLModel}
 */
export class MLModelConfig {
  /**
   * Reference to the {@link MLModel}
   */
  mlModelId: string;

  /**
   * Reference to the trained model file
   */
  modelInstance: string;

  constructor(data: any) {
    if (!data) {
      return;
    }

    this.mlModelId = data.mlModelId;
    this.modelInstance = data.modelInstance;
  }
}
