<div>
    <nb-card class="dialog-card">
        <nb-card-body class="flex-centered">
            <div class="container" [innerHTML]="body"></div>
        </nb-card-body>
        <nb-card-footer class="d-flex">
            <button (click)="continue()" class="btn">{{'Continue'|translate}}</button>
            <button (click)="cancel()" class="btn left-margined">{{'Logout'|translate}}</button>
        </nb-card-footer>
    </nb-card>
</div>