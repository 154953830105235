import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Realm} from '../../../shared/models/realm.model';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {UserService} from '../data/user.service';
import {SearchQuery} from 'app/shared/models/query/search-query.model';
import {RestUtil} from 'app/shared/utils/rest-util';
import {RealmLogo} from 'app/shared/models/realm-logo.model';
import {Attribute} from '../../../shared/models/attribute.model';

/**
 * A service to handle tags
 */
@Injectable()
export class RealmService extends BaseHttpClientService {
  private readonly domainsPath = 'domains';
  private readonly attributesPath = 'attributes';

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'realms');
    // if the current user is not a platform manager, the endpoint should be adjusted accordingly i.e. <baseUrl>/admin
    const userService: UserService = injector.get(UserService);
    userService.onUserRolesChanged()
      .pipe(first(roles => roles !== null)) // take the first non-null user roles to check whether the user is platform
      // manager or not
      .subscribe(() => {
        if (!userService.isPlatformManager()) {
          this.endpoint = environment.server.adminApi;
        }
      });
  }

  /**
   * Retrieves all realms defined on the platform
   */
  getAllRealms(): Observable<Realm[]> {
    let url: string = this.endpoint;
    const parameters: string = RestUtil.createURLParameters(new SearchQuery());
    if (parameters) {
      url += '?' + parameters;
    }
    return this.httpClient.get<Realm[]>(url).pipe(map(response => response.map(item => new Realm(item))));
  }

  /**
   * Retrieves the details of a single realm
   * @param realmId Identifier of the page to be retrieved
   */
  getRealm(realmId: String): Observable<Realm> {
    const url = this.endpoint + '/' + realmId;
    return this.httpClient.get(url).pipe(map(response => new Realm(response)));
  }

  /**
   * Retrieves all attributes associated with a given realm based on its ID.
   *
   * @param realmId - The unique identifier of the realm from which the attributes are being fetched.
   * @returns Observable<Attribute[]> - An Observable that contains an array of attributes when the HTTP GET request is successful.
   */
   getRealmAttributes(realmId: String): Observable<Attribute[]> {
    const url = this.endpoint + '/' + realmId + '/attributes';
    return this.httpClient.get<Attribute[]>(url);
  }

  /**
   * Retrieves the logo of a realm
   * @param realmId Identifier of the realm
   */
  getRealmLogo(realmId: String): Observable<RealmLogo> {
    const url = `${this.endpoint}/${realmId}/logo`;
    return this.httpClient.get(url).pipe(map(response => new RealmLogo(response)));
  }

  /**
   * Creates a new realm on the platform
   * @param realm Realm to be created
   */
  createRealm(realm: Realm): Observable<Realm> {
    return this.httpClient.post(this.endpoint, realm).pipe(map(response => new Realm(response)));
  }

  /**
   * Updates the given realm
   * @param realm Realm to be updated
   */
  updateRealm(realm: Realm): Observable<Realm> {
    const patch: any = this.extractEditableFields(realm);
    const url = this.endpoint + '/' + realm.id;
    return this.httpClient.patch(url, patch).pipe(map(response => new Realm(response)));
  }

  /**
   * Enables the realm within Uruk platform.
   * @param realmId Id of the realm
   */
  enableRealm(realmId: string) {
    const url = `${this.endpoint}/${realmId}/status`;
    return this.httpClient.put(url, {});
  }

  /**
   * Disables the realm within Uruk platform.
   * @param realmId Id of the realm
   */
  disableRealm(realmId: string) {
    const url = `${this.endpoint}/${realmId}/status`;
    return this.httpClient.delete(url);
  }

  /**
   * Adds a domain for the given realm
   * @param realmId Id of the realm
   * @param domain Doamin to be added
   */
  addDomain(realmId: string, domain: string) {
    const url = this.endpoint + '/' + realmId + '/' + this.domainsPath + '/' + domain;
    return this.httpClient.put(url, {});
  }

  /**
   * Deletes the given domain from realm.
   * @param realmId Id of the realm
   * @param domain Domaing to be deleted
   * */
  deleteDomain(realmId: string, domain: string) {
    const url = this.endpoint + '/' + realmId + '/' + this.domainsPath + '/' + domain;
    return this.httpClient.delete(url);
  }

  /**
   * Adds an attribute for the given realm
   * @param realmId Id of the realm
   * @param attribute The attribute object to be added
   */
  addAttribute(realmId: string, attribute: Attribute) {
    // Construct the URL using both parentName and attributeName
    const url = `${this.endpoint}/${realmId}/${this.attributesPath}/${attribute.parentName}/${attribute.name}`;
    return this.httpClient.put(url, {});
  }

  /**
   * Deletes the given attribute from the realm.
   * @param realmId Id of the realm
   * @param attribute The attribute object to be deleted
   */
  deleteAttribute(realmId: string, attribute: Attribute) {
    // Construct the URL using both parentName and attributeName
    const url = `${this.endpoint}/${realmId}/${this.attributesPath}/${attribute.parentName}/${attribute.name}`;
    return this.httpClient.delete(url);
  }

  /**
   * Extracts and returns the editable fields of the given Realm as a json.
   *
   * @param realm
   * @return the json with editable realm fields
   * */
  private extractEditableFields(realm: Realm) {
    return {
      name: realm.name,
      regionId: realm.regionId,
      attributes: realm.attributes ? realm.attributes : {},
      logo: {
        content: realm.logo.content,
        fileName: realm.logo.fileName
      }
    };
  }
}
