<closeable-dialog class="add-layer-dialog">
  <!-- Dialog header -->
  <div dialog-card-header>
    <label [ngClass]="{'error-label': displayErrors}">
      {{ 'Entities to be Tracked' | translate }}
    </label>
  </div>
  <!-- Dialog body displaying a list of layers for the selection-->
  <div class="dialog-width" dialog-card-body>
    <div class="form-group">
      <br>
      <uruk-layer-selection (onLayerSelection)="setSelectedLayerIds($event)" [domains]="domains"
                            [layerIds]="layerIds"></uruk-layer-selection>
      <br>
    </div>
  </div>
  <!-- Dialog footer to save selection-->
  <div dialog-card-footer>
    <button (click)="save()" class="float-right" nbButton status="primary" type="submit">
      {{ 'Save' | translate }}
    </button>
  </div>
</closeable-dialog>
