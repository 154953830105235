import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {TimeUtil} from '../../../../../shared/utils/time-util';
import {Panel} from '../../../../../shared/models/visualization/panel.model';
import {VisualizationMode} from '../../../../../shared/enums/visualization-mode.enum';

@Component({
  selector: 'parking-dialog-template',
  templateUrl: './parking-dialog-template.component.html',
  styleUrls: ['./parking-dialog-template.component.scss']
})
export class ParkingDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {

  // Context variables
  title: string;
  entity: any;

  // keeps the active tab
  activeTab: string;

  // Panel location variables
  PANEL_LOCATION_LEFT = 'left';
  PANEL_LOCATION_RIGHT = 'right';

  leftPanel: Panel;
  rightPanel: Panel;

  // parking details
  name: string;
  type: string;
  totalSpotNumber: number;
  availableSpotNumber: number;
  occupancy: string;
  paymentMethods: string;
  address: any;
  workingHours: any;
  description: any;
  date: any;
  styleClass: string;
  statusLabel: string;

  // declarations
  VisualizationMode = VisualizationMode;
  dialogViewportMin: number;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dialogViewportMin = Math.min(this.layoutService.getApplicationSize()[0], this.layoutService.getApplicationSize()[1]) / 6;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected mapContextData(context: any): void {
    this.title = context.title;
    this.entity = context.entity ? context.entity.entity : context.result.entity;

    this.processParkingDetails();
  }

  mapPanels(): void {
    this.page.panels.forEach(panel => {
      panel.ngsiContext.idQuery = this.entity.id;
      switch (panel.panelLocation) {
        case this.PANEL_LOCATION_LEFT:
          this.leftPanel = panel;
          break;
        case this.PANEL_LOCATION_RIGHT:
          this.rightPanel = panel;
          break;
      }
    });
  }

  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  public processParkingDetails() {
    // get entity values
    this.name = this.getValue(this.entity, 'name');
    this.type = this.entity.type;
    this.totalSpotNumber = this.getValue(this.entity, 'totalSpotNumber');
    this.availableSpotNumber = this.getValue(this.entity, 'availableSpotNumber');
    this.address = super.getValue(this.entity, 'address');
    this.description = super.getValue(this.entity, 'description');
    this.workingHours = super.getValue(this.entity, 'permitActiveHours');

    // process the date
    const date = TimeUtil.parseISODatetime(super.getValue(this.entity, 'availableSpotNumber', 'modifiedAt'));
    this.date = TimeUtil.serializeCustomDatetime(date);

    // process the acceptable payment methods
    const acceptablePaymentMethods = this.getValue(this.entity, 'acceptedPaymentMethod');
    if (acceptablePaymentMethods) {
      this.translateService.get(acceptablePaymentMethods).subscribe(result => {
        this.paymentMethods = Object.values(result).join(',');
      });
    } else {
      this.paymentMethods = '';
    }

    // process occupancy
    this.occupancy = ((this.totalSpotNumber - this.availableSpotNumber) / this.totalSpotNumber * 100).toFixed(0);

    // process the status
    const status = this.getValue(this.entity, 'status');
    if (status === 'closed') {
      this.styleClass = 'onstreetparking-closed';
      this.statusLabel = 'Closed';
    } else if (this.availableSpotNumber === 0) {
      this.styleClass = 'onstreetparking-spots-not-available';
      this.statusLabel = 'Full';
    } else if (this.availableSpotNumber > 0) {
      this.styleClass = 'onstreetparking-spots-available';
      this.statusLabel = 'Vacancy';
    } else {
      this.styleClass = 'onstreetparking-data-not-available';
      this.statusLabel = 'No data';
    }
  }
}
