/**
 * An information entity that provides information about a particular entity type e.g. OffStreetParking
 */
export class Context {
  /**
   * Type of the entity
   */
  entityType: string;

  /**
   * Identifiers of entity instances of interest for this context
   */
  entityIds?: string[];

  /**
   * Id pattern for entities
   */
  entityIdPattern?: string;

  /**
   * Properties of interest of the entity type
   */
  properties?: string[];

  /**
   * Relationships of interest of the entity type
   */
  relationships?: string[];

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.entityType = data.entityType;
    this.entityIds = data.entityIds;
    this.entityIdPattern = data.entityIdPattern;
    this.properties = data.properties;
    this.relationships = data.relationships;
  }
}
