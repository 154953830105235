import {GeoProperty} from '../schema/geo-property.model';
import {v4 as uuidv4} from 'uuid';

export class Linestring {
  id: string;
  location: GeoProperty;
  color: string;
  weight: number;
  opacity: number;

  constructor(location, color, weight, opacity) {
    this.id = uuidv4();
    this.location = location;
    this.color = color;
    this.weight = weight;
    this.opacity = opacity;
  }

  toGeoJson() {
    const geoJson = this.location.toGeoJson();
    geoJson.properties = {
      id: this.id,
      color: this.color,
      weight: this.weight,
      opacity: this.opacity
    }
    return geoJson;
  }
}
