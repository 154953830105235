import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

/**
 * Navigation service keeps state for the navigation activity throughout the application
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private markerClick = new Subject<[string, any]>();

  /**
   * An observable for subscribing to the marker click events
   */
  public markerClickObservable$: Observable<[string, any]> = this.markerClick.asObservable();

  /**
   * Triggers an marker click event
   * @param dialogTemplate
   * @param dialogContext
   */
  public onMarkerClicked(dialogTemplate: string, dialogContext: any): void {
    this.markerClick.next([dialogTemplate, dialogContext]);
  }
}
