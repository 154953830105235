<div [ngSwitch]="QueryTypeUtil.isTemporalQuery(kpi.queryType)" class="entity-parameter-filters h-100">
  <!-- for non temporal queries any type of parameters can be used as filters -->
  <ng-container *ngSwitchCase="false">
    <ng-container *ngIf="showExpressions">
      <div *ngIf="!disabled" (click)="onAddFilterClicked()" class="btn add-button">{{'Filtre Ekle' | translate}}</div>
      <div *ngFor="let expression of queryExpressions; let i = index" class="expression-container">
        <div class="d-flex">
          <query-expression [queryExpression]="expression" [disabled]="disabled"></query-expression>
        </div>
        <!-- relation operator -->
        <div *ngIf="i < queryExpressions.length - 1" class="operator-row">
          <nb-select [(selected)]="rootLevelOperators[i]" [disabled]="disabled">
            <nb-option value="and">{{'Ve' | translate}}</nb-option>
            <nb-option value="or">{{'Veya' | translate}}</nb-option>
          </nb-select>
          <div *ngIf="!disabled" (click)="onGroupClicked(i)" class="btn btn-primary">{{'Grupla' | translate}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- for temporal queries, first let users to define queries on static attributes and on temporal attributes -->
  <ng-container *ngSwitchCase="true">
    <div class="d-flex filters h-100">
      <div>
        <div (click)="onAddFilterClicked()"
             class="button">{{'Statik Parametre Filtresi Ekle' | translate}}</div>
        <div *ngIf="showExpressions" class="expressions">
          <div *ngFor="let expression of queryExpressions; let i = index">
            <div class="d-flex">
              <query-expression [queryExpression]="expression"></query-expression>
            </div>
            <!-- relation operator -->
            <div class="relation-operator" *ngIf="i < queryExpressions.length - 1">
              <nb-select [(selected)]="rootLevelOperators[i]">
                <nb-option value="and">{{'Ve' | translate}}</nb-option>
                <nb-option value="or">{{'Veya' | translate}}</nb-option>
              </nb-select>
              <div (click)="onGroupClicked(i)" class="btn btn-primary">{{'Grupla' | translate}}</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div (click)="onAddFilterClicked(true)"
             class="button">{{'Zamansal Parametre Filtresi Ekle' | translate}}</div>
        <div *ngIf="showExpressions && temporalQueryExpression"  class="expressions">
          <query-expression [operatorDisabled]="true" [queryExpression]="temporalQueryExpression"></query-expression>
        </div>
      </div>
    </div>

  </ng-container>
</div>
