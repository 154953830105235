import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import { BaseDialogTemplateComponent } from '../base-dialog-template.component';
import {PaginationCallbackFunction} from '../../../../../shared/components/list/list.component';
import {PAGINATION_DEFAULT_PAGE_SIZE} from '../../../../../shared/components/list/pagination.component';
import {DefibrillatorTest} from '../../../../../shared/models/defibrillatorTest.model';
import {VisualizationMode} from '../../../../../shared/enums/visualization-mode.enum';
import {Panel} from '../../../../../shared/models/visualization/panel.model';
import {HealthCase} from '../../../../../shared/models/healthCase.model';
import {ContextService} from '../../../../../core/services/http/context.service';
import { DateSelectionDialogComponent } from 'app/modules/dialog/date-selection-dialog/date-selection-dialog.component';
import { EventService } from 'app/core/services/event.service';
import { UrukTemporalQuery } from 'app/shared/models/query/uruk-temporal-query.model';
import { DefibrillatorService } from 'app/core/services/defibrillator.service';
import { DefibrillatorProperty, DefibrillatorUpdatedData } from 'app/shared/models/defibrillatorUpdatedData.model';

@Component({
  selector: 'drone-template',
  templateUrl: './drone-template.component.html',
  styleUrls: ['./drone-template.component.scss']
})

export class DroneTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {
  @Input() cBrokerEndpoint: string;
  cbrokerService: ContextService;
  defibrillatorTests: DefibrillatorTest[] = [];
  paginatedDefibrillatorTests: DefibrillatorTest[] = [];
  paginatedHealthCases: HealthCase[] = [];
  healthCases: HealthCase[] = [];

  entity: any;

  positionEntity: any;

  subEntities: any;

  healthCasesEntities = [];

  Defibrillator: any;

  defibrillatorId: string;

  defibrillatorLocation: string;

  defibrillatorService: DefibrillatorService;

  AEDDeviceModel: string;

  brand: string;

  AEDDeviceLCD: number;

  AEDSerialNumber: string;

  batterySerialNumber: string;

  hardwareRevisionNumber: string;

  softwareRevisionNumber: string;

  primaryLanguagePack: string;

  secondaryLanguagePack: string;

  simOperatorName: string;

  simTelNo: string;

  aedDeviceAdultShockEnergySteps: string;

  aedDevicePediatricShockEnergySteps: string;

  aedDeviceEnergyRetentionTime: number;

  aedDevicePeriodicTestInterval: number;

  aedDeviceShockProtocol: number;

  aedDeviceSoundLevel: number;

  iotModuleSerialNumber: string;

  iotModuleSoftwareRevisionNumber: string;

  iotModuleHardwareRevisionNumber: string;

  iotGsmConnectionType: number;

  iotAccelerometerTestResult: number;

  iotInternalMemoryTestResult: number;

  iotGsmSignalQualityLte: number;

  iotAedConnectionTestResult: number;

  iotGsmSignalQuality2G: number;

  adultPadExpirationDate: string;

  pediatricPadExpirationDate: string;

  guaranteeStartDate: string;

  descriptionTempQuery: UrukTemporalQuery;

  adultTempQuery: UrukTemporalQuery;

  pediatricTempQuery: UrukTemporalQuery;

  guaranteeTempQuery: UrukTemporalQuery;

  isReadOnlyMode: boolean = true;
  description: string;

  initialCompanyName: string;
  companyName: string;
  isCompanyChanged: boolean = false;
  companyNameOptions=[];

  showFooter: boolean = true;

  activeTab: string;

  dialogViewportMin: number;

  casePanel: Panel;

  PANEL_LOCATION_CASE = 'case';

  testResultMap: string[] = ['Hata!', 'Başarılı', 'Test Sonucu Gelmedi', 'Uyarı!'];

  testTypeMap: string[] = ['24 Saatlik', '6 Aylık', 'Manuel Test'];

  VisualizationMode = VisualizationMode;

  defibrillatorStatus: string;
  defibrillatorStatusMessage: string;

  isBatteryErrorAED: boolean;

  connectionTypeName: string;

  signalQuality: string;

  signalQualityIcon: string;

  droneId: number;

  colorSignalQuality: string;

  isLastTestTimeNotValid: boolean = true;

  showTestError: boolean = false;
  isFullAutomaticDisplay: boolean = false;
  isHalfAutomaticDisplay: boolean = false;
  isFullAutomaticNonDisplay: boolean = false;
  isHalfAutomaticNonDisplay: boolean = false;
  positionStatus: number;
  showSignalQuality: boolean = true;

  private pageSize = PAGINATION_DEFAULT_PAGE_SIZE;

  private defibrillatorPage = 1;

  public paginationCallback: PaginationCallbackFunction;

  signalQualityMap: string[] = ['Kritik Sinyal', 'Düşük Sinyal', 'Normal Sinyal', 'İyi Sinyal'];

  constructor(injector: Injector) {
    super(injector);
    this.cbrokerService = injector.get(ContextService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.paginationCallback = this.setPaginatedSensors.bind(this);
    this.dialogViewportMin = Math.min(this.layoutService.getApplicationSize()[0], this.layoutService.getApplicationSize()[1]) / 4;
    this.handleEventSubscriptions();

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  handleEventSubscriptions() {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.TIME_CHANGED:
            if (event.data.dialogSource === 'adult') {
              this.adultTempQuery = event.data.timeQuery;
            } else if (event.data.dialogSource === 'pediatric') {
              this.pediatricTempQuery = event.data.timeQuery;
            } else {
              this.guaranteeTempQuery = event.data.timeQuery;
            }
            break;
          default:
            break;
        }
      });
  }

  mapPanels(): void {
    this.page?.panels.forEach(panel => {
      panel.ngsiContext.idQuery = this.entity.id;
      switch (panel.panelLocation) {
        case this.PANEL_LOCATION_CASE:
          this.casePanel = panel;
          break;
      }
    });
  }

  protected mapContextData(context: any): void {
    this.entity = context.entity.entity ? context.entity.entity : undefined;
    this.positionEntity = context.positionEntity.entity;
    this.droneId =  this.entity.id.split(':')[3];

    this.defibrillatorId = super.getValue(this.entity, 'id').split(':')[3];
    this.defibrillatorLocation = this.positionEntity.location.value.coordinates;
    this.AEDDeviceModel = this.entity.model.value;
    this.brand = this.entity.brand.value;
    this.AEDSerialNumber = '189992882';
    this.batterySerialNumber = this.defibrillatorId;
    this.hardwareRevisionNumber = 'AA';
    this.softwareRevisionNumber = 'UYG_V03.05.0005.20240627.120222';
    this.primaryLanguagePack =  'tr';
    this.secondaryLanguagePack = 'tr';
    this.iotModuleSoftwareRevisionNumber = 'UYG_V01.01.0006.20240417.08280';
    this.iotModuleHardwareRevisionNumber = 'AA';

    this.positionStatus = this.positionEntity.status ? this.positionEntity.status.value : -1;

    for (let i = 0; i < this.healthCasesEntities.length; i++) {
      this.healthCases.push(new HealthCase({
        healthCaseId: this.getHealthCaseId(this.healthCasesEntities[i].id),
        caseDate: this.getCaseDate(this.healthCasesEntities[i].refId.observedAt),
        caseHour: this.getCaseTime(this.healthCasesEntities[i].refId.observedAt),
        observedAt: this.healthCasesEntities[i].refId.observedAt
      }));
    }

    this.paginatedDefibrillatorTests = this.defibrillatorTests.slice(0, this.defibrillatorTests.length);
    this.paginatedHealthCases = this.healthCases.slice(0, this.healthCases.length);

  }
  private setPaginatedSensors(pageSize = PAGINATION_DEFAULT_PAGE_SIZE, page = 1) {
    this.pageSize = pageSize;
    this.defibrillatorPage = page;
    // @ts-ignore
    this.paginatedDefibrillatorTests = this.sensors.slice(this.pageSize * (this.mgubPage - 1), this.pageSize * this.mgubPage);
  }

  private checkConnectionStatusType(gsmConnectionType, gsmLteQuality, gsm2gQuality) {
    if (gsmConnectionType === 7) {
      this.connectionTypeName = 'LTE';
      this.calculateSignalQualityLTE(gsmLteQuality);
    } else {
      this.connectionTypeName = '2G';
      this.calculateSignalQuality2G(gsm2gQuality);
    }
    this.getSignalQualityIcon(this.signalQuality);
  }

  private calculateSignalQuality2G(gsmSignalQuality) {
    if (gsmSignalQuality === 0) {
      this.signalQuality = this.signalQualityMap[0];
    } else if (gsmSignalQuality === 1) {
      this.signalQuality = this.signalQualityMap[1];
    } else if (gsmSignalQuality === 31) {
      this.signalQuality = this.signalQualityMap[3];
    } else {
      this.signalQuality = this.signalQualityMap[2];
    }
  }

  private calculateSignalQualityLTE(gsmSignalQuality) {
    if (gsmSignalQuality < -100) {
      this.signalQuality = this.signalQualityMap[0];
    } else if (gsmSignalQuality < -90 && gsmSignalQuality >= -100) {
      this.signalQuality = this.signalQualityMap[1];
    } else if (gsmSignalQuality < -80 && gsmSignalQuality >= -90) {
      this.signalQuality = this.signalQualityMap[2];
    } else {
      this.signalQuality = this.signalQualityMap[3];
    }
  }

  private getSignalQualityIcon(signalQuality) {
    if (signalQuality === this.signalQualityMap[0]) {
      this.signalQualityIcon = 'assets/images/critical-signal.png';
      this.colorSignalQuality = 'red';
    } else if (signalQuality === this.signalQualityMap[1]) {
      this.signalQualityIcon = 'assets/images/low-signal.png';
      this.colorSignalQuality = 'yellow';
    } else if (signalQuality === this.signalQualityMap[2]) {
      this.signalQualityIcon = 'assets/images/normal-signal.png';
      this.colorSignalQuality = 'green';
    } else {
      this.signalQualityIcon = 'assets/images/good-signal.png';
      this.colorSignalQuality = 'green';
    }
  }

  getHealthCaseId(ngsiLdId) {
    const parts = ngsiLdId.split(':');
    const entityId = parts[parts.length - 1];
    return entityId;
  }

  getCaseDate(caseDate) {
    const dateObject = new Date(caseDate);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  getCaseTime(caseDate) {
    const dateObject = new Date(caseDate);
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  }

  updateDefibrillator(updatedData: DefibrillatorUpdatedData) {
    this.defibrillatorService.updateDefibrillatorInfo(updatedData);
  }

  updateDescription() {
    const descriptionProperty = new DefibrillatorProperty(this.description);
    const descriptionPropertyArray = [];
    descriptionPropertyArray.push(descriptionProperty);
    const updatedData = new DefibrillatorUpdatedData(this.defibrillatorId, undefined, undefined, descriptionPropertyArray);
    this.updateDefibrillator(updatedData);
  }
}
