/**
 * Enumeration of layer index of grid locations
 * */
export enum GridLocationLayerIndex {
  MAP = 0,
  PANEL = 1
}

/**
 * Defines the location of an item in the grid.
 */
export class GridLocation {

  /**
   * the identifier of grid location
   */
  id: string;

  /**
   * x
   */
  x: number;

  /**
   * y
   */
  y: number;

  /**
   * the number of rows the item spans
   */
  rows: number;

  /**
   * the number of columns the item spans
   */
  cols: number;

  /**
   * The index of map layer where this grid location will be placed
   * */
  layerIndex: GridLocationLayerIndex;

  constructor(data?) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.x = data.x;
    this.y = data.y;
    this.rows = data.rows;
    this.cols = data.cols;
    this.layerIndex = data.layerIndex;
  }

}
