import {NGSIQueryExpression} from '../ngsi/ngsi-query-expression.model';
import {NGSIQueryExpressionFactory} from '../factories/ngsi-query-expression.factory';
import {Persistable} from '../persistanble';

export enum ReservedCssAttribute {
  /**
   * Used to configure the source of an icon
   */
  ICON_TYPE = 'icon-type',

  /**
   * Used to configure the size of the icon. It is expected to be used as a multiplying factor on the original size of the icon e.g. 2 means the icons size would be doubled.
   */
  ICON_SIZE_FACTOR = 'size-factor',

  /**
   * Used to configure color of a line
   */
  LINE_COLOR = 'line-color',

  /**
   * Used to configure thickness of a line
   */
  LINE_WEIGHT = 'line-weight',

  /**
   * Used to configure fill color of a polygon
   */
  FILL_COLOR = 'fill-color',

  HTML = 'html',
}

/**
 * A visualization setting is a rule-based definition for styling options as CSS modifications on the target visual element
 */
export class VisualizationSetting implements Persistable {
  /**
   * Target CSS attribute to be updated
   */
  cssAttribute: string;

  /**
   * Value to be applied to the CSS attribute
   */
  value: any;

  /**
   * Condition to be met so that the value of this setting is applied on the specified CSS attribute
   */
  expression: NGSIQueryExpression;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.cssAttribute = data.cssAttribute;
    this.value = data.value;
    this.expression = NGSIQueryExpressionFactory.create(data.expression);
  }

  createPersistableObject(): any {
    const {expression, ...rest} = this;
    rest['expression'] = expression.createPersistableObject();
    return rest;
  }
}
