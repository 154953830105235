<nb-card class="dialog-card">
  <nb-card-header>{{ title | translate}}</nb-card-header>
  <nb-card-body class="flex-centered">
    <div class="container" [innerHTML]="body | translate"></div>
  </nb-card-body>
  <nb-card-footer>
    <button (click)="cancel()" class="btn btn-primary cancel-button">{{'Cancel'|translate}}</button>
    <button (click)="remove()" class="btn btn-danger delete-button">{{'Delete'|translate}}</button>
  </nb-card-footer>
</nb-card>
