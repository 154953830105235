import { SearchQuery } from './search-query.model';

/**
 * Model to query regions
 * */
export class RegionQuery extends SearchQuery {

    /**
     * Filter by parent region
     * */
    parentRegionId: string;

    constructor(data?: any) {
        super(data);
        if (!data) {
            return;
        }

        this.parentRegionId = data.parentRegionId;
    }
}
