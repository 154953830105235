<ng-container [ngSwitch]="simpleExpression">
  <!-- simple query term-->
  <div *ngSwitchCase="true" class="simple-expression">
    <div *ngIf="getExpressionAsQueryTerm() as term" class="d-flex">
      <span>{{term.path.columnName}}</span>
      <span>{{term.op}}</span>
      <span *ngIf="term.value">{{term.isQueryTermNull() ? 'null' : term.value}}</span>
      <span *ngIf="term.valueRange">{{term.valueRange.start}} - {{term.valueRange.end}}</span>
      <ng-container *ngIf="!disabled">
        <!-- edit icon -->
        <div class="svg-container">
          <uruk-svg class="action-icon" [viewbox]="'0 0 16 16'"
                  [paths]="['M13.1777 0.583496C12.6048 0.583496 12.0318 0.801699 11.5944 1.2391L10.6667 2.16683L13.8333 5.3335L14.7611 4.40576C15.6351 3.53176 15.6351 2.11389 14.7611 1.2391C14.3237 0.801699 13.7507 0.583496 13.1777 0.583496ZM9.47917 3.35433L0.375 12.4585V15.6252H3.54167L12.6458 6.521L9.47917 3.35433Z']"
                  [svgContainerClass]="'uruk-icon'"
                  [svgContainerStyle]="'width: 0.8vw'"
                  (click)="onExpressionEditClicked()">
          </uruk-svg>
        </div>
        <!-- delete icon -->
        <div class="svg-container">
          <uruk-svg class="action-icon" (click)="onExpressionDeleteClicked()"
                  [paths]="['M1.06559 0.000461224C0.854603 0.000516648 0.648429 0.0635092 0.473423 0.181386C0.298417 0.299262 0.162533 0.466666 0.0831407 0.662196C0.0037489 0.857725 -0.0155422 1.0725 0.0277336 1.27905C0.0710095 1.4856 0.174886 1.67455 0.326083 1.82175L7.00027 8.49772L0.326083 15.1737C0.224295 15.2715 0.14303 15.3885 0.0870479 15.5181C0.0310654 15.6477 0.00149046 15.7871 5.48971e-05 15.9282C-0.00138067 16.0694 0.0253522 16.2094 0.0786878 16.34C0.132023 16.4707 0.21089 16.5894 0.310668 16.6892C0.410446 16.789 0.529129 16.8679 0.659769 16.9213C0.790408 16.9746 0.930376 17.0014 1.07148 16.9999C1.21258 16.9985 1.35197 16.9689 1.4815 16.9129C1.61103 16.8569 1.72808 16.7756 1.82581 16.6738L8.5 9.99785L15.1742 16.6738C15.2719 16.7756 15.389 16.8569 15.5185 16.9129C15.648 16.9689 15.7874 16.9985 15.9285 16.9999C16.0696 17.0014 16.2096 16.9746 16.3402 16.9213C16.4709 16.8679 16.5896 16.7891 16.6893 16.6892C16.7891 16.5894 16.868 16.4707 16.9213 16.3401C16.9746 16.2094 17.0014 16.0694 16.9999 15.9282C16.9985 15.7871 16.9689 15.6477 16.913 15.5181C16.857 15.3885 16.7757 15.2715 16.6739 15.1737L9.99972 8.49772L16.6739 1.82175C16.8272 1.67274 16.9318 1.48089 16.9742 1.27135C17.0166 1.06181 16.9947 0.844346 16.9114 0.647465C16.8281 0.450584 16.6873 0.283466 16.5074 0.168014C16.3275 0.0525612 16.117 -0.00584068 15.9033 0.000461224C15.6278 0.00867351 15.3663 0.123861 15.1742 0.321621L8.5 6.9976L1.82581 0.321621C1.72697 0.219996 1.60878 0.139217 1.4782 0.0840554C1.34763 0.028894 1.20733 0.000469489 1.06559 0.000461224Z']"
                  [svgContainerClass]="'uruk-icon'"
                  [svgContainerStyle]="'width: 0.8vw'"
                  [viewbox]="'0 0 17 17'">
          </uruk-svg>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- query term association -->
  <div *ngSwitchCase="false" class="complex-expression">
    <ng-container *ngFor="let expression of getExpressionAsQueryTermAssoc().queries; let i = index">
      <query-expression [queryExpression]="getExpressionAsQueryTermAssoc().queries[i]" [parameterType]="parameterType"></query-expression>
      <nb-select *ngIf="i < getExpressionAsQueryTermAssoc().queries.length - 1"
                 [selected]="getExpressionAsQueryTermAssoc().op"
                 (selectedChange)="onAssociationOperatorChanged($event)"
                 [disabled]="operatorDisabled">
        <nb-option value="and">{{'Ve' | translate}}</nb-option>
        <nb-option value="or">{{'Veya' | translate}}</nb-option>
      </nb-select>
    </ng-container>
  </div>
</ng-container>

