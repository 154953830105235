import { SearchQuery } from './search-query.model';

/**
 * Model to query tags
 * */
export class TagQuery extends SearchQuery {
  /**
   * Tag type
   * */
  type: string;

  constructor(data?: any) {
    super(data);
    if (!data) {
      return;
    }

    this.type = data.type;
  }
}
