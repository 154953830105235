import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Realm} from '../../../shared/models/realm.model';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {SearchQuery} from 'app/shared/models/query/search-query.model';
import {RestUtil} from 'app/shared/utils/rest-util';
import {UrukPattern} from '../../../shared/models/pattern.model';

/**
 * A service to handle patterns
 */
@Injectable()
export class PatternService extends BaseHttpClientService {
  private readonly patternsPath = 'patterns';
  private readonly cepEndpoint = environment.server.cepApi + '/' + 'realms' + '/' + this.authService.getRealmId() + '/' + 'pattern';

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'patterns');
  }

  /**
   * Retrieves all patterns defined on the platform
   */
  getAllPatterns(): Observable<UrukPattern[]> {
    let url: string = this.endpoint;
    const parameters: string = RestUtil.createURLParameters(new SearchQuery());
    if (parameters) {
      url += '?' + parameters;
    }
    return this.httpClient.get<UrukPattern[]>(url).pipe(map(response => response.map(item => (new UrukPattern(item)).convertPatternDataToString())));
  }

  /**
   * Retrieves ids of active patterns on cep module
   */
  getActivePatternIds(): Observable<string[]> {
    let url: string = this.cepEndpoint;
    const parameters: string = RestUtil.createURLParameters(new SearchQuery());
    if (parameters) {
      url += '?' + parameters;
    }
    return this.httpClient.get<string[]>(url)
      .pipe(map(response => Object(response).patterns.map(item => Object(item).patternId)));
  }

  /**
   * Retrieves the details of a single pattern
   * @param patternId Identifier of the pattern to be retrieved
   */
  getPattern(patternId: String): Observable<UrukPattern> {
    const url = this.endpoint + '/' + patternId;
    return this.httpClient.get(url).pipe(map(response => new UrukPattern(response)));
  }

  /**
   * Creates a new pattern
   * @param pattern UrukPattern to be created
   */
  createPattern(pattern: UrukPattern): Observable<UrukPattern> {
    return this.httpClient.post(this.endpoint, pattern).pipe(map(response => new UrukPattern(response)));
  }

  /**
   * Starts the pattern created previously.
   * @param patternId Id of the pattern
   */
  startPattern(patternId: string) {
    const url = `${this.cepEndpoint}/${patternId}`;
    return this.httpClient.put(url, {});
  }

  /**
   * Stops the pattern started previously.
   * @param patternId Id of the pattern to be stopped
   */
  stopPattern(patternId: string) {
    const url = `${this.cepEndpoint}/${patternId}`;
    return this.httpClient.delete(url);
  }

  /**
   * Deletes the pattern.
   * @param patternId Id of the pattern
   * */
  deletePattern(patternId: string) {
    const url = this.endpoint + '/' + patternId ;
    return this.httpClient.delete(url);
  }
}
