<!-- multiple values are allowed -->
<div *ngIf="multiValue" class="multi-value-input">
  <span *ngIf="label">{{label | translate}}</span>
  <!-- render already set values -->
  <div>
    <div *ngFor="let value of managedValues; let i = index; trackBy:trackFn">
      <div class="d-flex">
        <span *ngIf="displayMode === 'view'">{{managedValues[i]}}</span>
        <div *ngIf="displayMode === 'edit'" class="value-row">
          <ng-container [ngSwitch]="dataType">
            <input *ngSwitchCase="dataTypes.TEXT" type="text" nbInput [ngModel]="managedValues[i]" (ngModelChange)="handleModelChange($event, i)">
            <input *ngSwitchCase="dataTypes.NUMBER" type="number" nbInput [(ngModel)]="managedValues[i]" (ngModelChange)="handleModelChange($event, i)">
            <uruk-date-selection *ngSwitchCase="dataTypes.DATE" [temporalQuery]="managedValues[i]"
              [parentComponentId]="'multi_value_input_'+i"
              [onlyDayTab]="true"
              (onTemporalQueryChanged)="handleModelChange($event,i)">
            </uruk-date-selection>
            <uruk-date-time-selection *ngSwitchCase="dataTypes.DATETIME"
              [temporalQuery]="managedValues[i]"
              [parentComponentId]="'multi_value_input_'+i"
              (onTemporalQueryChanged)="handleModelChange($event, i)">
            </uruk-date-time-selection>
            <uruk-date-time-selection *ngSwitchCase="dataTypes.TIME"
              [temporalQuery]="managedValues[i]"
              [onlyTimeSelection]="true"
              [parentComponentId]="'multi_value_input_'+i"
              (onTemporalQueryChanged)="handleModelChange($event, i)">
            </uruk-date-time-selection>
          </ng-container>
          <span class="form-error" *ngIf="displayErrorForValue(i)">*</span>
          <span class="form-error" *ngIf="regex && displayRegexError(i)">{{'Pattern'|translate}}: {{regex}}</span>
          <div class="action-icon-container">
            <i class="action-icon fa fa-minus" (click)="onDeleteValue(i)"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- area to add new value -->
    <hr *ngIf="managedValues?.length > 0">
    <div *ngIf="displayMode === 'edit'" class="d-flex align-items-center">
      <ng-container [ngSwitch]="dataType">
        <input *ngSwitchCase="dataTypes.TEXT" type="text" nbInput [(ngModel)]="newValue">
        <input *ngSwitchCase="dataTypes.NUMBER" type="number" nbInput [(ngModel)]="newValue">
        <uruk-date-selection *ngSwitchCase="dataTypes.DATE" [temporalQuery]="newValue"
            [parentComponentId]="'multi_value_input'"
            [onlyDayTab]="true"
            (onTemporalQueryChanged)="newValue=$event">
        </uruk-date-selection>
        <uruk-date-time-selection *ngSwitchCase="dataTypes.DATETIME"
            [temporalQuery]="newValue"
            [parentComponentId]="'multi_value_input'"
            (onTemporalQueryChanged)="newValue=$event">
        </uruk-date-time-selection>
        <uruk-date-time-selection *ngSwitchCase="dataTypes.TIME"
          [temporalQuery]="newValue"
          [onlyTimeSelection]="true"
          [parentComponentId]="'multi_value_input'"
          (onTemporalQueryChanged)="newValue=$event">
        </uruk-date-time-selection>
      </ng-container>
      <span class="form-error" *ngIf="displayErrorForNewValue()">*</span>
      <span class="form-error" *ngIf="regex && displayRegexError()">{{'Pattern'|translate}}: {{regex}}</span>
      <div class="action-icon-container">
        <i class="action-icon fa fa-plus" (click)="onAddValue()"></i>
      </div>
    </div>
  </div>
</div>

<!-- multiple values are not allowed -->
<ng-container *ngIf="!multiValue">
  <ng-container [ngSwitch]="dataType">
    <input *ngSwitchCase="dataTypes.TEXT" type="text" nbInput [ngModel]="newValue" (ngModelChange)="handleSingleValueModelChange($event)">
    <input *ngSwitchCase="dataTypes.NUMBER" type="number" nbInput [ngModel]="newValue" (ngModelChange)="handleSingleValueModelChange($event)">
    <uruk-date-selection *ngSwitchCase="dataTypes.DATE" [temporalQuery]="newValue"
      [parentComponentId]="'multi_value_input'"
      [onlyDayTab]="true"
      (onTemporalQueryChanged)="handleSingleValueModelChange($event)">
    </uruk-date-selection>
    <uruk-date-time-selection *ngSwitchCase="dataTypes.DATETIME"
      [temporalQuery]="newValue"
      [parentComponentId]="'multi_value_input'"
      (onTemporalQueryChanged)="handleSingleValueModelChange($event)">
    </uruk-date-time-selection>
    <uruk-date-time-selection *ngSwitchCase="dataTypes.TIME"
      [temporalQuery]="newValue"
      [onlyTimeSelection]="true"
      [parentComponentId]="'multi_value_input'"
      (onTemporalQueryChanged)="handleSingleValueModelChange($event)">
    </uruk-date-time-selection>
    <span class="form-error" *ngIf="regex && displayRegexError()">{{'Pattern'|translate}}: {{regex}}</span>
  </ng-container>
</ng-container>
