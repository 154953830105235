import {NGSIQueryExpression} from '../../shared/models/ngsi/ngsi-query-expression.model';
import {NGSIQueryTerm} from '../../shared/models/ngsi/ngsi-query-term.model';
import {NGSIQueryTermAssoc} from '../../shared/models/ngsi/ngsi-query-term-assoc.model';
import {LogicalOperator} from '../../shared/enums/logical-operator.enum';
import {NGSIPath} from '../../shared/models/ngsi/ngsi-path.model';
import {QueryOperator} from '../../shared/models/query/query-operator.model';
import {OperatorType, NGSIOperator, RangeOperator} from '../../shared/enums/ngsi-query.enum';
import {Range} from '../../shared/models/generic/range.model';
import {QueryOperatorParam} from '../../shared/models/query/query-operator-param.model';
import {QueryOperatorParamValue} from '../../shared/models/query/query-operator-param-value.model';
import {sum} from './operator-evaluator';
import {NGSIResult} from '../../shared/models/ngsi/ngsi-result';

/**
 * Evaluates the expression on the entity
 * @param expression
 * @param entity
 */
export function evaluateExpression(expression: NGSIQueryExpression, entity: NGSIResult): boolean {
  // evaluate single expression
  if (expression instanceof NGSIQueryTerm) {
    return evaluateQueryTerm(expression, entity);

    // evaluate logically bound expressions
  } else if (expression instanceof NGSIQueryTermAssoc) {
    const results: boolean[] = expression.queries.map(query => evaluateExpression(query, entity));
    let result = false;
    if (expression.op === LogicalOperator.AND) {
      result = true;
    }
    results.forEach(r => {
      if (expression.op === LogicalOperator.AND) {
        result = r && result;
      } else {
        result = r || result;
      }
    });
    return result;
  }
}

/**
 * Evaluates a single query on the entity
 * @param query Single criteria defined on the entity e.g. OffStreetParking.availableSpotNumber > 0
 * @param entity Content on which the query will be executed
 */
function evaluateQueryTerm(query: NGSIQueryTerm, entity: NGSIResult): boolean {
  let value: any;
  if (query.path instanceof NGSIPath) {
    value = entity.extractSingleValueByNgsiPath(query.path);
  } else if (query.path instanceof QueryOperator) {
    const queryOperator: QueryOperator = query.path as QueryOperator;
    evaluateAggregationOperator(queryOperator.op, queryOperator.params, entity);
  }
  return evaluateOperator(value, query.value, query.op);
}

/**
 * Evaluates an aggregation operator on the given parameters
 * @param aggregationOperator
 * @param params
 * @param entity
 */
function evaluateAggregationOperator(aggregationOperator: string, params: QueryOperatorParam[], entity: NGSIResult): any {
  const values: any[] = params.map(param => resolveOperatorParam(param, entity));
  if (aggregationOperator === OperatorType.SUM) {
    return sum(values);
  }
}

/**
 * Resolves or evaluates the parameter value based on the type of the parameter. For simple parameter values returns the value directly.
 * For NGSIPath parameters, extracts the value in the path and for QueryOperators, evaluates the aggregation operator.
 * @param param
 * @param entity
 */
function resolveOperatorParam(param: QueryOperatorParam, entity: NGSIResult): any {
  if (param instanceof QueryOperatorParamValue) {
    return param.value;
  } else if (param instanceof NGSIPath) {
    return entity.extractValueByNgsiPath(param);
  } else if (param instanceof QueryOperator) {
    const queryOperator: QueryOperator = param as QueryOperator;
    evaluateAggregationOperator(queryOperator.op, queryOperator.params, entity);
  }
}

/**
 * Evaluates the operator by considering the given values.
 * @param value1 First operand for the operator
 * @param value2 A list of second operands. It is sufficient for evaluation to result as true if one of the values in this list satisfies the operator.
 * @param operator NGSI operator. See {@link NGSIOperator}
 * @return
 */
function evaluateOperator(value1: any, value2: any[] | Range, operator: string): boolean {
  if (value2 instanceof Range) {
    // TODO handle range values
    throw new Error('Evaluation for range values are not implemented');
  } else {
    for (const value of value2) {
      if (evaluateOperatorForSingleValue(value1, value, operator)) {
        return true;
      }
    }
    return false;
  }
}

/**
 * Evaluates an {@link NGSIOperator} on the given values.
 * @param value1
 * @param value2
 * @param operator
 */
export function evaluateOperatorForSingleValue(value1: any, value2: any, operator: string): boolean {
  switch (operator) {
    case NGSIOperator.EQUAL:
      return value1 === value2;
    case NGSIOperator.UNEQUAL:
      return value1 !== value2;
    case NGSIOperator.LESS:
      return (value1 as number) < (value2 as number);
    case NGSIOperator.LESS_OR_EQUAL_TO:
      return (value1 as number) <= (value2 as number);
    case NGSIOperator.GREATER:
      return (value1 as number) > (value2 as number);
    case NGSIOperator.GREATER_OR_EQUAL_TO:
      return (value1 as number) >= (value2 as number);
    case RangeOperator.IN_CLOSED_RANGE:
      if (Array.isArray(value2) && value2.length > 1) {
        return (value2[0] as number) <= (value1 as number) && (value1 as number) <= (value2[1] as number);
      }
      return false;
    case RangeOperator.IN_OPEN_RANGE:
      if (Array.isArray(value2) && value2.length > 1) {
        return (value2[0] as number) <= (value1 as number) && (value1 as number) < (value2[1] as number);
      }
      return false;
  }
  return false;
}
