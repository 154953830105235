import {Visualization} from './visualization.model';
import {VisualizationType} from '../../enums/viualization-type.enum';

export class PolygonVisualization extends Visualization {

  /**
   * Opacity of the polygon
   */
  opacity: number;

  /**
   * Fill color of the polygon
   */
  fillColor: string;

  constructor(data?) {
    super(data);
    this.jsonClass = VisualizationType.POLYGON;

    if (!data) {
      return;
    }

    this.opacity = data.opacity;
    this.fillColor = data.fillColor;
  }
}
