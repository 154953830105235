import {Visualization} from './visualization.model';
import {VisualizationType} from '../../enums/viualization-type.enum';

export class LineVisualization extends Visualization {

  /**
   * Opacity of the line
   */
  opacity: number;

  /**
   * Weight (thickness) of the line
   */
  weight: number;

  /**
   * Color of the line
   */
  color: string;

  constructor(data?) {
    super(data);
    this.jsonClass = VisualizationType.LINE;

    if (!data) {
      return;
    }

    this.opacity = data.opacity;
    this.weight = data.weight;
    this.color = data.color;
  }
}
