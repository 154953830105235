import {Observable, Subject} from 'rxjs';
import {NGSIResult} from '../ngsi/ngsi-result';

/**
 * A context to keep kpi execution-related resources
 */
export class NGSIExecutionContext {
  /**
   *
   * @param subject
   * @param observable
   * @param interval
   * @param count Number of times that the same NGSI context is registered. This situation may occur e.g. when the same KPI is added to two different panels with different visualization configurations.
   * We should not clear the context in the first attempt if the count is greater than 1.
   */
  constructor(public subject: Subject<NGSIResult[]>,
              public observable: Observable<NGSIResult[]>,
              public interval: NodeJS.Timeout = null,
              public count: number = 1) {

  }
}

