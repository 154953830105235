<!--List of available tags based on the search query-->
<div class="tag-container">
  <!--Input to search for a tag-->
  <input #tagSearchText nbInput type="text">

  <div (click)="selectTag(tag)" *ngFor="let tag of filteredTags" class="line-item" [ngClass]="{'selected': highlightSelected && selectedTags[tag.id]}">
    <i class="fa fa-tag"></i>
    <label class="label">{{ 'Tag_' + tag.code | translate}}</label>
  </div>
</div>
