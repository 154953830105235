import {DataSeries} from './data-series.model';
import {ChartDataFormat} from '../../enums/chart-data-format';
import {Persistable} from '../persistanble';

/**
 * Settings for a specific {@link DataSeries} or {@link DataDimension}
 */
export class AxisSettings implements Persistable {
  /**
   * Index of the dimension or series description in the relevant {@link KPI}
   */
  index: number;

  /**
   * Json Path to access the data for this axis in a data item
   */
  path: string;

  /**
   * Label of the axis
   */
  label: string;

  /**
   * Precision for the values after comma, if the data is numeric
   */
  precision?: number;

  /**
   * Whether the thousand separator will be applied or not for numeric values
   */
  thousandSeparator?: boolean;

  /**
   * Chart data format
   * */
  chartDataFormat?: ChartDataFormat;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.index = data.index;
    this.path = data.path;
    this.label = data.label;
    // set precision if the given value is a valid number
    if (Number.isFinite(data.precision)) {
      this.precision = data.precision;
    }
    this.thousandSeparator = !!data.thousandSeparator;
    this.chartDataFormat = data.targetType;
  }

  createPersistableObject(): any {
    const {chartDataFormat, ...rest} = this;
    // renaming the chartDataFormat field into targetType as the legacy data uses targetType name, which is actually misleading
    // TODO update backend to use this name as well, which requires updates also in the legacy data
    rest['chartDataFormat'] = this.chartDataFormat;
    return rest;
  }
}
