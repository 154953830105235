import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TimeUtil} from '../utils/time-util';
import {User} from '../models/user.model';
import {AuthService} from '../../core/services/auth/auth.service';
import {Tag} from '../models/tag.model';
import {TagTypeUtil} from '../enums/tag-type.enum';
import {Layer} from '../models/layer.model';
import {Page} from '../models/page.model';
import {Region} from '../models/region.model';
import {QueryTypeUtil} from '../enums/query-type';
import {KPI} from '../models/kpi.model';
import {StructureDefinitionState} from '../enums/structure-definition-state.enum';
import {StructureDefinition} from '../models/schema/structure-definition.model';
import {ContextSource} from '../models/context-source.model';
import {Realm} from '../models/realm.model';
import {Sensor} from '../models/sensor.model';
import {LayerService} from '../../core/services/meta/layer.service';
import {map} from 'rxjs/operators';
import {RegionService} from '../../core/services/meta/region.service';
import {SearchQuery} from '../models/query/search-query.model';
import { RegionQuery } from '../models/query/region-query.model';
import {PageTemplate} from '../models/page-template.model';
import {MLModel} from '../models/analytics/mlmodel.model';
import {UrukPattern} from '../models/pattern.model';
import {ControllableDevice} from "../models/device-management/controllableDevice.model";

/**
 * Maps the given array of objects to their metadata.
 * */
@Pipe({
  name: 'metadata'
})
export class MetadataPipe implements PipeTransform {

  constructor(private translateService: TranslateService,
              private authService: AuthService,
              private layerService: LayerService,
              private regionService: RegionService) {

  }

  /**
   * @param objects the list of objects. It is assumed that all objects have the same type.
   * */
  transform(objects: any[]): Promise<any[]> {
    if (objects?.length) {
      if (objects[0] instanceof User) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id,
            username: object.username,
            fullname: object.getName(),
            status: object.enabled ? 'Active' : 'Inactive',
            role: object.roles && object.roles.length > 0 ? this.getRoleLabels(object.roles) : [],
            realm: this.authService.getRealmId(),
            creationTime: object.creationDate ? TimeUtil.serializeDatetime(object.creationDate) : TimeUtil.serializeDatetime(new Date())
          };
        }));
      } else if (objects[0] instanceof Tag) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id, code: object.code, name: object.name
          };
        }));
      } else if (objects[0] instanceof Layer) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id,
            domain: object.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag)).map(tag => TagTypeUtil.extractTagCode(tag)).join(', '),
            name: object.name
          };
        }));
      } else if (objects[0] instanceof Page) {
        const layerIds = objects.map(object => object.layerIds).reduce((previousValue, currentValue) => {
          currentValue.forEach(layerId => previousValue.add(layerId));
          return previousValue;
        }, new Set());
        const query: SearchQuery = new SearchQuery();
        query.ids = Array.from(layerIds);
        return this.layerService.getLayers(query).pipe(map(layers => {
          return objects.map(object => {
            return {
              id: object.id,
              domain: object.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag)).map(tag => TagTypeUtil.extractTagCode(tag)).join(', '),
              name: object.title,
              templateType: object.templateType,
              layers: layers.filter(layer => object.layerIds.includes(layer.id)).map(layer => layer.name).join(', ')
            };
          });
        })).toPromise();
      } else if (objects[0] instanceof Realm) {
        const regionIds = objects.map(object => object.regionId).reduce((regionSet, regionId) => {
          return regionSet.add(regionId);
        }, new Set());
        const query: RegionQuery = new RegionQuery();
        query.ids = Array.from(regionIds);
        return this.regionService.getRegions(query).pipe(map(regions => {
          return objects.map(object => {
            return {
              id: object.id,
              domain: object.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag)).map(tag => TagTypeUtil.extractTagCode(tag)).join(', '),
              name: object.name,
              region: regions.find(region => region.id === object.regionId).name,
              active: object.inUse ? 'Active' : 'Inactive'
            };
          });
        })).toPromise();
      } else if (objects[0] instanceof Region) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id, category: this.translateService.instant(`region.${object.category}`), name: object.name
          };
        }));
      } else if (objects[0] instanceof Sensor) {
        return Promise.resolve(objects.map(object => {
          return {
            sensorId: object.sensorId,
            description: object.description,
            status: object.status,
            sensorType: object.sensorType,
          };
        }));
      } else if (objects[0] instanceof KPI) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id,
            domain: object.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag)).map(tag => TagTypeUtil.extractTagCode(tag)).join(', '),
            name: object.name,
            queryType: QueryTypeUtil.getHumanReadableQueryTypeName(object.queryType)
          };
        }));
      } else if (objects[0] instanceof UrukPattern) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id,
            domain: object.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag)).map(tag => TagTypeUtil.extractTagCode(tag)).join(', '),
            name: object.name,
            title: object.title,
            description: object.description,
            active: object.active ? 'Active' : 'Inactive'
          };
        }));
      } else if (objects[0] instanceof StructureDefinition) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id,
            domain: object.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag)).map(tag => TagTypeUtil.extractTagCode(tag)).join(', '),
            name: object.title,
            status: this.getStructureDefinitionStateText(object),
            isAbstract: object.isAbstract ? 'Yes' : 'No',
            category: object.category,
          };
        }));
      } else if (objects[0] instanceof ContextSource) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id,
            domain: object.meta.tags.filter(tag => TagTypeUtil.isDomainTag(tag)).map(tag => TagTypeUtil.extractTagCode(tag)).join(', '),
            name: object.name,
            active: object.inUse ? 'Active' : 'Inactive'
          };
        }));
      } else if (objects[0] instanceof PageTemplate) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id,
            name: object.title,
            creationTime: object.meta.createdAt ? TimeUtil.serializeDatetime(object.meta.createdAt) : TimeUtil.serializeDatetime(new Date())
          };
        }));
      } else if (objects[0] instanceof MLModel) {
        return Promise.resolve(objects.map(object => {
          const o: any = {
            id: object.id,
            name: object.name,
            description: object.description,
            script: object.config.script,
            entityType: object.config.entityType,
            creationTime: object.meta.createdAt ? TimeUtil.serializeDatetime(object.meta.createdAt) : TimeUtil.serializeDatetime(new Date()),
            models: object.models
          };

          const index = object.trainingStatus.indexOf(':');
          if (index > 0) {
            o.status = object.trainingStatus.substring(0, index);
            o.statusTooltip = object.trainingStatus.substring(index + 1);
          } else {
            o.status = object.trainingStatus;
          }
          return o;
        }));
      } else if (objects[0] instanceof ControllableDevice) {
        return Promise.resolve(objects.map(object => {
          return {
            id: object.id.split(':')[3],
            type: object.type,
            distributorName: object.DistributorName,
            baseUrl: object.BaseUrl,
            baseRealm: object.BaseRealm,
            baseClient: object.BaseClient,
            baseSecret: object.BaseSecret,
            dataUrl: object.DataUrl,
            dataRealm: object.DataRealm,
            dataClient: object.DataClient,
            dataSecret: object.DataSecret,
            deviceType: object.DeviceType,
            deviceId: object.DeviceId,
          };
        }));
      }
    }
    return Promise.resolve([]);
  }

  /**
   * Returns human readable label for given roles
   * @param roles
   */
  private getRoleLabels(roles) {
    const translation = this.translateService.instant(roles);
    return Array.isArray(roles) ? roles.map(role => translation[role]).join(', ') : roles;
  }

  /**
   * Returns the text to be displayed for the state of structure definition.
   * @param definition the structure definition
   * @returns
   */
  private getStructureDefinitionStateText(definition: StructureDefinition): string {
    switch (definition.state) {
      case StructureDefinitionState.DRAFT:
        return "Not In Use";
      case StructureDefinitionState.OBSOLETE:
        return "Obsolete";
      case StructureDefinitionState.IN_USE:
        return "In Use";
    }
  }
}
