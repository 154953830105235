<!-- URUK image and breadcrumb title -->
<div class="overlay-container page-breadcrumb-container uruk-list-breadcrumb">
  <div class="page-breadcrumb">
    <img class="uruk-logo" src="../../../../assets/images/uruk-name-white.png">
    <div class="page-title">
      <span>{{title | translate}}</span>
    </div>
  </div>
</div>

<!-- Subtitle with additional icons -->
<div class="breadcrumb-height">
  <div (click)="onCreateClicked.emit()" *ngIf="displayCreateButton"
       class="btn btn-primary add-item-button flex-centered {{createButtonClass}}">
    <uruk-svg
      [paths]="['M30 14.25H17.75V2C17.75 1.034 16.966 0.25 16 0.25C15.034 0.25 14.25 1.034 14.25 2V14.25H2C1.034 14.25 0.25 15.034 0.25 16C0.25 16.966 1.034 17.75 2 17.75H14.25V30C14.25 30.966 15.034 31.75 16 31.75C16.966 31.75 17.75 30.966 17.75 30V17.75H30C30.966 17.75 31.75 16.966 31.75 16C31.75 15.034 30.966 14.25 30 14.25Z']"
      [svgContainerClass]="'uruk-icon'"
      [svgContainerStyle]="'width: 1vw'"
      [viewbox]="'0 0 32 32'"
      class="action-icon">
    </uruk-svg>
  </div>
  <ng-container [ngSwitch]="displayLevels">
    <div *ngSwitchCase="true" class="form-title">
      <!-- Levels -->
      <div class="add-item-label d-flex {{levelsClass}}">
        <!-- User can not select the active step which is the last one in the list-->
        <div *ngFor="let level of levels; let last=last " class="flex-centered level {{level.class}}" [ngClass]="{'clickable':!last}" (click)="last ? null : selectLevel(level)">{{level.title|translate}}</div>
      </div>
      <div (click)="onCancelClicked.emit()" class="cancel-form-button flex-centered">
        <i class="fa fa-times"></i>
      </div>
      <!-- Tooltip icon -->
      <uruk-svg *ngIf="displayTooltip"
        (click)="openTooltip.emit()"
        [paths]="['M15.5003 0.916626C7.44595 0.916626 0.916992 7.44558 0.916992 15.5C0.916992 23.5543 7.44595 30.0833 15.5003 30.0833C23.5547 30.0833 30.0837 23.5543 30.0837 15.5C30.0837 7.44558 23.5547 0.916626 15.5003 0.916626ZM15.5003 22.7916C14.6953 22.7916 14.042 22.1383 14.042 21.3333V15.5C14.042 14.695 14.6953 14.0416 15.5003 14.0416C16.3053 14.0416 16.9587 14.695 16.9587 15.5V21.3333C16.9587 22.1383 16.3053 22.7916 15.5003 22.7916ZM16.2295 11.125H14.7712C14.3687 11.125 14.042 10.7983 14.042 10.3958V8.93746C14.042 8.53496 14.3687 8.20829 14.7712 8.20829H16.2295C16.632 8.20829 16.9587 8.53496 16.9587 8.93746V10.3958C16.9587 10.7983 16.632 11.125 16.2295 11.125Z']"
        [svgContainerClass]="'uruk-icon'"
        [svgContainerStyle]="'width: 1vw'"
        [viewbox]="'0 0 31 31'" class="action-icon">
      </uruk-svg>
    </div>
    <div *ngSwitchCase="false" class="functions-menu ml-auto">
      <!-- Action buttons -->
      <nb-actions class="control-actions right" size="small">
        <nb-action class="control-button thin-border-right"
                   nbPopoverPlacement="bottom" nbPopoverTrigger="hint">
          <uruk-svg [height]="'100%'"
                    [paths]="['M12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3ZM12 14C8.996 14 3 15.508 3 18.5V20C3 20.552 3.448 21 4 21H20C20.552 21 21 20.552 21 20V18.5C21 15.508 15.004 14 12 14Z']"
                    [svgContainerClass]="'uruk-icon'"
                    [viewbox]="'0 0 24 24'"
                    [width]="'100%'">
          </uruk-svg>
        </nb-action>
        <nb-action class="control-button " (mouseenter)="displayNotifications = true" (mouseleave)="displayNotifications = false"
                   nbPopoverPlacement="bottom" nbPopoverTrigger="hint">
          <uruk-svg [height]="'100%'"
                    [paths]="['M12 2C11.172 2 10.5 2.672 10.5 3.5V4.19531C7.91318 4.86209 6 7.2048 6 10V16L4.46484 17.1562H4.46289C4.32099 17.2466 4.20418 17.3713 4.12327 17.5188C4.04236 17.6663 3.99996 17.8318 4 18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H12H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18C20 17.8318 19.9576 17.6663 19.8767 17.5188C19.7958 17.3713 19.679 17.2466 19.5371 17.1562L18 16V10C18 7.2048 16.0868 4.86209 13.5 4.19531V3.5C13.5 2.672 12.828 2 12 2ZM10 20C10 21.1 10.9 22 12 22C13.1 22 14 21.1 14 20H10Z']"
                    [svgContainerClass]="'uruk-icon'"
                    [viewbox]="'0 0 24 24'"
                    [width]="'100%'">
          </uruk-svg>
          <notifications-tooltip *ngIf="displayNotifications"></notifications-tooltip>
        </nb-action>
      </nb-actions>
    </div>
  </ng-container>
</div>
