import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, take} from 'rxjs';
import {UserPreference} from '../../../shared/models/user-preference.model';
import {Page} from '../../../shared/models/page.model';
import {UserRole, UserRoleUtil} from '../../../shared/enums/user-role.enum';
import {AuthorizationScope, AuthorizationScopeUtil} from '../../../shared/enums/authorization-scope.enum';
import {AuthScope} from '../../../shared/models/auth/auth-scope.model';
import { ObjectUtil } from 'app/shared/utils/object-util';

/**
 * A data sharing service across all available components. The content of the data stored by this service can
 * be categorized by all types of user related information such as user id, name, role, etc.
 */
@Injectable()
export class UserService {
  /**
   * Identifier of the authenticated user
   */
  userId: BehaviorSubject<string>;

  /**
   * Name of the authenticated user that is set by the authorization server
   */
  private userName: BehaviorSubject<string>;

  companyName: BehaviorSubject<string>;

  /**
   * Display name of the authenticated user
   */
  private userDisplayName: BehaviorSubject<string>;

  /**
   * Roles of the authenticated user
   */
  private userRoles: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private userRolesObs: Observable<string[]> = this.userRoles.asObservable();

  /**
   * Keeps the domain codes which are assigned to the authenticated user. If all domains are available to the user,
   * it is simply an empty array.
   */
  private userDomains: BehaviorSubject<string[]>;

  /**
   * Authorization scopes of user
   * */
  private userScopes: BehaviorSubject<AuthScope[]>;

  /**
   * User preferences
   */
  private userPreferences: ReplaySubject<UserPreference> = new ReplaySubject<UserPreference>(1);
  private userPreferencesObs: Observable<UserPreference> = this.userPreferences.asObservable();

  /**
   * Page summaries
   */
  private pageSummaries: ReplaySubject<Map<string, Page>> = new ReplaySubject<Map<string, Page>>(1);
  private pageSummariesObs: Observable<Map<string, Page>> = this.pageSummaries.asObservable();

  constructor() {
    this.userId = new BehaviorSubject<string>('e3d2719d-0cde-4d16-b4c3-edaa3bf106b5');
    this.userName = new BehaviorSubject<string>(null);
    this.companyName = new BehaviorSubject<string>(null);
    this.userDisplayName = new BehaviorSubject<string>(null);
    this.userDomains = new BehaviorSubject<string[]>([]);
    this.userScopes = new BehaviorSubject<AuthScope[]>([]);
  }

  /**
   * Sets all the user information from the JWT retrieved from the server
   * @param jwt
   */
  setUserData(jwt: any) {
    this.userId.next(jwt.sub);
    this.userName.next(jwt.preferred_username);
    this.companyName.next(jwt.companyName);
    this.userDisplayName.next(jwt.name);
    this.userRoles.next(UserRoleUtil.extractRolesFromToken(jwt));
    this.userDomains.next(AuthorizationScopeUtil.extractDomains(jwt));
    this.userScopes.next(AuthorizationScopeUtil.extractAuthScopes(jwt));
  }

  /**
   * Checks whether authenticated user is admin or not
   */
  isAdmin(): boolean {
    // if the user has some roles, s/he is not a regular user
    // TODO: but, it does not mean that s/he is an admin. Check it Later
    return this.userRoles.value?.length > 0;
  }

  /**
   * Checks whether the authenticated user is a platform manager or not.
   * */
  isPlatformManager(): boolean {
    return this.userRoles.value?.includes(UserRole.PLATFORM_MANAGER);
  }

  /**
   * Checks whether the authenticated user is a realm manager or not.
   * */
  isRealmManager(): boolean {
    return this.userRoles.value?.includes(UserRole.REALM_MANAGER) || this.userRoles.value?.includes(UserRole.ASELSAN_USER);
  }

  /**
   * Checks whether the authenticated user has one of the following roles: platform manager or realm manager.
   * */
  isPlatformOrRealmManager(): boolean {
    return this.isRealmManager() || this.isPlatformManager();
  }

  /**
   * Checks whether authenticated user is regular user. If s/he does not have any roles, s/he is a regular user.
   */
  isRegularUser(): boolean {
    return !this.userRoles.value?.length || this.userRoles.value?.includes(UserRole.REGULAR_USER);
  }

  isTestUser() {
    return !this.userRoles.value?.length || this.userRoles.value?.includes(UserRole.TEST_USER);
  }

  isAselsanUser() {
    return !this.userRoles.value?.length || this.userRoles.value?.includes(UserRole.ASELSAN_USER);
  }

  isDistributorUser() {
    return !this.userRoles.value?.length || this.userRoles.value?.includes(UserRole.DISTRIBUTOR_USER);
  }
  /**
   * Returns observable to subscribe to changes on user preferences
   */
  onUserPreferencesChanged(): Observable<UserPreference> {
    return this.userPreferencesObs;
  }

  /**
   * Returns observable to subscribe to the changes on user rules
   */
  onUserRolesChanged(): Observable<string[]> {
    return this.userRolesObs;
  }

  /**
   * Returns whether the user has specified permission for the given resource.
   * @param resourceId the resource id
   * @param permission the authorization scope
   * */
  hasPermissionForResource(resourceId: string, permission: AuthorizationScope): boolean {
    const scopes = this.userScopes.value;
    return scopes.some(scope => (!scope.resourceId || scope.resourceId === resourceId) && (scope.permission.includes(permission)));
  }

  /**
   * Returns whether the user has a 'read' permission for the given domain.
   * @param domain domain
   * @returns true if the user has a 'read' permission.
   */
  hasReadPermissionForDomain(domain: string): boolean {
    return this.userScopes.value.some(scope => scope.resourceId === domain && (scope.permission.includes(AuthorizationScope.DOMAIN_READ)
    || scope.permission.includes(AuthorizationScope.DOMAIN_MANAGE)));
  }

  /**
   * Sets user preferences and notifies subscribers with the new preferences
   * @param userPreferences
   */
  setUserPreferences(userPreferences: UserPreference): void {
    this.userPreferences.next(userPreferences);
  }

  /**
   * Returns observable to subscribe to changes page summaries
   */
  onPageSummariesChanged(): Observable<Map<string, Page>> {
    return this.pageSummariesObs;
  }

  /**
   * Sets page summaries and notifies subscribers with the new pages
   * @param summaries
   */
  setPageSummaries(summaries: Map<string, Page>): void {
    this.pageSummaries.next(summaries);
  }

  /**
   * Updates the corresponding page in {@link pageSummaries}.
   * @param pageId the identifier of page to be updated
   * @param title the new title of page
   * @param icon the new icon of page
   */
  public updatePageSummary(pageId: string, title: string, icon: string){
    this.pageSummariesObs.pipe(take(1)).subscribe(summaries => {
      const newSummaries: Map<string, Page> = new Map<string,Page>();
      summaries.forEach((page: Page, key: string) => {
        const copyPage: Page = ObjectUtil.deepCopy(Page, page);
        // apply the update
        if(copyPage.id === pageId){
          copyPage.title = title;
          copyPage.icon = icon;
        }
        newSummaries.set(key, copyPage)
      });
      this.setPageSummaries(newSummaries);
    })
  }

  /**
   * Returns the domains of authenticated user.
   * */
  public getUserDomains(): string[] {
    return this.userDomains.value;
  }

  /**
   * Returns the username.
   * */
  public getUsername(): string {
    return this.userName.getValue();
  }
}
