<leaflet-map #map *ngIf="mapFramework === MapFramework.LEAFLET && mapType === MapTileType.SATELLITE"
             [layers]="layers"
             [mapType] = MapTileType.SATELLITE
             [mapSettings]="mapSettings"
             (onMapInitialized)="onMapInitialized($event)"
             (onMapBoundariesChanged)="onMapBoundriesChanged($event)"
             (onMapClicked)="onInternalMapClicked($event)"
             (onShapeDrawn)="onShapeDrawn($event)">
</leaflet-map>
<leaflet-map #map *ngIf="mapFramework === MapFramework.LEAFLET && mapType === MapTileType.ROADMAP"
             [layers]="layers"
             [mapSettings]="mapSettings"
             [mapType] = MapTileType.ROADMAP
             (onMapInitialized)="onMapInitialized($event)"
             (onMapBoundariesChanged)="onMapBoundriesChanged($event)"
             (onMapClicked)="onInternalMapClicked($event)"
             (onShapeDrawn)="onShapeDrawn($event)">
</leaflet-map>
<maplibre-map #map *ngIf="mapFramework === MapFramework.MAPLIBRE"
              [layers]="layers"
              [mapSettings]="mapSettings"
              (onMapInitialized)="onMapInitialized($event)"
              (onMapBoundariesChanged)="onMapBoundriesChanged($event)"
              (onMapClicked)="onInternalMapClicked($event)"
              (onShapeDrawn)="onShapeDrawn($event)">
</maplibre-map>

<maprays-map #map *ngIf="mapFramework === MapFramework.MAPRAYS" [mapSettings]="mapSettings" ></maprays-map>
