import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Page} from '../../../shared/models/page.model';
import {Panel} from '../../../shared/models/visualization/panel.model';
import {RestParameters, RestUtil} from '../../../shared/utils/rest-util';
import {environment} from '../../../../environments/environment';
import {BaseHttpClientService} from '../base-http-client.service';
import {SearchQuery} from '../../../shared/models/query/search-query.model';

/**
 * A service to handle page related operations
 */
@Injectable()
export class PageService extends BaseHttpClientService {

  constructor(private injector: Injector) {
    super(injector, environment.server.adminApi, 'pages');
  }

  /**
   * Retrieves all pages defined in the platform.
   */
   getAllPages(): Observable<Page[]> {
    return this.getPages(new SearchQuery());
  }

  /**
   * Retrieves the pages which satisfies the given query.
   * @param query Search query
   */
  getPages(query?: SearchQuery): Observable<Page[]> {
    let url: string = this.endpoint;
    if (query) {
      const parameters: string = RestUtil.createURLParameters(query);
      if (parameters) {
        url += '?' + parameters;
      }
    }
    return this.httpClient.get<Page[]>(url).pipe(map(response => response.map(item => new Page(item))));
  }

  /**
   * Retrieves the details of a single page
   * @param pageId Identifier of the page to be retrieved
   */
  getPage(pageId: String): Observable<Page> {
    const url = this.endpoint + '/' + pageId;
    return this.httpClient.get(url).pipe(map(response => new Page(response)));
  }

  /**
   * Creates a new page for the user
   * @param page Page to be created
   */
  createPage(page: Page): Observable<Page> {
    return this.httpClient.post(this.endpoint, page).pipe(map(response => new Page(response)));
  }

  /**
   * Updates the given page of the user
   * @param page Page to be updated
   */
  updatePage(page: Page): Observable<Page> {
    const url = this.endpoint + '/' + page.id;
    return this.httpClient.put(url, page.createPersistableObject()).pipe(map(response => {
      const updatedPage = new Page(response);
      // notify the system about page update
      this.eventService.broadcastPageUpdatedEvent(updatedPage);;
      return updatedPage;
    }));
  }

  /**
   * Deletes the page of the user
   * @param pageId Id of the page to be deleted
   */
  deletePage(pageId: string) {
    const url = this.endpoint + '/' + pageId;
    return this.httpClient.delete(url)
      .pipe(
        map(() => {
          this.eventService.broadcastPageDeletedEvent(pageId);
        })
      );
  }

  /**
   * Copies the content of a page belonging to a user to another user
   * @param pageId Id of the page to be assigned
   * @param userId Id of the user to be assigned
   */
  assignPage(pageId: String, userId: String) {
    const url = this.endpoint + '/' + pageId + '/assign?' + RestParameters.USER_ID + '=' + userId;
    return this.httpClient.put(url, null);
  }

  /**
   * Adds a panel to a page at specified location
   * @param pageId Id of the page
   * @param panelLocation Location of the panel
   * @param panel Panel object to be added
   */
  addPanel(pageId: String, panelLocation: String, panel: Panel) {
    const url = this.endpoint + '/' + pageId + '/panels/' + panelLocation;
    return this.httpClient.put(url, panel);
  }

  /**
   * Copies the given page and creates a personal one for the authenticated user.
   * @param pageId Id of the page
   * @return the created page for the authenticated user
   */
  copyPage(pageId: String): Observable<Page> {
    const url = `${this.endpoint}/${pageId}/copy`;
    return this.httpClient.put(url, null).pipe(map(response => new Page(response)));
  }

  /**
   * Deletes a panel at a specified location from a page
   * @param pageId Id of the page
   * @param panelLocation Location of the panel
   */
  deletePanel(pageId, panelLocation: String) {
    const url = this.endpoint + '/' + pageId + '/panels/' + panelLocation;
    return this.httpClient.delete(url);
  }
}
