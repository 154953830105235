import {Component, Injector, Input, OnInit} from '@angular/core';
import {NGSIQueryTerm} from '../../models/ngsi/ngsi-query-term.model';
import {NGSIQueryTermAssoc} from '../../models/ngsi/ngsi-query-term-assoc.model';
import {NGSIQueryExpression} from '../../models/ngsi/ngsi-query-expression.model';
import {Observable} from 'rxjs';
import {ElementDefinition} from '../../models/schema/element-definition.model';
import {QueryTermEditorDialogComponent} from '../../../modules/kpi-editor/components/dialogs/query-term-editor-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {EventService} from '../../../core/services/event.service';
import {KPI} from '../../models/kpi.model';
import {QueryTypeUtil} from 'app/shared/enums/query-type';
import {BaseComponent} from '../base.component';

/**
 * It allows users to create static and temporal query expressions i.e. filters.
 * */
@Component({
  selector: 'query-expression-filter',
  styleUrls: ['query-expression-filter.component.scss'],
  templateUrl: './query-expression-filter.component.html'
})
export class QueryExpressionFilterComponent extends BaseComponent implements OnInit {

  // KPI whose entity elements will be used to create filters
  @Input() kpi: KPI;
  // query expression representing the filter. It is available if and only if this component is used for editing
  @Input() queryExpression: NGSIQueryExpression;
  @Input() disabled = false;

  // filters being edited
  queryExpressions: NGSIQueryExpression[] = [];
  // filters on temporal parameters
  temporalQueryExpression: NGSIQueryExpression;
  // operators that combine the root level terms
  rootLevelOperators: string[] = [];
  // indicates whether the filters will be shown or not. It is used to re-render the filter list when filter list changes
  showExpressions = true;

  QueryTypeUtil = QueryTypeUtil;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initializeFields();
    this.subscribeToEvents();
  }

  initializeFields(): void {
    if (this.queryExpression) {
      this.initializeQueries();
    }
  }

  /**
   * Returns the complete query expression by combining the static and temporal terms.
   * */
  public getCompleteNGSIQueryExpression(): NGSIQueryExpression {
    // for non-temporal queries, use the query expressions directly
    if (!QueryTypeUtil.isTemporalQuery(this.kpi.queryType)) {
      return this.combineStaticTerms();
    }
    // for temporal queries, "and" the static query expressions and temporal query expressions
    return this.combineStaticAndTemporalTerms();
  }

  /**
   * Opens a dialog for creating a new filter. When the dialog is closed, it adds the new filter to the relevant list according to the given parameter type.
   * @param addToTemporalList
   */
  public onAddFilterClicked(addToTemporalList = false): void {
    // for temporal queries, retrieve only static elements.
    const category = QueryTypeUtil.isTemporalQuery(this.kpi.queryType) ? 'static' : null;
    let elementObservable: Observable<ElementDefinition[]> = this.structureDefinitionService.getEntityElements(this.kpi.getQueryEntityType(), category);
    if (addToTemporalList) {
      elementObservable = this.structureDefinitionService.getEntityElements(this.kpi.getQueryEntityType(), 'temporal');
    }
    elementObservable.subscribe(elements => {
      // open the dialog
      const dialogRef = this.dialogService.open(QueryTermEditorDialogComponent, {
        context: {
          elements: elements
        }
      });

      // retrieve the term on close and add it to the query expression list
      dialogRef.onClose
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          // check there is a valid result. There may not be such a result when the dialog is closed without clicking the add button
          if (result) {
            if (addToTemporalList) {
              this.addTemporalQueryTerm(result);

            } else {
              this.queryExpressions.push(result);
              if (this.queryExpressions.length > 1) {
                this.rootLevelOperators.push('and');
              }
            }
          }
        });
    });

  }

  /**
   * Handler for the group buttons. Combines the two terms around the operator.
   * @param index
   */
  public onGroupClicked(index: number): void {
    if (this.queryExpressions[index] instanceof NGSIQueryTerm) {
      // both terms are simple, a new association is created
      if (this.queryExpressions[index + 1] instanceof NGSIQueryTerm) {
        const association: NGSIQueryTermAssoc = new NGSIQueryTermAssoc();
        association.op = this.rootLevelOperators[index];
        association.queries = [this.queryExpressions[index], this.queryExpressions[index + 1]];
        association.queries.forEach(query => {
          query.parentAssociation = association;
        });

        // delete the related terms and add the new term association to the target index
        this.queryExpressions.splice(index, 2, association);

      }
      // the second term is an association. Put the first one into that
      else {
        const association: NGSIQueryTermAssoc = (this.queryExpressions[index + 1] as NGSIQueryTermAssoc);
        const termToAdd: NGSIQueryTerm = this.queryExpressions[index] as NGSIQueryTerm;
        termToAdd.parentAssociation = association;
        association.queries = [this.queryExpressions[index], ...association.queries];

        this.queryExpressions.splice(index, 1);
      }

    }
    // first term is an association
    else {
      // add the second term to the first one
      if (this.queryExpressions[index + 1] instanceof NGSIQueryTerm) {
        const termToAdd: NGSIQueryTerm = this.queryExpressions[index + 1] as NGSIQueryTerm;
        termToAdd.parentAssociation = this.queryExpressions[index] as NGSIQueryTermAssoc;
        (this.queryExpressions[index] as NGSIQueryTermAssoc).queries.push(termToAdd);

      }
      // both operands are an association. Merge the second one into the first one. We do not assure that operator type of the second association will be preserved.
      else {
        const association: NGSIQueryTermAssoc = (this.queryExpressions[index] as NGSIQueryTermAssoc);
        let associatedTerms1: NGSIQueryExpression[] = association.queries;
        const associatedTerms2: NGSIQueryExpression[] = (this.queryExpressions[index + 1] as NGSIQueryTermAssoc).queries;
        associatedTerms2.forEach(term => term.parentAssociation = association);
        associatedTerms1 = [...associatedTerms1, ...associatedTerms2];
        association.queries = associatedTerms1;
      }

      this.queryExpressions.splice(index + 1, 1);
    }

    // remove the operator in the specified index
    this.rootLevelOperators.splice(index, 1);
  }

  handleFilterEditClickedEvent(editedTerm: NGSIQueryTerm): void {
    const inTemporalList: boolean = this.isInTemporalList(editedTerm);
    let elementObservable: Observable<ElementDefinition[]> = this.structureDefinitionService.getEntityElements(this.kpi.getQueryEntityType());
    if (inTemporalList) {
      elementObservable = this.structureDefinitionService.getEntityElements(this.kpi.getQueryEntityType(), 'temporal');
    }
    elementObservable.subscribe(elements => {

      // open the dialog
      const dialogRef = this.dialogService.open(QueryTermEditorDialogComponent, {
        context: {
          elements: elements,
          queryTerm: editedTerm
        }
      });

      // retrieve the term on close and add it to the query expression list
      dialogRef.onClose
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          // check there is a valid result. There may not be such a result when the dialog is closed without clicking the add button
          if (result) {
            editedTerm.path = result.path;
            editedTerm.op = result.op;
            editedTerm.value = result.value;
            editedTerm.valueRange = result.valueRange;
          }
        });
    });
  }

  private subscribeToEvents(): void {
    this.eventService.eventEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        switch (event.id) {
          case EventService.KPI_EDITOR_FILTER_DELETED:
            this.handleFilterDeletedEvent(event.data);
            break;
          case EventService.KPI_EDITOR_FILTER_EDIT_CLICKED:
            this.handleFilterEditClickedEvent(event.data);
        }
      });
  }

  /**
   * Adds the new term to the temporal query terms list.
   * @param term
   * @private
   */
  private addTemporalQueryTerm(term: NGSIQueryTerm): void {
    // first term is added as it is
    if (!this.temporalQueryExpression) {
      this.temporalQueryExpression = term;
    }
    // subsequent terms are combined in an AND association
    else {
      this.showExpressions = false;
      setTimeout(() => {
        if (this.temporalQueryExpression instanceof NGSIQueryTerm) {
          const association: NGSIQueryTermAssoc = new NGSIQueryTermAssoc();
          association.op = 'and';
          association.queries = [this.temporalQueryExpression, term];
          association.queries.forEach(query => {
            query.parentAssociation = association;
          });
          this.temporalQueryExpression = association;

        } else {
          term.parentAssociation = this.temporalQueryExpression as NGSIQueryTermAssoc;
          (this.temporalQueryExpression as NGSIQueryTermAssoc).queries.push(term);
        }

        this.showExpressions = true;
      });
    }
  }

  private handleFilterDeletedEvent(deletedTerm: NGSIQueryExpression): void {
    const inTemporalList: boolean = this.isInTemporalList(deletedTerm);

    // when an inner filter is deleted, components rendering its parents become invalid. Therefore, the filter hierarchy should be re-rendered with the new data.
    this.showExpressions = false;
    setTimeout(() => {
      if (inTemporalList) {
        this.deleteTermFromTemporalList(deletedTerm);
      } else {
        this.deleteTermFromRegularList(deletedTerm);
      }

      this.showExpressions = true;
    });
  }

  /**
   * Deletes the given term from the regular terms list.
   * @param deletedTerm
   * @private
   */
  private deleteTermFromRegularList(deletedTerm: NGSIQueryExpression): void {
    const parentAssociation: NGSIQueryTermAssoc = deletedTerm.parentAssociation;
    // deleted term is located at the root level
    if (!parentAssociation) {
      const selfIndex: number = this.queryExpressions.findIndex(term => term === deletedTerm);
      this.queryExpressions.splice(selfIndex, 1);
      // term is not the first element. So, delete the operator before the term
      if (selfIndex > 0) {
        this.rootLevelOperators.splice(selfIndex - 1, 1);

        // first term is deleted. Remove the first operator
      } else {
        this.rootLevelOperators.splice(0, 1);
      }

      // an inner term is deleted
    } else {
      let selfIndex: number = parentAssociation.queries.findIndex(expression => expression === deletedTerm);
      // deleted term has exactly one sibling. So, remove the group containing the deleted term.
      if (parentAssociation.queries.length === 2) {
        const rootTerm: NGSIQueryTermAssoc = this.findRootmostExpression(deletedTerm) as NGSIQueryTermAssoc;
        const rootIndex: number = this.queryExpressions.findIndex(term => term === rootTerm);
        const sibling: NGSIQueryExpression = parentAssociation.queries[(++selfIndex) % 2];
        sibling.parentAssociation = null;
        this.queryExpressions.splice(rootIndex, 1, sibling);

        // deleted term has more than one sibling. So, just remove the term from its parent
      } else {
        const indexInParent: number = parentAssociation.queries.findIndex(term => term === deletedTerm);
        parentAssociation.queries.splice(indexInParent, 1);
      }
    }
  }

  /**
   * Deletes the given term from the temporal terms list.
   * @param deletedTerm
   * @private
   */
  private deleteTermFromTemporalList(deletedTerm: NGSIQueryExpression): void {
    const rootTerm: NGSIQueryExpression = this.temporalQueryExpression;

    // it the term is an NGSIQueryTerm (i.e. there is only one temporal term)
    if (rootTerm instanceof NGSIQueryTerm) {
      this.temporalQueryExpression = null;

    }
    // there are more than one temporal terms. Remove the given term from the list
    else {
      const rootTermAssoc: NGSIQueryTermAssoc = rootTerm as NGSIQueryTermAssoc;
      let selfIndex: number = rootTermAssoc.queries.findIndex(expression => expression === deletedTerm);

      // if there are two terms in the list, replace the remaining term with association
      if (rootTermAssoc.queries.length === 2) {
        const sibling: NGSIQueryExpression = rootTermAssoc.queries[(++selfIndex) % 2];
        sibling.parentAssociation = null;
        this.temporalQueryExpression = sibling;

      } else {
        const indexInParent: number = rootTermAssoc.queries.findIndex(term => term === deletedTerm);
        rootTermAssoc.queries.splice(indexInParent, 1);
      }
    }
  }

  /**
   * Finds the root-most expression containing the given expression
   * @param expression
   * @private
   */
  private findRootmostExpression(expression: NGSIQueryExpression): NGSIQueryExpression {
    let parentAssociation: NGSIQueryTermAssoc = expression.parentAssociation;
    if (!parentAssociation) {
      return expression;
    }
    // traverse the parent associations until the root
    while (parentAssociation.parentAssociation) {
      parentAssociation = parentAssociation.parentAssociation;
    }
    return parentAssociation;
  }

  /**
   * Checks whether the given expression is included in the temporal list
   * @param expression
   * @private
   */
  private isInTemporalList(expression: NGSIQueryExpression): boolean {
    const rootMostExpression: NGSIQueryExpression = this.findRootmostExpression(expression);
    if (this.temporalQueryExpression === expression) {
      return true;
    }
    return false;
  }

  /**
   * Combines the static and temporal terms. If there is only one static query term, it's ANDed with temporal terms. If the static terms are an association, we
   * first check the operator of that association. If it has an AND operator the temporal terms are merged with the static terms associations. If it has an OR operator,
   * we create a wrapper association combining the static and temporal terms with AND operator.
   * @private
   */
  private combineStaticAndTemporalTerms(): NGSIQueryExpression {
    const staticTerms: NGSIQueryExpression = this.combineStaticTerms();
    const temporalTerms: NGSIQueryExpression = this.temporalQueryExpression;
    let finalExpression: NGSIQueryExpression = staticTerms;
    if (staticTerms) {
      if (temporalTerms) {
        const finalAssociation: NGSIQueryTermAssoc = new NGSIQueryTermAssoc();
        finalAssociation.op = 'and';
        finalAssociation.queries = [staticTerms, temporalTerms];
        finalExpression = finalAssociation;
      }
    } else {
      finalExpression = temporalTerms;
    }
    return finalExpression;
  }

  /**
   * Combines the static terms. Terms as operands of AND operators are grouped. The AND associations are then wrapped by an OR association if there are OR any operator.
   * @private
   */
  private combineStaticTerms(): NGSIQueryExpression {
    let expression: NGSIQueryExpression;
    if (this.queryExpressions.length === 0) {
      return null;
    }
    // use the only term as it is
    else if (this.queryExpressions.length === 1) {
      expression = this.queryExpressions[0];
    }
    // combine the root level terms if there are more than one
    else {
      expression = this.getWrapperOredExpression();
    }
    return expression;
  }

  /**
   * Extracts the AND terms groups. If there are terms that are not included in the ANDed terms, it means they should be ORed with the AND associations (e.g. q1;q2|q3|q4;q5,
   * here c should be ORed with (q1;q2) and (q4;q5) AND associations).
   * @private
   */
  private getWrapperOredExpression(): NGSIQueryExpression {
    const [andedIndexes, andAssociations] = this.groupAndOperands();
    // extract the expressions that are not included in the "ANDed" ones
    let expressionsToBeOred: NGSIQueryExpression[] = [];
    for (let i: number = 0; i < this.queryExpressions.length; i++) {
      if (!andedIndexes.some(index => i === index)) {
        expressionsToBeOred.push(this.queryExpressions[i]);
      }
    }

    // add the AND associations to the list of expressions to be ORed
    expressionsToBeOred = expressionsToBeOred.concat(andAssociations);

    // if there is only one expression, we use it e.g. a or a;b
    if (expressionsToBeOred.length === 1) {
      return expressionsToBeOred[0];
    }
    // combine the expressions with OR
    else {
      const wrapperOrAssociation = new NGSIQueryTermAssoc();
      wrapperOrAssociation.op = 'or';
      wrapperOrAssociation.queries = expressionsToBeOred;
      return wrapperOrAssociation;
    }
  }

  /**
   * As the AND operator has higher priority than OR, we first group terms of AND operators.
   * @private
   */
  private groupAndOperands(): [number[], NGSIQueryTermAssoc[]] {
    // expressions that included in the AND associations
    const andedExpressions: NGSIQueryTermAssoc[] = [];
    // indexes of terms that are ANDed
    const andedIndexes: number[] = [];
    // AND association that is updated while traversing the terms below
    let currentAssociation: NGSIQueryTermAssoc;
    // index of the last term that is included in the AND association
    let currentAssociationLastIndex: number;
    // traverse the operators and keep the terms around them
    this.rootLevelOperators.forEach((operator, i) => {
      // encountered and operator, put the terms around the operator to the current and association
      if (operator === 'and') {
        const firstExpression: NGSIQueryExpression = this.queryExpressions[i];
        const secondExpressions: NGSIQueryExpression = this.queryExpressions[i + 1];
        // association is not initialized. Initialize one and put both expressions into it
        if (!currentAssociation) {
          currentAssociation = new NGSIQueryTermAssoc();
          currentAssociation.op = operator;
          currentAssociation.queries = [firstExpression, secondExpressions];
          currentAssociationLastIndex = i + 1;
          andedIndexes.push(i);
          andedIndexes.push(i + 1);

        } else {
          // checking the expression right after the last expression of the current association (e.g. assuming a criteria like q1;q2;q3 , we are checking q3)
          if (i === currentAssociationLastIndex) {
            currentAssociationLastIndex = i + 1;
            andedIndexes.push(i + 1);
            currentAssociation.queries.push(this.queryExpressions[i + 1]);
          }
        }

        // when we encounter or operator, keep the current association and set it null so that a new one would be created for the next AND operator
      } else {
        if (currentAssociation) {
          andedExpressions.push(currentAssociation);
          currentAssociation = null;
        }
      }
    });

    // keep the last group of anded expressions (e.g. q1;q2 -> q1;q2 or q1|q2|q3;q4 --> q3;q4)
    if (currentAssociation) {
      andedExpressions.push(currentAssociation);
      currentAssociation = null;
    }

    return [andedIndexes, andedExpressions];
  }

  private initializeQueries(): void {
    // if it is a temporal KPI, try to identify the temporal queries, if any
    if (QueryTypeUtil.isTemporalQuery(this.kpi.queryType)) {
      this.initializeTemporalQueries();
    }
    this.initializeStaticQueries();
  }

  private initializeStaticQueries(): void {
    let staticQueriesRoot: NGSIQueryExpression = this.queryExpression;
    // if there is a temporal query, we try to find the root expression of the static queries
    if (this.temporalQueryExpression) {
      if (this.queryExpression instanceof NGSIQueryTermAssoc) {
        staticQueriesRoot = (this.queryExpression as NGSIQueryTermAssoc).queries.find(query => query !== this.temporalQueryExpression);
      }
    }
    this.traverseStaticQueries(staticQueriesRoot);
  }

  /**
   * Traverses expressions ORed at the root level
   * @param expression
   * @private
   */
  private traverseStaticQueries(expression: NGSIQueryExpression): void {
    if (expression instanceof NGSIQueryTerm) {
      this.queryExpressions = [expression];
    } else {
      const association: NGSIQueryTermAssoc = expression as NGSIQueryTermAssoc;
      // there are terms to be ORed
      if (association.op === 'or') {
        association.queries.forEach((query, index) => {
          // traverse each AND subgroup separately
          this.traverseAndQueries(query);
          // keep an OR operator between each AND subgroup
          if (index < association.queries.length - 1) {
            this.rootLevelOperators.push('or');
          }
        });
      } else {
        this.traverseAndQueries(association);
      }
    }
  }

  /**
   * Traverses a expression provided to an AND operator
   * @param expression
   * @private
   */
  private traverseAndQueries(expression: NGSIQueryExpression): void {
    // it's a simple query. Just add it to the list of query expressions
    if (expression instanceof NGSIQueryTerm) {
      this.queryExpressions.push(expression);
    }
    // it's an AND association. AND operators will be at the root level for each sub-term
    else {
      const association: NGSIQueryTermAssoc = expression as NGSIQueryTermAssoc;
      association.queries.forEach((query, index) => {
        this.queryExpressions.push(query);
        if (index < association.queries.length - 1) {
          this.rootLevelOperators.push('and');
        }
      });
    }
  }

  /**
   * Initializes the temporal terms
   * @private
   */
  private initializeTemporalQueries(): void {
    const rootExpression: NGSIQueryExpression = this.queryExpression;
    if (rootExpression) {
      // the main filter is a simple query term. Example query: t1
      if (rootExpression instanceof NGSIQueryTerm) {
        const rootTerm: NGSIQueryTerm = rootExpression as NGSIQueryTerm;
        if (rootTerm.path.containsTemporalElement()) {
          this.temporalQueryExpression = rootExpression;
        }
      } else {
        // we should look for a term with a temporal element or an association including temporal element inside the root expression
        const rootAssociation: NGSIQueryTermAssoc = rootExpression as NGSIQueryTermAssoc;

        // check the first level terms inside root association
        rootAssociation.queries.forEach(term => {
          // the term is a simple term. Example query: q1;t1
          if (term instanceof NGSIQueryTerm) {
            if ((term as NGSIQueryTerm).path.containsTemporalElement()) {
              this.temporalQueryExpression = term;
            }
          }
            // the term is an association. It is sufficient to check expressions at one more level depth.
          // It is the deepest level where temporal queries can be e.g. q1;(t1;t2)
          else {
            const associationContainsTemporalTerm: boolean = (term as NGSIQueryTermAssoc).queries
              .filter(subTerm => subTerm instanceof NGSIQueryTerm)
              .some(subTerm => (subTerm as NGSIQueryTerm).path.containsTemporalElement());
            if (associationContainsTemporalTerm) {
              this.temporalQueryExpression = term;
            }
          }
        });
      }
    }
  }
}

