import {SelectWidthFormatterFunction} from '../directives/select-width.directive';
import {Tag} from '../models/tag.model';
import {Layer} from '../models/layer.model';
import {Page} from '../models/page.model';
import {NGSIPath} from '../models/ngsi/ngsi-path.model';
import {StructureDefinition} from '../models/schema/structure-definition.model';
import {KPI} from '../models/kpi.model';
import {Region} from '../models/region.model';
import {Realm} from '../models/realm.model';
import {NGSIOperator} from '../models/ngsi/operator/ngsi-operator.model';
import {ElementDefinition} from '../models/schema/element-definition.model';
import {PageTemplate} from '../models/page-template.model';

/**
 * This class provides the implementations of {@link SelectWidthFormatterFunction} for several models such as Tag and Layer.
 * */
export class SelectWidthFormatterUtil {

  public static tagFormatter: SelectWidthFormatterFunction = (options: Tag[]) => {
    return options ? options.map(tag => tag.name) : [];
  };

  public static layerFormatter: SelectWidthFormatterFunction = (options: Layer[]) => {
    return options ? options.map(layer => layer.name) : [];
  };

  public static pageFormatter: SelectWidthFormatterFunction = (options: Page[]) => {
    return options ? options.map(page => page.title) : [];
  };

  public static structureDefinitionFormatter: SelectWidthFormatterFunction = (options: StructureDefinition[]) => {
    return options ? options.map(definition => definition.title) : [];
  };

  public static kpiFormatter: SelectWidthFormatterFunction = (options: KPI[]) => {
    return options ? options.map(kpi => kpi.name) : [];
  };

  public static regionFormatter: SelectWidthFormatterFunction = (options: Region[]) => {
    return options ? options.map(region => region.name) : [];
  };

  public static realmFormatter: SelectWidthFormatterFunction = (options: Realm[]) => {
    return options ? options.map(realm => realm.name) : [];
  };

  public static ngsiOperatorFormatter: SelectWidthFormatterFunction = (options: NGSIOperator[]) => {
    return options ? options.map(operator => operator.name) : [];
  };

  public static elementDefinitionFormatter: SelectWidthFormatterFunction = (options: ElementDefinition[]) => {
    return options ? options.map(definition => definition.path) : [];
  };

  public static titleFormatter: SelectWidthFormatterFunction = (options: any[]) => {
    return options ? options.map(option => option.title) : [];
  };

  public static ngsiPathFormatter: SelectWidthFormatterFunction = (options: NGSIPath[]) => {
    return options ? options.map(ngsiPath => ngsiPath.columnName) : [];
  }

  public static pageTemplateFormatter: SelectWidthFormatterFunction = (options: PageTemplate[]) => {
    return options ? options.map(pageTemplate => pageTemplate.id) : [];
  }
}
