/**
 * Abstract base query class for NGSI query builders
 */
import {BaseModel} from '../base/base-model.model';

export abstract class BaseQuery extends BaseModel {
  protected constructor(data?: any) {
    super(data);
  }

  abstract createNGSIParameters();
}
