export class SignalizationModel {
  etcsIsolationByPass: any;
  ertmsCutOff: any;
  atsByPass: any;
  ertmsServiceBrake: any;

  constructor(data: any) {
    this.etcsIsolationByPass = data.etcsIsolationByPass;
    this.ertmsCutOff = data.ertmsCutOff;
    this.atsByPass = data.atsByPass;
    this.ertmsServiceBrake = data.ertmsServiceBrake;
  }
}
