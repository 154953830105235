<div class="success-dialog flex-centered">
  <div class="icon">
    <uruk-svg
      [paths]="['M48 0.5C21.7658 0.5 0.5 21.7658 0.5 48C0.5 74.2342 21.7658 95.5 48 95.5C74.2342 95.5 95.5 74.2342 95.5 48C95.5 21.7658 74.2342 0.5 48 0.5ZM75.1083 37.1082L41.8582 70.3583C40.932 71.2845 39.716 71.75 38.5 71.75C37.284 71.75 36.068 71.2845 35.1418 70.3583L20.8918 56.1082C19.0345 54.251 19.0345 51.249 20.8918 49.3918C22.749 47.5345 25.751 47.5345 27.6082 49.3918L38.5 60.2835L68.3917 30.3918C70.249 28.5345 73.251 28.5345 75.1083 30.3918C76.9655 32.249 76.9655 35.251 75.1083 37.1082Z']"
      [svgContainerClass]="'uruk-icon'"
      [svgContainerStyle]="'width: 7vmin;'"
      [viewbox]="'0 0 96 96'">
    </uruk-svg>
  </div>
  <div class="message">
    {{message | translate}}
  </div>
</div>
