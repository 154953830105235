import {FormUtil} from './form-util';

export enum RestParameters {
  CLIENT_ID = 'client_id',
  ENTITY_TYPE = 'entityType',
  IDS = 'ids',
  NAME = 'name',
  NONCE = 'nonce',
  PAGE_ID = 'pageId',
  PARENT_REGION_ID = 'parentRegionId',
  REDIRECT_URI = 'redirect_uri',
  RESPONSE_TYPE = 'response_type',
  SCOPE = 'scope',
  STATE = 'state',
  TAGS = 'tags',
  TYPE = 'type',
  USER_ID = 'userId',
  REFRESH_TOKEN = 'refresh_token',
  GRANT_TYPE = 'grant_type',
  AUDIENCE = 'audience'
}

export enum Headers {
  AUTHORIZATION = 'Authorization'
}

export const BEARER_TOKEN_PREFIX = 'Bearer';

export class RestUtil {

  public static defaultHeaders() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }

  public static createURLParameters(parameters: any): string {
    return Object.keys(parameters)
      .filter(key => {
        const value = parameters[key];
        if (FormUtil.isEmptyValue(value)) {
          return false;
        } else {
          if (Array.isArray(value)) {
            return value.length > 0;
          } else {
            return true;
          }
        }
      })
      .map(key => {
        const value = parameters[key];

        // encodeURIComponent removes paranthesis for geolocation queries. e.g. [[[lng1,lat1], [lng2,lat2], ...]]
        // therefore, do not use it for coordinates
        if (key === 'coordinates') {
          return key + '=' + JSON.stringify(value);
        }
        // Otherwise, call encodeURIComponent
        else {
          // handle arrays
          if (Array.isArray(value)) {
            return key + '=' + value.map(encodeURIComponent).join(',');
          }
          // handle objects
          else {
            return key + '=' + encodeURIComponent(value);
          }
        }
      })
      .join('&');
  }

  public static parseURLParameters(parameters: string): any {
    const result = {};
    parameters.split('&').forEach((part) => {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }
}
