import {QueryOperatorExpr} from '../query/query-operator-expr.model';
import {NGSIPath} from '../ngsi/ngsi-path.model';
import {QueryOperator} from '../query/query-operator.model';

/**
 * Factory class to handle different types query operator expressions
 */
export class QueryOperatorExpressionFactory {

  /**
   * Factory method to create a specific query operator expression
   * @param data
   */
  static create(data: any): QueryOperatorExpr {
    if (data.jsonClass === 'QueryOperator') {
      return new QueryOperator(data);
    } else {
      return new NGSIPath(data);
    }
  }
}
